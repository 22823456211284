import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // submit new password
    requestSubmitNewPassword: ['data'],
    submitNewPasswordSucceeded: ['message'],
    submitNewPasswordFailed: ['errors'],

    // clear message success
    clearMessage: null,

});

export default createReducer(initialState, {
    // submit new password
    [types.REQUEST_SUBMIT_NEW_PASSWORD]: (state) => ({
        ...state,
        submitting: true,
        errors: null,
    }),
    [types.SUBMIT_NEW_PASSWORD_SUCCEEDED]: (state, { message }) => ({
        ...state,
        submitting: false,
        success: message,
    }),
    [types.SUBMIT_NEW_PASSWORD_FAILED]: (state, { errors }) => ({
        ...state,
        submitting: false,
        errors,
    }),

    // clear message success
    [types.CLEAR_MESSAGE]: (state) => ({
        ...state,
        success: '',
        errors: null,
    }),
});

export { types, actions };
