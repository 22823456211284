import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Space,
    Select,
    Slider,
    Divider,
    Typography,
    DatePicker,
} from 'antd';
import { CalendarIcon, FilterIcon, PlusIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/invoice/reducer';
import { selectors } from '@/redux/invoice/create/model';
import { useCurrency } from '@/hooks/useCurrency';

const Filters = ({
    total,
    filters,
    setFilters,
    withCreateButton = false,
    hasCustomer,
    usedForCustomerInvoices,
    customerData,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const [isOpen, setIsOpen] = useState(false);

    const customerList = useSelector(selectors.customerList);
    const loading = useSelector(selectors.loading);

    // get customer list by search
    const onSearch = useCallback((search) => {
        if (search) {
            const data = {
                full_name: search,
            };
            dispatch(actions.requestGetCustomerList(data));
        }
    }, [dispatch]);

    return (
        <Form
            initialValues={filters}
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                if (name[0] !== 'prices') {
                    setFilters(name[0], value);
                }
            }}
        >
            <Row
                className="filter-content"
                justify="space-between"
                gutter={[0, 30]}
            >
                <Col>
                    {usedForCustomerInvoices ? (
                        <Space direction="vertical" className="customer-invoices">
                            <div>
                                <span>{`${t('No. of Invoices')}: `}</span>
                                <span>{total}</span>
                            </div>
                            <div>
                                <span>{`${t('Revenue')}: `}</span>
                                <span>{`${currencyIcon}${customerData.revenue}`}</span>
                            </div>
                        </Space>
                    ) : (
                        <Typography.Text>
                            {`${t('Currently')} ${total} ${total === 1 ? t('Invoice') : t('Invoices')}`}
                        </Typography.Text>
                    )}
                </Col>

                <Col>
                    <Space size={25}>
                        <Form.Item name="name" noStyle>
                            <Input.Search
                                className="search"
                                placeholder={t('Search')}
                            />
                        </Form.Item>

                        <Button
                            className="button"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <FilterIcon />

                            {t('Filter').toUpperCase()}
                        </Button>

                        <Form.Item name="sort" noStyle>
                            <Select placeholder={t('Sort by')}>
                                <Select.Option value="status">{t('Status')}</Select.Option>
                                <Select.Option value="amount">{t('Amount')}</Select.Option>
                                <Select.Option value="created_by">{t('Created by')}</Select.Option>
                            </Select>
                        </Form.Item>

                        {withCreateButton && (
                            <Button
                                onClick={() => {
                                    push('/app/invoices/custom/new');
                                    dispatch(actions.clearCustomInvoiceState());
                                }}
                                className="button add-button"
                            >
                                <PlusIcon />
                                {t('Add custom Invoice')}
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>

            {isOpen && (
                <>
                    <Divider dashed />

                    <Row
                        className="advanced-filter-content"
                        gutter={[30, 30]}
                    >
                        <Col xxl={5} xl={8} md={12} xs={24}>
                            <Form.Item name="date" noStyle>
                                <DatePicker.RangePicker
                                    placeholder={[t('Created Date'), '']}
                                    popupClassName="filter-calendar"
                                    suffixIcon={<CalendarIcon />}
                                    separator={filters.date ? '-' : ''}
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl={4} xl={8} sm={12} xs={24}>
                            <Form.Item name="widgets" noStyle>
                                <Select
                                    className="select-option"
                                    placeholder={t('Created by')}
                                >
                                    <Select.Option value={0}>{t('Web')}</Select.Option>
                                    <Select.Option value={1}>{t('Internal')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xxl={4} xl={8} sm={12} xs={24}>
                            <Form.Item name="type" noStyle>
                                <Select
                                    className="select-option"
                                    placeholder={t('Service Type')}
                                >
                                    <Select.Option value={1}>{t('Tour Trip')}</Select.Option>
                                    <Select.Option value={2}>{t('Tour Package')}</Select.Option>
                                    <Select.Option value={3}>{t('Cruise')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {hasCustomer && (
                            <Col lg={4}>
                                <Form.Item name="customer" noStyle>
                                    <Select
                                        className="select-option"
                                        placeholder={t('Customer')}
                                        onSearch={onSearch}
                                        loading={loading}
                                        showSearch
                                        filterOption={false}
                                        defaultActiveFirstOption={false}
                                        notFoundContent={null}
                                        allowClear
                                    >
                                        {customerList.map((customer) => (
                                            <Select.Option
                                                key={customer.id}
                                                value={customer.id}
                                            >
                                                {customer.full_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col xxl={4} xl={8} sm={6} xs={24}>
                            <Form.Item name="status" noStyle>
                                <Select
                                    className="select-option"
                                    placeholder={t('Status')}
                                >
                                    <Select.Option value={1}>{t('Paid')}</Select.Option>
                                    <Select.Option value={2}>{t('Unpaid')}</Select.Option>
                                    <Select.Option value={3}>{t('Canceled')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xxl={4} xl={8} sm={24}>

                            <div className="ant-slider-min-max-row">
                                <span className="min-price">
                                    {`${currencyIcon}${filters.prices[0]}`}
                                </span>
                                <div className="ant-slider-min-max-row__line" />
                                <span className="max-price">
                                    {`${currencyIcon}${filters.prices[1]}`}
                                </span>
                            </div>

                            <div>
                                <Form.Item name="prices" noStyle>
                                    <Slider
                                        className="trip-slider"
                                        range
                                        min={10}
                                        max={700}
                                        step={5}
                                        tooltip={{
                                            open: false,
                                        }}
                                        onAfterChange={(value) => {
                                            setFilters('prices', value);
                                        }}
                                    />
                                </Form.Item>
                            </div>

                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
};

export default Filters;
