import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import TaxesService from '@/services/SettingsServices/TaxesService';
import { types, actions } from './reducer';

function* submitTaxDetails({ id, data }) {
    try {
        let response;
        if (id) {
            response = yield call(TaxesService.editTaxDetails, id, data);
        } else {
            response = yield call(TaxesService.addTaxDetails, data);
        }
        yield put(actions.submitTaxDetailsSucceeded(response.data, 'Tax details saved successfully'));
    } catch (error) {
        yield put(actions.submitTaxDetailsFailed(extractError(error)));
    }
}

function* fetchTaxDetails({ id }) {
    try {
        const response = yield call(TaxesService.viewTaxDetails, id);
        yield put(actions.fetchTaxDetailsSucceeded(response.data));
    } catch (error) {
        yield put(actions.fetchTaxDetailsFailed(extractError(error)));
    }
}

function* watchSubmitTaxDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_TAX_DETAILS,
        submitTaxDetails,
    );
}

function* watchFetchTaxDetails() {
    yield takeEvery(
        types.REQUEST_FETCH_TAX_DETAILS,
        fetchTaxDetails,
    );
}

function* createTaxSaga() {
    yield all([
        fork(watchSubmitTaxDetails),
        fork(watchFetchTaxDetails),
    ]);
}

export default createTaxSaga;
