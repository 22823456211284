import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Typography,
    Divider,
    Form,
    Input,
    Radio,
    Button,
    Space,
} from 'antd';
import { HelpIcon } from '@/assets/images/icons/topbar';
import { actions } from '@/redux/b2b/share/reducer';
import { selectors as shareSelectors } from '@/redux/b2b/share/model';
import useNotification from '@/hooks/useNotification';

const ShareProduct = ({
    visible,
    setVisible,
    serviceName,
    serviceType,
    serviceId,
    isShared,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const options = useMemo(() => ([
        { label: '%', value: 1 },
        { label: '$', value: 2 },
    ]), []);

    const sharedSuccess = useSelector(shareSelectors.message);
    const details = useSelector(shareSelectors.details);
    const error = useSelector(shareSelectors.error);

    const onFinish = useCallback((values) => {
        const data = {
            type: serviceType === 'tour' ? 1 : 2,
            commission: values.commission,
            commission_type: values.commission_type,
            termsofservice: values.termsofservice,
        };
        dispatch(actions.requestShareB2bService(serviceId, data));
        dispatch(actions.clearSharedB2bServiceDetails());
    }, [dispatch, serviceId, serviceType]);

    useEffect(() => {
        if (sharedSuccess === 'Shared successfully') {
            setVisible(false);
            form.setFieldsValue(
                {
                    commission: null,
                    commission_type: 1,
                    termsofservice: null,
                },
            );
            dispatch(actions.clearShareRemoveMessage());
        }
    }, [sharedSuccess, setVisible, form, dispatch]);

    useEffect(() => {
        if (isShared && serviceId && visible) {
            const data = {
                type: serviceType === 'tour' ? 1 : 2,
            };
            dispatch(actions.requestViewSharedService(serviceId, data));
        }
    }, [dispatch, isShared, serviceId, serviceType, visible]);

    useEffect(() => {
        let initialData = {
            commission_type: 1,
        };
        if (details) {
            initialData = {
                commission: details.commission,
                commission_type: parseInt(details.commission_type, 10),
                termsofservice: details.termsofservice,
            };
        } form.setFieldsValue(initialData);
    }, [form, details]);

    useNotification({
        messageSuccess: '',
        error,
        clearAction: actions.clearB2bShareError(),
    });

    return (
        <Modal
            className="share-product"
            open={visible}
            onCancel={() => {
                setVisible(false);
                dispatch(actions.clearSharedB2bServiceDetails());
                form.setFieldsValue(
                    {
                        commission: null,
                        commission_type: 1,
                        termsofservice: null,
                    },
                );
            }}
            footer={null}
            maskClosable={false}
            centered
            width={550}
        >
            <div className="service-name">
                <Typography.Text>
                    {`${t('Sharing')} "${serviceName}"`}
                </Typography.Text>
            </div>

            <Divider />

            <Form
                onFinish={onFinish}
                form={form}
            >
                <Form.Item name="commission">
                    <Input
                        placeholder={t('Enter the commission for vendors')}
                        suffix={(
                            <>
                                <span>0.00</span>
                                <Form.Item
                                    name="commission_type"
                                    noStyle
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </>
                        )}
                    />
                </Form.Item>
                <Form.Item name="termsofservice" noStyle>
                    <Input.TextArea className="input" placeholder={t('Your Terms and Conditions')} />
                </Form.Item>
                <Form.Item>
                    <div className="terms-and-condition-info">
                        <Space size={10} align="start">
                            <HelpIcon />
                            {`${t('In order to have a fair B2B collaboration please enter your terms and conditions that will be use as an agreement to your new partner')}.`}
                        </Space>
                    </div>
                </Form.Item>
                <Button block onClick={() => form.submit()}>
                    {t('Share now').toUpperCase()}
                </Button>
            </Form>
        </Modal>
    );
};

export default ShareProduct;
