import { useTranslation } from 'react-i18next';
import {
    Input,
    Row,
    Col,
    Typography,
    Select,
    Form,
    Button,
} from 'antd';
import { CloseCircleIcon } from '@/assets/images/icons/screens';

const options = new Array(5).fill(null).map((_, i) => (
    <Select.Option key={i.toString()}>{i}</Select.Option>
));

const AddEvaluation = ({ setVisibleForm }) => {
    const { t } = useTranslation();
    return (
        <div className="add-evaluation-form">
            <Form>
                <Row gutter={[30, 30]}>
                    <Col span={8} xxl={8} xs={24}>
                        <Typography.Text>{t('Add new Evaluation')}</Typography.Text>
                        <Row gutter={[0, 20]}>
                            <Col span={24}>
                                <Form.Item name="job-knowledge" noStyle>
                                    <Select placeholder={t('Job Knowledge')}>
                                        {options}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="productivity" noStyle>
                                    <Select placeholder={t('Productivity')}>
                                        {options}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="teamwork" noStyle>
                                    <Select placeholder={t('Teamwork')}>
                                        {options}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="communication-skills" noStyle>
                                    <Select placeholder={t('Communication Skills')}>
                                        {options}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={16} xxl={16} xs={24}>
                        <Form.Item name="notes" noStyle>
                            <Input.TextArea
                                rows={8}
                                placeholder={t('Enter your opinion about this evaluation')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row gutter={30} justify="end">
                            <Col>
                                <Button danger type="text" onClick={() => setVisibleForm(false)}>
                                    <CloseCircleIcon />
                                    {t('Cancel').toUpperCase()}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    block
                                    type="primary"
                                >
                                    {t('SAVE')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AddEvaluation;
