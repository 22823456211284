import { lazy } from 'react';

const Login = lazy(() => import('@/screens/Login'));
const Register = lazy(() => import('@/screens/Register'));
const ForgotPassword = lazy(() => import('@/screens/ForgotPassword'));
const NotFound = lazy(() => import('@/screens/NotFound'));

export default [
    {
        path: '/login',
        key: 'LOGIN',
        exact: true,
        component: Login,
        accessor: 'login',
    },
    {
        path: '/register',
        key: 'REGISTER',
        exact: false,
        component: Register,
        accessor: 'register',
    },
    {
        path: '/forgot-password',
        key: 'FORGOT_PASSWORD',
        exact: true,
        component: ForgotPassword,
        accessor: 'forgotPassword',
    },
    {
        path: '/not-found',
        key: 'NOT_FOUND',
        exact: true,
        component: NotFound,
        accessor: 'notFound',
    },
];
