import { all, fork, takeEvery } from 'redux-saga/effects';

import pagination from '@/helpers/pagination';
import CruiseService from '@/services/TourServices/CruiseService';
import { types } from './reducer';

function* watchFetchCruises() {
    yield takeEvery(
        types.REQUEST_FETCH_TOUR_CRUISES,
        pagination('tourCruises').saga(CruiseService),
    );
}

function* cruiseSaga() {
    yield all([
        fork(watchFetchCruises),
    ]);
}

export default cruiseSaga;
