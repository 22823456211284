import BaseService from '@/services/BaseService';

class PackageService extends BaseService {
    getAll = (filters) => this.post('/agency/packageslist', filters || { });

    getOne = (id) => this.get(`/agency/viewpackage/${id}`);

    getReservationList = (id, filters) => this.post(
        `/agency/searchtouraccommodation/${id}`,
        filters,
    );

    deleteOne = (id) => this.delete(`/agency/deletetour/${id}`);

    // general info
    addGeneralInfo = (data) => this.post(
        '/agency/addtourpackagegeneralinfo',
        data,
    );

    editGeneralInfo = (id, data) => this.post(
        `/agency/edittourpackagegeneralinfo/${id}`,
        data,
    );

    viewGeneralInfo = (id) => this.get(
        `/agency/viewtourpackagegeneralinfo/${id}`,
    );

    // accommodation

    getHotels = (filters) => this.post(
        '/agency/getaccommodationlist',
        filters || { },
    );

    submitHotel = (id, data) => this.post(
        `/agency/addtouraccommodation/${id}`,
        data,
    );

    editHotel = (id, data) => this.post(
        `/agency/edittouraccommodation/${id}`,
        data,
    );

    viewHotel = (id) => this.get(
        `/agency/viewtouraccommodation/${id}`,
    );

    // inclusions, exclusions

    addInclusionsExclusions = (id, data) => this.post(
        `/agency/addtourinclusions/${id}`,
        data,
    );

    editInclusionsExclusions = (id, data) => this.post(
        `/agency/edittourinclusions/${id}`,
        data,
    );

    viewInclusionsExclusions = (id) => this.get(
        `/agency/viewtourinclusions/${id}`,
    );

    // other services
    getPackageTypes = (data) => this.post('/agency/gettourtypeslist/2', data || { });

    addNewPackageType = (data) => this.post('/agency/addtourtype', data);

    getInclusionsExclusions = () => this.get('/agency/getinclusions/2');

    addNewInclusion = (data) => this.post('/agency/addnewinclusion', data);

    addNewExclusion = (data) => this.post('/agency/addnewexclusion', data);

    getRoomTypeList = (id) => this.get(`/agency/getpackageroomtypes/${id}`);
}

export default new PackageService();
