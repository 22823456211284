import { useTranslation } from 'react-i18next';
import {
    Pagination,
    Table,
    Row, Col,
    // Space,
    // Button,
} from 'antd';
import InfoBlock from '@/components/Information/WithoutImage/Block';
// import { DownLoadIcon, SendMsgIcon } from '@/assets/images/icons/screens';

const InvoiceTable = ({
    data,
    totalItems,
    page,
    setPage,
    perPage,
    setPerPage,
    currency,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('Product /Service').toUpperCase(),
            dataIndex: 'product',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Partner type').toUpperCase(),
            dataIndex: 'partner_type',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Amount').toUpperCase(),
            dataIndex: 'amount',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Order date').toUpperCase(),
            dataIndex: 'order_date',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Status').toUpperCase(),
            dataIndex: 'status',
            sorter: true,
            width: '20%',
            render: (text, record) => {
                if (record.status === 'Paid') {
                    return <p className="paid">{record.status}</p>;
                } else if (record.status === 'Unpaid') {
                    return <p className="unpaid">{record.status}</p>;
                } else if (record.status === 'Partial') {
                    return <p className="partial">{record.status}</p>;
                } else if (record.status === 'Canceled') {
                    return <p className="canceled">{record.status}</p>;
                } else {
                    return <p className="draft">{record.status}</p>;
                }
            },
        },
    ];

    // const rowSelection = {};

    const datasource = data?.map((row, i) => ({
        key: i,
        id: row.id,
        product:
    <InfoBlock
        name={row.service_name}
        className="textSize14"
    >
        <span className="textSize12">
            {row.service}
        </span>
    </InfoBlock>,
        partner_type:
    <InfoBlock name={row.partner_type} className="textSize14">
        <span className="textSize12">
            {t('Company')}
            :
            {row.company}
        </span>
    </InfoBlock>,
        amount:
    <InfoBlock name={currency.concat(row.amount)} className="textSize14">
        <span className="textSize12">
            {row.commission}
            {t('Profits').toLowerCase()}
        </span>
    </InfoBlock>,
        order_date:
    <InfoBlock name={row.date_insert} className="textSize14">
        <span className="textSize12">
            {t('by')}
            :
            {row.added_by}
        </span>
    </InfoBlock>,
        status: row.status,
    }));

    return (
        <Table
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={datasource}
            pagination={false}
            rowKey={(record) => record.id}
            size="small"
            footer={() => (
                <Row justify="end">
                    {/* <Col>
                        <Space size={25}>
                            <Button>
                                <DownLoadIcon />
                                {t('Export').toUpperCase()}
                            </Button>
                            <Button>
                                <SendMsgIcon />
                                {t('Send').toUpperCase()}
                            </Button>
                        </Space>
                    </Col> */}
                    <Col>
                        <Pagination
                            current={page}
                            total={totalItems}
                            pageSize={perPage}
                            onChange={(current, pageSize) => {
                                setPage(current);
                                setPerPage(pageSize);
                            }}
                        />
                    </Col>
                </Row>
            )}
        />
    );
};

export default InvoiceTable;
