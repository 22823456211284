import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    name: {
        name: 'Name',
        required: true,
        min: 5,
        max: 150,
    },
    duration: {
        name: 'Duration',
        required: true,
    },
    cost: {
        name: 'Cost',
        required: true,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    style: {
        name: 'Tour style',
        required: true,
    },
    max_person_no: {
        name: 'Adults number',
        required: true,
    },
    min_person_no: {
        name: 'Adults number',
        required: true,
    },
    adult_price: {
        name: 'Adults price',
        required: true,
    },
    extraServiceName: {
        name: 'Extra service name',
        required: true,
    },
    // extraServicePrice: {
    //     name: 'Extra service price',
    //     required: true,
    // },
};

export default buildValidationsFromConfig(config);
