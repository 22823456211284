import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import ReportService from '@/services/ReportService';
import { types, actions } from './reducer';

// let total = 0;

function* getB2bReports({ filters }) {
    try {
        const response = yield call(ReportService.getB2bReports, filters);
        const {
            cards: supplierAndVendors,
            graph: b2bSalesOverview,
            invoices: invoiceData,
        } = response.data;
        // const {
        //     list: invoiceList,
        //     meta: metaObject,
        // } = invoiceData;
        // const totalInvoices = metaObject.total !== undefined ? metaObject.total : total;
        // total = totalInvoices;
        yield put(actions.getB2bReportsSucceeded(
            supplierAndVendors,
            b2bSalesOverview,
            invoiceData,
            // totalInvoices,
        ));
    } catch (error) {
        yield put(actions.getB2bReportsFailed(extractError(error)));
    }
}

function* compareB2bReports({ filters }) {
    try {
        const response = yield call(ReportService.compareB2bReports, filters);
        yield put(actions.compareB2bReportsSucceeded(response.data));
    } catch (error) {
        yield put(actions.compareB2bReportsFailed(extractError(error)));
    }
}

function* watchGetB2bReports() {
    yield takeEvery(
        types.REQUEST_GET_B2B_REPORTS,
        getB2bReports,
    );
}

function* watchCompareB2bReports() {
    yield takeEvery(
        types.REQUEST_COMPARE_B2B_REPORTS,
        compareB2bReports,
    );
}

function* b2bReportSaga() {
    yield all([
        fork(watchGetB2bReports),
        fork(watchCompareB2bReports),
    ]);
}

export default b2bReportSaga;
