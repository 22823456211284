const model = {
    supplierAndVendors: null,
    b2bSalesOverview: [],
    invoices: [],
    totalInvoices: 0,
    loading: false,
    error: null,

    // compare
    b2bReports: null,
};

export default model;
