import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { selectors } from '@/redux/invoice/createcustom/model';
import { useCurrency } from '@/hooks/useCurrency';
import General from '../InvoicePreview/General';
import Total from '../InvoicePreview/Total';
import DateOfIssue from './DateOfIssue';
import Services from './Services';

const Preview = ({
    createMode,
    invoiceDetails,
    invoiceCustomer,
}) => {
    const { icon: currencyIcon } = useCurrency();
    // view mode
    const {
        agency_data: agencyData,
        invoice_data: invoiceCharge,
        custom_invoice: servicesFromView,
    } = invoiceDetails !== null ? invoiceDetails : {};

    // create mode
    const agencyDataFromCreate = useSelector((state) => state.authentication.user);
    // const extra = useSelector(selectors.extra);
    const servicesFromCreate = useSelector(selectors.services);
    const { invoice_to: invoiceTo } = invoiceCustomer !== null ? invoiceCustomer : {};

    return (
        <div className="preview">
            <General
                agencyData={createMode ? agencyDataFromCreate : agencyData}
                invoiceCustomer={createMode ? invoiceTo : invoiceCustomer}
            />

            <Divider />

            <DateOfIssue
                services={createMode ? servicesFromCreate : servicesFromView}
            />

            <Services
                createMode={createMode}
                services={createMode ? servicesFromCreate : servicesFromView}
                currency={currencyIcon}
            />

            <div className="invoice-divider"><Divider /></div>

            <Total
                invoiceCharge={createMode ? invoiceDetails : invoiceCharge}
                currency={currencyIcon}
            />
        </div>
    );
};

export default Preview;
