import { Waypoint } from 'react-waypoint';
import { Row, Col } from 'antd';
import EmptyList from '@/components/EmptyList';
import Filters from '@/components/Invoice/InvoiceFilters';

import GraphCard from './GraphCard';
import InvoiceTable from './InvoiceTable';

const WorkDetails = ({
    id,
    currency,
    invoices,
    filters,
    setFilters,
    handleFetchMore,
    isLoadingMore,
    hasMore,
    loading,
    total,
}) => {
    return (
        <Row gutter={[0, 30]}>
            <Col xs={24}>
                <GraphCard employeeId={id} currency={currency} />
            </Col>
            <Col xs={24}>
                <Filters
                    invoices={invoices}
                    filters={filters}
                    setFilters={setFilters}
                    hasCustomer={true}
                    usedForCustomerInvoices={false}
                    total={total}
                />
            </Col>
            <Col xs={24}>
                { loading
                    ? 'Loading...'
                    : invoices.length === 0
                        ? (
                            <EmptyList
                                src="/assets/images/icons/invoices.svg"
                                message="There are no data stored yet"
                            />
                        ) : (
                            <>
                                <InvoiceTable data={invoices} currency={currency} />
                                {!isLoadingMore && hasMore && (
                                    <Waypoint onEnter={() => handleFetchMore()} />
                                )}
                            </>
                        )}
            </Col>
        </Row>
    );
};

export default WorkDetails;
