import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import CollapsedCard from '@/components/CollapsedCard';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { PlusIcon } from '@/assets/images/icons/screens';

const RoomList = ({ data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const goToCreateRoom = useCallback(() => {
        dispatch(actions.goToCreateRoom());
    }, [dispatch]);

    return (
        <>
            <Row gutter={[0, 20]}>
                {data.map((item) => (
                    <Col key={item.id} xs={24}>
                        <CollapsedCard
                            item={item}
                            firstLabel="Number of Rooms"
                            secondLabel="Price per Night"
                            hasImage={true}
                            type="room"
                            viewItem={actions.requestViewAccommodationRoom}
                            getAmenities={actions.requestGetAmenitiesList}
                            deleteItem={actions.requestDeleteAccommodationRoom}
                            create={actions.goToCreateRoom}
                        />
                    </Col>
                ))}
            </Row>
            <div className="add-new-room-button">
                <Button
                    className="text-add-button"
                    type="text"
                    onClick={() => goToCreateRoom()}
                >
                    <PlusIcon />
                    {t('Add New Room Type')}
                </Button>
            </div>
        </>

    );
};

export default RoomList;
