import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    step: 0, // 0, 1, 2
    completedStep: null, // null, 0, 1, 2
    submitting: false,
    loading: false,
    errors: null,
    generalInfo: null,
    itenerary: [],
    inclusions: [],
    exclusions: [],
    tripTypeError: null,

    tripTypes: [],
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.trip.create,
    model,
);

export default model;
