import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import {
    DeleteIcon,
    EditIcon,
    RemoveCollectionIcon,
    RemoveShareCollectionIcon,
} from '@/assets/images/icons/screens';

const noop = () => {};

const SimpleDropdownMenu = ({
    editText,
    onEditClick = noop,
    deleteText,
    deleteAction,
    type,
    removeSharedText,
    removeSharedServiceAction,
    removeSharedCollectedText,
    removeSharedCollectedServiceAction,
    isShared,
    name,
    setItemName,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showDeleteModal = useCallback(() => {
        Modal.confirm({
            className: 'confirm-modal',
            title: `${t('Are you sure to delete this')} ${t(type)} ?`,
            onOk() {
                dispatch(deleteAction);
            },
        });
        if (setItemName !== undefined) setItemName(name);
    }, [dispatch, t, type, deleteAction]);

    const removeSharedService = useCallback(() => {
        dispatch(removeSharedServiceAction);
    }, [dispatch, removeSharedServiceAction]);

    const removeSharedCollectedService = useCallback(() => {
        dispatch(removeSharedCollectedServiceAction);
    }, [dispatch, removeSharedCollectedServiceAction]);

    const items = [
        {
            label: editText,
            icon: <div><EditIcon /></div>,
            key: 'edit',
            onClick: onEditClick,
        },
        {
            label: deleteText,
            icon: <div><DeleteIcon /></div>,
            key: 'delete',
            onClick: showDeleteModal,
        },
        {
            label: removeSharedText,
            icon: <div><RemoveCollectionIcon /></div>,
            key: 'remove shared',
            onClick: removeSharedService,
        },
        {
            label: removeSharedCollectedText,
            icon: <div><RemoveShareCollectionIcon /></div>,
            key: 'remove shared collected',
            onClick: removeSharedCollectedService,
        },
    ];

    return (isShared ? items : [items[0], items[1]]);
};

export default SimpleDropdownMenu;
