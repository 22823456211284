import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Card,
} from 'antd';
import { TinyArea } from '@ant-design/charts';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import useEmployeeInvoicesGraphs from '@/hooks/useEmployeeInvoicesGraphs';

const GraphCard = ({ employeeId, currency }) => {
    const { t } = useTranslation();
    const { data } = useEmployeeInvoicesGraphs({ id: employeeId });

    const aStyle = {
        fillOpacity: 0.2,
        cursor: 'pointer',
    };

    const lStyle = {
        line: {
            style: {
                lineDash: [2, 3],
            },
        },
    };

    const totalSales = data?.data.year_data.map((month) => month.revenue);
    const thisMonthSales = data?.data.month_data.map((day) => day.revenue);

    const totalSalesConfig = {
        height: 60,
        autoFit: false,
        data: totalSales,
        smooth: true,
        line: {
            color: '#24DCAE',
            ...lStyle.line,
        },
        areaStyle: {
            fill: 'l(270) 0:#ffffff 0.5:#ffffff 1:#24DCAE',
            ...aStyle,
        },
    };

    const thisMonthConfig = {
        height: 60,
        autoFit: false,
        data: thisMonthSales,
        smooth: true,
        line: {
            color: '#24DCAE',
            ...lStyle.line,
        },
        areaStyle: {
            fill: 'l(270) 0:#ffffff 0.5:#ffffff 1:#24DCAE',
            ...aStyle,
        },
    };
    return (
        <Row gutter={20} className="sales-graph-wrapper">
            <Col span={12}>
                <Card
                    className="sales-card"
                    title={(
                        <>
                            <div>{t('Total sales').toUpperCase()}</div>
                            <div id="subtitle">{t('Since the beginning')}</div>
                        </>
                    )}
                    extra={<span>{`${data?.data.year_sales} ${t('sales')}`}</span>}
                >
                    <Row gutter={30}>
                        <Col xs={16}>
                            <TinyArea {...totalSalesConfig} />
                        </Col>
                        <Col xs={8}>
                            <BlockDescription
                                name={`${currency}${data?.data.year_revenue}`}
                                className="total-amount-value"
                                childClassName="total-amount-text"
                            >
                                {t('Total amount').toUpperCase()}
                            </BlockDescription>
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={12}>
                <Card
                    className="sales-card"
                    title={(
                        <>
                            <div>{t('This month').toUpperCase()}</div>
                            <div id="subtitle">{t('Last 30 days')}</div>
                        </>
                    )}
                    extra={<span>{`${data?.data.month_sales} ${t('sales')}`}</span>}
                >
                    <Row gutter={30}>
                        <Col xs={16}>
                            <TinyArea {...thisMonthConfig} />
                        </Col>
                        <Col xs={8}>
                            <BlockDescription
                                name={`${currency}${data?.data.month_revenue}`}
                                className="total-amount-value"
                                childClassName="total-amount-text"
                            >
                                {t('Total amount').toUpperCase()}
                            </BlockDescription>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export default GraphCard;
