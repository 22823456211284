import { all } from 'redux-saga/effects';

import dashboardSaga from './dashboard/saga';
import hotelSaga from './hotel/saga';

function* accommodationSaga() {
    yield all([
        dashboardSaga(),
        hotelSaga(),
    ]);
}

export default accommodationSaga;
