import { combineReducers } from 'redux';

import general, {
    types as generalTypes,
    actions as generalActions,
} from './general/reducer';

import taxes, {
    types as taxesTypes,
    actions as taxesActions,
} from './taxes/reducer';

import billing, {
    types as billingTypes,
    actions as billingActions,
} from './billing/reducer';

const actions = {
    ...generalActions,
    ...taxesActions,
    ...billingActions,
};

const types = {
    ...generalTypes,
    ...taxesTypes,
    ...billingTypes,
};

export { types, actions };

const settingsReducer = combineReducers({
    general,
    taxes,
    billing,
});

export default settingsReducer;
