import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Typography,
} from 'antd';
import DataContainer from '@/components/DataContainer';
import InlineDescription from '@/components/Information/WithoutImage/Inline';

const Rooms = ({ data }) => {
    const { t } = useTranslation();
    return (
        data.length !== 0 && (
            <DataContainer title={t('Rooms')}>
                <Row gutter={[0, 0]} className="right-col_cards">
                    {data.map((item) => (
                        <Col span={24} key={item.id}>
                            <Space>
                                <div className="rooms-created-card_image">
                                    <img src={item.image} alt="room" />
                                </div>
                                <div>
                                    <Typography.Text>{item.type_name}</Typography.Text>
                                    <InlineDescription
                                        label={t('Number of Rooms')}
                                        content={item.no_rooms}
                                    />
                                    <InlineDescription
                                        label={t('Price per Night')}
                                        content={item.price}
                                    />
                                </div>
                            </Space>
                        </Col>
                    ))}
                </Row>
            </DataContainer>
        )
    );
};

export default Rooms;
