import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
    Divider,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { actions } from '@/redux/tour/activities/create/reducer';
import { selectors } from '@/redux/tour/activities/create/model';
import { useCurrency } from '@/hooks/useCurrency';

const formFields = [
    'company_name',
    'vat_number',
    'email',
    'phone_number',
    'address',
    'website',
    'commission',
    'commission_type',
];

const AddNewPartner = ({ form, isEdit, regStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const valueOptions = useMemo(() => ([
        { label: '%', value: 1 },
        { label: currencyIcon, value: 2 },
    ]), [t]);

    const onFinish = useCallback((values) => {
        let data = null;
        if (values.company_name !== undefined) {
            data = {
                type: 1,
                company_name: values.company_name,
                vat_number: values.vat_number,
                email: values.email,
                phone_number: values.phone_number,
                address: values.partner_address,
                website: values.website,
                commission: values.commission,
                commission_type: values.commission_type,
            };
            if (isEdit && regStep === 3) {
                const step = 3;
                const completedStep = 3;
                dispatch(actions.requestSubmitActivityPartner(
                    data,
                    step,
                    completedStep,
                ));
            } else {
                const step = 3;
                const completedStep = 2;
                dispatch(actions.requestSubmitActivityPartner(
                    data,
                    step,
                    completedStep,
                ));
            }
        } else {
            data = {
                type: 0,
            };
            if (isEdit && regStep === 3) {
                const step = 3;
                const completedStep = 3;
                dispatch(actions.requestSubmitActivityPartner(
                    data,
                    step,
                    completedStep,
                ));
            } else {
                const step = 3;
                const completedStep = 2;
                dispatch(actions.requestSubmitActivityPartner(
                    data,
                    step,
                    completedStep,
                ));
            }
        }
    }, [dispatch]);

    const partnerData = useSelector(selectors.partner);

    const error = useSelector(selectors.errors);
    const formattedErrors = {
        ...error,
        partnerAddress: error?.address,
    };
    const errors = error === null ? null : formattedErrors;

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => {
        if (partnerData && partnerData.partner) {
            return {
                company_name: partnerData.partner.company_name,
                vat_number: partnerData.partner.vat_number,
                email: partnerData.partner.email,
                phone_number: partnerData.partner.phone_number,
                partner_address: partnerData.partner.address,
                website: partnerData.partner.website,
                commission: partnerData.commission,
                commission_type: partnerData.commission_type === 'Percentage' ? 1 : 2,
            };
        } else {
            return {
                commission_type: 1,
            };
        }
    }, [partnerData]);

    return (
        <Form
            form={form}
            id="activity-partner-form"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={initialData || {}}
        >
            <Row gutter={[25, 0]}>
                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Company Name')}
                        name="company_name"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Registration Number')}
                        name="vat_number"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Phone Number')}
                        name="phone_number"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Email')}
                        name="email"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Address')}
                        name="partner_address"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Website')}
                        name="website"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>
            </Row>

            <Divider />

            <MUIFormItem
                label={t('My Commission')}
                name="commission"
            >
                <Input
                    suffix={(
                        <Form.Item
                            noStyle
                            name="commission_type"
                        >
                            <Radio.Group
                                options={valueOptions}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </Form.Item>
                    )}
                />
            </MUIFormItem>
        </Form>
    );
};

export default AddNewPartner;
