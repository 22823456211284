import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Checkbox,
} from 'antd';
import { actions } from '@/redux/integrations/bookingEngine/reducer';

const SearchWidgetServices = ({
    checkedServices,
    setCheckedServices,
    checkedTourCategories,
    setCheckedTourCategories,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tourCategories = [
        {
            id: 1,
            name: t('Tour Trip'),
        },
        {
            id: 2,
            name: t('Tour Package'),
        },
        {
            id: 3,
            name: t('Cruise Tour'),
        },
        // {
        //     id: 4,
        //     name: t('Activity'),
        // },
    ];

    const services = useSelector((state) => state.integrations.bookingEngine.services);

    const removeService = (id) => {
        const result = checkedServices.filter((service) => service.id !== id);
        setCheckedServices(result);
    };

    useEffect(() => {
        dispatch(actions.requestGetServices());
    }, [dispatch]);

    const isTourChecked = checkedServices.some((item) => item.id === 2);

    return (
        <div className="service-list-wrapper">
            <Checkbox.Group
                defaultValue={[checkedServices[0]?.id]}
                className="service-list"
            >
                <Row gutter={[20, 20]}>
                    {services.map((service) => (
                        <Col
                            span={12}
                            className="services"
                            key={service.id}
                        >
                            <Checkbox
                                value={service.id}
                                onChange={(e) => {
                                    e.target.checked
                                        ? setCheckedServices((prev) => [
                                            {
                                                id: service.id,
                                                name: service.name,
                                            },
                                            ...prev,
                                        ])
                                        : removeService(service.id);
                                }}
                            >
                                <div className="role-name">
                                    {t(service.name)}
                                </div>
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
            {isTourChecked && (
                <div className="tour-categories">
                    <Checkbox.Group defaultValue={checkedTourCategories}>
                        <Row gutter={[45, 20]}>
                            {tourCategories.map((category) => (
                                <Col span={8} key={category.id}>
                                    <Checkbox
                                        value={category.id}
                                        onChange={(e) => {
                                            e.target.checked
                                                ? setCheckedTourCategories(
                                                    (prev) => [...prev, category.id],
                                                ) : setCheckedTourCategories(
                                                    (prev) => prev.filter(
                                                        (item) => item !== category.id,
                                                    ),
                                                );
                                        }}
                                    >
                                        {category.name}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </div>
            )}
        </div>
    );
};

export default SearchWidgetServices;
