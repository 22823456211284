import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';

import { actions } from '@/redux/employees/create/reducer';
import { selectors } from '@/redux/employees/create/model';

import GroupMenu from './GroupMenu';
import AddNewRole from './AddNewRole';

const Roles = () => {
    const dispatch = useDispatch();
    const [addMode, setAddMode] = useState();
    const [groupSelected, setGroupSelected] = useState();
    const [defGroupName, setDefGroupName] = useState();
    const [checkedRoles, setCheckedRoles] = useState({});

    const roles = useSelector(selectors.roles);
    const groups = useSelector(selectors.groups);

    // roles of selected group
    const groupRoles = useSelector(selectors.groupRoles);

    useEffect(() => {
        dispatch(actions.requestGetGroups());
    }, [dispatch]);

    useEffect(() => {
        dispatch(actions.requestGetRoles());
    }, [dispatch]);

    const updatedGroupRoles = groupRoles.map((groupRole) => {
        const matchingItem = roles.find((role) => role.category === groupRole.category);

        // If a matching item is found in roles, return a new object with the id from roles
        if (matchingItem) {
            return {
                id: matchingItem.id,
                category: groupRole.category,
                roles: groupRole.roles,
            };
        }

        return null;
    }).filter(Boolean);

    useEffect(() => {
        const data = {};
        if (groupRoles.length > 0) {
            updatedGroupRoles.forEach((role) => {
                data[`roles${role.id}`] = role.roles.map((item) => item.id);
            });
            setCheckedRoles(data);
        }
    }, [groupRoles]);

    return (
        <Row gutter={30} className="role-page">
            <Col xs={7} className="menu-section">
                <GroupMenu
                    groups={groups}
                    groupSelected={groupSelected}
                    setGroupSelected={setGroupSelected}
                    setDefGroupName={setDefGroupName}
                    setAddMode={setAddMode}
                    setCheckedRoles={setCheckedRoles}
                />
            </Col>
            <Col xs={17}>
                <AddNewRole
                    roles={roles}
                    groups={groups}
                    defGroupName={defGroupName}
                    setDefGroupName={setDefGroupName}
                    setGroupSelected={setGroupSelected}
                    addMode={addMode}
                    setAddMode={setAddMode}
                    checkedRoles={checkedRoles}
                    setCheckedRoles={setCheckedRoles}
                    defGroupId={groups[0]?.id}
                    groupSelectedId={groupSelected !== 1 && groupSelected?.slice(groupSelected.indexOf('-') + 1)}
                />
            </Col>
        </Row>
    );
};

export default Roles;
