import {
    HostelIcon,
    HotelIcon,
    MotelIcon,
    ResortIcon,
} from '@/assets/images/icons/screens';

export default [
    {
        id: 1,
        image: <HotelIcon />,
        title: 'Hotel',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new hotel',
        viewButtonText: 'View hotels',
        url: 'accommodations/hotel',
        type: 'hotel',
    },
    {
        id: 2,
        image: <ResortIcon />,
        title: 'Resort',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new resort',
        viewButtonText: 'View resorts',
        url: 'accommodations/resort',
        type: 'resort',
    },
    {
        id: 3,
        image: <MotelIcon />,
        title: 'Motel',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new motel',
        viewButtonText: 'View motels',
        url: 'accommodations/motel',
        type: 'motel',
    },
    {
        id: 4,
        image: <HostelIcon />,
        title: 'Hostel',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new hostel',
        viewButtonText: 'View hostels',
        url: 'accommodations/hostel',
        type: 'hostel',
    },
];
