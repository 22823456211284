import { Switch, Route, Redirect } from 'react-router-dom';
import WorldwideSupplier from './WorldwideSupplier';
import MyPartners from './MyPartners';
import Requests from './Requests';
import AgencyProfile from './AgencyProfile';

export const routes = [
    {
        name: 'Worldwide Supplier',
        icon: 'worldwide supplier icon',
        description: 'worldwide desc',
        url: '/worldwide-supplier',
        component: WorldwideSupplier,
    },
    {
        name: 'My Partners',
        icon: 'my partners icon',
        description: 'my partners desc',
        url: '/my-partners',
        component: MyPartners,
    },
    {
        name: 'Requests',
        icon: 'requests icon',
        description: 'requests desc',
        url: '/requests',
        component: Requests,
    },
    {
        name: 'Agency Profile',
        icon: 'agency profile icon',
        description: 'agency profile desc',
        url: '/agency-profile/:id',
        component: AgencyProfile,
    },
];

const B2BPartners = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/b2b-partners${route.url}`}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/app/b2b-partners/worldwide-supplier" />
        </Switch>
    );
};

export default B2BPartners;
