import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import CollapsedCard from '@/components/CollapsedCard';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { PlusIcon } from '@/assets/images/icons/screens';

const SeasonList = ({ data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const goToCreateSeason = useCallback(() => {
        dispatch(actions.goToCreateSeason());
    }, [dispatch]);

    return (
        <>
            <Row gutter={[0, 20]}>
                {data.map((item) => (
                    <Col key={item.id} xs={24}>
                        <CollapsedCard
                            item={item}
                            firstLabel="Start Season"
                            secondLabel="End Season"
                            type="season"
                            viewItem={actions.requestViewAccommodationSeason}
                            deleteItem={actions.requestDeleteAccommodationSeason}
                            create={actions.goToCreateSeason}
                        />
                    </Col>
                ))}
            </Row>
            <div className="add-new-room-button">
                <Button
                    className="text-add-button"
                    type="text"
                    onClick={goToCreateSeason}
                >
                    <PlusIcon />
                    {t('Add new season').toUpperCase()}
                </Button>
            </div>
        </>

    );
};

export default SeasonList;
