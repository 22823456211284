import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    accommodationDetails: null,
    roomTypeList: [],
    loading: false,
    error: null,

    // offers
    offerDetails: null,
    offerList: [],
    submitting: false,
    id: null,
    messageSuccess: '',
    messageError: null,

    // change form visibility
    visibleForm: false,

    roomList: [],
    filterData: null,
    fetchingLoading: true,
    fetchingError: null,
    isLoadingMore: false,
    isLoadingMoreError: null,
    hasMore: false,
};

export const selectors = createSelectorsFromModel(
    (state) => state.accommodation.hotel.view,
    model,
);

export default model;
