import { Typography } from 'antd';
import clsx from 'clsx';

const InlineDescription = ({ label, content, className }) => {
    return (
        <div className="description">
            <Typography.Text className="label">
                {label}
                :
            </Typography.Text>

            <Typography.Text className={clsx('content', className)}>
                {content}
            </Typography.Text>
        </div>
    );
};

export default InlineDescription;
