export default {
    authenticated: true,
    authenticatedUncompleted: false,
    authenticatedReview: false,
    user: null,
    fetchingSelf: true,
    fetchingSelfError: null,

    loggingIn: false,
    loggingInError: null,
    loggingOut: false,
    loggingOutError: null,

    loading: false,
    error: null,
    accessTree: {},
};
