import { Form } from 'antd';

import MultipleUpload from '@/components/Upload/Multiple';
import { allowedImageMIMETypes } from '@/constants/upload';

const ImagesUpload = () => {
    return (
        <Form.Item name="images">
            <MultipleUpload
                allowedMIMETypes={allowedImageMIMETypes}
                maxCount={5}
                listType="picture-card"
            />
        </Form.Item>
    );
};

export default ImagesUpload;
