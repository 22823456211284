import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import EmptyList from '@/components/EmptyList';
import { actions } from '@/redux/employees/reducer';
import { EmptyEmployeesListIcon } from '@/assets/images/icons/screens';

import Filters from './Filters';
import EmployeeList from './EmployeeList';
import Loading from './Loading';

const initialFilters = {};

const ViewAll = () => {
    const dispatch = useDispatch();

    const [filters, baseSetFilters] = useState(initialFilters);
    const [page, setPage] = useState(1);

    const handleFetchEmployees = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                ...filters,
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            dispatch(actions.requestFetchEmployees(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const employees = useSelector((state) => state.employees.data);
    const total = useSelector((state) => state.employees.totalItems);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.employees);

    useEffect(() => {
        handleFetchEmployees({});
    }, [handleFetchEmployees]);

    const handleFetchMore = useCallback(() => {
        handleFetchEmployees({ currentPage: page, fetchMore: true });
    }, [handleFetchEmployees, page]);

    return (
        <div className="employee-view-all">
            <Filters
                filters={filters}
                setFilters={setFilters}
                total={total}
            />
            { loading
                ? <Loading />
                : employees.length === 0
                    ? (
                        <EmptyList
                            image={<EmptyEmployeesListIcon />}
                            category={'Start creating employee'.toUpperCase()}
                            message="Oops! Looks you haven't created yet any Employee"
                            url="/app/employees/employees/add"
                        />
                    ) : (
                        <>
                            <EmployeeList data={employees} />
                            {!isLoadingMore && hasMore && (
                                <Waypoint onEnter={() => handleFetchMore()} />
                            )}
                        </>
                    )}
        </div>
    );
};

export default ViewAll;
