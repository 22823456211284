import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Row,
    Col,
    Calendar,
    Divider,
    Typography,
    Checkbox,
    Space,
    Select,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import { dateFormat } from '@/helpers/dateFormat';
import { actions } from '@/redux/tour/activities/view/reducer';
import { selectors } from '@/redux/tour/activities/view/model';

import { TimeInDays, TimeInMinHours } from '@/helpers/timeFormat';
import { useEffect } from 'react';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const ActivityCalendar = ({
    data,
    setSelectedDate,
    setTimeChecked,
    timeCheckedId,
    setTimeCheckedId,
    personNo,
    setPersonNo,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const timeList = useSelector(selectors.timeList);
    const selectedDate = useSelector(selectors.activityDate);

    const { duration: activityDuration } = data;

    useEffect(() => {
        dispatch(actions.requestGetActivityTimeList(
            data.id,
            moment(data.start_date).format(dateFormat),
        ));
    }, []);

    return (
        <Row justify="space-between">
            <Col lg={12}>
                <Calendar
                    value={selectedDate !== null ? moment(selectedDate) : moment()}
                    fullscreen={false}
                    validRange={[moment(data.start_date), moment(data.end_date)]}
                    onSelect={(date) => {
                        dispatch(actions.requestGetActivityTimeList(
                            data.id,
                            moment(date).format(dateFormat),
                        ));
                        setSelectedDate(moment(date).format(dateFormat));
                    }}
                    headerRender={({ value, onChange }) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];
                        const current = value.clone();
                        const localeData = value.localeData();
                        const months = [];

                        for (let i = 0; i < 12; i++) {
                            current.month(i);
                            months.push(localeData.monthsShort(current));
                        }

                        for (let index = start; index < end; index++) {
                            monthOptions.push(
                                <Select.Option className="month-item" key={`${index}`}>
                                    {months[index]}
                                </Select.Option>,
                            );
                        }
                        const month = value.month();
                        const year = value.year();
                        const options = [];

                        for (let i = year - 10; i < year + 10; i += 1) {
                            options.push(
                                <Select.Option key={i} value={i} className="year-item">
                                    {i}
                                </Select.Option>,
                            );
                        }
                        return (
                            <div className="calendar-header">
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Select
                                            size="small"
                                            dropdownMatchSelectWidth={false}
                                            className="my-year-select"
                                            onChange={(newYear) => {
                                                const now = value.clone().year(Number(newYear));
                                                (now);
                                            }}
                                            value={String(year)}
                                        >
                                            {options}
                                        </Select>
                                    </Col>
                                    <Col span={12}>
                                        <Select
                                            size="small"
                                            dropdownMatchSelectWidth={false}
                                            value={String(month)}
                                            onChange={(selectedMonth) => {
                                                const newValue = value.clone();
                                                newValue.month(parseInt(selectedMonth, 10));
                                                onChange(newValue);
                                            }}
                                        >
                                            {monthOptions}
                                        </Select>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }}
                />
            </Col>

            <Col lg={2} className="calendar-divider">
                <Divider type="vertical" />
            </Col>

            <Col lg={10}>
                <Row justify="space-between" className="duration-content">
                    <Col>
                        <BlockDescription name={t('Duration')}>
                            {activityDuration.duration_type === 'Hours'
                                ? (
                                    <>
                                        <span className="duration-start">
                                            <TimeInMinHours
                                                value={activityDuration.duration_from}
                                            />
                                        </span>

                                        {' -- '}

                                        <span className="duration-end">
                                            <TimeInMinHours
                                                value={activityDuration.duration_to}
                                            />
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="duration-start">
                                            <TimeInDays value={activityDuration.duration_from} />
                                        </span>

                                        {' -- '}

                                        <span className="duration-end">
                                            <TimeInDays value={activityDuration.duration_to} />
                                        </span>
                                    </>
                                )}
                        </BlockDescription>
                    </Col>
                    <Col>
                        <HeaderWithHelp
                            question={question}
                            answer={answer}
                        />
                    </Col>
                </Row>
                {timeList.length !== 0 && (
                    <Typography.Text>
                        {t(`There are "${timeList.length} times" available on ${selectedDate}`)}
                    </Typography.Text>
                )}

                <Divider />

                {timeList.map((item) => (
                    <Space key={item.id} className="time-price">
                        <Checkbox
                            checked={item.id === timeCheckedId}
                            onChange={() => {
                                setTimeCheckedId(item.id);
                                setTimeChecked(item.time);
                                setPersonNo(0);
                            }}
                        >
                            {item.time}
                            {' '}
                            {item.price === data.price
                                ? `${item.price} ${t('Default Price')}`
                                : `${item.price} / ${t('person')}`}
                        </Checkbox>
                        <IncrementNumberInput
                            max={4}
                            onChange={setPersonNo}
                            defaultCount={item.id === timeCheckedId ? personNo : 0}
                            disableButton={item.id !== timeCheckedId}
                        />
                    </Space>
                ))}
            </Col>
        </Row>
    );
};

export default ActivityCalendar;
