import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import PackageService from '@/services/TourServices/PackageService';
import { types, actions } from './reducer';

function* deletePackage({ id }) {
    try {
        yield call(PackageService.deleteOne, id);
        yield put(actions.deletePackageSucceeded(id, 'package deleted successfully'));
    } catch (error) {
        yield put(actions.deletePackageFailed(extractError(error)));
    }
}

function* watchDeletePackage() {
    yield takeEvery(
        types.REQUEST_DELETE_PACKAGE,
        deletePackage,
    );
}

function* packageDeleteSaga() {
    yield all([
        fork(watchDeletePackage),
    ]);
}

export default packageDeleteSaga;
