import { useTranslation } from 'react-i18next';
import LinkButton from '@/components/LinkButton';

const EmptyList = ({
    category,
    message,
    url,
    image,
}) => {
    const { t } = useTranslation();

    return (
        <div className="empty-list-content">
            {image}

            <div className="text">
                {t(message)}
            </div>

            <LinkButton to={url} className="create-button">
                {t(category)}
            </LinkButton>
        </div>
    );
};

export default EmptyList;
