import BaseService from '@/services/BaseService';

class GeneralService extends BaseService {
    getAgencyDetails = () => this.get('/agency/getgeneral');

    submitAgencyDetails = (data) => this.post('/agency/editgeneral', data);

    getAgencyCurrency = () => this.get('/agency/currencylist');
}

export default new GeneralService();
