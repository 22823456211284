import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { NavLink } from 'react-router-dom';

// import useCurrentRoute from '@/hooks/useCurrentRoute';
import { concatParentPathToSubroute } from '@/helpers/routing';

const SubMenu = ({ currentRoute }) => {
    const { t } = useTranslation();

    const hasSubRoutes = (
        currentRoute
        && currentRoute.subMenus
        && Array.isArray(currentRoute.subMenus)
    );

    const subRoutes = useMemo(() => {
        return hasSubRoutes ? currentRoute.subMenus.map(
            concatParentPathToSubroute.bind(null, currentRoute.path),
        ) : [];
    }, [hasSubRoutes, currentRoute]);

    // const currentSubRoute = useCurrentRoute(subRoutes);

    return (
        <Space className="subroutes-menu" size={30}>
            {hasSubRoutes && subRoutes.map((item) => (
                <NavLink
                    key={item.name}
                    className="subroutes-menu-item"
                    to={item.path}
                >
                    <Space size={10}>
                        <span className="icon">
                            {item.icon}
                        </span>
                        <span className="text">
                            {t(item.name)}
                        </span>
                    </Space>
                </NavLink>
            ))}
        </Space>
    );
};

export default SubMenu;
