import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@/assets/images/icons/screens';

const ViewOneHeader = ({
    goBackTitle,
    goBackUrl,
    title,
    children,
}) => {
    const { t } = useTranslation();

    return (
        <Row className="view-one-header" justify="space-between">
            <Col>
                <Link to={goBackUrl}>
                    <Button className="go-back">
                        <ArrowLeftIcon />
                        {t(goBackTitle)}
                    </Button>
                </Link>

                <span className="header-title">
                    {title}
                </span>
            </Col>

            <Col>
                {children}
            </Col>
        </Row>
    );
};

export default ViewOneHeader;
