import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from 'antd';

import HeaderWithHelp from '@/components/HeaderWithHelp';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { selectors } from '@/redux/accommodation/hotel/create/model';

import AddNewSeason from './AddNewSeason';
import EmptySeasonList from './EmptySeasonList';
import SeasonList from './SeasonList';

const question = 'What is this ?';
const answer = 'Lorem ipsum dolor sit amet...';

const SeasonalPrices = () => {
    const { id: urlId } = useParams();
    const dispatch = useDispatch();

    const seasonList = useSelector(selectors.seasonList);
    const roomTypesList = useSelector(selectors.roomList);
    const loading = useSelector(selectors.loading);
    const isCreatingSeason = useSelector(selectors.isCreatingSeason);
    const reduxId = useSelector(
        (state) => state.accommodation.hotel.create.accommodationDetails?.id,
    );

    const hotelId = urlId || reduxId;

    useEffect(() => {
        dispatch(actions.requestGetRoomList(hotelId));
    }, [dispatch, hotelId]);

    useEffect(() => {
        dispatch(actions.requestGetSeasonList(hotelId));
    }, [dispatch, hotelId]);

    if (loading) {
        return (
            <p>loading</p>
        );
    } else if (isCreatingSeason) {
        return (
            <>
                <HeaderWithHelp
                    title="Seasonal Price"
                    question={question}
                    answer={answer}
                />

                <Divider />

                <AddNewSeason roomTypesList={roomTypesList} />
            </>
        );
    } else {
        return (
            <>
                <HeaderWithHelp
                    title="Seasonal Price"
                    question={question}
                    answer={answer}
                />

                <Divider />

                {(seasonList.length === 0) ? (
                    <EmptySeasonList />
                ) : (
                    <SeasonList data={seasonList} />
                )}
            </>
        );
    }
};

export default SeasonalPrices;
