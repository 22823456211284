import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Space,
    Select,
    Divider,
    Typography,
    DatePicker,
    Checkbox,
} from 'antd';
import GenderSelect from '@/components/GenderSelect';
import {
    CalendarIcon,
    FilterIcon,
    // LocationIcon,
    NewUserIcon,
} from '@/assets/images/icons/screens';
import CountrySelect from '@/components/CountrySelect';
import { actions } from '@/redux/customers/reducer';

const Filters = ({
    data,
    filters,
    setFilters,
    setCheckedList,
    checkAll,
    setCheckAll,
    totalItems,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const addCustomerUrl = '/app/customers/add';

    const [isOpen, setIsOpen] = useState(false);

    const onCheckAllChange = (e) => {
        setCheckedList(
            e.target.checked
                ? data.map((item) => item.id)
                : [],
        );
        setCheckAll(e.target.checked);
    };

    return (
        <Form
            initialValues={filters}
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                setFilters(name[0], value);
            }}
        >
            <Row
                className="filter-content"
                justify="space-between"
                gutter={30}
            >
                <Col>
                    <Checkbox checked={checkAll} onChange={onCheckAllChange}>
                        <Space size={1} direction="vertical">
                            <Typography.Text>
                                {t('Select All')}
                            </Typography.Text>
                            <Typography.Text>
                                {`${t('Currently')} ${totalItems} ${t('Customers').toLowerCase()}`}
                            </Typography.Text>
                        </Space>
                    </Checkbox>
                </Col>

                <Col>
                    <Space size={30}>
                        <Form.Item name="name" noStyle>
                            <Input.Search
                                className="search"
                                placeholder={t('Search')}
                            />
                        </Form.Item>

                        <Button
                            className="button"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <FilterIcon />

                            {t('Filter').toUpperCase()}
                        </Button>

                        <Form.Item name="sort" noStyle>
                            <Select placeholder={t('Sort by')}>
                                <Select.Option value="date">{t('Registered Date')}</Select.Option>
                            </Select>
                        </Form.Item>

                        <Link to={addCustomerUrl}>
                            <Button
                                className="button add-button"
                                onClick={() => dispatch(actions.clearCustomerState())}
                            >
                                <NewUserIcon />

                                {t('Add new Customer')}
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>

            {isOpen && (
                <>
                    <Divider dashed />

                    <Row
                        className="advanced-filter-content"
                        gutter={30}
                    >
                        <Col lg={6}>
                            <Form.Item name="date">
                                <DatePicker
                                    placeholder={t('Registered Date')}
                                    suffixIcon={<CalendarIcon />}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item
                                name="countries"
                                noStyle
                            >
                                <CountrySelect
                                    placeholder={t('Country')}
                                    // suffixIcon={<LocationIcon />}
                                    mode="multiple"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={6}>
                            <Form.Item name="gender" noStyle>
                                <GenderSelect placeholder={true} />
                            </Form.Item>
                        </Col>

                        <Col lg={6}>
                            <Form.Item name="client_type" noStyle>
                                <Select
                                    className="select-option"
                                    placeholder={t('Client Type')}
                                >
                                    <Select.Option value={0}>{t('Individual')}</Select.Option>
                                    <Select.Option value={1}>{t('Business')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
};

export default Filters;
