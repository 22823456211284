import { useTranslation } from 'react-i18next';
import { Row, Col, Input } from 'antd';
import MUIFormItem from '@/components/MUIFormItem';

const ContactPerson = ({ validations }) => {
    const { t } = useTranslation();
    return (
        <Row gutter={30}>
            <Col xl={12} lg={24} xs={24}>
                <MUIFormItem
                    label={t('First Name')}
                    name="first_name"
                    rules={validations.first_name}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xl={12} lg={24} xs={24}>
                <MUIFormItem
                    label={t('Last Name')}
                    name="last_name"
                    rules={validations.last_name}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xl={12} lg={24} xs={24}>
                <MUIFormItem
                    label={t('Position in Hotel')}
                    name="position"
                    rules={validations.position}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xl={12} lg={24} xs={24}>
                <MUIFormItem
                    label={t('Phone Number')}
                    name="phone"
                    rules={validations.phone}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xl={12} lg={24} xs={24}>
                <MUIFormItem
                    label={t('Email')}
                    name="email"
                    rules={validations.email}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xl={12} lg={24} xs={24}>
                <MUIFormItem
                    label={t('Webiste')}
                    name="website"
                    rules={validations.website}
                >
                    <Input />
                </MUIFormItem>
            </Col>
        </Row>
    );
};

export default ContactPerson;
