import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    name: {
        name: 'Name',
        required: true,
    },
    vat_number: {
        name: 'VAT Number',
        required: true,
    },
    company_size: {
        name: 'Company Size',
        required: true,
    },
    address: {
        name: 'Address',
        required: true,
    },
    city: {
        name: 'City',
        required: true,
    },
    state: {
        name: 'State/Province',
        required: true,
    },
    country: {
        name: 'Country',
        required: true,
    },
    phone: {
        name: 'Phone',
        required: true,
    },
    company_email: {
        name: 'Email',
        required: true,
    },
    website: {
        name: 'Website',
        required: true,
    },
    timezone: {
        name: 'Timezone',
        required: true,
    },
    zip_code: {
        name: 'Zip Code',
        required: true,
    },
    currency: {
        name: 'Currency',
        required: true,
    },
    icon: {
        name: 'Agency logo',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
