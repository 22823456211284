import { useTranslation } from 'react-i18next';
import {
    Card,
    Checkbox,
    Typography,
    Form,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';

const ServicesMenu = ({
    service,
    invoiceParticipants,
    clients,
    setClients,
    currency,
}) => {
    const { t } = useTranslation();

    const deleteClient = (id) => {
        const result = clients.filter((client) => client !== id);
        setClients(result);
    };

    return (
        <Card
            bordered={false}
            title={(
                <Checkbox
                    value={service.id}
                    checked={true}
                >
                    <BlockDescription name={service.name}>
                        <Typography.Text>
                            {`${currency}${service.price} / ${t('person')}`}
                        </Typography.Text>
                    </BlockDescription>
                </Checkbox>
            )}
        >
            <div>{t('You can assign individual service')}</div>
            <div>
                <Form.Item>
                    <Checkbox.Group>
                        {invoiceParticipants?.map((participant) => (
                            <Checkbox
                                onChange={(e) => {
                                    e.target.checked
                                        ? setClients((prev) => [...prev, (service.id.toString()).concat('-', participant.id)])
                                        : deleteClient((service.id.toString()).concat('-', participant.id));
                                }}
                                key={participant.id}
                                value={(service.id.toString()).concat('-', participant.id)}
                            >
                                {participant.fullname}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                </Form.Item>
            </div>
        </Card>
    );
};

export default ServicesMenu;
