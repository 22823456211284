import {
    takeEvery,
    all,
    put,
    fork,
    call,
} from 'redux-saga/effects';

import { extractError } from '@/helpers/api';
import CustomersService from '@/services/CustomersService';
import { types, actions } from './reducer';

function* submitCustomerDetails({ data }) {
    try {
        const { id: customerId } = data;
        let response;
        if (customerId) {
            response = yield call(CustomersService.editCustomer, customerId, data);
        } else {
            response = yield call(CustomersService.addCustomer, data);
            yield put(actions.submitCustomerDetailsSucceeded(response.data));
        }
    } catch (error) {
        yield put(actions.submitCustomerDetailsFailed(extractError(error)));
    }
}

function* fetchCustomerData({ id }) {
    try {
        const response = yield call(CustomersService.getOne, id);
        yield put(actions.fetchCustomerDataSucceeded(response.data));
    } catch (error) {
        yield put(actions.fetchCustomerDataFailed(extractError(error)));
    }
}

function* watchSubmitCustomerDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CUSTOMER_DETAILS,
        submitCustomerDetails,
    );
}

function* watchFetchCustomerData() {
    yield takeEvery(
        types.REQUEST_FETCH_CUSTOMER_DATA,
        fetchCustomerData,
    );
}

function* createCustomerSaga() {
    yield all([
        fork(watchSubmitCustomerDetails),
        fork(watchFetchCustomerData),
    ]);
}

export default createCustomerSaga;
