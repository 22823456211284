import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({

    // send service request
    sendServiceRequest: ['data'],
    sendServiceRequestSucceeded: ['data'],
    sendServiceRequestFailed: ['error'],

    // clear request success
    clearRequestMessage: ['null'],
});

export default {
    // send service request
    [types.SEND_SERVICE_REQUEST]: (state) => ({
        ...state,
        collect: {
            ...state.collect,
            submitting: true,
            errors: null,
        },
    }),
    [types.SEND_SERVICE_REQUEST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        collect: {
            ...state.collect,
            submitting: true,
            message: data,
        },
    }),
    [types.SEND_SERVICE_REQUEST_FAILED]: (state, { error }) => ({
        ...state,
        collect: {
            ...state.collect,
            submitting: false,
            error,
        },
    }),

    // clear request success
    [types.CLEAR_REQUEST_MESSAGE]: (state) => ({
        ...state,
        collect: {
            ...state.collect,
            message: '',
            error: null,
        },
    }),
};

export { types, actions };
