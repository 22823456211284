import {
    useEffect,
    useMemo,
    useState,
    useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Radio,
    Input,
    Form,
    Select,
    Button,
    Space,
    Divider,
    Typography,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import { actions } from '@/redux/tour/packages/create/reducer';
import { selectors } from '@/redux/tour/packages/create/model';

const PackageDetails = ({ validations }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const options = useMemo(() => ([
        { label: t('Days'), value: 2 },
        { label: t('Hours'), value: 1 },
    ]), [t]);

    const packageTypes = useSelector(selectors.packageTypes);
    const loading = useSelector(selectors.loading);

    useEffect(() => {
        dispatch(actions.requestGetPackageTypes());
    }, [dispatch]);

    // search package type
    const onSearch = useCallback((searchValue) => {
        if (searchValue.length === 3) {
            dispatch(actions.requestGetPackageTypes({
                name: searchValue,
            }));
        }
        if (searchValue.length === 0) {
            dispatch(actions.requestGetPackageTypes());
        }
    }, [dispatch]);

    // add new type
    const [typeName, setTypeName] = useState('');

    const onTypeNameChange = (event) => {
        setTypeName(event.target.value);
    };

    const addItem = useCallback(() => {
        dispatch(actions.requestAddNewPackageType({
            name: typeName,
            category: 2,
        }));
        setTypeName('');
    }, [dispatch, typeName]);

    return (
        <>
            <Row gutter={30}>
                <Col lg={24} xs={24}>
                    <MUIFormItem
                        label={t('Package Name')}
                        name="name"
                        rules={validations.name}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xs={24} xxl={12}>
                    <MUIFormItem
                        label={t('Duration')}
                        name="duration"
                        rules={validations.duration}
                    >
                        <Input
                            suffix={(
                                <Form.Item
                                    name="duration_type"
                                    noStyle
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            )}
                        />
                    </MUIFormItem>
                </Col>
                <Col xxl={12} xs={24}>
                    <MUIFormItem
                        label={t('Package Style')}
                        name="tour_types"
                        rules={validations.style}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            defaultActiveFirstOption={false}
                            onSearch={onSearch}
                            loading={loading}
                            popupClassName="tour-style-select"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider />
                                    <Space
                                        align="center"
                                    >
                                        <Input
                                            placeholder="Type"
                                            value={typeName}
                                            onChange={onTypeNameChange}
                                        />
                                        <Button type="primary" onClick={addItem}>
                                            <Typography.Text>
                                                {t('Add')}
                                            </Typography.Text>
                                        </Button>
                                    </Space>
                                </>
                            )}
                        >
                            {packageTypes.map((type) => (
                                <Select.Option key={type.id} value={type.id}>
                                    {type.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </MUIFormItem>
                </Col>
            </Row>
        </>
    );
};

export default PackageDetails;
