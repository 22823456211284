import { createReducer } from 'reduxsauce';
import initialState from './model';

import paginationReduxHandlers, {
    types as paginationTypes,
    actions as paginationActions,
} from './pagination/reducer';

import collectReduxHandlers, {
    types as collectTypes,
    actions as collectActions,
} from './collect/reducer';

const types = {
    ...paginationTypes,
    ...collectTypes,
};

const actions = {
    ...paginationActions,
    ...collectActions,
};

export { types, actions };

export default createReducer(initialState, {
    ...paginationReduxHandlers,
    ...collectReduxHandlers,
});
