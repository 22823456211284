import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Form, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions } from '@/redux/tour/activities/create/reducer';
import { selectors } from '@/redux/tour/activities/create/model';

import DashedContainer from '@/components/DashedContainer';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import TourExtraServices from '@/components/TourExtraServices';
import Loading from '@/components/Loading';
import WidgetCategory from '@/components/WidgetCategory';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { eServicesFormat } from '@/helpers/mapping';
import { returnDeletedImages } from '@/helpers/deleteImages';
import { extractUrlFromFile } from '@/helpers/upload';
import { dateFormat } from '@/helpers/dateFormat';

import ImagesUpload from './ImagesUpload';
import ActivityDetails from './ActivityDetails';
import ActivityLocation from './ActivityLocation';
import baseValidations from './validations';

const formFields = [
    'images',
    'name',
    'price',
    'availability',
    'description',
    'address',
    'latitude',
    'longitude',
];

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const formatExtraServicesForSubmit = (services) => services.map((service) => ({
    name: service.service_name,
    price: service.price_type === true ? 0 : service.price,
}));

const formatDataToSubmit = (id, values, initialImages) => ({
    id,
    name: values.name,
    price: values.price,
    start_date: values.availability[0].format(dateFormat),
    end_date: values.availability[1].format(dateFormat),
    description: values.description,
    address: values.address,
    latitude: values.latitude,
    longitude: values.longitude,
    widgets_category: values.widgets_category,
    extra_services: values.extra_services
        ? formatExtraServicesForSubmit(values.extra_services)
        : [],
    images: values.images
        ? values.images.fileList?.map(extractUrlFromFile)
        : [],
    deleted_images: returnDeletedImages(initialImages, values.images?.fileList),
});

const GeneralDetails = ({
    form,
    id,
    isEdit,
    regStep,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const validations = useTranslatedValidationConfig(baseValidations);

    const timeAndDuration = useSelector(selectors.timeAndDuration);
    const generalInfo = useSelector(selectors.generalInfo);

    const initialImages = generalInfo?.images;

    // add mode
    const onAdd = useCallback((values) => {
        const data = formatDataToSubmit(id, values, initialImages);
        const step = 1;
        const completedStep = 0;
        dispatch(actions.requestSubmitActivityGeneralDetails(
            data,
            step,
            completedStep,
        ));
    }, [dispatch, id, initialImages]);

    // edit mode
    const onEdit = useCallback((values) => {
        const data = formatDataToSubmit(id, values, initialImages);
        const step = 1;
        const completedStep = 1;
        dispatch(actions.requestSubmitActivityGeneralDetails(
            data,
            step,
            completedStep,
        ));
    }, [dispatch, id, initialImages]);

    const error = useSelector(selectors.errors);

    const formattedErrors = {
        ...error,
        availability: error?.start_date || error?.end_date,
    };
    const errors = error === null ? null : formattedErrors;

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => {
        if (generalInfo) {
            return {
                name: generalInfo.name,
                price: generalInfo.price,
                availability: [
                    moment(generalInfo.start_date),
                    moment(generalInfo.end_date),
                ],
                description: generalInfo.description,
                address: generalInfo.address,
                latitude: generalInfo.latitude,
                longitude: generalInfo.longitude,
                widgets_category: generalInfo
                    .widget_categories.map((category) => category.id),
                extra_services: generalInfo.extra_services
                    ? eServicesFormat(generalInfo.extra_services)
                    : [],
                images: generalInfo.images.map((image) => (
                    {
                        id: image.id,
                        url: image.path,
                        thumbUrl: image.path,
                    }
                )),
            };
        } else {
            return null;
        }
    }, [generalInfo]);

    if (id && !initialData) {
        return <Loading />;
    }

    return (
        <Form
            form={form}
            id="activity-general-details-form"
            layout="vertical"
            requiredMark={false}
            onFinish={
                (isEdit && regStep > 1)
                || (!isEdit && id)
                || (isEdit && timeAndDuration.duration_from !== null)
                    ? onEdit
                    : onAdd
            }
            initialValues={initialData || {}}
        >
            <HeaderWithHelp
                title="General Details"
                question={question}
                answer={answer}
            />

            <Divider />

            <Form.Item>
                <ActivityDetails validations={validations} />
            </Form.Item>

            <DashedContainer title="Location">
                <Form.Item>
                    <ActivityLocation
                        validations={validations}
                        lat={generalInfo?.latitude}
                        lng={generalInfo?.longitude}
                        form={form}
                    />
                </Form.Item>
            </DashedContainer>

            <DashedContainer title="Widget Category">
                <Form.Item>
                    <WidgetCategory />
                </Form.Item>
            </DashedContainer>

            <DashedContainer>
                <span>{t('Extra Services')}</span>
                <TourExtraServices
                    name="extra_services"
                    validations={validations}
                    checkboxText="The price is inclusive to the activity"
                />
            </DashedContainer>

            <Form.Item>
                <ImagesUpload />
            </Form.Item>
        </Form>
    );
};

export default GeneralDetails;
