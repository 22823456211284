import {
    call,
    takeEvery,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { setToken, setRefreshToken } from '@/helpers/auth';
import { extractError } from '@/helpers/api';
import RegistrationService from '@/services/RegistrationService';
import { types, actions } from './reducer';

function* registerBaseUser({ data }) {
    try {
        const response = yield call(
            RegistrationService.registerStepOne,
            data,
        );
        const { token, refresh_token: refreshToken } = response.data;

        setToken(token);
        setRefreshToken(refreshToken);

        yield put(actions.registerBaseUserSucceeded(data));
    } catch (error) {
        const message = extractError(error);

        if (message === 'Passwords do not match') {
            yield put(actions.registerBaseUserFailed({
                password_confirm: 'Password and confirm password do not match',
            }));
        }

        yield put(actions.registerBaseUserFailed(message));
    }
}

function* watchRegisterBaseUser() {
    yield takeEvery(types.REQUEST_REGISTER_BASE_USER, registerBaseUser);
}

function* baseUserSaga() {
    yield all([
        fork(watchRegisterBaseUser),
    ]);
}

export default baseUserSaga;
