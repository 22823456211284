import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Space,
    Typography,
    Switch,
    Input,
    Select,
    Form,
} from 'antd';
import { LocationIcon, SortIcon } from '@/assets/images/icons/screens';

const Filters = ({
    myCollection,
    setMyCollection,
    filters,
    setFilters,
    data,
}) => {
    const { t } = useTranslation();

    return (
        <Form
            initialValues={filters}
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                setFilters(name[0], value);
            }}
        >
            <Row gutter={[0, 30]} className="worldwide-supplier-filters">
                <Col xs={24}>
                    <Row gutter={[25, 25]}>
                        <Col xs={24} md={5}>
                            <Form.Item name="service_type" noStyle>
                                <Select allowClear placeholder={t('Filter by Service Type')}>
                                    <Select.Option value={1}>{t('Tour Trip')}</Select.Option>
                                    <Select.Option value={2}>{t('Tour Package')}</Select.Option>
                                    <Select.Option value={3}>{t('Cruise')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={5}>
                            <Form.Item name="location" noStyle>
                                <Input
                                    placeholder={t('Filter by Service Location')}
                                    suffix={<LocationIcon />}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={5}>
                            <Form.Item name="status" noStyle>
                                <Select allowClear placeholder={t('Filter by Status')}>
                                    <Select.Option value={1}>{t('Collected')}</Select.Option>
                                    <Select.Option value={2}>{t('Pending')}</Select.Option>
                                    <Select.Option value={5}>{t('Force Request')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={9}>
                            <Form.Item name="name" noStyle>
                                <Input.Search
                                    placeholder={t('Search with keyword (agency name, product name...)')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24}>
                    <Row justify="space-between" align="middle">
                        <Col className="switch-collection">
                            <Space size={25}>
                                <Typography.Text className={clsx(!myCollection && 'color-blue')}>
                                    {t('All')}
                                </Typography.Text>
                                <Form.Item name="type" noStyle>
                                    <Switch
                                        checked={myCollection}
                                        onChange={() => setMyCollection(!myCollection)}
                                    />
                                </Form.Item>
                                <Typography.Text className={clsx(myCollection && 'color-blue')}>
                                    {t('My Collection')}
                                </Typography.Text>
                            </Space>
                        </Col>
                        <Col className="sort-collection">
                            <Space size={50}>
                                <Typography.Text>
                                    {`${data.length} ${data.length === 1 ? t('tour found') : t('tours found')}`}
                                </Typography.Text>
                                <Form.Item noStyle>
                                    <Select
                                        className="sort-tour"
                                        placeholder={t('Sort by')}
                                        suffixIcon={<SortIcon />}
                                    >
                                        <Select.Option value="price">{t('Price')}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default Filters;
