import {
    TourTripIcon,
    TourPackageIcon,
    CruiseTourIcon,
    TourActivityIcon,
} from '@/assets/images/icons/screens';

export default [
    {
        id: 1,
        image: <TourTripIcon />,
        title: 'Tour Trip',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new tour',
        viewButtonText: 'View tours',
        url: 'tours/trip',
        type: 'trip',
    },
    {
        id: 2,
        image: <TourPackageIcon />,
        title: 'Tour Packages',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new tour',
        viewButtonText: 'View tours',
        url: 'tours/packages',
        type: 'package',
    },
    {
        id: 3,
        image: <CruiseTourIcon />,
        title: 'Cruise Tour',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new tour',
        viewButtonText: 'View tours',
        url: 'tours/cruise',
        type: 'cruise',
    },
    {
        id: 4,
        image: <TourActivityIcon />,
        title: 'Activities',
        description: 'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.',
        addButtonText: 'Add new activity',
        viewButtonText: 'View activities',
        url: 'tours/activities',
        type: 'activity',
    },
];
