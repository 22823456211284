import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    activity: null,
    partner: null,
    timeList: [],
    timeLoading: false,
    activityDate: null,
    loading: false,
    error: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.activities.view,
    model,
);

export default model;
