import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WorldMap } from 'react-svg-worldmap';
import Carousel from 'react-elastic-carousel';
import {
    Card,
    Row,
    Col,
    List,
} from 'antd';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import { actions } from '@/redux/dashboard/reducer';

const formatData = (data) => data.map((item) => ({
    country: item.short,
    value: item.total,
}));

const Map = ({ currency }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const data = useSelector((state) => state.dashboard.salesByLocation);
    const renamedData = formatData(data);

    useEffect(() => {
        dispatch(actions.requestGetSalesByLocation());
    }, []);

    const breakPoints = [
        { width: 767, itemsToShow: 1 },
        { width: 1369, itemsToShow: 4 },
        { width: 1710, itemsToShow: 4 },
    ];

    return (
        <Card
            title={(
                <>
                    <span className="sales-graphs_header_text">{t('Sales by Locations')}</span>
                    <HeaderWithHelp
                        question={t('Question')}
                        answer={t('Answer')}
                    />
                </>
            )}
            width={500}
        >
            <Row>
                <Col
                    lg={14}
                    md={18}
                    sm={24}
                    xs={24}
                >
                    <WorldMap
                        color="#24DCAE"
                        size="lg"
                        data={renamedData}
                    />
                </Col>
                <Col
                    lg={10}
                    md={6}
                    sm={24}
                    xs={24}
                >
                    <Carousel
                        breakPoints={breakPoints}
                        showArrows={false}
                        enableMouseSwipe={true}
                    >
                        <List
                            size="small"
                            bordered={false}
                            dataSource={data}
                            renderItem={(item) => (
                                <List.Item>
                                    <Row justify="space-between">
                                        <Col xs={16}>{item.country}</Col>
                                        <Col xs={8}>{`${currency} ${item.total}`}</Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                        <List
                            size="small"
                            bordered={false}
                            dataSource={data}
                            renderItem={(item) => (
                                <List.Item>
                                    <Row justify="space-between">
                                        <Col xs={12}>{item.country}</Col>
                                        <Col xs={12}>{item.total}</Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    </Carousel>
                </Col>
            </Row>
        </Card>
    );
};

export default Map;
