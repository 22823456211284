import { createActions } from 'reduxsauce';
import { upseartItem } from '@/helpers/CRUD';

const { Types: types, Creators: actions } = createActions({

    // go tp step
    goToThirdStep: [null],

    // submit first step
    requestSubmitAgencyAccount: ['data'],
    submitAgencyAccountSucceeded: ['data'],
    submitAgencyAccountFailed: ['errors'],

    // submit second step
    requestSubmitAgencyInfo: ['data'],
    submitAgencyInfoSucceeded: ['data'],
    submitAgencyInfoFailed: ['errors'],

    // submit third step
    requestSubmitAgencyDistribution: ['data'],
    submitAgencyDistributionSucceeded: ['message', 'newData'],
    submitAgencyDistributionFailed: ['errors'],

    // get all agency services
    requestGetAllAgencyServices: null,
    getAllAgencyServicesSucceeded: ['data'],
    getAllAgencyServicesFailed: ['errors'],

    // get checked services
    requestGetCheckedServices: ['id'],
    getCheckedServicesSucceeded: ['agencyN', 'agencyServices'],
    getCheckedServicesFailed: ['errors'],

    // get agency list
    requestGetAgencyList: ['filters'],
    getAgencyListSucceeded: ['data'],
    getAgencyListFailed: ['errors'],

    // clear state
    clearAgencyState: null,
});

export default {
    // go to step
    [types.GO_TO_THIRD_STEP]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 2,
        },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_AGENCY_ACCOUNT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_AGENCY_ACCOUNT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            step: 1,
            submitting: false,
            agencyAccount: data,
        },
    }),
    [types.SUBMIT_AGENCY_ACCOUNT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_AGENCY_INFO]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_AGENCY_INFO_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            step: 2,
            submitting: false,
            agencyInfo: data,
        },
    }),
    [types.SUBMIT_AGENCY_INFO_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_AGENCY_DISTRIBUTION]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_AGENCY_DISTRIBUTION_SUCCEEDED]: (state, { message, newData }) => ({
        ...state,
        data: upseartItem(state.data, newData),
        create: {
            ...state.create,
            step: 2,
            submitting: false,
            distributionMessage: message,
        },
    }),
    [types.SUBMIT_AGENCY_DISTRIBUTION_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // get all agency services
    [types.REQUEST_GET_ALL_AGENCY_SERVICES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_ALL_AGENCY_SERVICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            allAgencyServices: data,

        },
    }),
    [types.GET_ALL_AGENCY_SERVICES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // get checked services
    [types.REQUEST_GET_CHECKED_SERVICES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_CHECKED_SERVICES_SUCCEEDED]: (state, { agencyN, agencyServices }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            agencyName: agencyN,
            checkedServices: agencyServices,
        },
    }),
    [types.GET_CHECKED_SERVICES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // get agency list
    [types.REQUEST_GET_AGENCY_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_AGENCY_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            agencyList: data,

        },
    }),
    [types.GET_AGENCY_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_AGENCY_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            agencyAccount: null,
            agencyInfo: null,
            distributionMessage: '',
            checkedServices: [],
        },
    }),
};

export { types, actions };
