import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Typography,
    Divider,
} from 'antd';
import clsx from 'clsx';
import { CalendarIcon, GroupIcon, LocationIcon } from '@/assets/images/icons/screens';

const SearchBox = ({
    leftCol = 19,
    rightCol = 5,
    checkedId,
    checkedServices,
    dynamicMenu = false,
    menuSpaceSize = 5,
    formSpaceSize = 1,
    buttonClassName,
}) => {
    const { t } = useTranslation();

    return (
        <div className="search-box-wrapper">
            <Space
                size={menuSpaceSize}
                className={clsx('search-box-menu', checkedId === 1 && 'hide-menu')}
            >
                {dynamicMenu
                    ? (
                        <>
                            {checkedServices.length !== 0
                                ? checkedServices.map((service) => (
                                    <Typography.Text key={service.id}>
                                        {service.name}
                                    </Typography.Text>
                                ))
                                : <Typography.Text>{t('Accommodation')}</Typography.Text>}
                        </>
                    )
                    : (
                        <>
                            <Typography.Text>
                                {t('Accommodation')}
                            </Typography.Text>
                            {/* <Typography.Text>
                                {t('Flights')}
                            </Typography.Text> */}
                            <Typography.Text>
                                {t('Tours')}
                            </Typography.Text>
                            {/* <Typography.Text>
                                {t('Transfers')}
                            </Typography.Text> */}
                        </>
                    )}
            </Space>
            <Row
                className={clsx('search-box-form', checkedId === 2 && 'add-border-radius')}
                justify="space-between"
                align="middle"
            >
                <Col span={leftCol}>
                    <Space
                        size={formSpaceSize}
                        split={<Divider type="vertical" />}
                    >
                        <div>
                            <LocationIcon />
                            <Typography.Text>
                                {t('Location')}
                            </Typography.Text>
                        </div>
                        <div>
                            <CalendarIcon />
                            <Typography.Text>
                                {`${t('Check in')} - ${t('Check out')}`}
                            </Typography.Text>
                        </div>
                        <div>
                            <GroupIcon />
                            <Typography.Text>
                                {t('Guests')}
                            </Typography.Text>
                        </div>
                    </Space>
                </Col>
                <Col
                    className={clsx('search-box-button', buttonClassName)}
                    span={rightCol}
                >
                    {t('Search')}
                </Col>
            </Row>
        </div>
    );
};

export default SearchBox;
