import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('customers');

const actions = {
    requestFetchCustomers: baseActions.request,
    fetchCustomersSucceeded: baseActions.succeeded,
    fetchCustomersFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
