import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    DatePicker,
    Select,
    Divider,
    Space,
    Button,
    Typography,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import CustomTextArea from '@/components/TextArea';
import { CalendarIcon, LocationIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/tour/cruise/create/reducer';
import { selectors } from '@/redux/tour/cruise/create/model';

const CruiseDetails = ({ validations }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cruiseTypes = useSelector(selectors.cruiseTypes);
    const loading = useSelector(selectors.loading);

    useEffect(() => {
        dispatch(actions.requestGetCruiseTypes());
    }, [dispatch]);

    // search cruise type
    const onSearch = useCallback((searchValue) => {
        if (searchValue.length === 3) {
            dispatch(actions.requestGetCruiseTypes({
                name: searchValue,
            }));
        }
        if (searchValue.length === 0) {
            dispatch(actions.requestGetCruiseTypes());
        }
    }, [dispatch]);

    // add new type
    const [typeName, setTypeName] = useState('');

    const onTypeNameChange = (event) => {
        setTypeName(event.target.value);
    };

    const addItem = useCallback(() => {
        dispatch(actions.requestAddNewCruiseType({
            name: typeName,
            category: 3,
        }));
        setTypeName('');
    }, [dispatch, typeName]);

    return (
        <>
            <Row gutter={30} className="cruise-details">
                <Col lg={24} xs={24}>
                    <MUIFormItem
                        label={t('Cruise Name')}
                        name="name"
                        rules={validations.name}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xxl={12} xs={24}>
                    <MUIFormItem
                        label={t('Cruise Line')}
                        name="line"
                        rules={validations.line}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xxl={12} xs={24}>
                    <MUIFormItem
                        label={t('Cruise Style')}
                        name="types"
                        rules={validations.style}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            defaultActiveFirstOption={false}
                            onSearch={onSearch}
                            loading={loading}
                            popupClassName="tour-style-select"
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider />
                                    <Space
                                        align="center"
                                    >
                                        <Input
                                            placeholder="Type"
                                            value={typeName}
                                            onChange={onTypeNameChange}
                                        />
                                        <Button type="primary" onClick={addItem}>
                                            <Typography.Text>
                                                {t('Add')}
                                            </Typography.Text>
                                        </Button>
                                    </Space>
                                </>
                            )}
                        >
                            {cruiseTypes.map((type) => (
                                <Select.Option key={type.id} value={type.id}>
                                    {type.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </MUIFormItem>
                </Col>

                <Col xxl={12} xs={24}>
                    <MUIFormItem
                        label={t('Start Date & Time')}
                        name="start_date"
                        rules={validations.startDate}
                    >
                        <DatePicker
                            showTime={{ format: 'HH:mm a' }}
                            suffixIcon={<CalendarIcon />}
                        />
                    </MUIFormItem>
                </Col>

                <Col xxl={12} xs={24}>
                    <MUIFormItem
                        label={t('End Date & Time')}
                        name="end_date"
                        rules={validations.endDate}
                    >
                        <DatePicker
                            showTime={{ format: 'HH:mm a' }}
                            suffixIcon={<CalendarIcon />}
                        />
                    </MUIFormItem>
                </Col>

                <Col xxl={12} xs={24}>
                    <MUIFormItem
                        label={t('Departure Port / Location')}
                        name="departure_place"
                        rules={validations.departurePort}
                    >
                        <Input suffix={<LocationIcon />} />
                    </MUIFormItem>
                </Col>

                <Col xxl={12} xs={24}>
                    <MUIFormItem
                        label={t('Arrival Port / Location')}
                        name="arrival_place"
                        rules={validations.arrivalPort}
                    >
                        <Input suffix={<LocationIcon />} />
                    </MUIFormItem>
                </Col>
            </Row>

            <CustomTextArea
                name="description"
                placeholder={t('Cruise Description')}
                rules={validations.description}
            />
        </>
    );
};

export default CruiseDetails;
