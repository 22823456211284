import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Badge, Card, Button, Typography,
} from 'antd';
import { actions as tripActions } from '@/redux/tour/trip/create/reducer';
import { actions as packageActions } from '@/redux/tour/packages/create/reducer';
import { actions as cruiseActions } from '@/redux/tour/cruise/create/reducer';
import { actions as activityActions } from '@/redux/tour/activities/create/reducer';
import { actions as accommodationActions } from '@/redux/accommodation/hotel/create/reducer';

import Cover from './Cover';

const TourCard = ({ data, type }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const hasTours = data.count > 0;
    const key = data.id;
    const goToAddTour = () => {
        if (type === 'tour') {
            if (key === 1) {
                dispatch(tripActions.clearTripState());
            } else if (key === 2) {
                dispatch(packageActions.clearPackageState());
            } else if (key === 3) {
                dispatch(cruiseActions.clearCruiseState());
            } else {
                dispatch(activityActions.clearActivityState());
            }
        } else {
            dispatch(accommodationActions.clearHotelState());
        }
        history.push(`/app/${data.url}/add`);
    };

    return (
        <Badge
            count={data.count}
            showZero
            color={hasTours ? '#FCB151' : '#E7EBEF'}
        >
            <Card
                className="tour-card"
                cover={(<Cover image={data.image} />)}
            >
                <Card.Meta
                    title={t(data.title).toUpperCase()}
                    description={t(data.description)}
                />
                <div>
                    <Button
                        className="button btn-blue"
                        onClick={goToAddTour}
                    >
                        {t(data.addButtonText).toUpperCase()}
                    </Button>
                    <Button
                        className="button btn-grey"
                        type="text"
                        onClick={() => history.push(`/app/${data.url}`)}
                    >
                        <Typography.Text underline>
                            {t(data.viewButtonText).toUpperCase()}
                        </Typography.Text>
                    </Button>
                </div>
            </Card>
        </Badge>
    );
};

export default TourCard;
