import { Switch, Route, Redirect } from 'react-router-dom';

import ViewAll from './ViewAll';
import ViewOneService from './ViewOneService';

export const routes = [
    {
        name: 'ViewAll',
        url: '/',
        component: ViewAll,
    },
    {
        name: 'ViewOneService',
        url: '/:type/:id',
        component: ViewOneService,
    },

];

const WorldwideSupplier = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/b2b-partners/worldwide-supplier${route.url}`}
                    exact={true}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default WorldwideSupplier;
