import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import CustomersService from '@/services/CustomersService';
import { types, actions } from './reducer';

function* viewCustomer({ id }) {
    try {
        const res = yield call(CustomersService.getOne, id);
        yield put(actions.viewCustomerSucceeded(res.data));
    } catch (error) {
        yield put(actions.viewCustomerFailed(extractError(error)));
    }
}

function* watchViewCustomer() {
    yield takeEvery(
        types.REQUEST_VIEW_CUSTOMER,
        viewCustomer,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewCustomer),
    ]);
}

export default viewSaga;
