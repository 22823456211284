import { call, put } from 'redux-saga/effects';
import { createActions } from 'reduxsauce';
// import pagination from './pagination';
import { createEntityCrudActionTypes } from './redux';
import { camelToSnake, firstToUpper } from './string';

export const replaceUpdatedItem = (items, updatedItem, identityKey = 'id') => items.map(
    (item) => (
        (item[identityKey] === updatedItem[identityKey])
            ? updatedItem
            : item
    ),
);

export const removeSelectedItem = (items, deletedItemId, identityKey = 'id') => items.filter((item) => item[identityKey] !== deletedItemId);

export const upseartItem = (existingItems, newItem) => {
    const itemsAlreadyExists = existingItems.findIndex(
        (existingItem) => existingItem.id === newItem.id,
    ) !== -1;
    if (itemsAlreadyExists) {
        return replaceUpdatedItem(existingItems, newItem);
    } else {
        return existingItems.concat(newItem);
    }
};
const CRUD = (entity) => {
    // const {
    //     // types: paginationTypes,
    //     // mappedTypes: paginationMappedTypes,
    //     // model: paginationModel,
    //     // actions: paginationActions,
    //     // reducer: paginationReducer,
    //     // saga: paginationSaga,
    // } = pagination(entity);

    const actionCreatorNames = createEntityCrudActionTypes(
        firstToUpper(entity),
    );

    const { Types, Creators } = createActions({
        [actionCreatorNames.create.request]: ['data'],
        [actionCreatorNames.create.succeeded]: ['data'],
        [actionCreatorNames.create.failed]: ['error'],
        [actionCreatorNames.update.request]: ['data'],
        [actionCreatorNames.update.succeeded]: ['data'],
        [actionCreatorNames.update.failed]: ['error'],
        [actionCreatorNames.delete.request]: ['id'],
        [actionCreatorNames.delete.succeeded]: ['id'],
        [actionCreatorNames.delete.failed]: ['error'],
    });

    const types = {
        // ...paginationTypes,
        ...Types,
    };

    const mappedTypes = {
        // fetch: paginationMappedTypes,
        create: {
            request: camelToSnake(actionCreatorNames.create.request),
            succeeded: camelToSnake(actionCreatorNames.create.succeeded),
            failed: camelToSnake(actionCreatorNames.create.failed),
        },
        update: {
            request: camelToSnake(actionCreatorNames.update.request),
            succeeded: camelToSnake(actionCreatorNames.update.succeeded),
            failed: camelToSnake(actionCreatorNames.update.failed),
        },
        delete: {
            request: camelToSnake(actionCreatorNames.delete.request),
            succeeded: camelToSnake(actionCreatorNames.delete.succeeded),
            failed: camelToSnake(actionCreatorNames.delete.failed),
        },
    };

    const actions = {
        // fetch: paginationActions,
        create: {
            request: Creators[actionCreatorNames.create.request],
            succeeded: Creators[actionCreatorNames.create.succeeded],
            failed: Creators[actionCreatorNames.create.failed],
        },
        update: {
            request: Creators[actionCreatorNames.update.request],
            succeeded: Creators[actionCreatorNames.update.succeeded],
            failed: Creators[actionCreatorNames.update.failed],
        },
        delete: {
            request: Creators[actionCreatorNames.delete.request],
            succeeded: Creators[actionCreatorNames.delete.succeeded],
            failed: Creators[actionCreatorNames.delete.failed],
        },
    };

    const model = {
        // ...paginationModel,
        creatingLoading: false,
        creatingError: null,
        updatingLoading: false,
        updatingError: null,
        deletingLoading: false,
        deletingError: null,
    };

    const reducer = {
        // fetch
        // ...paginationReducer,
        // create
        [mappedTypes.create.request]: (state) => ({
            ...state,
            creatingLoading: true,
            creatingError: null,
        }),
        [mappedTypes.create.succeeded]: (state, action) => ({
            ...state,
            data: state.data.concat(action.data),
            totalItems: state.totalItems + 1,
            creatingLoading: false,
            creatingError: null,
        }),
        [mappedTypes.create.failed]: (state, action) => ({
            ...state,
            creatingLoading: false,
            creatingError: action.error,
        }),
        // update
        [mappedTypes.update.request]: (state) => ({
            ...state,
            updatingLoading: true,
            updatingError: null,
        }),
        [mappedTypes.update.succeeded]: (state, action) => ({
            ...state,
            data: action.data
                ? replaceUpdatedItem(state.data, action.data)
                : state.data,
            updatingLoading: false,
            updatingError: null,
        }),
        [mappedTypes.update.failed]: (state, action) => ({
            ...state,
            updatingLoading: false,
            updatingError: action.error,
        }),
        // delete
        [mappedTypes.delete.request]: (state) => ({
            ...state,
            deletingLoading: true,
            deletingError: null,
        }),
        [mappedTypes.delete.succeeded]: (state, action) => ({
            ...state,
            data: removeSelectedItem(state.data, action.id),
            totalItems: state.totalItems - 1,
            deletingLoading: false,
            deletingError: null,
        }),
        [mappedTypes.delete.failed]: (state, action) => ({
            ...state,
            deletingLoading: false,
            deletingError: action.error,
        }),
    };

    const saga = (Service) => ({
        // read: paginationSaga(Service),
        * create({ data }) {
            try {
                const response = yield call(Service.create, data);
                yield put(actions.create.succeeded(response.data));
            } catch (error) {
                yield put(actions.create.failed(error.message));
            }
        },
        * update(data) {
            try {
                const response = yield call(Service.updateOne, data);
                yield put(actions.update.succeeded(response.data));
            } catch (error) {
                yield put(actions.update.failed(error.message));
            }
        },
        * delete(id) {
            try {
                yield call(Service.deleteOne, id);
                yield put(actions.delete.succeeded(id));
            } catch (error) {
                yield put(actions.delete.failed(error.message));
            }
        },
    });

    return {
        types, mappedTypes, model, actions, reducer, saga,
    };
};

export default CRUD;
