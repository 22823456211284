import { combineReducers } from 'redux';
import authentication from './authentication/reducer';
import dashboard from './dashboard/reducer';
import tour from './tour/reducer';
import accommodation from './accommodation/reducer';
import invoice from './invoice/reducer';
import customers from './customers/reducer';
import employees from './employees/reducer';
import flights from './flights/reducer';
import integrations from './integrations/reducer';
import b2b from './b2b/reducer';
import reports from './reports/reducer';
import settings from './settings/reducer';
import maxprice from './maxprice/reducer';
import userprofile from './userprofile/reducer';
import countrylist from './countrylist/reducer';

const appReducer = combineReducers({
    authentication,
    dashboard,
    tour,
    accommodation,
    invoice,
    customers,
    employees,
    flights,
    integrations,
    b2b,
    reports,
    settings,
    maxprice,
    userprofile,
    countrylist,
});

export default appReducer;
