import addServicesListReduxHandlers, {
    types as addServicesListTypes,
    actions as addServicesListActions,
} from './addServicesList/reducer';

import agencyDetailsReduxHandlers, {
    types as agencyDetailsTypes,
    actions as agencyDetailsActions,
} from './agencyDetails/reducer';

import verifyEmailReduxHandlers, {
    types as verifyEmailTypes,
    actions as verifyEmailActions,
} from './verifyEmail/reducer';

import verifyPhoneReduxHandlers, {
    types as verifyPhoneTypes,
    actions as verifyPhoneActions,
} from './verifyPhone/reducer';

import baseUserReduxHandlers, {
    types as baseUserTypes,
    actions as baseUserActions,
} from './baseUser/reducer';

const types = {
    ...addServicesListTypes,
    ...agencyDetailsTypes,
    ...verifyEmailTypes,
    ...verifyPhoneTypes,
    ...baseUserTypes,
};

const actions = {
    ...addServicesListActions,
    ...agencyDetailsActions,
    ...verifyEmailActions,
    ...verifyPhoneActions,
    ...baseUserActions,
};

export default {
    ...addServicesListReduxHandlers,
    ...agencyDetailsReduxHandlers,
    ...verifyEmailReduxHandlers,
    ...verifyPhoneReduxHandlers,
    ...baseUserReduxHandlers,
};

export { types, actions };
