import { useMemo, useCallback } from 'react';
import { Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { inclExclFormat } from '@/helpers/mapping';
import { actions } from '@/redux/tour/trip/create/reducer';
import { selectors } from '@/redux/tour/trip/create/model';

import Inclusions from './Inclusions';
import Exclusions from './Exclusions';

const formFields = ['inclusions', 'exclusions'];

const InclusionsExclusions = ({ form, isEdit, regStep }) => {
    const dispatch = useDispatch();

    const onFinish = useCallback((values) => {
        const data = {
            inclusions: values.inclusions,
            exclusions: values.exclusions,
        };
        if (isEdit && regStep === 3) {
            const step = 3;
            const completedStep = 3;
            dispatch(actions.requestSubmitTripInclusionsExclusions(
                data,
                step,
                completedStep,
            ));
        } else {
            const step = 3;
            const completedStep = 2;
            dispatch(actions.requestSubmitTripInclusionsExclusions(
                data,
                step,
                completedStep,
            ));
        }
    }, [dispatch]);

    const inclusions = useSelector(selectors.inclusions);
    const exclusions = useSelector(selectors.exclusions);
    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => {
        return {
            inclusions: inclExclFormat(inclusions),
            exclusions: inclExclFormat(exclusions),
        };
    }, [inclusions, exclusions]);

    return (
        <Form
            id="trip-inclusion-exclusion-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={initialData || {}}
        >
            <Inclusions
                initialInclusions={initialData?.inclusions}
            />

            <Exclusions
                initialExclusions={initialData?.exclusions}
            />
        </Form>
    );
};

export default InclusionsExclusions;
