import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Row,
    Col,
    Button,
    DatePicker,
    Select,
    Form,
} from 'antd';
import {
    CompareIcon,
    LocationIcon,
    CalendarIcon,
} from '@/assets/images/icons/screens';
import { actions as inHouseActions } from '@/redux/reports/inhouse/reducer';
import { actions as b2bActions } from '@/redux/reports/b2b/reducer';
import CountrySelect from '@/components/CountrySelect';
import CompareReports from './CompareReports';

const Filters = ({ type, setFilters, currency }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [modalVisible, setModalVisible] = useState(false);
    const [startMonth, setStartMonth] = useState(moment().subtract(1, 'months').format('YYYY-MM'));
    const [endMonth, setEndMonth] = useState(moment().format('YYYY-MM'));

    // in house reports
    const total = useSelector((state) => state.reports.inhouse.total);
    const cost = useSelector((state) => state.reports.inhouse.cost);
    const earn = useSelector((state) => state.reports.inhouse.earn);
    const status = useSelector((state) => state.reports.inhouse.status);
    const customer = useSelector((state) => state.reports.inhouse.customer);
    const inHouseLoading = useSelector((state) => state.reports.inhouse.loading);

    // b2b reports
    const firstMonthReport = useSelector((state) => state.reports.b2b.b2bReports);
    const b2bLoading = useSelector((state) => state.reports.b2b.loading);
    const {
        first_month: firstMonth,
        second_month: secondMonth,
    } = firstMonthReport !== null && firstMonthReport;

    useEffect(() => {
        const data = {
            start_month: startMonth,
            end_month: endMonth,
        };
        if (type === 'in-house' && modalVisible) {
            dispatch(inHouseActions.requestCompareInHouseReports(data));
        }
        if (type === 'b2b' && modalVisible) {
            dispatch(b2bActions.requestCompareB2bReports(data));
        }
    }, [dispatch, startMonth, endMonth, type, modalVisible]);

    return (
        <Form
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                setFilters(name[0], value);
            }}
            className="filters"
        >
            <Row gutter={[25, 25]}>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form.Item name="date" noStyle>
                        <DatePicker.RangePicker
                            placeholder={[t('Stats From'), t('To')]}
                            separator="-"
                            suffixIcon={<CalendarIcon />}
                            popupClassName="filter-calendar"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={7} lg={5}>
                    <Form.Item
                        name={type === 'in-house' ? 'service_id' : 'partner_type'}
                        noStyle
                    >
                        <Select
                            placeholder={type === 'in-house' ? t('All Services') : t('All Type of Partners')}
                            allowClear
                        >
                            {type === 'in-house' ? (
                                <>
                                    <Select.Option value={1}>{t('Tour Trip')}</Select.Option>
                                    <Select.Option value={2}>{t('Tour Package')}</Select.Option>
                                    <Select.Option value={3}>{t('Cruise')}</Select.Option>
                                </>
                            ) : (
                                <>
                                    <Select.Option value={0}>{t('Vendor')}</Select.Option>
                                    <Select.Option value={1}>{t('Supplier')}</Select.Option>
                                </>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={9} lg={8}>
                    <Form.Item name="country_id" noStyle>
                        <CountrySelect
                            placeholder={type === 'in-house' ? t('Customers Origin / Country') : t('Partners Origin / Country')}
                            suffixIcon={<LocationIcon />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={24} lg={5}>
                    <Button
                        className="compare-button"
                        onClick={() => setModalVisible(true)}
                    >
                        <CompareIcon />
                        {t('Compare').toUpperCase()}
                    </Button>
                    <CompareReports
                        type={type}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        startMonth={startMonth}
                        setStartMonth={setStartMonth}
                        endMonth={endMonth}
                        setEndMonth={setEndMonth}
                        firstMonth={firstMonth}
                        secondMonth={secondMonth}
                        total={total}
                        cost={cost}
                        earn={earn}
                        status={status}
                        customer={customer}
                        currency={currency}
                        inHouseLoading={inHouseLoading}
                        b2bLoading={b2bLoading}
                    />
                </Col>
            </Row>
        </Form>
    );
};

export default Filters;
