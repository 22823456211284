import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // fetch specified package
    requestViewPackage: ['id'],
    viewPackageSucceeded: [
        'packageData',
        'roomType',
    ],
    viewPackageFailed: ['error'],

    // search for rooms
    requestFetchPackageRooms: ['id', 'filters', 'isFetchingMore'],
    fetchPackageRoomsSucceeded: ['rooms'],
    fetchPackageRoomsFailed: ['error'],

    // fetch more rooms
    fetchMorePackageRooms: ['filters'],
    fetchMorePackageRoomsSucceeded: ['rooms'],
    fetchMorePackageRoomsFailed: ['error'],

    // save filters
    savePackageFilterData: ['filters'],

    // clear package view state
    clearPackageViewState: ['null'],
});

export default {
    // fetch specified package
    [types.REQUEST_VIEW_PACKAGE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_PACKAGE_SUCCEEDED]: (state, { packageData, roomType }) => ({
        ...state,
        view: {
            ...state.view,
            packageDetails: packageData,
            roomTypeList: roomType,
            loading: false,
        },
    }),
    [types.VIEW_PACKAGE_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // search for rooms
    [types.REQUEST_FETCH_PACKAGE_ROOMS]: (state, { isFetchingMore }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: !isFetchingMore,
            fetchingError: null,
        },
    }),
    [types.FETCH_MORE_PACKAGE_ROOMS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            isLoadingMore: true,
            isLoadingMoreError: null,
        },
    }),
    [types.FETCH_PACKAGE_ROOMS_SUCCEEDED]: (state, { rooms }) => ({
        ...state,
        view: {
            ...state.view,
            roomList: rooms,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
        },
    }),
    [types.FETCH_MORE_PACKAGE_ROOMS_SUCCEEDED]: (state, { rooms }) => ({
        ...state,
        view: {
            ...state.view,
            roomList: [...state.view.roomList, ...rooms],
            fetchingLoading: false,
            fetchingError: null,
            isLoadingMore: false,
            hasMore: rooms.length !== 0,
        },
    }),
    [types.FETCH_PACKAGE_ROOMS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: false,
            fetchingError: error,
        },
    }),
    [types.FETCH_MORE_PACKAGE_ROOMS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            isFetchingMore: false,
            isLoadingMoreError: error,
        },
    }),

    // save filters
    [types.SAVE_PACKAGE_FILTER_DATA]: (state, { filters }) => ({
        ...state,
        view: {
            ...state.view,
            filterData: filters,
        },
    }),

    // clear package view state
    [types.CLEAR_PACKAGE_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            packageDetails: null,
            roomTypeList: [],
            roomList: [],
            filterData: null,
            loading: false,
            error: null,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
            isLoadingMore: false,
            isLoadingMoreError: null,
        },
    }),
};
