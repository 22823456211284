import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import DashboardService from '@/services/TourDashboardService';
import { types, actions } from './reducer';

function* getTourCount() {
    try {
        const response = yield call(DashboardService.getTourCount);
        yield put(actions.getTourCountSucceeded(response.data));
    } catch (error) {
        yield put(actions.getTourCountFailed(extractError(error)));
    }
}

function* watchGetTourCount() {
    yield takeEvery(
        types.REQUEST_GET_TOUR_COUNT,
        getTourCount,
    );
}

function* tourDashboardSaga() {
    yield all([
        fork(watchGetTourCount),
    ]);
}

export default tourDashboardSaga;
