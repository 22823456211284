import { Space, Typography } from 'antd';

const BlockDescription = ({
    icon,
    label,
    content,
    spaceSize,
}) => {
    return (
        <Space className="block-description" size={spaceSize}>
            {label !== 'Destination' && (
                <Typography.Text className="icon">
                    {icon}
                </Typography.Text>
            )}

            <div>
                <Typography.Text className="label">
                    {label}
                </Typography.Text>

                <Typography.Text className="content">
                    {content}
                </Typography.Text>
            </div>

            {label === 'Destination' && (
                <Typography.Text className="icon">
                    {icon}
                </Typography.Text>
            )}
        </Space>
    );
};

export default BlockDescription;
