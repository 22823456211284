import { Row, Col, Skeleton } from 'antd';

const Filters = () => {
    return (
        <Row
            className="loading-filters"
            gutter={30}
            justify="end"
        >
            <Col>
                <Skeleton.Button
                    className="search"
                    active
                />
            </Col>

            <Col>
                <Skeleton.Button
                    className="filter-invoices"
                    active
                />
            </Col>

            <Col>
                <Skeleton.Button
                    className="sort-invoices"
                    active
                />
            </Col>
        </Row>
    );
};

export default Filters;
