import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // resend
    requestResendRegistrationPhoneCode: null,

    // register
    requestRegisterVerifyPhone: ['code'],
    registerVerifyPhoneSucceeded: ['code'],
    registerVerifyPhoneFailed: ['errors'],

    // edit
    requestEditPhone: ['data'],
    editPhoneSucceeded: ['prefix', 'phone', 'message'],
    editPhoneFailed: ['errors'],

    // clear edit phone state
    clearEditPhoneState: null,
});

export default {
    // register
    [types.REQUEST_REGISTER_VERIFY_PHONE]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: true,
            errors: null,
        },
    }),
    [types.REGISTER_VERIFY_PHONE_SUCCEEDED]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            registerStep: 3,
        },
    }),
    [types.REGISTER_VERIFY_PHONE_FAILED]: (state, { errors }) => {
        return ({
            ...state,
            registration: {
                ...state.registration,
                submitting: false,
                errors,
            },
        });
    },
    // edit phone
    [types.REQUEST_EDIT_PHONE]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submittingNewPhone: true,
            errors: null,
        },
    }),
    [types.EDIT_PHONE_SUCCEEDED]: (state, { phone, prefix, message }) => ({
        ...state,
        user: { ...state.user, prefix, phone },
        registration: {
            ...state.registration,
            submittingNewPhone: false,
            phone,
            phoneEditSuccess: message,
        },
    }),
    [types.EDIT_PHONE_FAILED]: (state, { errors }) => ({
        ...state,
        registration: {
            ...state.registration,
            submittingNewPhone: false,
            errors,
        },
    }),
    // clear edit phone state
    [types.CLEAR_EDIT_PHONE_STATE]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            phoneEditSuccess: '',
        },
    }),
};

export { types, actions };
