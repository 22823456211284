import { useEffect } from 'react';
import {
    useHistory,
    useParams,
    Switch,
    Route,
} from 'react-router-dom';
import { basePath, typeBasePath, types } from '../constants';
import ViewAll from './ViewAll';
import New from './New';
import ViewOne from './ViewOne';

const Accommodation = () => {
    const { push } = useHistory();
    const { type } = useParams();

    /**
     * if type is not one of the above, redirect to dashboard of accomodation
     */
    useEffect(() => {
        if (!types.includes(type)) {
            push(basePath);
        }
    }, [type, push]);

    return (
        <Switch>
            <Route path={`${typeBasePath}/`} exact component={ViewAll} />

            <Route path={`${typeBasePath}/add`} exact component={New} />

            <Route path={`${typeBasePath}/:id`} exact component={ViewOne} />

            <Route path={`${typeBasePath}/:id/edit`} exact component={New} />
        </Switch>
    );
};

export default Accommodation;
