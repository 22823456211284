import { all, fork, takeEvery } from 'redux-saga/effects';

import pagination from '@/helpers/pagination';
import PackageService from '@/services/TourServices/PackageService';
import { types } from './reducer';

function* watchFetchPackages() {
    yield takeEvery(
        types.REQUEST_FETCH_TOUR_PACKAGES,
        pagination('tourPackages').saga(PackageService),
    );
}

function* packageSaga() {
    yield all([
        fork(watchFetchPackages),
    ]);
}

export default packageSaga;
