import { Link } from 'react-router-dom';
import {
    Space,
    Rate,
} from 'antd';

const Title = ({
    name,
    rate,
    url,
    isList,
}) => {
    return (
        isList ? (
            <Space size={11}>
                <Link className="title" to={url}>
                    <div>
                        {name}
                    </div>
                </Link>

                <Rate
                    disabled
                    value={rate}
                    count={rate}
                />
            </Space>
        ) : (
            <Space
                direction="vertical"
                size={10}
            >
                <Rate
                    disabled
                    value={rate}
                    count={rate}
                />

                <Link className="title" to={url}>
                    <div>
                        {name}
                    </div>
                </Link>
            </Space>
        )
    );
};

export default Title;
