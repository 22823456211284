/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Divider,
    Space,
    // Progress,
    Avatar,
    Typography,
    // Tooltip,
    Modal,
} from 'antd';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import ActionIcon from '@/components/ActionIcon';
import {
    DeleteIcon,
    EditIcon,
    // EmployeeIcon,
    EyeIcon,
} from '@/assets/images/icons/screens';
import { actions } from '@/redux/employees/reducer';
import { useCurrency } from '@/hooks/useCurrency';
import useNotification from '@/hooks/useNotification';

// const setColor = (points) => {
//     if (points < 16) {
//         return '#F67481';
//     } else if (points >= 16 && points < 31) {
//         return '#FFD36C';
//     } else {
//         return '#24DCAE';
//     }
// };

const EmployeeList = ({ data }) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const [employeeName, setEmployeeName] = useState('');

    const messageSuccess = useSelector((state) => state.employees.delete.messageSuccess);
    const error = useSelector((state) => state.employees.delete.error);

    const deleteEmployee = useCallback((id) => {
        Modal.confirm({
            className: 'confirm-modal',
            title: `${t('Are you sure to delete this employee')} ?`,
            onOk() {
                dispatch(actions.requestDeleteEmployee(id));
            },
        });
    }, [dispatch, t]);

    useNotification({
        messageSuccess,
        error,
        item: employeeName,
        clearAction: actions.clearDeleteEmployeeState(),
        mode: 'list',
    });

    return (
        <Row className="employee-list">
            {data.map((d) => (
                <Col key={d.id} xs={24}>
                    <Row gutter={26}>
                        <Col xs={20}>
                            <Space size={100}>
                                <div>
                                    <Space size={25}>
                                        <Avatar src={d.image} />
                                        <div>
                                            <Typography.Text className="diff-color_author">
                                                {d.full_name}
                                            </Typography.Text>
                                            <InlineDescription
                                                className="diff-color"
                                                label={t('Since')}
                                                content={d.date_employed}
                                            />
                                        </div>
                                    </Space>
                                </div>
                                <div>
                                    <InlineDescription
                                        className="diff-color"
                                        label={t('Role')}
                                        content={d.role}
                                    />
                                    <InlineDescription
                                        className="diff-color"
                                        label={t('Salary')}
                                        content={`${currencyIcon}${d.salary_amount}`}
                                    />
                                </div>
                                <div>
                                    <InlineDescription
                                        className="diff-color"
                                        label={t('No. of Sales')}
                                        content={d.no_sales}
                                    />
                                    <InlineDescription
                                        className="diff-color"
                                        label={t('Total Revenue')}
                                        content={`${currencyIcon}${d.total_revenues}`}
                                    />
                                </div>
                                {/* <Col xs={10}>
                                    <Progress
                                        // percent={d.points}
                                        size="small"
                                        strokeColor={setColor(d.points)}
                                        // format={(percent) => `${percent} / 100 Points`}
                                    />
                                </Col> */}
                            </Space>
                        </Col>
                        <Col xs={4} className="employee-list_action_icon">
                            <Space size={1}>
                                {/* <Tooltip title={t('Add Points/Review')} overlayClassName="employee-tooltip">
                                    <ActionIcon>
                                        <EmployeeIcon onClick={() => {}} />
                                    </ActionIcon>
                                </Tooltip> */}
                                <ActionIcon onClick={() => {
                                    push(`/app/employees/employees/${d.id}/edit`);
                                    dispatch(actions.clearEmployeeState());
                                }}
                                >
                                    <EditIcon />
                                </ActionIcon>
                                <ActionIcon onClick={() => {
                                    deleteEmployee(d.id);
                                    setEmployeeName(d.full_name);
                                }}
                                >
                                    <DeleteIcon />
                                </ActionIcon>
                                <ActionIcon onClick={() => push(`/app/employees/employees/${d.id}`)}>
                                    <EyeIcon />
                                </ActionIcon>
                            </Space>
                        </Col>
                    </Row>
                    <div className="employee-divider"><Divider /></div>
                </Col>
            ))}
        </Row>
    );
};

export default EmployeeList;
