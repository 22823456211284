import { Switch, Route, Redirect } from 'react-router-dom';
import Employee from './Employee';
import Roles from './Roles';

export const routes = [
    {
        name: 'Employees',
        icon: 'employees icon',
        description: 'employees desc',
        url: '/employees',
        component: Employee,
    },
    {
        name: 'Roles',
        icon: 'roles icon',
        description: 'roles desc',
        url: '/roles',
        component: Roles,
    },
];

const Employees = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/employees${route.url}`}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/app/employees/employees" />
        </Switch>
    );
};

export default Employees;
