import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { Divider } from 'antd';

import HeaderWithHelp from '@/components/HeaderWithHelp';
import { actions } from '@/redux/tour/packages/create/reducer';
import { selectors } from '@/redux/tour/packages/create/model';

import EmptyHotelList from './EmptyHotelList';
import HotelList from './HotelList';

const question = 'What is this ?';
const answer = 'Lorem ipsum dolor sit amet...';

const Accommodation = ({ form, isEdit, regStep }) => {
    const dispatch = useDispatch();

    const hotels = useSelector(selectors.hotels);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.tour.packages.create);

    const [page, setPage] = useState(1);

    const handleFetchHotels = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            dispatch(actions.requestGetHotels(data, fetchMore));
            setPage(currentPage + 1);
        },
        [dispatch],
    );

    useEffect(() => {
        handleFetchHotels({});
    }, [handleFetchHotels]);

    const handleFetchMore = useCallback(() => {
        handleFetchHotels({ currentPage: page, fetchMore: true });
    }, [handleFetchHotels, page]);

    return (
        <>
            <HeaderWithHelp
                title="Accommodation"
                question={question}
                answer={answer}
            />

            <Divider />

            { loading
                ? 'Loading...'
                : hotels.length === 0
                    ? (
                        <EmptyHotelList />
                    ) : (
                        <>
                            <HotelList
                                data={hotels}
                                form={form}
                                isEdit={isEdit}
                                regStep={regStep}
                            />
                            {!isLoadingMore && hasMore && (
                                <Waypoint onEnter={() => handleFetchMore()} />
                            )}
                        </>
                    )}
        </>
    );
};

export default Accommodation;
