import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Row,
    Col,
    Form,
    Button,
} from 'antd';
import AddCustomerForm from '@/components/AddCustomerForm';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { dateFormat } from '@/helpers/dateFormat';
import { actions } from '@/redux/customers/create/reducer';
import { selectors } from '@/redux/customers/create/model';
import { ArrowRightIcon } from '@/assets/images/icons/screens';

const formFields = [
    'name',
    'surname',
    'id_number',
    'nationality',
    'phone',
    'email',
    'gender',
    'birth',
    'company_name',
    'vat_number',
    'company_phone',
    'company_email',
    'address',
];

const New = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { push } = useHistory();

    const { id: urlId } = useParams();
    const reduxId = useSelector((state) => state.customers.create.customersInfo?.id);
    const submitting = useSelector(
        (state) => state.customers.create.submitting,
    );
    const success = useSelector((state) => state.customers.create.success);

    const id = useMemo(() => {
        return isEdit ? urlId : reduxId;
    }, [isEdit, urlId, reduxId]);

    useEffect(() => {
        if (isEdit && id) {
            dispatch(actions.requestFetchCustomerData(id));
        }
    }, [isEdit, dispatch, id]);

    const onFinish = useCallback((values) => {
        const data = {
            // id,
            name: values.name,
            surname: values.surname,
            id_number: values.id_number,
            nationality: values.nationality?.value,
            phone: values.phone,
            email: values.email,
            gender: values.gender,
            birth: values.birth?.format(dateFormat),

            company: values.company_name
                ? {
                    company_name: values.company_name,
                    vat_number: values.vat_number,
                    company_phone: values.company_phone,
                    company_email: values.company_email,
                    address: values.address,
                }
                : undefined,
        };
        dispatch(actions.requestSubmitCustomerDetails(data));
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            push('/app/customers');
        }
    }, [success, push]);

    const customerInfo = useSelector(selectors.customerInfo);
    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    useEffect(() => {
        let initialData = {};
        if (customerInfo) {
            initialData = {
                name: customerInfo.name,
                surname: customerInfo.surname,
                id_number: customerInfo.id_number,
                nationality: customerInfo.nationality,
                phone: customerInfo.phone,
                email: customerInfo.email,
                gender: customerInfo.gender,
                birth: moment(customerInfo.birth),

                company_name: customerInfo.company_name,
                vat_number: customerInfo.vat_number,
                company_phone: customerInfo.company_phone,
                company_email: customerInfo.company_email,
                address: customerInfo.address,

            };
        }form.setFieldsValue(initialData);
    }, [form, customerInfo]);

    return (
        <div className="add-customer">
            <div className="form">
                <Form
                    form={form}
                    onFinish={onFinish}
                    className="inner-content"
                    requiredMark={false}
                >
                    <AddCustomerForm />
                </Form>
            </div>

            <div className="form-button">
                <Row justify="end">
                    <Col>
                        <Button
                            className="save-button"
                            type="primary"
                            loading={submitting}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            <div>
                                <span>{t('Save').toUpperCase()}</span>
                                <ArrowRightIcon />
                            </div>
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default New;
