import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Input,
    Typography,
    Avatar,
    Checkbox,
    Button,
    Rate,
    Form,
} from 'antd';

import { actions } from '@/redux/tour/packages/create/reducer';
import { selectors } from '@/redux/tour/packages/create/model';

import InlineDescription from '@/components/Information/WithoutImage/Inline';
import { InfoCircleIcon } from '@/assets/images/icons/screens';

const Accommodation = ({
    data,
    form,
    isEdit,
    regStep,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { push } = useHistory();

    const hotelDetails = useSelector(selectors.hotelDetails);

    const [selectedHotel, setSelectedHotel] = useState();

    const onFinish = useCallback(() => {
        if (isEdit && regStep > 2) {
            const step = 2;
            const completedStep = 2;
            dispatch(actions.requestSubmitHotelDetails(
                { hotel: selectedHotel },
                step,
                completedStep,
            ));
        } else {
            const step = 2;
            const completedStep = 1;
            dispatch(actions.requestSubmitHotelDetails(
                { hotel: selectedHotel },
                step,
                completedStep,
            ));
        }
    }, [selectedHotel]);

    useEffect(() => {
        if (hotelDetails) {
            setSelectedHotel(hotelDetails.id);
        }
    }, [hotelDetails]);

    return (
        <>
            <Input.Search placeholder={`${t('Search for Hotel, Villa, Guesthouse')} ...`} />
            <div className="accommodation-text">
                <Typography.Text>
                    <InfoCircleIcon />
                    {' '}
                    {t('Select one of the Accommodation or')}
                </Typography.Text>

                <Button
                    className="text-add-button"
                    type="text"
                    onClick={() => push('/app/accommodations')}
                >
                    {t('Add New Accommodation')}
                </Button>
            </div>
            <Form form={form} onFinish={onFinish}>
                {data.map((hotel) => (
                    <div className="accommodation-card" key={hotel.id}>
                        <Card>
                            <Card.Meta
                                avatar={(<Avatar src={hotel.image} />)}
                                title={(
                                    <>
                                        <span>{hotel.name}</span>
                                        {' '}
                                        <Rate disabled value={hotel.stars} />
                                    </>
                                )}
                                description={(
                                    <Row justify="space-between">
                                        <Col>
                                            <InlineDescription
                                                label={t('Room Types')}
                                                content={t(hotel.room_types)}
                                            />

                                            <InlineDescription
                                                label={t('Number of Rooms Available')}
                                                content={hotel.available_rooms}
                                            />
                                        </Col>

                                        <Col className="selected-hotel">
                                            <Checkbox
                                                checked={selectedHotel === hotel.id}
                                                onChange={() => setSelectedHotel(hotel.id)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            />
                        </Card>
                    </div>
                ))}
            </Form>
        </>
    );
};

export default Accommodation;
