import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import FlightService from '@/services/FlightService';
import { types, actions } from './reducer';

function* submitFlight({ data }) {
    try {
        const res = yield call(FlightService.createTicket, data);
        yield put(actions.submitFlightSucceeded(res.data));
    } catch (error) {
        yield put(actions.submitFlightFailed(extractError(error)));
    }
}

function* fetchFlightData({ id }) {
    try {
        const res = yield call(FlightService.getOne, id);
        yield put(actions.fetchFlightDataSucceeded(res.data));
    } catch (error) {
        yield put(actions.fetchFlightDataFailed(extractError(error)));
    }
}

function* getAirportList({ data }) {
    try {
        const res = yield call(FlightService.getAirports, data);
        yield put(actions.getAirportListSucceeded(res.data));
    } catch (error) {
        yield put(actions.getAirportListFailed(extractError(error)));
    }
}

function* addNewAirport({ data }) {
    try {
        const res = yield call(FlightService.addNewAirport, data);
        yield put(actions.addNewAirportSucceeded(res.data));
    } catch (error) {
        yield put(actions.addNewAirportFailed(extractError(error)));
    }
}

function* getAirlineList({ data }) {
    try {
        const res = yield call(FlightService.getAirlines, data);
        yield put(actions.getAirlineListSucceeded(res.data));
    } catch (error) {
        yield put(actions.getAirlineListFailed(extractError(error)));
    }
}

function* addNewAirline({ data }) {
    try {
        const res = yield call(FlightService.addNewAirline, data);
        yield put(actions.addNewAirlineSucceeded(res.data));
    } catch (error) {
        yield put(actions.addNewAirlineFailed(extractError(error)));
    }
}

function* watchSubmitFlight() {
    yield takeEvery(
        types.REQUEST_SUBMIT_FLIGHT,
        submitFlight,
    );
}

function* watchFetchFlightData() {
    yield takeEvery(
        types.REQUEST_FETCH_FLIGHT_DATA,
        fetchFlightData,
    );
}

function* watchGetAirportList() {
    yield takeEvery(
        types.REQUEST_GET_AIRPORT_LIST,
        getAirportList,
    );
}

function* watchAddNewAirport() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_AIRPORT,
        addNewAirport,
    );
}

function* watchGetAirlineList() {
    yield takeEvery(
        types.REQUEST_GET_AIRLINE_LIST,
        getAirlineList,
    );
}

function* watchAddNewAirline() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_AIRLINE,
        addNewAirline,
    );
}

function* createFlightSaga() {
    yield all([
        fork(watchSubmitFlight),
        fork(watchFetchFlightData),
        fork(watchGetAirportList),
        fork(watchAddNewAirport),
        fork(watchGetAirlineList),
        fork(watchAddNewAirline),
    ]);
}

export default createFlightSaga;
