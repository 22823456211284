import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Divider,
    Select,
    Space,
    Button,
    Popover,
} from 'antd';

import BlockDescription from '@/components/Information/WithImage/Block';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import {
    AdultIcon,
    ChildIcon,
    InfantIcon,
    DownIcon,
    InfoCircleIcon,
} from '@/assets/images/icons/screens';
import SelectCabin from './SelectCabin';

const CabinDetails = ({
    roomListSelected,
    setRoomListSelected,
    startDate,
    endDate,
    item,
    type,
    tourId,
}) => {
    const { t } = useTranslation();

    const options = item.number_of_cabins !== '0' && new Array(parseInt(item.number_of_cabins, 10)).fill(null).map((_, i) => (
        <Select.Option
            key={(i).toString()}
            value={i + 1}
        >
            {i + 1}
        </Select.Option>
    ));

    const [checkedRoom, setCheckedRoom] = useState(false);

    // data to submit
    const [noRooms, setNoRooms] = useState(0);
    const [adultNumber, setAdultNumber] = useState(0);
    const [childNumber, setChildNumber] = useState(0);
    const [infantNumber, setInfantNumber] = useState(0);

    return (
        <Row className="room">
            <Col lg={4}>
                <BlockDescription
                    icon={(
                        <AdultIcon />
                    )}
                    label={`${t('Max. Adults')} ${item.adults}`}
                    content={`${item.currency_icon}${item.price} / ${t('adult')}`}
                    spaceSize={14}
                />
                <BlockDescription
                    icon={(
                        <ChildIcon />
                    )}
                    label={`${t('Max. Children')} ${item.children}`}
                    content={`${item.currency_icon}${item.child_charges} / ${t('child')}`}
                    spaceSize={14}
                />
                <BlockDescription
                    icon={(
                        <InfantIcon />
                    )}
                    label={`${t('Max. Infants')} ${item.infants}`}
                    content={`${item.currency_icon}${item.infant_charges} / ${t('infant')}`}
                    spaceSize={14}
                />
            </Col>

            <Col lg={15}>
                <Row>
                    {item.amenities?.map((amenity) => (
                        <Col lg={8} key={amenity.id}>
                            <li className="inclusion">{t(amenity.name)}</li>
                        </Col>
                    ))}
                </Row>
            </Col>

            <Col lg={1} className="reservation-list-divider">
                <Divider type="vertical" />
            </Col>

            <Col lg={4}>
                <Row gutter={[0, 10]} className="reservation-list_right-col">
                    <Col lg={24}>
                        <div className="no-of-rooms">{`${t('No. of cabins')}: ${item.number_of_cabins}`}</div>
                    </Col>
                    <Col lg={24}>
                        <Select
                            placeholder={t('Available Cabins')}
                            onSelect={(value) => setNoRooms(value)}
                            disabled={checkedRoom}
                        >
                            {options}
                        </Select>
                    </Col>
                    <Col lg={24}>
                        <Popover
                            trigger="click"
                            placement="bottom"
                            disabled={checkedRoom}
                            getPopupContainer={(trigger) => {
                                return trigger?.parentElement;
                            }}
                            className="reservation-guests_dropdown-2"
                            content={(
                                <div className="reservation-guests_dropdown_content">
                                    <div>
                                        <Space size={35}>
                                            <span>{t('Adults')}</span>
                                            <IncrementNumberInput
                                                max={item.adults * noRooms}
                                                hasInputBorder={false}
                                                onChange={setAdultNumber}
                                                defaultCount={adultNumber}
                                            />
                                        </Space>
                                    </div>
                                    <div>
                                        <Space size={20}>
                                            <span>{t('Children')}</span>
                                            <IncrementNumberInput
                                                max={item.children * noRooms}
                                                hasInputBorder={false}
                                                onChange={setChildNumber}
                                                defaultCount={childNumber}
                                            />
                                        </Space>
                                    </div>
                                    <div>
                                        <Space size={30}>
                                            <span>{t('Infants')}</span>
                                            <IncrementNumberInput
                                                max={item.infants * noRooms}
                                                hasInputBorder={false}
                                                onChange={setInfantNumber}
                                                defaultCount={infantNumber}
                                            />
                                        </Space>
                                    </div>
                                </div>
                            )}
                        >
                            {/* <div className="guests_filters"> */}
                            <Button disabled={checkedRoom}>
                                <div className="filter-guest_title">
                                    {t('No. of guests')}
                                    <DownIcon />
                                </div>
                            </Button>
                            {/* </div> */}
                        </Popover>
                        <div className="guests-options_picked">
                            {adultNumber !== 0 && (
                                `${adultNumber} ${(adultNumber === 1) ? t('Adult ') : t('Adults ')}`
                            )}
                            {childNumber !== 0 && (
                                `${childNumber} ${(childNumber === 1) ? t('Child ') : t('Children ')}`
                            )}
                            {infantNumber !== 0 && (
                                `${infantNumber} ${(infantNumber === 1) ? t('Infant ') : t('Infants ')}`
                            )}
                        </div>
                    </Col>
                    <Col lg={24}>
                        <SelectCabin
                            roomListSelected={roomListSelected}
                            setRoomListSelected={setRoomListSelected}
                            startDate={startDate}
                            endDate={endDate}
                            checkedRoom={checkedRoom}
                            setCheckedRoom={setCheckedRoom}
                            roomId={item.id}
                            roomName={item.type_name}
                            roomPrice={item.price}
                            noRooms={noRooms}
                            adultNumber={adultNumber}
                            childNumber={childNumber}
                            infantNumber={infantNumber}
                            type={type}
                            tourId={tourId}
                            cabinImg={item.images?.[0]?.path}
                            currency={item.currency_icon}
                        />
                    </Col>
                    {startDate === undefined && endDate === undefined && (
                        <Col lg={24} className="reservation-info">
                            <Space>
                                <InfoCircleIcon />
                                <span>{t('Choose start/end date before reservation')}</span>
                            </Space>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default CabinDetails;
