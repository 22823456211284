/* eslint-disable import/no-named-as-default-member */
import {
    takeEvery,
    select,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import AccommodationService from '@/services/AccommodationService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';
import { selectors } from './model';

function* isEdit(step) {
    const completedStep = yield select(selectors.completedStep);
    if (completedStep === null) {
        return false;
    }
    return completedStep >= step;
}

function* submitAccommodationDetails({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        if (edit) {
            const { id } = data;
            response = yield call(AccommodationService.editAccommodationDetails, id, data);
        } else {
            response = yield call(AccommodationService.addAccommodationDetails, data);
        }
        yield put(actions.submitAccommodationDetailsSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitAccommodationDetailsFailed(extractError(error)));
    }
}

function* submitAccommodationFacilities({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        const { id: hotelId } = yield select(selectors.accommodationDetails);
        if (edit) {
            response = yield call(AccommodationService.editFacilities, hotelId, data);
        } else {
            response = yield call(AccommodationService.addFacilities, hotelId, data);
        }
        const {
            selected: facilities,
            extra_services: services,
        } = response.data;
        yield put(actions.submitAccommodationFacilitiesSucceeded(
            facilities,
            services,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitAccommodationFacilitiesFailed(extractError(error)));
    }
}

function* getRoomList({ id }) {
    try {
        const response = yield call(AccommodationService.getRoomList, id);
        yield put(actions.getRoomListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getRoomListFailed(extractError(error)));
    }
}

function* submitAccommodationRoom({ data }) {
    const { id: hotelId } = yield select(selectors.accommodationDetails);
    const room = yield select(selectors.roomDetails);
    try {
        let response;
        if (room !== null) {
            const { id: roomId } = yield select(selectors.roomDetails);
            response = yield call(AccommodationService.editRoom, roomId, data);
        } else {
            response = yield call(AccommodationService.addRoom, hotelId, data);
        }
        yield put(actions.submitAccommodationRoomSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitAccommodationRoomFailed(extractError(error)));
    }
}

function* viewAccommodationRoom({ id }) {
    try {
        const res = yield call(AccommodationService.viewRoom, id);
        yield put(actions.viewAccommodationRoomSucceeded(res.data));
    } catch (error) {
        yield put(actions.viewAccommodationRoomFailed(extractError(error)));
    }
}

function* deleteAccommodationRoom({ id }) {
    try {
        yield call(AccommodationService.deleteRoom, id);
        yield put(actions.deleteAccommodationRoomSucceeded(id));
    } catch (error) {
        yield put(actions.deleteAccommodationRoomFailed(extractError(error)));
    }
}

function* getSeasonList({ id }) {
    try {
        const response = yield call(AccommodationService.getSeasonList, id);
        yield put(actions.getSeasonListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getSeasonListFailed(extractError(error)));
    }
}

function* submitAccommodationSeason({ data }) {
    const { id: hotelId } = yield select(selectors.accommodationDetails);
    const season = yield select(selectors.seasonDetails);
    try {
        let response;
        if (season !== null) {
            const { id: seasonId } = yield select(selectors.seasonDetails);
            response = yield call(AccommodationService.editSeason, seasonId, data);
        } else {
            response = yield call(AccommodationService.addSeason, hotelId, data);
        }
        yield put(actions.submitAccommodationSeasonSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitAccommodationSeasonFailed(extractError(error)));
    }
}

function* viewAccommodationSeason({ id }) {
    try {
        const res = yield call(AccommodationService.viewSeason, id);
        yield put(actions.viewAccommodationSeasonSucceeded(res.data));
    } catch (error) {
        yield put(actions.viewAccommodationSeasonFailed(extractError(error)));
    }
}

function* deleteAccommodationSeason({ id }) {
    try {
        yield call(AccommodationService.deleteSeason, id);
        yield put(actions.deleteAccommodationSeasonSucceeded(id));
    } catch (error) {
        yield put(actions.deleteAccommodationSeasonFailed(extractError(error)));
    }
}

function* fetchAccommodationData({ id }) {
    try {
        const [
            generalData,
            servicesFacilities,
        ] = yield all([
            call(AccommodationService.viewAccommodationDetails, id),
            call(AccommodationService.viewFacilities, id),
        ]);
        const {
            selected: facilities,
            extra_services: services,
        } = servicesFacilities.data;
        yield put(actions.fetchAccommodationDataSucceeded(
            generalData.data,
            facilities,
            services,
        ));
    } catch (error) {
        yield put(actions.fetchAccommodationDataFailed(extractError(error)));
    }
}

function* getAmenitiesList({ data }) {
    try {
        const res = yield call(AccommodationService.getAmenities, data);
        const { amenities: amenitiesList } = res.data;
        yield put(actions.getAmenitiesListSucceeded(amenitiesList));
    } catch (error) {
        yield put(actions.getAmenitiesListFailed(extractError(error)));
    }
}

function* addNewAmenity({ id, data }) {
    try {
        const res = yield call(AccommodationService.addNewAmenity, id, data);
        yield put(actions.addNewAmenitySucceeded(res.data));
    } catch (error) {
        yield put(actions.addNewAmenityFailed(extractError(error)));
    }
}

function* watchSubmitAccommodationDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ACCOMMODATION_DETAILS,
        submitAccommodationDetails,
    );
}

function* watchSubmitAccommodationFacilities() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ACCOMMODATION_FACILITIES,
        submitAccommodationFacilities,
    );
}

function* watchGetRoomList() {
    yield takeEvery(
        types.REQUEST_GET_ROOM_LIST,
        getRoomList,
    );
}

function* watchSubmitAccommodationRoom() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ACCOMMODATION_ROOM,
        submitAccommodationRoom,
    );
}

function* watchViewAccommodationRoom() {
    yield takeEvery(
        types.REQUEST_VIEW_ACCOMMODATION_ROOM,
        viewAccommodationRoom,
    );
}

function* watchDeleteAccommodationRoom() {
    yield takeEvery(
        types.REQUEST_DELETE_ACCOMMODATION_ROOM,
        deleteAccommodationRoom,
    );
}

function* watchGetSeasonList() {
    yield takeEvery(
        types.REQUEST_GET_SEASON_LIST,
        getSeasonList,
    );
}

function* watchSubmitAccommodationSeason() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ACCOMMODATION_SEASON,
        submitAccommodationSeason,
    );
}

function* watchViewAccommodationSeason() {
    yield takeEvery(
        types.REQUEST_VIEW_ACCOMMODATION_SEASON,
        viewAccommodationSeason,
    );
}

function* watchDeleteAccommodationSeason() {
    yield takeEvery(
        types.REQUEST_DELETE_ACCOMMODATION_SEASON,
        deleteAccommodationSeason,
    );
}

function* watchAccommodationData() {
    yield takeEvery(
        types.REQUEST_FETCH_ACCOMMODATION_DATA,
        fetchAccommodationData,
    );
}

function* watchGetAmenitiesList() {
    yield takeEvery(
        types.REQUEST_GET_AMENITIES_LIST,
        getAmenitiesList,
    );
}

function* watchAddNewAmenity() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_AMENITY,
        addNewAmenity,
    );
}

function* createSaga() {
    yield all([
        fork(watchSubmitAccommodationDetails),
        fork(watchSubmitAccommodationFacilities),
        fork(watchGetRoomList),
        fork(watchSubmitAccommodationRoom),
        fork(watchViewAccommodationRoom),
        fork(watchDeleteAccommodationRoom),
        fork(watchGetSeasonList),
        fork(watchSubmitAccommodationSeason),
        fork(watchViewAccommodationSeason),
        fork(watchDeleteAccommodationSeason),
        fork(watchAccommodationData),
        fork(watchGetAmenitiesList),
        fork(watchAddNewAmenity),
    ]);
}

export default createSaga;
