import { ToggleIcon3, ToggleIcon4 } from '@/assets/images/icons/topbar';

const SubMenu = ({
    isCollapsed,
    setIsCollapsed,
}) => {
    return (
        <div className="toggle-wrapper">
            {isCollapsed ? (
                <ToggleIcon3 className="stbi" onClick={() => setIsCollapsed(false)} />
            ) : (<ToggleIcon4 className="stbi" onClick={() => setIsCollapsed(true)} />)}
        </div>
    );
};

export default SubMenu;
