/* eslint-disable object-curly-newline */
/* eslint-disable no-debugger */
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { actions } from '@/redux/tour/reducer';
import EmptyList from '@/components/EmptyList';
import Filters from '@/components/TourFilters';
import { EmptyListIcon } from '@/assets/images/icons/screens';

import TripList from './TripList';
import Loading from './Loading';

const ViewAll = () => {
    const dispatch = useDispatch();

    const maxPrice = useSelector((state) => state.maxprice.tourMaxPrice);

    const [filters, baseSetFilters] = useState({ prices: [0, 0] });
    useEffect(() => {
        if (maxPrice !== null) {
            baseSetFilters({ prices: [0, maxPrice] });
        }
    }, [maxPrice]);

    const [viewMode, setViewMode] = useState('list');
    const [page, setPage] = useState(1);

    const handleFetchTourTrips = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                name: filters.name,
                group_size: filters.group_size,
                min_price: filters.prices[0],
                max_price: filters.prices[1],
                location: filters.location,
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            filters.prices[1] !== 0 && dispatch(actions.requestFetchTourTrips(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters.group_size,
            filters.location,
            filters.name,
            filters.prices,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const trips = useSelector((state) => state.tour.trip.data);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.tour.trip);

    useEffect(() => {
        handleFetchTourTrips({});
    }, [handleFetchTourTrips]);

    const handleFetchMore = useCallback(() => {
        handleFetchTourTrips({ currentPage: page, fetchMore: true });
    }, [handleFetchTourTrips, page]);

    return (
        <div id="tour-view-all">
            <Filters
                filters={filters}
                setFilters={setFilters}
                viewMode={viewMode}
                setViewMode={setViewMode}
                type="trip"
                searchPlaceholder="Tour name"
                locationPlaceholder="Location"
                addNewItemText="Add new tour"
                addUrl="/app/tours/trip/add"
                clearTourState={actions.clearTripState()}
            />
            {loading ? (
                <Loading />
            ) : trips.length === 0 ? (
                <EmptyList
                    image={<EmptyListIcon />}
                    category={'Start creating tour trip'.toUpperCase()}
                    message="Oops! Looks you haven't created yet any Tour Trip"
                    url="/app/tours/trip/add"
                />
            ) : (
                <>
                    <TripList trips={trips} viewMode={viewMode} />
                    {!isLoadingMore && hasMore && (
                        <Waypoint onEnter={() => handleFetchMore()} />
                    )}
                </>
            )}
        </div>
    );
};

export default ViewAll;
