import {
    useCallback,
    useEffect,
    useState,
    useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Select,
    Typography,
    Input,
    DatePicker,
    Row,
    Col,
    Button,
    Radio,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CalendarIcon, PlusIcon } from '@/assets/images/icons/screens';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import { actions } from '@/redux/invoice/createcustom/reducer';
import { selectors } from '@/redux/invoice/createcustom/model';
import { dateFormat } from '@/helpers/dateFormat';
import { useCurrency } from '@/hooks/useCurrency';

import baseValidations from './validations';

const formFields = [
    'service_name',
    'description',
    'date',
    'price',
];

const BilledFor = ({ form }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();
    const validations = useTranslatedValidationConfig(baseValidations);

    const options = useMemo(() => ([
        { label: t('%'), value: 1 },
        { label: t(currencyIcon), value: 2 },
    ]), [t]);

    const existingServices = useSelector(selectors.existingServices);
    const serviceDetails = useSelector(selectors.serviceDetails);
    const loading = useSelector(selectors.loading);
    const errors = useSelector(selectors.errors);

    const [serviceId, setServiceId] = useState(null);
    const [serviceName, setServiceName] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [dateOfIssue, setDateOfIssue] = useState('');
    const [servicePrice, setServicePrice] = useState('');
    const [serviceCommission, setServiceCommission] = useState('');
    const [commissionType, setCommissionType] = useState(1);

    const addService = useCallback(() => {
        const data = {
            id: serviceId,
            key: Math.floor(Math.random() * 100000),
            service_name: form.getFieldValue('service_name'),
            description: serviceDescription,
            date: dateOfIssue?.format(dateFormat),
            price: servicePrice,
            commission: serviceCommission,
            commission_type: commissionType,
        };
        dispatch(actions.addInvoiceService(data));
        setServiceId(null);
    }, [dispatch,
        serviceId,
        serviceDescription,
        dateOfIssue,
        servicePrice,
        serviceCommission,
        commissionType,
        form]);

    // get existing services
    const getExistingServices = useCallback((value) => {
        if (value && value.length === 3) {
            const data = {
                name: value,
            };
            dispatch(actions.requestGetExistingServices(data));
        }
    }, [dispatch]);

    // get service details
    const getServiceDetails = useCallback((id) => {
        dispatch(actions.requestGetServiceDetails(id));
    }, [dispatch]);

    // submit invoice services
    const serviceList = useSelector(selectors.services);

    const formattedServices = serviceList.map((service) => ({
        id: service.id,
        service_name: service.service_name,
        description: service.description,
        date: service.date,
        price: service.price,
        commission: service.commission,
        commission_type: service.commission_type,
    }));

    const onFinish = useCallback(() => {
        const data = {
            service: formattedServices,
        };
        dispatch(actions.requestSubmitInvoiceServices(data));
    }, [dispatch, formattedServices]);

    useEffect(() => {
        let initialData = {
            commission_type: 1,
        };
        if (serviceName) {
            initialData = {
                service_name: serviceName,
                commission_type: 1,
            };
        }
        if (serviceDetails) {
            initialData = {
                service_name: serviceDetails.name,
                price: serviceDetails.price,
                commission: serviceDetails.commission,
                commission_type: serviceDetails.commission_type === '1' ? 1 : 2,
                description: undefined,
                date: undefined,
            };
            setServiceId(serviceDetails.id);
            setServiceName(serviceDetails.name);
            setServiceDescription(serviceDetails.description);
            setDateOfIssue(serviceDetails.date);
            setServicePrice(serviceDetails.price);
            setServiceCommission(serviceDetails.commission);
            setCommissionType(serviceDetails.commission_type);
        }
        form.setFieldsValue(initialData);
    }, [serviceName, form, serviceDetails]);

    useApiValidationsForForm({ form, errors, formFields });

    useEffect(() => {
        if (errors) {
            dispatch(actions.clearCustomInvoiceErrors());
        }
    }, [dispatch, errors]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
            className="billed-for"
        >
            <Typography.Text>{`${t('Service')}: `}</Typography.Text>
            <Form.Item
                name="service_name"
                rules={validations.service_name}
            >
                <Select
                    showSearch
                    allowClear
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    notFoundContent={null}
                    loading={loading}
                    onSearch={(value) => {
                        setServiceName(value);
                        getExistingServices(value);
                        dispatch(actions.clearServiceDetails());
                    }}
                    placeholder={t('Search for an Existing Service or create a new one')}
                    onSelect={(id) => getServiceDetails(id)}
                    onClear={() => form.resetFields()}
                >
                    {existingServices?.map((service) => (
                        <Select.Option key={service.id} value={service.id}>
                            {service.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <div className="info">
                <QuestionCircleOutlined />
                <Typography.Text>
                    {t('Enter a service or product name that is not listed in your database (ex. Flight Ticket for Tirana - Amsterdam)')}
                </Typography.Text>
            </div>
            <Form.Item
                name="description"
                rules={validations.description}
            >
                <Input.TextArea
                    placeholder={t('Description')}
                    onChange={(e) => setServiceDescription(e.target.value)}
                />
            </Form.Item>
            <Row gutter={30}>
                <Col span={8}>
                    <Typography.Text>{`${t('Date of issue')}: `}</Typography.Text>
                    <Form.Item
                        name="date"
                        rules={validations.date}
                    >
                        <DatePicker
                            placeholder={t('Select a date')}
                            suffixIcon={<CalendarIcon />}
                            onChange={(date) => setDateOfIssue(date)}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Typography.Text>{`${t('Price')}: `}</Typography.Text>
                    <Form.Item
                        name="price"
                        rules={validations.price}
                    >
                        <Input
                            suffix={`${currencyIcon}0.00`}
                            onChange={(e) => setServicePrice(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Typography.Text>{`${t('Commission')}: `}</Typography.Text>
                    <Form.Item
                        name="commission"
                        rules={validations.commission}
                    >
                        <Input
                            onChange={(e) => setServiceCommission(e.target.value)}
                            suffix={(
                                <Form.Item
                                    name="commission_type"
                                    noStyle
                                    rules={validations.commission_type}
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType="button"
                                        buttonStyle="solid"
                                        onChange={(e) => setCommissionType(e.target.value)}
                                    />
                                </Form.Item>
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col offset={16} span={8}>
                    <Button
                        block
                        onClick={() => {
                            addService();
                            // form.resetFields();
                            setServiceName('');
                            dispatch(actions.clearServiceDetails());
                        }}
                    >
                        <PlusIcon />
                        {t('Add new Service')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default BilledFor;
