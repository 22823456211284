import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import FlightService from '@/services/FlightService';
import { types, actions } from './reducer';

function* deleteFlight({ id }) {
    try {
        yield call(FlightService.deleteOne, id);
        yield put(actions.deleteFlightSucceeded(id));
    } catch (error) {
        yield put(actions.deleteFlightFailed(extractError(error)));
    }
}

function* watchDeleteFlight() {
    yield takeEvery(
        types.REQUEST_DELETE_FLIGHT,
        deleteFlight,
    );
}

function* flightDeleteSaga() {
    yield all([
        fork(watchDeleteFlight),
    ]);
}

export default flightDeleteSaga;
