import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // go to step
    goToCreatePackageStep: ['step'],

    // fetch for edit
    requestFetchPackageData: ['id'],
    fetchPackageDataSucceeded: [
        'generalData',
        'hotelData',
        'inclusionsData',
        'exclusionsData',
    ],
    fetchPackageDataFailed: ['errors'],

    // submit first step
    requestSubmitPackageGeneralDetails: ['data', 'currStep', 'complStep'],
    submitPackageGeneralDetailsSucceeded: ['data', 'currStep', 'complStep'],
    submitPackageGeneralDetailsFailed: ['errors'],

    // fetch hotels
    requestGetHotels: ['filters', 'isFetchingMore'],
    getHotelsSucceeded: ['data'],
    getHotelsFailed: ['errors'],

    // get more hotels
    getMoreHotels: ['filters'],
    getMoreHotelsSucceeded: ['data'],
    getMoreHotelsFailed: ['error'],

    // submit second step
    requestSubmitHotelDetails: ['data', 'currStep', 'complStep'],
    submitHotelDetailsSucceeded: ['data', 'currStep', 'complStep'],
    submitHotelDetailsFailed: ['errors'],

    // submit third step
    requestSubmitPackageInclusionsExclusions: ['data', 'currStep', 'complStep'],
    submitPackageInclusionsExclusionsSucceeded: [
        'inclusionsData',
        'exclusionsData',
        'currStep',
        'complStep',
    ],
    submitPackageInclusionsExclusionsFailed: ['errors'],

    // get package types
    requestGetPackageTypes: ['data'],
    getPackageTypesSucceeded: ['data'],
    getPackageTypesFailed: ['errors'],

    // add package
    requestAddNewPackageType: ['data'],
    addNewPackageTypeSucceeded: ['data'],
    addNewPackageTypeFailed: ['packageTypeError'],

    // clear state
    clearPackageState: null,

    // clear type error
    clearPackageTypeError: null,
});

const goToExactStep = (step) => {
    if (step === 3) {
        return 0;
    }
    return step - 1;
};

export default {
    // go to step
    [types.GO_TO_CREATE_PACKAGE_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_PACKAGE_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_PACKAGE_DATA_SUCCEEDED]: (
        state,
        {
            generalData,
            hotelData,
            inclusionsData,
            exclusionsData,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: goToExactStep(generalData.tour_register_step),
            completedStep: goToExactStep(generalData.tour_register_step),
            loading: false,
            generalInfo: generalData,
            hotelDetails: hotelData,
            inclusions: inclusionsData,
            exclusions: exclusionsData,
        },
    }),
    [types.FETCH_PACKAGE_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_PACKAGE_GENERAL_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_PACKAGE_GENERAL_DETAILS_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            generalInfo: data,
        },
    }),
    [types.SUBMIT_PACKAGE_GENERAL_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // fetch hotels
    [types.REQUEST_GET_HOTELS]: (state, { isFetchingMore }) => ({
        ...state,
        create: {
            ...state.create,
            fetchingLoading: !isFetchingMore,
            fetchingError: null,
        },
    }),
    [types.GET_MORE_HOTELS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            isLoadingMore: true,
            isLoadingMoreError: null,
        },
    }),
    [types.GET_HOTELS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            hotels: data,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
        },
    }),
    [types.GET_MORE_HOTELS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            hotels: [...state.create.hotels, ...data],
            fetchingLoading: false,
            fetchingError: null,
            isLoadingMore: false,
            hasMore: data.length !== 0,
        },
    }),
    [types.GET_HOTELS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            fetchingLoading: false,
            fetchingError: errors,
        },
    }),
    [types.GET_MORE_HOTELS_FAILED]: (state, { error }) => ({
        ...state,
        create: {
            ...state.create,
            isFetchingMore: false,
            isLoadingMoreError: error,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_HOTEL_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_HOTEL_DETAILS_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            hotelDetails: data,
        },
    }),
    [types.SUBMIT_HOTEL_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_PACKAGE_INCLUSIONS_EXCLUSIONS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_PACKAGE_INCLUSIONS_EXCLUSIONS_SUCCEEDED]: (
        state,
        {
            inclusionsData,
            exclusionsData,
            currStep,
            complStep,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            inclusions: inclusionsData,
            exclusions: exclusionsData,
        },
    }),
    [types.SUBMIT_PACKAGE_INCLUSIONS_EXCLUSIONS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // get trip types
    [types.REQUEST_GET_PACKAGE_TYPES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),

    [types.GET_PACKAGE_TYPES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            packageTypes: data,
            loading: false,
            errors: null,
        },
    }),
    [types.GET_PACKAGE_TYPES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // add package type
    [types.REQUEST_ADD_NEW_PACKAGE_TYPE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            packageTypeError: null,
        },
    }),

    [types.ADD_NEW_PACKAGE_TYPE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            packageTypes: [...state.create.packageTypes, data],
            packageTypeError: null,
        },
    }),
    [types.ADD_NEW_PACKAGE_TYPE_FAILED]: (state, { packageTypeError }) => ({
        ...state,
        create: {
            ...state.create,
            packageTypeError,
        },
    }),

    // clear type error
    [types.CLEAR_PACKAGE_TYPE_ERROR]: (state) => ({
        ...state,
        create: {
            ...state.create,
            packageTypeError: null,
        },
    }),

    // clear state
    [types.CLEAR_PACKAGE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            generalInfo: null,
            hotels: [],
            hotelDetails: null,
            inclusions: [],
            exclusions: [],
            loading: false,
            errors: null,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
            isLoadingMore: false,
            isLoadingMoreError: null,
            submitting: false,
            packageTypes: [],
            typeError: null,
        },
    }),
};

export { types, actions };
