import { all, fork, takeEvery } from 'redux-saga/effects';
import pagination from '@/helpers/pagination';
import SupplierService from '@/services/B2BPartnerServices/SupplierService';
import { types } from './reducer';

function* watchFetchSharedServices() {
    yield takeEvery(
        types.REQUEST_FETCH_SHARED_SERVICES,
        pagination('sharedServices').saga(SupplierService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchSharedServices),
    ]);
}

export default paginationSaga;
