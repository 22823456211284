import { Row, Col } from 'antd';

const ShowIncusionsExclusions = ({ inclusions, exclusions }) => {
    return (
        <div className="incl-excl-row">
            <Row>
                <Col xs={12}>
                    <Row>
                        {inclusions.map((inclusion) => (
                            <Col key={inclusion.id} xl={12} lg={24} md={12} xs={24}>
                                <div className="inclusion">
                                    {inclusion.name}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>

                <Col xs={12}>
                    <Row>
                        {exclusions.map((exclusion) => (
                            <Col key={exclusion.id} xl={12} lg={24} md={12} xs={24}>
                                <div className="exclusion">
                                    {exclusion.name}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ShowIncusionsExclusions;
