/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { call, put } from 'redux-saga/effects';
import { createActions } from 'reduxsauce';
import { camelToSnake, firstToUpper } from './string';
import { createEntityActionTypes } from './redux';
import { extractError } from './api';

const Pagination = (entity) => {
    const actionCreatorNames = createEntityActionTypes(
        firstToUpper(entity),
        'fetch',
    );

    const { Types: types, Creators } = createActions({
        [actionCreatorNames.request]: ['filters', 'isFetchingMore'],
        [actionCreatorNames.succeeded]: ['data', 'totalItems'],
        [actionCreatorNames.failed]: ['error'],

        // fetchMore
        [actionCreatorNames.fetchMore]: ['filters'],
        [actionCreatorNames.fetchMoreSucceeded]: ['data'],
        [actionCreatorNames.fetchMoreFailed]: ['error'],
    });
    const mappedTypes = {
        request: camelToSnake(actionCreatorNames.request),
        succeeded: camelToSnake(actionCreatorNames.succeeded),
        failed: camelToSnake(actionCreatorNames.failed),

        // fetchMore
        fetchMore: camelToSnake(actionCreatorNames.fetchMore),
        fetchMoreSucceeded: camelToSnake(actionCreatorNames.fetchMoreSucceeded),
        fetchMoreFailed: camelToSnake(actionCreatorNames.fetchMoreFailed),
    };
    const actions = {
        request: Creators[actionCreatorNames.request],
        succeeded: Creators[actionCreatorNames.succeeded],
        failed: Creators[actionCreatorNames.failed],

        // fetchMore
        fetchMore: Creators[actionCreatorNames.fetchMore],
        fetchMoreSucceeded: Creators[actionCreatorNames.fetchMoreSucceeded],
        fetchMoreFailed: Creators[actionCreatorNames.fetchMoreFailed],
    };

    const model = {
        data: [],
        totalItems: 0,
        fetchingLoading: true,
        fetchingError: null,
        isLoadingMore: false,
        isLoadingMoreError: null,
        hasMore: false,
    };

    const reducer = {
        [mappedTypes.request]: (state, action) => {
            return {
                ...state,
                fetchingLoading: !action.isFetchingMore,
                fetchingError: null,
            };
        },
        [mappedTypes.fetchMore]: (state) => ({
            ...state,
            isLoadingMore: true,
            isLoadingMoreError: null,
        }),
        [mappedTypes.succeeded]: (state, action) => {
            return {
                ...state,
                data: action.data,
                totalItems: action.totalItems,
                fetchingLoading: false,
                fetchingError: null,
                hasMore: true,
            };
        },
        [mappedTypes.fetchMoreSucceeded]: (state, action) => {
            return {
                ...state,
                data: [...state.data, ...action.data],
                fetchingLoading: false,
                fetchingError: null,
                isLoadingMore: false,
                hasMore: action.data.length !== 0,
            };
        },
        [mappedTypes.failed]: (state, action) => ({
            ...state,
            fetchingLoading: false,
            fetchingError: action.error,
        }),
        [mappedTypes.fetchMoreFailed]: (state, action) => ({
            ...state,
            isFetchingMore: false,
            isLoadingMoreError: action.error,
        }),
    };

    let total = 0;

    const saga = (Service) =>
        function* paginationGenerator({ filters, isFetchingMore }) {
            if (isFetchingMore) {
                yield put(actions.fetchMore());
            }
            try {
                const response = yield call(Service.getAll, filters);
                // !! total is returned from BE only in cases where we have a list with pagination,
                // not in cases where we have a list with load more component
                const totalItems =
                    response.meta?.total !== undefined
                        ? response.meta.total
                        : total;
                total = totalItems;
                // !!
                if (isFetchingMore) {
                    yield put(
                        actions.fetchMoreSucceeded(response.data),
                    );
                    return;
                }
                yield put(actions.succeeded(response.data, totalItems));
            } catch (error) {
                if (isFetchingMore) {
                    yield put(actions.fetchMoreFailed(extractError(error)));
                    return;
                }
                yield put(actions.failed(extractError(error)));
            }
        };

    return {
        types,
        mappedTypes,
        model,
        actions,
        reducer,
        saga,
    };
};

export default Pagination;
