import BaseService from '@/services/BaseService';

class ActivityService extends BaseService {
    getAll = (filters) => this.post('/agency/activitieslist', filters || { });

    getOne = (id) => this.get(`/agency/viewactivity/${id}`);

    deleteOne = (id) => this.delete(`/agency/deleteactivity/${id}`);

    // add general details
    addGeneralInfo = (data) => this.post(
        '/agency/addactivityoverview',
        data,
    );

    // add time and duration
    addTimeDuration = (id, data) => this.post(
        `/agency/addactivitytimeandduration/${id}`,
        data,
    );

    // add partner
    addPartner = (id, data) => this.post(
        `/agency/addactivitypartner/${id}`,
        data,
    );

    // edit general details
    editGeneralInfo = (id, data) => this.post(
        `/agency/editactivityoverview/${id}`,
        data,
    );

    // edit time and duration
    editTimeDuration = (id, data) => this.post(
        `/agency/editactivitytimeandduration/${id}`,
        data,
    );

    // edit partner
    editPartner = (id, data) => this.post(
        `/agency/editactivitypartner/${id}`,
        data,
    );

    // view general data
    viewDetails = (id) => this.get(
        `/agency/viewactivityoverview/${id}`,
    );

    // view time and duration
    viewTimeAndDuration = (id) => this.get(
        `/agency/viewactivitytimeandduration/${id}`,
    );

    // get partner list

    getPartnerList = (search) => this.post(
        '/agency/getpartners',
        { name: search },
    );

    // view activity partner
    viewPartner = (id) => this.get(
        `/agency/viewactivitypartner/${id}`,
    );

    getActivityTimeList = (id, data) => this.post(
        `/agency/getactivitytimeslist/${id}`,
        data,
    );

    // other services
    getDaysOfWeek = () => this.get('/agency/getactivitydays');
}

export default new ActivityService();
