import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import DashboardService from '@/services/AppDashboardService';
import { types, actions } from './reducer';

function* getSalesOverview() {
    try {
        const response = yield call(DashboardService.getSalesOverview);
        const {
            salesOverView: totalSales,
            salesToday: todaySales,
            totalValues: total,
        } = response.data;
        yield put(actions.getSalesOverviewSucceeded(
            totalSales,
            todaySales,
            total,
        ));
    } catch (error) {
        yield put(actions.getSalesOverviewFailed(extractError(error)));
    }
}

function* getSalesByLocation() {
    try {
        const response = yield call(DashboardService.getSalesByLocation);
        const { salesByLocation: data } = response.data;
        yield put(actions.getSalesByLocationSucceeded(data));
    } catch (error) {
        yield put(actions.getSalesByLocationFailed(extractError(error)));
    }
}

function* getOrders() {
    try {
        const response = yield call(DashboardService.getOrders);
        yield put(actions.getOrdersSucceeded(response.data));
    } catch (error) {
        yield put(actions.getOrdersFailed(extractError(error)));
    }
}

function* getB2bServices() {
    try {
        const response = yield call(DashboardService.getB2bServices);
        yield put(actions.getB2bServicesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getB2bServicesFailed(extractError(error)));
    }
}

function* watchGetSalesOverview() {
    yield takeEvery(
        types.REQUEST_GET_SALES_OVERVIEW,
        getSalesOverview,
    );
}

function* watchGetSalesByLocation() {
    yield takeEvery(
        types.REQUEST_GET_SALES_BY_LOCATION,
        getSalesByLocation,
    );
}

function* watchGetOrders() {
    yield takeEvery(
        types.REQUEST_GET_ORDERS,
        getOrders,
    );
}

function* watchGetB2bServices() {
    yield takeEvery(
        types.REQUEST_GET_B2B_SERVICES,
        getB2bServices,
    );
}

function* dashboardSaga() {
    yield all([
        fork(watchGetSalesOverview),
        fork(watchGetSalesByLocation),
        fork(watchGetOrders),
        fork(watchGetB2bServices),
    ]);
}

export default dashboardSaga;
