import { useState } from 'react';
import { Image } from 'antd';

const PhotoGallery = ({ data, children }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Image
                className="default-image"
                preview={{ visible: false }}
                src={data[0]?.path}
                onClick={() => setVisible(true)}
            />
            <div className="image-collection">
                <Image.PreviewGroup
                    preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
                >
                    {data.map((image) => (
                        <Image key={image.id} src={image.path} />
                    ))}
                </Image.PreviewGroup>
            </div>
            {children}
        </>
    );
};

export default PhotoGallery;
