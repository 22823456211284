import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translateValidations } from '@/helpers/validation';

export default (config) => {
    const { t } = useTranslation();
    return useMemo(
        () => Object
            .keys(config)
            .reduce((validations, fieldKey) => ({
                ...validations,
                [fieldKey]: translateValidations(t, config[fieldKey]),
            }), {}),
        [config, t],
    );
};
