import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import InfoBlock from '@/components/Information/WithoutImage/Block';

const InvoiceTable = ({ data, currency }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: t('Created').toUpperCase(),
            dataIndex: 'created',
            sorter: true,
        },
        {
            title: t('Product /Service').toUpperCase(),
            dataIndex: 'product',
            sorter: true,
        },
        {
            title: t('Customer').toUpperCase(),
            dataIndex: 'customer',
            sorter: true,
            width: '15%',
        },
        {
            title: t('Amount').toUpperCase(),
            dataIndex: 'amount',
            sorter: true,
        },
        {
            title: t('Status').toUpperCase(),
            dataIndex: 'status',
            sorter: true,
            render: (text, record) => {
                if (record.status === 1) {
                    return <p className="paid">{t('Paid')}</p>;
                } else if (record.status === 2) {
                    return <p className="unpaid">{t('Unpaid')}</p>;
                } else if (record.status === 3) {
                    return <p className="canceled">{t('Canceled')}</p>;
                } else if (record.status === 4) {
                    return <p className="draft">{t('Draft')}</p>;
                } else if (record.status === 5) {
                    return <p className="partial">{t('Partial')}</p>;
                } else return null;
            },
        },
    ];

    const rowSelection = {};

    const datasource = data.map((row, i) => ({
        key: i,
        id: row.id,
        created:
    <InfoBlock name={row.created} className="textSize14" />,
        product:
    <InfoBlock
        name={row.service_name}
        className="textSize14"
    >
        <span className="textSize12">
            {row.service_type}
        </span>
    </InfoBlock>,
        customer:
    <InfoBlock name={`${row.name} ${row.surname}`} className="textSize14">
        <span className="textSize12">{row.nationality}</span>
    </InfoBlock>,
        amount:
    <InfoBlock name={currency.concat(row.amount)} className="textSize14">
        <span className="textSize12">
            {
                row.discount !== '0.00'
                    ? `${t('Discount')}: ${row.discount}`
                    : `${t('No discount')}`
            }
        </span>
    </InfoBlock>,
        status: row.status_id,
    }));

    return (
        <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={datasource}
            pagination={false}
            rowKey={(record) => record.id}
            size="small"
        />
    );
};

export default InvoiceTable;
