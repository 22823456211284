import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    service_name: {
        name: 'Service name',
        required: true,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    date: {
        name: 'Date',
        required: true,
    },
    price: {
        name: 'Price',
        required: true,
    },
    commission: {
        name: 'Commission',
        required: true,
    },
    commission_type: {
        name: 'Commission type',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
