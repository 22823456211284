import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    submitting: false,
    message: '',
    error: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.b2b.supplier.collect,
    model,
);

export default model;
