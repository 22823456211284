const model = {
    orders: [],
    salesOverview: [],
    invoices: null,
    totalInvoices: 0,
    loading: false,
    error: null,

    // compare
    total: null,
    cost: null,
    earn: null,
    status: [],
    customer: null,
};

export default model;
