import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // resend email code
    requestResendRegistrationEmailCode: null,
    resendRegistrationEmailCodeSucceeded: ['message'],
    resendRegistrationEmailCodeFailed: ['errors'],

    // verify email
    requestRegisterVerifyEmail: ['code'],
    registerVerifyEmailSucceeded: ['code'],
    registerVerifyEmailFailed: ['errors'],

    // clear resend email state
    clearResendEmailState: null,
});

export default {
    // resend email code
    [types.REQUEST_RESEND_REGISTRATION_EMAIL_CODE]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: true,
            errors: null,
        },
    }),
    [types.RESEND_REGISTRATION_EMAIL_CODE_SUCCEEDED]: (state, { message }) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            emailSuccess: message,
        },
    }),
    [types.RESEND_REGISTRATION_EMAIL_CODE_FAILED]: (state, { errors }) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            emailError: errors,
        },
    }),
    // verify email
    [types.REQUEST_REGISTER_VERIFY_EMAIL]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: true,
            errors: null,
        },
    }),
    [types.REGISTER_VERIFY_EMAIL_SUCCEEDED]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            registerStep: 2,
        },
    }),
    [types.REGISTER_VERIFY_EMAIL_FAILED]: (state, { errors }) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            errors,
        },
    }),

    // clear resend email state
    [types.CLEAR_RESEND_EMAIL_STATE]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            emailSuccess: '',
            emailError: null,
        },
    }),
};

export { types, actions };
