import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
    Row,
    Col,
    Card,
    Avatar,
    Divider,
} from 'antd';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import { DeleteIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/accommodation/hotel/view/reducer';
import { useCurrency } from '@/hooks/useCurrency';
import useNotification from '@/hooks/useNotification';

import ActionIcon from '../ActionIcon';

const PromotionCard = ({
    icon,
    title,
    category,
    duration,
    discount,
    discountType,
    bordered,
    className,
    children,
    id,
    deleteAction = false,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const messageSuccess = useSelector((state) => state.accommodation.hotel.view.messageSuccess);
    const messageError = useSelector((state) => state.accommodation.hotel.view.messageError);

    const deleteOffer = useCallback(() => {
        dispatch(actions.requestDeleteAccommodationOffer(id));
    }, [dispatch, id]);

    useNotification({
        messageSuccess,
        error: messageError,
        clearAction: actions.clearOfferActionState(),
    });

    return (
        <Card
            className={clsx('promotion-card', className)}
            bordered={bordered}
        >
            <Card.Meta
                avatar={(
                    <Avatar
                        size={80}
                        icon={icon}
                    />
                )}
                description={(
                    <Row>
                        <Col xs={9}>
                            <div className="title">
                                {t(title)}
                            </div>
                            <InlineDescription
                                label={t('Category')}
                                content={t(category)}
                                className="custom-color"
                            />
                            <InlineDescription
                                label={t('Duration')}
                                content={t(duration)}
                                className="custom-color"
                            />
                        </Col>

                        <Col lg={1}>
                            <Divider type="vertical" />
                        </Col>

                        <Col xs={12} className="discount-block">
                            <div className="title large-title">
                                {discountType === 'Percentage' ? `-%${discount}` : `-${currencyIcon}${discount}`}
                            </div>
                            <div className="discount-assign-title">
                                {`${t('Assign to')}:`}
                            </div>

                            <div className="discount-assign-content">
                                {children}
                            </div>
                        </Col>
                        <Col xs={2}>
                            {deleteAction && (
                                <ActionIcon onClick={() => deleteOffer()}>
                                    <DeleteIcon />
                                </ActionIcon>
                            )}
                        </Col>
                    </Row>
                )}
            />
        </Card>
    );
};

export default PromotionCard;
