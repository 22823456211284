import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Divider } from 'antd';
import clsx from 'clsx';
import { actions } from '@/redux/employees/create/reducer';
import { selectors } from '@/redux/employees/create/model';
import { useCurrency } from '@/hooks/useCurrency';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';

import Role from './Role';
import Salary from './Salary';
import baseValidations from './validations';

const formatGroups = (items) => items.map((item) => (
    parseInt(item.id, 10)
));

const formFields = [
    'salary_amount',
];

const JobPosition = ({ form, id }) => {
    const dispatch = useDispatch();
    const { str: currencyStr, id: currencyId } = useCurrency();

    const [roleSectionOpen, setRoleSectionOpen] = useState(false);
    const opacity = clsx(roleSectionOpen && 'opacity');

    const employeePersonalDetails = useSelector(selectors.personalDetails);

    const onFinish = useCallback((values) => {
        const data = {
            id,
            ...employeePersonalDetails,
            salary_amount: values.salary_amount,
            currency: currencyId,
            salary_period: values.period ? 1 : 2,
            group: values.group,
        };
        dispatch(actions.requestSubmitEmployeeDetails(data));
    }, [dispatch]);

    const details = useSelector(selectors.totalDetails);

    const initialData = useMemo(() => {
        if (details) {
            return {
                salary_amount: details.salary_amount,
                salary_period: details.period === 'Monthly' ? 2 : 1,
                group: details.groups ? formatGroups(details.groups) : [],
            };
        } else {
            return {
                salary_period: 1,
            };
        }
    }, [details]);

    const validations = useTranslatedValidationConfig(baseValidations);

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    return (
        <Form
            form={form}
            onFinish={onFinish}
            className="salary-job-position"
            initialValues={initialData}
        >
            <div className={opacity}>
                <Form.Item>
                    <Salary
                        roleSectionOpen={roleSectionOpen}
                        details={details}
                        currency={currencyStr}
                        validations={validations}
                    />
                </Form.Item>

                <Divider />
            </div>

            <Form.Item>
                <Role
                    opacity={opacity}
                    roleSectionOpen={roleSectionOpen}
                    setRoleSectionOpen={setRoleSectionOpen}
                    initialRoles={initialData?.group}
                />
            </Form.Item>
        </Form>
    );
};

export default JobPosition;
