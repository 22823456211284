import { Menu, Popover } from 'antd';
import { BellIcon } from '@/assets/images/icons/topbar';

const menu = (<Menu />);

const Notifications = () => (
    <Popover
        trigger={['click']}
        placement="bottom"
        content={menu}
    >
        <BellIcon />
    </Popover>
);

export default Notifications;
