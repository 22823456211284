import { createReducer } from 'reduxsauce';
import initialState from './model';

import forgotPasswordReduxHandlers, {
    types as forgotPasswordTypes,
    actions as forgotPasswordActions,
} from './forgotPassword/reducer';

import loginReduxHandlers, {
    types as loginTypes,
    actions as loginActions,
} from './login/reducer';

import registrationReduxHandlers, {
    types as registrationTypes,
    actions as registrationActions,
} from './registration/reducer';

const types = {
    ...loginTypes,
    ...forgotPasswordTypes,
    ...registrationTypes,
};

const actions = {
    ...loginActions,
    ...forgotPasswordActions,
    ...registrationActions,
};

export default createReducer(initialState, {
    ...forgotPasswordReduxHandlers,
    ...loginReduxHandlers,
    ...registrationReduxHandlers,
});

export { types, actions };
