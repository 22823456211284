import {
    Avatar,
    Col,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import BlockDescription from '@/components/Information/WithImage/Block';
import { CalendarIcon, ClockIcon } from '@/assets/images/icons/screens';
import SectionLayout from '../../SectionLayout';

const Activity = ({ reservationData }) => {
    const { t } = useTranslation();

    return reservationData !== undefined && (
        <SectionLayout
            className="tour-details"
            icon={(
                <Avatar
                    size={60}
                    src={reservationData.activity?.image || reservationData.image}
                />
            )}
            leftCol={3}
            rightCol={21}
        >
            <Row>
                <Col xs={12}>
                    <Typography.Title level={5}>
                        {reservationData.activity?.name || reservationData.name}
                    </Typography.Title>

                    <Typography.Paragraph>
                        {t('Location')}
                        {': '}
                        {reservationData.activity?.address || reservationData.address}
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        {t('Available Time')}
                        {': '}
                        {reservationData.activity?.start_date || reservationData.start_date}
                        {' - '}
                        {reservationData.activity?.end_date || reservationData.end_date}
                    </Typography.Paragraph>
                </Col>

                <Col xs={12}>
                    <div className="selected-times">
                        <div>
                            <BlockDescription
                                spaceSize={14}
                                icon={<CalendarIcon />}
                                label={t('Reserved Date')}
                                content={reservationData.date || reservationData.reserved_date}
                            />
                        </div>
                        <div>
                            <BlockDescription
                                spaceSize={14}
                                icon={<ClockIcon />}
                                label={t('Selected Start Times')}
                                content={
                                    reservationData.times
                                        ? reservationData.times.map((time, i) => (
                                            <div key={i.toString()}>{time.time}</div>
                                        ))
                                        : reservationData.days?.map((day) => (
                                            <div key={day.days_list}>{day.time}</div>
                                        ))
                                }
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default Activity;
