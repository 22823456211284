import { Avatar } from 'antd';

import Menu from './Menu';

const Cover = ({
    src,
    editUrl,
    deleteAction,
    type,
    isShared,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    title,
    setItemName,
    ...rest
}) => {
    return (
        <div className="cover">
            <Avatar
                className="trip-image"
                src={src}
                {...rest}
            />

            <Menu
                editUrl={editUrl}
                deleteAction={deleteAction}
                type={type}
                isShared={isShared}
                removeSharedServiceAction={removeSharedServiceAction}
                removeSharedCollectedServiceAction={removeSharedCollectedServiceAction}
                myService={myService}
                setItemName={setItemName}
                name={title}
            />
        </div>
    );
};

export default Cover;
