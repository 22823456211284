import { createActions } from 'reduxsauce';
import baseRegistrationModel from '../registration/model';

const { Types: types, Creators: actions } = createActions({
    // login
    requestLogin: ['data', 'rememberMe'],
    loginSucceeded: null,
    loginSucceededUncompleted: null,
    loginSucceededReview: null,
    loginFailed: ['error'],

    // logout
    requestLogout: null,
    logoutSucceeded: null,
    logoutFailed: ['error'],

    // fetchSelf
    requestFetchSelf: null,
    fetchSelfSucceeded: ['user', 'registerStep'],
    fetchSelfFailed: ['error'],
});

export default {
    // login
    [types.REQUEST_LOGIN]: (state) => {
        return {
            ...state,
            loggingIn: true,
            loggingInError: null,
        };
    },
    [types.LOGIN_SUCCEEDED]: (state) => ({
        ...state,
        authenticated: true,
        loggingIn: false,
    }),
    [types.LOGIN_SUCCEEDED_UNCOMPLETED]: (state) => {
        return {
            ...state,
            // authenticated: true,
            loggingIn: false,
        };
    },
    [types.LOGIN_SUCCEEDED_REVIEW]: (state) => {
        return {
            ...state,
            authenticated: true,
            authenticatedReview: true,
            loggingIn: false,
        };
    },
    [types.LOGIN_FAILED]: (state, { error }) => ({
        ...state,
        loggingIn: false,
        loggingInError: error,
    }),

    // logout
    [types.REQUEST_LOGOUT]: (state) => ({
        ...state,
        loggingOut: true,
        loggingOutError: null,
    }),
    [types.LOGOUT_SUCCEEDED]: (state) => ({
        ...state,
        authenticated: false,
        authenticatedReview: false,
        loggingOut: false,
        user: null,
        registration: baseRegistrationModel,
    }),
    [types.LOGOUT_FAILED]: (state, { error }) => ({
        ...state,
        loggingOut: false,
        loggingOutError: error,
    }),

    // fetchSelf
    [types.REQUEST_FETCH_SELF]: (state) => ({
        ...state,
        fetchingSelf: true,
        fetchingSelfError: null,
    }),
    [types.FETCH_SELF_SUCCEEDED]: (state, { user, registerStep }) => ({
        ...state,
        authenticated: true,
        fetchingSelf: false,
        authenticatedReview: registerStep === 5,
        loginSucceededUncompleted: registerStep < 5,
        user,
        registration: { ...state.registration, registerStep },
    }),
    [types.FETCH_SELF_FAILED]: (state, { error }) => ({
        ...state,
        authenticated: false,
        fetchingSelf: false,
        fetchingSelfError: error,
    }),
};

export { types, actions };
