import { Form } from 'antd';

import MultipleUpload from '@/components/Upload/Multiple';
import { allowedImageMIMETypes } from '@/constants/upload';

const ImagesUpload = ({ field }) => {
    return (
        <Form.Item name={[field.name, 'images']}>
            <MultipleUpload
                allowedMIMETypes={allowedImageMIMETypes}
                maxCount={5}
                className="upload-container"
                listType="picture-card"
            />
        </Form.Item>
    );
};

export default ImagesUpload;
