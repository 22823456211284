import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Typography,
} from 'antd';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import DashedContainer from '@/components/DashedContainer';

const ShipDetails = ({ data }) => {
    const { t } = useTranslation();

    let totalCabins = null;

    const cabins = data.length !== 0
        ? data.cabin_types.map((cabin) => cabin.number_of_cabins)
        : [];
    const reducer = (prev, curr) => prev + curr;
    totalCabins = cabins.length !== 0 && cabins.reduce(reducer, 0);

    return (
        data.length !== 0 && (
            <DashedContainer>
                <Row className="right-col_cards">
                    <Col span={24}>
                        <Space>
                            <div className="rooms-created-card_image">
                                <img src="backend-img" alt="backend-img" />
                            </div>
                            <div>
                                <Typography.Text>{data.ship_name}</Typography.Text>
                                <InlineDescription
                                    label={t('Cabin Types')}
                                    content={data.cabin_types?.length}
                                />

                                <InlineDescription
                                    label={t('Number of Cabins')}
                                    content={totalCabins}
                                />
                            </div>
                        </Space>
                    </Col>
                </Row>
            </DashedContainer>
        )
    );
};

export default ShipDetails;
