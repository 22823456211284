import { createActions } from 'reduxsauce';
import { upseartItem, removeSelectedItem } from '@/helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    // view accommodation data
    requestViewAccommodation: ['id'],
    viewAccommodationSucceeded: [
        'accommodationData',
        'roomTypes',
        'offers',
    ],
    viewAccommodationFailed: ['error'],

    // search for rooms
    requestFetchAccommodationRooms: ['id', 'filters', 'isFetchingMore'],
    fetchAccommodationRoomsSucceeded: ['rooms'],
    fetchAccommodationRoomsFailed: ['error'],

    // fetch more rooms
    fetchMoreAccommodationRooms: ['filters'],
    fetchMoreAccommodationRoomsSucceeded: ['rooms'],
    fetchMoreAccommodationRoomsFailed: ['error'],

    // save filters
    saveRoomFilterData: ['filters'],

    // add offer
    requestSubmitAccommodationOffer: ['data'],
    submitAccommodationOfferSucceeded: ['data', 'messageSuccess'],
    submitAccommodationOfferFailed: ['messageError'],

    // view offer
    requestViewAccommodationOffer: ['id'],
    viewAccommodationOfferSucceeded: ['data'],
    viewAccommodationOfferFailed: ['error'],

    // delete offer
    requestDeleteAccommodationOffer: ['id'],
    deleteAccommodationOfferSucceeded: ['id', 'messageSuccess'],
    deleteAccommodationOfferFailed: ['id', 'messageError'],

    // change form visibility
    setFormVisibleTrue: null,
    setFormVisibleFalse: null,

    // clear accommodation view state
    clearAccommodationViewState: ['null'],

    // clear offer action state
    clearOfferActionState: ['null'],
});

export default {
    // view accommodation data
    [types.REQUEST_VIEW_ACCOMMODATION]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_ACCOMMODATION_SUCCEEDED]: (
        state,
        {
            accommodationData,
            roomTypes,
            offers,
        },
    ) => ({
        ...state,
        view: {
            ...state.view,
            accommodationDetails: accommodationData,
            offerList: offers,
            roomTypeList: roomTypes,
            loading: false,
        },
    }),
    [types.VIEW_ACCOMMODATION_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // search for rooms
    [types.REQUEST_FETCH_ACCOMMODATION_ROOMS]: (state, { isFetchingMore }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: !isFetchingMore,
            fetchingError: null,
        },
    }),
    [types.FETCH_MORE_ACCOMMODATION_ROOMS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            isLoadingMore: true,
            isLoadingMoreError: null,
        },
    }),
    [types.FETCH_ACCOMMODATION_ROOMS_SUCCEEDED]: (state, { rooms }) => ({
        ...state,
        view: {
            ...state.view,
            roomList: rooms,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
        },
    }),
    [types.FETCH_MORE_ACCOMMODATION_ROOMS_SUCCEEDED]: (state, { rooms }) => ({
        ...state,
        view: {
            ...state.view,
            roomList: [...state.view.roomList, ...rooms],
            fetchingLoading: false,
            fetchingError: null,
            isLoadingMore: false,
            hasMore: rooms.length !== 0,
        },
    }),
    [types.FETCH_ACCOMMODATION_ROOMS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: false,
            fetchingError: error,
        },
    }),
    [types.FETCH_MORE_ACCOMMODATION_ROOMS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            isFetchingMore: false,
            isLoadingMoreError: error,
        },
    }),

    // save filters
    [types.SAVE_ROOM_FILTER_DATA]: (state, { filters }) => ({
        ...state,
        view: {
            ...state.view,
            filterData: filters,
        },
    }),

    // add offer
    [types.REQUEST_SUBMIT_ACCOMMODATION_OFFER]: (state) => ({
        ...state,
        view: {
            ...state.view,
            submitting: true,
            messageSuccess: '',
            messageError: null,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_OFFER_SUCCEEDED]: (state, { data, messageSuccess }) => ({
        ...state,
        view: {
            ...state.view,
            submitting: false,
            offerDetails: data,
            offerList: upseartItem(state.view.offerList, data),
            visibleForm: false,
            messageSuccess,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_OFFER_FAILED]: (state, { messageError }) => ({
        ...state,
        view: {
            ...state.view,
            submitting: false,
            visibleForm: true,
            messageError,
        },
    }),

    // view offer
    [types.REQUEST_VIEW_ACCOMMODATION_OFFER]: (state) => ({
        ...state,
        view: { ...state.view, loading: true },
    }),
    [types.VIEW_ACCOMMODATION_OFFER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            offerDetails: data,
        },
    }),
    [types.VIEW_ACCOMMODATION_OFFER_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // delete offer
    [types.REQUEST_DELETE_ACCOMMODATION_OFFER]: (state, { id }) => ({
        ...state,
        view: {
            ...state.view,
            id,
            loading: true,
            messageSuccess: '',
            messageError: null,
        },
    }),
    [types.DELETE_ACCOMMODATION_OFFER_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        view: {
            ...state.view,
            offerList: removeSelectedItem(state.view.offerList, id),
            id: null,
            loading: false,
            messageSuccess,
        },
    }),
    [types.DELETE_ACCOMMODATION_OFFER_FAILED]: (state, { messageError }) => ({
        ...state,
        view: {
            ...state.view,
            id: null,
            loading: false,
            messageError,
        },
    }),

    // change form visibility
    [types.SET_FORM_VISIBLE_TRUE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            visibleForm: true,
        },
    }),

    [types.SET_FORM_VISIBLE_FALSE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            visibleForm: false,
        },
    }),

    // clear accommodation view state
    [types.CLEAR_ACCOMMODATION_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            accommodationDetails: null,
            offerDetails: null,
            roomTypeList: [],
            roomList: [],
            filterData: null,
            loading: false,
            submitting: false,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
            isLoadingMore: false,
            isLoadingMoreError: null,
            visibleForm: false,
        },
    }),

    // clear offer action state
    [types.CLEAR_OFFER_ACTION_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            messageSuccess: '',
            messageError: null,
        },
    }),
};
