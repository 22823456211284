/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Card,
    Divider,
    Space,
    Image,
} from 'antd';

import InlineDescription from '@/components/Information/WithImage/Inline';
import { CalendarIcon, LocationIconBlue } from '@/assets/images/icons/screens';
import decodeHtml from '@/helpers/decodeHtml';

const CruiseItenerary = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Card className="tour-itenerary-list">
            {data.map((item) => (
                <div className="tour-itenerary-item" key={item.id}>
                    <Row>
                        <Col xxl={8} lg={24} sm={8} xs={24}>
                            <Image
                                src={item.image}
                                alt="image"
                                preview={false}
                            />
                        </Col>

                        <Col xxl={16} lg={24} sm={16} xs={24}>
                            <div className="tour-itenerary-content">
                                <Space size={10}>
                                    <div className="itenerary-day">
                                        <span>
                                            {item.day}
                                        </span>
                                    </div>
                                    <span>
                                        {item.title}
                                    </span>
                                </Space>
                                <div className="main-details">
                                    <div>
                                        <div className="location-icon-block">
                                            <LocationIconBlue />
                                        </div>
                                    </div>
                                    <span className="itenerary-address">{item.arrival_place}</span>
                                </div>

                                <InlineDescription
                                    icon={<CalendarIcon />}
                                    label={t(('Arrival time'))}
                                    content={item.arrival_time}
                                />

                                <InlineDescription
                                    icon={<CalendarIcon />}
                                    label={t(('Departure time'))}
                                    content={item.departure_time}
                                />

                                <Divider />

                                <div>
                                    <span className="itenerary-description">
                                        <div dangerouslySetInnerHTML={{ __html: decodeHtml(item.description) }} />
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ))}
        </Card>
    );
};

export default CruiseItenerary;
