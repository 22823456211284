import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Input,
    Space,
    Divider,
} from 'antd';
import ActionIcon from '@/components/ActionIcon';
import { DeleteIcon, EditIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/employees/create/reducer';

const EditSection = ({
    groups,
    defGroupName,
    setDefGroupName,
    groupSelectedId,
    setGroupSelected,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [editName, setEditNameVisible] = useState();

    const editGroupName = useCallback(() => {
        const data = {
            name: defGroupName,
        };
        dispatch(actions.requestEditGroup(groupSelectedId, data));
        setDefGroupName(defGroupName);
        setGroupSelected(defGroupName.concat('-', groupSelectedId));
    }, [defGroupName, groupSelectedId]);

    const deleteGroup = useCallback((id) => {
        dispatch(actions.requestDeleteGroup(parseInt(id, 10)));
        dispatch(actions.requestGetGroupRoles(groups[0]?.id));
        setGroupSelected(groups[0]?.name.concat('-', groups[0].id));
        setDefGroupName(groups[0]?.name);
    }, [groups]);

    return (
        editName ? (
            <Input
                value={defGroupName || groups[0]?.name}
                onChange={(e) => setDefGroupName(e.target.value)}
                addonAfter={(
                    <Button
                        className="input-button"
                        type="text"
                        onClick={() => {
                            editGroupName();
                            setEditNameVisible(false);
                        }}
                    >
                        {t('Save').toUpperCase()}
                    </Button>
                )}
            />
        ) : (
            <>
                <div className="role-action">
                    <span className="role-name">{defGroupName || groups[0]?.name}</span>
                    <div className="action-icon">
                        <Space size={20}>
                            <ActionIcon onClick={() => setEditNameVisible(true)}>
                                <EditIcon />
                            </ActionIcon>
                            <ActionIcon onClick={() => deleteGroup(groupSelectedId)}>
                                <DeleteIcon />
                            </ActionIcon>
                        </Space>
                    </div>
                </div>
                <div><Divider dashed /></div>
            </>
        )
    );
};

export default EditSection;
