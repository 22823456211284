import BaseService from '@/services/BaseService';

class ReportService extends BaseService {
    getInHouseReports = (filters) => this.post('/agency/report', filters || { });

    compareInHouseReports = (filters) => this.post('/agency/compare-report', filters || { });

    getB2bReports = (filters) => this.post('/agency/b2breport', filters || { });

    compareB2bReports = (filters) => this.post('/agency/compareb2breport', filters || { });
}

export default new ReportService();
