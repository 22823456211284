import { useState, useMemo, useEffect } from 'react';
import {
    Row,
    Col,
    Input,
    Radio,
    Checkbox,
    Form,
} from 'antd';
import { useTranslation } from 'react-i18next';

import MUIFormItem from '@/components/MUIFormItem';

const Details = ({
    field,
    formListName,
    childNumber,
    infantNumber,
    validations,
}) => {
    const { t } = useTranslation();

    const [hasChilds, setHasChilds] = useState(false);

    useEffect(() => {
        if (childNumber || infantNumber) {
            setHasChilds(true);
        }
    }, [childNumber, infantNumber]);

    const options = useMemo(() => ([
        { label: t('Per person'), value: 1 },
        { label: t('Per cabin'), value: 6 },
    ]), [t]);

    return (
        <Row gutter={[30, 30]}>
            <Col xxl={12} xs={24}>
                <MUIFormItem
                    label={t('Cabin Type')}
                    name={[field.name, 'cabin_type']}
                    formListName={formListName}
                    rules={validations.cabin_type}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xxl={12} xs={24}>
                <MUIFormItem
                    label={t('Number of Cabins')}
                    name={[field.name, 'number_of_cabins']}
                    formListName={formListName}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xxl={12} xs={24}>
                <MUIFormItem
                    label={`${t('No. of Adults')}: ${t('min')} - ${t('max')}`}
                    name={[field.name, 'number_of_adults']}
                    formListName={formListName}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xxl={12} xs={24}>
                <MUIFormItem
                    name={[field.name, 'price_of_adults']}
                >
                    <Input
                        suffix={(
                            <Form.Item
                                name={[field.name, 'pricingType']}
                                noStyle
                            >
                                <Radio.Group
                                    options={options}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>
                        )}
                        placeholder="0.00"
                    />
                </MUIFormItem>
            </Col>
            <Col lg={24} xs={24}>
                <Form.Item>
                    <Checkbox
                        checked={hasChilds}
                        onChange={(e) => setHasChilds(e.target.checked)}
                    >
                        {t('Add Children or Infants')}
                    </Checkbox>
                </Form.Item>
            </Col>

            {hasChilds && (
                <>
                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            label={`${t('Max. Children')}:`}
                            name={[field.name, 'number_of_children']}
                            formListName={formListName}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name={[field.name, 'price_of_children']}
                        >
                            <Input placeholder={`0.00 / ${t('child')}`} />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            label={`${t('Max. Infants')}:`}
                            name={[field.name, 'number_of_infants']}
                            formListName={formListName}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name={[field.name, 'price_of_infants']}
                            formListName={formListName}
                        >
                            <Input placeholder={`0.00 / ${t('infant')}`} />
                        </MUIFormItem>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default Details;
