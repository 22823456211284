import { all, fork, takeEvery } from 'redux-saga/effects';

import pagination from '@/helpers/pagination';
import FlightService from '@/services/FlightService';
import { types } from './reducer';

function* watchFetchFlights() {
    yield takeEvery(
        types.REQUEST_FETCH_FLIGHTS,
        pagination('flights').saga(FlightService),
    );
}

function* flightSaga() {
    yield all([
        fork(watchFetchFlights),
    ]);
}

export default flightSaga;
