import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import { paddNumber } from '@/helpers/string';
import InfoBlock from '@/components/Information/WithoutImage/Block';
import ParticipantsList from './ParticipantsList';

const Participants = ({ participants }) => {
    const { t } = useTranslation();

    return (
        <InfoBlock name={t('Adults')}>
            <Tooltip
                overlayClassName="participants-tooltip"
                placement="bottomRight"
                title={<ParticipantsList participants={participants} />}
            >
                {participants !== undefined
                    ? `${paddNumber(participants.length, 2)} ${t('Adults')}`
                    : `${paddNumber(0, 2)} ${t('Adults')}`}
            </Tooltip>
        </InfoBlock>
    );
};

export default Participants;
