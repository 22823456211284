import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import InvoiceService from '@/services/InvoiceService';
import { types, actions } from './reducer';

function* deleteInvoice({ id }) {
    try {
        yield call(InvoiceService.deleteOne, id);
        yield put(actions.deleteInvoiceSucceeded(id, 'invoice deleted successfully'));
    } catch (error) {
        yield put(actions.deleteInvoiceFailed(extractError(error)));
    }
}

function* watchDeleteInvoice() {
    yield takeEvery(
        types.REQUEST_DELETE_INVOICE,
        deleteInvoice,
    );
}

function* invoiceDeleteSaga() {
    yield all([
        fork(watchDeleteInvoice),
    ]);
}

export default invoiceDeleteSaga;
