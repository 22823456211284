import { Row, Col, Steps } from 'antd';
import { useTranslation } from 'react-i18next';

const StepDisplay = ({ step: currentStep }) => {
    const { t } = useTranslation();

    return (
        <Row
            className="step-display"
            justify="center"
        >
            <Col>
                <Steps
                    className="steps-container"
                    current={currentStep}
                    labelPlacement="vertical"
                    items={[

                        { title: t('Billed For') },
                        { title: t('Invoice To') },
                        { title: t('Extra') },
                    ]}
                />
            </Col>
        </Row>
    );
};

export default StepDisplay;
