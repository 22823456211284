import { useTranslation } from 'react-i18next';
import {
    Card,
    Space,
    Image,
    Row,
    Col,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import DataContainer from '@/components/DataContainer';
import GoogleMap from '@/components/GoogleMap';
import { useCurrency } from '@/hooks/useCurrency';
import decodeHtml from '@/helpers/decodeHtml';

const GeneralDetails = ({ data }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    return (
        <DataContainer title={t('General Details')}>
            <Space direction="vertical">
                <BlockDescription
                    name={t('Activity Name')}
                >
                    {data.name}
                </BlockDescription>

                <BlockDescription
                    name={t('Availability')}
                >
                    {data.start_date}
                    {' - '}
                    {data.end_date}
                </BlockDescription>

                <BlockDescription
                    name={t('Default Price')}
                >
                    {`${currencyIcon}${data.price} / ${data.price_type}`}
                </BlockDescription>

                <BlockDescription
                    name={t('Description')}
                >
                    <div dangerouslySetInnerHTML={{ __html: decodeHtml(data.description) }} />
                </BlockDescription>

                <BlockDescription
                    name={t('Location')}
                >
                    {data.address}
                </BlockDescription>

                <Card>
                    <GoogleMap latitude={data.latitude} longitude={data.longitude} />
                </Card>

                <BlockDescription>
                    <Row gutter={20}>
                        {data.images.map((image, index) => (
                            <Col
                                // eslint-disable-next-line react/no-array-index-key
                                key={(index).toString()}
                                span={6}
                            >
                                <Image src={image.path} preview={false} />
                            </Col>
                        ))}
                    </Row>
                </BlockDescription>
            </Space>
        </DataContainer>
    );
};

export default GeneralDetails;
