import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Select,
    TimePicker,
    Input,
    Checkbox,
    Button,
} from 'antd';
import useActivityDaysOfWeek from '@/hooks/useActivityDaysOfWeek';
import MUIFormItem from '@/components/MUIFormItem';
import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import { DeleteIcon } from '@/assets/images/icons/screens';
import { useCurrency } from '@/hooks/useCurrency';

const TimeItem = ({
    field,
    remove,
    initialDays,
    formListName,
}) => {
    const { t } = useTranslation();
    const { data } = useActivityDaysOfWeek();
    const { icon: currencyIcon } = useCurrency();

    const [inputFields, setInputFields] = useState();

    const onSelect = (value) => {
        setInputFields(value);
    };

    const [checked, setChecked] = useState(false);

    return (
        <Row gutter={[15, 0]}>
            <Col xs={24}>
                <Form.Item name={[field.name, 'days']}>
                    <CheckboxMultiSelect
                        options={data?.data}
                        labelParamName="name"
                        valueParamName="id"
                        hasSelectAll={false}
                        col={3}
                        initialCheckedList={initialDays}
                    />
                </Form.Item>
            </Col>

            <Col xs={24}>
                <MUIFormItem
                    label={`${t('How many times in day')}?`}
                    name={[field.name, 'times']}
                    formListName={formListName}
                >
                    <Select onSelect={onSelect}>
                        <Select.Option value={1}>{`1 ${t('times')}`}</Select.Option>
                        <Select.Option value={2}>{`2 ${t('times')}`}</Select.Option>
                        <Select.Option value={3}>{`3 ${t('times')}`}</Select.Option>
                        <Select.Option value={4}>{`4 ${t('times')}`}</Select.Option>
                    </Select>
                </MUIFormItem>
            </Col>

            <Col xs={24}>
                {new Array(inputFields).fill(null).map((_, i) => (
                    <Row key={(i).toString()} gutter={20}>
                        <Col xxl={12} xs={24} className="timeslot-form">
                            <Form.Item
                                name={[field.name, `startTime${(i).toString()}`]}
                            >
                                <TimePicker
                                    placeholder={t('Start time')}
                                    format="HH:mm a"
                                />
                            </Form.Item>
                        </Col>

                        <Col xxl={12} xs={24} className="timeslot-form">
                            <Form.Item
                                name={[field.name, `price${(i).toString()}`]}
                            >
                                <Input
                                    disabled={checked}
                                    placeholder={t('Price')}
                                    suffix={`${currencyIcon}0.00 / ${t('person')}`}
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={11} push={12} className="default-price-check">
                            <Form.Item
                                name={[field.name, `defaultPrice${(i).toString()}`]}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                >
                                    {t('or Default Price')}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                ))}
            </Col>

            <Col>
                <Form.Item>
                    <Button
                        className="delete-button-outlined"
                        onClick={() => remove(field.name)}
                    >
                        <DeleteIcon />
                        {t('Delete this timeslot').toUpperCase()}
                    </Button>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default TimeItem;
