import { Row, Col, Divider } from 'antd';
import decodeHtml from '@/helpers/decodeHtml';
import ViewButton from '../ViewButton';

const truncateText = (text, limit = 250) => {
    if (text.length > limit) {
        return text.substring(0, limit).concat('...');
    }
    return text;
};

const GridDescription = ({
    location,
    description,
    size,
    price,
    url,
    duration, // from activities
    registerStep,
    totalStep,
    type,
    agency,
}) => {
    const decodedHtml = decodeHtml(description);
    const truncatedDescription = truncateText(decodedHtml, 250);
    return (
        <div>
            <Row justify="center" gutter={[0, 0]}>
                <Col lg={duration ? 24 : ''}>
                    <div className="location">
                        {location}
                    </div>
                </Col>
                <Col lg={24}>
                    <div className="date">
                        {duration}
                    </div>
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <div className="description">
                        <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                    </div>
                </Col>
            </Row>
            <div className="divider-wrapper">
                <Divider />
            </div>

            <Row justify="space-between" align="middle">
                <Col>
                    <div className="agency">
                        {agency}
                    </div>

                    <div className="size">
                        {size}
                    </div>

                    <div className="price">
                        {price}
                    </div>
                </Col>

                <Col>
                    <ViewButton
                        url={url}
                        registerStep={registerStep}
                        totalStep={totalStep}
                        type={type}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default GridDescription;
