import { useEffect } from 'react';
import { notification, Space } from 'antd';

const useApiValidationsForForm = ({ form, formFields, errors }) => {
    let fields = formFields;
    useEffect(() => {
        if (errors !== null && typeof errors === 'object') {
            if (errors.name === 'Extra service name should not be blank.') {
                const updatedFormFields = formFields.filter((field) => field !== 'name');
                fields = updatedFormFields;
            }
            const fieldsConfig = fields
                ?.filter((field) => errors[field])
                ?.map((field) => ({
                    name: field, touched: false, errors: [errors[field]],
                }));
            form.setFields(fieldsConfig)
            || notification.error({
                description: <Space direction="vertical">{Object.values(errors)}</Space>,
                duration: 0,
            });
        } else {
            errors !== null && notification.error({ message: errors });
        }
    }, [form, errors, formFields]);
};

export default useApiValidationsForForm;
