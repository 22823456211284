import { useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import MultistepFormActions from '@/components/MultistepFormActions';
import { actions } from '@/redux/tour/trip/reducer';

import GeneralDetails from './GeneralDetails';
import InclusionsExclusions from './InclusionsExclusions';
import Itenerary from './Itenerary';
import Steps from './Steps';

const formSteps = [GeneralDetails, Itenerary, InclusionsExclusions];

const New = ({ isEdit = false }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { id: urlId } = useParams();
    const reduxId = useSelector((state) => state.tour.trip.create.generalInfo?.id);
    const regStep = useSelector((state) => state.tour.trip.create.generalInfo?.tour_register_step);
    const step = useSelector((state) => state.tour.trip.create.step);

    const id = useMemo(() => {
        return isEdit ? urlId : reduxId;
    }, [isEdit, urlId, reduxId]);
    const submitting = useSelector(
        (state) => state.tour.trip.create.submitting,
    );

    const goToStep = useCallback((s) => {
        dispatch(actions.goToCreateTripStep(s));
    }, [dispatch]);

    const FormStepComponent = useMemo(() => {
        if (step >= 0 && step < formSteps.length) {
            return formSteps[step];
        } else if (formSteps.length === step) {
            return formSteps[formSteps.length - 1];
        } else {
            return formSteps[0];
        }
    }, [step]);

    useEffect(() => {
        if (isEdit && id) {
            dispatch(actions.requestFetchTripData(id));
        }
    }, [isEdit, dispatch, id]);

    return (
        <Row id="add-tour-trip-page" gutter={[30, 30]}>
            <Col
                lg={{ span: 14, order: 1 }}
                xs={{ span: 24, order: 2 }}
            >
                <div className="left-col">
                    <FormStepComponent
                        form={form}
                        id={id}
                        isEdit={isEdit}
                        regStep={regStep}
                    />
                </div>
            </Col>

            <Col
                lg={{ span: 10, order: 2 }}
                xs={{ span: 24, order: 1 }}
            >
                <div className="right-col-container">
                    <div className="right-col">
                        <Steps step={step} />
                    </div>
                    <MultistepFormActions
                        stepCount={3}
                        step={step}
                        submitting={submitting}
                        onSubmit={() => {
                            form.submit();
                        }}
                        onBack={() => goToStep(step - 1)}
                        viewOneUrl={
                            (id !== undefined)
                                ? `/app/tours/trip/${id}`
                                : undefined
                        }
                        viewOneButtonText="View this tour"
                        viewAllUrl="/app/tours/trip/"
                        createUrl="/app/tours/trip/add/"
                        onPublish={console.log}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default New;
