import { Link } from 'react-router-dom';

const Title = ({ url, title }) => {
    return (
        <Link className="title" to={url}>
            <div>
                {title}
            </div>
        </Link>
    );
};

export default Title;
