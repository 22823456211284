import {
    takeEvery,
    select,
    all,
    put,
    fork,
    call,
} from 'redux-saga/effects';

import { extractError } from '@/helpers/api';
import CruiseService from '@/services/TourServices/CruiseService';
import { types, actions } from './reducer';
import { selectors } from './model';

function* isEdit(step) {
    const completedStep = yield select(selectors.completedStep);
    if (completedStep === null) {
        return false;
    }
    return completedStep >= step;
}

function* submitCruiseGeneralDetails({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        if (edit) {
            const { id } = data;
            response = yield call(CruiseService.editGeneralInfo, id, data);
        } else {
            response = yield call(CruiseService.addGeneralInfo, data);
        }
        yield put(actions.submitCruiseGeneralDetailsSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitCruiseGeneralDetailsFailed(extractError(error)));
    }
}

function* getCruiseShipList({ filters }) {
    try {
        const response = yield call(CruiseService.getShipList, filters);
        yield put(actions.getCruiseShipListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getCruiseShipListFailed(extractError(error)));
    }
}

function* createCruiseShip({ data }) {
    try {
        const ship = yield select(selectors.ship); // state from view data
        let res;
        if (ship !== null) {
            const { id: shipId } = yield select(selectors.ship);
            res = yield call(CruiseService.editShipOnClick, shipId, data);
        } else {
            res = yield call(CruiseService.addNewShip, data);
        }
        // formating the data before display in list
        const cabins = res.data.cabin_types.map((cabin) => cabin.number_of_cabins);
        const reducer = (prev, curr) => prev + curr;
        const totalCabins = cabins.reduce(reducer);

        yield put(actions.createCruiseShipSucceeded({
            id: res.data.id,
            name: res.data.name,
            cabin_types: res.data.cabin_types.length,
            cabins: totalCabins,
        }));
    } catch (error) {
        yield put(actions.createCruiseShipFailed(extractError(error)));
    }
}

function* viewShip({ id }) {
    try {
        const res = yield call(CruiseService.viewShipOnClick, id);
        yield put(actions.viewShipSucceeded(res.data));
    } catch (error) {
        yield put(actions.viewShipFailed(extractError(error)));
    }
}

function* deleteShip({ id }) {
    try {
        yield call(CruiseService.deleteShip, id);
        yield put(actions.deleteShipSucceeded(id));
    } catch (error) {
        yield put(actions.deleteShipFailed(extractError(error)));
    }
}

function* submitCruiseShipDetails({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        const { id: tourId } = yield select(selectors.generalInfo);
        if (edit) {
            response = yield call(CruiseService.editShip, tourId, data);
        } else {
            response = yield call(CruiseService.submitShip, tourId, data);
        }
        yield put(actions.submitCruiseShipDetailsSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitCruiseShipDetailsFailed(extractError(error)));
    }
}

const filterArray = (initialData, submittedData) => {
    const initial = initialData.map((item) => {
        return item.id;
    });
    const submitted = submittedData.map((item) => {
        return item.id;
    });
    const filtered = initial.filter((item) => {
        return submitted.indexOf(item) === -1;
    });
    return filtered;
};

function* submitCruiseItenerary({ data, currStep, complStep }) {
    try {
        const submittedData = data.itinerary;
        const initialData = yield select(selectors.itenerary);
        const deletedItems = filterArray(initialData, submittedData);
        const finalData = {
            itinerary: submittedData,
            deleted: deletedItems,
        };

        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;

        const { id: tourId } = yield select(selectors.generalInfo);
        if (edit) {
            response = yield call(CruiseService.editItenerary, tourId, finalData);
        } else {
            response = yield call(CruiseService.addItenerary, tourId, data);
        }
        yield put(actions.submitCruiseItenerarySucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitCruiseIteneraryFailed(extractError(error)));
    }
}

function* submitCruiseInclusionsExclusions({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        const { id: tourId } = yield select(selectors.generalInfo);
        if (edit) {
            response = yield call(CruiseService.editInclusionsExclusions, tourId, data);
        } else {
            response = yield call(CruiseService.addInclusionsExclusions, tourId, data);
        }
        const {
            inclusions: { selected: inclusions },
            exclusions: { selected: exclusions },
        } = response.data;
        yield put(actions.submitCruiseInclusionsExclusionsSucceeded(
            inclusions,
            exclusions,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitCruiseInclusionsExclusionsFailed(extractError(error)));
    }
}

function* fetchCruiseData({ id }) {
    try {
        const [
            generalData,
            shipDetails,
            itenerary,
            inclExcl,
        ] = yield all([
            call(CruiseService.viewDetails, id),
            call(CruiseService.viewShip, id),
            call(CruiseService.viewItenerary, id),
            call(CruiseService.viewInclusionsExclusions, id),
        ]);
        const {
            inclusions: { selected: inclusions },
            exclusions: { selected: exclusions },
        } = inclExcl.data;

        yield put(actions.fetchCruiseDataSucceeded(
            generalData.data,
            shipDetails.data,
            itenerary.data,
            inclusions,
            exclusions,
        ));
    } catch (error) {
        yield put(actions.fetchCruiseDataFailed(extractError(error)));
    }
}

function* getCruiseTypes({ data }) {
    try {
        const res = yield call(CruiseService.getCruiseTypes, data);
        yield put(actions.getCruiseTypesSucceeded(res.data));
    } catch (error) {
        yield put(actions.getCruiseTypesFailed(extractError(error)));
    }
}

function* addNewCruiseType({ data }) {
    try {
        const res = yield call(CruiseService.addNewCruiseType, data);
        yield put(actions.addNewCruiseTypeSucceeded(res.data));
    } catch (error) {
        yield put(actions.addNewCruiseTypeFailed('Cruise style cannot be empty'));
    }
}

function* watchSubmitCruiseGeneralDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CRUISE_GENERAL_DETAILS,
        submitCruiseGeneralDetails,
    );
}

function* watchSubmitCruiseShipDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CRUISE_SHIP_DETAILS,
        submitCruiseShipDetails,
    );
}

function* watchGetCruiseShipList() {
    yield takeEvery(
        types.REQUEST_GET_CRUISE_SHIP_LIST,
        getCruiseShipList,
    );
}

function* watchCreateCruiseShip() {
    yield takeEvery(
        types.REQUEST_CREATE_CRUISE_SHIP,
        createCruiseShip,
    );
}

function* watchViewShip() {
    yield takeEvery(
        types.REQUEST_VIEW_SHIP,
        viewShip,
    );
}

function* watchDeleteShip() {
    yield takeEvery(
        types.REQUEST_DELETE_SHIP,
        deleteShip,
    );
}

function* watchSubmitCruiseItenerary() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CRUISE_ITENERARY,
        submitCruiseItenerary,
    );
}

function* watchSubmitCruiseInclusionsExclusions() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CRUISE_INCLUSIONS_EXCLUSIONS,
        submitCruiseInclusionsExclusions,
    );
}

function* watchFetchCruiseData() {
    yield takeEvery(
        types.REQUEST_FETCH_CRUISE_DATA,
        fetchCruiseData,
    );
}

function* watchGetCruiseTypes() {
    yield takeEvery(
        types.REQUEST_GET_CRUISE_TYPES,
        getCruiseTypes,
    );
}

function* watchAddNewCruiseType() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_CRUISE_TYPE,
        addNewCruiseType,
    );
}

function* createCruiseSaga() {
    yield all([
        fork(watchSubmitCruiseGeneralDetails),
        fork(watchSubmitCruiseShipDetails),
        fork(watchGetCruiseShipList),
        fork(watchCreateCruiseShip),
        fork(watchViewShip),
        fork(watchDeleteShip),
        fork(watchSubmitCruiseItenerary),
        fork(watchSubmitCruiseInclusionsExclusions),
        fork(watchFetchCruiseData),
        fork(watchGetCruiseTypes),
        fork(watchAddNewCruiseType),
    ]);
}

export default createCruiseSaga;
