import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import CruiseService from '@/services/TourServices/CruiseService';
import { types, actions } from './reducer';

function* deleteTourCruise({ id }) {
    try {
        yield call(CruiseService.deleteOne, id);
        yield put(actions.deleteTourCruiseSucceeded(id, 'cruise deleted successfully'));
    } catch (error) {
        yield put(actions.deleteTourCruiseFailed(extractError(error)));
    }
}

function* watchDeleteTourCruise() {
    yield takeEvery(
        types.REQUEST_DELETE_TOUR_CRUISE,
        deleteTourCruise,
    );
}

function* cruiseDeleteSaga() {
    yield all([
        fork(watchDeleteTourCruise),
    ]);
}

export default cruiseDeleteSaga;
