import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    extraServiceName: {
        name: 'Extra service name',
        required: true,
    },
    // extraServicePrice: {
    //     name: 'Extra service price',
    //     required: true,
    // },
};

export default buildValidationsFromConfig(config);
