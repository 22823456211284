/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { replaceUpdatedItem, removeSelectedItem } from '@/helpers/CRUD';
import UploadService from '@/services/UploadService';

export const generateOnStartHandler = ({
    canUploadFile,
    isFileAllowedForUpload,
    setFileList,
}) => useCallback((file) => {
    if (canUploadFile && isFileAllowedForUpload(file)) {
        file.status = 'uploading'; // eslint-disable-line no-param-reassign
        file.percent = 0; // eslint-disable-line no-param-reassign
        file.thumbUrl = URL.createObjectURL(file); // eslint-disable-line no-param-reassign
        setFileList((prev) => prev.concat(file));
    }
}, [canUploadFile, isFileAllowedForUpload, setFileList]);

export const generateOnProgressHandler = ({
    setFileList,
}) => useCallback(({ percent }, file) => {
    file.status = 'uploading'; // eslint-disable-line no-param-reassign
    file.percent = percent; // eslint-disable-line no-param-reassign
    setFileList((prev) => replaceUpdatedItem(prev, file, 'uid'));
}, [setFileList]);

export const generateOnSuccessHandler = ({
    setFileList,
}) => useCallback((res, file) => {
    file.url = res.data.data; // eslint-disable-line no-param-reassign
    file.status = 'done'; // eslint-disable-line no-param-reassign
    setFileList((prev) => replaceUpdatedItem(prev, file, 'uid'));
}, [setFileList]);

export const generateOnRemoveHandler = ({
    setFileList,
}) => useCallback((removedFile) => {
    setFileList((prev) => removeSelectedItem(prev, removedFile.uid, 'uid'));
}, [setFileList]);

export const generateOnErrorHandler = ({
    setFileList,
}) => useCallback((error, _, file) => {
    file.status = 'error'; // eslint-disable-line no-param-reassign
    file.error = error; // eslint-disable-line no-param-reassign
    setFileList((prev) => replaceUpdatedItem(prev, file, 'uid'));
}, [setFileList]);

export const generateCustomRequestHandler = ({
    canUploadFile,
    isFileAllowedForUpload,
}) => useCallback(({
    file,
    onProgress,
    onSuccess,
    onError,
}) => {
    const controller = new AbortController();

    if (canUploadFile && isFileAllowedForUpload(file)) {
        const onUploadProgress = ({ total, loaded }) => {
            const percent = Math.round((loaded / total) * 100).toFixed(2);
            onProgress({ percent }, file);
        };
        UploadService
            .uploadFile(file, { onUploadProgress, signal: controller.signal })
            .then((res) => onSuccess(res, file))
            .catch(onError);
    }

    return { abort: controller.abort };
}, [canUploadFile, isFileAllowedForUpload]);
