import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import { useSelector } from 'react-redux';

import BlockDescription from '@/components/Information/WithImage/Block';
import { selectors } from '@/redux/tour/trip/view/model';
import { TagsIcon } from '@/assets/images/icons/screens';

const Extras = () => {
    const { t } = useTranslation();

    const generalInfo = useSelector(selectors.generalInfo);
    const extras = generalInfo.extra_services;

    return (
        <>
            <Divider />

            <span className="extras-title">{t('Extras')}</span>

            <Row className="accomodation-block-details extras-block" gutter={[0, 15]}>
                {extras.map((extra) => (
                    <Col key={extra.id} lg={8}>
                        <BlockDescription
                            spaceSize={15}
                            icon={<TagsIcon />}
                            label={extra.name}
                            content={`${extra.price} / ${t('person')}`}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default Extras;
