import { all, fork, takeEvery } from 'redux-saga/effects';
import pagination from '@/helpers/pagination';
import TaxesService from '@/services/SettingsServices/TaxesService';
import { types } from './reducer';

function* watchFetchTaxList() {
    yield takeEvery(
        types.REQUEST_FETCH_TAX_LIST,
        pagination('taxList').saga(TaxesService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchTaxList),
    ]);
}

export default paginationSaga;
