import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Divider,
    Button,
} from 'antd';
import { HorizontalMoreOutlinedIcon } from '@/assets/images/icons/screens';
import Title from '../Title';
import Menu from '../Menu';
import ButtonContent from '../ButtonContent';

const ListDescription = ({
    name,
    address,
    rooms,
    facilities,
    rate,
    price,
    isList,
    viewUrl,
    reservationUrl,
    editUrl,
    deleteAction,
    type,
    totalStep,
    registerStep,
    isShared,
    agency,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    currency,
    setItemName,
}) => {
    const { t } = useTranslation();

    const visibleRow = facilities.length >= 6 ? facilities.slice(0, 5) : facilities;
    const hiddenRow = facilities.length >= 6 ? facilities.slice(5, facilities.length) : [];
    const hiddenRowLength = hiddenRow.length;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Row justify="space-between">
            <Col xs={19}>
                <div>
                    <Title
                        name={name}
                        rate={rate}
                        isList={isList}
                        url={viewUrl}
                    />
                </div>

                <div>
                    {address}
                </div>

                <div>
                    {rooms}
                </div>

                <div>
                    {agency}
                </div>

                <Divider />

                <Space size={21}>
                    <div>
                        {visibleRow.map((item) => (
                            <span className="facilities" key={item.id}>{item.name}</span>
                        ))}
                    </div>
                    {!isOpen && hiddenRowLength !== 0 && (
                        <Button
                            className="view-more-button"
                            onClick={() => setIsOpen(true)}
                        >
                            <HorizontalMoreOutlinedIcon />
                            {' '}
                            {`${hiddenRowLength} ${t('more')}`}
                        </Button>
                    )}
                </Space>

                {isOpen && (
                    <Space size={21}>
                        <div>
                            {hiddenRow.map((item) => (
                                <span className="facilities" key={item.id}>{item.name}</span>
                            ))}
                        </div>
                        <Button
                            className="view-more-button"
                            onClick={() => setIsOpen(false)}
                        >
                            {t('view less')}
                        </Button>
                    </Space>
                )}
            </Col>

            <Col xs={5}>
                <Space>
                    <Divider type="vertical" className={!myService && 'change-margin'} />

                    <ButtonContent
                        price={price}
                        isList={isList}
                        viewUrl={viewUrl}
                        reservationUrl={reservationUrl}
                        totalStep={totalStep}
                        registerStep={registerStep}
                        type={type}
                        currency={currency}
                    />

                    <Menu
                        editUrl={editUrl}
                        deleteAction={deleteAction}
                        type={type}
                        isShared={isShared}
                        removeSharedServiceAction={removeSharedServiceAction}
                        removeSharedCollectedServiceAction={
                            removeSharedCollectedServiceAction
                        }
                        myService={myService}
                        name={name}
                        setItemName={setItemName}
                    />
                </Space>
            </Col>
        </Row>
    );
};

export default ListDescription;
