/* eslint-disable no-unused-vars */
import {
    useState,
    Children,
    cloneElement,
    useRef,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { Form } from 'antd';
import clsx from 'clsx';

const combinePropFunctions = (
    component,
    baseFunctionName,
    ...additionalFunctions
) => (...args) => {
    if (component.props[baseFunctionName]) {
        component.props[baseFunctionName](...args);
    }
    for (let i = 0; i < additionalFunctions.length; i++) {
        additionalFunctions[i](...args);
    }
};

const MUIFormItem = ({
    children, className, name, formListName, ...props
}) => {
    const ref = useRef();
    const [isActive, setIsActive] = useState(false);
    const [value, setValue] = useState();

    const onInputChange = useCallback((e) => {
        if (typeof e === 'object' && e !== null) {
            if (e && ('_reactName' in e)) {
                setValue(e?.target?.value);
            } else if ('value' in e) {
                setValue(e.value);
            } else {
                setValue(e);
            }
        } else {
            setValue(e);
        }
    }, [setValue]);

    const Input = useMemo(() => {
        if (Children.count(children) === 1) {
            const child = Children.only(children);
            return cloneElement(Children.only(child), {
                ref,
                onChange: combinePropFunctions(
                    child,
                    'onChange',
                    onInputChange,
                ),
                onFocus: combinePropFunctions(
                    child,
                    'onChange',
                    () => setIsActive(true),
                ),
                onBlur: combinePropFunctions(
                    child,
                    'onChange',
                    () => setIsActive(false),
                ),
            });
        } else {
            return children;
        }
    }, [children, onInputChange, setIsActive]);

    // useEffect(() => {
    //     if (
    //         ref
    //         && ref?.current
    //         && ref?.current.props?.value
    //     ) {
    //         setValue(ref?.current.props?.value);
    //     }
    // }, [setValue]);

    return (
        <Form.Item shouldUpdate>
            {({ getFieldValue }) => (
                <Form.Item
                    name={name}
                    className={clsx(
                        'mui-form-item',
                        isActive && 'active',
                        (getFieldValue(name)
                        || getFieldValue(name)?.toString()
                        || getFieldValue([formListName, ...name])
                        || value) && 'filled',
                        className,
                    )}
                    {...props}
                >
                    {Input}
                </Form.Item>
            )}
        </Form.Item>
    );
};

export default MUIFormItem;
