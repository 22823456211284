import { createReducer } from 'reduxsauce';
import initialState from './model';

import paginationReduxHandlers, {
    types as paginationTypes,
    actions as paginationActions,
} from './pagination/reducer';

const types = {
    ...paginationTypes,
};

const actions = {
    ...paginationActions,
};

export { types, actions };

export default createReducer(initialState, {
    ...paginationReduxHandlers,
});
