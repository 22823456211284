import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { EmptyDataIcon } from '@/assets/images/icons/screens/';

const EmptyData = () => {
    const { t } = useTranslation();

    return (
        <div className="empty">
            <div className="empty-data-icon">
                <EmptyDataIcon />
            </div>
            <div>
                <Typography.Text className="text">
                    {t('There are no data stored yet').toUpperCase()}
                </Typography.Text>
            </div>
        </div>
    );
};

export default EmptyData;
