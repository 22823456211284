import forgotPasswordModel from './forgotPassword/model';
import loginModel from './login/model';
import registrationModel from './registration/model';

export default {
    ...loginModel,
    forgotPassword: forgotPasswordModel,
    registration: registrationModel,
    /*
    requiredToChangePassword: false,
    */
    /*
    forgotPassword: {
        loading: false,
        error: false,
        success: false,
        successConfirm: false,
        confirmationToken: null,
    },
    */
    /*
    changePassword: {
        loading: false,
        error: false,
        success: false,
    },
    */
};
