import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Card,
    Avatar,
    Typography,
    Space,
} from 'antd';
import ActionIcon from '@/components/ActionIcon';
import { DeleteIcon, EditIcon } from '@/assets/images/icons/screens';
import { useCurrency } from '@/hooks/useCurrency';
import InlineDescription from '../Information/WithoutImage/Inline';

const CollapsedCard = ({
    firstLabel,
    secondLabel,
    hasImage = false,
    type,
    item,
    create,
    viewItem,
    deleteItem,
    getAmenities,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const deleteRoom = useCallback((id) => {
        dispatch(deleteItem(id));
    }, [dispatch, deleteItem]);

    const viewRoom = useCallback((id) => {
        viewItem && dispatch(viewItem(id));
        create && dispatch(create());
        getAmenities && dispatch(getAmenities());
    }, [dispatch, viewItem, create, getAmenities]);

    return (
        <Card className="season-card">
            <Card.Meta
                avatar={
                    hasImage ? (
                        <Avatar src={item.image} />
                    ) : (
                        <Avatar className="season-avatar">
                            <div className="avatar-price">{`${currencyIcon}${item.price}`}</div>
                            <div>{t('per Night').toUpperCase()}</div>
                        </Avatar>
                    )
                }
                title={(<Typography.Text>{item.type_name}</Typography.Text>)}
                description={(
                    <Row justify="space-between" align="middle">
                        <Col>
                            <InlineDescription
                                label={t(firstLabel)}
                                content={type === 'room' ? item.no_rooms : item.start_date}
                            />

                            <InlineDescription
                                label={t(secondLabel)}
                                content={type === 'room' ? `${currencyIcon}${item.price}` : item.end_date}
                            />
                        </Col>
                        <Col>
                            <Space size={30}>
                                <ActionIcon>
                                    <EditIcon onClick={() => { viewRoom(item.id); }} />
                                </ActionIcon>
                                <ActionIcon onClick={() => { deleteRoom(item.id); }}>
                                    <DeleteIcon />
                                </ActionIcon>
                            </Space>
                        </Col>
                    </Row>
                )}
            />
        </Card>
    );
};

export default CollapsedCard;
