import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Typography,
    Tooltip,
    Card,
    Button,
} from 'antd';
import { QuestionCircleIcon } from '@/assets/images/icons/screens';

const HeaderWithHelp = ({ title, question, answer }) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    return (
        <>
            <Typography.Text className="form-step-title">
                {t(title)}
            </Typography.Text>

            <Tooltip
                overlayClassName="custom-tooltip"
                open={visible}
                placement="rightTop"
                color="#ffffff"
                title={(
                    <Card
                        // className="help-card"
                        bordered={false}
                        title={t(question)}
                    >
                        <p>{t(answer)}</p>
                    </Card>
                )}
            >
                <Button
                    className="tooltip-button"
                    onClick={() => setVisible(!visible)}
                >
                    <QuestionCircleIcon />
                </Button>
            </Tooltip>
        </>
    );
};

export default HeaderWithHelp;
