import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    ArrowCircleLeftIcon,
    ArrowCircleRightIcon,
    CarouselWidgetIcon,
} from '@/assets/images/icons/screens';

const CarouselWidget = () => {
    const { t } = useTranslation();
    return (
        <div className="carousel-widget">
            <Space>
                <Typography.Text>
                    {t('View All Tours')}
                </Typography.Text>
                <ArrowCircleLeftIcon />
                <ArrowCircleRightIcon />
            </Space>
            <div className="carousel-svg">
                <CarouselWidgetIcon />
            </div>
        </div>
    );
};

export default CarouselWidget;
