import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('billList');

const actions = {
    requestFetchBillList: baseActions.request,
    fetchBillListSucceeded: baseActions.succeeded,
    fetchBillListFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
