import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Form,
    Slider,
    Col,
    notification,
    Space,
} from 'antd';
import ActionIcon from '@/components/ActionIcon';
import { PlusIcon } from '@/assets/images/icons/screens';
import { timeFormat } from '@/helpers/dateFormat';
import { TimeInDays } from '@/helpers/timeFormat';
import { selectors } from '@/redux/tour/activities/create/model';
import { actions } from '@/redux/tour/activities/create/reducer';

import FieldItem from './FieldItem';

const formatActivitiesForSubmit = (activities) => activities.map((activity) => ({
    times: [
        {
            time: activity.startTime?.format(timeFormat),
            price: activity.defaultPrice === true ? 0 : activity.price,
        },
    ],
}));

const DurationInDays = ({ form, isEdit, regStep }) => {
    const dispatch = useDispatch();

    const generalInfo = useSelector(selectors.generalInfo);
    const timeAndDuration = useSelector(selectors.timeAndDuration);

    const [duration, setDuration] = useState([1, 10]);

    useEffect(() => {
        if (timeAndDuration && timeAndDuration.duration_type === 'Days') {
            setDuration([
                timeAndDuration.duration_from,
                timeAndDuration.duration_to,
            ]);
        }
    }, [timeAndDuration]);

    const onFinish = useCallback((values) => {
        const data = {
            duration_from: values.durationInDays[0],
            duration_to: values.durationInDays[1],
            duration_type: 2,
            when: values.activityTimeDays
                ? formatActivitiesForSubmit(values.activityTimeDays)
                : [],
        };
        if (isEdit && regStep > 2) {
            const step = 2;
            const completedStep = 2;
            dispatch(actions.requestSubmitActivityTimeDuration(
                data,
                step,
                completedStep,
            ));
        } else {
            const step = 2;
            const completedStep = 1;
            dispatch(actions.requestSubmitActivityTimeDuration(
                data,
                step,
                completedStep,
            ));
        }
    }, [dispatch]);

    useEffect(() => {
        let initialData = {
            durationInDays: [1, 10],
            activityTimeDays: [{}],
        };
        if (timeAndDuration && timeAndDuration.duration_type === 'Days') {
            initialData = {
                durationInDays: [
                    timeAndDuration.duration_from,
                    timeAndDuration.duration_to,
                ],
                activityTimeDays: timeAndDuration.time.map((i) => ({
                    startTime: moment(i.time, 'HH:mm:ss'),
                    price: i.price,
                    defaultPrice: i.price === generalInfo.price,
                })),
            };
        }
        form.setFieldsValue(initialData);
    }, [timeAndDuration, generalInfo, form]);

    const errors = useSelector(selectors.errors);

    useEffect(() => {
        if (errors) {
            notification.error({
                description: <Space direction="vertical">{Object.values(errors)}</Space>,
                duration: 0,
            });
        }
        dispatch(actions.clearActivityTimeErrors());
    }, [errors]);

    return (
        <Form
            form={form}
            id="days-duration-form"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
        >
            <div className="duration-content">
                <div className="duration-start">
                    <span>
                        <TimeInDays value={duration[0]} />
                    </span>
                </div>
                <div className="seperator" />
                <div className="duration-end">
                    <span>
                        <TimeInDays value={duration[1]} />
                    </span>
                </div>
            </div>

            <Form.Item name="durationInDays">
                <Slider
                    className="trip-slider"
                    range
                    min={1}
                    max={40}
                    step={1}
                    tooltip={{
                        open: false,
                    }}
                    onChange={setDuration}
                />
            </Form.Item>

            <Form.Item>
                <Form.List name="activityTimeDays">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <FieldItem
                                    key={field.key}
                                    field={field}
                                    remove={remove}
                                />
                            ))}
                            <Col
                                sm={24}
                            >
                                <Form.Item>
                                    <ActionIcon
                                        className="circle-add-button"
                                        onClick={() => add()}
                                    >
                                        <PlusIcon />
                                    </ActionIcon>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </Form>
    );
};

export default DurationInDays;
