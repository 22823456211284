import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Steps, Row, Col } from 'antd';

import EmptyData from '@/components/EmptyData';
import { selectors } from '@/redux/tour/cruise/create/model';

import GeneralDetails from './GeneralDetails';
import ShipDetails from './ShipDetails';
import CruiseItenerary from './CruiseItenerary';
import InclusionsExclusions from './InclusionsExclusions';

const AddTourSteps = ({ step }) => {
    const { t } = useTranslation();

    const generalInfo = useSelector(selectors.generalInfo);
    const shipDetails = useSelector(selectors.shipDetails);
    const itenerary = useSelector(selectors.itenerary);
    const inclusions = useSelector(selectors.inclusions);
    const exclusions = useSelector(selectors.exclusions);

    return (
        <>
            <Steps
                className="steps-container"
                current={step}
                labelPlacement="vertical"
                items={[

                    { title: t('General Details') },
                    { title: t('Ship Details') },
                    { title: t('Cruise Itenerary') },
                    { title: t('Incl. / Excl.') },
                ]}
            />

            <div className="data-display">
                {(generalInfo !== null)
                    ? (
                        <Row gutter={[0, 30]}>
                            <Col xs={24}>
                                <GeneralDetails data={generalInfo} />
                            </Col>
                            <Col xs={24}>
                                <ShipDetails data={shipDetails} />
                            </Col>
                            <Col xs={24}>
                                <CruiseItenerary data={itenerary} />
                            </Col>
                            <Col xs={24}>
                                <InclusionsExclusions
                                    inclusions={inclusions}
                                    exclusions={exclusions}
                                />
                            </Col>
                        </Row>
                    )
                    : (<EmptyData />)}
            </div>
        </>
    );
};

export default AddTourSteps;
