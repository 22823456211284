import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form } from 'antd';

const modules = {
    toolbar: [
        // [{ header: [1, 2, false] }],
        ['bold', 'italic', 'strike'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        ['blockquote'],
        [{ align: [] }],
        ['link', 'image', 'video'],
    ],
};

const formats = [
    // 'header',
    'bold', 'italic', 'strike', 'blockquote',
    'list', 'bullet',
    'link', 'image', 'video',
    'align',
];

const CustomTextArea = (props) => {
    return (
        <Form.Item {...props}>
            <ReactQuill
                modules={modules}
                formats={formats}
                placeholder={props.placeholder}
            />
        </Form.Item>
    );
};

export default CustomTextArea;
