import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
    Space,
    Button,
    Divider,
    Table,
} from 'antd';
import {
    CalendarIcon,
    FilePdfIcon,
    FileTextIcon,
    SendBillIcon,
} from '@/assets/images/icons/screens';
import { actions } from '@/redux/settings/billing/reducer';
import { dateFormat } from '@/helpers/dateFormat';

const initialFilters = {};

const Billing = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
            width: '10%',
        },
        {
            title: t('Items').toUpperCase(),
            dataIndex: 'items',
            sorter: true,
            width: '30%',
        },
        {
            title: t('Period').toUpperCase(),
            dataIndex: 'period',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Price').toUpperCase(),
            dataIndex: 'price',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Total').toUpperCase(),
            dataIndex: 'total',
            sorter: true,
            width: '20%',
        },
    ];

    const [isSelected, setIsSelected] = useState(0);
    const [filters, baseSetFilters] = useState(initialFilters);

    const setFilters = useCallback((name, value) => {
        baseSetFilters((prev) => ({ ...prev, [name]: value }));
    }, [baseSetFilters]);

    const billList = useSelector((state) => state.settings.billing.data);

    useEffect(() => {
        const data = {
            // name: filters.search, // TO DO
            date_start: filters.date?.[0]?.format(dateFormat),
            date_end: filters.date?.[1]?.format(dateFormat),
        };
        dispatch(actions.requestFetchBillList(data));
    }, [dispatch, filters]);

    const datasource = billList.map((row, i) => ({
        key: i,
        id: row.id,
        items: row.name,
        period: `${row.date_start} - ${row.date_end}`,
        price: row.price,
        total: row.total,
    }));

    return (
        <div className="billing">
            <div className="bills-table">
                <Form
                    onFieldsChange={([changedField]) => {
                        const { name, value } = changedField;
                        setFilters(name[0], value);
                    }}
                >
                    <Row gutter={[30, 30]}>
                        <Col lg={9} xs={4}>
                            <Form.Item name="search" noStyle>
                                <Input.Search placeholder={t('Search')} />
                            </Form.Item>
                        </Col>
                        <Col lg={9} xs={4}>
                            <Form.Item name="date" noStyle>
                                <DatePicker.RangePicker
                                    suffixIcon={<CalendarIcon />}
                                    popupClassName="filter-calendar"
                                    separator={filters.date ? '-' : ''}
                                    placeholder={[t('Start date'), t('End date')]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider />
                <Table
                    rowSelection={{
                        onSelect: (record, selected, selectedRows) => setIsSelected(
                            selectedRows.length,
                        ),
                    }}
                    columns={columns}
                    dataSource={datasource}
                    pagination={false}
                    rowKey={(record) => record.id}
                    size="small"
                />
            </div>
            {isSelected > 0 && (
                <div className="table-action">
                    <Space size={30}>
                        <Button className="export">
                            {t('Send to')}
                            <SendBillIcon />
                        </Button>
                        <Button className="export">
                            {t('Export in XLS')}
                            <FileTextIcon />
                        </Button>
                        <Button className="export">
                            {t('Export in PDF')}
                            <FilePdfIcon />
                        </Button>
                    </Space>
                </div>
            )}
        </div>
    );
};

export default Billing;
