import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';
import { Card } from 'antd';
import {
    ClockIcon,
    CloseIcon,
    PeopleIcon,
    TickIcon,
} from '@/assets/images/icons/screens';
import { returnArrow } from '@/helpers/exactIcon';

const OrderCards = ({ data }) => {
    const { t } = useTranslation();

    const breakPoints = [
        { width: 767, itemsToShow: 1 },
        { width: 991, itemsToShow: 1 },
    ];

    return (
        <Carousel
            breakPoints={breakPoints}
            showArrows={false}
            enableMouseSwipe={true}
        >
            <Card
                className="order-cards confirmed"
                title={(
                    <>
                        {t('Confirmed Orders')}
                        <div>{data[0]?.value}</div>
                    </>
                )}
                extra={<TickIcon />}
            >
                {returnArrow(data[0]?.percentage, t('since last month'))}
            </Card>

            <Card
                className="order-cards pending"
                title={(
                    <>
                        {t('Pending Orders')}
                        <div>{data[1]?.value}</div>
                    </>
                )}
                extra={<ClockIcon />}
            >
                {returnArrow(data[1]?.percentage, t('since last month'))}
            </Card>

            <Card
                className="order-cards canceled"
                title={(
                    <>
                        {t('Canceled Orders')}
                        <div>{data[2]?.value}</div>
                    </>
                )}
                extra={<CloseIcon />}
            >
                {returnArrow(data[2]?.percentage, t('since last month'))}
            </Card>

            <Card
                className="order-cards customers"
                title={(
                    <>
                        {t('Total Customers')}
                        <div>{data[3]?.total_customers}</div>
                    </>
                )}
                extra={<PeopleIcon />}
            >
                {returnArrow(data[3]?.percentage, t('since last month'))}
            </Card>
        </Carousel>
    );
};

export default OrderCards;
