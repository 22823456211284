import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    cabin_type: {
        name: 'Cabin Type',
        required: true,
        min: 3,
        max: 30,
    },
};

export default buildValidationsFromConfig(config);
