import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    id: null,
    loading: false,
    error: null,
    messageSuccess: '',
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.trip,
    model,
);

export default model;
