import pagination from '@/helpers/pagination';
import create from './create/model';
import view from './view/model';
import deleteModel from './delete/model';
import createcustom from './createcustom/model';

export default {
    ...pagination('invoices').model,
    create,
    view,
    delete: deleteModel,
    createcustom,
};
