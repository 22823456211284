import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Avatar,
    Space,
    Typography,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import DataContainer from '@/components/DataContainer';
import { useCurrency } from '@/hooks/useCurrency';

const GeneralDetails = ({ data }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();
    return (
        <DataContainer title={t('General Details')}>
            <Row justify="space-between" gutter={[0, 15]}>
                <Col>
                    <Space direction="vertical">
                        <BlockDescription name={t('Cruise Name')}>
                            <Typography.Text>
                                {data.name}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Cruise Line')}>
                            <Typography.Text>
                                {data.line}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Cruise Type')}>
                            <Space size={4} split=",">
                                {data.types.map((type) => (
                                    <Typography.Text key={type.id}>
                                        {type.name}
                                    </Typography.Text>
                                ))}
                            </Space>
                        </BlockDescription>

                        <BlockDescription name={t('Start Date')}>
                            <Typography.Text>
                                {data.start_date}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('End Date')}>
                            <Typography.Text>
                                {data.end_date}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Departure Port')}>
                            <Typography.Text>
                                {data.departure_place}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Arrival Port')}>
                            <Typography.Text>
                                {data.arrival_place}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Avg. Price per Night')}>
                            <Typography.Text>
                                {`${currencyIcon}${data.adult_price} / ${data.adult_price_type}`}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Extra Service')}>
                            {data.extra_services.map((extra) => (
                                <div key={extra.id}>
                                    <Typography.Text>
                                        {extra.name}
                                        {' '}
                                        {extra.price === '0.00' ? `${(t('inclusive'))}` : `(${extra.price} / ${extra.price_type})`}
                                    </Typography.Text>
                                </div>
                            ))}
                        </BlockDescription>
                    </Space>
                </Col>
                <Col>
                    <Avatar src={data.images?.[0]?.path} />
                </Col>
            </Row>
        </DataContainer>
    );
};
export default GeneralDetails;
