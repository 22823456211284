import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Typography,
    Button,
} from 'antd';
import { CloudUploadIcon } from '@/assets/images/icons/screens';

const UploadContent = () => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col span={24}>
                <CloudUploadIcon />
            </Col>

            <Col span={24}>
                <Typography.Text>{t('Drag & Drop photos here')}</Typography.Text>
            </Col>

            <Col span={24}>
                <Typography.Text>{t('or')}</Typography.Text>
            </Col>

            <Col span={24}>
                <Button className="button-underline" type="link">
                    {t('Browse').toUpperCase()}
                </Button>
            </Col>
        </Row>
    );
};

export default UploadContent;
