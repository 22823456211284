import { useState } from 'react';
import { useSelector } from 'react-redux';
import ShowMoreText from 'react-show-more-text';
import { useTranslation } from 'react-i18next';
import PromotionCard from '@/components/PromotionCard';
import PhotoGallery from '@/components/PhotoGallery';

import { selectors } from '@/redux/accommodation/hotel/view/model';
import formatOffers from '../FormatOffers';

const Images = ({ data }) => {
    const { t } = useTranslation();

    const offers = useSelector(selectors.offerList);

    const formatedOffers = offers.length !== 0 ? formatOffers(offers) : [];

    const [expand, setExpand] = useState(false);

    const onClick = () => {
        setExpand(!expand);
    };

    return (
        <PhotoGallery data={data}>
            {formatedOffers.length !== 0 && (
                <PromotionCard
                    className="custom-card"
                    icon={formatedOffers[0]?.icon}
                    bordered={false}
                    title={formatedOffers[0]?.name}
                    category={formatedOffers[0]?.type}
                    duration={`${formatedOffers[0]?.date_start} - ${formatedOffers[0]?.date_end}`}
                    discount={formatedOffers[0]?.discount}
                    discountType={formatedOffers[0]?.discount_type}
                >
                    <ShowMoreText
                        lines={1}
                        more={t('View more')}
                        less={t('View less')}
                        onClick={onClick}
                        expanded={expand}
                        width={290}
                    >
                        {formatedOffers[0]?.assigned_to?.map((roomType) => roomType.type_name).join(', ')}
                    </ShowMoreText>
                </PromotionCard>
            )}
        </PhotoGallery>
    );
};

export default Images;
