import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@/helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteCustomer: ['id'],
    deleteCustomerSucceeded: ['id', 'messageSuccess'],
    deleteCustomerFailed: ['error'],

    // clear delete customer state
    clearDeleteCustomerState: null,
});

export default {
    [types.REQUEST_DELETE_CUSTOMER]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_CUSTOMER_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            messageSuccess,
        },
    }),
    [types.DELETE_CUSTOMER_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),

    // clear delete customer state
    [types.CLEAR_DELETE_CUSTOMER_STATE]: (state) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error: null,
            messageSuccess: '',
        },
    }),
};
