import { Divider } from 'antd';
import Title from './Title';

const TitleContent = ({
    name,
    rate,
    url,
    address,
    isList,
}) => {
    return (
        <div className="title-content">
            <Title
                name={name}
                rate={rate}
                url={url}
                isList={isList}
            />
            <div className="address">{address}</div>
            <Divider />
        </div>
    );
};

export default TitleContent;
