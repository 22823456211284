import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    name: {
        name: 'Name',
        required: true,
        min: 5,
        max: 150,
    },
    line: {
        name: 'Cruise line',
        required: true,
    },
    style: {
        name: 'Cruise style',
        required: true,
    },
    startDate: {
        name: 'Start date & time',
        required: true,
    },
    endDate: {
        name: 'End date & time',
        required: true,
    },
    departurePort: {
        name: 'Departure port',
        required: true,
    },
    arrivalPort: {
        name: 'Arrival port',
        required: true,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    nightPrice: {
        name: 'Price',
        required: true,
    },
    extraServiceName: {
        name: 'Extra service name',
        required: true,
    },
    // extraServicePrice: {
    //     name: 'Extra service price',
    //     required: true,
    // },
};

export default buildValidationsFromConfig(config);
