export const returnDeletedImages = (initialImages, newImages) => {
    const initialImagesIds = initialImages?.map((image) => image.id);
    const newImagesIds = newImages !== undefined
        ? newImages?.map((image) => image?.id)
        : initialImagesIds;
    const deletedImages = initialImagesIds?.filter((image) => {
        return newImagesIds?.indexOf(image) === -1;
    });
    return deletedImages;
};
