const Label = ({ icon, text }) => {
    return (
        <>
            <div className="center-icon">
                {icon}
            </div>

            <div className="center-text">
                {text}
            </div>
        </>
    );
};

export default Label;
