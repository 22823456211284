import {
    takeEvery,
    select,
    all,
    put,
    fork,
    call,
} from 'redux-saga/effects';

import { extractError } from '@/helpers/api';
import PackageService from '@/services/TourServices/PackageService';
import { types, actions } from './reducer';
import { selectors } from './model';

function* isEdit(step) {
    const completedStep = yield select(selectors.completedStep);
    if (completedStep === null) {
        return false;
    }
    return completedStep >= step;
}
function* submitPackageGeneralDetails({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        if (edit) {
            const { id } = data;
            response = yield call(PackageService.editGeneralInfo, id, data);
        } else {
            response = yield call(PackageService.addGeneralInfo, data);
        }
        yield put(actions.submitPackageGeneralDetailsSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitPackageGeneralDetailsFailed(extractError(error)));
    }
}

function* getHotels({ filters, isFetchingMore }) {
    if (isFetchingMore) {
        yield put(actions.getMoreHotels());
    }
    try {
        const response = yield call(PackageService.getHotels, filters);
        if (isFetchingMore) {
            yield put(
                actions.getMoreHotelsSucceeded(response.data),
            );
            return;
        }
        yield put(actions.getHotelsSucceeded(response.data));
    } catch (error) {
        if (isFetchingMore) {
            yield put(actions.getMoreHotelsFailed(extractError(error)));
            return;
        }
        yield put(actions.getHotelsFailed(extractError(error)));
    }
}

function* submitHotelDetails({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        const { id: tourId } = yield select(selectors.generalInfo);
        if (edit) {
            response = yield call(PackageService.editHotel, tourId, data);
        } else {
            response = yield call(PackageService.submitHotel, tourId, data);
        }
        yield put(actions.submitHotelDetailsSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitHotelDetailsFailed(extractError(error)));
    }
}

function* submitPackageInclusionsExclusions({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        const { id: tourId } = yield select(selectors.generalInfo);
        if (edit) {
            response = yield call(PackageService.editInclusionsExclusions, tourId, data);
        } else {
            response = yield call(PackageService.addInclusionsExclusions, tourId, data);
        }
        const {
            inclusions: { selected: inclusions },
            exclusions: { selected: exclusions },
        } = response.data;
        yield put(actions.submitPackageInclusionsExclusionsSucceeded(
            inclusions,
            exclusions,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitPackageInclusionsExclusionsFailed(extractError(error)));
    }
}

function* fetchPackageData({ id }) {
    try {
        const [
            generalData,
            hotelData,
            inclExcl,
        ] = yield all([
            call(PackageService.viewGeneralInfo, id),
            call(PackageService.viewHotel, id),
            call(PackageService.viewInclusionsExclusions, id),
        ]);
        const {
            inclusions: { selected: inclusions },
            exclusions: { selected: exclusions },
        } = inclExcl.data;

        yield put(actions.fetchPackageDataSucceeded(
            generalData.data,
            hotelData.data,
            inclusions,
            exclusions,
        ));
    } catch (error) {
        yield put(actions.fetchPackageDataFailed(extractError(error)));
    }
}

function* getPackageTypes({ data }) {
    try {
        const res = yield call(PackageService.getPackageTypes, data);
        yield put(actions.getPackageTypesSucceeded(res.data));
    } catch (error) {
        yield put(actions.getPackageTypesFailed(extractError(error)));
    }
}

function* addNewPackageType({ data }) {
    try {
        const res = yield call(PackageService.addNewPackageType, data);
        yield put(actions.addNewPackageTypeSucceeded(res.data));
    } catch (error) {
        yield put(actions.addNewPackageTypeFailed('Package style cannot be empty'));
    }
}

function* watchSubmitPackageGeneralDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_PACKAGE_GENERAL_DETAILS,
        submitPackageGeneralDetails,
    );
}

function* watchGetHotels() {
    yield takeEvery(
        types.REQUEST_GET_HOTELS,
        getHotels,
    );
}

function* watchSubmitHotelDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_HOTEL_DETAILS,
        submitHotelDetails,
    );
}

function* watchSubmitPackageInclusionsExclusions() {
    yield takeEvery(
        types.REQUEST_SUBMIT_PACKAGE_INCLUSIONS_EXCLUSIONS,
        submitPackageInclusionsExclusions,
    );
}

function* watchFetchPackageData() {
    yield takeEvery(
        types.REQUEST_FETCH_PACKAGE_DATA,
        fetchPackageData,
    );
}

function* watchGetPackageTypes() {
    yield takeEvery(
        types.REQUEST_GET_PACKAGE_TYPES,
        getPackageTypes,
    );
}

function* watchAddNewPackageType() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_PACKAGE_TYPE,
        addNewPackageType,
    );
}

function* createPackageSaga() {
    yield all([
        fork(watchSubmitPackageGeneralDetails),
        fork(watchGetHotels),
        fork(watchSubmitHotelDetails),
        fork(watchSubmitPackageInclusionsExclusions),
        fork(watchFetchPackageData),
        fork(watchGetPackageTypes),
        fork(watchAddNewPackageType),
    ]);
}

export default createPackageSaga;
