import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Card,
} from 'antd';
import {
    ArrowBottomIcon,
    ArrowTopIcon,
    TotalIncomingIcon,
    ClockIcon,
    CloseIcon,
    PeopleIcon,
    TickIcon,
} from '@/assets/images/icons/screens';
import { returnArrow } from '@/helpers/exactIcon';

const OrderCards = ({ orders, currency }) => {
    const { t } = useTranslation();

    return (
        <Row gutter={[25, 25]} className="orders">
            <Col>
                <Card
                    className="total-incoming-card"
                    title={(
                        <>
                            {t('Total Incoming')}
                            <div>{`${currency}${orders[4]?.total}`}</div>
                        </>
                    )}
                    extra={<TotalIncomingIcon />}
                >
                    <ArrowBottomIcon />
                    <span className="order-costs">
                        {currency}
                        {orders[4]?.costs}
                        {' '}
                        {t('Costs')}
                    </span>
                    <ArrowTopIcon />
                    <span className="order-earned">
                        {currency}
                        {orders[4]?.earn}
                        {' '}
                        {t('Earned')}
                    </span>
                </Card>
            </Col>
            <Col>
                <Card
                    className="order-cards confirmed"
                    title={(
                        <>
                            {t('Confirmed Orders')}
                            <div>{orders[0]?.value}</div>
                        </>
                    )}
                    extra={<TickIcon />}
                >
                    {returnArrow(orders[0]?.percentage, t('since last month'))}
                </Card>
            </Col>

            <Col>
                <Card
                    className="order-cards pending"
                    title={(
                        <>
                            {t('Pending Orders')}
                            <div>{orders[1]?.value}</div>
                        </>
                    )}
                    extra={<ClockIcon />}
                >
                    {returnArrow(orders[1]?.percentage, t('since last month'))}
                </Card>
            </Col>

            <Col>
                <Card
                    className="order-cards canceled"
                    title={(
                        <>
                            {t('Canceled Orders')}
                            <div>{orders[2]?.value}</div>
                        </>
                    )}
                    extra={<CloseIcon />}
                >
                    {returnArrow(orders[2]?.percentage, t('since last month'))}
                </Card>
            </Col>

            <Col>
                <Card
                    className="order-cards customers"
                    title={(
                        <>
                            {t('Total Customers')}
                            <div>{orders[3]?.total_customers}</div>
                        </>
                    )}
                    extra={<PeopleIcon />}
                >
                    {returnArrow(orders[3]?.percentage, t('since last month'))}
                </Card>
            </Col>
        </Row>
    );
};

export default OrderCards;
