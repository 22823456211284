import { Form, Divider } from 'antd';

import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import usePackageInclusionsExclusions from '@/hooks/usePackageInclusionsExclusions';
import PackageService from '@/services/TourServices/PackageService';

import CreateSection from '@/components/CreateInclExclSection';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const Exclusions = ({ initialExclusions }) => {
    const { data, mutate } = usePackageInclusionsExclusions();

    return (
        <div className="exclusions">
            <HeaderWithHelp
                title="Exclusions"
                question={question}
                answer={answer}
            />

            <Divider />

            <Form.Item name="exclusions">
                <CheckboxMultiSelect
                    options={data?.data.exclusions}
                    labelParamName="name"
                    valueParamName="id"
                    initialCheckedList={initialExclusions}
                />
            </Form.Item>

            <CreateSection
                data={data}
                mutate={mutate}
                buttonText="Add New Exclusion Item"
                addNewItem={PackageService.addNewExclusion}
                tourcategory={2}
            />
        </div>
    );
};

export default Exclusions;
