import { Form, Divider } from 'antd';

import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import CruiseService from '@/services/TourServices/CruiseService';
import useCruiseInclusionsExclusions from '@/hooks/useCruiseInclusionsExclusions';
import CreateSection from '@/components/CreateInclExclSection';

const Inclusions = ({ initialInclusions }) => {
    const question = 'What is this ?';
    const answer = 'Lorem ipsum dolor sit amet...';

    const { data, mutate } = useCruiseInclusionsExclusions();
    return (
        <div className="inclusions">
            <HeaderWithHelp
                title="Inclusions"
                question={question}
                answer={answer}
            />

            <Divider />

            <Form.Item name="inclusions">
                <CheckboxMultiSelect
                    options={data?.data.inclusions}
                    labelParamName="name"
                    valueParamName="id"
                    initialCheckedList={initialInclusions}
                />
            </Form.Item>

            <CreateSection
                data={data}
                mutate={mutate}
                buttonText="Add New Inclusion Item"
                addNewItem={CruiseService.addNewInclusion}
                tourcategory={3}
            />
        </div>
    );
};

export default Inclusions;
