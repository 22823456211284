import { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { MinusIcon, PlusIcon } from '@/assets/images/icons/screens';

const noop = () => {};

const IncrementNumberInput = ({
    render = (value) => value,
    onChange = noop,
    min = 0,
    max = 30,
    label,
    defaultCount,
    hasInputBorder = true,
    disableButton,
}) => {
    const [count, setCount] = useState(defaultCount);

    useEffect(() => {
        (defaultCount !== undefined && defaultCount !== null)
        && setCount(defaultCount);
    }, [defaultCount]);

    const increment = useCallback(
        () => setCount((prev) => ((prev < max) ? (prev + 1) : prev)),
        [setCount, max],
    );
    const decrement = useCallback(
        () => setCount((prev) => ((prev > min) ? (prev - 1) : prev)),
        [setCount, min],
    );

    useEffect(() => {
        onChange(count);
    }, [count, onChange]);

    return (
        <div className={clsx('increment-number-input', hasInputBorder && 'border')}>
            <Button
                disabled={count === min || disableButton}
                type="text"
                onClick={decrement}
            >
                <MinusIcon />
            </Button>

            <span>
                {render(count)}
                {label}
            </span>

            <Button
                disabled={count === max || disableButton}
                type="text"
                onClick={increment}
            >
                <PlusIcon />
            </Button>
        </div>
    );
};

export default IncrementNumberInput;
