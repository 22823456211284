import { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Form } from 'antd';

import FindOrCreateResource from '@/components/FindOrCreateResource';
import AddCustomerForm from '@/components/AddCustomerForm';
import { dateFormat } from '@/helpers/dateFormat';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { actions } from '@/redux/invoice/createcustom/reducer';
import { selectors } from '@/redux/invoice/createcustom/model';
import { selectors as invoiceSelectors } from '@/redux/invoice/create/model';

const formFields = [
    'name',
    'surname',
    'id_number',
    'nationality',
    'phone',
    'email',
    'gender',
    'birth',
];

const InvoiceTo = ({ form, invoiceId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { service: serviceList } = useSelector(selectors.billedFor);
    const invoiceTo = useSelector(selectors.invoiceTo);
    const { invoice_to: newCustomer } = invoiceTo !== null && invoiceTo;

    const existingcustomer = useSelector(invoiceSelectors.invoiceCustomer);
    const customer = newCustomer || existingcustomer;
    const errors = useSelector(selectors.errors);

    const onFinish = useCallback((values) => {
        const data = {
            service: {
                id: serviceList.length !== 0 ? serviceList.map((service) => service.id) : [],
            },
            invoice: {
                invoice_id: invoiceId,
                customer: {
                    id: (customer?.id || customer?.customer_id)
                        ? (customer?.id || customer?.customer_id)
                        : null,
                    name: values.name,
                    surname: values.surname,
                    id_number: values.id_number,
                    nationality: values.nationality?.value,
                    phone: values.phone,
                    email: values.email,
                    gender: values.gender,
                    birth: values.birth?.format(dateFormat),
                },
            },
        };
        dispatch(actions.requestSubmitCustomInvoiceDetails(data));
    }, [dispatch, serviceList, customer]);

    useApiValidationsForForm({ form, errors, formFields });

    useEffect(() => {
        let initialData = {};
        if (customer) {
            // TO DO
            initialData = {
                name: customer.name,
                surname: customer.surname,
                id_number: customer.id_number,
                nationality: { value: customer.nationality_id || customer.country_id },
                phone: customer.phone,
                email: customer.email,
                gender: customer.gender,
                birth: moment(customer.birth),
            };
        } form.setFieldsValue(initialData);
    }, [customer, form, existingcustomer]);

    return (
        <Form
            form={form}
            className="details"
            onFinish={onFinish}
            requiredMark={false}
        >
            <FindOrCreateResource
                searchText={t('Search for an Existing Customer')}
                createForm={<AddCustomerForm isCompany={false} />}
                seperator={<Divider />}
            />
        </Form>
    );
};

export default InvoiceTo;
