import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@/helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeletePackage: ['id'],
    deletePackageSucceeded: ['id', 'messageSuccess'],
    deletePackageFailed: ['error'],

    // clear delete package state
    clearDeletePackageState: null,
});

export default {
    [types.REQUEST_DELETE_PACKAGE]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_PACKAGE_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            messageSuccess,
        },
    }),
    [types.DELETE_PACKAGE_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),

    // clear delete package state
    [types.CLEAR_DELETE_PACKAGE_STATE]: (state) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error: null,
            messageSuccess: '',
        },
    }),
};
