import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { useCurrency } from '@/hooks/useCurrency';
import { actions } from '@/redux/employees/view/reducer';
import { selectors } from '@/redux/employees/view/model';
import { dateFormat } from '@/helpers/dateFormat';
import PersonalDetails from './PersonalDetails';
import WorkDetails from './WorkDetails';
import Performance from './Performance';

const initialFilters = {
    prices: [80, 420],
};

const ViewOne = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const employee = useSelector(selectors.employeeDetails);
    const loading = useSelector(selectors.loading);
    const invoices = useSelector(selectors.invoiceList);
    const total = useSelector(selectors.total);
    const {
        fetchingLoading: floading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.employees.view);

    const [showPerformance, setPerformance] = useState(false);
    const [filters, baseSetFilters] = useState(initialFilters);
    const [page, setPage] = useState(1);

    const handleGetEmployeeInvoices = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
                type: filters.type,
                status: filters.status,
                date_start: filters.date?.[0]?.format(dateFormat),
                date_end: filters.date?.[1]?.format(dateFormat),
                widgets: filters.widgets, // 1 created from hola app, 0 created from website
                sort: filters.sort,
                name: filters.name,
                customer: filters.customer,
                min_amount: filters.prices[0],
                max_amount: filters.prices[1],
            };
            filters.prices[1] !== 0
            && dispatch(actions.requestGetEmployeeInvoices(id, data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            id,
            filters.type,
            filters.status,
            filters.widgets,
            filters.date,
            filters.name,
            filters.sort,
            filters.prices,
            filters.customer,
        ],
    );

    const setFilters = useCallback((name, value) => {
        baseSetFilters((prev) => ({ ...prev, [name]: value }));
    }, [baseSetFilters]);

    useEffect(() => {
        dispatch(actions.requestViewEmployeeDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        handleGetEmployeeInvoices({});
    }, [handleGetEmployeeInvoices]);

    const handleFetchMore = useCallback(() => {
        handleGetEmployeeInvoices({ currentPage: page, fetchMore: true });
    }, [handleGetEmployeeInvoices, page]);

    return (employee === null || loading) ? 'loading' : (
        <Row gutter={showPerformance ? 5 : 30} className="employee-view-one">
            <Col xs={7}>
                <div className="employee-personal-info">
                    <PersonalDetails
                        data={employee}
                        showPerformance={showPerformance}
                        setPerformance={setPerformance}
                        currency={currencyIcon}
                    />
                </div>
            </Col>
            <Col xs={17}>
                <div className="work-info">
                    {showPerformance
                        ? <Performance />
                        : (
                            <WorkDetails
                                id={id}
                                currency={currencyIcon}
                                invoices={invoices}
                                filters={filters}
                                setFilters={setFilters}
                                handleFetchMore={handleFetchMore}
                                isLoadingMore={isLoadingMore}
                                hasMore={hasMore}
                                loading={floading}
                                total={total}
                            />
                        )}
                </div>
            </Col>
        </Row>
    );
};

export default ViewOne;
