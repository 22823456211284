import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Input,
    Button,
    Space,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useNotification from '@/hooks/useNotification';
import { InfoCircleIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/userprofile/security/reducer';
import baseValidations from './validations';

const ChangePassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [newPassword, setNewPassword] = useState('');

    const validations = useTranslatedValidationConfig(baseValidations);

    const loading = useSelector((state) => state.userprofile.security.loading);
    const messageSuccess = useSelector((state) => state.userprofile.security.success);
    const error = useSelector((state) => state.userprofile.security.errors);

    const confirmPasswordEqualityValidator = {
        type: 'validator',
        validator: (_, value) => new Promise((resolve, reject) => {
            if (value && value !== newPassword) {
                const message = t(
                    'Confirm New Password needs to be the same as New Password',
                );
                reject(t(message));
            } else {
                resolve();
            }
        }),
    };

    const onSubmit = useCallback((values) => {
        const data = {
            pass: values.newPassword,
            pass_repeat: values.confirmNewPassword,
        };
        dispatch(actions.requestSubmitNewPassword(data));
    }, [dispatch]);

    useNotification({
        messageSuccess,
        error,
        clearAction: actions.clearMessage(),
    });

    useEffect(() => {
        form.resetFields();
    }, [messageSuccess, form]);

    return (
        <div className="change-password-page">
            <Form
                form={form}
                size="large"
                layout="vertical"
                requiredMark={false}
                onFinish={onSubmit}
            >

                <MUIFormItem
                    label={t('New Password')}
                    name="newPassword"
                    rules={validations.newPassword}
                    validateFirst
                >
                    <Input.Password
                        autoComplete="new-password"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </MUIFormItem>

                <MUIFormItem
                    label={t('Confirm Password')}
                    name="confirmNewPassword"
                    rules={[
                        ...validations.confirmNewPassword,
                        confirmPasswordEqualityValidator,
                    ]}
                    validateFirst
                >
                    <Input.Password autoComplete="confirm-new-password" />
                </MUIFormItem>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loading}
                    >
                        {t('Save password').toUpperCase()}
                    </Button>
                </Form.Item>

                <Space size={10} align="middle">
                    <InfoCircleIcon />
                    <p className="description">
                        {t("The password shouldn't be less than 8 character, including at least a cap letter, a symbol and a number")}
                    </p>
                </Space>
            </Form>
        </div>
    );
};

export default ChangePassword;
