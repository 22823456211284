import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from 'antd';

import HeaderWithHelp from '@/components/HeaderWithHelp';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { selectors } from '@/redux/accommodation/hotel/create/model';

import AddNewRoom from './AddNewRoom';
import EmptyRoomList from './EmptyRoomList';
import RoomList from './RoomList';

const question = 'What is this ?';
const answer = 'Lorem ipsum dolor sit amet...';

const Rooms = () => {
    const { id: urlId } = useParams();
    const dispatch = useDispatch();

    const roomList = useSelector(selectors.roomList);
    const loading = useSelector(selectors.loading);
    const isCreatingRoom = useSelector(selectors.isCreatingRoom);
    const reduxId = useSelector(
        (state) => state.accommodation.hotel.create.accommodationDetails?.id,
    );

    const hotelId = urlId || reduxId;
    useEffect(() => {
        dispatch(actions.requestGetRoomList(hotelId));
        dispatch(actions.requestGetAmenitiesList());
    }, [dispatch]);

    if (loading) {
        return (
            <p>loading</p>
        );
    } else if (isCreatingRoom) {
        return (
            <>
                <HeaderWithHelp
                    title="Rooms"
                    question={question}
                    answer={answer}
                />

                <Divider />

                <AddNewRoom hotelId={hotelId} />
            </>
        );
    } else {
        return (
            <>
                <HeaderWithHelp
                    title="Rooms"
                    question={question}
                    answer={answer}
                />

                <Divider />

                {roomList.length === 0 ? (
                    <EmptyRoomList />
                ) : (
                    <RoomList data={roomList} />
                )}
            </>
        );
    }
};

export default Rooms;
