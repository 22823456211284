import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import GeneralService from '@/services/SettingsServices/GeneralService';
import { types, actions } from './reducer';

function* getAgencyDetails() {
    try {
        const response = yield call(GeneralService.getAgencyDetails);
        yield put(actions.getAgencyDetailsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getAgencyDetailsFailed(extractError(error)));
    }
}

function* submitAgencyDetails({ data }) {
    try {
        yield call(GeneralService.submitAgencyDetails, data);
        yield put(actions.submitAgencyDetailsSucceeded('Agency details updated successfully'));
    } catch (error) {
        yield put(actions.submitAgencyDetailsFailed(extractError(error)));
    }
}

function* watchGetAgencyDetails() {
    yield takeEvery(
        types.REQUEST_GET_AGENCY_DETAILS,
        getAgencyDetails,
    );
}

function* watchSubmitAgencyDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_AGENCY_DETAILS,
        submitAgencyDetails,
    );
}

function* generalSaga() {
    yield all([
        fork(watchGetAgencyDetails),
        fork(watchSubmitAgencyDetails),
    ]);
}

export default generalSaga;
