import { combineReducers } from 'redux';

import inhouse, {
    types as inhouseTypes,
    actions as inhouseActions,
} from './inhouse/reducer';

import b2b, {
    types as b2bTypes,
    actions as b2bActions,
} from './b2b/reducer';

const actions = {
    ...inhouseActions,
    ...b2bActions,
};

const types = {
    ...inhouseTypes,
    ...b2bTypes,
};

export { types, actions };

const reportReducer = combineReducers({
    inhouse,
    b2b,
});

export default reportReducer;
