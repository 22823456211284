import { all } from 'redux-saga/effects';

import dashboardSaga from './dashboard/saga';
import tripSaga from './trip/saga';
import packagesSaga from './packages/saga';
import cruiseSaga from './cruise/saga';
import activitiesSaga from './activities/saga';

function* tourSaga() {
    yield all([
        dashboardSaga(),
        tripSaga(),
        packagesSaga(),
        cruiseSaga(),
        activitiesSaga(),
    ]);
}

export default tourSaga;
