import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isRouteSelected } from '@/helpers/routing';

const useCurrentRoute = (routes) => {
    const { pathname } = useLocation();

    return useMemo(() => {
        const matchingRoutes = routes.filter(
            isRouteSelected.bind(null, pathname),
        );
        if (matchingRoutes.length > 0) {
            return matchingRoutes[0];
        }
        return null;
    }, [pathname, routes]);
};

export default useCurrentRoute;
