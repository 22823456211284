import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    requestDetails: null,
    agencies: [],
    totalAgencies: 0,
    loading: false,
    error: null,
    message: '',
};

export const selectors = createSelectorsFromModel(
    (state) => state.b2b.request.view,
    model,
);

export default model;
