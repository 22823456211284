import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    step: 0, // 0, 1, 2
    completedStep: null, // null, 0, 1, 2
    submitting: false,
    loading: false,
    errors: null,
    packageTypeError: null,

    generalInfo: null,

    hotels: [],
    fetchingLoading: true,
    fetchingError: null,
    isLoadingMore: false,
    isLoadingMoreError: null,
    hasMore: false,
    hotelDetails: null,

    inclusions: [],
    exclusions: [],

    packageTypes: [],
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.packages.create,
    model,
);

export default model;
