import GoogleMap from '@/components/GoogleMap';

const TripMapItenerary = ({ data }) => {
    return (
        <GoogleMap
            itenerary={data}
            latitude={data[0]?.latitude}
            longitude={data[0]?.longitude}
        />
    );
};

export default TripMapItenerary;
