import BaseService from '@/services/BaseService';

class RequestService extends BaseService {
    getAll = (filters) => this.post('/agency/service/request/list', filters || { });

    getOne = (id, filters) => this.post(`/agency/service/request/view/${id}`, filters);

    approveOrCancelRequest = (data) => this.post('/agency/vendor/service/action', data);
}
export default new RequestService();
