import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import moment from 'moment';
import {
    Modal,
    Form,
    Row,
    Col,
    Typography,
    Input,
    DatePicker,
    Radio,
    Button,
    Divider,
} from 'antd';
import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import MUIFormItem from '@/components/MUIFormItem';
import {
    CalendarIcon,
    TicketIcon,
    ClockIcon,
    BedIcon,
} from '@/assets/images/icons/screens';
import { selectors } from '@/redux/accommodation/hotel/view/model';
import { actions } from '@/redux/accommodation/hotel/view/reducer';
import { dateFormat } from '@/helpers/dateFormat';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import useNotification from '@/hooks/useNotification';

import baseValidations from './validations';
import Label from './labelWithIcon';

const formFields = [
    'type',
    'name',
    'discount',
    'discount_type',
    'date_start',
    'date_end',
    'room_type',
];

const AddNewOffer = ({ currency }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const roomTypesList = useSelector(selectors.roomTypeList);
    // const offer = useSelector(selectors.offerDetails);
    const errors = useSelector(selectors.error);
    const visible = useSelector(selectors.visibleForm);
    const messageSuccess = useSelector(selectors.messageSuccess);
    const messageError = useSelector(selectors.messageError);

    const offerOptions = useMemo(() => ([
        {
            label:
    <Label
        icon={<TicketIcon />}
        text={t('Custom Offer')}
    />,
            value: 3,
        },
        {
            label:
    <Label
        icon={<CalendarIcon />}
        text={t('Early Booker')}
    />,
            value: 1,
        },
        {
            label:
    <Label
        icon={<ClockIcon />}
        text={t('Last Minute')}
    />,
            value: 4,
        },
        {
            label:
    <Label
        icon={<BedIcon />}
        text={t('Free Night')}
    />,
            value: 2,
        },
    ]), [t]);

    const options = useMemo(() => ([
        { label: t('%'), value: 4 },
        { label: t(currency), value: 5 },
    ]), [t]);

    const validations = useTranslatedValidationConfig(baseValidations);

    const onFinish = (values) => {
        const data = {
            type: values.type,
            name: values.name,
            discount: values.discount,
            discount_type: values.discount_type,
            date_start: values.date_start.format(dateFormat),
            date_end: values.date_end.format(dateFormat),
            room_types: values.room_types,
        };
        dispatch(actions.requestSubmitAccommodationOffer(data));
    };

    useNotification({
        messageSuccess,
        error: messageError,
        clearAction: actions.clearOfferActionState(),
    });

    useEffect(() => {
        if (visible === false) {
            form.resetFields();
        }
    }, [visible]);

    useApiValidationsForForm({ form, errors, formFields });

    // useEffect(() => {
    //     let offerType = null;
    //     if (offer) {
    //         if (offer.type === ' Custom Offer') {
    //             offerType = 3;
    //         } else if (offer.type === 'Free Night') {
    //             offerType = 2;
    //         } else if (offer.type === 'Early Booker') {
    //             offerType = 1;
    //         } else {
    //             offerType = 4;
    //         }
    //         const initialData = {
    //             type: offerType,
    //             name: offer.name,
    //             discount: offer.discount,
    //             discount_type: offer.discount_type === 'Percentage' ? 4 : 5,
    //             date_start: moment(offer.date_start),
    //             date_end: moment(offer.date_end),
    //             room_types: offer.room_types,
    //         };
    //         // form.setFieldsValue(initialData);
    //         // this is for later when we apply edit mode
    //         console.log(initialData);
    //     }
    // }, [form, offer]);

    return (
        <Modal
            className="add-new-offer"
            title={t('Create new Offer')}
            open={visible}
            maskClosable={false}
            forceRender
            width={750}
            onCancel={() => {
                dispatch(actions.setFormVisibleFalse(false));
            }}
            footer={null}
        >
            <Form
                id="add_new_offer"
                form={form}
                onFinish={onFinish}
                preserve={false}
                requiredMark={false}
                initialValues={{
                    discount_type: 4,
                }}
            >
                <Typography.Text>
                    {t('Select a Promotion Type')}
                </Typography.Text>
                <Form.Item name="type" rules={validations.type}>
                    <Radio.Group
                        className="offer-options"
                        options={offerOptions}
                        optionType="button"
                        size="large"
                    />
                </Form.Item>

                <Row gutter={30}>
                    <Col xs={24} sm={12}>
                        <MUIFormItem
                            label={t('Promotion Name')}
                            name="name"
                            rules={validations.name}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xs={24} sm={12}>
                        <MUIFormItem
                            label={t('Discount')}
                            name="discount"
                            rules={validations.discount}
                        >
                            <Input
                                suffix={(
                                    <Form.Item
                                        name="discount_type"
                                        noStyle
                                        rules={validations.discount_type}
                                    >
                                        <Radio.Group
                                            options={options}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                )}
                            />
                        </MUIFormItem>
                    </Col>

                    <Col xs={24} sm={12}>
                        <MUIFormItem
                            label={t('Start Offer')}
                            name="date_start"
                            rules={validations.date_start}
                        >
                            <DatePicker
                                suffixIcon={<CalendarIcon />}
                            />
                        </MUIFormItem>
                    </Col>

                    <Col xs={24} sm={12}>
                        <MUIFormItem
                            label={t('End Offer')}
                            name="date_end"
                            rules={validations.date_end}
                        >
                            <DatePicker
                                suffixIcon={<CalendarIcon />}
                            />
                        </MUIFormItem>
                    </Col>
                </Row>

                <Typography.Text>
                    {t('Select the Room Types you want to assign this Season')}
                </Typography.Text>
                <Form.Item
                    name="room_types"
                    rules={validations.room_types}
                >
                    <CheckboxMultiSelect
                        options={roomTypesList}
                        labelParamName="type_name"
                        valueParamName="id"
                        selectAllLabel={t('Assign to all')}
                        col={12}
                        initialCheckedList={[]}
                    />
                </Form.Item>
                <Divider />
                <Row justify="end">
                    <Col>
                        <Form.Item noStyle>
                            <Button
                                type="primary"
                                className="add-offer-btn"
                                onClick={() => form.submit()}
                            >
                                {t('Save').toUpperCase()}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddNewOffer;
