import {
    takeEvery,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import EmployeesService from '@/services/EmployeesService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* submitEmployeeDetails({ data }) {
    try {
        const { id: employeeId } = data;
        let response;
        if (employeeId) {
            response = yield call(EmployeesService.editEmployeeDetails, employeeId, data);
        } else {
            response = yield call(EmployeesService.addEmployeeDetails, data);
        }
        yield put(actions.submitEmployeeDetailsSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitEmployeeDetailsFailed(extractError(error)));
    }
}

function* fetchEmployeeData({ id }) {
    try {
        const response = yield call(EmployeesService.viewPersonalDetails, id);
        yield put(actions.fetchEmployeeDataSucceeded(response.data));
    } catch (error) {
        yield put(actions.fetchEmployeeDataFailed(extractError(error)));
    }
}

function* getGroups() {
    try {
        const response = yield call(EmployeesService.getGroups);
        yield put(actions.getGroupsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getGroupsFailed(extractError(error)));
    }
}

function* addNewGroup({ data }) {
    try {
        const response = yield call(EmployeesService.addNewGroup, data);
        yield put(actions.addNewGroupSucceeded(response.data, 'Group added successfully'));
    } catch (error) {
        yield put(actions.addNewGroupFailed(extractError(error)));
    }
}

function* editGroup({ id, data }) {
    try {
        const response = yield call(EmployeesService.editGroup, id, data);
        yield put(actions.editGroupSucceeded(response.data, 'Group edited successfully'));
    } catch (error) {
        yield put(actions.editGroupFailed(extractError(error)));
    }
}

function* getRoles() {
    try {
        const response = yield call(EmployeesService.getRoles);
        yield put(actions.getRolesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getRolesFailed(extractError(error)));
    }
}

function* deleteGroup({ id }) {
    try {
        yield call(EmployeesService.deleteGroup, id);
        yield put(actions.deleteGroupSucceeded(id, 'Group deleted successfully'));
    } catch (error) {
        yield put(actions.deleteGroupFailed(extractError(error)));
    }
}

function* getGroupRoles({ id }) {
    try {
        const response = yield call(EmployeesService.getGroupRoles, id);
        yield put(actions.getGroupRolesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getGroupRolesFailed(extractError(error)));
    }
}

function* watchGetGroupRoles() {
    yield takeEvery(
        types.REQUEST_GET_GROUP_ROLES,
        getGroupRoles,
    );
}

function* watchSubmitEmployeeDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_EMPLOYEE_DETAILS,
        submitEmployeeDetails,
    );
}

function* watchFetchEmployeeData() {
    yield takeEvery(
        types.REQUEST_FETCH_EMPLOYEE_DATA,
        fetchEmployeeData,
    );
}

function* watchGetGroups() {
    yield takeEvery(
        types.REQUEST_GET_GROUPS,
        getGroups,
    );
}

function* watchAddNewGroup() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_GROUP,
        addNewGroup,
    );
}

function* watchEditGroup() {
    yield takeEvery(
        types.REQUEST_EDIT_GROUP,
        editGroup,
    );
}

function* watchGetRoles() {
    yield takeEvery(
        types.REQUEST_GET_ROLES,
        getRoles,
    );
}

function* watchDeleteGroup() {
    yield takeEvery(
        types.REQUEST_DELETE_GROUP,
        deleteGroup,
    );
}

function* createEmployeeSaga() {
    yield all([
        fork(watchSubmitEmployeeDetails),
        fork(watchFetchEmployeeData),
        fork(watchGetGroups),
        fork(watchAddNewGroup),
        fork(watchEditGroup),
        fork(watchGetRoles),
        fork(watchDeleteGroup),
        fork(watchGetGroupRoles),
    ]);
}

export default createEmployeeSaga;
