import { useTranslation } from 'react-i18next';
import { Tooltip, Badge } from 'antd';
import { GroupIcon } from '@/assets/images/icons/screens';

const GroupAvatar = ({ group }) => {
    const { t } = useTranslation();

    return group.completed === '1' ? (
        <Tooltip
            placement="right"
            title={t('This group has completed with number of participants')}
        >
            <Badge dot color="#FC7886">
                <GroupIcon />
            </Badge>
        </Tooltip>
    ) : (
        <GroupIcon />
    );
};

export default GroupAvatar;
