import { useEffect } from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { firstToUpper } from '@/helpers/string';

const useNotification = ({
    messageSuccess,
    error,
    item,
    clearAction,
    pushUrl,
    mode,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (messageSuccess !== '' && messageSuccess !== undefined) {
            if (item) {
                notification.success({
                    description: `${item} ${t(messageSuccess)} !`,
                });
            } else {
                notification.success({
                    description: `${firstToUpper(t(messageSuccess))} !`,
                });
            }
            if (mode === 'view') {
                push(pushUrl);
            }
        }
        if (error !== null) {
            notification.error({
                description: error,
            });
        }
        clearAction && dispatch(clearAction);
    }, [messageSuccess, error, dispatch, t, item]);
};

export default useNotification;
