import { all } from 'redux-saga/effects';
import partnerSaga from './partner/saga';
import supplierSaga from './supplier/saga';
import requestSaga from './request/saga';
import shareB2BServiceSaga from './share/saga';
import agencyprofileSaga from './agencyprofile/saga';

function* b2bSaga() {
    yield all([
        partnerSaga(),
        supplierSaga(),
        requestSaga(),
        shareB2BServiceSaga(),
        agencyprofileSaga(),
    ]);
}

export default b2bSaga;
