import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Button } from 'antd';

import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import { MoreOutlinedIcon } from '@/assets/images/icons/screens';

const Menu = ({
    editUrl,
    deleteAction,
    type,
    isShared,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    name,
    setItemName,
}) => {
    const { push } = useHistory();

    const onEdit = useCallback(() => {
        push(editUrl);
    }, [push, editUrl]);
    return (
        <Dropdown
            trigger={['click']}
            placement="bottom"
            overlayClassName="edit-delete-dropdown"
            menu={{
                items: SimpleDropdownMenu({
                    onEditClick: onEdit,
                    deleteAction,
                    type,
                    isShared,
                    removeSharedServiceAction,
                    removeSharedCollectedServiceAction,
                    name,
                    setItemName,
                }),
            }}
        >
            <Button type="text" className={!myService && 'display-none'}>
                <MoreOutlinedIcon />
            </Button>
        </Dropdown>
    );
};

export default Menu;
