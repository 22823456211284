export default {
    submitting: false,
    errors: null,
    messageSuccess: '',
    resendEmailError: null,
    step: 0, // 0, 1, 2
    email: null, // needed for step 1
    code: null, // needed for step 2
    token: null, // needed for step 3
};
