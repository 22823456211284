import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import IntegrationService from '@/services/IntegrationService';
import { types, actions } from './reducer';

function* getWidgetDiv({ data }) {
    try {
        const response = yield call(IntegrationService.getWidgetDiv, data);
        const {
            search: widgetSearch,
            result: widgetResult,
            custom: widgetCustom,
        } = response.data;
        yield put(actions.getWidgetDivSucceeded(
            widgetSearch,
            widgetResult,
            widgetCustom,
        ));
    } catch (error) {
        yield put(actions.getWidgetDivFailed(extractError(error)));
    }
}

function* getServices() {
    try {
        const response = yield call(IntegrationService.getServiceList);
        yield put(actions.getServicesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getServicesFailed(extractError(error)));
    }
}

function* getDomainWhitelist() {
    try {
        const response = yield call(IntegrationService.getDomainWhitelist);
        yield put(actions.getDomainWhitelistSucceeded(response.data));
    } catch (error) {
        yield put(actions.getDomainWhitelistFailed(extractError(error)));
    }
}

function* addNewDomain({ data }) {
    try {
        const response = yield call(IntegrationService.addNewDomain, data);
        yield put(actions.addNewDomainSucceeded(response.data, 'Domain added successfully'));
    } catch (error) {
        yield put(actions.addNewDomainFailed(extractError(error)));
    }
}

function* deleteDomain({ id }) {
    try {
        yield call(IntegrationService.deleteDomain, id);
        yield put(actions.deleteDomainSucceeded(id, 'Domain deleted successfully'));
    } catch (error) {
        yield put(actions.deleteDomainFailed(extractError(error)));
    }
}

function* watchGetWidgetDiv() {
    yield takeEvery(
        types.REQUEST_GET_WIDGET_DIV,
        getWidgetDiv,
    );
}

function* watchGetServices() {
    yield takeEvery(
        types.REQUEST_GET_SERVICES,
        getServices,
    );
}

function* watchGetDomainWhitelist() {
    yield takeEvery(
        types.REQUEST_GET_DOMAIN_WHITELIST,
        getDomainWhitelist,
    );
}

function* watchAddNewDomain() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_DOMAIN,
        addNewDomain,
    );
}

function* watchDeleteDomain() {
    yield takeEvery(
        types.REQUEST_DELETE_DOMAIN,
        deleteDomain,
    );
}

function* integrationsSaga() {
    yield all([
        fork(watchGetWidgetDiv),
        fork(watchGetServices),
        fork(watchGetDomainWhitelist),
        fork(watchAddNewDomain),
        fork(watchDeleteDomain),
    ]);
}

export default integrationsSaga;
