import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    services: [],
    billedFor: null,
    invoiceTo: null,
    extra: null,
    loading: false,
    errors: null,
    submitting: false,
    step: 0, // 0, 1, 2
    completedStep: null, // null, 0, 1, 2

    existingServices: [],
    serviceDetails: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.invoice.createcustom,
    model,
);

export default model;
