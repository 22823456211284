import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import SupplierService from '@/services/B2BPartnerServices/SupplierService';
import { types, actions } from './reducer';

function* sendServiceRequest({ data }) {
    try {
        yield call(SupplierService.sendServiceRequest, data);
        yield put(actions.sendServiceRequestSucceeded('The request has been sent successfully'));
    } catch (error) {
        yield put(actions.sendServiceRequestFailed(extractError(error)));
    }
}

function* watchSendServiceRequest() {
    yield takeEvery(
        types.SEND_SERVICE_REQUEST,
        sendServiceRequest,
    );
}

function* collectSaga() {
    yield all([
        fork(watchSendServiceRequest),
    ]);
}

export default collectSaga;
