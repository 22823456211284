import BaseService from './BaseService';

class RegistrationService extends BaseService {
    // step 1
    registerStepOne = (data) => this.post('/register/agency', data);

    // step 2
    verifyEmail = (code) => this.get(`/agency/confirmemail/${code}`);

    resendConfirmEmail = () => this.get('/agency/resendconfirmemail');

    // step 3
    confirmPhone = (code) => this.get(`/agency/confirmphone/${code}`);

    resendConfirmPhoneText = () => this.get('/agency/sendphoneconfirmationtext');

    // step 4
    setAgencyDetails = (data) => this.post('/agency/setagencydetails', data);

    getAgencyServices = () => this.get('/agency/getservices');

    // step 5
    addServicesList = (data) => this.post('/agency/addservicelist', data);

    getRegisterStep = () => this.get('/agency/getregisterstep');

    // used to build the user
    getRegistrationDetails = () => this.get('/agency/getregistrationdetails');

    changePhoneNumber = (data) => this.post('/agency/changephonenumber', data);
}

export default new RegistrationService();
