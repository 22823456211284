import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom';

import AuthLayout from '@/components/AuthLayout';
import Loading from '@/components/Loading';

const WithNavigation = ({ children }) => (
    <AuthLayout>
        {children}
    </AuthLayout>
);

const RouteWithSubRoutes = ({
    path,
    exact,
    routes,
    component: Component,
}) => (

    <Route
        path={path}
        exact={exact}
        render={(props) => <Component {...props} routes={routes} />}
    />
);

const Routes = ({
    routes,
    // accessibleRoutes,
    isAuthenticatedRoutes,
    withoutNavRoutes,
}) => {
    const location = useLocation();
    return (
        <Switch>
            <Redirect
                exact
                from="/app"
                to="/app/dashboard"
            />
            {/*
            ***** this is for latter when we apply access levels
            {routes.map(({ accessor, key, ...route }) =>
                accessibleRoutes.includes(accessor) && (
                    <RouteWithSubRoutes key={key} {...route} />
                )
            )}
            */}
            {isAuthenticatedRoutes ? (
                <>
                    {withoutNavRoutes.map(({ key, ...route }) => {
                        return <RouteWithSubRoutes key={key} {...route} />;
                    })}
                    {location.pathname !== withoutNavRoutes[0].path && (
                        <WithNavigation>
                            {routes.map(({ key, ...route }) => {
                                return <RouteWithSubRoutes key={key} {...route} />;
                            })}
                        </WithNavigation>
                    )}

                </>
            ) : (

                routes.map(({ key, ...route }) => {
                    return <RouteWithSubRoutes key={key} {...route} />;
                })

            )}

            {/* <Redirect to="/not-found" /> */}
            {/* <Redirect to="/login" /> */}
            <Redirect
                exact
                from="/"
                to="/app/dashboard"
            />
        </Switch>
    );
};

const accessibleRoutes = [];
export const AuthenticatedRoutes = (props) => {
    const history = useHistory();

    const fetchingSelf = useSelector(
        (state) => state?.authentication?.fetchingSelf,
    );
    const { authenticated, authenticatedReview } = useSelector(
        (state) => state?.authentication,
    );

    const registerStep = useSelector(
        (state) => state?.authentication?.registration?.registerStep,
    );
    useEffect(() => {
        if (!fetchingSelf) {
            if (authenticated || sessionStorage.getItem('token')) {
                if (authenticatedReview) {
                    history.push('/app/waiting-approve');
                }
                if (registerStep !== null && registerStep < 5) {
                    history.push('/register');
                }
            } else {
                history.push('/login');
            }
        }
    }, [history.push, authenticated, registerStep, fetchingSelf, authenticatedReview, history]);

    const { filteredRoutes, withoutNavRoutes } = props?.routes?.reduce((prev, current) => {
        if (current.key === 'APP_Approve') {
            return {
                filteredRoutes: prev.filteredRoutes,
                withoutNavRoutes: [...prev.withoutNavRoutes, current],
            };
        } else {
            return {
                filteredRoutes: [...prev.filteredRoutes, current],
                withoutNavRoutes: [...prev.withoutNavRoutes],
            };
        }
    }, {
        filteredRoutes: [], withoutNavRoutes: [],
    });
    // const filteredRoutes = props?.routes.filter((route) => route.key == 'APP_Approve');
    if (fetchingSelf) return <Loading />;
    return (
        <>
            <Routes
                {...props}
                accessibleRoutes={accessibleRoutes}
                isAuthenticatedRoutes={true}
                routes={filteredRoutes}
                withoutNavRoutes={withoutNavRoutes}
            />
        </>
    );
};
export default Routes;
