import CountryFlagComponent from '@/components/CountryFlag';

const Toggle = ({ languageFlag }) => {
    return (
        <button className="language-select-toggle" type="button">
            <CountryFlagComponent code={languageFlag} />
        </button>
    );
};

export default Toggle;
