import BaseService from './BaseService';

class EmployeesService extends BaseService {
    getAll = (filters) => this.post('/agency/employeeslist', filters || { });

    getEmployeeDetails = (id) => this.get(`/agency/viewagencyemployee/${id}`);

    getEmployeeInvoices = (id, filters) => this.post(
        `/agency/viewagencyemployeeinvoices/${id}`,
        filters || { },
    );

    getSkillList = () => this.get('/agency/skilllist');

    deleteOne = (id) => this.delete(`/agency/deleteemployee/${id}`);

    // personal details
    addEmployeeDetails = (data) => this.post(
        '/agency/newagencyemployee',
        data,
    );

    editEmployeeDetails = (id, data) => this.post(
        `/agency/editagencyemployee/${id}`,
        data,
    );

    viewPersonalDetails = (id) => this.get(`/agency/viewagencyemployee/${id}`);

    // other services
    getGroups = () => this.get('/agency/getagencygroups');

    addNewGroup = (data) => this.post('/agency/newagencygroup', data);

    editGroup = (id, data) => this.post(`/agency/editagencygroup/${id}`, data);

    getRoles = () => this.get('/agency/getagencyroles');

    getEmployeeInvociesGraphs = (id) => this.get(`/agency/employeedashboarddetails/${id}`);

    getGroupRoles = (id) => this.get(`/agency/searchagencygroups/${id}`);

    deleteGroup = (id) => this.delete(`/agency/deletegroup/${id}`);
}

export default new EmployeesService();
