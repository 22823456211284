import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    step: 0, // 0, 1, 2
    completedStep: null, // null, 0, 1, 2
    submitting: false,
    loading: false,
    errors: null,
    cruiseTypeError: null,

    /*
     * manages mode of ship details
     * if false, the user is watching ships
     * if true, the user is creating ship
     * */
    isCreatingShip: false,
    submittingShip: false, // loading state for create ship submittion

    generalInfo: null,
    shipDetails: [],
    itenerary: [],
    inclusions: [],
    exclusions: [],

    // edit or create new ship
    shipList: [],
    ship: null,

    // delete ship
    id: null,

    // cruise types
    cruiseTypes: [],
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.cruise.create,
    model,
);

export default model;
