import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Space,
    Select,
    Typography,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { NewUserIcon, SortIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/employees/create/reducer';
import { useEffect } from 'react';
import { selectors } from '@/redux/employees/create/model';

const Filters = ({
    filters,
    setFilters,
    total,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const addEmployeeUrl = '/app/employees/employees/add';

    const groups = useSelector(selectors.groups);

    useEffect(() => {
        dispatch(actions.requestGetGroups());
    }, [dispatch]);

    return (
        <Form
            initialValues={filters}
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                setFilters(name[0], value);
            }}
        >
            <Row
                className="filter-content"
                justify="space-between"
            >
                <Col>
                    <BlockDescription
                        className="bigger-text-size"
                        name={t('Employees').toUpperCase()}
                    >
                        <Typography.Text>
                            {`${t('Currently')} ${total} ${t('Employees').toLowerCase()}`}
                        </Typography.Text>
                    </BlockDescription>
                </Col>

                <Col>
                    <Space size={30}>
                        <Form.Item name="name" noStyle>
                            <Input.Search
                                className="search"
                                placeholder={t('Search by employee name')}
                            />
                        </Form.Item>

                        <Form.Item name="role" noStyle>
                            <Select
                                placeholder={t('Roles')}
                                mode="multiple"
                                className="role-select"
                            >
                                {groups.map((group) => (
                                    <Select.Option
                                        key={group.id}
                                        value={group.id}
                                    >
                                        {group.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item name="sort" noStyle>
                            <Select
                                placeholder={t('Sort by')}
                                suffixIcon={<SortIcon />}
                            >
                                <Select.Option value="full_name">{t('Full Name')}</Select.Option>
                            </Select>
                        </Form.Item>

                        <Link to={addEmployeeUrl}>
                            <Button
                                className="button add-button"
                                onClick={() => dispatch(actions.clearEmployeeState())}
                            >
                                <NewUserIcon />

                                {t('Add new Employee')}
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export default Filters;
