import pagination from '@/helpers/pagination';
import create from './create/model';
import deleteModel from './delete/model';
import view from './view/model';
// import CRUD from '@/helpers/CRUD';

export default {
    ...pagination('tourActivities').model,
    create,
    delete: deleteModel,
    view,
};
