import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import moment from 'moment';
import clsx from 'clsx';
import {
    Row,
    Col,
    DatePicker,
    Input,
    Form,
    Button,
} from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import MUIFormItem from '@/components/MUIFormItem';
import { dateFormat } from '@/helpers/dateFormat';
import { selectors } from '@/redux/tour/trip/view/model';
import { actions } from '@/redux/tour/trip/view/reducer';

import EmptyGroupList from './EmptyGroupList';
import Filters from './Filters';
import GroupList from './GroupList';

const Reservation = ({ fetchMore }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const [adultNumber, setAdultNumber] = useState(2);
    const [childNumber, setChildNumber] = useState(0);
    const [infantNumber, setInfantNumber] = useState(0);

    const { data: groups } = useSelector(selectors.groups);
    const { totalItems: totalGroup } = useSelector(selectors.groups);
    const groupDetails = useSelector(selectors.groupDetails);
    const { isLoadingMore: isLoadingMoreData } = useSelector(selectors.groups);
    const { hasMore: hasMoreData } = useSelector(selectors.groups);

    const onSubmit = useCallback(({ name, startDate }) => {
        const data = { name, date_start: startDate?.format(dateFormat) };
        if (groupDetails !== null) {
            dispatch(actions.requestUpdateTourTripGroups(data));
        } else {
            dispatch(actions.requestCreateTourTripGroups(
                {
                    id,
                    data,
                },
            ));
        }
        setIsOpen(false);
        form.resetFields();
        dispatch(actions.clearGroupDetails());
    }, [id, groupDetails, dispatch, form]);

    useEffect(() => {
        let initialData = {};
        if (groupDetails) {
            setIsOpen(true);
            initialData = {
                name: groupDetails.name,
                startDate: moment(groupDetails.date_start),
            };
        }
        form.setFieldsValue(initialData);
    }, [form, groupDetails]);

    return (
        <>
            <Filters
                onAddClick={() => setIsOpen(true)}
                adultNumber={adultNumber}
                setAdultNumber={setAdultNumber}
                childNumber={childNumber}
                setChildNumber={setChildNumber}
                infantNumber={infantNumber}
                setInfantNumber={setInfantNumber}
            />
            <Form
                className={clsx(isOpen ? 'add-group-form' : 'hide-form')}
                form={form}
                // size="large"
                requiredMark={false}
                onFinish={onSubmit}
                layout="inline"
            >
                <Row gutter={30}>
                    <Col className="usergroup-add-icon">
                        <UsergroupAddOutlined />
                    </Col>

                    <Col>
                        <MUIFormItem
                            label={t('Group Name')}
                            name="name"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col>
                        <MUIFormItem
                            label={t('Starting date')}
                            name="startDate"
                            rules={[{ required: true }]}
                        >
                            <DatePicker />
                        </MUIFormItem>
                    </Col>

                    <Col>
                        <Button
                            className="button save-button"
                            onClick={() => form.submit()}
                        >
                            {t('Save').toUpperCase()}
                        </Button>
                    </Col>
                </Row>
            </Form>
            {(totalGroup === 0 && !isOpen)
                ? (<EmptyGroupList setIsOpen={setIsOpen} />)
                : (
                    <>
                        <GroupList
                            groups={groups}
                            adultNumber={adultNumber}
                            childNumber={childNumber}
                            infantNumber={infantNumber}
                        />
                        {!isLoadingMoreData && hasMoreData && (
                            <Waypoint onEnter={fetchMore} />
                        )}
                    </>
                )}
        </>
    );
};

export default Reservation;
