import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InvoiceExtras from '@/components/Invoice/InvoiceExtras';
import { actions } from '@/redux/invoice/create/reducer';
import { selectors } from '@/redux/invoice/create/model';

const formatClients = (service, clients) => {
    const result = {};
    clients.forEach((item) => {
        const [first, second] = item.split('-');
        if (!result[first]) {
            result[first] = [];
        }
        result[first].push(second);
    });

    const matchService = Object.keys(result).includes(service.toString());
    if (matchService) {
        return result[service];
    } else {
        return [];
    }
};

const Extras = ({ form }) => {
    const dispatch = useDispatch();

    const invoiceDetails = useSelector(selectors.invoiceDetails);
    const invoiceParticipants = useSelector(selectors.invoiceParticipants);
    const { reservation_type: reservationType } = invoiceDetails !== null
    && invoiceDetails.invoice_data;

    // checks whether it is a tour invoice or an accommodation invoice
    const invoiceType = reservationType !== 'Hotel';

    const extraServices = useSelector(selectors.extraServices);

    const reservationData = (invoiceDetails !== null
        && invoiceDetails.tour_trip_general_data)
    || (invoiceDetails !== null && invoiceDetails.tour_package_general_data)
    || (invoiceDetails !== null && invoiceDetails.tour_cruise_general_data)
    || (invoiceDetails !== null && invoiceDetails.activity_general_data)
    || (invoiceDetails !== null && invoiceDetails.hotel_general_data)
    || null;
    const { id: reservationId } = reservationData !== null && reservationData;

    useEffect(() => {
        if (reservationId) {
            dispatch(actions.requestGetExtraServices(reservationId, reservationType));
        }
    }, [reservationId, dispatch, reservationType]);

    const [clients, setClients] = useState([]);

    const onFinish = useCallback((values) => {
        let data = null;
        if (reservationType === 'Hotel') {
            data = {
                discount: values.discount,
                discount_type: values.discount_type,
                service_id: reservationId,
            };
        } else {
            data = {
                discount: values.discount,
                discount_type: values.discount_type,
                extra_services: values.services
                    ? values.services.map((service) => (
                        {
                            id: service,
                            clients: formatClients(service, clients),
                        }
                    ))
                    : [],
                service_id: reservationId,
            };
        }
        dispatch(actions.requestSubmitInvoiceExtras(data));
    }, [dispatch, clients, reservationType]);

    useEffect(() => {
        const initialData = {
            discount_type: 4,
        };
        form.setFieldsValue(initialData);
    }, [form]);

    return (
        <InvoiceExtras
            form={form}
            onFinish={onFinish}
            group="participants"
            invoiceParticipants={invoiceParticipants}
            invoiceType={invoiceType}
            extraServices={extraServices}
            clients={clients}
            setClients={setClients}
        />
    );
};

export default Extras;
