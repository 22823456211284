import BaseService from '@/services/BaseService';

class CruiseService extends BaseService {
    getAll = (filters) => this.post('/agency/getcruiseslist', filters || { });

    getOne = (id) => this.get(`/agency/viewcruise/${id}`);

    getReservationList = (id, filters) => this.post(
        `/agency/searchtourcruisecabins/${id}`,
        filters,
    );

    deleteOne = (id) => this.delete(`/agency/deletetour/${id}`);

    // add general details
    addGeneralInfo = (data) => this.post(
        '/agency/addcruisegeneralinfo',
        data,
    );

    // add ship details
    addNewShip = (data) => this.post(
        '/agency/addnewship',
        data,
    );

    viewShipOnClick = (id, data) => this.get(
        `/agency/viewship/${id}`,
        data,
    );

    deleteShip = (id) => this.delete(
        `/agency/deleteship/${id}`,
    );

    editShipOnClick = (id, data) => this.post(
        `/agency/editship/${id}`,
        data,
    );

    getShipList = (filters) => this.post(
        '/agency/getshiplist/1', // temporary logic, must be dynamic page
        filters || { },
    );

    submitShip = (id, data) => this.post(
        `/agency/addcruiseshipdata/${id}`,
        data,
    );

    // add itenerary
    addItenerary = (id, data) => this.post(
        `/agency/addcruiseitinerary/${id}`,
        data,
    );

    // add inclusions, exclusions
    addInclusionsExclusions = (id, data) => this.post(
        `/agency/addcruiseinclusions/${id}`,
        data,
    );

    // edit cruise general info
    editGeneralInfo = (id, data) => this.post(
        `/agency/editcruisegeneralinfo/${id}`,
        data,
    );

    // edit cruise ship details
    editShip = (id, data) => this.post(
        `/agency/editcruiseshipdata/${id}`,
        data,
    );

    // edit cruise itenerary
    editItenerary = (id, data) => this.post(
        `/agency/editcruiseitinerary/${id}`,
        data,
    );

    // edit cruise inclusions and exclusions
    editInclusionsExclusions = (id, data) => this.post(
        `/agency/editcruiseinclusions/${id}`,
        data,
    );

    // view cruise general details
    viewDetails = (id) => this.get(`/agency/viewcruisegeneralinfo/${id}`);

    // view cruise itenerary
    viewItenerary = (id) => this.get(`/agency/viewcruiseitinerary/${id}`);

    // view cruise ship
    viewShip = (id) => this.get(`/agency/viewcruiseshipdata/${id}`);

    // view cruise inclusion exclusions
    viewInclusionsExclusions = (id) => this.get(
        `/agency/viewcruiseinclusions/${id}`,
    );

    // other services
    getInclusionsExclusions = () => this.get('/agency/getinclusions/3');

    addNewInclusion = (data) => this.post('/agency/addnewinclusion', data);

    addNewExclusion = (data) => this.post('/agency/addnewexclusion', data);

    getCruiseTypes = (data) => this.post('/agency/gettourtypeslist/3', data || { });

    addNewCruiseType = (data) => this.post('/agency/addtourtype', data);

    getAmenities = () => this.get('/agency/getcabinamenities');

    getCabinTypeList = (id) => this.get(`/agency/getcabintypeslist/${id}`);
}

export default new CruiseService();
