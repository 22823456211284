import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    partnerDetails: null,
    loading: false,
    error: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.b2b.partner.view,
    model,
);

export default model;
