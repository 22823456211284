import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    employeeDetails: null,
    skillList: [],
    loading: false,
    error: null,

    invoiceList: [],
    total: 0,
    fetchingLoading: true,
    fetchingError: null,
    isLoadingMore: false,
    isLoadingMoreError: null,
    hasMore: false,
};

export const selectors = createSelectorsFromModel(
    (state) => state.employees.view,
    model,
);

export default model;
