import { useSelector } from 'react-redux';
import {
    Row,
    Col,
    Card,
    Divider,
} from 'antd';
import { selectors } from '@/redux/accommodation/hotel/view/model';
import ViewWidgetCategories from '@/components/ViewWidgetCategories';
import GeneralData from './GeneralData';
import ServicesAndFacilities from './ServicesAndFacilities';
import ImageGallery from './Images';
import Policies from './Policies';
import ContactDetails from './ContactDetails';

const AccommodationDetails = ({ myService }) => {
    const {
        general_data: generalDetails,
        rooms: roomList,
        facilities: facilitiesList,
        services: servicesList,
        contact: contactPerson,
        policy: policyData,
        price_from: price,
        images: imageList,
        widget_categories: widgetCategories,
    } = useSelector(selectors.accommodationDetails);

    return (
        <Row gutter={30}>
            <Col lg={14} xs={24}>
                <Card bordered={false} className="left-col">
                    <GeneralData
                        generalDetails={generalDetails}
                        roomList={roomList}
                        priceData={price}
                        myService={myService}
                    />

                    <Divider />

                    <ServicesAndFacilities
                        facilities={facilitiesList}
                        services={servicesList}
                    />

                    <ViewWidgetCategories data={widgetCategories} />

                    <Divider />

                    <Row>
                        <Col lg={12} xs={24}>
                            <Policies data={policyData} />
                        </Col>

                        <Col lg={1}>
                            <Divider type="vertical" />
                        </Col>

                        <Col lg={11} xs={24}>
                            <ContactDetails data={contactPerson} />
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col lg={10} xs={24}>
                <div className="right-col">
                    <ImageGallery data={imageList} />
                </div>
            </Col>
        </Row>
    );
};

export default AccommodationDetails;
