import { Switch, Route, Redirect } from 'react-router-dom';

import ViewAll from './ViewAll';
import ViewOne from './ViewOne';
import New from './New';

export const routes = [
    {
        name: 'ViewAll',
        url: '/',
        component: ViewAll,
    },
    {
        name: 'New',
        url: '/add',
        component: New,
    },
    {
        name: 'Edit',
        url: '/:id/edit',
        component: (props) => <New {...props} isEdit />,
    },
    {
        name: 'ViewOne',
        url: '/:id',
        component: ViewOne,
    },
];

const Cruise = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/tours/cruise${route.url}`}
                    exact={true}
                >
                    <route.component />
                </Route>
            ))}

            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Cruise;
