import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Card,
    Button,
    Dropdown,
    Divider,
    Space,
    Modal,
} from 'antd';
import clsx from 'clsx';

import InfoBlock from '@/components/Information/WithoutImage/Block';
import { MoreOutlinedIcon, DeleteIcon, EditIcon } from '@/assets/images/icons/screens';
import { selectors } from '@/redux/tour/trip/view/model';
import { actions as groupActions } from '@/redux/tour/trip/view/reducer';
import { actions as invoiceActions } from '@/redux/invoice/create/reducer';
import { useCurrency } from '@/hooks/useCurrency';

import Participants from './Participants';
import GroupAvatar from './Avatar';

const formatParticipants = (participants, totalAdult) => {
    const diff = parseInt(totalAdult, 10) - participants?.length;
    const emptyParticipants = [];
    for (let i = 0; i < diff; i++) {
        emptyParticipants.push({});
    }
    const formattedParticipants = participants?.concat(emptyParticipants);
    return formattedParticipants;
};

const GroupList = ({
    groups,
    adultNumber,
    childNumber,
    infantNumber,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const type = 'Tour Trip';
    const { icon: currencyIcon } = useCurrency();

    const { creatingLoading: loading } = useSelector(selectors.groups);
    const { id } = useSelector(selectors.generalInfo);

    const editGroup = useCallback((groupId) => {
        dispatch(groupActions.requestViewTourTripGroup(groupId));
    }, [dispatch]);

    const showDeleteModal = useCallback((groupId) => {
        Modal.confirm({
            className: 'confirm-modal',
            title: t('Are you sure to delete this group'),
            onOk() {
                dispatch(groupActions.requestDeleteTourTripGroups(
                    groupId,
                ));
            },
        });
    }, [dispatch, t]);

    const bookNow = (data) => {
        const reservationData = {
            reservation_type: type,
            tour_id: id,
            date_start: data.date_start,
            group_name: data.name,
            currency_icon: data.icon || currencyIcon,
            data: [
                {
                    group_: data.id,
                    adults: adultNumber,
                    children: childNumber,
                    infants: infantNumber,
                },
            ],
        };
        dispatch(invoiceActions.clearInvoiceState());
        dispatch(invoiceActions.prepareReservationDataForSubmit(reservationData));
        dispatch(invoiceActions.requestGetReservationData(
            id,
            {
                type: 1,
                adults: adultNumber,
                children: childNumber,
                infants: infantNumber,
            },
        ));
        push('/app/invoices/new');
    };

    return (
        groups?.map((group) => (
            <Card className="group-cards" key={group.id}>
                <Card.Meta
                    description={(
                        <Row justify="space-between" align="middle" gutter={[0, 25]}>
                            <Col>
                                <Space size={25}>
                                    <div className="usergroup-add-icon">
                                        <GroupAvatar group={group} />
                                    </div>
                                    <Space
                                        size={25}
                                        split={(<Divider type="vertical" />)}
                                    >
                                        <InfoBlock name={t('Group Name')}>
                                            {group.name}
                                        </InfoBlock>

                                        <InfoBlock name={t('Start Date')}>
                                            {group.date_start}
                                        </InfoBlock>

                                        <InfoBlock name={t('Participants')}>
                                            {group.participants}
                                        </InfoBlock>

                                        <Participants participants={
                                            formatParticipants(group.participantsData, group.gl_a)
                                        }
                                        />

                                        <InfoBlock name={t('Avg. Age')}>
                                            {group.age}
                                        </InfoBlock>

                                        <InfoBlock name={t('No. Reservation')}>
                                            {group.reservations_no}
                                        </InfoBlock>
                                    </Space>
                                </Space>
                            </Col>

                            <Col>
                                <Button
                                    onClick={() => bookNow(group)}
                                    className={clsx(
                                        'reservation-button',
                                        group.completed === '1' || (adultNumber === 0
                                        && childNumber === 0
                                        && infantNumber === 0)
                                            ? 'btn-grey'
                                            : 'btn-orange',
                                    )}
                                    loading={loading}
                                    disabled={adultNumber === 0
                                        && childNumber === 0
                                        && infantNumber === 0}
                                >
                                    {t('Add reservation').toUpperCase()}
                                </Button>

                                <Dropdown
                                    trigger={['click']}
                                    placement="bottom"
                                    overlayClassName="edit-delete-dropdown"
                                    menu={{
                                        items: [{
                                            icon: <div><EditIcon /></div>,
                                            key: 'edit',
                                            onClick: () => editGroup(group.id),
                                        },
                                        {
                                            icon: <div><DeleteIcon /></div>,
                                            key: 'delete',
                                            onClick: () => showDeleteModal(group.id),
                                        }],
                                    }}
                                >
                                    <Button type="text">
                                        <MoreOutlinedIcon />
                                    </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                    )}
                />
            </Card>
        ))
    );
};

export default GroupList;
