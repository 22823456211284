import CountryFlag from 'react-country-flag';

const CountryFlagComponent = ({ code }) => {
    return (
        <CountryFlag
            countryCode={code}
            svg
            style={{
                width: '2em',
                height: '2em',
            }}
        />
    );
};

export default CountryFlagComponent;
