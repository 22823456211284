import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('taxList');

const actions = {
    requestFetchTaxList: baseActions.request,
    fetchTaxListSucceeded: baseActions.succeeded,
    fetchTaxListFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
