import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    packageDetails: null,
    roomTypeList: [],
    loading: false,
    error: null,

    roomList: [],
    filterData: null,
    fetchingLoading: true,
    fetchingError: null,
    isLoadingMore: false,
    isLoadingMoreError: null,
    hasMore: false,
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.packages.view,
    model,
);

export default model;
