import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { actions } from '@/redux/invoice/reducer';
import { selectors } from '@/redux/invoice/create/model';

const FindOrCreateResource = ({
    searchText,
    seperator,
    createForm,
}) => {
    const dispatch = useDispatch();

    const customerList = useSelector(selectors.customerList);
    const loading = useSelector(selectors.loading);

    // get customer list by search
    const onSearch = useCallback((search) => {
        if (search && search.length === 3) {
            const data = {
                full_name: search,
            };
            dispatch(actions.requestGetCustomerList(data));
        }
    }, [dispatch]);

    // get existing customer
    const getExistingCustomer = useCallback((id) => {
        dispatch(actions.requestGetExistingCustomer(id));
    }, [dispatch]);

    return (
        <div className="find-or-create-resource">
            <Select
                className="select-customer"
                showSearch
                filterOption={false}
                defaultActiveFirstOption={false}
                notFoundContent={null}
                loading={loading}
                onSearch={onSearch}
                placeholder={searchText}
                onSelect={(customerId) => getExistingCustomer(customerId)}
            >
                {customerList.map((customer) => (
                    <Select.Option
                        key={customer.id}
                        value={customer.id}
                    >
                        {customer.full_name}
                    </Select.Option>
                ))}
            </Select>

            {seperator}

            {createForm}
        </div>
    );
};

export default FindOrCreateResource;
