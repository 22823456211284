import {
    Avatar,
    Col,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@/assets/images/icons/screens';
import { AccommodationIcon } from '@/assets/images/icons/sidebar';
import BlockDescription from '@/components/Information/WithImage/Block';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import SectionLayout from '../../SectionLayout';

const TourPackage = ({ reservationData }) => {
    const { t } = useTranslation();

    const rooms = reservationData?.rooms || reservationData?.data;

    return rooms !== undefined && (
        <SectionLayout
            className="tour-details"
            icon={(
                <Avatar
                    size={60}
                    src={reservationData.package?.image || reservationData.image}
                />
            )}
            leftCol={3}
            rightCol={21}
        >
            <Row className="tour-package-details">
                <Col xs={12}>
                    <Typography.Title level={5}>
                        { reservationData.package?.name || reservationData.name}
                    </Typography.Title>

                    <div>
                        <BlockDescription
                            spaceSize={15}
                            icon={<AccommodationIcon />}
                            label={t('Accommodation')}
                            content={
                                reservationData.package?.accommodation
                                || reservationData.accommodation
                            }
                        />
                    </div>
                    <div>
                        <BlockDescription
                            spaceSize={15}
                            icon={<CalendarIcon />}
                            label={t('Duration / Date')}
                            content={`${reservationData.date_start} - ${reservationData.date_end}`}
                        />
                    </div>
                </Col>

                <Col xs={12}>
                    <div className="rooms">
                        {rooms.map((room) => (
                            <div key={room.id || room.room_type}>
                                <Typography.Title level={5}>
                                    {room.type_name}
                                </Typography.Title>
                                <InlineDescription
                                    label={t('No. of rooms')}
                                    content={room.no_rooms}
                                />
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default TourPackage;
