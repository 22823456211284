import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EmptyList from '@/components/EmptyList';
import { EmptyCustomerListIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/customers/reducer';

import Filters from './Filters';
import CustomerList from './CustomerList';
import Loading from './Loading';

const initialFilters = {};

const ViewAll = () => {
    const dispatch = useDispatch();
    const [filters, baseSetFilters] = useState(initialFilters);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const setFilters = useCallback((name, value) => {
        baseSetFilters((prev) => ({ ...prev, [name]: value }));
    }, [baseSetFilters]);

    const [checkedList, setCheckedList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    const customers = useSelector((state) => state.customers.data);
    const loading = useSelector((state) => state.customers.fetchingLoading);
    const totalItems = useSelector((state) => state.customers.totalItems);

    useEffect(() => {
        const data = {
            ...filters,
            date: filters.date?.format('YYYY-MM-DD'),
            countries: filters.countries?.map((country) => country.value),
            page: currentPage,
            perPage: itemsPerPage,
        };
        dispatch(actions.requestFetchCustomers(data));
    }, [dispatch, currentPage, itemsPerPage, filters]);

    return (
        <div className="cf-m1">
            <Filters
                data={customers}
                filters={filters}
                setFilters={setFilters}
                setCheckedList={setCheckedList}
                checkAll={checkAll}
                setCheckAll={setCheckAll}
                totalItems={totalItems}
            />
            { loading
                ? <Loading />
                : customers.length === 0

                    ? (
                        <EmptyList
                            image={<EmptyCustomerListIcon />}
                            category={'Start creating customer'.toUpperCase()}
                            message="Oops! Looks you haven't created yet any Customer"
                            url="/app/customers/add"
                        />
                    ) : (
                        <CustomerList
                            data={customers}
                            checkedList={checkedList}
                            setCheckedList={setCheckedList}
                            setCheckAll={setCheckAll}
                            totalItems={totalItems}
                            page={currentPage}
                            setPage={setCurrentPage}
                            perPage={itemsPerPage}
                            setPerPage={setItemsPerPage}
                        />
                    )}
        </div>
    );
};

export default ViewAll;
