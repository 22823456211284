import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Routes from '@/routes';
import Loading from '@/components/Loading';
import { actions } from '@/redux/authentication/reducer';

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.requestFetchSelf());
    }, [dispatch]);
    return (
        <Suspense fallback={<Loading />}>
            <Routes />
        </Suspense>
    );
};

export default App;
