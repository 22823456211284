import {
    useState,
    useMemo,
    useEffect,
    useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

import Header from '@/components/ViewOneHeader';
import { selectors } from '@/redux/tour/cruise/view/model';
import { actions } from '@/redux/tour/cruise/view/reducer';
import { getSubPageByHash } from '@/helpers/routing';
import ProductCollection from '@/components/ProductCollection';

import TourInfo from './TourInfo';
import TourItenerary from './TourItenerary';
import Reservation from './Reservation';

const subPages = [
    { name: 'Tour Info', hash: 'tour-info' },
    { name: 'Tour Itenerary', hash: 'tour-itenerary' },
    { name: 'Reservation', hash: 'reservation' },
];

const ViewOne = ({ viewFromB2b = false }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const {
        location: { hash },
        push,
    } = useHistory();
    const dispatch = useDispatch();

    const loading = useSelector(selectors.loading);
    const generalData = useSelector(selectors.generalInfo);
    const filters = useSelector(selectors.filterData);
    const tourMaxPrice = useSelector((state) => state.maxprice.tourMaxPrice);
    const { status: serviceStatus } = generalData !== null && generalData;
    const { b2b_agency_name: b2bAgency } = generalData !== null && generalData;

    const myService = b2bAgency === null; // if b2bAgency is null, it's my service

    const formattedSubPages = (myService) || (!myService && serviceStatus === '1')
        ? subPages : subPages.slice(0, 2);

    const [visibleDetails, setVisibleDetails] = useState(
        () => getSubPageByHash(subPages, hash).hash,
    );

    const options = useMemo(
        () => formattedSubPages.map((page) => ({
            label: t(page.name),
            value: page.hash,
        })),
        [formattedSubPages, t],
    );

    const ViewDetailsComponent = useMemo(() => {
        switch (visibleDetails) {
            default:
            case subPages[0].hash:
                return TourInfo;
            case subPages[1].hash:
                return TourItenerary;
            case subPages[2].hash:
                return Reservation;
        }
    }, [visibleDetails]);

    useEffect(() => {
        if (!viewFromB2b) {
            push(`/app/tours/cruise/${id}#${visibleDetails}`);
        } else {
            push(`/app/b2b-partners/worldwide-supplier/cruise/${id}#${visibleDetails}`);
        }
    }, [push, visibleDetails, id, viewFromB2b]);

    const [page, setPage] = useState(1);

    const handleFetchCruiseCabins = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            let data = null;
            if (filters) {
                data = {
                    ...filters,
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                };
            } else {
                data = {
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                    adults: 2,
                    min_price: 10,
                    max_price: tourMaxPrice,
                };
            }
            tourMaxPrice !== null
            && dispatch(actions.requestFetchCruiseCabins(id, data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            id,
            filters,
            tourMaxPrice,
        ],
    );

    useEffect(() => {
        dispatch(
            actions.requestViewCruise(id),
        );
    }, [dispatch, id]);

    useEffect(() => {
        handleFetchCruiseCabins({});
    }, [handleFetchCruiseCabins]);

    // fetch more cabins
    const handleFetchMore = useCallback(() => {
        handleFetchCruiseCabins({ currentPage: page, fetchMore: true });
    }, [handleFetchCruiseCabins, page]);

    return (
        <>
            {generalData === null || loading ? (
                'loading'
            ) : (
                <div id="view-tour-cruise-page">
                    {!myService
                        && (serviceStatus === null || serviceStatus === '2')
                        && (
                            <ProductCollection
                                data={generalData}
                                serviceStatus={serviceStatus}
                                serviceId={id}
                            />
                        )}
                    <Header
                        goBackTitle={myService ? 'View all Cruise Tours' : 'Go back'}
                        goBackUrl={myService ? '/app/tours/cruise' : '/app/b2b-partners/worldwide-supplier'}
                        title={generalData.name}
                    >
                        <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            options={options}
                            onChange={(e) => setVisibleDetails(e.target.value)}
                            value={visibleDetails}
                        />
                    </Header>
                    <div className="view-content">
                        <ViewDetailsComponent
                            myService={myService}
                            fetchMore={handleFetchMore}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ViewOne;
