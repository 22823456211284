import {
    takeEvery,
    select,
    all,
    put,
    fork,
    call,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import ActivityService from '@/services/TourServices/ActivityService';
import { types, actions } from './reducer';
import { selectors } from './model';

function* isEdit(step) {
    const completedStep = yield select(selectors.completedStep);
    if (completedStep === null) {
        return false;
    }
    return completedStep >= step;
}

function* submitActivityGeneralDetails({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        if (edit) {
            const { id } = data;
            response = yield call(ActivityService.editGeneralInfo, id, data);
        } else {
            response = yield call(ActivityService.addGeneralInfo, data);
        }
        yield put(actions.submitActivityGeneralDetailsSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitActivityGeneralDetailsFailed(extractError(error)));
    }
}

function* submitActivityTimeDuration({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        const { id: activityId } = yield select(selectors.generalInfo);
        let response;
        if (edit) {
            response = yield call(ActivityService.editTimeDuration, activityId, data);
        } else {
            response = yield call(ActivityService.addTimeDuration, activityId, data);
        }
        yield put(actions.submitActivityTimeDurationSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitActivityTimeDurationFailed(extractError(error)));
    }
}

function* getPartnerList({ filters: search }) {
    try {
        const response = yield call(ActivityService.getPartnerList, search);
        yield put(actions.getPartnerListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getPartnerListFailed(extractError(error)));
    }
}

function* getExistingPartner({ id }) {
    try {
        const res = yield call(ActivityService.viewPartner, id);
        yield put(actions.getExistingPartnerSucceeded(res.data));
    } catch (error) {
        yield put(actions.getExistingPartnerFailed(extractError(error)));
    }
}

function* submitActivityPartner({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        const { id: activityId } = yield select(selectors.generalInfo);
        let response;
        if (edit) {
            response = yield call(ActivityService.editPartner, activityId, data);
        } else {
            response = yield call(ActivityService.addPartner, activityId, data);
        }
        yield put(actions.submitActivityPartnerSucceeded(
            response.data,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(actions.submitActivityPartnerFailed(extractError(error)));
    }
}

function* fetchActivityData({ id }) {
    try {
        const [
            generalData,
            timeAndDuration,
            partner,
        ] = yield all([
            call(ActivityService.viewDetails, id),
            call(ActivityService.viewTimeAndDuration, id),
            call(ActivityService.viewPartner, id),
        ]);
        yield put(actions.fetchActivityDataSucceeded(
            generalData.data,
            timeAndDuration.data,
            partner.data,
        ));
    } catch (error) {
        yield put(actions.fetchActivityDataFailed(extractError(error)));
    }
}

function* watchSubmitActivityGeneralDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ACTIVITY_GENERAL_DETAILS,
        submitActivityGeneralDetails,
    );
}

function* watchSubmitActivityTimeDuration() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ACTIVITY_TIME_DURATION,
        submitActivityTimeDuration,
    );
}

function* watchGetPartnerList() {
    yield takeEvery(
        types.REQUEST_GET_PARTNER_LIST,
        getPartnerList,
    );
}

function* watchGetExistingPartner() {
    yield takeEvery(
        types.REQUEST_GET_EXISTING_PARTNER,
        getExistingPartner,
    );
}

function* watchSubmitActivityPartner() {
    yield takeEvery(
        types.REQUEST_SUBMIT_ACTIVITY_PARTNER,
        submitActivityPartner,
    );
}

function* watchFetchActivityData() {
    yield takeEvery(
        types.REQUEST_FETCH_ACTIVITY_DATA,
        fetchActivityData,
    );
}

function* createActivitySaga() {
    yield all([
        fork(watchSubmitActivityGeneralDetails),
        fork(watchSubmitActivityTimeDuration),
        fork(watchGetPartnerList),
        fork(watchGetExistingPartner),
        fork(watchSubmitActivityPartner),
        fork(watchFetchActivityData),
    ]);
}

export default createActivitySaga;
