import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // share to worldwide b2b
    requestShareB2bService: ['id', 'data'],
    shareB2bServiceSucceeded: ['data'],
    shareB2bServiceFailed: ['error'],

    // view shared service
    requestViewSharedService: ['id', 'data'],
    viewSharedServiceSucceeded: ['data'],
    viewSharedServiceFailed: ['error'],

    // remove shared service
    requestRemoveSharedService: ['id', 'data'],
    removeSharedServiceSucceeded: ['id', 'data'],
    removeSharedServiceFailed: ['error'],

    // remove shared collected service
    requestRemoveSharedCollectedService: ['id', 'data'],
    removeSharedCollectedServiceSucceeded: ['id', 'data'],
    removeSharedCollectedServiceFailed: ['error'],

    // clear share details
    clearSharedB2bServiceDetails: ['null'],

    // clear share/remove message
    clearShareRemoveMessage: ['null'],

    // clear b2b share error
    clearB2bShareError: ['null'],
});

export default createReducer(initialState, {

    // share to worldwide b2b
    [types.REQUEST_SHARE_B2B_SERVICE]: (state) => ({
        ...state,
        submitting: true,
        error: null,
    }),
    [types.SHARE_B2B_SERVICE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        message: data,
        submitting: false,
    }),
    [types.SHARE_B2B_SERVICE_FAILED]: (state, { error }) => ({
        ...state,
        submitting: false,
        error,
    }),

    // view shared service
    [types.REQUEST_VIEW_SHARED_SERVICE]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.VIEW_SHARED_SERVICE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        details: data[0],
        loading: false,
    }),
    [types.VIEW_SHARED_SERVICE_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // remove shared service
    [types.REQUEST_REMOVE_SHARED_SERVICE]: (state) => ({
        ...state,
        submitting: true,
        error: null,
    }),
    [types.REMOVE_SHARED_SERVICE_SUCCEEDED]: (state, { id, data }) => ({
        ...state,
        serviceId: id,
        message: data,
        submitting: false,
    }),
    [types.REMOVE_SHARED_SERVICE_FAILED]: (state, { error }) => ({
        ...state,
        submitting: false,
        error,
    }),

    // remove shared collected service
    [types.REQUEST_REMOVE_SHARED_COLLECTED_SERVICE]: (state) => ({
        ...state,
        submitting: true,
        error: null,
    }),
    [types.REMOVE_SHARED_COLLECTED_SERVICE_SUCCEEDED]: (state, { id, data }) => ({
        ...state,
        serviceId: id,
        message: data,
        submitting: false,
    }),
    [types.REMOVE_SHARED_COLLECTED_SERVICE_FAILED]: (state, { error }) => ({
        ...state,
        submitting: false,
        error,
    }),

    // clear share details
    [types.CLEAR_SHARED_B2B_SERVICE_DETAILS]: (state) => ({
        ...state,
        details: null,
    }),

    // clear share/remove message
    [types.CLEAR_SHARE_REMOVE_MESSAGE]: (state) => ({
        ...state,
        message: '',
    }),

    // clear b2b share error
    [types.CLEAR_B2B_SHARE_ERROR]: (state) => ({
        ...state,
        error: null,
    }),
});

export { types, actions };
