import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Row,
    Col,
    Space,
    Dropdown,
    Button,
    Avatar,
    Typography,
    // Progress,
    Divider,
} from 'antd';
import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { /* ArrowLeftIcon */ EyeIcon, MoreOutlinedIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/employees/delete/reducer';
import useNotification from '@/hooks/useNotification';

// const setColor = (points) => {
//     if (points < 16) {
//         return '#F67481';
//     } else if (points >= 16 && points < 31) {
//         return '#FFD36C';
//     } else {
//         return '#24DCAE';
//     }
// };

const PersonalDetails = ({
    data,
    currency,
    // showPerformance,
    // setPerformance,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const editUrl = `/app/employees/employees/${data.id}/edit`;

    const messageSuccess = useSelector((state) => state.employees.delete.messageSuccess);
    const error = useSelector((state) => state.employees.delete.error);

    const onEdit = useCallback(() => {
        push(editUrl);
    }, [push, editUrl]);

    useNotification({
        messageSuccess,
        error,
        item: data.full_name,
        clearAction: actions.clearDeleteEmployeeState(),
        mode: 'view',
        pushUrl: '/app/employees/employees',
    });

    return (
        <Row gutter={[0, 0]}>
            <Col xs={24} className="general-section">
                <Space>
                    <div>
                        <Space size={21}>
                            <Avatar
                                size={86}
                                src={data.image}
                            />
                            <div>
                                <Typography.Text className="personal-info_name">
                                    {data.full_name}
                                </Typography.Text>
                                <InlineDescription
                                    className="diff-color"
                                    label={t('Role')}
                                    content={data.groups.map((group) => group.name).join(', ')}
                                />
                                <InlineDescription
                                    className="diff-color"
                                    label={t('Since')}
                                    content={data.date_employed}
                                />
                                <InlineDescription
                                    className="diff-color"
                                    label={t('Salary')}
                                    content={`${currency}${data.salary_amount} / ${t('month')}`}
                                />
                            </div>
                        </Space>
                    </div>
                    <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        overlayClassName="edit-delete-dropdown"
                        menu={{
                            items: SimpleDropdownMenu({
                                editText: t('Edit this employee'),
                                deleteText: t('Delete this employee'),
                                onEditClick: onEdit,
                                deleteAction: actions.requestDeleteEmployee(data.id),
                            }),
                        }}
                    >
                        <Button type="text">
                            <MoreOutlinedIcon />
                        </Button>
                    </Dropdown>
                </Space>
            </Col>
            {/* <Col xs={24} className="progress-section">
                <Progress
                    percent={85} // TODO dynamic
                    size="small"
                    strokeColor={setColor(85)}
                    format={(percent) => `${t('Overall Performance')} ${percent} / 100 Points`}
                />
                <Button
                        onClick={() => setPerformance(!showPerformance)}
                        className={showPerformance ? 'close' : 'open'}
                    >
                    {showPerformance
                        ? (
                            <span>
                                <ArrowLeftIcon />
                                {' '}
                                {t('CLOSE')}
                            </span>
                        )
                        : t('View full story').toUpperCase()}
                </Button>
            </Col> */}
            <Col xs={24} className="details-section">
                <BlockDescription name={t('ID Number')}>
                    {data.id_number}
                </BlockDescription>

                <Divider />
            </Col>
            <Col xs={24} className="details-section">
                <BlockDescription name={t('Nationality')}>
                    {data.nationality}
                </BlockDescription>

                <Divider />
            </Col>
            <Col xs={24} className="details-section">
                <BlockDescription name={t('Gender')}>
                    {data.gender}
                </BlockDescription>

                <Divider />
            </Col>
            <Col xs={24} className="details-section">
                <BlockDescription name={t('Age')}>
                    {data.birthdate}
                </BlockDescription>

                <Divider />
            </Col>
            <Col xs={24} className="details-section">
                <BlockDescription name={t('Address')}>
                    {data.address}
                </BlockDescription>

                <Divider />
            </Col>
            <Col xs={24} className="details-section">
                <BlockDescription name={t('Phone Number')}>
                    {data.phone_number}
                </BlockDescription>

                <Divider />
            </Col>
            <Col xs={24} className="details-section">
                <BlockDescription name={t('Email')}>
                    {data.email}
                </BlockDescription>

                <Divider />
            </Col>
            <Col xs={24} className="details-section">
                <BlockDescription name={t('Password')}>
                    <Space>
                        <span>**********</span>
                        <EyeIcon />
                    </Space>
                </BlockDescription>
            </Col>
        </Row>
    );
};

export default PersonalDetails;
