import BaseService from './BaseService';

class UploadService extends BaseService {
    // this will be changed when the api is updated
    uploadFile = (file, extraConfig) => {
        const data = new FormData();
        data.append('tag', 'photo');
        data.append('file', file);

        return this.fileUploadApi.post(
            '/agency/uploadfile',
            data,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                ...extraConfig,
            },
        );
    }
}

export default new UploadService();
