import BaseService from '@/services/BaseService';

class InvoiceService extends BaseService {
    getAll = (filters) => this.post('/agency/invoiceslist', filters || { });

    getOne = (id) => this.get(`/agency/viewagencyinvoice/${id}`);

    payInvoice = (id, data) => this.post(`/agency/addpayment/${id}`, data);

    deleteOne = (id) => this.delete(`/agency/deleteinvoice/${id}`);

    // tour invoice
    submitTourInvoiceDetails = (id, data) => this.post(
        `/agency/maketourreservationfirst/${id}`,
        data,
    );

    submitTourInvoiceParticipants = (id, data) => this.post(
        `/agency/maketourreservationsecond/${id}`,
        data,
    );

    submitTourInvoiceExtras = (id, data) => this.post(
        `/agency/maketourreservationthird/${id}`,
        data,
    );

    // accommodation invoice
    submitHotelInvoiceDetails = (id, data) => this.post(
        `/agency/hotelreservationfirst/${id}`,
        data,
    );

    submitHotelInvoiceParticipants = (id, data) => this.post(
        `/agency/hotelreservationsecond/${id}`,
        data,
    );

    submitHotelInvoiceExtras = (id, data) => this.post(
        `/agency/hotelreservationthird/${id}`,
        data,
    );

    // activity invoice
    submitActivityInvoiceDetails = (id, data) => this.post(
        `/agency/makeactivityreservationfirst/${id}`,
        data,
    );

    submitActivityInvoiceParticipants = (id, data) => this.post(
        `/agency/makeactivityreservationsecond/${id}`,
        data,
    );

    submitActivityInvoiceExtras = (id, data) => this.post(
        `/agency/makeactivityreservationthird/${id}`,
        data,
    );

    // custom invoice
    submitInvoiceServices = (data) => this.post('/agency/addcustominvoice', data);

    submitInvoiceDetails = (data) => this.post('/agency/custominvoicefirststeep', data);

    submitInvoiceExtra = (id, data) => this.post(`/agency/custominvoicesecondsteep/${id}`, data);

    getExistingServices = (filters) => this.post('/agency/servicecustomlist', filters || { });

    getServiceDetails = (id) => this.get(`/agency/viewservicecustom/${id}`);

    // other services
    getCustomerList = (filters) => this.post('/agency/existingcustomers', filters || { });

    getExistingCustomer = (id) => this.get(`/agency/existingcustomerdetails/${id}`);

    getTourExtraServices = (id) => this.get(`/agency/gettourextraservices/${id}`);

    getActivityExtraServices = (id) => this.get(`/agency/getactivityextraservices/${id}`);

    getReservationData = (id, data) => this.post(`/agency/getinvoicedata/${id}`, data);

    getInvoiceGraphs = () => this.get('/agency/getinvoiceslistdashboard');
}

export default new InvoiceService();
