import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    Space,
} from 'antd';

import ViewOneHeader from '@/components/ViewOneHeader';
import PhotoGallery from '@/components/PhotoGallery';
import { CalendarIcon, ReservationIcon } from '@/assets/images/icons/screens';
import { selectors } from '@/redux/tour/activities/view/model';
import { actions } from '@/redux/tour/activities/view/reducer';
import { actions as invoiceActions } from '@/redux/invoice/create/reducer';
import ProductCollection from '@/components/ProductCollection';
import ViewWidgetCategories from '@/components/ViewWidgetCategories';
import { useCurrency } from '@/hooks/useCurrency';

import GeneralData from './GeneralData';
import ActivityLocation from './ActivitiyLocation';
import Calendar from './Calendar';

const ViewOne = ({ viewFromB2b = false }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { replace, push } = useHistory();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const loading = useSelector(selectors.loading);
    const activity = useSelector(selectors.activity);
    const { status: serviceStatus } = activity !== null && activity;
    // const { b2b_agency_name: b2bAgency } = activity !== null && activity;

    const myService = true;

    useEffect(() => {
        if (!viewFromB2b) {
            push(`/app/tours/activities/${id}`);
        } else {
            push(`/app/b2b-partners/worldwide-supplier/activity/${id}`);
        }
    }, [push, id, viewFromB2b]);

    useEffect(() => {
        dispatch(actions.requestViewTourActivity(id));
    }, [dispatch, id]);

    const [selectedDate, setSelectedDate] = useState();
    const [timeCheckedId, setTimeCheckedId] = useState();
    const [timeChecked, setTimeChecked] = useState(null);
    const [personNo, setPersonNo] = useState(0);

    const bookNow = () => {
        const reservationData = {
            date: selectedDate,
            days: [
                {
                    days_list: parseInt(timeCheckedId, 10),
                    persons_no: personNo,
                    time: timeChecked,
                },
            ],
            tour_id: id,
            reservation_type: 'Activity',
            currency_icon: currencyIcon,
        };
        dispatch(invoiceActions.clearInvoiceState());
        dispatch(invoiceActions.prepareReservationDataForSubmit(reservationData));
        dispatch(invoiceActions.requestGetReservationData(
            id,
            {
                type: 4,
                date: selectedDate,
                days: [
                    {
                        days_list: parseInt(timeCheckedId, 10),
                        persons_no: personNo,
                        time: timeChecked,
                    },
                ],
            },
        ));
    };

    return (activity === null || loading) ? 'loading' : (
        <div id="view-tour-activity-page">
            {!myService
            && (serviceStatus === null || serviceStatus === '2')
            && (
                <ProductCollection
                    data={activity}
                    serviceStatus={serviceStatus}
                    serviceId={id}
                />
            )}
            <ViewOneHeader
                goBackTitle={myService ? 'View all Activities' : 'Go back'}
                goBackUrl={myService ? '/app/tours/activities' : '/app/b2b-partners/worldwide-supplier'}
                title={activity.name}
            />
            <Row
                gutter={[30, 30]}
                className="view-tour-activity-body"
            >
                <Col
                    lg={{ span: 14, order: 1 }}
                    xs={{ span: 24, order: 1 }}
                >
                    <div className="left-col">
                        <GeneralData
                            data={activity}
                            myService={myService}
                        />

                        <ViewWidgetCategories data={activity.widget_categories} />

                        <ActivityLocation
                            lat={activity?.latitude}
                            lng={activity?.longitude}
                        />
                    </div>

                    <div className="left-col-photo-gallery">
                        <PhotoGallery data={activity.images} />
                    </div>
                </Col>

                <Col
                    lg={{ span: 10, order: 2 }}
                    xs={{ span: 24, order: 2 }}
                >
                    <div className="right-col-container">
                        <Space size={11} className="header-text">
                            <CalendarIcon />
                            <span className="reservation-text">{t('Make a Reservation to this Activity')}</span>
                        </Space>
                        <div className="right-col">
                            <Calendar
                                data={activity}
                                setSelectedDate={setSelectedDate}
                                timeChecked={timeChecked}
                                setTimeChecked={setTimeChecked}
                                timeCheckedId={timeCheckedId}
                                setTimeCheckedId={setTimeCheckedId}
                                personNo={personNo}
                                setPersonNo={setPersonNo}
                            />
                        </div>
                        <div className="info-text">
                            {t('You can select more than one times above, depended by dates')}
                        </div>

                        <Button
                            block
                            disabled={timeChecked === null || personNo === 0}
                            className="button btn-orange"
                            onClick={() => {
                                replace('/app/invoices/new');
                                bookNow();
                            }}
                        >
                            <ReservationIcon />
                            {t('Add new reservation for this activity').toUpperCase()}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ViewOne;
