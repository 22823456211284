import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Progress, Typography } from 'antd';
import BlockDescription from '@/components/Information/WithImage/Block';
import { EllipseIcon } from '@/assets/images/icons/screens';

const ProgressContent = ({ currency }) => {
    const { t } = useTranslation();

    const data = useSelector((state) => state.dashboard.total);
    const {
        earning: earningValue,
        cost: costValue,
        total: totalValue,
    } = data !== null && data;

    const earningPercent = (earningValue / totalValue) * 100;

    const now = new Date();

    return (
        <>
            <Progress
                type="circle"
                percent={earningPercent}
                format={() => {
                    return (
                        <div>
                            <div>{`${currency} ${totalValue}`}</div>
                            <div>{t('Total Incoming')}</div>
                        </div>
                    );
                }}
                strokeColor="white"
                trailColor="#758CA1"
            />

            <BlockDescription
                icon={<EllipseIcon />}
                label={`${t('Costs since Jan')} 01, ${now.getFullYear()}`}
                content={`${currency}${costValue}`}
            />

            <BlockDescription
                icon={<EllipseIcon />}
                label={`${t('Earning since Jan')} 01, ${now.getFullYear()}`}
                content={`${currency}${earningValue}`}
            />

            <Divider />

            <div className="description">
                <Typography.Text>
                    {`${t('This is a revenue reports for the last year starting from January up to current time')}.`}
                    {`${t('You can view full reports in the reports menu')}.`}
                </Typography.Text>
            </div>
        </>
    );
};

export default ProgressContent;
