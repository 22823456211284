import { combineReducers } from 'redux';

import dashboard, {
    types as dashboardTypes,
    actions as dashboardActions,
} from './dashboard/reducer';

import hotel, {
    types as hotelTypes,
    actions as hotelActions,
} from './hotel/reducer';

const actions = {
    ...dashboardActions,
    ...hotelActions,
};

const types = {
    ...dashboardTypes,
    ...hotelTypes,
};

export { types, actions };

const tourReducer = combineReducers({
    dashboard,
    hotel,
});

export default tourReducer;
