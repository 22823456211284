import BaseService from '@/services/BaseService';

class AccommodationService extends BaseService {
    getAll = (filters) => this.post('/agency/agencyhotellist', filters || { });

    getOne = (id) => this.get(`/agency/hotelview/${id}`);

    getReservationList = (id, filters) => this.post(
        `/agency/getavailablerooms/${id}`,
        filters,
    );

    deleteOne = (id) => this.delete(`/agency/deletehotel/${id}`);

    // accommodation details
    addAccommodationDetails = (data) => this.post(
        '/agency/addhotelgeneralinfo',
        data,
    );

    editAccommodationDetails = (id, data) => this.post(
        `/agency/edithotelgeneralinfo/${id}`,
        data,
    );

    viewAccommodationDetails = (id) => this.get(`/agency/viewhotelgeneralinfo/${id}`);

    // accommodation facilities
    addFacilities = (id, data) => this.post(
        `/agency/addhotelfacilities/${id}`,
        data,
    );

    editFacilities = (id, data) => this.post(
        `/agency/edithotelfacilities/${id}`,
        data,
    );

    viewFacilities = (id) => this.get(`/agency/viewhotelfacilities/${id}`);

    // accommodation room
    addRoom = (id, data) => this.post(
        `/agency/addhotelroomtype/${id}`,
        data,
    );

    editRoom = (id, data) => this.post(
        `/agency/edithotelroomtype/${id}`,
        data,
    );

    viewRoom = (id) => this.get(`/agency/viewroomtype/${id}`);

    deleteRoom = (id) => this.delete(`/agency/deleteroomtype/${id}`);

    getRoomList = (id) => this.get(`/agency/roomtypelist/${id}`);

    // accommodation season
    addSeason = (id, data) => this.post(
        `/agency/addhotelseasonalprice/${id}`,
        data,
    );

    editSeason = (id, data) => this.post(
        `/agency/edithotelseasonalprice/${id}`,
        data,
    );

    viewSeason = (id) => this.get(`/agency/viewhotelseasonalprice/${id}`);

    deleteSeason = (id) => this.delete(`/agency/deletehotelseasonalprice/${id}`);

    getSeasonList = (id) => this.get(`/agency/viewhotelseasonalpriceslist/${id}`);

    // accommodation offer
    addAccommodationOffer = (data) => this.post('/agency/newoffer', data);

    editAccommodationOffer = (id, data) => this.post(`/agency/editoffer/${id}`, data);

    getAccommodationOffers = (id) => this.get(`/agency/hotelofferslist/${id}`);

    viewAccommodationOffer = (id) => this.get(`/agency/viewoffer/${id}`);

    deleteAccommodationOffer = (id) => this.delete(`/agency/deleteoffer/${id}`);

    // other services
    getFacilities = () => this.get('/agency/getfacilities');

    addNewFacility = (data) => this.post('/agency/newhotelfacility', data);

    getPaymentOptions = () => this.get('/agency/getpaymentoptions');

    addNewPaymentOption = (accommodationType, data) => this.post(
        `/agency/newhotelpaymentmethod/${accommodationType}`,
        data,
    );

    getAmenities = (data) => this.post('/agency/searchroomtypeamenities', data || { });

    addNewAmenity = (id, data) => this.post(`/agency/newhotelroomtypeamenity/${id}`, data);

    getRoomTypeList = (id) => this.get(`/agency/getroomtypelist/${id}`);
}

export default new AccommodationService();
