import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import { selectors } from '@/redux/invoice/create/model';

import Extras from './Extras';
import General from './General';
import Participants from './Participants';
import Total from './Total';
import TourTrip from './Reservation/TourTrip';
import TourPackage from './Reservation/TourPackage';
import TourCruise from './Reservation/TourCruise';
import Activity from './Reservation/Activity';
import Accommodation from './Reservation/Accommodation';
import Rooms from './Rooms';
import ExtraBed from './ExtraBed';

const Preview = ({
    invoiceDetails,
    invoiceCustomer,
    invoiceParticipants,
    invoiceExtras,
}) => {
    // data received from create state
    const reservationDataFromCreate = useSelector(selectors.reservationData);

    // data received from view state
    const reservationDataFromView = (invoiceDetails !== null
    && invoiceDetails.tour_trip_general_data)
    || (invoiceDetails !== null && invoiceDetails.tour_package_general_data)
    || (invoiceDetails !== null && invoiceDetails.tour_cruise_general_data)
    || (invoiceDetails !== null && invoiceDetails.hotel_general_data)
    || (invoiceDetails !== null && invoiceDetails.activity_general_data);

    // final data
    const reservationData = reservationDataFromCreate || reservationDataFromView;
    const {
        invoice_data: invoiceCharge,
        agency_data: agencyData,
    } = invoiceDetails !== null && invoiceDetails;
    const participants = invoiceParticipants.clients;
    const price = reservationDataFromCreate !== null && reservationDataFromCreate.price;

    // define currency
    const currencyFromView = invoiceCharge?.currency_icon;
    const currencyFromCreate = reservationDataFromCreate?.currency_icon;

    const currencyIcon = currencyFromCreate || currencyFromView;

    // define reservation type
    const reservationType = reservationDataFromCreate?.reservation_type
    || (invoiceDetails !== null && invoiceDetails.invoice_data.reservation_type);

    const isTourTrip = reservationType === 'Tour Trip';
    const isTourPackage = reservationType === 'Tour Package';
    const isTourCruise = reservationType === 'Tour Cruise';
    const isAccommodation = reservationType === 'Hotel';
    const isActivity = reservationType === 'Activity';

    // define rooms
    const rooms = reservationData?.rooms || reservationData?.data;

    const [hasExtraBed, setHasExtraBed] = useState(false);

    useEffect(() => {
        for (let i = 0; i < rooms?.length; i++) {
            if (rooms[i].extra_beds !== 0) {
                setHasExtraBed(true);
                break;
            }
        }
    }, [rooms]);

    return (
        <div className="preview">
            <General
                agencyData={agencyData !== undefined ? agencyData : reservationData}
                invoiceCustomer={invoiceCustomer}
            />

            <Divider />

            {isTourTrip && (
                <TourTrip reservationData={reservationData} />
            )}
            {isTourPackage && (
                <TourPackage reservationData={reservationData} />
            )}
            {isTourCruise && (
                <TourCruise reservationData={reservationData} />
            )}
            {isAccommodation && (
                <Accommodation reservationData={reservationData} />
            )}
            {isActivity && (
                <Activity reservationData={reservationData} />
            )}

            <div className="invoice-divider"><Divider /></div>

            {isAccommodation ? (
                <>
                    <Rooms
                        reservationData={reservationData}
                        invoiceCharge={invoiceCharge}
                        currency={currencyIcon}
                    />
                    {hasExtraBed && (
                        <div className="invoice-divider"><Divider /></div>
                    )}
                </>
            ) : (
                <>
                    <Participants
                        invoiceParticipants={participants}
                        currency={currencyIcon}
                        invoiceCharge={invoiceCharge}
                    />
                    {invoiceExtras?.length !== 0 && (
                        <div className="invoice-divider"><Divider /></div>
                    )}
                </>
            )}

            {isAccommodation ? (
                <ExtraBed
                    rooms={rooms}
                    invoiceCharge={invoiceCharge}
                    currency={currencyIcon}
                    hasExtraBed={hasExtraBed}
                />
            ) : (
                <Extras
                    invoiceExtras={invoiceExtras}
                    currency={currencyIcon}
                    invoiceCharge={invoiceCharge}
                />
            )}
            <div className="invoice-divider"><Divider /></div>
            <Total
                invoiceCharge={invoiceCharge}
                currency={currencyIcon}
                price={price}
            />
        </div>
    );
};

export default Preview;
