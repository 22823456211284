import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({

    // go to step
    goToCreateFlightStep: ['step'],

    // fetch for edit
    requestFetchFlightData: ['id'],
    fetchFlightDataSucceeded: ['data'],
    fetchFlightDataFailed: ['errors'],

    // save flight data to submit
    saveFlightDataToSubmit: ['data', 'createStep'],

    // submit flight data
    requestSubmitFlight: ['data'],
    submitFlightSucceeded: ['data'],
    submitFlightFailed: ['errors'],

    // get airport list
    requestGetAirportList: ['data'],
    getAirportListSucceeded: ['data'],
    getAirportListFailed: ['errors'],

    // add airport
    requestAddNewAirport: ['data'],
    addNewAirportSucceeded: ['data'],
    addNewAirportFailed: ['errors'],

    // get airline list
    requestGetAirlineList: ['data'],
    getAirlineListSucceeded: ['data'],
    getAirlineListFailed: ['errors'],

    // add airline
    requestAddNewAirline: ['data'],
    addNewAirlineSucceeded: ['data'],
    addNewAirlineFailed: ['errors'],

    // clear state
    clearFlightState: null,
});

export default {

    // go to step
    [types.GO_TO_CREATE_FLIGHT_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_FLIGHT_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_FLIGHT_DATA_SUCCEEDED]: (
        state,
        {
            data,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            ticketData: data,
        },
    }),
    [types.FETCH_FLIGHT_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // save flight data to submit
    [types.SAVE_FLIGHT_DATA_TO_SUBMIT]: (state, { data, createStep }) => ({
        ...state,
        create: {
            ...state.create,
            ticketData: { ...state.create.ticketData, ...data },
            step: createStep,
        },
    }),

    // submit flight data
    [types.REQUEST_SUBMIT_FLIGHT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),

    [types.SUBMIT_FLIGHT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            ticketData: data,
            submitting: false,
            errors: null,
        },
    }),
    [types.SUBMIT_FLIGHT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // get airport list
    [types.REQUEST_GET_AIRPORT_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),

    [types.GET_AIRPORT_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            airportList: data,
            loading: false,
            errors: null,
        },
    }),
    [types.GET_AIRPORT_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // add airport
    [types.REQUEST_ADD_NEW_AIRPORT]: (state) => ({
        ...state,
        create: {
            ...state.create,
            errors: null,
        },
    }),

    [types.ADD_NEW_AIRPORT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            airportList: [...state.create.airportList, data],
            errors: null,
        },
    }),
    [types.ADD_NEW_AIRPORT_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
        },
    }),

    // get airline list
    [types.REQUEST_GET_AIRLINE_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),

    [types.GET_AIRLINE_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            airlineList: data,
            loading: false,
            errors: null,
        },
    }),
    [types.GET_AIRLINE_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // add airline
    [types.REQUEST_ADD_NEW_AIRLINE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            errors: null,
        },
    }),

    [types.ADD_NEW_AIRLINE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            airlineList: [...state.create.airlineList, data],
            errors: null,
        },
    }),
    [types.ADD_NEW_AIRLINE_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_FLIGHT_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            ticketData: null,
        },
    }),
};
