import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Typography,
    Checkbox,
    Space,
} from 'antd';
import clsx from 'clsx';
import { InfoCircleIcon } from '@/assets/images/icons/screens';
import SearchBox from '../SearchBox';

const optionData = [
    {
        id: 0,
        mainText: 'Put all services into one search box',
        searchBox: <SearchBox checkedId={0} />,
    },
    {
        id: 1,
        mainText: 'Split the services into separated search boxes',
        searchBox: <SearchBox checkedId={1} />,
    },
];

const SearchWidgetType = ({
    checkedId,
    setCheckedId,
}) => {
    const { t } = useTranslation();

    return (
        <Row
            className="widget-options"
            gutter={[20, 0]}
        >
            {optionData.map((option) => (
                <Col span={12} key={option.id}>
                    <Row
                        gutter={[0, 29]}
                        className={clsx('item', checkedId === option.id && 'selected')}
                    >
                        <Col span={16}>
                            <Typography.Text>
                                {t(option.mainText)}
                            </Typography.Text>
                        </Col>
                        <Col span={8}>
                            <Checkbox
                                checked={checkedId === option.id}
                                onChange={() => {
                                    setCheckedId(option.id);
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            {option.searchBox}
                        </Col>
                        {option.id === 0 && (
                            <Col span={24} className="info-text">
                                <Space size={6}>
                                    <InfoCircleIcon />
                                    <span>
                                        {t("This option will generate more than one search boxes based on the services you'll select")}
                                    </span>
                                </Space>
                            </Col>
                        )}
                    </Row>
                </Col>
            ))}
        </Row>
    );
};

export default SearchWidgetType;
