import {
    takeEvery,
    call,
    all,
    put,
    fork,
    select,
} from 'redux-saga/effects';
import MyPartnerService from '@/services/B2BPartnerServices/MyPartnerService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';
import { selectors } from './model';

function* submitAgencyAccount({ data }) {
    try {
        const response = yield call(MyPartnerService.addAgencyAccount, data);
        yield put(actions.submitAgencyAccountSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitAgencyAccountFailed(extractError(error)));
    }
}

function* submitAgencyInfo({ data }) {
    try {
        const { id: agencyId } = yield select(selectors.agencyAccount);
        const response = yield call(MyPartnerService.addAgencyInfo, agencyId, data);
        yield put(actions.submitAgencyInfoSucceeded(response.data));
    } catch (error) {
        yield put(actions.submitAgencyInfoFailed(extractError(error)));
    }
}

function* submitAgencyDistribution({ data }) {
    try {
        const { id: agencyId } = data;
        const response = yield call(MyPartnerService.addAgencyDistribution, agencyId, data);
        yield put(actions.submitAgencyDistributionSucceeded(response.message, response.data));
    } catch (error) {
        yield put(actions.submitAgencyDistributionFailed(extractError(error)));
    }
}

function* getAllAgencyServices() {
    try {
        const response = yield call(MyPartnerService.getAllAgencyServices);
        yield put(actions.getAllAgencyServicesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getAllAgencyServicesFailed(extractError(error)));
    }
}

function* getCheckedServices({ id }) {
    try {
        const response = yield call(MyPartnerService.getCheckedServices, id);
        const {
            agency_name: agencyName,
            agency_services: agencyServices,
        } = response.data;
        yield put(actions.getCheckedServicesSucceeded(agencyName, agencyServices));
    } catch (error) {
        yield put(actions.getCheckedServicesFailed(extractError(error)));
    }
}

function* getAgencyList({ filters }) {
    try {
        const response = yield call(MyPartnerService.getAgencyList(filters));
        yield put(actions.getAgencyListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getAgencyListFailed(extractError(error)));
    }
}

function* watchSubmitAgencyAccount() {
    yield takeEvery(
        types.REQUEST_SUBMIT_AGENCY_ACCOUNT,
        submitAgencyAccount,
    );
}

function* watchSubmitAgencyInfo() {
    yield takeEvery(
        types.REQUEST_SUBMIT_AGENCY_INFO,
        submitAgencyInfo,
    );
}

function* watchSubmitAgencyDistribution() {
    yield takeEvery(
        types.REQUEST_SUBMIT_AGENCY_DISTRIBUTION,
        submitAgencyDistribution,
    );
}

function* watchGetAllAgencyServices() {
    yield takeEvery(
        types.REQUEST_GET_ALL_AGENCY_SERVICES,
        getAllAgencyServices,
    );
}

function* watchGetCheckedServices() {
    yield takeEvery(
        types.REQUEST_GET_CHECKED_SERVICES,
        getCheckedServices,
    );
}

function* watchGetAgencyList() {
    yield takeEvery(
        types.REQUEST_GET_AGENCY_LIST,
        getAgencyList,
    );
}

function* createPartnerSaga() {
    yield all([
        fork(watchSubmitAgencyAccount),
        fork(watchSubmitAgencyInfo),
        fork(watchSubmitAgencyDistribution),
        fork(watchGetAllAgencyServices),
        fork(watchGetCheckedServices),
        fork(watchGetAgencyList),
    ]);
}

export default createPartnerSaga;
