import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Button,
    Divider,
    Table,
    Space,
    Popconfirm,
    Modal,
    Form,
} from 'antd';
import { PlusIcon, DeleteIcon, EditIcon } from '@/assets/images/icons/screens';
import ActionIcon from '@/components/ActionIcon';
import { actions } from '@/redux/settings/taxes/reducer';
import useNotification from '@/hooks/useNotification';

import AddNewTax from './AddNewTax';

const Taxes = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [search, setSearch] = useState('');
    const [taxName, setTaxName] = useState('');

    const data = useSelector((state) => state.settings.taxes.data);
    const messageSuccess = useSelector((state) => state.settings.taxes.delete.messageSuccess);
    const error = useSelector((state) => state.settings.taxes.delete.error);

    const deleteTax = useCallback((id) => {
        dispatch(actions.requestDeleteTax(id));
    }, [dispatch]);

    useNotification({
        messageSuccess,
        error,
        item: taxName,
        clearAction: actions.clearDeleteTaxState(),
        mode: 'list',
    });

    useEffect(() => {
        if (search) {
            dispatch(actions.requestFetchTaxList({
                name: search,
            }));
        } else {
            dispatch(actions.requestFetchTaxList());
        }
    }, [dispatch, search]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Tax Name').toUpperCase(),
            dataIndex: 'tax_name',
            sorter: true,
            width: '30%',
        },
        {
            title: t('Tax Rate').toUpperCase(),
            dataIndex: 'tax_rate',
            sorter: true,
            width: '50%',
        },
        {
            title: '',
            dataIndex: 'actions',
            width: '50%',
            render: (text, record) => (
                <Space className="a-btns-m1" size="large">
                    <ActionIcon onClick={() => {
                        dispatch(actions.requestFetchTaxDetails(record.id));
                        setIsEdit(true);
                        setIsOpen(true);
                    }}
                    >
                        <EditIcon />
                    </ActionIcon>
                    <Popconfirm
                        title={`${t('Are you sure to delete this tax')} ?`}
                        onConfirm={() => {
                            deleteTax(record.id);
                            setTaxName(record.tax_name);
                        }}
                    >
                        <ActionIcon>
                            <DeleteIcon />
                        </ActionIcon>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const datasource = data.map((row, i) => ({
        key: i,
        id: row.id,
        tax_name: row.name,
        tax_rate: row.commission_type === 'Percentage' ? `${row.rate}%` : `${row.rate}`,
        // tax_rule: row._rule,
        // tax_type: row.tax_type,
    }));

    return (
        <div className="taxes">
            <Row justify="space-between" className="filters">
                <Col>
                    <Input.Search
                        placeholder={t('Search')}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Col>
                <Col>
                    <Button
                        className="add-btn"
                        onClick={() => setIsOpen(true)}
                    >
                        <PlusIcon />
                        {t('Add new tax').toUpperCase()}
                    </Button>
                    <Modal
                        open={isOpen}
                        footer={null}
                        onCancel={() => {
                            setIsOpen(false);
                            form.resetFields();
                        }}
                        maskClosable={true}
                        centered
                        width={700}
                        className="add-tax-form"
                    >
                        <AddNewTax
                            form={form}
                            isEdit={isEdit}
                            setIsOpen={setIsOpen}
                        />
                    </Modal>
                </Col>
            </Row>
            <Divider />
            <Table
                rowSelection={{}}
                columns={columns}
                dataSource={datasource}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
            />
        </div>
    );
};

export default Taxes;
