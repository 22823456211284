import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
    Col,
    Form,
    Slider,
    Button,
    Row,
    notification,
    Space,
} from 'antd';
import DashedContainer from '@/components/DashedContainer';
import { PlusIcon } from '@/assets/images/icons/screens';
import { timeFormat } from '@/helpers/dateFormat';
import { TimeInMinHours } from '@/helpers/timeFormat';
import { selectors } from '@/redux/tour/activities/create/model';
import { actions } from '@/redux/tour/activities/create/reducer';
import TimeItem from './TimeItem';

const formatTimesForSubmit = (activity) => {
    const activityTimes = activity.times ? activity.times : 1;
    const timesList = [];
    for (let i = 0; i < activityTimes; i++) {
        timesList.push({
            time: activity['startTime'.concat(i)]?.format(timeFormat),
            price: activity['defaultPrice'.concat(i)] === true ? 0 : activity['price'.concat(i)],
        });
    }
    return {
        days: activity.days,
        times: timesList,
    };
};

const formatActivitiesForSubmit = (activities) => activities.map((activity) => (
    formatTimesForSubmit(activity)
));

const DurationInMinutes = ({
    form,
    isEdit,
    regStep,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const generalInfo = useSelector(selectors.generalInfo);
    const timeAndDuration = useSelector(selectors.timeAndDuration);

    const [duration, setDuration] = useState([20, 60]);

    useEffect(() => {
        if (timeAndDuration && timeAndDuration.duration_type === 'Hours') {
            setDuration([timeAndDuration.duration_from, timeAndDuration.duration_to]);
        }
    }, [timeAndDuration]);

    const onFinish = useCallback((values) => {
        const data = {
            duration_from: values.durationInMinutes[0],
            duration_to: values.durationInMinutes[1],
            duration_type: 1,
            when: values.activityTimeMinutes
                ? formatActivitiesForSubmit(values.activityTimeMinutes)
                : [],
        };
        if (isEdit && regStep > 2) {
            const step = 2;
            const completedStep = 2;
            dispatch(actions.requestSubmitActivityTimeDuration(
                data,
                step,
                completedStep,
            ));
        } else {
            const step = 2;
            const completedStep = 1;
            dispatch(actions.requestSubmitActivityTimeDuration(
                data,
                step,
                completedStep,
            ));
        }
    }, [dispatch]);

    useEffect(() => {
        let initialData = {
            durationInMinutes: [20, 60],
            activityTimeMinutes: [{}],
        };
        if (timeAndDuration && timeAndDuration.duration_type === 'Hours') {
            const formatTimes = (item) => {
                const activityTimes = item.times.length;
                const timePriceObject = {};
                for (let i = 0; i < activityTimes; i++) {
                    timePriceObject['startTime'.concat(i)] = moment(item.times[i].time, 'HH:mm:ss');
                    timePriceObject['price'.concat(i)] = item.times[i].price;
                    timePriceObject['defaultPrice'.concat(i)] = item.times[i].price === generalInfo.price;
                }
                timePriceObject.days = [item.id];
                timePriceObject.times = item.times.length;
                return timePriceObject;
            };

            const formatActivityTimeMinutes = (time) => time.map((item) => formatTimes(item));

            initialData = {
                durationInMinutes: [
                    timeAndDuration.duration_from,
                    timeAndDuration.duration_to,
                ],
                activityTimeMinutes: timeAndDuration.time
                    ? formatActivityTimeMinutes(timeAndDuration.time)
                    : [],
            };
        }
        form.setFieldsValue(initialData);
    }, [timeAndDuration, generalInfo, form]);

    const formFields = form.getFieldsValue();

    const errors = useSelector(selectors.errors);

    useEffect(() => {
        if (errors && typeof errors === 'object') {
            notification.error({
                description: <Space direction="vertical">{Object.values(errors)}</Space>,
                duration: 0,
            });
        } else if (errors && typeof errors === 'string') {
            notification.error({
                description: errors,
                duration: 0,
            });
        }
        dispatch(actions.clearActivityTimeErrors());
    }, [errors]);

    return (
        <Form
            form={form}
            id="minutes-duration-form"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
        >
            <div className="duration-content">
                <div className="duration-start">
                    <span>
                        <TimeInMinHours value={duration[0]} />
                    </span>
                </div>
                <div className="seperator" />
                <div className="duration-end">
                    <span>
                        <TimeInMinHours value={duration[1]} />
                    </span>
                </div>
            </div>

            <Form.Item name="durationInMinutes">
                <Slider
                    className="trip-slider"
                    range
                    min={10}
                    max={1380}
                    step={10}
                    tooltip={{
                        open: false,
                    }}
                    onChange={setDuration}
                />
            </Form.Item>

            <Form.Item>
                <Form.List name="activityTimeMinutes">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <DashedContainer
                                    key={field.key}
                                    title={t('When')}
                                >
                                    <TimeItem
                                        field={field}
                                        remove={remove}
                                        initialDays={
                                            formFields.activityTimeMinutes?.[field.key]?.days
                                        }
                                        formListName="activityTimeMinutes"
                                    />
                                </DashedContainer>
                            ))}

                            <Row justify="end" className="add-button-row">
                                <Col>
                                    <Form.Item>
                                        <Button
                                            className="button add-button-outlined"
                                            onClick={() => add()}
                                        >
                                            <PlusIcon />
                                            {t('add new timeslot').toUpperCase()}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </Form>
    );
};

export default DurationInMinutes;
