import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    firstName: {
        name: 'First Name',
        type: 'string',
        min: 3,
        max: 30,
        required: true,
    },
    lastName: {
        name: 'Last Name',
        type: 'string',
        min: 3,
        max: 30,
        required: true,
    },
    email: {
        name: 'Email',
        type: 'email',
        min: 2,
        max: 150,
        required: true,
    },
    prefix: {
        name: 'Prefix',
        required: true,
    },
    phone: {
        name: 'Phone number',
        type: 'string',
        min: 8,
        max: 12,
        required: true,
    },
    password: {
        name: 'Password',
        type: 'string',
        min: 6,
        max: 40,
        required: true,
    },
    confirmPassword: {
        name: 'Confirm Password',
        type: 'string',
        min: 6,
        max: 40,
        required: true,
    },
    termsOfService: {
        name: 'Terms of service',
        required: true,
        type: {
            type: 'enum',
            enum: [true],
            message: 'Terms of service has to be accepted',
        },
    },
    name: {
        name: 'Company Name',
        type: 'string',
        min: 3,
        max: 30,
        required: true,
    },
    vat_number: {
        name: 'Register Number (VAT)',
        type: 'string',
        min: 3,
        max: 30,
        required: true,
    },
    companySize: {
        name: 'Company Size',
        required: true,
    },
    website: {
        name: 'Website',
        type: 'string',
        min: 8,
        max: 50,
        required: true,
    },
    address: {
        name: 'Street Address',
        type: 'string',
        min: 3,
        max: 50,
        required: true,
    },
    city: {
        name: 'City',
        type: 'string',
        min: 3,
        max: 20,
        required: true,
    },
    /*
    zip_code: {
        name: 'Zip / Postal Code',
        type: 'number',
        required: true,
    },
    */
    state: {
        name: 'State/Province',
        type: 'string',
        min: 3,
        max: 20,
        required: true,
    },
    country: {
        name: 'Country',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
