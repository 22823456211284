import {
    Col,
    Divider,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SpacedDetails = ({ name, value }) => {
    return (
        <Row justify="space-between">
            <Col>
                {name}
            </Col>

            <Col>
                {value}
            </Col>
        </Row>
    );
};

const Total = ({ invoiceCharge, currency, price }) => {
    const { t } = useTranslation();

    const chargeType = invoiceCharge?.discount_type === 'Percentage' ? '%' : currency;
    const finalPrice = price?.total || price;
    const subtotal = invoiceCharge?.subtotal || finalPrice;
    const total = invoiceCharge?.total || finalPrice;

    return (
        <Row className="total">
            <Col xs={{ span: 14, push: 10 }}>
                <SpacedDetails
                    name={t('Subtotal')}
                    value={subtotal ? `${currency}${subtotal}` : `${currency}0.00`}
                />

                <SpacedDetails
                    name={t('Discount')}
                    value={invoiceCharge ? `${chargeType}${invoiceCharge.discount}` : `${currency}0.00`}
                />

                <SpacedDetails
                    name={t('Tax / VAT')}
                    value={invoiceCharge ? `${currency}${invoiceCharge.tax_invoice}` : `${currency}0.00`}
                />

                <Divider />

                <SpacedDetails
                    name={(
                        <Typography.Title level={4} className="invoice-total">
                            {t('Total')}
                        </Typography.Title>
                    )}
                    value={(
                        <Typography.Title level={4} className="invoice-total">
                            {total ? `${currency}${total}` : `${currency}0.00`}
                        </Typography.Title>
                    )}
                />
            </Col>
        </Row>
    );
};

export default Total;
