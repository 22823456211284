import { useSelector } from 'react-redux';
import { Row, Col, Card } from 'antd';
import { selectors } from '@/redux/tour/trip/view/model';

import TripItenerary from './TripItenerary';
import TripMapItenerary from './TripMapItenerary';

const TourItenerary = () => {
    const itenerary = useSelector(selectors.itenerary);

    return (
        <Row>
            <Col lg={14} xs={24} className="map-col">
                <Card bordered={false} className="left-col">
                    <TripMapItenerary data={itenerary} />
                </Card>
            </Col>

            <Col lg={10} xs={24}>
                <TripItenerary data={itenerary} />
            </Col>
        </Row>
    );
};

export default TourItenerary;
