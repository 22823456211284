import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import DataContainer from '@/components/DataContainer';

const Partner = ({ data }) => {
    const { t } = useTranslation();

    return (
        (data && data.partner !== null) && (
            <DataContainer title={t('Partner')}>
                <Space direction="vertical">
                    <BlockDescription
                        name={t('Company Name')}
                    >
                        {data.partner.company_name}
                    </BlockDescription>

                    <BlockDescription
                        name={t('Registration Number')}
                    >
                        {data.partner.vat_number}
                    </BlockDescription>

                    <BlockDescription
                        name={t('Phone')}
                    >
                        {data.partner.phone_number}
                    </BlockDescription>

                    <BlockDescription
                        name={t('Email')}
                    >
                        {data.partner.email}
                    </BlockDescription>

                    <BlockDescription
                        name={t('Address')}
                    >
                        {data.partner.address}
                    </BlockDescription>

                    <BlockDescription
                        name={t('Website')}
                    >
                        {data.partner.website}
                    </BlockDescription>
                </Space>
            </DataContainer>
        )
    );
};

export default Partner;
