import BaseService from '@/services/BaseService';

class DashboardService extends BaseService {
    getSalesOverview = () => this.get('/agency/getsalesoverview');

    getSalesByLocation = () => this.get('/agency/getsalesbylocation');

    getOrders = () => this.get('/agency/getinvoicestatusdashboards');

    getB2bServices = () => this.get('/agency/servicelistrandom');
}

export default new DashboardService();
