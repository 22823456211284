import { useTranslation } from 'react-i18next';

import { Row, Col, Button } from 'antd';
import { NoGroupIcon } from '@/assets/images/icons/screens';

const EmptyGroupList = ({ setIsOpen }) => {
    const { t } = useTranslation();

    return (
        <Row
            className="empty-group-list"
            gutter={[0, 30]}
        >
            <Col xs={24}>
                <NoGroupIcon />
            </Col>

            <Col xs={24}>
                <span className="text">
                    {t("Oops! Looks you haven't created yet any Group")}
                </span>
            </Col>

            <Col xs={24}>
                <Button
                    className="create-button-filled"
                    onClick={() => setIsOpen(true)}
                >
                    {t('Add new group').toUpperCase()}
                </Button>
            </Col>
        </Row>
    );
};

export default EmptyGroupList;
