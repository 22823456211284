import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Modal,
    Avatar,
    Divider,
    Space,
    Typography,
} from 'antd';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { selectors } from '@/redux/b2b/partner/view/model';

const ViewVendor = ({ modalVisible, setModalVisible }) => {
    const { t } = useTranslation();

    const details = useSelector(selectors.partnerDetails);
    const { general_data: generalDetails } = details !== null && details;

    return (
        <Modal
            open={modalVisible}
            footer={null}
            onCancel={() => setModalVisible(false)}
            maskClosable={true}
            closable={false}
            centered
            width={600}
            className="view-vendor"
        >
            <div className="agency-details">
                <div className="general-data">
                    <Space size={20}>
                        <Avatar size={88} src={generalDetails?.icon} />
                        <div className="contact-details">
                            <Typography.Text className="agency-name">
                                {generalDetails?.name}
                            </Typography.Text>
                            <InlineDescription
                                label={t('Website')}
                                content={generalDetails?.website}
                            />
                            <InlineDescription
                                label={t('Phone')}
                                content={generalDetails?.phone}
                            />
                        </div>
                    </Space>
                </div>
                <div className="more-details">
                    <BlockDescription name={t('B2B Role')}>
                        <Typography.Text>{details?.role}</Typography.Text>
                    </BlockDescription>
                    <Divider />
                    <BlockDescription name={t('Countries Operation')}>
                        <Typography.Text>
                            {generalDetails?.location}
                        </Typography.Text>
                    </BlockDescription>
                </div>
            </div>
        </Modal>
    );
};

export default ViewVendor;
