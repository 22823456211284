import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Typography,
    Divider,
} from 'antd';
import { actions } from '@/redux/integrations/bookingEngine/reducer';
import SearchWidgetType from './SearchWidgetType';
import SearchWidgetServices from './SearchWidgetServices';
import GeneratedWidget from './GeneratedWidget';
import CardWidgetServices from './CardWidgetServices';
import CardWidgetType from './CardWidgetType';

const BookingEngine = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [checkedServices, setCheckedServices] = useState([{
        id: 1,
        name: 'Accommodation',
    }]);
    const [checkedTourCategories, setCheckedTourCategories] = useState([1, 2, 3]); // [1,2,3,4]
    const [checkedCardWidgetServices, setCheckedCardWidgetServices] = useState([1]);
    const [searchWidgetType, setSearchWidgetType] = useState(0);
    const [cardWidgetType, setCardWidgetType] = useState(0);

    const messageSuccess = useSelector((state) => state.integrations.bookingEngine.messageSuccess);

    const data = {
        widgets_search_type: searchWidgetType,
        widgets_type: checkedServices
            ? checkedServices.map((service) => (service.id))
            : [],
        widgets_categories: checkedCardWidgetServices,
        widgets_tour: checkedTourCategories,
        widgets_custom_type: cardWidgetType,
    };

    useEffect(() => {
        dispatch(actions.requestGetWidgetDiv(data));
    }, [dispatch, searchWidgetType, checkedServices,
        checkedTourCategories, cardWidgetType, checkedCardWidgetServices]);

    // in case of domain whitelist added/deleted successfully
    useEffect(() => {
        if (messageSuccess !== '') {
            dispatch(actions.requestGetWidgetDiv(data));
        }
    }, [messageSuccess]);

    return (
        <Row className="integrations-page">
            <Col span={24}>
                <div>
                    <Typography.Title level={5}>{t('Booking engine').toUpperCase()}</Typography.Title>
                </div>
                <div className="subtitle">
                    <Typography.Text>
                        {t('Configure widgets for your website integration')}
                    </Typography.Text>
                </div>
                <Divider />
            </Col>

            <Col span={24}>
                <Row gutter={30}>
                    <Col span={12}>
                        <Row gutter={[0, 35]}>
                            <Col>
                                <Typography.Text className="section-title">
                                    {t('Select the services you want to integrate to the website')}
                                </Typography.Text>

                                <SearchWidgetServices
                                    checkedServices={checkedServices}
                                    setCheckedServices={setCheckedServices}
                                    checkedTourCategories={checkedTourCategories}
                                    setCheckedTourCategories={setCheckedTourCategories}
                                />
                            </Col>
                            <Col>
                                <CardWidgetServices
                                    checkedCardWidgetServices={checkedCardWidgetServices}
                                    setCheckedCardWidgetServices={setCheckedCardWidgetServices}
                                />
                            </Col>
                            <Col>
                                <Typography.Text className="section-title">
                                    {t('Choose the type of widget for the Search Box')}
                                </Typography.Text>

                                <SearchWidgetType
                                    checkedId={searchWidgetType}
                                    setCheckedId={setSearchWidgetType}
                                />
                            </Col>
                            <Col>
                                <Typography.Text className="section-title">
                                    {t('Choose the preview mode on website')}
                                </Typography.Text>

                                <CardWidgetType
                                    customCheckedId={cardWidgetType}
                                    setCustomCheckedId={setCardWidgetType}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Typography.Text className="section-title">
                            {t('Generated Widgets')}
                        </Typography.Text>

                        <GeneratedWidget
                            checkedId={searchWidgetType}
                            checkedServices={checkedServices}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default BookingEngine;
