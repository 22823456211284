import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Switch,
    Typography,
    Divider,
    Button,
} from 'antd';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import { selectors } from '@/redux/tour/activities/create/model';

import DurationInMinutes from './InMinutes';
import DurationInDays from './InDays';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const TimeAndDuration = ({
    form,
    isEdit,
    regStep,
    id,
}) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);

    const timeAndDuration = useSelector(selectors.timeAndDuration);
    useEffect(() => {
        if (timeAndDuration && timeAndDuration.duration_type === 'Days') {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [timeAndDuration]);
    return (
        <>
            <Row justify="space-between" className="time-duration">
                <Col>
                    <HeaderWithHelp
                        title="Time & Duration"
                        question={question}
                        answer={answer}
                    />
                </Col>

                <Col>
                    <Button type="text" className="skip-btn">
                        {t('Skip this step')}
                    </Button>
                </Col>
            </Row>

            <Divider />

            <Row gutter={20} justify="center" align="middle" className="duration-section">
                <Col>
                    <Typography.Text>
                        {t('Duration in minutes / hours')}
                    </Typography.Text>
                </Col>

                <Col>
                    <Switch
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                </Col>

                <Col>
                    <Typography.Text>
                        {t('Duration in days')}
                    </Typography.Text>
                </Col>
            </Row>

            {
                checked
                    ? (
                        <DurationInDays
                            form={form}
                            isEdit={isEdit}
                            regStep={regStep}
                            id={id}
                        />
                    )
                    : (
                        <DurationInMinutes
                            form={form}
                            isEdit={isEdit}
                            regStep={regStep}
                            id={id}
                        />
                    )
            }
        </>
    );
};

export default TimeAndDuration;
