/* eslint-disable max-len */
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Divider,
    Space,
    Typography,
    Button,
} from 'antd';
import {
    CloseIcon,
    PartnerIcon,
} from '@/assets/images/icons/screens';
import { actions } from '@/redux/b2b/supplier/reducer';
import TermsAndConditions from '@/components/TermsAndConditions';
import B2BCard from '@/components/B2BCard';
import Filters from './Filters';

const initialFilters = {
    type: 0,
};

const ViewAll = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [myCollection, setMyCollection] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [showInfoText, setShowInfoText] = useState(true);
    const [filters, baseSetFilters] = useState(initialFilters);
    const [serviceId, setServiceId] = useState(null);
    const [page, setPage] = useState(1);

    const handleFetchSharedServices = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                ...filters,
                service_type: filters.service_type ? [filters.service_type] : undefined,
                type: filters.type ? 0 : 1, // 0 --> my collection, 1 --> all collection
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            dispatch(actions.requestFetchSharedServices(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const sharedServices = useSelector((state) => state.b2b.supplier.data);
    const requestSuccess = useSelector((state) => state.b2b.supplier.collect.message);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.b2b.supplier);

    useEffect(() => {
        if (requestSuccess === 'Success') {
            handleFetchSharedServices({});
            dispatch(actions.clearRequestSuccess());
        }
    }, [handleFetchSharedServices, requestSuccess, dispatch]);

    useEffect(() => {
        handleFetchSharedServices({});
    }, [handleFetchSharedServices]);

    const handleFetchMore = useCallback(() => {
        handleFetchSharedServices({ currentPage: page, fetchMore: true });
    }, [handleFetchSharedServices, page]);

    // accept or reject shared service
    const sendRequest = useCallback((values) => {
        dispatch(actions.sendServiceRequest(values));
    }, [dispatch]);

    return (
        <>
            <Row gutter={[0, 25]} className="worldwide-supplier-page">
                {showInfoText && (
                    <Col xs={24}>
                        <div className="info-text">
                            <Space size={25}>
                                <PartnerIcon />
                                <Typography.Text>
                                    <div>
                                        {`${t('You can increase your revenue by selling the B2B products below')}.`}
                                    </div>
                                    <div>
                                        {`${t("Make requests for the products you'll choose and be a worldwide vendor")}.`}
                                    </div>
                                </Typography.Text>
                            </Space>
                            <Button type="text" onClick={() => setShowInfoText(false)}>
                                <CloseIcon />
                            </Button>
                        </div>
                    </Col>
                )}
                <Col xs={24}>
                    <Filters
                        myCollection={myCollection}
                        setMyCollection={setMyCollection}
                        filters={filters}
                        setFilters={setFilters}
                        data={sharedServices}
                    />
                </Col>
                <Col xs={24}><Divider dashed /></Col>
                {loading
                    ? 'Loading...'
                    : (
                        <Col xs={24}>
                            <B2BCard
                                data={sharedServices}
                                myCollection={myCollection}
                                setModalVisible={setModalVisible}
                                setServiceId={setServiceId}
                                sendRequest={sendRequest}
                            />
                            {!isLoadingMore && hasMore && (
                                <Waypoint onEnter={() => handleFetchMore()} />
                            )}
                        </Col>
                    )}
            </Row>
            <TermsAndConditions
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                serviceId={serviceId}
            />
        </>
    );
};

export default ViewAll;
