import { Space } from 'antd';

const Label = ({ icon, text }) => {
    return (
        <Space size={10}>
            {icon}
            {text}
        </Space>
    );
};

export default Label;
