export default {
    submitting: false,
    submittingNewPhone: false,
    errors: null,
    registerStep: null,

    // verify email
    emailSuccess: '',
    emailError: null,

    // verify phone
    phoneEditSuccess: '',
};
