import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    ticketData: null,
    loading: false,
    errors: null,
    submitting: false,
    step: 0, // 0, 1, 2
    completedStep: null, // null, 0, 1, 2

    airportList: [],
    airlineList: [],
};

export const selectors = createSelectorsFromModel(
    (state) => state.flights.create,
    model,
);

export default model;
