import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import MyPartnerService from '@/services/B2BPartnerServices/MyPartnerService';
import { types, actions } from './reducer';

function* deletePartner({ id }) {
    try {
        yield call(MyPartnerService.deleteOne, id);
        yield put(actions.deletePartnerSucceeded(id, 'partner deleted successfully'));
    } catch (error) {
        yield put(actions.deletePartnerFailed(extractError(error)));
    }
}

function* watchDeletePartner() {
    yield takeEvery(
        types.REQUEST_DELETE_PARTNER,
        deletePartner,
    );
}

function* partnerDeleteSaga() {
    yield all([
        fork(watchDeletePartner),
    ]);
}

export default partnerDeleteSaga;
