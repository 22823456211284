import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Card,
    Space,
    Divider,
} from 'antd';
import {
    ArrowBottomIcon,
    ArrowTopIcon,
    TotalIncomingIcon,
    TickIcon,
    RevenueR1Icon,
    TotalVendorIcon,
    RevenueR2Icon,
    WorldwideIcon,
    ShareIcon,
} from '@/assets/images/icons/screens';

const OrderCards = ({ data, currency }) => {
    const { t } = useTranslation();

    return (
        <Row gutter={25} className="orders">
            <Col>
                <Card
                    className="b2b-incoming-card"
                    title={(
                        <>
                            {t('B2B Incoming')}
                            <div>{`${currency}${data?.b2b_incoming}`}</div>
                        </>
                    )}
                    extra={<TotalIncomingIcon />}
                >
                    <Space align="start" split={<Divider type="vertical" />}>
                        <div>
                            <Space align="start">
                                <ArrowBottomIcon />
                                <div>
                                    <div>
                                        {`${currency}${data?.partnership_cost}`}
                                    </div>
                                    <div>
                                        {t('Partnership Costs')}
                                    </div>
                                </div>
                            </Space>
                        </div>
                        <div>
                            <Space align="start">
                                <ArrowTopIcon />
                                <div>
                                    <div>
                                        {`${currency}${data?.profit}`}
                                    </div>
                                    <div>
                                        {t('Profits')}
                                    </div>
                                </div>
                            </Space>
                        </div>
                    </Space>
                </Card>
            </Col>
            <Col>
                <Card
                    className="b2b-order-cards total-suppliers"
                    title={(
                        <>
                            {t('Total Suppliers')}
                            <div>{data?.supplier_no}</div>
                        </>
                    )}
                    extra={<WorldwideIcon />}
                >
                    <div className="svg-wrapper">
                        <TickIcon />
                    </div>
                    <span className="value">
                        {data?.collected}
                    </span>
                    <span className="item">
                        {t('Collected Services')}
                    </span>
                </Card>
            </Col>

            <Col>
                <Card
                    className="b2b-order-cards supplier-revenue"
                    title={(
                        <>
                            {t('Revenue from Suppliers')}
                            <div>{`${currency}${data?.total_supplier}`}</div>
                        </>
                    )}
                    extra={<RevenueR1Icon />}
                >
                    <Space split={<Divider type="vertical" />}>
                        <div>
                            <span className="value">
                                {data?.avg_profit}
                            </span>
                            <span className="item">
                                {t('average profit')}
                            </span>
                        </div>
                        <div>
                            <span className="value">
                                {`${currency}${data?.my_earning_supplier}`}
                            </span>
                            <span className="item">
                                {t('my earning')}
                            </span>
                        </div>
                    </Space>
                </Card>
            </Col>

            <Col>
                <Card
                    className="b2b-order-cards total-vendors"
                    title={(
                        <>
                            {t('Total Vendors')}
                            <div>{data?.vendor_no}</div>
                        </>
                    )}
                    extra={<TotalVendorIcon />}
                >
                    <div className="svg-wrapper">
                        <ShareIcon />
                    </div>
                    <span className="value">
                        {data?.shared}
                    </span>
                    <span className="item">
                        {t('shared services')}
                    </span>
                </Card>
            </Col>

            <Col>
                <Card
                    className="b2b-order-cards vendor-sales"
                    title={(
                        <>
                            {t('Sales by Vendors')}
                            <div>{`${currency}${data?.total_vendor}`}</div>
                        </>
                    )}
                    extra={<RevenueR2Icon />}
                >
                    <Space split={<Divider type="vertical" />}>
                        <div>
                            <span className="cost-value">
                                {data?.avg_commission}
                            </span>
                            <span className="item">
                                {t('average commission')}
                            </span>
                        </div>
                        <div>
                            <span className="profit-value">
                                {`${currency}${data?.my_earning_vendor}`}
                            </span>
                            <span className="item">
                                {t('my earning')}
                            </span>
                        </div>
                    </Space>
                </Card>
            </Col>
        </Row>
    );
};

export default OrderCards;
