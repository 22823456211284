import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    name: {
        name: 'First Name',
        required: true,
    },
    surname: {
        name: 'Last Name',
        required: true,
    },
    id_number: {
        name: 'Id',
        required: true,
    },
    nationality: {
        name: 'Nationality',
        required: true,
    },
    gender: {
        name: 'Gender',
        required: true,
    },
    birth: {
        name: 'Birthdate',
        required: true,
    },
    phone: {
        name: 'Phone number',
        required: true,
    },
    address: {
        name: 'Address',
        required: true,
    },
    email: {
        name: 'Email',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
