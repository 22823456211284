import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import MaxPriceService from '@/services/MaxPriceService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* getMaxPrice({ data }) {
    const { type: serviceType } = data;
    try {
        const response = yield call(MaxPriceService.getMaxPrice, data);
        const { max_price: maxPrice } = response.data;
        yield put(actions.getMaxPriceSucceeded(parseInt(maxPrice, 10), serviceType));
    } catch (error) {
        yield put(actions.getMaxPriceFailed(extractError(error)));
    }
}

function* watchGetMaxPrice() {
    yield takeEvery(
        types.REQUEST_GET_MAX_PRICE,
        getMaxPrice,
    );
}

function* maxpriceSaga() {
    yield all([
        fork(watchGetMaxPrice),
    ]);
}

export default maxpriceSaga;
