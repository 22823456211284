import { Typography } from 'antd';

const InlineDescription = ({ icon, label, content }) => {
    return (
        <div className="description">
            <Typography.Text className="icon">
                {icon}
            </Typography.Text>

            <Typography.Text className="label">
                {label}
                :
            </Typography.Text>

            <Typography.Text className="content">
                {content}
            </Typography.Text>
        </div>
    );
};

export default InlineDescription;
