import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    TimePicker,
    Checkbox,
    Divider,
} from 'antd';
import ActionIcon from '@/components/ActionIcon';
import { MinusIcon } from '@/assets/images/icons/screens';
import { useCurrency } from '@/hooks/useCurrency';

const FieldItem = ({ field, remove }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    const [checked, setChecked] = useState(false);

    return (
        <Row
            className="time-input-fields"
            gutter={[30, 0]}
        >
            <Col xxl={11} xs={24} className="timeslot-form">
                <Form.Item name={[field.name, 'startTime']}>
                    <TimePicker
                        placeholder={t('Start time')}
                        format="HH:mm a"
                    />
                </Form.Item>
            </Col>

            <Col xxl={11} xs={18} className="timeslot-form">
                <Form.Item name={[field.name, 'price']}>
                    <Input
                        disabled={checked}
                        placeholder={t('Price')}
                        suffix={`${currencyIcon}0.00 / ${t('person')}`}
                    />
                </Form.Item>
            </Col>

            <Col xxl={2} xs={6}>
                <Form.Item>
                    <ActionIcon
                        className="circle-delete-button"
                        onClick={() => remove(field.name)}
                    >
                        <MinusIcon />
                    </ActionIcon>
                </Form.Item>
            </Col>

            <Col lg={11} push={11} className="default-price-check">
                <Form.Item
                    name={[field.name, 'defaultPrice']}
                    valuePropName="checked"
                >
                    <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    >
                        {t('or Default Price')}
                    </Checkbox>
                </Form.Item>
            </Col>
            <Divider dashed />
        </Row>
    );
};

export default FieldItem;
