import { all, fork, takeEvery } from 'redux-saga/effects';
import pagination from '@/helpers/pagination';
import RequestService from '@/services/B2BPartnerServices/RequestService';
import { types } from './reducer';

function* watchFetchRequestList() {
    yield takeEvery(
        types.REQUEST_FETCH_REQUEST_LIST,
        pagination('requestList').saga(RequestService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchRequestList),
    ]);
}

export default paginationSaga;
