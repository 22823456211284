import BaseService from './BaseService';

class ResetPasswordService extends BaseService {
    constructor() {
        const baseUrl = process.env.REACT_APP_API_URL;
        super(baseUrl);
        if (baseUrl === 'https://api.hola.tours/api') {
            this.resetPasswordBaseUrl = 'https://api.hola.tours';
        } else if (baseUrl === 'https://holav2.apibackend11.com/api') {
            this.resetPasswordBaseUrl = 'https://holav2.apibackend11.com';
        } else {
            console.error('Invalid REACT_APP_API_URL:', baseUrl);
        }
    }

    resetPassword = (data) => this.post(`${this.resetPasswordBaseUrl}/agency/reset`, data);

    resetPasswordVerifyEmail = (data) => this.post(
        `${this.resetPasswordBaseUrl}/agency/resetverify`,
        data,
    );

    resetPasswordSetNewEmail = (id, data) => this.post(
        `${this.resetPasswordBaseUrl}/agency/resetpassword/${id}`, // not sure if it is an id or sth
        data,
    );
}

export default new ResetPasswordService();
