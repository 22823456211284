import {
    Row,
    Col,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ExtrasIcon } from '@/assets/images/icons/screens';

import SectionLayout from '../SectionLayout';

const Extras = ({
    invoiceExtras,
    currency,
    invoiceCharge,
}) => {
    const { t } = useTranslation();

    return invoiceExtras?.length !== 0 && (
        <SectionLayout
            className="extras"
            icon={<ExtrasIcon />}
            leftCol={3}
            rightCol={21}
            align="middle"
        >
            <Row justify="space-between" align="middle">
                <Col className="list">
                    <Typography.Title level={5}>
                        {t('Extras')}
                    </Typography.Title>

                    {invoiceExtras?.map((service) => (
                        <div key={service.id} className="extra">
                            <Typography.Paragraph className="name">
                                {service.name}
                                {' '}
                                (
                                {service.count}
                                {t('people')}
                                )
                            </Typography.Paragraph>

                            <Typography.Paragraph className="price">
                                {currency?.concat(service.price)}
                                /
                                {t('adult')}
                            </Typography.Paragraph>
                        </div>
                    ))}
                </Col>

                <Col>
                    <Typography.Text className="total-price">
                        {`${currency}${invoiceCharge?.extra_service_total || '0.00'}`}
                    </Typography.Text>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default Extras;
