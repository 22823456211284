import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import privateRoutes from '@/routes/privateRoutes';
import { useState, useEffect } from 'react';
import { QuestionIcon } from '@/assets/images/icons/sidebar';

const sidebarItems = privateRoutes.filter((route) => route.inSidebar);

const Sidebar = ({ isCollapsed, currentRoute }) => {
    const { t } = useTranslation();

    const items = sidebarItems.map((item) => ({
        key: item.name,
        icon: item.icon,
        label: <NavLink to={item.path}>{t(item.name)}</NavLink>,
    }));

    return (
        <Layout.Sider
            collapsible
            collapsed={isCollapsed}
            trigger={null}
            className="sidebar sidebar-s1"
            collapsedWidth={64}
            width={250}
        >
            <div className="logo logo-s1">
                <LogoWhite viewBox={isCollapsed} />
            </div>
            <Menu
                className="menu-s1"
                theme="dark"
                mode="inline"
                selectedKeys={currentRoute ? [currentRoute.name] : []}
                items={items}
            />
            <div className="question-s1">
                <a href="#test">
                    <QuestionIcon />
                </a>
            </div>
        </Layout.Sider>
    );
};

export default Sidebar;

const LogoWhite = ({ viewBox }) => {
    const [viewBoxState, setViewBoxState] = useState(viewBox);

    useEffect(() => {
        setTimeout(() => {
            setViewBoxState(viewBox);
        }, 100);
    }, [viewBox]);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="128.838"
            height="40"
            viewBox={`0 0 ${viewBoxState ? '60 60' : '128.838 40'} `}
        >
            <g
                id="Group_8693"
                data-name="Group 8693"
                transform="translate(16785 3665)"
            >
                <path
                    id="Path_7101"
                    data-name="Path 7101"
                    d="M113.363,13.8V25.24c0,.4-.353.6-1.044.6s-1.044-.2-1.044-.6v-11.7q0-2.043-3.567-2.035t-3.56,2.035v11.7c0,.4-.345.6-1.044.6s-1.044-.2-1.044-.6V5.443c0-.4.345-.593,1.044-.593s1.044.2,1.044.593v5.3A6.166,6.166,0,0,1,107.9,9.672a7.157,7.157,0,0,1,3.875.984,3.432,3.432,0,0,1,1.585,3.147Z"
                    transform="translate(-16810.41 -3665.192)"
                    fill="#fff"
                />
                <path
                    id="Path_7102"
                    data-name="Path 7102"
                    d="M135.714,15.4v8.194a3.447,3.447,0,0,1-1.5,3.147,7.585,7.585,0,0,1-4.093.976A7.705,7.705,0,0,1,126,26.738a3.455,3.455,0,0,1-1.547-3.147V15.4a3.44,3.44,0,0,1,1.5-3.147,7.51,7.51,0,0,1,4.093-.984,7.638,7.638,0,0,1,4.116.984,3.447,3.447,0,0,1,1.547,3.147Zm-2.088,8.434v-8.7q0-2.043-3.56-2.035t-3.56,2.035v8.7c0,1.352,1.187,2.035,3.56,2.035S133.626,25.206,133.626,23.832Z"
                    transform="translate(-16815.984 -3666.787)"
                    fill="#fff"
                />
                <path
                    id="Path_7103"
                    data-name="Path 7103"
                    d="M148.838,5.443V22.672a3.267,3.267,0,0,0,.391,1.555c.263.481.4.751.4.751,0,.21-.173.428-.5.668a1.615,1.615,0,0,1-.841.353c-.361,0-.751-.323-1.044-.954a4.506,4.506,0,0,1-.5-2.1V5.443c0-.4.353-.593,1.044-.593S148.838,5.045,148.838,5.443Z"
                    transform="translate(-16821.539 -3665.192)"
                    fill="#fff"
                />
                <path
                    id="Path_7104"
                    data-name="Path 7104"
                    d="M163.543,27.456h-3.079a4.807,4.807,0,0,1-3.312-.939,4.206,4.206,0,0,1-1.021-3.184v-.21a4.025,4.025,0,0,1,.916-3.057A5.775,5.775,0,0,1,160.313,19l4.506-.443v-2.9q0-2.035-3.552-2.035h-3.575c-.345,0-.526-.308-.526-.916s.18-.909.526-.909h3.6a7.57,7.57,0,0,1,4.1.976,3.455,3.455,0,0,1,1.54,3.147v7.7a5.115,5.115,0,0,0,.6,2.689,1.284,1.284,0,0,1,.18.391c0,.21-.165.428-.5.668a1.57,1.57,0,0,1-.834.353c-.383,0-.751-.368-1.059-1.089a6.759,6.759,0,0,1-.481-2.749v-3.5l-4.363.443a3,3,0,0,0-1.825.623,2.561,2.561,0,0,0-.413,1.675v.21a2.824,2.824,0,0,0,.428,1.772,2.328,2.328,0,0,0,1.81.518h3.064c.345,0,.518.308.518.916S163.865,27.456,163.543,27.456Z"
                    transform="translate(-16823.873 -3666.919)"
                    fill="#fff"
                />
                <path
                    id="Path_7105"
                    data-name="Path 7105"
                    d="M103.831,41.854h-.045c-.068.2-.15.443-.248.751s-.188.548-.285.849l-.285.841-.128.6a1.736,1.736,0,0,1-.113.376c-.038.135-.075.278-.12.413l-.12.391a1.171,1.171,0,0,1-.1.323l-.2.06a.849.849,0,0,1-.255,0,.811.811,0,0,1-.481-.12.406.406,0,0,1-.173-.345.751.751,0,0,1,0-.218l.075-.233c.06-.2.135-.451.24-.751s.21-.623.33-.976.248-.7.375-1.044l.36-.991.285-.751a2.493,2.493,0,0,1,.353-.128,1.968,1.968,0,0,1,.458-.06,1.6,1.6,0,0,1,.586.1.5.5,0,0,1,.338.308c.128.323.255.7.4,1.127s.285.871.436,1.337.293.909.428,1.344.255.826.36,1.179a.751.751,0,0,1-.248.165.961.961,0,0,1-.375.068.668.668,0,0,1-.443-.113.751.751,0,0,1-.21-.36l-.345-1.157-.135-.608c-.083-.293-.18-.586-.27-.879l-.2-.841C103.966,42.252,103.891,42.034,103.831,41.854Zm-1.5,3.214.428-.924h2.253l.165.924Z"
                    transform="translate(-16810.215 -3674.153)"
                    fill="#fff"
                />
                <path
                    id="Path_7106"
                    data-name="Path 7106"
                    d="M119.068,43.669v1.9a.511.511,0,0,1-.1.345,1.014,1.014,0,0,1-.3.233,2.644,2.644,0,0,1-.683.233,4.363,4.363,0,0,1-.879.09,3.755,3.755,0,0,1-1.5-.3,2.313,2.313,0,0,1-1.066-.939,3.087,3.087,0,0,1-.391-1.622,3,3,0,0,1,.4-1.6,2.456,2.456,0,0,1,1.066-.969,3.32,3.32,0,0,1,1.5-.33,3.552,3.552,0,0,1,.946.113,1.615,1.615,0,0,1,.623.308.548.548,0,0,1,.218.428.556.556,0,0,1-.09.315.751.751,0,0,1-.233.21,3.688,3.688,0,0,0-.571-.278,2.2,2.2,0,0,0-.819-.135,1.923,1.923,0,0,0-.931.225,1.637,1.637,0,0,0-.646.653,2.538,2.538,0,0,0-.075,2.133,1.5,1.5,0,0,0,.646.631,1.915,1.915,0,0,0,.916.21,1.968,1.968,0,0,0,.481-.053,2.027,2.027,0,0,0,.33-.1V44.119h-1.157a.961.961,0,0,1-.075-.2.752.752,0,0,1,0-.263.488.488,0,0,1,.113-.353.4.4,0,0,1,.248-.128h1.5a.518.518,0,0,1,.36.128.481.481,0,0,1,.165.36Z"
                    transform="translate(-16813.422 -3674.12)"
                    fill="#fff"
                />
                <path
                    id="Path_7107"
                    data-name="Path 7107"
                    d="M128.879,44.545H127.73v-3a.608.608,0,0,1,.173-.451.593.593,0,0,1,.451-.165,1.975,1.975,0,0,1,.308,0l.218.053Zm-1.149-1.817h1.149v3.59l-.218.045a1.337,1.337,0,0,1-.308,0,.593.593,0,0,1-.421-.128.646.646,0,0,1-.173-.466Zm.623-.886V40.91h3a.878.878,0,0,1,.09.188.923.923,0,0,1,0,.263.548.548,0,0,1-.113.36.383.383,0,0,1-.3.12Zm0,2.208v-.931h2.711a.488.488,0,0,1,.09.188.751.751,0,0,1,.038.263.556.556,0,0,1-.105.36.383.383,0,0,1-.308.12Zm0,2.343v-.909h3a1.216,1.216,0,0,1,.09.2,1.021,1.021,0,0,1,.038.27.556.556,0,0,1-.105.36.4.4,0,0,1-.308.12Z"
                    transform="translate(-16816.801 -3674.17)"
                    fill="#fff"
                />
                <path
                    id="Path_7108"
                    data-name="Path 7108"
                    d="M144.253,44.708v1.5a.916.916,0,0,1-.285.15,1.5,1.5,0,0,1-.436.053,1.359,1.359,0,0,1-.5-.09.849.849,0,0,1-.391-.391L141.392,43.6l-.233-.451-.225-.451c-.068-.143-.135-.278-.188-.406l-1.112.248V41.08a.638.638,0,0,1,.3-.2,1.126,1.126,0,0,1,.421-.075,1.239,1.239,0,0,1,.511.1.8.8,0,0,1,.383.4l1.3,2.336c.075.135.158.285.233.436l.218.451c.075.15.135.285.188.421ZM139.6,42.162h1.051c0,.233.038.481.053.751s0,.5,0,.751v2.749l-.225.045a2.05,2.05,0,0,1-.308,0,.691.691,0,0,1-.466-.12.526.526,0,0,1-.143-.406Zm4.656,2.929H143.2c-.045-.458-.075-.909-.075-1.344V40.87h.225a1.953,1.953,0,0,1,.3,0,.706.706,0,0,1,.473.12.511.511,0,0,1,.143.413Z"
                    transform="translate(-16819.746 -3674.145)"
                    fill="#fff"
                />
                <path
                    id="Path_7109"
                    data-name="Path 7109"
                    d="M157.1,41.569a.5.5,0,0,1-.1.308.879.879,0,0,1-.225.218,2.831,2.831,0,0,0-.518-.293,1.84,1.84,0,0,0-.713-.12,1.7,1.7,0,0,0-.886.218,1.554,1.554,0,0,0-.586.653,2.456,2.456,0,0,0-.2,1.051,1.975,1.975,0,0,0,.466,1.434,1.712,1.712,0,0,0,1.262.481,1.84,1.84,0,0,0,.751-.12,3,3,0,0,0,.541-.263.653.653,0,0,1,.2.225.548.548,0,0,1,.083.315.481.481,0,0,1-.083.278.654.654,0,0,1-.27.233,2.651,2.651,0,0,1-.5.2,3.2,3.2,0,0,1-.819.09,3.132,3.132,0,0,1-1.457-.285,2.4,2.4,0,0,1-1.006-.946,3.094,3.094,0,0,1-.375-1.615,3,3,0,0,1,.375-1.577,2.478,2.478,0,0,1,.991-.976,2.907,2.907,0,0,1,1.374-.33,3.192,3.192,0,0,1,.9.12,1.5,1.5,0,0,1,.593.308A.586.586,0,0,1,157.1,41.569Z"
                    transform="translate(-16823.008 -3674.13)"
                    fill="#fff"
                />
                <path
                    id="Path_7110"
                    data-name="Path 7110"
                    d="M167.7,44.545h-1.044l-.511-.789c-.188-.285-.376-.578-.556-.879s-.345-.593-.5-.879-.285-.548-.4-.751a.864.864,0,0,1,.233-.285.646.646,0,0,1,.391-.113.751.751,0,0,1,.421.113,1.157,1.157,0,0,1,.315.406l.173.308c.075.143.158.293.248.473l.278.518c.1.165.18.323.248.458l.165.285h.015c.165-.3.308-.578.436-.834l.383-.751.383-.826a.954.954,0,0,1,.248-.09h.263a.638.638,0,0,1,.406.12.436.436,0,0,1,.15.368,1.09,1.09,0,0,1-.1.338,6.579,6.579,0,0,1-.308.623c-.143.255-.323.571-.548.939S168.017,44.049,167.7,44.545Zm-1.1-.451h1.142v2.253l-.218.045a2.081,2.081,0,0,1-.315,0,.751.751,0,0,1-.473-.12.563.563,0,0,1-.135-.421Z"
                    transform="translate(-16826.004 -3674.155)"
                    fill="#fff"
                />
                <g
                    id="Group_8692"
                    data-name="Group 8692"
                    transform="translate(0 -1)"
                >
                    <path
                        id="Path_6851"
                        data-name="Path 6851"
                        d="M29.417,0h0A16.146,16.146,0,0,0,17.1,5.706L34.824,19a3.6,3.6,0,0,1,1.427,2.929v2.028A16.019,16.019,0,0,1,32.5,34.247L39.105,39.2a3.95,3.95,0,0,0,6.309-3.154V15.989A15.959,15.959,0,0,0,29.417,0Z"
                        transform="translate(-16789.258 -3663.984)"
                        fill="#fff"
                        opacity="0.9"
                    />
                    <path
                        id="Path_6852"
                        data-name="Path 6852"
                        d="M9.162,18.019V15.992A16.019,16.019,0,0,1,12.918,5.7L6.309.8A3.913,3.913,0,0,0,0,3.878V23.93a15.959,15.959,0,0,0,16,16h0a16.267,16.267,0,0,0,12.317-5.708L10.589,20.926a3.7,3.7,0,0,1-1.427-2.906Z"
                        transform="translate(-16785 -3663.979)"
                        fill="#fff"
                        opacity="0.9"
                    />
                    <path
                        id="Path_6853"
                        data-name="Path 6853"
                        d="M35.006,23.812a3.815,3.815,0,0,0-1.427-2.929L15.855,7.59A16.019,16.019,0,0,0,12.1,17.879v2.035a3.815,3.815,0,0,0,1.427,2.929L31.251,36.136a16.019,16.019,0,0,0,3.755-10.289Z"
                        transform="translate(-16788.012 -3665.874)"
                        fill="#fff"
                    />
                    <path
                        id="Path_6854"
                        data-name="Path 6854"
                        d="M53.2,0A15.952,15.952,0,0,0,44.75,2.411a15.929,15.929,0,0,1,7.51,13.578v20.06a4.272,4.272,0,0,1-.083.751,17.4,17.4,0,0,0,5.663-1.382l5.009,3.755A3.95,3.95,0,0,0,69.2,36.049V15.989A15.959,15.959,0,0,0,53.2,0Z"
                        transform="translate(-16796.141 -3663.984)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};
