import { Col, Row } from 'antd';
import ListItem from '@/components/LoadingDataDisplay/ListItem';

import Graphs from './Graphs';
import Filters from './Filters';

const Loading = () => {
    return (
        <Row gutter={[20, 20]} className="loading-invoices">
            <Col xs={24}>
                <Row gutter={30}>
                    <Col xs={8}>
                        <Graphs />
                    </Col>
                    <Col xs={8}>
                        <Graphs />
                    </Col>
                    <Col xs={8}>
                        <Graphs />
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Filters />
            </Col>
            <Col xs={24}>
                <ListItem className="loading-table" />
            </Col>
        </Row>
    );
};

export default Loading;
