import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Divider,
    Progress,
    Typography,
} from 'antd';
import { CalendarIcon } from '@/assets/images/icons/screens';
import skills from './dummyData';

const setCircleColor = (points) => {
    if (points < 25) {
        return '#F67481';
    } else if (points >= 25 && points < 50) {
        return '#FFD36C';
    } else {
        return '#24DCAE';
    }
};

const setBarColor = (points) => {
    if (points < 6) {
        return '#F67481';
    } else if (points >= 6 && points < 15) {
        return '#FFD36C';
    } else {
        return '#24DCAE';
    }
};

const EvaluationList = () => {
    const { t } = useTranslation();

    return (
        <Row gutter={[0, 20]}>
            {skills.map((skill) => (
                <Col span={24} key={skill.id} className="skills-wrapper">
                    <Space split={<Divider type="vertical" />}>
                        <div>
                            <Space size={30}>
                                <Progress
                                    type="circle"
                                    percent={skill.total}
                                    format={() => (
                                        <div>
                                            <div>
                                                <span className="em-scores">{skill.total}</span>
                                                <span className="total-scores">/ 100</span>
                                            </div>
                                            <div className="scores-desc">
                                                {`${t('Total scores').toUpperCase()}`}
                                            </div>
                                        </div>
                                    )}
                                    strokeColor={setCircleColor(skill.total)}
                                    strokeWidth="6"
                                    trailColor="#F4F6F8"
                                    width="100px"
                                />
                                <div className="split-scores">
                                    <Progress
                                        percent={skill.job_knowledge}
                                        format={() => `${t('Job Knowledge')} ${skill.job_knowledge} / 100 ${t('Points')}`}
                                        size="small"
                                        trailColor="#F4F6F8"
                                        strokeColor={setBarColor(skill.job_knowledge)}
                                    />
                                    <Progress
                                        percent={skill.productivity}
                                        format={() => `${t('Productivity')} ${skill.productivity} / 100 ${t('Points')}`}
                                        size="small"
                                        trailColor="#F4F6F8"
                                        strokeColor={setBarColor(skill.productivity)}
                                    />
                                    <Progress
                                        percent={skill.teamwork}
                                        format={() => `${t('Teamwork')} ${skill.teamwork} / 100 ${t('Points')}`}
                                        size="small"
                                        trailColor="#F4F6F8"
                                        strokeColor={setBarColor(skill.teamwork)}
                                    />
                                    <Progress
                                        percent={skill.communication_skills}
                                        format={() => `${t('Communication Skills')} ${skill.communication_skills} / 100 ${t('Points')}`}
                                        size="small"
                                        trailColor="#F4F6F8"
                                        strokeColor={setBarColor(skill.communication_skills)}
                                    />
                                </div>

                            </Space>
                        </div>
                        <div>
                            <Space direction="vertical" size={0}>
                                <Typography.Text>{t('Notes')}</Typography.Text>
                                <span>
                                    {skill.notes}
                                </span>
                                <div className="created-date">
                                    <CalendarIcon />
                                    <span>{skill.date}</span>
                                </div>
                            </Space>
                        </div>
                    </Space>
                </Col>
            ))}
        </Row>
    );
};
export default EvaluationList;
