import { Row, Col } from 'antd';
// import LoadingFilters from '@/components/LoadingDataDisplay/Filters';
import LoadingListItem from '@/components/LoadingDataDisplay/ListItem';

const Loading = () => {
    return (
        <Row gutter={[20, 20]}>
            {/* <Col sm={24}>
                <LoadingFilters />
            </Col> */}

            <Col sm={24}>
                <LoadingListItem />
            </Col>

            <Col sm={24}>
                <LoadingListItem />
            </Col>

            <Col sm={24}>
                <LoadingListItem />
            </Col>
        </Row>
    );
};

export default Loading;
