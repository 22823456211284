import { useTranslation } from 'react-i18next';
import { Row, Col, Modal } from 'antd';
import PromotionCard from '@/components/PromotionCard';
import formatOffers from '../../FormatOffers';

const Offers = ({
    offers,
    visible,
    setVisible,
}) => {
    const { t } = useTranslation();
    const formatedOffers = formatOffers(offers);

    return (
        <Modal
            className="existing_promotions"
            title={t('Promotions / Offers')}
            open={visible}
            closable={false}
            onCancel={() => setVisible(false)}
            footer={null}
            width={700}
        >
            <Row gutter={[0, 30]}>
                {formatedOffers.map((offer) => (
                    <Col key={offer.id} xs={24}>
                        <PromotionCard
                            bordered={true}
                            icon={offer.icon}
                            title={offer.name}
                            category={offer.type}
                            duration={`${offer.date_start} - ${offer.date_end}`}
                            discount={offer.discount}
                            discountType={offer.discount_type}
                            id={offer.id}
                            deleteAction={true}
                        >
                            {offer.assigned_to
                                ? offer.assigned_to?.map((roomType) => (roomType.type_name)).join(', ')
                                : offer.rooms?.map((roomType) => (roomType.type_name)).join(', ')}
                        </PromotionCard>
                    </Col>
                ))}
            </Row>
        </Modal>
    );
};

export default Offers;
