/* eslint-disable import/no-named-as-default-member */
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Divider,
} from 'antd';
import { DeleteIcon } from '@/assets/images/icons/screens';
import ActionIcon from '@/components/ActionIcon';
import { actions } from '@/redux/integrations/bookingEngine/reducer';
import useNotification from '@/hooks/useNotification';

const DomainWhitelist = ({ strDiv }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    // Find the index of the start of the desired piece of information
    const start = strDiv?.indexOf('id="') + 4;

    // Find the index of the end of the desired piece of information
    const end = strDiv?.indexOf('"', start);

    // Extract the substring containing the desired piece of information
    const result = strDiv && strDiv.length !== 0 && strDiv.substring(start, end);

    const buffer = result && Buffer.from(result, 'base64');
    const text = buffer.toString('ascii');
    const array = text.split('_');

    // get token
    const tokenArray = array[2]?.split('=');
    const tokenValue = tokenArray?.[1];

    // get domain list
    const domainList = useSelector((state) => state.integrations.bookingEngine.domainWhitelist);
    const messageSuccess = useSelector((state) => state.integrations.bookingEngine.messageSuccess);
    const messageError = useSelector((state) => state.integrations.bookingEngine.messageError);

    useEffect(() => {
        if (tokenValue) {
            dispatch(actions.requestGetDomainWhitelist());
        }
    }, [tokenValue]);

    // submit new domain
    const onFinish = useCallback((values) => {
        const data = {
            // token: tokenValue,
            domain: values.domain,
        };
        dispatch(actions.requestAddNewDomain(data));
        form.resetFields();
    }, [dispatch, tokenValue]);

    // delete domain
    const deleteDomain = useCallback((domainId) => {
        dispatch(actions.requestDeleteDomain(domainId));
    }, [dispatch]);

    useNotification({
        messageSuccess,
        error: messageError,
        clearAction: actions.clearDomainActionState(),
    });

    return (
        <div className="whitelist-section">
            <Form form={form} onFinish={onFinish}>
                <Row gutter={30}>
                    <Col span={20}>
                        <Form.Item name="domain" noStyle>
                            <Input placeholder={t('Domain')} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button
                            className="add-domain-btn"
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            {t('Add')}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <>
                {domainList?.length > 0 && <Divider dashed />}
                <div className="domain-list">
                    {domainList?.map((item, index) => (
                        <Row gutter={30} align="middle" key={item.id}>
                            <Col span={20}>
                                {index + 1}
                                .
                                {' '}
                                {item.domain}
                            </Col>
                            <Col span={4}>
                                <ActionIcon onClick={() => deleteDomain(item.id)}>
                                    <DeleteIcon />
                                </ActionIcon>
                            </Col>
                        </Row>
                    ))}
                </div>
            </>
        </div>
    );
};

export default DomainWhitelist;
