import { all, fork } from 'redux-saga/effects';

import loginSaga from './login/saga';
import forgotPasswordSaga from './forgotPassword/saga';
import registrationSaga from './registration/saga';

function* authenticationSaga() {
    yield all([
        fork(loginSaga),
        fork(forgotPasswordSaga),
        fork(registrationSaga),
    ]);
}

export default authenticationSaga;
