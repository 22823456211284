import { Card } from 'antd';
import GoogleMap from '@/components/GoogleMap';

const ActivityLocation = ({ lat, lng }) => {
    return (
        <Card>
            <GoogleMap latitude={lat} longitude={lng} />
        </Card>
    );
};

export default ActivityLocation;
