import { all } from 'redux-saga/effects';
import generalSaga from './general/saga';
import taxesSaga from './taxes/saga';
import billingSaga from './billing/saga';

function* settingsSaga() {
    yield all([
        generalSaga(),
        taxesSaga(),
        billingSaga(),
    ]);
}

export default settingsSaga;
