import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import InvoiceService from '@/services/InvoiceService';
import { types, actions } from './reducer';

function* viewInvoice({ id }) {
    try {
        const res = yield call(InvoiceService.getOne, id);
        const {
            general_data: generalData,
            first_step: firstStep,
            second_step: secondStep,
            third_step: thirdStep,
        } = res.data;
        yield put(actions.viewInvoiceSucceeded(
            generalData,
            firstStep,
            secondStep,
            thirdStep,
        ));
    } catch (error) {
        yield put(actions.viewInvoiceFailed(extractError(error)));
    }
}

function* submitPayInvoice({ id, data }) {
    try {
        yield call(InvoiceService.payInvoice, id, data);
        yield put(actions.submitPayInvoiceSucceeded('success'));
    } catch (error) {
        yield put(actions.submitPayInvoiceFailed(extractError(error)));
    }
}

function* downloadInvoicePdf({ id }) {
    try {
        yield call(InvoiceService.downloadInvoicePdf, id);
        yield put(actions.downloadInvoicePdfSucceeded('success'));
    } catch (error) {
        yield put(actions.downloadInvoicePdfFailed(extractError(error)));
    }
}

function* watchViewInvoice() {
    yield takeEvery(
        types.REQUEST_VIEW_INVOICE,
        viewInvoice,
    );
}

function* watchSubmitPayInvoice() {
    yield takeEvery(
        types.REQUEST_SUBMIT_PAY_INVOICE,
        submitPayInvoice,
    );
}

function* watchDownloadInvoicePdf() {
    yield takeEvery(
        types.REQUEST_DOWNLOAD_INVOICE_PDF,
        downloadInvoicePdf,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewInvoice),
        fork(watchSubmitPayInvoice),
        fork(watchDownloadInvoicePdf),
    ]);
}

export default viewSaga;
