import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('tourPackages');

const actions = {
    requestFetchTourPackages: baseActions.request,
    fetchTourPackagesSucceeded: baseActions.succeeded,
    fetchTourPackagesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
