import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import moment from 'moment';
import Filters from '@/components/RerservationFilters';
import ReservationList from '@/components/ReservationList';
import { selectors } from '@/redux/accommodation/hotel/view/model';

const Reservation = ({ fetchMore }) => {
    const [roomListSelected, setRoomListSelected] = useState([]);
    const [dateStartEnd, setDateStartEnd] = useState([moment(), moment().add(1, 'day')]);

    const offers = useSelector(selectors.offerList);
    const roomList = useSelector(selectors.roomList);
    const roomTypeList = useSelector(selectors.roomTypeList);
    const { general_data: generalData } = useSelector(selectors.accommodationDetails);
    const accommodationId = generalData.id;
    const isLoadingMore = useSelector(selectors.isLoadingMore);
    const hasMore = useSelector(selectors.hasMore);

    const formattedRoomList = roomList.map((room) => {
        const assignedTo = offers?.find((offer) => {
            return offer.assigned_to.some((item) => {
                return item.id === room.id;
            });
        });
        if (assignedTo) {
            room.offer = assignedTo;
        }
        return room;
    });

    return (
        <>
            <Filters
                type="Hotel"
                dateStartEnd={dateStartEnd}
                setDateStartEnd={setDateStartEnd}
                roomTypeList={roomTypeList}

            />
            <ReservationList
                roomListSelected={roomListSelected}
                setRoomListSelected={setRoomListSelected}
                dateStartEnd={dateStartEnd}
                reservationList={formattedRoomList}
                type="Hotel"
                tourId={accommodationId}
            />
            {!isLoadingMore && hasMore && (
                <Waypoint onEnter={fetchMore} />
            )}
        </>
    );
};

export default Reservation;
