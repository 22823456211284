import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Checkbox,
    notification,
    Form,
    Space,
} from 'antd';
import CabinList from './CabinList';

const SelectCabin = ({
    roomListSelected,
    setRoomListSelected,
    startDate,
    endDate,
    checkedRoom,
    setCheckedRoom,
    adultNumber,
    childNumber,
    infantNumber,
    roomId,
    noRooms,
    roomName,
    roomPrice,
    type,
    tourId,
    cabinImg,
    currency,
}) => {
    const { t } = useTranslation();
    const { replace } = useHistory();
    const dispatch = useDispatch();

    const notificationKey = 'rooms';

    // open notification
    const openNotification = () => {
        roomListSelected.push({
            cabin_type: roomId,
            cabins_no: noRooms,
            adults: adultNumber,
            children: childNumber,
            infants: infantNumber,
            // frontend data
            room_img: cabinImg,
            type_name: roomName,
            room_type_total_price: noRooms * roomPrice,
            price_type: currency,
        });
    };

    const listOfSelectedRoomPrice = roomListSelected.map((room) => (
        room.room_type_total_price
    ));

    const initialValue = 0;
    const totalPrice = listOfSelectedRoomPrice.reduce(
        (total, current) => total + current, initialValue,
    );

    useEffect(() => {
        if (roomListSelected.length !== 0) {
            notification.open({
                key: notificationKey,
                className: 'select-cabin',
                message: (
                    <Space size={80}>
                        <span className="selected-cabin">{t('Selected Cabins')}</span>
                        <span>
                            {`${t('Total Price')}
                            ${currency}${totalPrice}`}
                        </span>
                    </Space>
                ),
                description: (<CabinList
                    roomListSelected={roomListSelected}
                    startDate={startDate}
                    endDate={endDate}
                    replace={replace}
                    dispatch={dispatch}
                    notificationKey={notificationKey}
                    type={type}
                    tourId={tourId}
                    currency={currency}
                />),
                placement: 'bottomRight',
                duration: 0,
            });
        } else {
            notification.close('rooms');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomListSelected, checkedRoom]);

    const removeRoom = () => {
        const result = roomListSelected.filter((room) => room.cabin_type !== roomId);
        setRoomListSelected(result);
    };

    return (
        <Form>
            <Form.Item>
                <Checkbox
                    checked={checkedRoom}
                    disabled={
                        noRooms === 0
                        || (adultNumber === 0
                            && childNumber === 0
                            && infantNumber === 0
                        ) || (
                            startDate === undefined && endDate === undefined
                        )
                    }
                    onChange={(e) => {
                        setCheckedRoom(e.target.checked);
                        e.target.checked ? openNotification() : removeRoom();
                    }}
                >
                    {t('Select this cabin for Reservation')}
                </Checkbox>
            </Form.Item>
        </Form>

    );
};

export default SelectCabin;
