import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    Tooltip,
    Space,
} from 'antd';

import LinkButton from '@/components/LinkButton';
import ActionIcon from '@/components/ActionIcon';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ListIcon,
    PlusCircleIcon,
    RedoIcon,
} from '@/assets/images/icons/screens';

const noop = () => {};

const MultistepFormActions = ({
    stepCount,
    step,
    submitting = false,
    onSubmit = noop,
    onBack = noop,
    viewOneUrl,
    viewOneButtonText,
    viewAllUrl,
    createUrl,
    onPublish = noop,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const isFirstStep = (step === 0);
    const isFinished = (step === stepCount);

    return isFinished ? (
        <Row justify="end" gutter={30}>
            <div>
                <Col>
                    <Tooltip title={t('Create New')} overlayClassName="seasonal-tooltip">
                        <ActionIcon onClick={() => push(createUrl)}>
                            <PlusCircleIcon />
                        </ActionIcon>
                    </Tooltip>
                </Col>

                <Col>
                    <Tooltip title={t('Publish to the website')} overlayClassName="seasonal-tooltip">
                        <ActionIcon onClick={onPublish}>
                            <RedoIcon />
                        </ActionIcon>
                    </Tooltip>
                </Col>

                <Col>
                    <Tooltip title={t('View full list')} overlayClassName="seasonal-tooltip">
                        <ActionIcon onClick={() => push(viewAllUrl)}>
                            <ListIcon />
                        </ActionIcon>
                    </Tooltip>
                </Col>
            </div>

            <Col sm={12}>
                <LinkButton
                    className="view-one-btn"
                    to={viewOneUrl}
                    type="primary"
                    block
                >
                    {t(viewOneButtonText).toUpperCase()}
                </LinkButton>
            </Col>
        </Row>
    ) : (
        <Row gutter={[30, 10]}>
            {!isFirstStep && (
                <Col xl={12} lg={24} sm={12}>
                    <Button
                        block
                        className="btn-grey"
                        onClick={onBack}
                        disabled={submitting}
                    >
                        <Space size={30}>
                            <ArrowLeftIcon />
                            {t('Previews step').toUpperCase()}
                        </Space>
                    </Button>
                </Col>
            )}

            <Col xl={isFirstStep ? 24 : 12} lg={24} sm={isFirstStep ? 24 : 12}>
                <Button
                    className="button btn-orange"
                    onClick={onSubmit}
                    loading={submitting}
                >
                    <Space size={38}>
                        {(step === stepCount - 1) ? (
                            t('Save').toUpperCase()
                        ) : (
                            t('Save and go to next step').toUpperCase()
                        )}
                        <ArrowRightIcon />
                    </Space>
                </Button>
            </Col>
        </Row>
    );
};

export default MultistepFormActions;
