import { useSelector } from 'react-redux';

export const useCurrency = () => {
    const currencyIcon = useSelector((state) => state.authentication.user?.currency_icon);
    const currencyStr = useSelector((state) => state.authentication.user?.currency);
    const currencyId = useSelector((state) => state.authentication.user?.currency_id);

    return {
        icon: currencyIcon,
        str: currencyStr,
        id: currencyId,
    };
};
