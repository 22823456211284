import {
    Col,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { BedIcon } from '@/assets/images/icons/screens';

import SectionLayout from '../SectionLayout';

const Rooms = ({ reservationData, invoiceCharge, currency }) => {
    const { t } = useTranslation();
    const rooms = reservationData?.rooms || reservationData?.data;
    return rooms !== undefined && (
        <SectionLayout
            className="rooms"
            icon={<BedIcon />}
            leftCol={1}
            rightCol={23}
        >
            <Row justify="space-between">
                <Col className="list">
                    <Typography.Title level={5}>
                        {t('Rooms')}
                    </Typography.Title>
                    {rooms.map((room) => (
                        <Typography.Paragraph key={room.id || room.room_type}>
                            {`
                                ${room.type_name}
                                ( x ${room.no_rooms} ${t('Rooms')})
                            `}
                        </Typography.Paragraph>
                    ))}
                </Col>

                <Col>
                    <Typography.Text className="total-price">
                        {`${currency}${invoiceCharge?.rooms_price || '0.00'}`}
                    </Typography.Text>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default Rooms;
