import { createSelectorsFromModel } from '@/helpers/redux';
import { groupsRedux } from './reducer';

const groupsModel = { ...groupsRedux.model };
delete groupsModel.fetchingError;
delete groupsModel.fetchingLoading;

const model = {
    generalInfo: null,
    itenerary: [],
    loading: false,
    error: null,

    groups: {
        data: [],
        totalItems: 0,
        filterData: null,
        fetchingLoading: true,
        fetchingError: null,
        isLoadingMore: false,
        isLoadingMoreError: null,
        hasMore: false,
        groupsModel,
    },

    groupDetails: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.trip.view,
    model,
);

export default model;
