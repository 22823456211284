import { Steps, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EmptyData from '@/components/EmptyData';
import { selectors } from '@/redux/tour/activities/create/model';

import GeneralDetails from './GeneralDetails';
import TimeAndDuration from './TimeAndDuration';
import Partner from './Partner';

const AddTourSteps = ({ step }) => {
    const { t } = useTranslation();

    const generalInfo = useSelector(selectors.generalInfo);
    const timeAndDuration = useSelector(selectors.timeAndDuration);
    const partnerData = useSelector(selectors.partner);

    return (
        <>
            <Steps
                className="steps-container"
                current={step}
                labelPlacement="vertical"
                items={[

                    { title: t('General Details') },
                    { title: t('Time & Duration') },
                    { title: t('Partner') },
                ]}
            />

            <div className="data-display">
                {generalInfo !== null ? (
                    <Row gutter={[0, 30]}>
                        <Col xs={24}>
                            <GeneralDetails data={generalInfo} />
                        </Col>
                        <Col xs={24}>
                            <TimeAndDuration data={timeAndDuration} price={generalInfo?.price} />
                        </Col>
                        <Col xs={24}>
                            <Partner data={partnerData} />
                        </Col>
                    </Row>
                ) : (
                    <EmptyData />
                )}
            </div>
        </>
    );
};

export default AddTourSteps;
