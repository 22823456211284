import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Form } from 'antd';
import MultipleUpload from '@/components/Upload/Multiple';
import { allowedImageMIMETypes } from '@/constants/upload';
import { firstToUpper } from '@/helpers/string';

const ImagesUpload = () => {
    const { t } = useTranslation();
    const { type } = useParams();
    const formattedType = firstToUpper(type);
    return (
        <>
            <Typography.Text className="images-upload_text">
                {t(`${formattedType} Photo Gallery`)}
            </Typography.Text>

            <Form.Item name="images">
                <MultipleUpload
                    allowedMIMETypes={allowedImageMIMETypes}
                    maxCount={5}
                    className="upload-container"
                    listType="picture-card"
                />
            </Form.Item>
        </>
    );
};

export default ImagesUpload;
