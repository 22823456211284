import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { actions } from '@/redux/tour/reducer';
import EmptyList from '@/components/EmptyList';
import Filters from '@/components/TourFilters';
import { EmptyListIcon } from '@/assets/images/icons/screens';

import PackageList from './PackageList';
import Loading from './Loading';

const ViewAll = () => {
    const dispatch = useDispatch();

    const maxPrice = useSelector((state) => state.maxprice.tourMaxPrice);

    const [filters, baseSetFilters] = useState({ prices: [0, 0] });
    useEffect(() => {
        if (maxPrice !== null) {
            baseSetFilters({ prices: [0, maxPrice] });
        }
    }, [maxPrice]);

    const [viewMode, setViewMode] = useState('list');
    const [page, setPage] = useState(1);

    const handleFetchTourPackages = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                name: filters.name,
                location: filters.location,
                // duration: filters.date
                min_price: filters.prices[0],
                max_price: filters.prices[1],
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            filters.prices[1] !== 0 && dispatch(actions.requestFetchTourPackages(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters.location,
            filters.name,
            filters.prices,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const packages = useSelector((state) => state.tour.packages.data);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.tour.packages);

    useEffect(() => {
        handleFetchTourPackages({});
    }, [handleFetchTourPackages]);

    const handleFetchMore = useCallback(() => {
        handleFetchTourPackages({ currentPage: page, fetchMore: true });
    }, [handleFetchTourPackages, page]);

    return (
        <div id="tour-view-all" className="package-view-all">
            <Filters
                filters={filters}
                setFilters={setFilters}
                viewMode={viewMode}
                setViewMode={setViewMode}
                type="package"
                searchPlaceholder="Search for Package"
                locationPlaceholder="Hotel, Location"
                addNewItemText="Add new tour"
                addUrl="/app/tours/packages/add"
                clearTourState={actions.clearPackageState()}
            />
            { loading
                ? <Loading />
                : packages.length === 0

                    ? (
                        <EmptyList
                            image={<EmptyListIcon />}
                            category={'Start creating tour packages'.toUpperCase()}
                            message="Oops! Looks you haven't created yet any Tour Packages"
                            url="/app/tours/packages/add"
                        />
                    ) : (
                        <>
                            <PackageList
                                data={packages}
                                viewMode={viewMode}
                            />
                            {!isLoadingMore && hasMore && (
                                <Waypoint onEnter={() => handleFetchMore()} />
                            )}
                        </>
                    )}

        </div>
    );
};

export default ViewAll;
