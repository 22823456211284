import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import ActivityService from '@/services/TourServices/ActivityService';
import { types, actions } from './reducer';

function* viewTourActivity({ id }) {
    try {
        const [
            activity,
            partner,
        ] = yield all([
            call(ActivityService.getOne, id),
            call(ActivityService.viewPartner, id),
        ]);
        yield put(actions.viewTourActivitySucceeded(activity.data, partner.data));
    } catch (error) {
        yield put(actions.viewTourActivityFailed(extractError(error)));
    }
}

function* getActivityTimeList({ id, activityDate }) {
    const data = {
        date: activityDate,
    };
    try {
        const response = yield call(ActivityService.getActivityTimeList, id, data);
        yield put(actions.getActivityTimeListSucceeded(response.data, activityDate));
    } catch (error) {
        yield put(actions.getActivityTimeListFailed(extractError(error)));
    }
}

function* watchViewTourActivity() {
    yield takeEvery(
        types.REQUEST_VIEW_TOUR_ACTIVITY,
        viewTourActivity,
    );
}

function* watchgetActivityTimeList() {
    yield takeEvery(
        types.REQUEST_GET_ACTIVITY_TIME_LIST,
        getActivityTimeList,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewTourActivity),
        fork(watchgetActivityTimeList),
    ]);
}

export default viewSaga;
