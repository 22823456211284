import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Select,
    Input,
    Typography,
    Form,
} from 'antd';
import { LocationIcon, SortIcon } from '@/assets/images/icons/screens';

const Filters = ({ filters, setFilters, data }) => {
    const { t } = useTranslation();

    const totalRequests = data.reduce((acc, item) => acc + item.total_requests, 0);

    return (
        <Form
            initialValues={filters}
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                setFilters(name[0], value);
            }}
        >
            <Row gutter={[30, 30]} align="middle" className="b2b-request-filters">
                <Col xs={24} md={8} xl={5}>
                    <Form.Item name="service_type" noStyle>
                        <Select allowClear placeholder={t('Filter by Service Type')}>
                            <Select.Option value={1}>{t('Tour Trip')}</Select.Option>
                            <Select.Option value={2}>{t('Tour Package')}</Select.Option>
                            <Select.Option value={3}>{t('Cruise')}</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={5}>
                    <Form.Item name="location" noStyle>
                        <Input
                            placeholder={t('Filter by Tour Location')}
                            suffix={<LocationIcon />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                    <Form.Item name="name" noStyle>
                        <Input.Search
                            placeholder={t('Search with keyword (agency name, product name...)')}
                        />
                    </Form.Item>
                </Col>
                <Col xs={12} md={18} xl={3} className="total-requests">
                    <Typography.Text>
                        {`${totalRequests} ${t('requests found')}`}
                    </Typography.Text>
                </Col>
                <Col xs={12} md={6} xl={3}>
                    <Form.Item name="order_by" noStyle>
                        <Select
                            className="sort-request"
                            placeholder={t('Recent')}
                            suffixIcon={<SortIcon />}
                        >
                            <Select.Option value={1}>type</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default Filters;
