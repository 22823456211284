export const formatExtraServicesForSubmit = (services) => services.map((service) => ({
    name: service.service_name,
    price: service.price_type === true ? 0 : service.price,
    price_type: service.price_type === true ? 1 : null,
}));

// format extra services as initial data
export const eServicesFormat = (eServices) => eServices.map((service) => ({
    service_name: service.name,
    price: service.price,
    price_type: service.price === 0,
}));

// format inclusions && exclusions as initial data
export const inclExclFormat = (items) => items.map((item) => (
    item.id
));
