import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import EmptyList from '@/components/EmptyList';
import { EmptyListIcon } from '@/assets/images/icons/screens';
import { firstToUpper } from '@/helpers/string';
import { actions } from '@/redux/accommodation/hotel/reducer';

import Filters from './Filters';
import AccommodationList from './AccommodationList';
import Loading from './Loading';

const ViewAll = () => {
    const { type } = useParams();
    const dispatch = useDispatch();
    const formattedType = firstToUpper(type);

    const maxPrice = useSelector((state) => state.maxprice.accommodationMaxPrice);

    const [filters, baseSetFilters] = useState({ prices: [0, 0] });
    useEffect(() => {
        if (maxPrice !== null) {
            baseSetFilters({ prices: [0, maxPrice] });
        }
    }, [maxPrice]);

    const [viewMode, setViewMode] = useState('list');
    const [page, setPage] = useState(1);

    let urlType = null;

    if (type === 'hotel') {
        urlType = 3;
    } else if (type === 'motel') {
        urlType = 4;
    } else if (type === 'resort') {
        urlType = 7;
    } else {
        urlType = 10;
    }

    const handleFetchAccommodations = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const filterData = {
                min_price: filters.prices[0],
                max_price: filters.prices[1],
                type: [urlType],
                name: filters.name,
                sort: filters.sort,
                location: filters.location?.length !== 0 ? filters.location : undefined,
                rate: filters.rate?.length !== 0 ? filters.rate : undefined,
                facilities: filters.facilities?.length !== 0 ? filters.facilities : undefined,
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            filters.prices[1] !== 0
            && dispatch(actions.requestFetchAccommodationList(filterData, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters.name,
            urlType,
            filters.prices,
            filters.sort,
            filters.location,
            filters.rate,
            filters.facilities,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const accommodationlist = useSelector((state) => state.accommodation.hotel.data);
    const total = useSelector((state) => state.accommodation.hotel.totalItems);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.accommodation.hotel);

    useEffect(() => {
        dispatch(actions.clearHotelState());
    }, [type, dispatch]);

    useEffect(() => {
        handleFetchAccommodations({});
    }, [handleFetchAccommodations]);

    const handleFetchMore = useCallback(() => {
        handleFetchAccommodations({ currentPage: page, fetchMore: true });
    }, [handleFetchAccommodations, page]);

    return (
        <div id="accommodation-view-all">
            <Filters
                filters={filters}
                setFilters={setFilters}
                viewMode={viewMode}
                setViewMode={setViewMode}
            />
            { loading
                ? <Loading />
                : accommodationlist.length === 0

                    ? (
                        <EmptyList
                            image={<EmptyListIcon />}
                            category={`Start creating ${type}`}
                            message={`Oops! Looks you haven't created yet any ${formattedType}`}
                            url={`/app/accommodations/${type}/add`}
                        />
                    ) : (
                        <>
                            <AccommodationList
                                data={accommodationlist}
                                viewMode={viewMode}
                                total={total}
                            />
                            {!isLoadingMore && hasMore && (
                                <Waypoint onEnter={() => handleFetchMore()} />
                            )}
                        </>
                    )}
        </div>
    );
};

export default ViewAll;
