import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import { useCurrency } from '@/hooks/useCurrency';

const CruisePrices = ({ validations }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    const options = useMemo(() => ([
        { label: t('Per person'), value: 1 },
        { label: t('Per cabin'), value: 6 },
    ]), [t]);

    const costTypeOptions = useMemo(() => ([
        { label: t('%'), value: 1 },
        { label: t(currencyIcon), value: 2 },
    ]), [t]);

    return (
        <Row>
            <Col lg={24} xs={24}>
                <MUIFormItem
                    className="cruise-price"
                    label={`${t('Avg. Price per Night')}:`}
                    name="adult_price"
                    rules={validations.nightPrice}
                >
                    <Input
                        suffix={(
                            <>
                                {`${currencyIcon}0.00`}
                                <Form.Item
                                    name="adult_price_type"
                                    noStyle
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </>
                        )}
                    />
                </MUIFormItem>
            </Col>
            <Col lg={24} xs={24}>
                <MUIFormItem
                    className="cruise-cost"
                    label={t('Cost')}
                    name="cost"
                    rules={validations.cost}
                >
                    <Input
                        suffix={(
                            <>
                                {`${currencyIcon}0.00`}
                                <Form.Item
                                    name="cost_type"
                                    noStyle
                                >
                                    <Radio.Group
                                        options={costTypeOptions}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </>
                        )}
                    />
                </MUIFormItem>
            </Col>
        </Row>
    );
};

export default CruisePrices;
