/* eslint-disable max-len */
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import {
    Row,
    Col,
    Form,
    Input,
    Select,
} from 'antd';
import { LocationIcon, SortIcon } from '@/assets/images/icons/screens';
import TermsAndConditions from '@/components/TermsAndConditions';
import { actions } from '@/redux/b2b/agencyprofile/reducer';
import { actions as collectActions } from '@/redux/b2b/supplier/collect/reducer';
import B2BCard from '@/components/B2BCard';

const initialFilters = null;

const AgencyServices = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id: agencyId } = useParams();

    const [modalVisible, setModalVisible] = useState(false);
    const [filters, baseSetFilters] = useState(initialFilters);
    const [serviceId, setServiceId] = useState(null);
    const [page, setPage] = useState(1);

    const handleGetAgencyServices = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                ...filters,
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            dispatch(actions.requestGetAgencyServices(agencyId, data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters,
            agencyId,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const sharedServices = useSelector((state) => state.b2b.agencyprofile.agencyServices);
    const requestSuccess = useSelector((state) => state.b2b.supplier.collect.message);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.b2b.agencyprofile);

    useEffect(() => {
        if (requestSuccess === 'Success') {
            handleGetAgencyServices({});
            dispatch(collectActions.clearRequestSuccess());
        }
    }, [handleGetAgencyServices, requestSuccess, dispatch]);

    useEffect(() => {
        handleGetAgencyServices({});
    }, [handleGetAgencyServices]);

    const handleFetchMore = useCallback(() => {
        handleGetAgencyServices({ currentPage: page, fetchMore: true });
    }, [handleGetAgencyServices, page]);

    // accept or reject shared service
    const sendRequest = useCallback((values) => {
        dispatch(collectActions.sendServiceRequest(values));
    }, [dispatch]);

    if (loading) {
        return (
            'Loading...'
        );
    } else {
        return (
            <div className="agency-services">
                <div className="filters">
                    <Form
                        initialValues={filters}
                        onFieldsChange={([changedField]) => {
                            const { name, value } = changedField;
                            setFilters(name[0], value);
                        }}
                    >
                        <Row gutter={[30, 30]}>
                            <Col xs={24} md={7}>
                                <Form.Item name="service_type" noStyle>
                                    <Select placeholder={t('Filter by Service Type')}>
                                        <Select.Option value={1}>{t('Tour Trip')}</Select.Option>
                                        <Select.Option value={2}>{t('Tour Package')}</Select.Option>
                                        <Select.Option value={3}>{t('Cruise')}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={7}>
                                <Form.Item name="location" noStyle>
                                    <Input
                                        placeholder={t('Filter by Service Location')}
                                        suffix={<LocationIcon />}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item name="status" noStyle>
                                    <Select placeholder={t('Filter by Status')}>
                                        <Select.Option value={1}>{t('Collected')}</Select.Option>
                                        <Select.Option value={2}>{t('Pending')}</Select.Option>
                                        <Select.Option value={5}>{t('Force Request')}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={4}>
                                <Form.Item name="order_by" noStyle>
                                    <Select
                                        className="sort-tour"
                                        placeholder={t('Sort by')}
                                        suffixIcon={<SortIcon />}
                                    >
                                        <Select.Option value="price">{t('Price')}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <B2BCard
                    data={sharedServices}
                    setModalVisible={setModalVisible}
                    setServiceId={setServiceId}
                    sendRequest={sendRequest}
                    xlColValue={8}
                />
                {!isLoadingMore && hasMore && (
                    <Waypoint onEnter={() => handleFetchMore()} />
                )}
                <TermsAndConditions
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    serviceId={serviceId}
                />
            </div>
        );
    }
};

export default AgencyServices;
