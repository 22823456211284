import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    step: 0,
    completedStep: null,
    submitting: false,
    loading: false,
    loadingAmenity: false,
    errors: null,
    accommodationDetails: null,
    facilities: [],
    services: [],
    amenitiesList: [],

    /*
     * manages mode of room & season details
     * if false, the user is watching room or season
     * if true, the user is creating room or season
     * */
    isCreatingRoom: false,
    submittingRoom: false,
    isCreatingSeason: false,
    submittingSeason: false,

    // edit or create new room type & season
    roomList: [],
    roomDetails: null,

    seasonList: [],
    seasonDetails: null,

    // delete room type & season
    id: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.accommodation.hotel.create,
    model,
);

export default model;
