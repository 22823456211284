import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { Line } from '@ant-design/plots';
import HeaderWithHelp from '@/components/HeaderWithHelp';

const SalesGraph = ({ data, currency }) => {
    const { t } = useTranslation();

    const formatValue = (v) => {
        if (v >= 1000) {
            return `${currency}${(v / 1000)}K`;
        } else if (v >= 1000000) {
            return `${currency}${(v / 1000000)}M`;
        } else if (v >= 1000000000) {
            return `${currency}${(v / 1000000000)}B`;
        } else {
            return `${currency}${v}`;
        }
    };

    const config = {
        data,
        xField: 'month',
        yField: 'total',
        seriesField: 'type',
        yAxis: {
            label: {
                formatter: (v) => formatValue(v),
            },
        },
        legend: {
            position: 'top',
        },
        smooth: true,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
        tooltip: {
            crosshairs: {
                line: {
                    style: {
                        lineDash: [4, 5],
                    },
                },
            },
        },
    };

    return (
        <Card
            className="sales-overview-card"
            title={(
                <>
                    <span className="sales-graphs_header_text">{t('Sales Overview')}</span>
                    <HeaderWithHelp
                        question={t('Question')}
                        answer={t('Answer')}
                    />
                </>
            )}
        >
            <Line {...config} />
        </Card>
    );
};

export default SalesGraph;
