import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Checkbox,
    notification,
    Form,
    Space,
} from 'antd';
import RoomList from './RoomList';

const SelectRoom = ({
    roomListSelected,
    setRoomListSelected,
    checkedRoom,
    setCheckedRoom,
    dateStartEnd,
    adultNumber,
    childNumber,
    infantNumber,
    extraBeds,
    extraBedCharge,
    extraBedChargeType,
    roomId,
    noRooms,
    roomName,
    roomPrice,
    type,
    tourId,
    roomImg,
    currency,
}) => {
    const { t } = useTranslation();
    const { replace } = useHistory();
    const dispatch = useDispatch();

    const notificationKey = 'rooms';

    const extraBedTotalPrice = extraBedChargeType === 'Percentage'
        ? ((extraBedCharge / 100) * roomPrice) * extraBeds
        : extraBeds * extraBedCharge;

    // open notification
    const openNotification = () => {
        roomListSelected.push({
            room_type: roomId,
            no_rooms: noRooms,
            adults: adultNumber,
            children: childNumber,
            infants: infantNumber,
            extra_beds: extraBeds,
            // frontend data
            room_img: roomImg,
            type_name: roomName,
            room_type_total_price: noRooms * roomPrice,
            extra_beds_total_price: extraBedTotalPrice,
            total_price: (noRooms * roomPrice) + extraBedTotalPrice,
            price_type: currency,
            extra_beds_price: extraBedCharge,
            extra_beds_price_type: extraBedChargeType,

        });
    };

    const listOfSelectedRoomPrice = roomListSelected.map((room) => (
        room.total_price
    ));

    const initialValue = 0;
    const totalPrice = listOfSelectedRoomPrice.reduce(
        (total, current) => total + current, initialValue,
    );

    useEffect(() => {
        if (roomListSelected.length !== 0) {
            notification.open({
                key: notificationKey,
                message:
                    (
                        <Space>
                            <span className="notification-title">{t('Selected Rooms')}</span>
                            <span className="notification-price">
                                {`${t('Total Price')}
                                ${currency}
                                ${totalPrice}`}
                            </span>
                        </Space>
                    ),
                description: (<RoomList
                    roomListSelected={roomListSelected}
                    dateStartEnd={dateStartEnd}
                    replace={replace}
                    dispatch={dispatch}
                    notificationKey={notificationKey}
                    type={type}
                    tourId={tourId}
                    currency={currency}
                />),
                placement: 'bottomRight',
                duration: 0,
            });
        } else {
            notification.close('rooms');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomListSelected, checkedRoom]);

    const removeRoom = () => {
        const result = roomListSelected.filter((room) => room.room_type !== roomId);
        setRoomListSelected(result);
    };

    return (
        <Form>
            <Form.Item>
                <Checkbox
                    checked={checkedRoom}
                    disabled={
                        noRooms === 0
                        || (adultNumber === 0
                            && childNumber === 0
                            && infantNumber === 0
                        )
                    }
                    onChange={(e) => {
                        setCheckedRoom(e.target.checked);
                        e.target.checked ? openNotification() : removeRoom();
                    }}
                >
                    {t('Select this room for Reservation')}
                </Checkbox>
            </Form.Item>
        </Form>

    );
};

export default SelectRoom;
