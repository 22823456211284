import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Space,
    Avatar,
    Typography,
    Divider,
} from 'antd';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { InfoCircleIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/b2b/agencyprofile/reducer';

const AgencyDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id: agencyId } = useParams();

    const agencyDetails = useSelector((state) => state.b2b.agencyprofile.agencyDetails);
    const {
        general_data: details,
        // supplier_for: supplierFor,
    } = agencyDetails !== null && agencyDetails;

    useEffect(() => {
        dispatch(actions.requestViewAgencyDetails(agencyId));
    }, [dispatch, agencyId]);

    return (
        <div className="agency-details">
            <div className="general-data">
                <Space size={20}>
                    <Avatar size={88} src={details?.icon} />
                    <div className="contact-details">
                        <Typography.Text className="agency-name">
                            {details?.name}
                        </Typography.Text>
                        <InlineDescription
                            label={t('Location')}
                            content={details?.location}
                        />
                        <InlineDescription
                            label={t('Email')}
                            content={details?.company_email}
                        />
                        <InlineDescription
                            label={t('Phone')}
                            content={details?.phone}
                        />
                    </div>
                </Space>
            </div>
            <div className="commission-data">
                <Space>
                    <Typography.Text>{t('Average Commission')}</Typography.Text>
                    <span className="commission-value">{details?.avg_b2b_commission}</span>
                </Space>
                <Divider />
                <InfoCircleIcon />
                <Typography.Text>
                    {t('This is the commission that this Supplier offers to his Vendors')}
                </Typography.Text>
            </div>
            <div className="more-details">
                <BlockDescription name={t('B2B Role')}>
                    <Typography.Text>{agencyDetails?.role}</Typography.Text>
                </BlockDescription>
                <Divider />
                <BlockDescription name={t('Supplies for')}>
                    {/* {supplierFor?.map((item) => (
                        <div>
                            <span className="value">{item.count}</span>
                            <span>{item.name}</span>
                        </div>
                    ))} */}
                </BlockDescription>
                <Divider />
                <BlockDescription name={t('Countries Operation')}>
                    <Typography.Text>
                        --
                    </Typography.Text>
                </BlockDescription>
            </div>
        </div>
    );
};

export default AgencyDetails;
