import BaseService from '@/services/BaseService';

class CustomersService extends BaseService {
    getAll = (filters) => this.post('/agency/agencycustomerlist', filters || { });

    getOne = (id) => this.get(`/agency/viewagencycustomer/${id}`);

    deleteOne = (id) => this.delete(`/agency/deleteagencycustomer/${id}`);

    addCustomer = (data) => this.post(
        '/agency/newagencycustomer',
        data,
    );
}

export default new CustomersService();
