import { useTranslation } from 'react-i18next';
import {
    Typography,
    Space,
    Row,
    Col,
    Input,
    DatePicker,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import GenderSelect from '@/components/GenderSelect';
import CountrySelect from '@/components/CountrySelect';
import { AddressBookIcon, CalendarIcon } from '@/assets/images/icons/screens';

const PersonDetails = ({ validations }) => {
    const { t } = useTranslation();
    return (
        <Row gutter={25}>
            <Col xs={24} className="details-title">
                <Space size={11}>
                    <AddressBookIcon />
                    <Typography.Text>
                        {t('Personal Details')}
                    </Typography.Text>
                </Space>
            </Col>

            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('First Name')}
                    name="name"
                    rules={validations.name}
                >
                    <Input />
                </MUIFormItem>
            </Col>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Last Name')}
                    name="surname"
                    rules={validations.surname}
                >
                    <Input />
                </MUIFormItem>
            </Col>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('ID Number')}
                    name="id_number"
                    rules={validations.id_number}
                >
                    <Input />
                </MUIFormItem>
            </Col>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Nationality')}
                    name="nationality"
                    rules={validations.nationality}
                >
                    <CountrySelect />
                </MUIFormItem>
            </Col>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Gender')}
                    name="gender"
                    rules={validations.gender}
                >
                    <GenderSelect placeholder={false} />
                </MUIFormItem>
            </Col>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Birthdate')}
                    name="birthdate"
                    rules={validations.birthdate}
                >
                    <DatePicker
                        placeholder=""
                        suffixIcon={<CalendarIcon />}
                    />
                </MUIFormItem>
            </Col>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Employed since')}
                    name="date_employed"
                    rules={validations.date_employed}
                >
                    <DatePicker suffixIcon={<CalendarIcon />} />
                </MUIFormItem>
            </Col>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Phone number')}
                    name="phone_number"
                    rules={validations.phone_number}
                >
                    <Input />
                </MUIFormItem>
            </Col>
            <Col xs={24}>
                <MUIFormItem
                    label={t('Address')}
                    name="address"
                    rules={validations.address}
                >
                    <Input />
                </MUIFormItem>
            </Col>
        </Row>
    );
};

export default PersonDetails;
