import {
    Avatar,
    Col,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import SectionLayout from '../../SectionLayout';

const TourCruise = ({ reservationData }) => {
    const { t } = useTranslation();
    const cabins = reservationData?.cabins || reservationData?.data;
    return cabins !== undefined && (
        <SectionLayout
            className="tour-details"
            icon={(
                <Avatar
                    size={60}
                    src={
                        reservationData.cruise?.image
                        || reservationData.image
                    }
                />
            )}
            leftCol={3}
            rightCol={21}
        >
            <Row>
                <Col xs={12}>
                    <Typography.Title level={5}>
                        {
                            reservationData.cruise?.name
                            || reservationData.name
                        }
                    </Typography.Title>

                    <Typography.Paragraph>
                        {t('Cruise Line')}
                        {': '}
                        {
                            reservationData.cruise?.line
                            || reservationData.line
                        }
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        {t('Duration')}
                        {': '}
                        {
                            reservationData.cruise?.date_start
                            || reservationData.date_start
                        }
                        {' - '}
                        {
                            reservationData.cruise?.date_end
                            || reservationData.date_end
                        }
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        {t('Departure Port')}
                        {': '}
                        {
                            reservationData.cruise?.departure_place
                            || reservationData.departure_place
                        }
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        {t('Arrival Port')}
                        {': '}
                        {
                            reservationData.cruise?.arrival_place
                            || reservationData.arrival_place
                        }
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        {t('Ship Name')}
                        {': '}
                        {
                            reservationData.cruise?.ship_name
                            || reservationData.ship_name
                        }
                    </Typography.Paragraph>
                </Col>

                <Col xs={12}>
                    <div className="cabins">
                        {cabins.map((cabin) => (
                            <div key={cabin.id || cabin.cabin_type}>
                                <Typography.Title level={5}>
                                    {cabin.type}
                                </Typography.Title>
                                <InlineDescription
                                    label={t('No. of cabins')}
                                    content={cabin.cabins_no}
                                />
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default TourCruise;
