import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
    Row,
    Col,
    Space,
    Divider,
    Typography,
    Select,
    Input,
    Switch,
    Button,
    Form,
} from 'antd';
import CountrySelect from '@/components/CountrySelect';
import { LocationIcon, SortIcon } from '@/assets/images/icons/screens';
import { B2BIcon } from '@/assets/images/icons/sidebar';
import { actions } from '@/redux/b2b/partner/create/reducer';
import AddVendorForm from './AddVendorForm';

const Filters = ({
    isSupplier,
    setIsSupplier,
    modalVisible,
    setModalVisible,
    filters,
    setFilters,
    partnerId,
    setPartnerId,
    isEdit,
    setIsEdit,
    totalItems,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className="my-partner-filters">
            <Form
                initialValues={filters}
                onFieldsChange={([changedField]) => {
                    const { name, value } = changedField;
                    setFilters(name[0], value);
                }}
            >
                <Row
                    justify="space-between"
                    align="middle"
                    className="base-filter"
                >
                    <Col className="switch-partner">
                        <Space size={25}>
                            <Typography.Text className={clsx(!isSupplier && 'color-blue')}>
                                {t('Vendors')}
                            </Typography.Text>
                            <Form.Item name="type" noStyle>
                                <Switch
                                    checked={isSupplier}
                                    onChange={() => setIsSupplier(!isSupplier)}
                                />
                            </Form.Item>
                            <Typography.Text className={clsx(isSupplier && 'color-blue')}>
                                {t('Suppliers')}
                            </Typography.Text>
                        </Space>
                    </Col>
                    {!isSupplier && (
                        <Col>
                            <Button
                                onClick={() => {
                                    setModalVisible(true);
                                    setIsEdit(false);
                                    dispatch(actions.clearAgencyState());
                                }}
                                className="add-vendor-btn"
                            >
                                <B2BIcon />
                                {t('Add Vendor')}
                            </Button>
                        </Col>
                    )}
                </Row>
                <Divider dashed />
                <Row gutter={[30, 30]} align="middle">
                    <Col xs={24} md={8} xl={5}>
                        <Form.Item name="location" noStyle>
                            <Input
                                placeholder={t('Filter by Business Location')}
                                suffix={<LocationIcon />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} xl={5}>
                        <Form.Item name="country" noStyle>
                            <CountrySelect placeholder={t('Filter by Country Operation')} />
                            {/* <Input
                                placeholder={t('Filter by Country Operation')}
                                suffix={<LocationIcon />}
                            /> */}
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="name" noStyle>
                            <Input.Search
                                placeholder={t('Search with keyword (agency name, product name...)')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={18} xl={3} className="total-partner">
                        <Typography.Text>
                            {!isSupplier ? (
                                `${totalItems} ${totalItems === '1' ? t('vendor found') : t('vendors found')}`
                            ) : (
                                `${totalItems} ${totalItems === '1' ? t('supplier found') : t('suppliers found')}`
                            )}
                        </Typography.Text>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <Form.Item noStyle>
                            <Select
                                className="sort-partner"
                                placeholder={t('Sort by')}
                                suffixIcon={<SortIcon />}
                            >
                                <Select.Option value={1}>type</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <AddVendorForm
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                partnerId={partnerId}
                setPartnerId={setPartnerId}
                isEdit={isEdit}
            />
        </div>
    );
};

export default Filters;
