import {
    Row,
    Col,
    Card,
    Avatar,
} from 'antd';
import RoomItem from './RoomItem';
import CabinItem from './CabinItem';

const ReservationList = ({
    roomListSelected,
    setRoomListSelected,
    reservationList,
    dateStartEnd,
    cruiseStartDate,
    cruiseEndDate,
    type,
    tourId,
}) => {
    return (
        <Row className="reservation-list" gutter={[0, 31]}>
            {reservationList.map((item) => (
                <Col xs={24} key={item.id}>
                    <Card>
                        <Card.Meta
                        //  Will be replaced with a photo gallery component
                            avatar={(
                                <Avatar
                                    shape="square"
                                    src={item.images[0]?.path}
                                />
                            )}
                            title={item.type_name}
                            description={
                                type === 'Tour Cruise' ? (
                                    <CabinItem
                                        roomListSelected={roomListSelected}
                                        setRoomListSelected={setRoomListSelected}
                                        startDate={cruiseStartDate}
                                        endDate={cruiseEndDate}
                                        item={item}
                                        type={type}
                                        tourId={tourId}
                                    />
                                ) : (
                                    <RoomItem
                                        roomListSelected={roomListSelected}
                                        setRoomListSelected={setRoomListSelected}
                                        dateStartEnd={dateStartEnd}
                                        item={item}
                                        type={type}
                                        tourId={tourId}
                                    />
                                )
                            }
                        />
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default ReservationList;
