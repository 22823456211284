import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';

const ViewWidgetCategories = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            <Divider />

            <span className="extras-title tour-info-section-title">{t('Widget Category')}</span>

            <Row className="widget-categories">
                {data?.map((category) => (
                    <Col key={category.id}>
                        <div className="category">
                            {category.name}
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default ViewWidgetCategories;
