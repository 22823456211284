import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    newPassword: {
        name: 'New Password',
        type: 'string',
        min: 6,
        max: 40,
        required: true,
    },
    confirmNewPassword: {
        name: 'Confirm New Password',
        type: 'string',
        min: 6,
        max: 40,
        required: true,
    },
};

export default buildValidationsFromConfig(config);
