import { useTranslation } from 'react-i18next';
import { Row, Col, Checkbox } from 'antd';

import {
    defaultLabelParamName,
    defaultValueParamName,
    defaultGutter,
} from './config';

const BaseMultiSelect = ({
    gutter = defaultGutter,
    col = 8,
    options,
    labelParamName = defaultLabelParamName,
    valueParamName = defaultValueParamName,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <Checkbox.Group className="checkbox-multi-select" {...rest}>
            <Row gutter={gutter}>
                {options?.map((option) => (
                    <Col key={option[valueParamName]} lg={col}>
                        <Checkbox value={option[valueParamName]}>
                            {t(option[labelParamName])}
                        </Checkbox>
                    </Col>
                ))}
            </Row>
        </Checkbox.Group>
    );
};

export default BaseMultiSelect;
