import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    discount: {
        name: 'Discount',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
