import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get country list
    requestGetCountryList: ['filters'],
    getCountryListSucceeded: ['data'],
    getCountryListFailed: ['error'],
});

export default createReducer(initialState, {
    // country list
    [types.REQUEST_GET_COUNTRY_LIST]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_COUNTRY_LIST_SUCCEEDED]: (
        state,
        {
            data,
        },
    ) => ({
        ...state,
        countries: data,
        loading: false,
    }),
    [types.GET_COUNTRY_LIST_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };
