import {
    ArrowBottomIcon,
    ArrowTopIcon,
} from '@/assets/images/icons/screens';

export const returnArrow = (percentage, text) => {
    if (percentage?.includes('+')) {
        return (
            <>
                <ArrowTopIcon />
                <span className="ant-card-head_order_variation">
                    {percentage}
                </span>
                <span className="ant-card-head_order_variation_timeline">
                    {text}
                </span>
            </>
        );
    }
    if (percentage?.includes('-')) {
        return (
            <>
                <ArrowBottomIcon />
                <span className="ant-card-head_order_variation_decrease">
                    {percentage}
                </span>
                <span className="ant-card-head_order_variation_timeline">
                    {text}
                </span>
            </>
        );
    }
    return null;
};
