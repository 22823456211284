import { useTranslation } from 'react-i18next';
import { Divider, Row, Col } from 'antd';
import { TagsIcon } from '@/assets/images/icons/screens';
import BlockDescription from '@/components/Information/WithImage/Block';

const ServicesAndFacilities = ({ facilities, services }) => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col xs={24} className="tour-info-section-title">
                {t('Facilities')}
            </Col>

            <Col xs={24}>
                <Row>
                    {facilities.map((facility) => (
                        <Col className="tour-info-section-content" key={facility.id} xl={8} lg={12} md={8} xs={24} sm={12}>
                            {facility.name}
                        </Col>
                    ))}
                </Row>
            </Col>

            <Col xs={24}>
                <Divider />
                <div className="tour-info-section-title">{t('Services')}</div>
            </Col>

            <Col xs={24}>
                <Row justify="space-between" className="accommodation-block-details">
                    {services.map((service) => (
                        <Col key={service.id} lg={8} xs={24} sm={12} md={12}>
                            <BlockDescription
                                spaceSize={14}
                                icon={<TagsIcon />}
                                label={service.name}
                                content={`${service.price} / ${t('person')}`}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default ServicesAndFacilities;
