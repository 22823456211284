import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Typography,
    Space,
    Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { selectors } from '@/redux/invoice/view/model';
import { actions } from '@/redux/invoice/view/reducer';
import CustomInvoicePreview from '@/components/Invoice/CustomInvoicePreview';
import ActionIcon from '@/components/ActionIcon';
import LinkButton from '@/components/LinkButton';

import {
    ArrowLeftIcon,
    CloseCircleIcon,
    // EditIcon,
} from '@/assets/images/icons/screens';

const ViewCustomInvoice = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const invoiceDetails = useSelector(selectors.invoiceDetails);
    const invoiceCustomer = useSelector(selectors.invoiceCustomer);

    useEffect(() => {
        dispatch(actions.requestViewInvoice(id));
    }, [dispatch, id]);

    return (
        <div className="finished view-custom-invoice">
            <Row>
                <Col>
                    <Space size={12}>
                        <LinkButton
                            className="go-back"
                            to="/app/invoices"
                        >
                            <ArrowLeftIcon />
                        </LinkButton>

                        <Typography.Text>
                            {t('Back')}
                        </Typography.Text>
                    </Space>
                </Col>
            </Row>

            <div className="block">
                <Row justify="space-between" className="invoice-title">
                    <Col>
                        <Typography.Title level={5}>
                            1
                        </Typography.Title>
                    </Col>

                    <Col>
                        <Space size={30}>
                            <ActionIcon />
                        </Space>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <CustomInvoicePreview
                        createMode={false}
                        invoiceDetails={invoiceDetails}
                        invoiceCustomer={invoiceCustomer}
                    />
                </Row>

                <Row className="action-step">
                    <Col xs={12}>
                        <LinkButton
                            to="/app/invoices"
                            className="cancel"
                            block
                        >
                            <Space size={22}>
                                <CloseCircleIcon />
                                <Typography.Text>
                                    {t('Cancel').toUpperCase()}
                                </Typography.Text>
                            </Space>
                        </LinkButton>
                    </Col>
                    {/* <Col xs={12}>
                        <LinkButton
                            to={`/app/invoices/custom/${id}/edit`}
                            className="edit"
                            block
                        >
                            <Space size={22}>
                                <EditIcon />
                                <Typography.Text>
                                    {t('Edit').toUpperCase()}
                                </Typography.Text>
                            </Space>
                        </LinkButton>
                    </Col> */}
                </Row>
            </div>
        </div>
    );
};

export default ViewCustomInvoice;
