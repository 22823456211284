import { useTranslation } from 'react-i18next';
import { Space, Divider } from 'antd';
import DataContainer from '@/components/DataContainer';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { TimeInDays, TimeInMinHours } from '@/helpers/timeFormat';
import { useCurrency } from '@/hooks/useCurrency';

const TimeAndDuration = ({ data, price }) => {
    const { t } = useTranslation();
    const { icon: currency } = useCurrency();

    // group data by time and price
    const groupedData = {};

    data?.duration_type === 'Hours' && data.time?.forEach((day) => {
        day.times.forEach((time) => {
            const key = time.time.concat('_').concat(time.price);
            if (!groupedData[key]) {
                groupedData[key] = [];
            }
            groupedData[key].push({
                name: day.name,
                id: day.id,
            });
        });
    });

    // group data by days
    const finalGroupedData = [];

    Object.keys(groupedData).forEach((key) => {
        const daysArray = groupedData[key].map((day) => day.name);
        const daysKey = daysArray.join(',');

        const existingGroup = finalGroupedData.find((group) => group.days === daysKey);

        if (existingGroup) {
            existingGroup.data.push({
                time: key.split('_')[0],
                price: key.split('_')[1],
                days: groupedData[key],
            });
        } else {
            finalGroupedData.push({
                days: daysKey,
                data: [{
                    time: key.split('_')[0],
                    price: key.split('_')[1],
                    days: groupedData[key],
                }],
            });
        }
    });

    return (
        (data && data?.duration_from !== null) && (
            <DataContainer title={t('Time & Duration')}>
                {data.duration_type === 'Hours' ? (
                    <>
                        <BlockDescription
                            name={t('Duration in min/hours')}
                        >
                            <Space split=" - ">
                                <TimeInMinHours value={data.duration_from} />
                                <TimeInMinHours value={data.duration_to} />
                            </Space>
                        </BlockDescription>
                        <Divider dashed />
                        <Space direction="vertical" className="time-duration-wrapper">
                            {finalGroupedData.map((group, i) => (
                                <Space key={i} direction="vertical">
                                    <BlockDescription name={t('When')}>
                                        {group.days}
                                    </BlockDescription>
                                    <BlockDescription name={`${t('How many times in day')}?`}>
                                        {group.data.length}
                                        {' '}
                                        {group.data.length === 1 ? t('time') : t('times')}
                                    </BlockDescription>
                                    <BlockDescription name={t('Start time')}>
                                        {group.data.map((item, index) => (
                                            <div key={index}>
                                                {`${item.time}(${currency}${item.price}/${item.price === price ? t('Default Price') : t('person')})`}
                                            </div>
                                        ))}
                                    </BlockDescription>
                                    <Divider dashed />
                                </Space>
                            ))}
                        </Space>
                    </>
                ) : (
                    <>
                        <BlockDescription
                            name={t('Duration in days')}
                        >
                            <Space split=" - ">
                                <TimeInDays value={data.duration_from} />
                                <TimeInDays value={data.duration_to} />
                            </Space>
                        </BlockDescription>
                        <Divider dashed />
                        <BlockDescription name={t('Start time')}>
                            {data?.time?.map((item, i) => (
                                <div key={i}>
                                    {item.time.concat('(', currency).concat(item.price).concat('/')
                                        .concat(item.price === price ? t('Default Price') : t('person'), ')')}
                                </div>
                            ))}
                        </BlockDescription>
                    </>
                )}
            </DataContainer>
        )
    );
};

export default TimeAndDuration;
