import { useState, useCallback } from 'react';
import {
    Row,
    Col,
    Input,
    Button,
    Space,
    // Form,
} from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PlusIcon } from '@/assets/images/icons/screens';

const CreateSection = ({
    data,
    mutate,
    buttonText,
    addNewItem,
    tourcategory,
    accommodationType,
}) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState('');
    const [name, setName] = useState('');

    const close = useCallback(() => {
        setName('');
        setIsOpen(false);
    }, [setName, setIsOpen]);

    const save = useCallback(() => {
        if (accommodationType) {
            addNewItem(accommodationType, {
                name,
            })
                .then(() => {
                    mutate();
                    close();
                });
        } else {
            addNewItem({
                name,
                category: tourcategory,
            })
                .then(() => {
                    mutate();
                    close();
                });
        }
        // .catch((error) => { });
    }, [name, mutate, data, close]);

    return (
        <div className="create-section">
            <div className={clsx('input-section', isOpen && 'open')}>
                <Row
                    gutter={25}
                    justify="space-between"
                >
                    <Col lg={12} xs={24}>
                        <Input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Col>

                    <Col>
                        <Space>
                            {/* className="button delete-button" */}
                            <Button type="primary" onClick={save}>
                                {t('Save')}
                            </Button>

                            <Button type="danger" onClick={close}>
                                {t('Close')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </div>

            {!isOpen && (
                <Button
                    type="text"
                    className="text-circle-button"
                    onClick={() => setIsOpen(true)}
                >
                    <div className="create-section_button">
                        <PlusIcon />
                    </div>
                    {t(buttonText)}
                </Button>
            )}
        </div>
    );
};

export default CreateSection;
