export const getMinValidations = (field, min) => ({
    min,
    message: `${field} has to be at least ${min} characters`,
});

export const getMaxValidations = (field, max) => ({
    max,
    message: `${field} cannot be more than ${max} characters`,
});

export const getTypeValidations = (field, type) => {
    if (typeof type === 'string') {
        return { type, message: `${field} has to be ${type}` };
    }
    return type;
};

export const getRequiredValidations = (field) => ({
    required: true,
    message: `${field} can't be empty`,
});

const validatorsMap = {
    min: getMinValidations,
    max: getMaxValidations,
    type: getTypeValidations,
    required: getRequiredValidations,
};

const validateField = (field) => Object
    .keys(field)
    .filter((validationType) => (
        validatorsMap[validationType]
        && ![null, undefined, false].includes(field[validationType])
    ))
    .map((validationType) => validatorsMap[validationType](
        field.name,
        field[validationType],
    ));

export const buildValidationsFromConfig = (config) => Object
    .keys(config)
    .reduce((validations, fieldKey) => ({
        ...validations,
        [fieldKey]: validateField(config[fieldKey]),
    }), {});

export const translateValidations = (t, rules) => rules.map((rule) => ({
    ...rule,
    message: t(rule.message),
}));
