import { combineReducers } from 'redux';

import partner, {
    types as partnerTypes,
    actions as partnerActions,
} from './partner/reducer';

import supplier, {
    types as supplierTypes,
    actions as supplierActions,
} from './supplier/reducer';

import request, {
    types as requestTypes,
    actions as requestActions,
} from './request/reducer';

import share, {
    types as shareTypes,
    actions as shareActions,
} from './share/reducer';

import agencyprofile, {
    types as agencyprofileTypes,
    actions as agencyprofileActions,
} from './agencyprofile/reducer';

const actions = {
    ...partnerActions,
    ...supplierActions,
    ...requestActions,
    ...shareActions,
    ...agencyprofileActions,
};

const types = {
    ...partnerTypes,
    ...supplierTypes,
    ...requestTypes,
    ...shareTypes,
    ...agencyprofileTypes,
};

export { types, actions };

const b2bReducer = combineReducers({
    partner,
    supplier,
    request,
    share,
    agencyprofile,
});

export default b2bReducer;
