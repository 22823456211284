import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Tooltip,
    Button,
    Card,
    Divider,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { InfoCircleIcon } from '@/assets/images/icons/screens';

const CompanyInfo = ({ data }) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);

    return (
        <Tooltip
            color="#ffffff"
            open={visible}
            placement="leftTop"
            overlayClassName="company-info-tooltip"
            title={(
                <Card
                    className="info-card"
                    bordered={false}
                    title={data.company_name}
                >
                    <Row gutter={10}>
                        <Col xs={24}>
                            <BlockDescription name={t('Registration Number')}>
                                {data.vat_number}
                            </BlockDescription>
                            <Divider />
                        </Col>
                        <Col xs={24}>
                            <BlockDescription name={t('Address')}>
                                {data.address}
                            </BlockDescription>
                            <Divider />
                        </Col>
                        <Col xs={24}>
                            <BlockDescription name={t('Phone')}>
                                {data.company_phone}
                            </BlockDescription>
                            <Divider />
                        </Col>
                        <Col xs={24}>
                            <BlockDescription name={t('Email')}>
                                {data.company_email}
                            </BlockDescription>
                        </Col>
                    </Row>
                </Card>
            )}
        >
            <Button
                className="tooltip-button"
                type="text"
                onClick={() => setVisible(!visible)}
            >
                <InfoCircleIcon />
                {t('Company Info').toUpperCase()}
            </Button>
        </Tooltip>
    );
};

export default CompanyInfo;
