import { createActions } from 'reduxsauce';
import CRUD from '@/helpers/CRUD';
import { destructureCRUDMappedActions } from '@/helpers/redux';

export const groupsRedux = CRUD('tourTripGroups');

const {
    types: groupsTypes,
    actions: groupsActions,
    reducer: groupsReducer,
    mappedTypes: groupsMappedTypes,
} = groupsRedux;

const { Types, Creators } = createActions({
    // fetch specified trip
    requestViewTourTrip: ['id'],
    viewTourTripSucceeded: [
        'generalData',
        'iteneraryData',
    ],
    viewTourTripFailed: ['error'],

    // search for groups
    requestFetchTourTripGroups: ['id', 'filters', 'isFetchingMore'],
    fetchTourTripGroupsSucceeded: ['groups'],
    fetchTourTripGroupsFailed: ['error'],

    // fetch more groups
    fetchMoreTourTripGroups: ['filters'],
    fetchMoreTourTripGroupsSucceeded: ['groups'],
    fetchMoreTourTripGroupsFailed: ['error'],

    // save filter
    saveTripFilterData: ['filters'],

    // view group details
    requestViewTourTripGroup: ['id'],
    viewTourTripGroupSucceeded: ['data'],
    viewTourTripGroupFailed: ['error'],

    // clear group details
    clearGroupDetails: ['null'],

    // clear trip view state
    clearTripViewState: ['null'],
});

export const types = { ...Types, ...groupsTypes };

export const actions = {
    ...Creators,
    ...destructureCRUDMappedActions(groupsMappedTypes, groupsActions),
};

const adaptStateForCRUDReducer = (state) => {
    return {
        data: state.view.groups,
        totalItems: state.view.groups.length,
        ...state.view.groups,
    };
};

const getNewStateWithAdaptedReducer = (state, action) => {
    const reducerState = adaptStateForCRUDReducer(state);
    const reducer = groupsReducer[action.type];
    const newState = reducer(reducerState, action);
    delete newState.fetchingError;
    delete newState.fetchingLoading;

    return {
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                ...newState,
            },
        },
    };
};

export default {
    // fetch specified trip
    [types.REQUEST_VIEW_TOUR_TRIP]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_TOUR_TRIP_SUCCEEDED]: (
        state,
        {
            generalData,
            iteneraryData,
        },
    ) => {
        return {
            ...state,
            view: {
                ...state.view,
                generalInfo: generalData,
                itenerary: iteneraryData,
                loading: false,
            },
        };
    },
    [types.VIEW_TOUR_TRIP_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // search for groups
    [types.REQUEST_FETCH_TOUR_TRIP_GROUPS]: (state, { isFetchingMore }) => ({
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                fetchingLoading: !isFetchingMore,
                fetchingError: null,
            },
        },
    }),
    [types.FETCH_MORE_TOUR_TRIP_GROUPS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                isLoadingMore: true,
                isLoadingMoreError: null,
            },
        },
    }),
    [types.FETCH_TOUR_TRIP_GROUPS_SUCCEEDED]: (state, { groups }) => ({
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                data: groups,
                totalItems: groups.length,
                fetchingLoading: false,
                fetchingError: null,
                hasMore: true,
            },
        },
    }),
    [types.FETCH_MORE_TOUR_TRIP_GROUPS_SUCCEEDED]: (state, { groups }) => ({
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                data: [...state.view.groups.data, ...groups],
                totalItems: [...state.view.groups.data, ...groups].length,
                fetchingLoading: false,
                fetchingError: null,
                isLoadingMore: false,
                hasMore: groups.length !== 0,
            },
        },
    }),
    [types.FETCH_TOUR_TRIP_GROUPS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                fetchingLoading: false,
                fetchingError: error,
            },
        },
    }),
    [types.FETCH_MORE_TOUR_TRIP_GROUPS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                isFetchingMore: false,
                isLoadingMoreError: error,
            },
        },
    }),

    // save filters
    [types.SAVE_TRIP_FILTER_DATA]: (state, { filters }) => ({
        ...state,
        view: {
            ...state.view,
            groups: {
                ...state.view.groups,
                filterData: filters,
            },
        },
    }),

    // view trip group
    [types.REQUEST_VIEW_TOUR_TRIP_GROUP]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_TOUR_TRIP_GROUP_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            groupDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_TOUR_TRIP_GROUP_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear group details
    [types.CLEAR_GROUP_DETAILS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            groupDetails: null,
        },
    }),

    // clear trip view state
    [types.CLEAR_TRIP_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            generalInfo: null,
            itenerary: null,
            groups: {
                ...state.view.groups,
                data: [],
                totalItems: 0,
                fetchingLoading: false,
                fetchingError: null,
                hasMore: true,
            },
        },
    }),

    [groupsMappedTypes.create.request]: getNewStateWithAdaptedReducer,
    [groupsMappedTypes.create.succeeded]: getNewStateWithAdaptedReducer,
    [groupsMappedTypes.create.failed]: getNewStateWithAdaptedReducer,

    [groupsMappedTypes.update.request]: getNewStateWithAdaptedReducer,
    [groupsMappedTypes.update.succeeded]: getNewStateWithAdaptedReducer,
    [groupsMappedTypes.update.failed]: getNewStateWithAdaptedReducer,

    [groupsMappedTypes.delete.request]: getNewStateWithAdaptedReducer,
    [groupsMappedTypes.delete.succeeded]: getNewStateWithAdaptedReducer,
    [groupsMappedTypes.delete.failed]: getNewStateWithAdaptedReducer,
};
