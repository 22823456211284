import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({

    // go to step
    goToCreateCustomInvoiceStep: ['step'],

    // add service
    addInvoiceService: ['data'],

    // delete service
    requestDeleteService: ['key'],

    // fetch for edit
    requestFetchCustomInvoiceData: ['id'],
    fetchCustomInvoiceDataSucceeded: [
        'generalData',
        'invoiceToData',
        'invoiceExtraData',
    ],
    fetchCustomInvoiceDataFailed: ['errors'],

    // submit first step
    requestSubmitInvoiceServices: ['data'],
    submitInvoiceServicesSucceeded: ['data'],
    submitInvoiceServicesFailed: ['errors'],

    // submit second step
    requestSubmitCustomInvoiceDetails: ['data'],
    submitCustomInvoiceDetailsSucceeded: ['data'],
    submitCustomInvoiceDetailsFailed: ['errors'],

    // submit third step
    requestSubmitInvoiceExtra: ['data'],
    submitInvoiceExtraSucceeded: ['data'],
    submitInvoiceExtraFailed: ['errors'],

    // get existing services
    requestGetExistingServices: ['filters'],
    getExistingServicesSucceeded: ['data'],
    getExistingServicesFailed: ['errors'],

    // get service details
    requestGetServiceDetails: ['id'],
    getServiceDetailsSucceeded: ['data'],
    getServiceDetailsFailed: ['errors'],

    // clear service details
    clearServiceDetails: null,

    // clear custom invoice state
    clearCustomInvoiceState: null,

    // clear errors
    clearCustomInvoiceErrors: null,
});

export const removeSelectedKey = (items, deletedItemKey, identityKey = 'key') => items.filter((item) => item[identityKey] !== deletedItemKey);

export default {

    // go to step
    [types.GO_TO_CREATE_CUSTOM_INVOICE_STEP]: (state, { step }) => ({
        ...state,
        createcustom: { ...state.createcustom, step },
    }),

    // add service
    [types.ADD_INVOICE_SERVICE]: (state, { data }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            services: [...state.createcustom.services, data],
        },
    }),

    // delete service
    [types.REQUEST_DELETE_SERVICE]: (state, { key }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            services: removeSelectedKey(state.createcustom.services, key),
        },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_CUSTOM_INVOICE_DATA]: (state) => ({
        ...state,
        createcustom: { ...state.createcustom, loading: true },
    }),
    [types.FETCH_CUSTOM_INVOICE_DATA_SUCCEEDED]: (
        state,
        {
            generalData,
            invoiceToData,
            invoiceExtraData,
        },
    ) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            loading: false,
            step: 1,
            billedFor: generalData,
            invoiceTo: invoiceToData,
            extra: invoiceExtraData,
        },
    }),
    [types.FETCH_CUSTOM_INVOICE_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        createcustom: { ...state.createcustom, loading: false, errors },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_INVOICE_SERVICES]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            sumbitting: true,
            error: null,
        },
    }),
    [types.SUBMIT_INVOICE_SERVICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            step: 1,
            billedFor: data,
            sumbitting: false,
        },
    }),
    [types.SUBMIT_INVOICE_SERVICES_FAILED]: (state, { errors }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            errors,
            sumbitting: false,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_CUSTOM_INVOICE_DETAILS]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            sumbitting: true,
            error: null,
        },
    }),
    [types.SUBMIT_CUSTOM_INVOICE_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            step: 2,
            invoiceTo: data,
            sumbitting: false,
        },
    }),
    [types.SUBMIT_CUSTOM_INVOICE_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            errors,
            sumbitting: false,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_INVOICE_EXTRA]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            sumbitting: true,
            error: null,
        },
    }),
    [types.SUBMIT_INVOICE_EXTRA_SUCCEEDED]: (state, { data }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            step: 3,
            extra: data,
            sumbitting: false,
        },
    }),
    [types.SUBMIT_INVOICE_EXTRA_FAILED]: (state, { errors }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            errors,
            sumbitting: false,
        },
    }),

    // get existing services
    [types.REQUEST_GET_EXISTING_SERVICES]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_EXISTING_SERVICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            loading: false,
            existingServices: data,
        },
    }),
    [types.GET_EXISTING_SERVICES_FAILED]: (state, { errors }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            loading: false,
            errors,
        },
    }),

    // get service details
    [types.REQUEST_GET_SERVICE_DETAILS]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_SERVICE_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            loading: false,
            serviceDetails: data,
        },
    }),
    [types.GET_SERVICE_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            loading: false,
            errors,
        },
    }),

    // clear service details
    [types.CLEAR_SERVICE_DETAILS]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            serviceDetails: null,
        },
    }),

    // clear custom invoice state
    [types.CLEAR_CUSTOM_INVOICE_STATE]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            services: [],
            billedFor: null,
            invoiceTo: null,
            extra: null,
            loading: false,
            errors: null,
            submitting: false,
            step: 0,
            completedStep: null,

            existingServices: [],
            serviceDetails: null,
        },
    }),

    // clear errors
    [types.CLEAR_CUSTOM_INVOICE_ERRORS]: (state) => ({
        ...state,
        createcustom: {
            ...state.createcustom,
            errors: null,
        },
    }),
};
