import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import InvoiceParticipants from '@/components/Invoice/InvoiceParticipants';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { actions } from '@/redux/invoice/create/reducer';
import { selectors } from '@/redux/invoice/create/model';
import { dateFormat } from '@/helpers/dateFormat';

const formFields = [
    'fullname',
    'nationality',
    'birthdate',
    'gender',
];

// format data for submit
const formatGuests = (participants) => participants
    .map((participant) => ({
        fullname: participant.firstName.concat(' ', participant.lastName),
        nationality: participant.nationality?.value,
        birthdate: participant.birthdate?.format(dateFormat),
        gender: participant.gender,
    }));

// format clients as initial data
const formatClient = (client) => {
    const [name, surname] = client.fullname.split(' ');
    return {
        firstName: name,
        lastName: surname,
        nationality: {
            value: client.country_id,
            label: client.country,
        },
        birthdate: moment(client.birthdate),
        gender: client.gender,
    };
};

const Participants = ({ form }) => {
    const dispatch = useDispatch();

    const participants = useSelector(selectors.invoiceParticipants);

    const onFinish = useCallback((values) => {
        const adults = values.adults !== undefined ? values.adults : [];
        const children = values.children !== undefined ? values.children : [];
        const infants = values.infants !== undefined ? values.infants : [];
        const guests = [...adults, ...children, ...infants];

        const data = {
            clients: guests ? formatGuests(guests) : [],
        };
        dispatch(actions.requestSubmitInvoiceParticipants(data));
    }, [dispatch]);

    const adultList = [];
    const childList = [];
    const infantList = [];

    participants?.forEach((participant) => {
        const participantBirthdate = (participant.birthdate).slice(0, 4);
        const participantAge = moment().format('YYYY') - participantBirthdate;
        if (participantAge > 12) {
            adultList.push(participant);
        } else if (participantAge > 3 && participantAge < 13) {
            childList.push(participant);
        } else {
            infantList.push(participant);
        }
    });

    useEffect(() => {
        let initialData = {};
        if (participants) {
            initialData = {
                adults: adultList.map((adult) => (
                    formatClient(adult)
                )),
                children: childList.map((child) => (
                    formatClient(child)
                )),
                infants: infantList.map((infant) => (
                    formatClient(infant)
                )),
            };
        } form.setFieldsValue(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants, form]);

    const mergeClients = [
        {
            adults: adultList.length,
        },
        {
            children: childList.length,
        },
        {
            infants: infantList.length,
        },
    ];

    const listOfCountedClients = mergeClients.map((client) => ({
        [Object.keys(client)[0]]: Object.values(client)[0],
    }));

    const errors = useSelector(selectors.errors);
    useApiValidationsForForm({ form, formFields, errors });

    return (
        <InvoiceParticipants
            form={form}
            onFinish={onFinish}
            usedFor="invoice"
            clients={listOfCountedClients}
        />
    );
};

export default Participants;
