import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Space, Typography, Input } from 'antd';
import SearchBox from '../SearchBox';
import DomainWhitelist from './DomainWhitelist';

const GeneratedWidget = ({ checkedId, checkedServices }) => {
    const { t } = useTranslation();

    // TODO change format of widgetDiv
    const searchWidgetDiv = useSelector((state) => state.integrations.bookingEngine.searchWidget);
    const resultWidgetDiv = useSelector((state) => state.integrations.bookingEngine.resultWidget);
    const customWidget = useSelector((state) => state.integrations.bookingEngine.customWidget);

    const customWidgetDiv = customWidget !== null && typeof (customWidget) === 'object'
        ? customWidget.message
        : customWidget;

    return (
        <div className="generated-widgets">
            <Space className="title-1" size={9}>
                <span id="section-number">1</span>
                <Typography.Text>{t('Availability search box')}</Typography.Text>
            </Space>
            {checkedId === 1 ? (
                <>
                    {checkedServices.length === 0 || checkedServices.length === 1 ? (
                        <SearchBox
                            leftCol={22}
                            rightCol={2}
                            checkedId={checkedId}
                            formSpaceSize={15}
                        />
                    ) : (
                        checkedServices.map((service) => (
                            <div className="search-box-s2">
                                <Typography.Text className="search-box-title">
                                    {`${t('Availability search box for')} ${t(service.name)}`}
                                </Typography.Text>
                                <SearchBox
                                    key={service.id}
                                    leftCol={22}
                                    rightCol={2}
                                    checkedId={checkedId}
                                    formSpaceSize={15}
                                />
                            </div>
                        ))
                    )}
                </>
            ) : (
                <SearchBox
                    leftCol={22}
                    rightCol={2}
                    checkedId={checkedId}
                    checkedServices={checkedServices}
                    dynamicMenu={true}
                    menuSpaceSize={25}
                    formSpaceSize={15}
                />
            )}
            <Space className="title-2" size={9}>
                <span id="section-number">2</span>
                <Typography.Text>
                    {t('Script Search box')}
                </Typography.Text>
            </Space>
            <Input.TextArea
                rows={6}
                readOnly
                value={searchWidgetDiv}
            />
            <Space className="title-3" size={9}>
                <span id="section-number">3</span>
                <Typography.Text>
                    {t('Script Search Result Page')}
                </Typography.Text>
            </Space>
            <Input.TextArea
                rows={6}
                readOnly
                value={resultWidgetDiv}
                className="result-widget"
            />
            <div className="info-text">
                <p>{t("To integrate widget results, set the widget on a new page at '/widget-results'")}</p>
            </div>
            <Space className="title-4" size={9}>
                <span id="section-number">4</span>
                <Typography.Text>
                    {t('Script Featured products by selected preview mode')}
                </Typography.Text>
            </Space>
            <Input.TextArea
                rows={6}
                readOnly
                value={customWidgetDiv}
            />
            <Space className="title-5" size={9}>
                <span id="section-number">5</span>
                <Typography.Text>
                    {t('Domain whitelist')}
                </Typography.Text>
            </Space>
            <DomainWhitelist strDiv={searchWidgetDiv} />
        </div>
    );
};

export default GeneratedWidget;
