import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view flight data
    requestViewFlight: ['id'],
    viewFlightSucceeded: ['data'],
    viewFlightFailed: ['error'],
});

export default {
    // view flight data
    [types.REQUEST_VIEW_FLIGHT]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_FLIGHT_SUCCEEDED]: (
        state,
        {
            data,
        },
    ) => ({
        ...state,
        view: {
            ...state.view,
            ticketData: data,
            loading: false,
        },
    }),
    [types.VIEW_FLIGHT_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),
};
