import {
    Row,
    Col,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ExtraBedIcon } from '@/assets/images/icons/screens';

import SectionLayout from '../SectionLayout';

const ExtraBed = ({
    rooms,
    invoiceCharge,
    currency,
    hasExtraBed,
}) => {
    const { t } = useTranslation();

    return hasExtraBed && (
        <SectionLayout
            className="extra-bed"
            icon={<ExtraBedIcon />}
            leftCol={1}
            rightCol={23}
        >
            <Row justify="space-between">
                <Col className="list">
                    <Typography.Title level={5}>
                        {t('Extra Bed')}
                    </Typography.Title>

                    {rooms.map((room) => (
                        <Typography.Paragraph key={room.id || room.room_type}>
                            {`
                                ${room.extra_beds} ${t('Extra Bed')}
                                ( + ${room.extra_beds_price} ${room.extra_beds_price_type || room.price_type} ${t('over room price')})
                            `}
                        </Typography.Paragraph>
                    ))}
                </Col>

                <Col>
                    <Typography.Text className="total-price">
                        {`${currency}${invoiceCharge?.extra_bed_price || '0.00'}`}
                    </Typography.Text>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default ExtraBed;
