import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    Checkbox,
    Radio,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import { selectors } from '@/redux/accommodation/hotel/create/model';

const ExtraBed = ({ currency }) => {
    const { t } = useTranslation();

    const options = useMemo(() => ([
        { label: t('%'), value: 4 },
        { label: t(currency), value: 5 },
    ]), [t]);

    const room = useSelector(selectors.roomDetails);

    const [hasExtraBed, setHasExtraBed] = useState(false);
    const [extraBedNo, setExtraBedNo] = useState(0);

    useEffect(() => {
        (room?.extra_beds !== null && room?.extra_beds !== undefined)
        && setHasExtraBed(true);
    }, [room]);

    return (
        <>
            <Checkbox
                className="create-room-checkbox"
                checked={hasExtraBed}
                onChange={(e) => setHasExtraBed(e.target.checked)}
            >
                {t('Add Extra Bed')}
            </Checkbox>
            {hasExtraBed && (
                <Row gutter={[28, 0]}>
                    <Col lg={12} xs={12}>
                        <Form.Item
                            name="extra_beds"
                            // rules={validations.extraBed}
                        >
                            <IncrementNumberInput
                                onChange={setExtraBedNo}
                                label={` ${t('Extra Bed')}`}
                                max={10}
                                defaultCount={room?.extra_beds || extraBedNo}
                            />
                            {/* to be handle the error !!!!!!!!! */}
                        </Form.Item>
                    </Col>
                    <Col lg={12} xs={12}>
                        <div className="create-room-form-switch">
                            <MUIFormItem
                                label={t('Price per Extra Bed')}
                                name="extra_bed_charge"
                            >
                                <Input
                                    disabled={extraBedNo === 0}
                                    suffix={(
                                        <>
                                            0.00
                                            <Form.Item
                                                name="extra_bed_charges_type"
                                                noStyle
                                            >
                                                <Radio.Group
                                                    disabled={extraBedNo === 0}
                                                    options={options}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                />
                            </MUIFormItem>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ExtraBed;
