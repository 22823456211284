import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Divider,
    Typography,
    Button,
    Modal,
    Checkbox,
    notification,
} from 'antd';
import {
    RightIcon,
} from '@/assets/images/icons/screens';
import { actions } from '@/redux/b2b/supplier/collect/reducer';

const TermsAndCondition = ({
    modalVisible,
    setModalVisible,
    serviceId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [termsofservice, setTermsOfService] = useState(false);

    const sendRequest = useCallback(() => {
        dispatch(actions.sendServiceRequest({
            service_id: serviceId,
            status: 2,
            termsofservice: termsofservice ? 1 : 0,
        }));
    }, [dispatch, serviceId, termsofservice]);

    const requestSuccess = useSelector((state) => state.b2b.supplier.collect.message);
    const requestError = useSelector((state) => state.b2b.supplier.collect.error);

    useEffect(() => {
        if (requestSuccess !== '') {
            setModalVisible(false);
            setTermsOfService(false);
            notification.success({
                description: `${t(requestSuccess)} !`,
            });
        }
        if (requestError !== null) {
            notification.error({
                description: t(requestError),
            });
        }
        dispatch(actions.clearRequestMessage());
    }, [requestSuccess, requestError, setModalVisible]);

    return (
        <Modal
            open={modalVisible}
            footer={null}
            onCancel={() => {
                setModalVisible(false);
                setTermsOfService(false);
            }}
            maskClosable={false}
            centered
            width={650}
            className="terms-and-conditions"
        >
            <Typography.Text>
                {t('Terms and Conditions')}
            </Typography.Text>
            <div className="info">
                {t('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer scelerisque mauris eget metus tempus, eget accumsan mauris euismod. Duis vitae lobortis purus, sed elementum nulla. Praesent nec metus rutrum, tincidunt tortor ac, ultricies mi. Phasellus sodales velit id porta consequat. Vivamus pharetra, sapien sed convallis placerat, velit nunc porttitor risus, tincidunt ornare massa neque ut libero. Vestibulum pellentesque enim sed varius pulvinar. In eget lacus dictum, scelerisque quam ac, ullamcorper nibh. Aliquam ac cursus sem. Etiam purus nisl, ornare eget nunc congue, dapibus faucibus massa. Nulla commodo et ex et elementum. Pellentesque nibh velit, suscipit id pulvinar non, auctor quis augue. Donec placerat ante arcu, nec cursus risus maximus eget. Integer massa ex, cursus ut lectus eu, ornare semper nunc. Vivamus vitae urn')}
            </div>
            <Divider />
            <Row justify="space-between">
                <Col span={18}>
                    <Checkbox
                        checked={termsofservice}
                        onChange={(e) => setTermsOfService(e.target.checked)}
                    >
                        {t('Please accept terms and conditions in order to have a fair partnership with the owner of this product')}
                    </Checkbox>
                </Col>
                <Col span={6}>
                    <Button onClick={() => sendRequest()}>
                        {t('Send request').toUpperCase()}
                        <RightIcon />
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default TermsAndCondition;
