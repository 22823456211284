import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';

import TourTypeListCard from '@/components/TourTypeListCard';
import { actions } from '@/redux/tour/trip/delete/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import {
    LocationIcon,
    PriceIcon,
    UserIcon,
} from '@/assets/images/icons/screens';
import useNotification from '@/hooks/useNotification';

const TripList = ({ trips, viewMode }) => {
    const { t } = useTranslation();

    const displayType = viewMode;
    const isList = (displayType === 'list');

    const [itemName, setItemName] = useState('');

    const messageSuccess = useSelector((state) => state.tour.trip.delete.messageSuccess);
    const error = useSelector((state) => state.tour.trip.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: itemName,
        clearAction: actions.clearDeleteTripState(),
        mode: 'list',
    });

    return (
        <Row gutter={isList ? [0, 15] : [30, 30]}>
            {trips.map((trip) => (
                <TourTypeListCard
                    key={trip.id}
                    image={trip.image}
                    title={trip.name}
                    location={(
                        <>
                            <LocationIcon />
                            {trip.itinerary.map((item) => item.location).join(', ')}
                        </>
                    )}
                    size={(
                        <>
                            {isList ? (<UserIcon />) : `${t('Group size')}:`}
                            {` ${parseInt(trip.group_size, 10).toFixed(0)} ${t('people')}`}
                        </>
                    )}
                    price={(
                        <>
                            {isList && (
                                <>
                                    <PriceIcon />
                                    {' '}
                                </>
                            )}
                            {`${trip.currency_icon}${trip.adult_price} / ${trip.price_type}`}
                        </>
                    )}
                    agency={(
                        <>
                            {!isList && trip.b2b_agency !== null && ('Collected from:')}
                            {trip.b2b_agency}
                        </>
                    )}
                    url={`/app/tours/trip/${trip.id}`}
                    description={trip.description}
                    totalStep={3}
                    registerStep={trip.tour_register_step}
                    displayType={displayType}
                    editUrl={`/app/tours/trip/${trip.id}/edit`}
                    deleteAction={actions.requestDeleteTourTrip(trip.id)}
                    type="trip"
                    removeSharedServiceAction={shareActions.requestRemoveSharedService(
                        trip.id,
                        { type: 1 },
                    )}
                    removeSharedCollectedServiceAction={
                        shareActions.requestRemoveSharedCollectedService(
                            trip.id,
                            { type: 1 },
                        )
                    }
                    isShared={trip.share}
                    id={trip.id}
                    myService={trip.b2b_agency === null}
                    setItemName={setItemName}
                />
            ))}
        </Row>
    );
};

export default TripList;
