import { Form } from 'antd';
import AvatarUpload from '@/components/Upload/Avatar';
import { AvatarUploadIcon } from '@/assets/images/icons/screens';

const UploadImage = (props) => {
    return (
        <Form.Item {...props}>
            <AvatarUpload image={<AvatarUploadIcon />} />
        </Form.Item>
    );
};

export default UploadImage;
