import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import EmployeesService from '@/services/EmployeesService';
import { types, actions } from './reducer';

function* viewEmployeeDetails({ id }) {
    try {
        const res = yield call(EmployeesService.getEmployeeDetails, id);
        yield put(actions.viewEmployeeDetailsSucceeded(res.data));
    } catch (error) {
        yield put(actions.viewEmployeeDetailsFailed(extractError(error)));
    }
}

function* getEmployeeInvoices({ id, filters, isFetchingMore }) {
    if (isFetchingMore) {
        yield put(actions.getMoreEmployeeInvoices());
    }
    try {
        const response = yield call(EmployeesService.getEmployeeInvoices, id, filters);
        const { invoices: invoiceList } = response.data;
        const { total: totalInvoices } = response.meta;
        if (isFetchingMore) {
            yield put(
                actions.getMoreEmployeeInvoicesSucceeded(invoiceList),
            );
            return;
        }
        yield put(actions.getEmployeeInvoicesSucceeded(invoiceList, totalInvoices));
    } catch (error) {
        if (isFetchingMore) {
            yield put(actions.getMoreEmployeeInvoicesFailed(extractError(error)));
            return;
        }
        yield put(actions.getEmployeeInvoicesFailed(extractError(error)));
    }
}

function* viewSkillList() {
    try {
        const res = yield call(EmployeesService.getSkillList);
        yield put(actions.viewSkillListSucceeded(res.data));
    } catch (error) {
        yield put(actions.viewSkillListFailed(extractError(error)));
    }
}

function* watchViewEmployeeDetails() {
    yield takeEvery(
        types.REQUEST_VIEW_EMPLOYEE_DETAILS,
        viewEmployeeDetails,
    );
}

function* watchgetEmployeeInvoices() {
    yield takeEvery(
        types.REQUEST_GET_EMPLOYEE_INVOICES,
        getEmployeeInvoices,
    );
}

function* watchViewSkillList() {
    yield takeEvery(
        types.REQUEST_VIEW_SKILL_LIST,
        viewSkillList,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewEmployeeDetails),
        fork(watchgetEmployeeInvoices),
        fork(watchViewSkillList),
    ]);
}

export default viewSaga;
