import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { actions } from '@/redux/tour/cruise/create/reducer';
import { EmptyShipListIcon } from '@/assets/images/icons/screens';

const EmptyShipList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const goToCreate = useCallback(() => {
        dispatch(actions.goToCruiseCreateShip());
        dispatch(actions.clearShipDetails());
    }, [dispatch]);

    return (
        <div className="empty-ship-list">
            <div>
                <EmptyShipListIcon />
            </div>
            <div>
                <span>
                    {t("Oops! Looks you haven't created yet any Ship")}
                </span>
            </div>
            <div>
                <Button
                    className="create-button-filled"
                    onClick={goToCreate}
                >
                    {t('Add new ship').toUpperCase()}
                </Button>
            </div>
        </div>
    );
};

export default EmptyShipList;
