import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Form,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import GoogleMap from '@/components/GoogleMap';
import { LocationIcon } from '@/assets/images/icons/screens';

const ActivityLocation = ({
    validations,
    lat,
    lng,
    form,
}) => {
    const { t } = useTranslation();

    const [address, setAddress] = useState(undefined);

    return (
        <>
            <MUIFormItem
                label={t('Enter the full address')}
                name="address"
                rules={validations.address}
            >
                <Input
                    suffix={<LocationIcon />}
                    onChange={(e) => setAddress(e.target.value)}
                />
            </MUIFormItem>

            <Row gutter={25}>
                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Map Latitude')}
                        name="latitude"
                        rules={validations.mapLatitude}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Map Longitude')}
                        name="longitude"
                        rules={validations.mapLongitude}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>
            </Row>

            <Form.Item>
                <GoogleMap
                    latitude={lat}
                    longitude={lng}
                    address={address}
                    form={form}
                />
            </Form.Item>
        </>
    );
};

export default ActivityLocation;
