import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { Row, Col } from 'antd';
import EmptyList from '@/components/EmptyList';
import Filters from '@/components/Invoice/InvoiceFilters';
import { EmptyInvoicesListIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/invoice/reducer';
import { dateFormat } from '@/helpers/dateFormat';
import { useCurrency } from '@/hooks/useCurrency';
import GraphCard from './GraphCard';
import InvoiceTable from './InvoiceTable';
import Loading from './Loading';

const initialFilters = {
    prices: [10, 700],
};

const View = () => {
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const [filters, baseSetFilters] = useState(initialFilters);
    const [page, setPage] = useState(1);

    const handleFetchInvoices = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                min_amount: filters.prices[0],
                max_amount: filters.prices[1],
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
                type: filters.type,
                status: filters.status,
                date_start: filters.date?.[0]?.format(dateFormat),
                date_end: filters.date?.[1]?.format(dateFormat),
                widgets: filters.widgets, // 1 created from hola app, 0 created from website
                sort: filters.sort,
                name: filters.name,
                customer_list: 0,
                customer: filters.customer,
            };
            filters.prices[1] !== 0
            && dispatch(actions.requestFetchInvoices(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters.type,
            filters.status,
            filters.widgets,
            filters.date,
            filters.name,
            filters.sort,
            filters.customer,
            filters.prices,
        ],
    );
    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const invoiceList = useSelector((state) => state.invoice.data);
    const total = useSelector((state) => state.invoice.totalItems);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.invoice);

    useEffect(() => {
        handleFetchInvoices({});
    }, [handleFetchInvoices]);

    const handleFetchMore = useCallback(() => {
        handleFetchInvoices({ currentPage: page, fetchMore: true });
    }, [handleFetchInvoices, page]);

    return (
        <Row className="invoices-row-m1" gutter={[0, 30]}>
            <Col xs={24}>
                <GraphCard currency={currencyIcon} />
            </Col>
            <Col xs={24}>
                <Filters
                    invoices={invoiceList}
                    total={total}
                    filters={filters}
                    setFilters={setFilters}
                    withCreateButton={true}
                    hasCustomer={true}
                    usedForCustomerInvoices={false}
                />
            </Col>
            <Col xs={24}>
                { loading
                    ? <Loading />
                    : invoiceList.length === 0
                        ? (
                            <EmptyList
                                image={<EmptyInvoicesListIcon />}
                                message="There are no data stored yet"
                            />
                        ) : (
                            <>
                                <InvoiceTable data={invoiceList} />
                                {!isLoadingMore && hasMore && (
                                    <Waypoint onEnter={() => handleFetchMore()} />
                                )}
                            </>
                        )}
            </Col>
        </Row>
    );
};

export default View;
