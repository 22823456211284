import { Row, Col } from 'antd';
import AgencyDetails from './AgencyDetails';
import AgencyServices from './AgencyServices';

const AgencyProfile = () => {
    return (
        <Row gutter={[30, 30]} className="agency-profile-page">
            <Col xs={24} lg={8}>
                <AgencyDetails />
            </Col>
            <Col xs={24} lg={16}>
                <AgencyServices />
            </Col>
        </Row>
    );
};

export default AgencyProfile;
