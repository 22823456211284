import { Form } from 'antd';
import { CameraUploadIcon } from '@/assets/images/icons/screens';
import CoverUpload from '@/components/Upload/Cover';
import { allowedImageMIMETypes } from '@/constants/upload';

const ImageUpload = (props) => {
    return (
        <Form.Item {...props}>
            <CoverUpload
                image={<CameraUploadIcon />}
                allowedMIMETypes={allowedImageMIMETypes}
                listType="picture-card"
                showUploadList={false}
            />
        </Form.Item>
    );
};

export default ImageUpload;
