import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Radio,
    Checkbox,
    Input,
    Button,
    Divider,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import { PlusIcon } from '@/assets/images/icons/screens';
import { useCurrency } from '@/hooks/useCurrency';
import PriceItem from './PriceItem';

const TourPrices = ({
    validations,
    form,
    prices,
    childNumber,
    infantNumber,
}) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    const [hasChilds, setHasChilds] = useState(false);
    const [priceType, setPriceType] = useState(1);

    useEffect(() => {
        if ((childNumber !== '0' && childNumber !== undefined) || infantNumber) {
            setHasChilds(true);
        }
    }, [childNumber, infantNumber]);

    const options = useMemo(() => ([
        { label: t('Fixed'), value: 1 },
        { label: t('Rules'), value: 0 },
    ]), [t]);

    return (
        <Row gutter={30}>
            <Col xxl={6} sm={12} xs={24}>
                <MUIFormItem
                    name="min_person_no"
                    label={`${t('No. of Adults')}: ${t('min')} `}
                    rules={validations.min_person_no}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xxl={6} sm={12} xs={24}>
                <MUIFormItem
                    name="max_person_no"
                    label={`${t('No. of Adults')}: ${t('max')} `}
                    rules={validations.max_person_no}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xxl={12} sm={18} xs={24}>
                <MUIFormItem
                    name="adult_price"
                    rules={validations.adult_price}
                    label={t('Adult Price')}
                >
                    <Input
                        suffix={(
                            <>
                                {`${currencyIcon}0.00`}
                                <Form.Item
                                    name="fixed"
                                    noStyle
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType="button"
                                        buttonStyle="solid"
                                        onChange={(e) => setPriceType(e.target.value)}
                                    />
                                </Form.Item>
                            </>
                        )}
                    />
                </MUIFormItem>
            </Col>

            <Col xxl={12} sm={6} xs={24}>
                <Form.Item name="popular" valuePropName="checked">
                    <Checkbox>
                        {t('Popular')}
                    </Checkbox>
                </Form.Item>
            </Col>

            {(priceType === 0 || prices?.[0]?.rule_type !== undefined) && (
                <Col xs={24}>
                    <Form.List name="prices">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => (
                                    <PriceItem
                                        key={field.key}
                                        field={field}
                                        remove={remove}
                                        form={form}
                                        formListName="prices"
                                        hasEndingNo={prices[field.key]?.ending_no !== null}
                                    />
                                ))}

                                <Divider />

                                <Form.Item className="add-button-form">
                                    <Row justify="end">
                                        <Col>
                                            <Button
                                                className="button add-button-outlined"
                                                onClick={() => add()}
                                            >
                                                <PlusIcon />
                                                {t('add new rule').toUpperCase()}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
            )}
            <Col lg={24} xs={24}>
                <Form.Item>
                    <Checkbox
                        checked={hasChilds}
                        onChange={(e) => setHasChilds(e.target.checked)}
                    >
                        {t('Add Children or Infants')}
                    </Checkbox>
                </Form.Item>
            </Col>

            {hasChilds && (
                <>
                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="children"
                            label={`${t('Max. Children')}:`}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="child_price"
                        >
                            <Input placeholder={`${currencyIcon}0.00 / ${t('child')}`} />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="infants"
                            label={`${t('Max. Infants')}:`}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="infant_price"
                        >
                            <Input placeholder={`${currencyIcon}0.00 / ${t('infant')}`} />
                        </MUIFormItem>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default TourPrices;
