import {
    call,
    takeEvery,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import RegistrationService from '@/services/RegistrationService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* resendRegistrationEmailCode() {
    try {
        yield call(RegistrationService.resendConfirmEmail);
        yield put(actions.resendRegistrationEmailCodeSucceeded('OTP code sent to your email'));
    } catch (error) {
        if (error.response.message === 'email_already_confirmed') {
            yield put(actions.registerVerifyEmailSucceeded());
        } else {
            yield put(actions.resendRegistrationEmailCodeFailed(extractError(error)));
        }
    }
}

function* registerVerifyEmail({ code }) {
    try {
        yield call(RegistrationService.verifyEmail, code);
        yield put(actions.registerVerifyEmailSucceeded(code));
    } catch (error) {
        yield put(actions.registerVerifyEmailFailed(extractError(error)));
    }
}

function* watchResendRegistrationEmailCode() {
    yield takeEvery(
        types.REQUEST_RESEND_REGISTRATION_EMAIL_CODE,
        resendRegistrationEmailCode,
    );
}

function* watchRegisterVerifyEmail() {
    yield takeEvery(types.REQUEST_REGISTER_VERIFY_EMAIL, registerVerifyEmail);
}

function* verifyEmailSaga() {
    yield all([
        fork(watchResendRegistrationEmailCode),
        fork(watchRegisterVerifyEmail),
    ]);
}

export default verifyEmailSaga;
