import { Row, Col } from 'antd';

const SectionLayout = ({
    children,
    icon,
    leftCol,
    rightCol,
    ...props
}) => {
    return (
        <Row {...props}>
            <Col xs={leftCol}>
                <div><span>{icon}</span></div>
            </Col>

            <Col xs={rightCol}>
                {children}
            </Col>
        </Row>
    );
};

export default SectionLayout;
