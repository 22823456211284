import {
    call,
    takeEvery,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import RegistrationService from '@/services/RegistrationService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* registerAddServicesList({ data }) {
    try {
        yield call(RegistrationService.addServicesList, data);
        yield put(actions.registerAddServicesListSucceeded(data));
    } catch (error) {
        yield put(actions.registerAddServicesListFailed(extractError(error)));
    }
}

function* watchRegisterAddServicesList() {
    yield takeEvery(
        types.REQUEST_REGISTER_ADD_SERVICES_LIST,
        registerAddServicesList,
    );
}

function* addServicesListSaga() {
    yield all([
        fork(watchRegisterAddServicesList),
    ]);
}

export default addServicesListSaga;
