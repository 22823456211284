import {
    all,
    fork,
    takeEvery,
    call,
    put,
    select,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import InvoiceService from '@/services/InvoiceService';
import { types, actions } from './reducer';
import { selectors } from './model';

const returnExactService = (type, step) => {
    if (type === 'Tour Trip' || type === 'Tour Package' || type === 'Tour Cruise') {
        if (step === '1') {
            return InvoiceService.submitTourInvoiceDetails;
        } else if (step === '2') {
            return InvoiceService.submitTourInvoiceParticipants;
        } else {
            return InvoiceService.submitTourInvoiceExtras;
        }
    } else if (type === 'Hotel') {
        if (step === '1') {
            return InvoiceService.submitHotelInvoiceDetails;
        } else if (step === '2') {
            return InvoiceService.submitHotelInvoiceParticipants;
        } else {
            return InvoiceService.submitHotelInvoiceExtras;
        }
    } else if (step === '1') {
        return InvoiceService.submitActivityInvoiceDetails;
    } else if (step === '2') {
        return InvoiceService.submitActivityInvoiceParticipants;
    } else {
        return InvoiceService.submitActivityInvoiceExtras;
    }
};

function* submitDetails({ data }) {
    const { invoice_id: invoiceId } = data;

    // in case of add invoice
    const { tour_id: idFromCreate } = data;
    const { reservation_type: typeFromCreate } = data;

    // in case of edit invoice
    const invoiceDetails = yield select(selectors.invoiceDetails);

    let typeFromView = null;
    if (invoiceDetails !== null) {
        const {
            invoice_data: {
                reservation_type: type,
            },
        } = invoiceDetails;
        typeFromView = type;
    }

    let idFromView = null;
    if (typeFromView === 'Tour Trip') {
        const {
            tour_trip_general_data: {
                id: tripId,
            },
        } = invoiceDetails;
        idFromView = tripId;
    } else if (typeFromView === 'Tour Package') {
        const {
            tour_package_general_data: {
                id: packageId,
            },
        } = invoiceDetails;
        idFromView = packageId;
    } else if (typeFromView === 'Tour Cruise') {
        const {
            tour_cruise_general_data: {
                id: cruiseId,
            },
        } = invoiceDetails;
        idFromView = cruiseId;
    } else if (typeFromView === 'Activity') {
        const {
            activity_general_data: {
                id: activityId,
            },
        } = invoiceDetails;
        idFromView = activityId;
    } else if (typeFromView === 'Hotel') {
        const {
            hotel_general_data: {
                id: hotelId,
            },
        } = invoiceDetails;
        idFromView = hotelId;
    } else {
        idFromView = null;
    }

    // the exact id and type
    const id = invoiceId !== undefined ? idFromView : idFromCreate;
    const type = invoiceId !== undefined ? typeFromView : typeFromCreate;

    const service = returnExactService(type, '1');

    try {
        delete data.tour_id;
        delete data.reservation_type;
        delete data.currency_icon;
        const res = yield call(service, id, data);
        const {
            first_step: invoiceFirstStep,
            general_data: generalDetails,
        } = res.data;
        yield put(actions.submitInvoiceDetailsSucceeded(
            generalDetails,
            invoiceFirstStep,
        ));
    } catch (error) {
        yield put(actions.submitInvoiceDetailsFailed(extractError(error)));
    }
}

function* submitParticipants({ data }) {
    const { invoice_id: id } = yield select(selectors.invoiceCustomer);
    const { invoice_data: invoiceData } = yield select(selectors.invoiceDetails);
    const { reservation_type: type } = invoiceData;
    const service = returnExactService(type, '2');
    try {
        const res = yield call(service, id, data);
        const { second_step: invoiceSecondStep } = res.data;
        const { clients: clientList } = invoiceSecondStep;
        yield put(actions.submitInvoiceParticipantsSucceeded(clientList));
    } catch (error) {
        yield put(actions.submitInvoiceParticipantsFailed(extractError(error)));
    }
}

function* submitExtras({ data }) {
    const { invoice_id: id } = yield select(selectors.invoiceCustomer);
    const { invoice_data: invoiceData } = yield select(selectors.invoiceDetails);
    const { reservation_type: type } = invoiceData;
    const service = returnExactService(type, '3');
    try {
        const res = yield call(service, id, data);
        const { third_step: invoiceThirdStep } = res.data;
        yield put(actions.submitInvoiceExtrasSucceeded(invoiceThirdStep));
    } catch (error) {
        yield put(actions.submitInvoiceExtrasFailed(extractError(error)));
    }
}

function* getCustomerList({ filters }) {
    try {
        const response = yield call(InvoiceService.getCustomerList, filters);
        yield put(actions.getCustomerListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getCustomerListFailed(extractError(error)));
    }
}

function* getExistingCustomer({ id }) {
    try {
        const res = yield call(InvoiceService.getExistingCustomer, id);
        yield put(actions.getExistingCustomerSucceeded(res.data));
    } catch (error) {
        yield put(actions.getExistingCustomerFailed(extractError(error)));
    }
}

function* getExtraServices({ tourId, tourType }) {
    try {
        let response;
        if (tourType === 'Activity') {
            response = yield call(InvoiceService.getActivityExtraServices, tourId);
        } else {
            response = yield call(InvoiceService.getTourExtraServices, tourId);
        }
        yield put(actions.getExtraServicesSucceeded(response.data));
    } catch (error) {
        yield put(actions.getExtraServicesFailed(extractError(error)));
    }
}

function* fetchInvoiceData({ id }) {
    try {
        const res = yield call(InvoiceService.getOne, id);
        const {
            general_data: generalData,
            first_step: firstStep,
            second_step: secondStep,
            third_step: thirdStep,
        } = res.data;
        const { clients: clientList } = secondStep;
        yield put(actions.fetchInvoiceDataSucceeded(
            generalData,
            firstStep,
            clientList,
            thirdStep,
        ));
    } catch (error) {
        yield put(actions.fetchInvoiceDataFailed(extractError(error)));
    }
}

function* getReservationData({ id, data }) {
    try {
        const res = yield call(InvoiceService.getReservationData, id, data);
        yield put(actions.getReservationDataSucceeded(res.data));
    } catch (error) {
        yield put(actions.getReservationDataFailed(extractError(error)));
    }
}

function* watchSubmitDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_INVOICE_DETAILS,
        submitDetails,
    );
}

function* watchSubmitParticipants() {
    yield takeEvery(
        types.REQUEST_SUBMIT_INVOICE_PARTICIPANTS,
        submitParticipants,
    );
}

function* watchSubmitExtras() {
    yield takeEvery(
        types.REQUEST_SUBMIT_INVOICE_EXTRAS,
        submitExtras,
    );
}

function* watchGetCustomerList() {
    yield takeEvery(
        types.REQUEST_GET_CUSTOMER_LIST,
        getCustomerList,
    );
}

function* watchGetExistingCustomer() {
    yield takeEvery(
        types.REQUEST_GET_EXISTING_CUSTOMER,
        getExistingCustomer,
    );
}

function* watchGetExtraServices() {
    yield takeEvery(
        types.REQUEST_GET_EXTRA_SERVICES,
        getExtraServices,
    );
}

function* watchFetchInvoiceData() {
    yield takeEvery(
        types.REQUEST_FETCH_INVOICE_DATA,
        fetchInvoiceData,
    );
}

function* watchGetReservationData() {
    yield takeEvery(
        types.REQUEST_GET_RESERVATION_DATA,
        getReservationData,
    );
}

function* createInvoiceSaga() {
    yield all([
        fork(watchSubmitDetails),
        fork(watchSubmitParticipants),
        fork(watchSubmitExtras),
        fork(watchGetCustomerList),
        fork(watchGetExistingCustomer),
        fork(watchGetExtraServices),
        fork(watchFetchInvoiceData),
        fork(watchGetReservationData),
    ]);
}

export default createInvoiceSaga;
