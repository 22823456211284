import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    requestRegisterAddServicesList: ['data'],
    registerAddServicesListSucceeded: ['data'],
    registerAddServicesListFailed: ['errors'],

    registerAddServicesListGoBack: null,
});

export default {
    [types.REQUEST_REGISTER_ADD_SERVICES_LIST]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: true,
            errors: null,
        },
    }),
    [types.REGISTER_ADD_SERVICES_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        authenticatedReview: true,
        user: { ...state.user, services: data },
        registration: {
            ...state.registration,
            submitting: false,
            registerStep: 5,
        },
    }),
    [types.REGISTER_ADD_SERVICES_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            errors,
        },
    }),

    [types.REGISTER_ADD_SERVICES_LIST_GO_BACK]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            registerStep: 3,
        },
    }),
};

export { types, actions };
