import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view request details
    viewRequestDetails: ['id', 'filters'],
    viewRequestDetailsSucceeded: [
        'requestDetails',
        'agencyList',
        'totalAgencies',
    ],
    viewRequestDetailsFailed: ['error'],

    // approve or cancel request
    approveOrCancelRequest: ['data'],
    approveOrCancelRequestSucceeded: ['data'],
    approveOrCancelRequestFailed: ['error'],

    // clear request success message
    clearRequestSuccessMessage: ['null'],
});

export default {
    // view request details
    [types.VIEW_REQUEST_DETAILS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_REQUEST_DETAILS_SUCCEEDED]: (state,
        {
            requestDetails,
            agencyList,
            totalAgencies,
        }) => ({
        ...state,
        view: {
            ...state.view,
            requestDetails,
            agencies: agencyList,
            totalAgencies,
            loading: false,
        },
    }),
    [types.VIEW_REQUEST_DETAILS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // approve or cancel request
    [types.APPROVE_OR_CANCEL_REQUEST]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.APPROVE_OR_CANCEL_REQUEST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            message: data,
            loading: false,
        },
    }),
    [types.APPROVE_OR_CANCEL_REQUEST_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // clear request success message
    [types.CLEAR_REQUEST_SUCCESS_MESSAGE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            message: '',
        },
    }),
};
