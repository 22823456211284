import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Menu,
    Divider,
} from 'antd';
import { UserIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/employees/create/reducer';

const GroupMenu = ({
    groups,
    setDefGroupName,
    groupSelected,
    setGroupSelected,
    setAddMode,
    setCheckedRoles,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const items = groups.map((group) => ({
        label: group.name,
        icon: <UserIcon />,
        key: group.name?.concat('-', group.id),
    }));

    return (
        <Row>
            <Col xs={24} className="menu-title">
                <span>{t('Roles / Job Positions')}</span>
                <Divider />
            </Col>
            <Col xs={24}>
                <Menu
                    selectedKeys={[groupSelected || groups[0]?.name.concat('-', groups[0].id)]}
                    items={items}
                    onClick={(e) => {
                        setDefGroupName(e.key.slice(0, e.key.indexOf('-')));
                        setGroupSelected(e.key);
                        setAddMode(false);
                        setCheckedRoles({});
                        dispatch(actions.requestGetGroupRoles(e.key.slice(e.key.indexOf('-') + 1)));
                    }}
                />
            </Col>
        </Row>
    );
};

export default GroupMenu;
