import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceExtras from '@/components/Invoice/InvoiceExtras';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { actions } from '@/redux/invoice/createcustom/reducer';
import { selectors } from '@/redux/invoice/createcustom/model';

const formFields = [
    'discount',
    'discount_type',
];

const Extras = ({ form }) => {
    const dispatch = useDispatch();

    const extra = useSelector(selectors.extra);
    const errors = useSelector(selectors.errors);

    const onFinish = useCallback((values) => {
        const data = {
            discount: values.discount,
            discount_type: values.discount_type,
        };
        dispatch(actions.requestSubmitInvoiceExtra(data));
    }, [dispatch]);

    useEffect(() => {
        let initialData = {
            discount_type: 4,
        };
        if (extra) {
            initialData = {
                discount: extra.discount,
                discount_type: extra.discount_type,
            };
        } form.setFieldsValue(initialData);
        form.setFieldsValue(initialData);
    }, [form, extra]);

    useApiValidationsForForm({ form, errors, formFields });

    return (
        <InvoiceExtras
            form={form}
            onFinish={onFinish}
        />
    );
};

export default Extras;
