import {
    forwardRef,
    useCallback,
    useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { actions } from '@/redux/countrylist/reducer';

const defaultRenderOption = (country) => country.country;

const CountrySelect = ({
    name = 'country',
    titleParam = 'country',
    valueParam = 'country_id',
    renderOption = defaultRenderOption,
    onSelect = () => {},
    ...rest
}, ref) => {
    const dispatch = useDispatch();

    const data = useSelector((state) => state.countrylist.countries);
    const loading = useSelector((state) => state.countrylist.loading);

    const onSearch = useCallback((search) => {
        if (search) {
            dispatch(actions.requestGetCountryList({ name: search }));
        }
    }, []);

    useEffect(() => {
        dispatch(actions.requestGetCountryList({}));
    }, []);

    return (
        <Select
            ref={ref}
            name={name}
            popupClassName="country-select"
            loading={loading}
            defaultActiveFirstOption={false}
            notFoundContent={null}
            onSelect={onSelect}
            labelInValue={name === 'country'}
            bordered={name === 'country'}
            filterOption={false}
            defaultValue={name === 'prefix' ? '1' : undefined}
            {...rest}
            onSearch={onSearch}
            showSearch
            allowClear
            onClear={() => dispatch(actions.requestGetCountryList({}))}
        >
            {data?.map((country) => (
                <Select.Option
                    key={country.id}
                    title={country[titleParam]}
                    value={country[valueParam]}
                >
                    {renderOption(country)}
                </Select.Option>
            ))}
        </Select>
    );
};

export default forwardRef(CountrySelect);
