import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    generalInfo: null,
    itenerary: [],
    cabinTypeList: [],
    loading: false,
    error: null,

    cabinList: [],
    filterData: null,
    fetchingLoading: true,
    fetchingError: null,
    isLoadingMore: false,
    isLoadingMoreError: null,
    hasMore: false,
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.cruise.view,
    model,
);

export default model;
