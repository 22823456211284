import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    requestViewCustomer: ['id'],
    viewCustomerSucceeded: ['data'],
    viewCustomerFailed: ['error'],
});

export default {
    [types.REQUEST_VIEW_CUSTOMER]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_CUSTOMER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            customer: data,
            loading: false,
        },
    }),
    [types.VIEW_CUSTOMER_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),
};
