// import { Switch, Route, Redirect } from 'react-router-dom';

// import ViewAll from './ViewAll';
// import New from './New';
// import ViewOne from './ViewOne';

import ComingSoon from '@/components/ComingSoon';

// export const routes = [
//     {
//         name: 'ViewAll',
//         url: '/',
//         component: ViewAll,
//         exact: true,
//     },
//     {
//         name: 'New',
//         url: '/add',
//         component: New,
//         exact: true,
//     },
//     {
//         name: 'Edit',
//         url: '/:id/edit',
//         component: (props) => <New {...props} isEdit />,
//         exact: true,
//     },
//     {
//         name: 'ViewOne',
//         url: '/:id',
//         component: ViewOne,
//         exact: true,
//     },
// ];

const Flights = () => {
    return (
        <ComingSoon />
        // <Switch>
        //     {routes.map((route) => (
        //         <Route
        //             key={route.name}
        //             path={`/app/flights${route.url}`}
        //             exact={route.exact}
        //         >
        //             <route.component />
        //         </Route>
        //     ))}
        //     <Redirect to="/not-found" />
        // </Switch>
    );
};

export default Flights;
