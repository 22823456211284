import { Switch, Route, Redirect } from 'react-router-dom';

import Dashboard from './Dashboard';
import Trip from './Trip';
import Packages from './Packages';
import Cruise from './Cruise';
import Activities from './Activities';

export const routes = [
    {
        name: 'Dashboard',
        // icon: 'trip icon',
        description: 'dashboard desc',
        url: '/',
        component: Dashboard,
        exact: true,
    },
    {
        name: 'Trip',
        icon: 'trip icon',
        description: 'trip desc',
        url: '/trip',
        component: Trip,
    },
    {
        name: 'Packages',
        icon: 'packages icon',
        description: 'packages desc',
        url: '/packages',
        component: Packages,
    },
    {
        name: 'Cruise',
        icon: 'cruise icon',
        description: 'cruise desc',
        url: '/cruise',
        component: Cruise,
    },
    {
        name: 'Activities',
        icon: 'activities icon',
        description: 'activities desc',
        url: '/activities',
        component: Activities,
    },
];

const Tours = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/tours${route.url}`}
                    exact={route.exact}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Tours;
