import { useMemo, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Space,
    Button,
    Form,
} from 'antd';
import { actions } from '@/redux/employees/reducer';
import { ArrowLeftIcon, ArrowRightIcon } from '@/assets/images/icons/screens';

import StepDisplay from './StepDisplay';
import Details from './Details';
import JobPosition from './JobPosition';

const steps = [Details, JobPosition];

const getStep = (step) => steps[step] || steps[0];

const New = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { push } = useHistory();

    const { id: urlId } = useParams();
    const reduxId = useSelector((state) => state.employees.create.personalInfo?.id);
    const step = useSelector((state) => state.employees.create.step);

    const submitting = useSelector(
        (state) => state.employees.create.submitting,
    );

    const id = useMemo(() => {
        return isEdit ? urlId : reduxId;
    }, [isEdit, urlId, reduxId]);

    const goBack = useCallback((s) => {
        dispatch(actions.goToCreateEmployeeStep(s));
    }, [dispatch]);

    const FormStep = useMemo(() => getStep(step), [step]);

    useEffect(() => {
        if (isEdit && id) {
            dispatch(actions.requestFetchEmployeeData(id));
        }
    }, [isEdit, dispatch, id]);

    useEffect(() => {
        if (step === steps.length) {
            push('/app/employees/employees');
        }
    }, [step, push]);

    return (
        <div className="add-employee">

            <StepDisplay step={step} />

            <div className="form">
                <Row>
                    <Col>
                        <div className="form-step">
                            <FormStep form={form} id={id} isEdit={isEdit} />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="form-button">

                {(step === 0) ? (
                    <Row justify="end">
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                <Space size={10}>
                                    {t('Save and go to next step').toUpperCase()}
                                    <ArrowRightIcon />
                                </Space>
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <Row gutter={[20, 20]} justify="space-between">
                        <Col sm={12}>
                            <Button
                                block
                                className="back"
                                onClick={() => goBack(step - 1)}
                            >
                                <Space size={10}>
                                    <ArrowLeftIcon />
                                    {t('Previews step').toUpperCase()}
                                </Space>
                            </Button>
                        </Col>
                        <Col sm={12}>
                            <Button
                                block
                                className="save"
                                loading={submitting}
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                <div>
                                    <span>{t('Save').toUpperCase()}</span>
                                    <ArrowRightIcon />
                                </div>
                            </Button>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default New;
