import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    submitting: false,
    loading: false,
    errors: null,
    success: false,

    customerInfo: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.customers.create,
    model,
);

export default model;
