import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@/helpers/CRUD';

const { Types: types, Creators: actions } = createActions({

    // get widget div
    requestGetWidgetDiv: ['data'],
    getWidgetDivSucceeded: ['searchWidget', 'resultWidget', 'customWidget'],
    getWidgetDivFailed: ['error'],

    // get services
    requestGetServices: [null],
    getServicesSucceeded: ['data'],
    getServicesFailed: ['error'],

    // get domain whitelist
    requestGetDomainWhitelist: [null],
    getDomainWhitelistSucceeded: ['data'],
    getDomainWhitelistFailed: ['error'],

    // add new domain
    requestAddNewDomain: ['data'],
    addNewDomainSucceeded: ['data', 'messageSuccess'],
    addNewDomainFailed: ['messageErrror'],

    // delete domain
    requestDeleteDomain: ['id'],
    deleteDomainSucceeded: ['id', 'messageSuccess'],
    deleteDomainFailed: ['messageError'],

    // clear domain action state
    clearDomainActionState: ['null'],
});

export default {

    // get widget div
    [types.REQUEST_GET_WIDGET_DIV]: (state) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: true,
            error: null,
        },
    }),
    [types.GET_WIDGET_DIV_SUCCEEDED]: (
        state,
        {
            searchWidget,
            resultWidget,
            customWidget,
        },
    ) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            searchWidget,
            resultWidget,
            customWidget,
            loading: false,
        },
    }),
    [types.GET_WIDGET_DIV_FAILED]: (state, { error }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: false,
            error,
        },
    }),

    // get services
    [types.REQUEST_GET_SERVICES]: (state) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: true,
            error: null,
        },
    }),
    [types.GET_SERVICES_SUCCEEDED]: (
        state,
        {
            data,
        },
    ) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            services: data,
            loading: false,
        },
    }),
    [types.GET_SERVICES_FAILED]: (state, { error }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: false,
            error,
        },
    }),

    // get domain whitelist
    [types.REQUEST_GET_DOMAIN_WHITELIST]: (state) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: true,
            error: null,
        },
    }),
    [types.GET_DOMAIN_WHITELIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            domainWhitelist: data,
            loading: false,
        },
    }),
    [types.GET_DOMAIN_WHITELIST_FAILED]: (state, { error }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: false,
            error,
        },
    }),

    // add new domain
    [types.REQUEST_ADD_NEW_DOMAIN]: (state) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: true,
            error: null,
        },
    }),
    [types.ADD_NEW_DOMAIN_SUCCEEDED]: (state, { data, messageSuccess }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            domainWhitelist: [...state.bookingEngine.domainWhitelist, data],
            loading: false,
            messageSuccess,
        },
    }),
    [types.ADD_NEW_DOMAIN_FAILED]: (state, { messageError }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: false,
            messageError,
        },
    }),

    // delete domain
    [types.REQUEST_DELETE_DOMAIN]: (state) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_DOMAIN_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            domainWhitelist: removeSelectedItem(state.bookingEngine.domainWhitelist, id),
            loading: false,
            messageSuccess,
        },
    }),
    [types.DELETE_DOMAIN_FAILED]: (state, { messageError }) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            loading: false,
            messageError,
        },
    }),

    // clear domain action state
    [types.CLEAR_DOMAIN_ACTION_STATE]: (state) => ({
        ...state,
        bookingEngine: {
            ...state.bookingEngine,
            messageSuccess: '',
            messageError: null,
        },
    }),
};

export { types, actions };
