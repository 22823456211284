// import { useTranslation } from 'react-i18next';
import {
    useCallback,
    useState,
} from 'react';
import {
    Row,
    Col,
    Checkbox,
    Space,
    Input,
} from 'antd';
import { EditIcon } from '@/assets/images/icons/screens';
import useWidgetsCategories from '@/hooks/useWidgetsCategories';
import IntegrationService from '@/services/IntegrationService';
import ActionIcon from '@/components/ActionIcon';

const CardWidgetServices = ({
    checkedCardWidgetServices,
    setCheckedCardWidgetServices,
}) => {
    // const { t } = useTranslation();

    const [editSectionVisible, setEditSectionVisible] = useState(false);
    const [newName, setNewName] = useState('');
    const [editingServiceId, setEditingServiceId] = useState(null);

    const { data, mutate } = useWidgetsCategories();

    const editCategoryName = useCallback((categoryId, categoryNewName) => {
        IntegrationService.editCategoryName({
            id: categoryId,
            name: categoryNewName,
        })
            .then(() => {
                mutate();
            })
            .catch((error) => {
                console.log(error);
            });
        setNewName('');
    }, [mutate]);

    return (
        <Checkbox.Group defaultValue={[checkedCardWidgetServices[0].toString()]}>
            <Row gutter={[20, 20]} className="card-services">
                {data?.data.map((category) => (
                    <Col span={8} key={category.id}>
                        <Space>
                            <Checkbox
                                value={category.id}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCheckedCardWidgetServices((prev) => [
                                            ...prev,
                                            category.id,
                                        ]);
                                    } else {
                                        setCheckedCardWidgetServices((prev) => prev.filter(
                                            (item) => item !== category.id,
                                        ));
                                    }
                                }}
                            />
                            {editSectionVisible && editingServiceId === category.id ? (
                                <Input
                                    value={newName}
                                    onMouseLeave={() => {
                                        setEditSectionVisible(false);
                                        setEditingServiceId(null);
                                        newName !== category.name
                                        && editCategoryName(category.id, newName);
                                    }}
                                    onChange={(e) => {
                                        setNewName(e.target.value);
                                    }}
                                />
                            ) : (
                                <Space size={15}>
                                    <div>{category.name}</div>
                                    <ActionIcon onClick={() => {
                                        setEditSectionVisible(true);
                                        setEditingServiceId(category.id);
                                        setNewName(category.name);
                                    }}
                                    >
                                        <EditIcon />
                                    </ActionIcon>
                                </Space>
                            )}
                        </Space>
                    </Col>
                ))}
            </Row>
        </Checkbox.Group>
    );
};

export default CardWidgetServices;
