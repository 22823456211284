/* eslint-disable max-len */
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Space,
    Typography,
    Button,
    Avatar,
} from 'antd';
import {
    RightIcon,
    CalendarIcon,
    ClockIcon,
    LocationIcon,
    TickIcon,
    ShipIcon,
    DeleteIcon,
} from '@/assets/images/icons/screens';
import { HotelIcon, ActivityIcon } from '@/assets/images/icons/topbar';
import { TourIcon } from '@/assets/images/icons/sidebar';
import ActionIcon from '@/components/ActionIcon';

const B2BCard = ({
    data,
    setModalVisible,
    setServiceId,
    sendRequest,
    xlColValue = 6,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const pushToExactPath = (serviceCategory, id) => {
        const types = [
            {
                key: 'Tour Trip',
                type: 'trip',
            },
            {
                key: 'Tour Package',
                type: 'package',
            },
            {
                key: 'Tour Cruise',
                type: 'cruise',
            },
            {
                key: 'Activity',
                type: 'activity',
            },
            // {
            //     key: 'Hotel',
            //     type: 'hotel',
            // }
        ];
        const exactType = types.find((type) => type.key === serviceCategory);
        push(`/app/b2b-partners/worldwide-supplier/${exactType.type}/${id}`);
    };

    return (
        <Row gutter={[20, 20]} className="tour-list">
            {data.map((item) => (
                <Col
                    xl={xlColValue}
                    sm={12}
                    xs={24}
                    key={item.id}
                >
                    <div className="tour-item">
                        <div className="tour-details">
                            <div className="remove-icon-wrapper">
                                {(item.status_id === '1' || item.status_id === '2')
                                    && (
                                        <ActionIcon onClick={() => sendRequest({
                                            status: 3,
                                            service_id: item.id,
                                        })}
                                        >
                                            <DeleteIcon />
                                        </ActionIcon>
                                    )}
                            </div>
                            <Space align="start" size={20}>
                                <div>
                                    <Avatar
                                        size={90}
                                        src={item.image_path}
                                    />
                                    <div className="logo">
                                        <Avatar size={35} src={item.agency_icon} />
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        className="view-tour"
                                        onClick={() => pushToExactPath(item.category, item.id)}
                                    >
                                        {(item.category === 'Tour Trip' || item.category === 'Tour Package') && <TourIcon />}
                                        {item.category === 'Tour Cruise' && <ShipIcon />}
                                        {item.category === 'ACTIVITY' && <ActivityIcon />}
                                        {item.category === 'Tour Cruise' ? t('Cruise').toUpperCase() : t(item.category).toUpperCase()}
                                    </Button>
                                    <div className="tour-name">
                                        <Typography.Text>
                                            {item.name}
                                        </Typography.Text>
                                    </div>
                                    <div className="details">
                                        {item.category === 'Tour Package' ? (
                                            <div>
                                                <HotelIcon />
                                                {item.location}
                                            </div>
                                        ) : (
                                            <div>
                                                <LocationIcon />
                                                {item.location}
                                            </div>
                                        )}
                                        {item.category !== 'Tour Package' && item.category !== 'Tour Trip' && (
                                            <div>
                                                <CalendarIcon />
                                                {`${item.start_date} - ${item.end_date}`}
                                            </div>
                                        )}
                                        {item.category === 'Tour Package' && (
                                            <div>
                                                <CalendarIcon />
                                                {`${item.duration} - ${item.duration_type}`}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Space>
                        </div>
                        <div className="tour-price-section">
                            <Row justify="space-between">
                                <Col>
                                    <div className="price-content">
                                        <div>
                                            {`${item.currency_icon} ${item.adult_price} / ${item.price_type === 'Per Person' ? t('person') : `${item.price_type}`}`}
                                        </div>
                                        <div
                                            className={clsx(
                                                item.status_id === '2' ? 'second-color' : 'base-color',
                                            )}
                                        >
                                            <span>
                                                {item.commission_type === 'Percentage'
                                                    ? `${item.commission}%`
                                                    : `${item.currency_icon} ${item.commission}`}
                                            </span>
                                            <span>{t('your earning')}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    {item.status_id !== '5' ? (
                                        <Button
                                            onClick={() => {
                                                item.status_id === null
                                                && setModalVisible(true);
                                                setServiceId(item.id);
                                            }}
                                            className={clsx(
                                                item.status_id === null && 'base-button',
                                                item.status_id === '1' && 'collected-button',
                                                item.status_id === '2' && 'pending-button',
                                            )}
                                        >
                                            {item.status_id === null && (
                                                <>
                                                    <span>{t('collect').toUpperCase()}</span>
                                                    <RightIcon />
                                                </>
                                            )}
                                            {item.status_id === '1' && (
                                                <span className="btn-text">
                                                    <span className="svg-wrapper">
                                                        <TickIcon />
                                                    </span>
                                                    {t('collected').toUpperCase()}
                                                </span>
                                            )}
                                            {item.status_id === '2' && (
                                                <span className="btn-text">
                                                    <span className="svg-wrapper">
                                                        <ClockIcon />
                                                    </span>
                                                    {t('pending approval').toUpperCase()}
                                                </span>
                                            )}
                                        </Button>
                                    ) : (
                                        <Space>
                                            <Button
                                                className="accept-button"
                                                onClick={() => sendRequest({
                                                    status: 1,
                                                    service_id: item.id,
                                                })}
                                            >
                                                {t('accept').toUpperCase()}
                                            </Button>
                                            <Button
                                                className="reject-button"
                                                onClick={() => sendRequest({
                                                    status: 3,
                                                    service_id: item.id,
                                                })}
                                            >
                                                {t('reject').toUpperCase()}
                                            </Button>
                                        </Space>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default B2BCard;
