import { createActions } from 'reduxsauce';
import { removeSelectedItem, upseartItem } from '@/helpers/CRUD';

const { Types: types, Creators: actions } = createActions({
    // go to previous step
    goToEditAccommodationStep: ['step'],

    // go to next step
    goToCreateAccommodationStep: ['step'],

    // fetch for edit
    requestFetchAccommodationData: ['id'],
    fetchAccommodationDataSucceeded: [
        'generalData',
        'facilitiesData',
        'servicesData',
    ],
    fetchAccommodationDataFailed: ['errors'],

    // submit first step
    requestSubmitAccommodationDetails: ['data', 'currStep', 'complStep'],
    submitAccommodationDetailsSucceeded: ['data', 'currStep', 'complStep'],
    submitAccommodationDetailsFailed: ['errors'],

    // submit second step
    requestSubmitAccommodationFacilities: ['data', 'currStep', 'complStep'],
    submitAccommodationFacilitiesSucceeded: [
        'facilitiesData',
        'servicesData',
        'currStep',
        'complStep',
    ],
    submitAccommodationFacilitiesFailed: ['errors'],

    // create/view rooms or seasons mode actions
    goToCreateRoom: null,
    goToViewRoomList: null,
    goToCreateSeason: null,
    goToViewSeasonList: null,

    // fetch rooms
    requestGetRoomList: ['id'],
    getRoomListSucceeded: ['data'],
    getRoomListFailed: ['errors'],

    // submit third step
    requestSubmitAccommodationRoom: ['data'],
    submitAccommodationRoomSucceeded: ['data'],
    submitAccommodationRoomFailed: ['errors'],

    // view room
    requestViewAccommodationRoom: ['id'],
    viewAccommodationRoomSucceeded: ['data'],
    viewAccommodationRoomFailed: ['errors'],

    // delete room type
    requestDeleteAccommodationRoom: ['id'],
    deleteAccommodationRoomSucceeded: ['id'],
    deleteAccommodationRoomFailed: ['errors'],

    // fetch seasons
    requestGetSeasonList: ['id'],
    getSeasonListSucceeded: ['data'],
    getSeasonListFailed: ['errors'],

    // submit fourth step
    requestSubmitAccommodationSeason: ['data'],
    submitAccommodationSeasonSucceeded: ['data'],
    submitAccommodationSeasonFailed: ['errors'],

    // view season
    requestViewAccommodationSeason: ['id'],
    viewAccommodationSeasonSucceeded: ['data'],
    viewAccommodationSeasonFailed: ['errors'],

    // delete season
    requestDeleteAccommodationSeason: ['id'],
    deleteAccommodationSeasonSucceeded: ['id'],
    deleteAccommodationSeasonFailed: ['errors'],

    // get amenities list
    requestGetAmenitiesList: ['data'],
    getAmenitiesListSucceeded: ['data'],
    getAmenitiesListFailed: ['errors'],

    // add amenity
    requestAddNewAmenity: ['id', 'data'],
    addNewAmenitySucceeded: ['data'],
    addNewAmenityFailed: ['errors'],

    // clear state
    clearHotelState: null,
});

const goToExactStep = (step) => {
    if (step === 4) {
        return 0;
    }
    return step - 1;
};

export default {
    // go to previous step
    [types.GO_TO_EDIT_ACCOMMODATION_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // go to next step
    [types.GO_TO_CREATE_ACCOMMODATION_STEP]: (state, { step }) => ({
        ...state,
        create: {
            ...state.create,
            step: step + 1,
            completedStep: step,
        },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_ACCOMMODATION_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_ACCOMMODATION_DATA_SUCCEEDED]: (
        state,
        {
            generalData,
            facilitiesData,
            servicesData,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: goToExactStep(generalData.register_hotel_step),
            completedStep: goToExactStep(generalData.register_hotel_step),
            loading: false,
            accommodationDetails: generalData,
            facilities: facilitiesData,
            services: servicesData,
        },
    }),
    [types.FETCH_ACCOMMODATION_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_ACCOMMODATION_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_DETAILS_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            accommodationDetails: data,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_ACCOMMODATION_FACILITIES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_FACILITIES_SUCCEEDED]: (
        state,
        {
            facilitiesData,
            servicesData,
            currStep,
            complStep,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            facilities: facilitiesData,
            services: servicesData,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_FACILITIES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // create/view room mode actions
    [types.GO_TO_CREATE_ROOM]: (state) => ({
        ...state,
        create: {
            ...state.create,
            isCreatingRoom: true,
        },
    }),
    [types.GO_TO_VIEW_ROOM_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            isCreatingRoom: false,
            roomDetails: null,
        },
    }),

    // create/view season mode actions
    [types.GO_TO_CREATE_SEASON]: (state) => ({
        ...state,
        create: {
            ...state.create,
            isCreatingSeason: true,
        },
    }),
    [types.GO_TO_VIEW_SEASON_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            isCreatingSeason: false,
            seasonDetails: null,
        },
    }),

    // fetch rooms
    [types.REQUEST_GET_ROOM_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            roomList: [],
            loading: true,
            errors: null,
        },
    }),
    [types.GET_ROOM_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            roomList: data,
            loading: false,
        },
    }),
    [types.GET_ROOM_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            loading: false,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_ACCOMMODATION_ROOM]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submittingRoom: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_ROOM_SUCCEEDED]: (
        state,
        {
            data,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            roomList: upseartItem(state.create.roomList, data),
            submittingRoom: false,
            isCreatingRoom: false,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_ROOM_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submittingRoom: false,
            errors,
        },
    }),

    // view room
    [types.REQUEST_VIEW_ACCOMMODATION_ROOM]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.VIEW_ACCOMMODATION_ROOM_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            roomDetails: data,
        },
    }),
    [types.VIEW_ACCOMMODATION_ROOM_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // delete room type
    [types.REQUEST_DELETE_ACCOMMODATION_ROOM]: (state, { id }) => ({
        ...state,
        create: {
            ...state.create,
            id,
            loading: true,
            errors: null,
        },
    }),
    [types.DELETE_ACCOMMODATION_ROOM_SUCCEEDED]: (state, { id }) => ({
        ...state,
        create: {
            ...state.create,
            roomList: removeSelectedItem(state.create.roomList, id),
            id: null,
            loading: false,
        },
    }),
    [types.DELETE_ACCOMMODATION_ROOM_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            id: null,
            loading: false,
            errors,
        },
    }),

    // fetch season list
    [types.REQUEST_GET_SEASON_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            seasonList: [],
            loading: true,
            errors: null,
        },
    }),
    [types.GET_SEASON_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            seasonList: data,
            loading: false,
        },
    }),
    [types.GET_SEASON_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            loading: false,
        },
    }),

    // submit fourth step
    [types.REQUEST_SUBMIT_ACCOMMODATION_SEASON]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submittingSeason: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_SEASON_SUCCEEDED]: (
        state,
        {
            data,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            seasonList: upseartItem(state.create.seasonList, data),
            submittingSeason: false,
            isCreatingSeason: false,
        },
    }),
    [types.SUBMIT_ACCOMMODATION_SEASON_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submittingSeason: false,
            errors,
        },
    }),

    // view season
    [types.REQUEST_VIEW_ACCOMMODATION_SEASON]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.VIEW_ACCOMMODATION_SEASON_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            seasonDetails: data,
        },
    }),
    [types.VIEW_ACCOMMODATION_SEASON_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // delete season
    [types.REQUEST_DELETE_ACCOMMODATION_SEASON]: (state, { id }) => ({
        ...state,
        create: {
            ...state.create,
            id,
            loading: true,
            errors: null,
        },
    }),
    [types.DELETE_ACCOMMODATION_SEASON_SUCCEEDED]: (state, { id }) => ({
        ...state,
        create: {
            ...state.create,
            seasonList: removeSelectedItem(state.create.seasonList, id),
            id: null,
            loading: false,
        },
    }),
    [types.DELETE_ACCOMMODATION_SEASON_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            id: null,
            loading: false,
            errors,
        },
    }),

    // get amenities list
    [types.REQUEST_GET_AMENITIES_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loadingAmenity: true,
            errors: null,
        },
    }),

    [types.GET_AMENITIES_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            amenitiesList: data,
            loadingAmenity: false,
            errors: null,
        },
    }),
    [types.GET_AMENITIES_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loadingAmenity: false,
            errors,
        },
    }),

    // add amenity
    [types.REQUEST_ADD_NEW_AMENITY]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),

    [types.ADD_NEW_AMENITY_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            amenitiesList: [...state.create.amenitiesList, data],
            submitting: false,
            errors: null,
        },
    }),
    [types.ADD_NEW_AMENITY_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_HOTEL_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            accommodationDetails: null,
            facilities: [],
            roomList: [],
            seasonList: [],
        },
    }),
};

export { types, actions };
