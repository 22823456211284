import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Form,
    Divider,
} from 'antd';

import { selectors } from '@/redux/employees/create/model';
import { actions } from '@/redux/employees/create/reducer';
import { dateFormat } from '@/helpers/dateFormat';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import Loading from '@/components/Loading';
import PersonalDetails from './PersonalDetails';
import AccountDetails from './AccountDetails';
import baseValidations from './validations';
import Upload from './UploadImage';

const formFields = [
    'image',
    'name',
    'surname',
    'id_number',
    'nationality',
    'gender',
    'birthdate',
    'date_employed',
    'phone_number',
    'address',
    'email',
    'password',
];

const Details = ({ form, id, isEdit }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const validations = useTranslatedValidationConfig(baseValidations);

    const onFinish = useCallback((values) => {
        const data = {
            id,
            image: values.image?.[0]?.url,
            name: values.name,
            surname: values.surname,
            id_number: values.id_number,
            nationality: values.nationality?.value,
            gender: values.gender,
            birthdate: values.birthdate.format(dateFormat),
            date_employed: values.date_employed.format(dateFormat),
            phone_number: values.phone_number,
            address: values.address,
            email: values.email,
            password: values.password,
            deleted_images: id && !values.image?.[0]?.url ? id : undefined,
        };
        dispatch(actions.saveDataToSubmit(data));
    }, [dispatch]);

    const personalInfo = useSelector(selectors.personalDetails);
    const personalInfoView = useSelector(selectors.totalDetails);

    const initialData = useMemo(() => {
        if (isEdit && personalInfoView) {
            return {
                image: [{ url: personalInfoView.image, thumbUrl: personalInfoView.image }],
                name: personalInfoView.full_name.slice(0, personalInfoView.full_name.indexOf(' ')),
                surname: personalInfoView.full_name.slice(personalInfoView.full_name.indexOf(' ')),
                id_number: personalInfoView.id_number,
                nationality: {
                    value: personalInfoView.country_id,
                },
                gender: personalInfoView.gender,
                birthdate: personalInfoView.birthdate ? moment(personalInfoView.birthdate) : null,
                date_employed: personalInfoView.date_employed
                    ? moment(personalInfoView.date_employed)
                    : null,
                phone_number: personalInfoView.phone_number,
                address: personalInfoView.address,
            };
        } else if (personalInfo) {
            return {
                image: [{ url: personalInfo?.image, thumbUrl: personalInfo?.image }],
                name: personalInfo?.name,
                surname: personalInfo?.surname,
                id_number: personalInfo?.id_number,
                nationality: {
                    value: personalInfo?.nationality,
                },
                gender: personalInfo?.gender,
                birthdate: personalInfo?.birthdate ? moment(personalInfo?.birthdate) : null,
                date_employed: personalInfo?.birthdate ? moment(personalInfo?.date_employed) : null,
                phone_number: personalInfo?.phone_number,
                address: personalInfo?.address,
            };
        } else return null;
    }, [personalInfo, personalInfoView, isEdit]);

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    if (id && !initialData) {
        return <Loading />;
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            className="personal-details"
            requiredMark={false}
            initialValues={initialData}
        >
            <div className="upload-image">
                <Upload name="image" />
            </div>

            <Form.Item>
                <PersonalDetails validations={validations} />
            </Form.Item>

            <Divider>
                {t('Account Details').toUpperCase()}
            </Divider>

            <Form.Item>
                <AccountDetails
                    isEdit={isEdit}
                    validations={validations}
                />
            </Form.Item>
        </Form>
    );
};

export default Details;
