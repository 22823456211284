import { useTranslation } from 'react-i18next';
import {
    Divider,
    Card,
    Avatar,
    Button,
    Space,
    notification,
} from 'antd';
import { actions } from '@/redux/invoice/create/reducer';
import { removeUnnecessaryValues } from '../removeValues';

const CabinList = ({
    roomListSelected,
    startDate,
    endDate,
    replace,
    dispatch,
    notificationKey,
    type,
    tourId,
    currency,
}) => {
    const { t } = useTranslation();

    // goes to create invoice
    const reservationData = {
        reservation_type: type,
        tour_id: tourId,
        date_start: startDate,
        date_end: endDate,
        currency_icon: currency,
        data: removeUnnecessaryValues(
            roomListSelected,
            ['type_name', 'room_type_total_price', 'room_img', 'price_type'],
        ),
    };

    const bookNow = () => {
        dispatch(actions.clearInvoiceState());
        dispatch(actions.prepareReservationDataForSubmit(reservationData));
        dispatch(actions.requestGetReservationData(
            tourId,
            {
                type: 3,
                date_start: startDate,
                date_end: endDate,
                data: removeUnnecessaryValues(
                    roomListSelected,
                    ['type_name', 'room_type_total_price', 'room_img', 'price_type'],
                ),
            },
        ));
        replace('/app/invoices/new');
        setTimeout(() => {
            notification.close(notificationKey);
        }, 1000);
    };
    return (
        <>
            <Divider />
            {roomListSelected.map((room) => (
                <div key={room.cabin_type}>
                    <Card bordered={false}>
                        <Card.Meta
                            avatar={<Avatar src={room.room_img} />}
                            title={(
                                <Space size={65}>
                                    <span>{t(room.type_name)}</span>
                                    <span>
                                        {room.price_type}
                                        {room.room_type_total_price}
                                    </span>
                                </Space>
                            )}
                            description={(
                                <>
                                    <div>
                                        {`${t('Number of cabins')}: ${room.cabins_no}`}
                                    </div>
                                </>
                            )}
                        />
                    </Card>
                    <Divider dashed />
                </div>
            ))}
            <div>
                <Button
                    block
                    onClick={bookNow}
                >
                    {t('Book now').toUpperCase()}
                </Button>
            </div>
        </>
    );
};

export default CabinList;
