import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Card,
} from 'antd';
import { TinyArea } from '@ant-design/charts';

import BlockDescription from '@/components/Information/WithoutImage/Block';
import useInvoiceGraphs from '@/hooks/useInvoceGraph';

const aStyle = {
    fillOpacity: 0.2,
    cursor: 'pointer',
};

const lStyle = {
    line: {
        style: {
            lineDash: [2, 3],
        },
    },
};

const GraphCard = ({ currency }) => {
    const { t } = useTranslation();
    const { data } = useInvoiceGraphs();

    const paid = data?.data.paid.perMonth.map((month) => (month.paid));
    const revenue = data?.data.revenue.perMonth.map((month) => (month.revenue));
    const overdue = data?.data.overdue.perMonth.map((month) => (month.overdue));

    const revenueConfig = {
        height: 60,
        autoFit: false,
        data: revenue,
        smooth: true,
        line: {
            color: '#089ECB',
            ...lStyle.line,
        },
        areaStyle: {
            fill: 'l(270) 0:#ffffff 0.5:#ffffff 1:#089ECB',
            ...aStyle,
        },
    };

    const paidConfig = {
        height: 60,
        autoFit: false,
        data: paid,
        smooth: true,
        line: {
            color: '#24DCAE',
            ...lStyle.line,
        },
        areaStyle: {
            fill: 'l(270) 0:#ffffff 0.5:#ffffff 1:#24DCAE',
            ...aStyle,
        },
    };

    const overdueConfig = {
        height: 60,
        autoFit: false,
        data: overdue,
        smooth: true,
        line: {
            color: '#FC7886',
            ...lStyle.line,
        },
        areaStyle: {
            fill: 'l(270) 0:#ffffff 0.5:#ffffff 1:#FC7886',
            ...aStyle,
        },
    };

    return (
        <Row gutter={30} className="graph-wrapper">
            <Col span={8} xxl={8} md={12}>
                <Card
                    className="revenue-card"
                    title={t('Revenue').toUpperCase()}
                    extra={<span>{data?.data.revenue.percentage}</span>}
                >
                    <Row gutter={30}>
                        <Col xs={16}>
                            <TinyArea {...revenueConfig} />
                        </Col>
                        <Col xs={8}>
                            <BlockDescription
                                name={`${currency}${data?.data.revenue.value}`}
                                className="total-amount-value"
                                childClassName="total-amount-text"
                            >
                                {t('Total amount').toUpperCase()}
                            </BlockDescription>
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={8} xxl={8} md={12}>
                <Card
                    className="paid-card"
                    title={t('Paid').toUpperCase()}
                    extra={<span>{data?.data.paid.percentage}</span>}
                >
                    <Row gutter={30}>
                        <Col xs={16}>
                            <TinyArea {...paidConfig} />
                        </Col>
                        <Col xs={8}>
                            <BlockDescription
                                name={`${currency}${data?.data.paid.value}`}
                                className="total-amount-value"
                                childClassName="total-amount-text"
                            >
                                {t('Total amount').toUpperCase()}
                            </BlockDescription>
                        </Col>
                    </Row>
                </Card>
            </Col>

            <Col span={8} xxl={8} md={12}>
                <Card
                    className="overdue-card"
                    title={t('Overdue').toUpperCase()}
                    extra={<span>{data?.data.overdue.percentage}</span>}
                >
                    <Row gutter={30}>
                        <Col xs={16}>
                            <TinyArea {...overdueConfig} />
                        </Col>
                        <Col xs={8}>
                            <BlockDescription
                                name={`${currency}${data?.data.overdue.value}`}
                                className="total-amount-value"
                                childClassName="total-amount-text"
                            >
                                {t('Total amount').toUpperCase()}
                            </BlockDescription>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export default GraphCard;
