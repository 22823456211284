import { all, fork } from 'redux-saga/effects';
import bookingEngineSaga from './bookingEngine/saga';

function* integrationsSaga() {
    yield all([
        fork(bookingEngineSaga),
    ]);
}

export default integrationsSaga;
