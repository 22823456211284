import {
    call,
    takeEvery,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import {
    hasTokens,
    setToken,
    setRefreshToken,
    buildUserFromRegistrationSteps,
    removeToken,
    removeRefreshToken,
} from '@/helpers/auth';
import { extractError } from '@/helpers/api';

import AuthenticationService from '@/services/AuthenticationService';
import RegistrationService from '@/services/RegistrationService';
import { types, actions } from './reducer';

function* login({ data, rememberMe }) {
    try {
        const response = yield call(AuthenticationService.login, data);
        const { token, refresh_token: refreshToken, register_step: registerStep } = response.data;

        setToken(token, rememberMe);
        setRefreshToken(refreshToken, rememberMe);

        if (registerStep <= 4) {
            yield put(actions.loginSucceededUncompleted());
            yield put(actions.requestFetchSelf());
            // is going to register page
        } else if (registerStep === 5) {
            yield put(actions.loginSucceededReview());
            // is going to waiting page
        } else if (registerStep >= 6) {
            yield put(actions.loginSucceeded());
            yield put(actions.requestFetchSelf());
        }
    } catch (error) {
        yield put(actions.loginFailed(extractError(error)));
    }
}

function* logout() {
    try {
        yield call(AuthenticationService.logout);
        removeToken();
        removeRefreshToken();
        yield put(actions.logoutSucceeded());
    } catch (error) {
        removeToken();
        removeRefreshToken();
        yield put(actions.logoutSucceeded());
        yield put(actions.logoutFailed(extractError(error)));
    }
}

function* fetchSelf() {
    try {
        if (!hasTokens()) {
            throw new Error('No token');
        }

        const [
            registerStepData,
            registrationDetails,
        ] = yield all([
            call(RegistrationService.getRegisterStep),
            call(RegistrationService.getRegistrationDetails),
        ]);
        const { register_step: registerStep } = registerStepData.data;
        const user = buildUserFromRegistrationSteps(registrationDetails.data);
        yield put(actions.fetchSelfSucceeded(user, registerStep));
    } catch (error) {
        if (error.message === 'No token') {
            yield put(actions.fetchSelfFailed(null));
            return;
        }

        yield put(actions.fetchSelfFailed(extractError(error)));
    }
}

function* watchLogin() {
    yield takeEvery(types.REQUEST_LOGIN, login);
}

function* watchLogout() {
    yield takeEvery(types.REQUEST_LOGOUT, logout);
}

function* watchFetchSelf() {
    yield takeEvery(types.REQUEST_FETCH_SELF, fetchSelf);
}

function* loginSaga() {
    yield all([
        fork(watchLogin),
        fork(watchLogout),
        fork(watchFetchSelf),
    ]);
}

export default loginSaga;
