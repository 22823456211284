import {
    call,
    takeEvery,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import { extractError } from '@/helpers/api';

import RegistrationService from '@/services/RegistrationService';
import { types, actions } from './reducer';

function* registerAgencyDetails({ data }) {
    try {
        yield call(RegistrationService.setAgencyDetails, data);
        yield put(actions.registerAgencyDetailsSucceeded(data));
    } catch (error) {
        yield put(actions.registerAgencyDetailsFailed(extractError(error)));
    }
}

function* watchRegisterAgencyDetails() {
    yield takeEvery(
        types.REQUEST_REGISTER_AGENCY_DETAILS,
        registerAgencyDetails,
    );
}

function* agencyDetailsSaga() {
    yield all([
        fork(watchRegisterAgencyDetails),
    ]);
}

export default agencyDetailsSaga;
