import {
    call,
    takeEvery,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import RegistrationService from '@/services/RegistrationService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* resendRegistrationPhoneCode() {
    try {
        yield call(RegistrationService.resendConfirmPhoneText);
    } catch (error) {
        if (error.response.message === 'phone_already_confirmed') {
            yield put(actions.registerVerifyPhoneSucceeded());
        }
    }
}

function* requestRegisterVerifyPhone({ code }) {
    try {
        yield call(RegistrationService.confirmPhone, code);
        yield put(actions.registerVerifyPhoneSucceeded(code));
    } catch (error) {
        yield put(actions.registerVerifyPhoneFailed(extractError(error)));
    }
}

function* editPhone({ data }) {
    const { prefix, phone } = data;
    try {
        yield call(RegistrationService.changePhoneNumber, data);
        yield put(actions.editPhoneSucceeded(prefix, phone, 'Phone number updated'));
    } catch (error) {
        yield put(actions.editPhoneFailed(extractError(error)));
    }
}

function* watchResendRegistrationPhoneCode() {
    yield takeEvery(
        types.REQUEST_RESEND_REGISTRATION_PHONE_CODE,
        resendRegistrationPhoneCode,
    );
}

function* watchRegisterVerifyPhone() {
    yield takeEvery(
        types.REQUEST_REGISTER_VERIFY_PHONE,
        requestRegisterVerifyPhone,
    );
}

function* watchEditPhone() {
    yield takeEvery(types.REQUEST_EDIT_PHONE, editPhone);
}

function* verifyPhoneSaga() {
    yield all([
        fork(watchResendRegistrationPhoneCode),
        fork(watchRegisterVerifyPhone),
        fork(watchEditPhone),
    ]);
}

export default verifyPhoneSaga;
