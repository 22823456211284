import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get tour count
    requestGetAccommodationCount: [],
    getAccommodationCountSucceeded: ['data'],
    getAccommodationCountFailed: ['errors'],
});

export default createReducer(initialState, {
    [types.REQUEST_GET_ACCOMMODATION_COUNT]: (state) => ({
        ...state,
        loading: true,
        errors: null,
    }),
    [types.GET_ACCOMMODATION_COUNT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        accommodationCount: data,
        loading: false,
    }),
    [types.GET_ACCOMMODATION_COUNT_FAILED]: (state, { errors }) => ({
        ...state,
        loading: false,
        errors,
    }),
});

export { types, actions };
