import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
    Row,
    Col,
    Space,
    Typography,
    Button,
} from 'antd';
import { ClockIcon, PartnerIcon, RightIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/b2b/supplier/collect/reducer';
import { selectors } from '@/redux/b2b/supplier/collect/model';

const ProductCollection = ({ data, serviceStatus, serviceId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sendRequest = useCallback(() => {
        dispatch(actions.sendServiceRequest({
            service_id: serviceId,
            status: 2,
        }));
    }, [dispatch, serviceId]);

    const message = useSelector(selectors.message);
    const [pendingStatus, setPendingStatus] = useState(false);

    useEffect(() => {
        if (message === 'Success' || serviceStatus === '2') {
            setPendingStatus(true);
        }
    }, [dispatch, message, serviceStatus]);

    return (
        <Row
            justify="space-between"
            className={clsx(
                'sell-product-info',
                pendingStatus ? 'pending-color' : 'base-color',
            )}
        >
            <Col>
                <Space size={35}>
                    <PartnerIcon />
                    {pendingStatus ? (
                        <Typography.Text className="pending-color">
                            <div>
                                {t('Waiting for Owner Approval')}
                            </div>
                            <div>
                                {t('Immediately after approval, you will have full access to make reservations for your customers')}
                            </div>
                        </Typography.Text>
                    ) : (
                        <Typography.Text className="base-color">
                            <div>
                                {`${t('You can increase your revenue by selling the B2B products')}!`}
                            </div>
                            <div>
                                {`${t('Make a request by collecting this product and be a worldwide vendor')}.`}
                            </div>
                        </Typography.Text>
                    )}
                </Space>
            </Col>
            <Col>
                <Space size={25}>
                    <div className={clsx('earning-value', pendingStatus && 'pending-color')}>
                        <div>
                            {data.earning}
                            {data.commission_type === 'Percentage' ? '%' : data.currency_icon}
                        </div>
                        <div>{t('your earning')}</div>
                    </div>
                    <Button
                        className={pendingStatus ? 'pending-button' : 'base-button'}
                        onClick={() => serviceStatus === null && sendRequest()}
                    >
                        {pendingStatus ? (
                            <span className="btn-text">
                                <span className="svg-wrapper">
                                    <ClockIcon />
                                </span>
                                {t('pending approval').toUpperCase()}
                            </span>
                        ) : (
                            <>
                                <span>{t('collect now').toUpperCase()}</span>
                                <RightIcon />
                            </>
                        )}
                    </Button>
                </Space>
            </Col>
        </Row>
    );
};

export default ProductCollection;
