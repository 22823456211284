import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import TaxesService from '@/services/SettingsServices/TaxesService';
import { types, actions } from './reducer';

function* deleteTax({ id }) {
    try {
        yield call(TaxesService.deleteOne, id);
        yield put(actions.deleteTaxSucceeded(id, 'tax deleted successfully'));
    } catch (error) {
        yield put(actions.deleteTaxFailed(extractError(error)));
    }
}

function* watchDeleteTax() {
    yield takeEvery(
        types.REQUEST_DELETE_TAX,
        deleteTax,
    );
}

function* taxDeleteSaga() {
    yield all([
        fork(watchDeleteTax),
    ]);
}

export default taxDeleteSaga;
