import {
    Row,
    Col,
    Image,
    Typography,
    Space,
} from 'antd';
import { useTranslation } from 'react-i18next';

import BlockDescription from '@/components/Information/WithoutImage/Block';
import DataContainer from '@/components/DataContainer';
import { useCurrency } from '@/hooks/useCurrency';
import decodeHtml from '@/helpers/decodeHtml';

// const getBackendImageUrl = (imageUrl) => {
//     const backendUrl = process.env.REACT_APP_API_URL.slice(0, -3);
//     return backendUrl + imageUrl;
// };

const GeneralDetails = ({ data }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    return (
        <DataContainer title={t('General Details')}>
            <Space direction="vertical">
                <BlockDescription name={t('Package Name')}>
                    <Typography.Text>{data.name}</Typography.Text>
                </BlockDescription>

                <BlockDescription name={t('Duration')}>
                    <Typography.Text>
                        {data.duration}
                        {' '}
                        {data.duration_type}
                    </Typography.Text>
                </BlockDescription>

                <BlockDescription name={t('Tour Style')}>
                    {/* <Space size={4} split=",">
                        {data.style.map((style) => (
                            <Typography key={style.id}>
                                {style.name}
                            </Typography>
                        ))}
                    </Space> */}
                </BlockDescription>

                <BlockDescription name={t('Default Prices')}>
                    <Space direction="vertical" size={0}>
                        <Typography.Text>
                            {data.adults}
                            {' '}
                            {t('Adults')}
                            {' '}
                            {`(${currencyIcon}${data.adult_price} / ${data.adult_price_type})`}
                        </Typography.Text>

                        {data.children !== '0' && (
                            <Typography.Text>
                                {data.children}
                                {' '}
                                {t('Child')}
                                {' '}
                                {`(${currencyIcon}${data.child_price} / ${data.child_price_type})`}
                            </Typography.Text>
                        )}
                        {data.infants !== 0 && (
                            <Typography.Text>
                                {data.infants}
                                {' '}
                                {t('Infant')}
                                {' '}
                                {`(${currencyIcon}${data.infant_price} / ${data.infant_price_type})`}
                            </Typography.Text>
                        )}
                    </Space>
                </BlockDescription>

                <BlockDescription name={t('Extra Prices')}>
                    {data.extra_services.map((extra) => (
                        <Typography.Text key={extra.id}>
                            {extra.name}
                            {' '}
                            {extra.price === '0.00' ? `${(t('inclusive'))}` : `(${currencyIcon}${extra.price} / ${extra.price_type})`}
                        </Typography.Text>
                    ))}
                </BlockDescription>

                <BlockDescription name={t('Description')}>
                    <Typography.Text>
                        <div dangerouslySetInnerHTML={{ __html: decodeHtml(data.description) }} />
                    </Typography.Text>
                </BlockDescription>

                <BlockDescription name={t('Tour Gallery')}>
                    <Row gutter={20}>
                        {data.images.map((image, index) => (
                            <Col
                                /*
                                    i can't really think of a good solution here cuz
                                    there is no unique identifier for tour images
                                    yes you could say image link, but someone can add
                                    the same image twice and that would be a disaster
                                    so indexes are the only solution
                                    if someone thinks of a better fix, please change this
                                */
                                // eslint-disable-next-line react/no-array-index-key
                                key={(index).toString()}
                                // flex={1}
                                span={6}
                            >
                                <Image src={image.path} preview={false} />
                                {/* {data.images[index] && (
                                    <Image src={getBackendImageUrl(data.images[index])} />
                                )} */}
                            </Col>
                        ))}
                    </Row>
                </BlockDescription>
            </Space>
        </DataContainer>
    );
};

export default GeneralDetails;
