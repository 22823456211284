import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    title: {
        name: 'Title',
        required: true,
        min: 5,
        max: 150,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    location: {
        name: 'Location',
        required: true,
        min: 3,
        max: 150,
    },
};

export default buildValidationsFromConfig(config);
