import { Switch, Route, Redirect } from 'react-router-dom';
import BookingEngine from './BookingEngine';
import ChannelManager from './ChannelManager';
import Marketing from './Marketing';

export const routes = [
    {
        name: 'Booking Engine',
        icon: 'booking engine icon',
        description: 'booking engine desc',
        url: '/booking-engine',
        component: BookingEngine,
    },
    {
        name: 'Channel Manager',
        icon: 'channel manager icon',
        description: 'channel manager desc',
        url: '/channel-manager',
        component: ChannelManager,
    },
    {
        name: 'Marketing',
        icon: 'marketing icon',
        description: 'marketing desc',
        url: '/marketing',
        component: Marketing,
    },
];

const Integrations = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/integrations${route.url}`}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/app/integrations/booking-engine" />
        </Switch>
    );
};

export default Integrations;
