import { all, fork, takeEvery } from 'redux-saga/effects';
import pagination from '@/helpers/pagination';
import CustomersService from '@/services/CustomersService';
import { types } from './reducer';

function* watchFetchCustomers() {
    yield takeEvery(
        types.REQUEST_FETCH_CUSTOMERS,
        pagination('customers').saga(CustomersService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchCustomers),
    ]);
}

export default paginationSaga;
