import {
    call,
    takeEvery,
    all,
    put,
    fork,
    select,
} from 'redux-saga/effects';

import ResetPasswordService from '@/services/ResetPasswordService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* sendEmailVerificationCode(email) {
    const data = { email, recaptcha_token: 'temporary' };
    yield call(ResetPasswordService.resetPassword, data);
}

function* resendEmailCode() {
    try {
        const email = yield select(
            (state) => state.authentication.forgotPassword.email,
        );
        yield call(sendEmailVerificationCode, email);
        yield put(actions.resendForgotPasswordEmailCodeSucceeded('OTP code resent successfully'));
    } catch (error) {
        const message = extractError(error);
        yield put(actions.resendForgotPasswordEmailCodeFailed(message));
    }
}

function* forgotPasswordEmail({ email }) {
    try {
        yield call(sendEmailVerificationCode, email);
        yield put(actions.forgotPasswordEmailSucceeded(email));
    } catch (error) {
        yield put(actions.forgotPasswordEmailFailed(extractError(error)));
    }
}

function* forgotPasswordVerifyEmail({ code }) {
    try {
        const { data: { token } } = yield call(
            ResetPasswordService.resetPasswordVerifyEmail,
            { pin: code },
        );
        yield put(actions.forgotPasswordVerifyEmailSucceeded(code, token));
    } catch (error) {
        yield put(actions.forgotPasswordVerifyEmailFailed(extractError(error)));
    }
}

function* forgotPasswordSetPassword({ data }) {
    const token = yield select(
        (state) => state.authentication.forgotPassword.token,
    );
    try {
        yield call(
            ResetPasswordService.resetPasswordSetNewEmail,
            token,
            data,
        );
        yield put(actions.forgotPasswordSetPasswordSucceeded('Your password has been reset successfully'));
    } catch (error) {
        const message = extractError(error);

        if (message === 'Invalid token!') {
            yield put(actions.forgotPasswordSetPasswordFailedInvalidToken());
            return;
        }

        yield put(actions.forgotPasswordSetPasswordFailed(message));
    }
}

function* watchResendEmailCode() {
    yield takeEvery(types.REQUEST_RESEND_FORGOT_PASSWORD_EMAIL_CODE, resendEmailCode);
}

function* watchForgotPasswordEmail() {
    yield takeEvery(types.REQUEST_FORGOT_PASSWORD_EMAIL, forgotPasswordEmail);
}

function* watchForgotPasswordVerifyEmail() {
    yield takeEvery(types.REQUEST_FORGOT_PASSWORD_VERIFY_EMAIL, forgotPasswordVerifyEmail);
}

function* watchForgotPasswordSetPassword() {
    yield takeEvery(types.REQUEST_FORGOT_PASSWORD_SET_PASSWORD, forgotPasswordSetPassword);
}

function* forgotPasswordSaga() {
    yield all([
        fork(watchResendEmailCode),
        fork(watchForgotPasswordEmail),
        fork(watchForgotPasswordVerifyEmail),
        fork(watchForgotPasswordSetPassword),
    ]);
}

export default forgotPasswordSaga;
