import { useSelector } from 'react-redux';
import { Row, Col, Card } from 'antd';
import { selectors } from '@/redux/tour/packages/view/model';
import PhotoGallery from '@/components/PhotoGallery';
import ViewWidgetCategories from '@/components/ViewWidgetCategories';
import GeneralData from './GeneralData';
import ViewIncusionsExclusions from './ViewInclusionsExclusions';

const TourInfo = ({
    myService,
}) => {
    const packageDetails = useSelector(selectors.packageDetails);
    return (
        <Row gutter={[30, 30]}>
            <Col lg={14} xs={24}>
                <Card bordered={false} className="left-col">
                    <GeneralData
                        data={packageDetails}
                        myService={myService}
                    />

                    <ViewWidgetCategories data={packageDetails.widget_categories} />

                    <ViewIncusionsExclusions data={packageDetails} />
                </Card>
            </Col>

            <Col lg={10} xs={24}>
                <Card bordered={false} className="right-col">
                    <PhotoGallery data={packageDetails.images} />
                </Card>
            </Col>
        </Row>
    );
};

export default TourInfo;
