import clsx from 'clsx';

const ActionIcon = ({
    children,
    className,
    ...props
}) => {
    return (
        <button
            className={clsx('action-button', className)}
            type="button"
            {...props}
        >
            {children}
        </button>
    );
};

export default ActionIcon;
