import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import FlightService from '@/services/FlightService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* viewFlight({ id }) {
    try {
        const res = yield call(FlightService.getOne, id);

        yield put(actions.viewFlightSucceeded(res.data));
    } catch (error) {
        yield put(actions.viewFlightFailed(extractError(error)));
    }
}

function* watchViewFlight() {
    yield takeEvery(
        types.REQUEST_VIEW_FLIGHT,
        viewFlight,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewFlight),
    ]);
}

export default viewSaga;
