import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Divider,
    Select,
    Button,
    Card,
    Avatar,
    Tooltip,
    Typography,
    Space,
    Popover,
} from 'antd';

import BlockDescription from '@/components/Information/WithImage/Block';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import {
    AdultIcon,
    BedIcon,
    ChildIcon,
    DownIcon,
    InfantIcon,
    TickIcon,
} from '@/assets/images/icons/screens';
import SelectRoom from './SelectRoom';

const RoomDetails = ({
    roomListSelected,
    setRoomListSelected,
    dateStartEnd,
    item,
    type,
    tourId,
}) => {
    const { t } = useTranslation();

    const [isVisible, setVisible] = useState(false);

    const [checkedRoom, setCheckedRoom] = useState(false);

    const options = item.no_rooms !== '0' && new Array(parseInt(item.no_rooms, 10)).fill(null).map((_, i) => (
        <Select.Option
            key={(i).toString()}
            value={i + 1}
        >
            {i + 1}
        </Select.Option>
    ));

    const visibleAmenities = item.amenities?.length >= 6
        ? item.amenities?.slice(0, 5)
        : item.amenities;
    const tooltipAmenities = item.amenities?.length >= 6
        ? item.amenities?.slice(5, item.amenities.length)
        : [];
    const tooltipAmenitiesLength = tooltipAmenities.length;
    // data to submit
    const [noRooms, setNoRooms] = useState(0);
    const [adultNumber, setAdultNumber] = useState(0);
    const [childNumber, setChildNumber] = useState(0);
    const [infantNumber, setInfantNumber] = useState(0);
    const [extraBeds, setExtraBeds] = useState(0);

    return (
        <Row className="room">
            <Col lg={4}>
                <BlockDescription
                    icon={(
                        <AdultIcon />
                    )}
                    label={`${t('Max. Adults')} ${item.adults}`}
                    content={`${item.currency_icon}${type === 'Hotel' ? item.price?.room_price_data?.actual_price : item.price} / ${t('adult')}`}
                    spaceSize={14}
                />
                <BlockDescription
                    icon={(
                        <ChildIcon />
                    )}
                    label={`${t('Max. Children')} ${item.children}`}
                    content={`${item.currency_icon}${item.child_charges} / ${t('child')}`}
                    spaceSize={14}
                />
                <BlockDescription
                    icon={(
                        <InfantIcon />
                    )}
                    label={`${t('Max. Infants')} ${item.infants}`}
                    content={`${item.currency_icon}${item.infant_charges} / ${t('infant')}`}
                    spaceSize={14}
                />
                {type === 'Hotel' && (
                    <BlockDescription
                        icon={(
                            <BedIcon />
                        )}
                        label={`${t('Extra Bed')} ${item.extra_beds}`}
                        content={`${t('Extra Charges')} ${item.extra_bed_charge}${item.extra_bed_charges_type === '0' ? item.currency_icon : item.extra_bed_charges_type}`}
                        spaceSize={14}
                    />
                )}
            </Col>

            <Col lg={14}>
                {type === 'Tour Package' ? (
                    <Row>
                        {item.amenities?.map((amenity) => (
                            <Col lg={8} key={amenity.id} className="tour-item_amenities">
                                <Space>
                                    <TickIcon />
                                    {amenity.name}
                                </Space>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Row>
                        <Col lg={1}>
                            <Divider type="vertical" />
                        </Col>
                        <Col lg={6}>
                            <div>
                                <span className="title-amenitites">{t('Amenities')}</span>
                                {visibleAmenities.map((amenity) => (
                                    <Col lg={24} key={amenity.id}>
                                        <Space size={10}>
                                            <TickIcon />
                                            {amenity.name}
                                        </Space>
                                    </Col>
                                ))}
                                <Tooltip
                                    color="#ffff"
                                    open={isVisible}
                                    overlayClassName="amenities-tooltip"
                                    title={(
                                        <Typography.Text>
                                            {tooltipAmenities?.map((amenity) => (
                                                <div key={amenity.id}>
                                                    <Space size={10}>
                                                        <TickIcon />
                                                        {amenity.name}
                                                    </Space>
                                                </div>
                                            ))}
                                        </Typography.Text>
                                    )}
                                >
                                    {tooltipAmenitiesLength !== 0 && (
                                        <Button
                                            type="text"
                                            onClick={() => setVisible(!isVisible)}
                                        >
                                            <Typography.Text underline>
                                                {`${t('View more')}...`}
                                            </Typography.Text>
                                        </Button>
                                    )}
                                </Tooltip>
                            </div>
                        </Col>

                        {item.offer && (
                            <Col lg={17}>
                                <Card className="promotion-card-dashed">
                                    <Card.Meta
                                        avatar={(
                                            <Avatar
                                                size={80}
                                                className={clsx(
                                                    item.offer.type === 'Early booking' && 'early-booking',
                                                    item.offer.type === 'Last minute' && 'last-minute',
                                                    item.offer.type === 'Custom offer' && 'custom-offer',
                                                    item.offer.type === 'free night' && 'free-night',
                                                )}
                                            >
                                                {`- ${item.offer.discount} ${item.offer.discount_type === 'Percentage' ? '%' : `${item.currency_icon}`}`}
                                            </Avatar>
                                        )}
                                        description={(
                                            <>
                                                <div
                                                    className={clsx(
                                                        'offer-name',
                                                        item.offer.type === 'Early booking' && 'early-booking',
                                                        item.offer.type === 'Last minute' && 'last-minute',
                                                        item.offer.type === 'Custom offer' && 'custom-offer',
                                                        item.offer.type === 'free night' && 'free-night',
                                                    )}
                                                >
                                                    {item.offer.name}
                                                </div>
                                                <InlineDescription
                                                    label={t('Category')}
                                                    content={item.offer.type}
                                                />
                                                <InlineDescription
                                                    label={t('Duration')}
                                                    content={`${item.offer.date_start} - ${item.offer.date_end}`}
                                                />
                                            </>
                                        )}
                                    />
                                </Card>
                            </Col>
                        )}
                    </Row>
                )}
            </Col>

            <Col lg={1} className="reservation-list-divider">
                <Divider type="vertical" />
            </Col>

            <Col lg={5}>
                <Row gutter={[0, 10]} className="reservation-list_right-col">
                    <Col lg={24}>
                        {type === 'Hotel' && (
                            <div className="room_price">
                                {`${item.currency_icon}${item.price?.room_price_data?.actual_price} / ${t('night')}`}
                            </div>
                        )}
                        <div className="no-of-rooms">{`${t('No. of rooms')}: ${item.no_rooms}`}</div>
                    </Col>
                    <Col lg={24}>
                        <Select
                            // open
                            placeholder={t('Available Rooms')}
                            onSelect={(value) => setNoRooms(value)}
                            disabled={checkedRoom}
                        >
                            {options}
                        </Select>
                    </Col>
                    <Col lg={24}>
                        <div>
                            <div>
                                <Popover
                                    trigger="click"
                                    placement="bottom"
                                    disabled={checkedRoom}
                                    getPopupContainer={(trigger) => {
                                        return trigger?.parentElement;
                                    }}
                                    className="reservation-guests_dropdown-2"
                                    content={(
                                        <div className="reservation-guests_dropdown_content">
                                            <div>
                                                <Space size={35}>
                                                    <span>{t('Adults')}</span>
                                                    <IncrementNumberInput
                                                        max={item.adults * noRooms}
                                                        hasInputBorder={false}
                                                        onChange={setAdultNumber}
                                                        defaultCount={adultNumber}
                                                    />
                                                </Space>
                                            </div>
                                            <div>
                                                <Space size={20}>
                                                    <span>{t('Children')}</span>
                                                    <IncrementNumberInput
                                                        max={item.children * noRooms}
                                                        hasInputBorder={false}
                                                        onChange={setChildNumber}
                                                        defaultCount={childNumber}
                                                    />
                                                </Space>
                                            </div>
                                            <div>
                                                <Space size={30}>
                                                    <span>{t('Infants')}</span>
                                                    <IncrementNumberInput
                                                        max={item.infants * noRooms}
                                                        hasInputBorder={false}
                                                        onChange={setInfantNumber}
                                                        defaultCount={infantNumber}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {/* <div className="guests_filters"> */}
                                    <Button disabled={checkedRoom}>
                                        <div className="filter-guest_title">
                                            {t('No. of guests')}
                                            <DownIcon />
                                        </div>
                                    </Button>
                                    {/* </div> */}
                                </Popover>
                            </div>
                            <div className="guests-options_picked">
                                {adultNumber !== 0 && (
                                    `${adultNumber} ${(adultNumber === 1) ? t('Adult ') : t('Adults ')}`
                                )}
                                {childNumber !== 0 && (
                                    `${childNumber} ${(childNumber === 1) ? t('Child ') : t('Children ')}`
                                )}
                                {infantNumber !== 0 && (
                                    `${infantNumber} ${(infantNumber === 1) ? t('Infant ') : t('Infants ')}`
                                )}
                            </div>
                            {type === 'Hotel' && (
                                <div>
                                    <Space size={20}>
                                        <span>{t('Extra Beds')}</span>
                                        <IncrementNumberInput
                                            max={item.extra_beds * noRooms}
                                            hasInputBorder={false}
                                            onChange={setExtraBeds}
                                            defaultCount={extraBeds}
                                            disableButton={checkedRoom}
                                        />
                                    </Space>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col lg={24}>
                        <SelectRoom
                            roomListSelected={roomListSelected}
                            setRoomListSelected={setRoomListSelected}
                            checkedRoom={checkedRoom}
                            setCheckedRoom={setCheckedRoom}
                            dateStartEnd={dateStartEnd}
                            roomId={item.id}
                            roomName={item.type_name}
                            roomPrice={type === 'Hotel' ? item.price?.room_price_data?.actual_price : item.price}
                            noRooms={noRooms}
                            adultNumber={adultNumber}
                            childNumber={childNumber}
                            infantNumber={infantNumber}
                            extraBeds={extraBeds}
                            extraBedCharge={item.extra_bed_charge}
                            extraBedChargeType={item.extra_bed_charges_type === '0' ? item.currency_icon : item.extra_bed_charges_type}
                            type={type}
                            tourId={tourId}
                            roomImg={item.images?.[0]?.path}
                            currency={item.currency_icon}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default RoomDetails;
