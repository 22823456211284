import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { inclExclFormat } from '@/helpers/mapping';
import { actions } from '@/redux/tour/cruise/create/reducer';
import { selectors } from '@/redux/tour/cruise/create/model';

import Inclusions from './Inclusions';
import Exclusions from './Exclusions';

const formFields = ['inclusions', 'exclusions'];

const InclusionsExclusions = ({ form, isEdit, regStep }) => {
    const dispatch = useDispatch();

    const onFinish = useCallback((values) => {
        const data = {
            inclusions: values.inclusions,
            exclusions: values.exclusions,
        };
        if (isEdit && regStep === 4) {
            const step = 4;
            const completedStep = 4;
            dispatch(actions.requestSubmitCruiseInclusionsExclusions(
                data,
                step,
                completedStep,
            ));
        } else {
            const step = 4;
            const completedStep = 3;
            dispatch(actions.requestSubmitCruiseInclusionsExclusions(
                data,
                step,
                completedStep,
            ));
        }
    }, [dispatch]);

    const inclusions = useSelector(selectors.inclusions);
    const exclusions = useSelector(selectors.exclusions);

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => {
        return {
            inclusions: inclExclFormat(inclusions),
            exclusions: inclExclFormat(exclusions),

        };
    }, [inclusions, exclusions]);

    return (
        <Form
            form={form}
            id="cruise-inclusion-exclusion-form"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={initialData || {}}
        >
            <Inclusions
                initialInclusions={initialData?.inclusions}
            />

            <Exclusions
                initialExclusions={initialData?.exclusions}
            />
        </Form>
    );
};

export default InclusionsExclusions;
