import { all, fork, takeEvery } from 'redux-saga/effects';
import pagination from '@/helpers/pagination';
import BillingService from '@/services/SettingsServices/BillingService';
import { types } from './reducer';

function* watchFetchBillList() {
    yield takeEvery(
        types.REQUEST_FETCH_BILL_LIST,
        pagination('billList').saga(BillingService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchBillList),
    ]);
}

export default paginationSaga;
