import { createActions } from 'reduxsauce';
import model from './model';

export const { Types: types, Creators: actions } = createActions({
    // view activity
    requestViewTourActivity: ['id'],
    viewTourActivitySucceeded: [
        'activity',
        'partner',
    ],
    viewTourActivityFailed: ['error'],

    // get activity time list
    requestGetActivityTimeList: ['id', 'activityDate'],
    getActivityTimeListSucceeded: ['listOfTime', 'dateOfActivity'],
    getActivityTimeListFailed: ['error'],

    // clear activity view state
    clearActivityViewState: ['null'],
});

export default {
    // view activity
    [types.REQUEST_VIEW_TOUR_ACTIVITY]: (state) => ({
        ...state,
        view: {
            ...state.view,
            activity: model.activity,
            partner: model.partner,

            loading: true,
            error: null,
        },
    }),
    [types.VIEW_TOUR_ACTIVITY_SUCCEEDED]: (state, { activity, partner }) => ({
        ...state,
        view: {
            ...state.view,
            activity,
            partner,
            loading: false,
        },
    }),
    [types.VIEW_TOUR_ACTIVITY_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // activity time list
    [types.REQUEST_GET_ACTIVITY_TIME_LIST]: (state) => ({
        ...state,
        view: {
            ...state.view,
            timeList: model.timeList,
            timeLoading: true,
            error: null,
        },
    }),
    [types.GET_ACTIVITY_TIME_LIST_SUCCEEDED]: (state, { listOfTime, dateOfActivity }) => ({
        ...state,
        view: {
            ...state.view,
            timeList: listOfTime,
            activityDate: dateOfActivity,
            timeLoading: false,
        },
    }),
    [types.GET_ACTIVITY_TIME_LIST_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            timeLoading: false,
            error,
        },
    }),

    // clear activity view state
    [types.CLEAR_ACTIVITY_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            activity: null,
            partner: null,
            timeList: [],
            activityDate: null,
            loading: false,
            error: null,
        },
    }),
};
