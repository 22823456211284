import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Button,
    Space,
} from 'antd';
import clsx from 'clsx';
import { DeleteIcon, DownIcon } from '@/assets/images/icons/screens';
import DashedContainer from '@/components/DashedContainer';
import Details from './Details';
import ImagesUpload from './ImagesUpload';
import Amenities from './Amenities';

const ShipItem = ({
    field,
    remove,
    itemIndex,
    formListName,
    initialAmenities,
    formFields,
    childN,
    infantN,
    validations,
}) => {
    const { t } = useTranslation();

    const [fieldIndex, setFieldIndex] = useState(null);

    return (
        <div
            className="ship-item-container"
        >
            <DashedContainer title="Cabin Type">
                <Row gutter={[30, 0]}>
                    <Col
                        xs={24}
                        className={clsx(
                            'hidden-inputs',
                            itemIndex !== field.key && 'hidden',
                            field.name === fieldIndex && 'show',
                        )}
                    >
                        <ImagesUpload field={field} />

                        <Details
                            field={field}
                            formListName={formListName}
                            childNumber={childN}
                            infantNumber={infantN}
                            validations={validations}
                        />

                        <Amenities field={field} initialAmenities={initialAmenities} />
                    </Col>

                    <Col xs={24}>
                        <Row justify={itemIndex !== field.key ? 'space-between' : 'end'} align="middle">
                            {itemIndex !== field.key && field.name !== fieldIndex && (
                                <Col>
                                    <Space>
                                        {formFields.cabins?.[field.name]?.cabin_type}
                                        {`(${formFields.cabins?.[field.name]?.number_of_cabins})`}
                                    </Space>
                                </Col>
                            )}
                            <Col>
                                <Space>
                                    {itemIndex !== field.key && (
                                        <Button
                                            className="button-underline"
                                            type="link"
                                            onClick={() => {
                                                fieldIndex === field.name
                                                    ? setFieldIndex(null)
                                                    : setFieldIndex(field.name);
                                            }}
                                        >
                                            {field.name === fieldIndex ? 'Hide' : <DownIcon />}
                                        </Button>
                                    )}
                                    <Button
                                        className="button delete-button-outlined"
                                        onClick={() => remove(field.name)}
                                    >
                                        <DeleteIcon />
                                        {t('Delete cabin type').toUpperCase()}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </DashedContainer>
        </div>
    );
};

export default ShipItem;
