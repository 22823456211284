import { createActions } from 'reduxsauce';
import { upseartItem } from '@/helpers/CRUD';

const { Types: types, Creators: actions } = createActions({

    // create tax
    requestSubmitTaxDetails: ['id', 'data'],
    submitTaxDetailsSucceeded: ['data', 'messageSuccess'],
    submitTaxDetailsFailed: ['errors'],

    // fetch for edit
    requestFetchTaxDetails: ['id'],
    fetchTaxDetailsSucceeded: ['data'],
    fetchTaxDetailsFailed: ['errors'],

    // clear create tax state
    clearCreateTaxState: null,
});

export default {
    // create tax
    [types.REQUEST_SUBMIT_TAX_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_TAX_DETAILS_SUCCEEDED]: (state, { data, messageSuccess }) => ({
        ...state,
        data: upseartItem(state.data, data),
        create: {
            ...state.create,
            taxDetails: data,
            submitting: false,
            messageSuccess,
        },
    }),
    [types.SUBMIT_TAX_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_TAX_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.FETCH_TAX_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            taxDetails: data,
            loading: false,
        },
    }),
    [types.FETCH_TAX_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // clear create tax state
    [types.CLEAR_CREATE_TAX_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            taxDetails: null,
            messageSuccess: '',
            errors: null,
            submitting: false,
            loading: false,
        },
    }),
};

export { types, actions };
