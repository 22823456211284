import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    salary: {
        name: 'Salary',
    },
};

export default buildValidationsFromConfig(config);
