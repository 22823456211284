import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { selectors } from '@/redux/customers/view/model';
import { actions } from '@/redux/customers/view/reducer';
import { actions as invoiceActions } from '@/redux/invoice/reducer';
import { dateFormat } from '@/helpers/dateFormat';
import PersonalDetails from './PersonalDetails';
import WorkDetails from './WorkDetails';

const initialFilters = {
    prices: [80, 420],
};

const ViewOne = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const customer = useSelector(selectors.customer);
    const loading = useSelector(selectors.loading);
    const invoices = useSelector((state) => state.invoice.data);
    const totalInvoices = useSelector((state) => state.invoice.totalItems);
    const {
        fetchingLoading: fLoading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.invoice.data);

    const [filters, baseSetFilters] = useState(initialFilters);
    const [page, setPage] = useState(1);

    const handleFetchInvoices = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                min_amount: filters.prices[0],
                max_amount: filters.prices[1],
                type: filters.type,
                status: filters.status,
                date_start: filters.date?.[0]?.format(dateFormat),
                date_end: filters.date?.[1]?.format(dateFormat),
                widgets: filters.widgets, // 1 created from hola app, 0 created from website
                sort: filters.sort,
                name: filters.name,
                customer_list: 1,
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            filters.prices[1] !== 0
            && dispatch(invoiceActions.requestFetchInvoices(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters.type,
            filters.status,
            filters.widgets,
            filters.date,
            filters.name,
            filters.sort,
            filters.prices,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    useEffect(() => {
        dispatch(actions.requestViewCustomer(id));
    }, [dispatch, id]);

    useEffect(() => {
        handleFetchInvoices({});
    }, [handleFetchInvoices]);

    const handleFetchMore = useCallback(() => {
        handleFetchInvoices({ currentPage: page, fetchMore: true });
    }, [handleFetchInvoices, page]);

    return (customer === null || loading) ? 'loading' : (
        <Row gutter={30} className="customer-view-one">
            <Col md={6} xs={24}>
                <div className="personal-info">
                    <PersonalDetails data={customer} />
                </div>
            </Col>
            <Col md={18} xs={24}>
                <div className="work-info">
                    <WorkDetails
                        invoices={invoices}
                        totalInvoices={totalInvoices}
                        filters={filters}
                        setFilters={setFilters}
                        usedForCustomerInvoices={true}
                        handleFetchMore={handleFetchMore}
                        isLoadingMore={isLoadingMore}
                        hasMore={hasMore}
                        loading={fLoading}
                        customerData={customer}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default ViewOne;
