import { useTranslation } from 'react-i18next';
import {
    Space,
    Rate,
    Typography,
    Card,
    Row,
    Col,
    Image,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import DataContainer from '@/components/DataContainer';
import GoogleMap from '@/components/GoogleMap';

const Details = ({ data }) => {
    const { t } = useTranslation();
    const { policy: policyData } = data;
    const { selected_methods: paymentOptions } = policyData;
    const { contact: contactPerson } = data;

    return (
        <DataContainer title={t('General Details')}>
            <Space direction="vertical" size={15}>
                <BlockDescription name={t('Hotel Rates')}>
                    <Rate disabled count={5} value={data.stars} />
                </BlockDescription>

                <BlockDescription name={t('Hotel Name')}>
                    <Typography.Text>
                        {data.name}
                    </Typography.Text>
                </BlockDescription>

                <BlockDescription name={t('Address & Location')}>
                    <Typography.Text>
                        {data.address}
                    </Typography.Text>
                </BlockDescription>

                <Card>
                    <GoogleMap latitude={data.latitude} longitude={data.longitude} />
                </Card>

                <BlockDescription name={t('Policy')}>
                    <div>
                        <Typography.Text>
                            {`${t('Check in Time')} ${policyData.check_in_time}
                            - ${t('Check out Time')} ${policyData.check_out_time} `}
                        </Typography.Text>
                    </div>
                    <div>
                        <Typography.Text>
                            {`${t('Payment Option')}: 
                            ${paymentOptions.map((option) => (option.name)).join(', ')}`}
                        </Typography.Text>
                    </div>
                </BlockDescription>

                <BlockDescription name={t('Contact Person')}>
                    <div>
                        <Typography.Text>
                            {contactPerson.first_name}
                            {' '}
                            {contactPerson.last_name}
                        </Typography.Text>
                    </div>
                    <div>
                        <Typography.Text>
                            {contactPerson.position}
                        </Typography.Text>
                    </div>
                    <div>
                        <Typography.Text>
                            {contactPerson.phone}
                        </Typography.Text>
                    </div>
                    <div>
                        <Typography.Text>
                            {contactPerson.email}
                        </Typography.Text>
                    </div>
                    <div>
                        <Typography.Text>
                            {contactPerson.webiste}
                        </Typography.Text>
                    </div>
                </BlockDescription>

                <BlockDescription name={t('Photo Gallery')}>
                    <Row gutter={20}>
                        {data.images.map((image, index) => (
                            <Col
                                // eslint-disable-next-line react/no-array-index-key
                                key={(index).toString()}
                                span={6}
                            >
                                <Image src={image.path} preview={false} />
                            </Col>
                        ))}
                    </Row>
                </BlockDescription>
            </Space>
        </DataContainer>
    );
};

export default Details;
