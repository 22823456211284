import {
    useState,
    useCallback,
    useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Divider,
    Form,
    Input,
    Button,
    Typography,
} from 'antd';
import {
    CloseCircleIcon,
    InfoCircleIcon,
    PlusIcon,
} from '@/assets/images/icons/screens';
import CustomCheckBoxGroup from '@/components/CustomCheckBoxGroup';
import useNotification from '@/hooks/useNotification';

import { actions } from '@/redux/employees/create/reducer';
import { selectors } from '@/redux/employees/create/model';

import EditSection from './EditSection';

const AddNewRole = ({
    roles,
    groups,
    defGroupName,
    setDefGroupName,
    defGroupId,
    groupSelectedId,
    setGroupSelected,
    addMode,
    setAddMode,
    checkedRoles,
    setCheckedRoles,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const messageSuccess = useSelector(selectors.messageSuccess);
    const messageError = useSelector(selectors.messageError);
    const newGroupData = useSelector(selectors.newGroupData);

    // add new group
    const [groupName, setGroupName] = useState('');
    const addNewGroup = useCallback(() => {
        const data = {
            name: groupName,
            roles: roles.map((role) => checkedRoles[`roles${role.id}`]).flat().filter((item) => item !== undefined),
        };
        dispatch(actions.requestAddNewGroup(data));
        setAddMode(false);
        setDefGroupName(groupName);
    }, [groupName, checkedRoles]);

    // edit group
    const editGroup = useCallback(() => {
        const data = {
            name: defGroupName || groups[0]?.name,
            roles: roles.map((role) => checkedRoles[`roles${role.id}`]).flat().filter((item) => item !== undefined),
        };
        dispatch(actions.requestEditGroup(groupSelectedId, data));
    }, [defGroupName, checkedRoles]);

    // close
    const close = () => {
        setGroupName('');
        setCheckedRoles({});
        setAddMode(false);
    };

    useEffect(() => {
        if (newGroupData) {
            setGroupSelected(newGroupData?.name.concat('-', newGroupData?.id));
        }
    }, [newGroupData]);

    useNotification({
        messageSuccess,
        error: messageError,
        item: groupName,
        clearAction: actions.clearGroupActionState(),
        mode: 'list',
    });

    useEffect(() => {
        if (defGroupId) {
            dispatch(actions.requestGetGroupRoles(defGroupId));
        }
    }, [dispatch, defGroupId]);

    return (
        <Form className="permission-section">
            <div className="add-button-wrapper">
                <Button
                    onClick={() => {
                        setAddMode(true);
                        setGroupSelected(1);
                        setCheckedRoles({});
                        setGroupName('');
                    }}
                    className="add-button"
                >
                    <Space size={20}>
                        <PlusIcon />
                        {t('Add new Role')}
                    </Space>
                </Button>
            </div>

            <Divider />

            {addMode ? (
                <div className="new-role">
                    <Input
                        placeholder={t('Title of Job Position / Role')}
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </div>
            ) : (
                <EditSection
                    groups={groups}
                    defGroupName={defGroupName}
                    setDefGroupName={setDefGroupName}
                    setGroupSelected={setGroupSelected}
                    groupSelectedId={groupSelectedId}
                />
            )}

            <Space size={10} className="access-text">
                <InfoCircleIcon />
                <Typography.Text>
                    {t('Select the following action to give access / permissions this type of role')}
                </Typography.Text>
            </Space>
            <div className="role-list">
                {roles.map((role) => (
                    <div key={role.id} className="roles">
                        <CustomCheckBoxGroup
                            selectAllLabel={role.category}
                            options={role.roles}
                            labelParamName="role"
                            valueParamName="id"
                            checkedRoles={checkedRoles}
                            checkedList={checkedRoles[`roles${role.id}`]}
                            setCheckedList={setCheckedRoles}
                            roleId={role.id}
                        />
                    </div>
                ))}
            </div>
            <Row gutter={[25, 25]} justify="end" align="middle" className="button-row">
                <Col>
                    <Button type="text" danger onClick={close}>
                        <CloseCircleIcon />
                        {t('Cancel')}
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={!addMode ? editGroup : addNewGroup}
                    >
                        {t('Save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AddNewRole;
