const jwtTokenName = 'token';
const refreshTokenName = 'refreshToken';

export const setToken = (token, rememberMe = false) => {
    if (rememberMe) {
        localStorage.setItem(jwtTokenName, token);
    } else {
        sessionStorage.setItem(jwtTokenName, token);
    }
};

export const setRefreshToken = (refreshToken, rememberMe = false) => {
    if (rememberMe) {
        localStorage.setItem(refreshTokenName, refreshToken);
    } else {
        sessionStorage.setItem(refreshTokenName, refreshToken);
    }
};

export const getToken = () => localStorage.getItem(jwtTokenName)
        || sessionStorage.getItem(jwtTokenName);

export const getRefreshToken = () => localStorage.getItem(refreshTokenName)
        || sessionStorage.getItem(refreshTokenName);

export const removeToken = () => {
    localStorage.removeItem(jwtTokenName);
    sessionStorage.removeItem(jwtTokenName);
};

export const removeRefreshToken = () => {
    localStorage.removeItem(refreshTokenName);
    sessionStorage.removeItem(refreshTokenName);
};

export const hasTokens = () => !!getToken() && !!getRefreshToken();

export const buildUserFromRegistrationSteps = (registerDetails) => ({
    ...registerDetails.step_1,
    ...registerDetails.step_2,
    services: registerDetails.step_3,
});
