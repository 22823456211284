import {
    all,
    fork,
    takeEvery,
    call,
    put,
    select,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import TripService from '@/services/TourServices/TripService';
import TripGroupService from '@/services/TourServices/TripGroupService';
import { types, actions, groupsRedux } from './reducer';
import { selectors } from './model';

function* viewTourTrip({ id }) {
    try {
        const [
            generalInfo,
            itenerary,
        ] = yield all([
            call(TripService.getOne, id),
            call(TripService.viewItenerary, id),
        ]);
        yield put(actions.viewTourTripSucceeded(
            generalInfo.data,
            itenerary.data,
        ));
    } catch (error) {
        yield put(actions.viewTourTripFailed(extractError(error)));
    }
}

function* fetchTourTripGroups({ id, filters, isFetchingMore }) {
    if (isFetchingMore) {
        yield put(actions.fetchMoreTourTripGroups());
    }
    try {
        const groupService = new TripGroupService(id);
        const response = yield call(groupService.getAll, filters);
        if (isFetchingMore) {
            yield put(
                actions.fetchMoreTourTripGroupsSucceeded(response.data),
            );
            return;
        }
        yield put(actions.fetchTourTripGroupsSucceeded(response.data));
    } catch (error) {
        if (isFetchingMore) {
            yield put(actions.fetchMoreTourTripGroupsFailed(extractError(error)));
            return;
        }
        yield put(actions.fetchTourTripGroupsFailed(extractError(error)));
    }
}

function* viewTourTripGroup({ id }) {
    try {
        const groupService = new TripGroupService(id);
        const response = yield call(groupService.getOne);
        yield put(actions.viewTourTripGroupSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewTourTripGroupFailed(extractError(error)));
    }
}

function* watchViewTourTrip() {
    yield takeEvery(
        types.REQUEST_VIEW_TOUR_TRIP,
        viewTourTrip,
    );
}

function* watchFetchTourTripGroups() {
    yield takeEvery(
        types.REQUEST_FETCH_TOUR_TRIP_GROUPS,
        fetchTourTripGroups,
    );
}

function* watchViewTourTripGroup() {
    yield takeEvery(
        types.REQUEST_VIEW_TOUR_TRIP_GROUP,
        viewTourTripGroup,
    );
}

function* watchAddTourTripGroup() {
    yield takeEvery(
        groupsRedux.mappedTypes.create.request,
        function* ({ data: actionData }) {
            const {
                id,
                data,
            } = actionData;
            const service = new TripGroupService(id);
            yield call(groupsRedux.saga(service).create, { data });
        },
    );
}

function* watchEditTourTripGroup() {
    yield takeEvery(
        groupsRedux.mappedTypes.update.request,
        function* ({ data: actionData }) {
            const { id: groupId } = yield select(selectors.groupDetails);
            const service = new TripGroupService(groupId);
            yield call(groupsRedux.saga(service).update, actionData);
        },
    );
}

function* watchDeleteTourTripGroup() {
    yield takeEvery(
        groupsRedux.mappedTypes.delete.request,
        function* ({ id }) {
            const service = new TripGroupService(id);
            yield call(groupsRedux.saga(service).delete, id);
        },
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewTourTrip),
        fork(watchFetchTourTripGroups),
        fork(watchAddTourTripGroup),
        fork(watchDeleteTourTripGroup),
        fork(watchEditTourTripGroup),
        fork(watchViewTourTripGroup),
    ]);
}

export default viewSaga;
