import { forwardRef } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const GenderSelect = (props, ref) => {
    const { t } = useTranslation();

    return (
        <Select
            ref={ref}
            {...props}
            placeholder={props.placeholder && t('Gender')}
        >
            <Select.Option value="M">
                {t('Male')}
            </Select.Option>

            <Select.Option value="F">
                {t('Female')}
            </Select.Option>
        </Select>
    );
};

export default forwardRef(GenderSelect);
