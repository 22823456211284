import {
    useState,
    useMemo,
    useCallback,
    useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, Typography } from 'antd';

import {
    generateOnStartHandler,
    generateOnProgressHandler,
    generateOnSuccessHandler,
    generateOnRemoveHandler,
    generateOnErrorHandler,
    generateCustomRequestHandler,
} from '../hooks';

import UploadContent from './UploadContent';

const formatFileList = (fileList) => fileList.map((file) => {
    if (file.originFileObj) {
        return file.originFileObj;
    } else {
        return file;
    }
});

const MultipleUpload = ({
    allowedMIMETypes,
    maxCount,
    children,
    value,
    ...props
}) => {
    const { t } = useTranslation();

    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (value) {
            if (value.length !== 0) {
                setFileList(value);
            }
            if (value.fileList !== undefined) {
                setFileList(formatFileList(value.fileList));
            }
        }
    }, []);

    const canUploadFile = useMemo(
        () => (fileList.length < maxCount),
        [fileList.length],
    );

    const isFileAllowedForUpload = useCallback(
        (file) => allowedMIMETypes.includes(file.type),
        [allowedMIMETypes],
    );

    const onStartHandler = generateOnStartHandler({
        canUploadFile,
        isFileAllowedForUpload,
        setFileList,
    });
    const onProgressHandler = generateOnProgressHandler({ setFileList });
    const onSuccessHandler = generateOnSuccessHandler({ setFileList });
    const onRemoveHandler = generateOnRemoveHandler({ setFileList });
    const onErrorHandler = generateOnErrorHandler({ setFileList });
    const customRequestHandler = generateCustomRequestHandler({
        canUploadFile,
        isFileAllowedForUpload,
    });

    const stringAllowedMIMETypes = useMemo(
        () => allowedMIMETypes.join(', '),
        [allowedMIMETypes],
    );

    return (
        <>
            <Upload
                multiple
                {...props}
                value={value}
                fileList={fileList}
                maxCount={maxCount}
                // disabled={!canUploadFile}
                accept={stringAllowedMIMETypes}
                customRequest={customRequestHandler}
                onStart={onStartHandler}
                onProgress={onProgressHandler}
                onSuccess={onSuccessHandler}
                onRemove={onRemoveHandler}
                onError={onErrorHandler}
            >
                <UploadContent />
            </Upload>
            <Typography.Text className="upload-info-text">
                <div className="upload-info-text_container">
                    {t('You can upload up to 5 photos for the tour gallery')}
                </div>
            </Typography.Text>
        </>
    );
};

export default MultipleUpload;
