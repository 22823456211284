import { useState } from 'react';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import MUIFormItem from '@/components/MUIFormItem';
import GoogleMap from '@/components/GoogleMap';
import CustomTextArea from '@/components/TextArea';
import { DeleteIcon, LocationIcon } from '@/assets/images/icons/screens';

import Upload from './Upload';

const IteneraryItem = ({
    field,
    remove,
    validations,
    itemIndex,
    formListName,
    iteneraryList,
    form,
}) => {
    const { t } = useTranslation();

    const [fieldIndex, setFieldIndex] = useState(null);
    const [address, setAddress] = useState(undefined);

    return (
        <Row
            className="itenerary"
            gutter={[15, 15]}
        >
            <Col lg={5}>
                <Upload
                    name={[field.name, 'itinerary_image']}
                />
            </Col>

            <Col lg={19}>
                <MUIFormItem
                    label={t('Title')}
                    name={[field.name, 'title']}
                    rules={validations.title}
                    formListName={formListName}
                >
                    <Input />
                </MUIFormItem>
                <div className={clsx(
                    'hidden-inputs',
                    itemIndex !== field.key && 'hidden',
                    field.name === fieldIndex && 'show',
                )}
                >
                    <CustomTextArea
                        name={[field.name, 'description']}
                        placeholder={t('Description')}
                        rules={validations.description}
                    />

                    <MUIFormItem
                        label={t('Location')}
                        name={[field.name, 'location']}
                        rules={validations.location}
                        formListName={formListName}
                    >
                        <Input
                            suffix={<LocationIcon />}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </MUIFormItem>

                    <Row gutter={15}>
                        <Col lg={12} xs={24}>
                            <MUIFormItem
                                label={t('Map Latitude')}
                                name={[field.name, 'latitude']}
                                formListName={formListName}
                            >
                                <Input />
                            </MUIFormItem>
                        </Col>

                        <Col lg={12} xs={24}>
                            <MUIFormItem
                                label={t('Map Longitude')}
                                name={[field.name, 'longitude']}
                                formListName={formListName}
                            >
                                <Input />
                            </MUIFormItem>
                        </Col>
                    </Row>

                    <Form.Item>
                        <GoogleMap
                            fieldKey={field.name}
                            latitude={iteneraryList[field.name]?.latitude}
                            longitude={iteneraryList[field.name]?.longitude}
                            address={address}
                            form={form}
                        />
                    </Form.Item>
                </div>

                <Form.Item>
                    <Row justify="end" align="middle">
                        {itemIndex !== field.key && (
                            <Col>
                                <Button
                                    className="button-underline"
                                    type="link"
                                    onClick={() => {
                                        fieldIndex === field.name
                                            ? setFieldIndex(null)
                                            : setFieldIndex(field.name);
                                    }}
                                >
                                    {field.name === fieldIndex ? 'Hide' : 'View full details'}
                                </Button>
                            </Col>
                        )}

                        <Col>
                            <Button
                                className="button btn-grey"
                                onClick={() => remove(field.name)}
                            >
                                <DeleteIcon />
                                {t('delete item').toUpperCase()}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default IteneraryItem;
