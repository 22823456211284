import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';

import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { formatExtraServicesForSubmit, eServicesFormat } from '@/helpers/mapping';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { selectors } from '@/redux/accommodation/hotel/create/model';

import Facilities from './Facilities';
import Services from './Services';
import baseValidations from './validations';

// format facilities as initial data
const format = (items) => items.map((item) => (
    item.id
));

const formFields = ['facilities', 'extra_services'];

const FacilitiesAndServices = ({ form, regStep }) => {
    const dispatch = useDispatch();

    const validations = useTranslatedValidationConfig(baseValidations);

    const onFinish = useCallback((values) => {
        const data = {
            facilities: values.facilities,
            extra_services: values.extra_services
                ? formatExtraServicesForSubmit(values.extra_services)
                : [],
        };
        if (regStep <= 2) {
            const step = 2;
            const completedStep = 1;
            dispatch(actions.requestSubmitAccommodationFacilities(
                data,
                step,
                completedStep,
            ));
        } else {
            const step = 2;
            const completedStep = 2;
            dispatch(actions.requestSubmitAccommodationFacilities(
                data,
                step,
                completedStep,
            ));
        }
    }, [dispatch, regStep]);

    const facilities = useSelector(selectors.facilities);
    const services = useSelector(selectors.services);
    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => {
        return {
            facilities: format(facilities),
            extra_services: eServicesFormat(services),

        };
    }, [facilities, services]);

    return (
        <Form
            id="hotel-facilities-services-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={initialData || {}}
        >
            <Facilities initialFacilitiesList={initialData?.facilities} />

            <Services validations={validations} />
        </Form>
    );
};

export default FacilitiesAndServices;
