import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // go to step
    goToCreateTripStep: ['step'],

    // fetch for edit
    requestFetchTripData: ['id'],
    fetchTripDataSucceeded: [
        'generalData',
        'iteneraryData',
        'inclusionsData',
        'exclusionsData',
    ],
    fetchTripDataFailed: ['errors'],

    // submit first step
    requestSubmitTripGeneralDetails: ['data', 'currStep', 'complStep'],
    submitTripGeneralDetailsSucceeded: ['data', 'currStep', 'complStep'],
    submitTripGeneralDetailsFailed: ['errors'],

    // submit second step
    requestSubmitTripItenerary: ['data', 'currStep', 'complStep'],
    submitTripItenerarySucceeded: ['data', 'currStep', 'complStep'],
    submitTripIteneraryFailed: ['errors'],

    // submit third step
    requestSubmitTripInclusionsExclusions: ['data', 'currStep', 'complStep'],
    submitTripInclusionsExclusionsSucceeded: [
        'inclusionsData',
        'exclusionsData',
        'currStep',
        'complStep',
    ],
    submitTripInclusionsExclusionsFailed: ['errors'],

    // get trip types
    requestGetTripTypes: ['data'],
    getTripTypesSucceeded: ['data'],
    getTripTypesFailed: ['errors'],

    // add type
    requestAddNewTripType: ['data'],
    addNewTripTypeSucceeded: ['data'],
    addNewTripTypeFailed: ['tripTypeError'],

    // clear type error
    clearTripTypeError: null,

    // clear state
    clearTripState: null,
});

const goToExactStep = (step) => {
    if (step === 3) {
        return 0;
    }
    return step - 1;
};

export default {
    // go to step
    [types.GO_TO_CREATE_TRIP_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_TRIP_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_TRIP_DATA_SUCCEEDED]: (
        state,
        {
            generalData,
            iteneraryData,
            inclusionsData,
            exclusionsData,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: goToExactStep(generalData.tour_register_step),
            completedStep: goToExactStep(generalData.tour_register_step),
            loading: false,
            generalInfo: generalData,
            itenerary: iteneraryData,
            inclusions: inclusionsData,
            exclusions: exclusionsData,
        },
    }),
    [types.FETCH_TRIP_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_TRIP_GENERAL_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_TRIP_GENERAL_DETAILS_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            generalInfo: data,
        },
    }),
    [types.SUBMIT_TRIP_GENERAL_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_TRIP_ITENERARY]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_TRIP_ITENERARY_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            itenerary: data,
        },
    }),
    [types.SUBMIT_TRIP_ITENERARY_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_TRIP_INCLUSIONS_EXCLUSIONS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_TRIP_INCLUSIONS_EXCLUSIONS_SUCCEEDED]: (
        state,
        {
            inclusionsData,
            exclusionsData,
            currStep,
            complStep,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            inclusions: inclusionsData,
            exclusions: exclusionsData,
        },
    }),
    [types.SUBMIT_TRIP_INCLUSIONS_EXCLUSIONS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // get trip types
    [types.REQUEST_GET_TRIP_TYPES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),

    [types.GET_TRIP_TYPES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            tripTypes: data,
            loading: false,
            errors: null,
        },
    }),
    [types.GET_TRIP_TYPES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // add trip type
    [types.REQUEST_ADD_NEW_TRIP_TYPE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            tripTypeError: null,
        },
    }),

    [types.ADD_NEW_TRIP_TYPE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            tripTypes: [...state.create.tripTypes, data],
            tripTypeError: null,
        },
    }),
    [types.ADD_NEW_TRIP_TYPE_FAILED]: (state, { tripTypeError }) => ({
        ...state,
        create: {
            ...state.create,
            tripTypeError,
        },
    }),

    // clear type error
    [types.CLEAR_TRIP_TYPE_ERROR]: (state) => ({
        ...state,
        create: {
            ...state.create,
            tripTypeError: null,
        },
    }),

    // clear state
    [types.CLEAR_TRIP_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            generalInfo: null,
            itenerary: [],
            inclusions: [],
            exclusions: [],
            tripTypeError: null,
        },
    }),
};

export { types, actions };
