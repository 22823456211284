import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Typography,
    Switch,
    Divider,
    Select,
} from 'antd';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import { actions } from '@/redux/tour/activities/create/reducer';
import { selectors } from '@/redux/tour/activities/create/model';
import AddNewPartner from './AddNewPartner';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const Partner = ({ form, isEdit, regStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);

    const partnerData = useSelector(selectors.partner);

    useEffect(() => {
        if (partnerData && partnerData.partner === null) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [partnerData]);

    const partnerList = useSelector(selectors.partnerList);
    const loading = useSelector(selectors.loading);

    // get partner list by search
    const onSearch = useCallback((search) => {
        if (search && search.length === 3) {
            dispatch(actions.requestGetPartnerList(search));
        }
    }, [dispatch]);

    // get existing partner
    const getExistingPartner = useCallback((id) => {
        dispatch(actions.requestGetExistingPartner(id));
    }, [dispatch]);

    return (
        <>
            <HeaderWithHelp
                title="Partner"
                question={question}
                answer={answer}
            />

            <Divider />

            <Row gutter={[20, 20]} justify="center" className="duration-section" align="middle">
                <Col>
                    <Typography.Text>
                        {t("Partner's Product")}
                    </Typography.Text>
                </Col>

                <Col>
                    <Switch
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                </Col>

                <Col>
                    <Typography.Text>
                        {t('My Own Product')}
                    </Typography.Text>
                </Col>
            </Row>

            <Select
                showSearch
                filterOption={false}
                defaultActiveFirstOption={false}
                notFoundContent={null}
                loading={loading}
                onSearch={onSearch}
                placeholder={t('Search for an Existing Partner')}
                onSelect={(partnerId) => getExistingPartner(partnerId)}
            >
                {partnerList?.map((partner) => (
                    <Select.Option
                        key={partner.id}
                        value={partner.id}
                    >
                        {partner.company_name}
                    </Select.Option>
                ))}
            </Select>

            <Typography.Text className="partner-create-text">{t('- or create new one below -')}</Typography.Text>

            <AddNewPartner form={form} isEdit={isEdit} regStep={regStep} />
        </>
    );
};

export default Partner;
