import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Checkbox,
    Col,
    Row,
    Space,
    Button,
    Pagination,
    Modal,
} from 'antd';
import ActionIcon from '@/components/ActionIcon';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import {
    DeleteIcon,
    // EditIcon,
    EyeIcon,
    FilePdfIcon,
    // FileTextIcon,
} from '@/assets/images/icons/screens';
import { actions } from '@/redux/customers/delete/reducer';
import { firstToUpper } from '@/helpers/string';
import { useCurrency } from '@/hooks/useCurrency';
import useNotification from '@/hooks/useNotification';

const ViewAll = ({
    data,
    checkedList,
    setCheckedList,
    setCheckAll,
    totalItems,
    page,
    setPage,
    perPage,
    setPerPage,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const [isOpen, setIsOpen] = useState(false);
    const [customerName, setCustomerName] = useState('');

    const onChange = (list) => {
        setCheckedList(list);
        setCheckAll(list.length === data.length);
        setIsOpen(!isOpen);
    };

    const deleteCustomer = useCallback((id) => {
        Modal.confirm({
            className: 'confirm-modal',
            title: `${t('Are you sure to delete this customer')} ?`,
            onOk() {
                dispatch(actions.requestDeleteCustomer(id));
            },
        });
    }, [dispatch, t]);

    const messageSuccess = useSelector((state) => state.customers.delete.messageSuccess);
    const error = useSelector((state) => state.customers.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: customerName,
        clearAction: actions.clearDeleteCustomerState(),
        mode: 'list',
    });

    return (
        <div className="customer-page">
            <div className="customer-list">
                <Checkbox.Group
                    value={checkedList}
                    onChange={onChange}
                >
                    <table>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    <td className="no-width-m1">
                                        <Checkbox value={item.id} />
                                    </td>
                                    <td>
                                        <div className="un-m1">
                                            {item.name}
                                        </div>
                                        <InlineDescription
                                            label={t('Since')}
                                            content={item.date_insert}
                                        />
                                    </td>
                                    <td>
                                        <InlineDescription
                                            label={t('Personal No')}
                                            content={item.id_number}
                                        />
                                        <InlineDescription
                                            label={t('Nationality')}
                                            content={item.nationality}
                                        />
                                    </td>
                                    <td>
                                        <InlineDescription
                                            label={t('Birthdate')}
                                            content={item.birthday}
                                        />
                                        <InlineDescription
                                            label={t('Gender')}
                                            content={item.gender === 'M' ? 'Male' : 'Female'}
                                        />
                                    </td>
                                    <td>
                                        <InlineDescription
                                            label={t('Client Type')}
                                            content={firstToUpper(item.customer_type)}
                                        />
                                        {item.company_name === null ? (
                                            <div>--</div>
                                        ) : (
                                            <InlineDescription
                                                label={t('Company Name')}
                                                content={item.company_name}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <InlineDescription
                                            label={t('No. of Invoices')}
                                            content={item.invoices_no}
                                        />
                                        <InlineDescription
                                            label={t('Revenue')}
                                            content={`${currencyIcon}${item.revenue}`}
                                        />
                                    </td>
                                    <td className="no-width-m1">
                                        <Space className="a-btns-m1">
                                            <ActionIcon onClick={() => push(`/app/customers/${item.id}`)}>
                                                <EyeIcon />
                                            </ActionIcon>
                                            {/* TODO backend API */}
                                            {/* <ActionIcon onClick={() => push(
                                                `/app/customers/${item.id}/edit`
                                            )}>
                                                <EditIcon />
                                            </ActionIcon> */}
                                            <ActionIcon onClick={() => {
                                                deleteCustomer(item.id);
                                                setCustomerName(item.name);
                                            }}
                                            >
                                                <DeleteIcon />
                                            </ActionIcon>
                                        </Space>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Checkbox.Group>
            </div>
            <div className="footer">
                <Row justify="space-between">
                    <Col>
                        { isOpen && (
                            <Space size={30}>
                                <Button className="delete">
                                    {t('Delete')}
                                    <DeleteIcon />
                                </Button>
                                {/* <Button className="export">
                                    {t('Export in XLS')}
                                    <FileTextIcon />
                                </Button> */}
                                <Button className="export">
                                    {t('Export in PDF')}
                                    <FilePdfIcon />
                                </Button>
                            </Space>
                        )}
                    </Col>
                    <Col>
                        <Pagination
                            size="small"
                            current={page}
                            pageSize={perPage}
                            total={totalItems}
                            onChange={(current, pageSize) => {
                                setPage(current);
                                setPerPage(pageSize);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ViewAll;
