import { combineReducers } from 'redux';

import dashboard, {
    types as dashboardTypes,
    actions as dashboardActions,
} from './dashboard/reducer';

import trip, {
    types as tripTypes,
    actions as tripActions,
} from './trip/reducer';

import packages, {
    types as packagesTypes,
    actions as packagesActions,
} from './packages/reducer';

import cruise, {
    types as cruiseTypes,
    actions as cruiseActions,
} from './cruise/reducer';

import activities, {
    types as activitiesTypes,
    actions as activitiesActions,
} from './activities/reducer';

const actions = {
    ...dashboardActions,
    ...tripActions,
    ...packagesActions,
    ...cruiseActions,
    ...activitiesActions,
};

const types = {
    ...dashboardTypes,
    ...tripTypes,
    ...packagesTypes,
    ...cruiseTypes,
    ...activitiesTypes,
};

export { types, actions };

const tourReducer = combineReducers({
    dashboard,
    trip,
    packages,
    cruise,
    activities,
});

export default tourReducer;
