import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, message } from 'antd';
import clsx from 'clsx';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import { selectors as shareSelectors } from '@/redux/b2b/share/model';

import ListDescription from './Description/List';
import GridDescription from './Description/Grid';
import Cover from './Cover';
import Title from './Title';

const TourTypeListCard = ({
    id,
    image,
    title,
    location,
    size,
    url,
    description,
    duration, // from activities
    price,
    displayType = 'list', // list || card
    editUrl,
    deleteAction,
    type,
    registerStep,
    totalStep,
    isShared,
    agency,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    setItemName,
}) => {
    const dispatch = useDispatch();
    const isList = (displayType === 'list');

    const cardClass = clsx(
        'tour-type-list-card',
        isList ? 'list' : 'grid',
    );

    const props = {
        id,
        title,
        location,
        size,
        description,
        price,
        url,
        duration,
        editUrl,
        deleteAction,
        type,
        registerStep,
        totalStep,
        isShared,
        agency,
        removeSharedServiceAction,
        removeSharedCollectedServiceAction,
        myService,
    };

    const reduxMessage = useSelector(shareSelectors.message);
    const serviceId = useSelector(shareSelectors.serviceId);

    const [isServiceShared, setIsServiceShared] = useState(isShared);

    useEffect(() => {
        if (reduxMessage === 'Removed successfully' && id === serviceId) {
            setIsServiceShared(false);
            message.success('Remove shared service successfully');
            dispatch(shareActions.clearShareRemoveMessage());
        }
    }, [reduxMessage, dispatch, serviceId, id]);

    return (
        <Col xs={isList ? 24 : 6} className={isList ? 'list-col' : 'grid-col'}>
            {isList ? (
                <Card className={cardClass}>
                    <Card.Meta
                        avatar={<Cover src={image} size={60} />}
                        description={(
                            <ListDescription
                                {...props}
                                isShared={isServiceShared}
                                myService={myService}
                                setItemName={setItemName}
                            />
                        )}
                    />
                </Card>
            ) : (
                <Card
                    className={cardClass}
                    cover={(
                        <Cover
                            src={image}
                            size={120}
                            editUrl={editUrl}
                            deleteAction={deleteAction}
                            type={type}
                            isShared={isServiceShared}
                            removeSharedServiceAction={removeSharedServiceAction}
                            removeSharedCollectedServiceAction={removeSharedCollectedServiceAction}
                            myService={myService}
                            title={title}
                            setItemName={setItemName}
                        />
                    )}
                >
                    <Card.Meta
                        title={(
                            <Title url={url} title={title} />
                        )}
                        description={(
                            <GridDescription {...props} />
                        )}
                    />
                </Card>
            )}
        </Col>
    );
};

export default TourTypeListCard;
