import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('accommodationList');

const actions = {
    requestFetchAccommodationList: baseActions.request,
    fetchAccommodationListSucceeded: baseActions.succeeded,
    fetchAccommodationListFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
