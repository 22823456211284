import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Typography,
    Checkbox,
} from 'antd';
import clsx from 'clsx';
import CarouselWidget from './CarouselWidget';
import GridWidget from './GridWidget';

const optionData = [
    {
        id: 0,
        mainText: 'Grid Mode',
        widget: <GridWidget />,
    },
    {
        id: 1,
        mainText: 'Carousel Mode',
        widget: <CarouselWidget />,
    },
];

const CardWidgetType = ({
    customCheckedId,
    setCustomCheckedId,
}) => {
    const { t } = useTranslation();

    return (
        <Row
            className="card-widget-options"
            gutter={[20, 0]}
        >
            {optionData.map((option) => (
                <Col span={12} key={option.id}>
                    <Row
                        gutter={[0, 29]}
                        className={clsx('item', customCheckedId === option.id && 'selected')}
                    >
                        <Col span={16}>
                            <Typography.Text>
                                {t(option.mainText)}
                            </Typography.Text>
                        </Col>
                        <Col span={8}>
                            <Checkbox
                                checked={customCheckedId === option.id}
                                onChange={() => {
                                    setCustomCheckedId(option.id);
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            {option.widget}
                        </Col>
                    </Row>
                </Col>
            ))}
        </Row>
    );
};

export default CardWidgetType;
