import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Checkbox,
    DatePicker,
} from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import MUIFormItem from '@/components/MUIFormItem';
import GoogleMap from '@/components/GoogleMap';
import CustomTextArea from '@/components/TextArea';
import {
    CalendarIcon,
    LocationIcon,
    DeleteIcon,
} from '@/assets/images/icons/screens';
import { selectors } from '@/redux/tour/cruise/create/model';
import Upload from './Upload';

const IteneraryItem = ({
    field,
    remove,
    validations,
    itemIndex,
    formListName,
    form,
}) => {
    const { t } = useTranslation();

    const [fieldIndex, setFieldIndex] = useState(null);
    const [checked, setChecked] = useState(false);
    const iteneraryList = useSelector(selectors.itenerary);

    return (
        <Row
            className="itenerary"
            gutter={[15, 15]}
        >
            <Col lg={5}>
                <Upload
                    name={[field.name, 'itinerary_image']}
                />
            </Col>

            <Col lg={19}>
                <MUIFormItem
                    label={t('Title')}
                    name={[field.name, 'title']}
                    rules={validations.title}
                    formListName={formListName}
                >
                    <Input />
                </MUIFormItem>

                <div className={clsx(
                    'hidden-inputs',
                    itemIndex !== field.key && 'hidden',
                    field.name === fieldIndex && 'show',
                )}
                >
                    <CustomTextArea
                        name="description"
                        placeholder={t('Description')}
                        rules={validations.description}
                    />

                    <Row gutter={15}>
                        <Col xxl={12} xs={24}>
                            <MUIFormItem
                                label={t('Arrival time')}
                                name={[field.name, 'arrival_time']}
                                rules={validations.arrival_time}
                                formListName={formListName}
                            >
                                <DatePicker
                                    showTime={{ format: 'HH:mm a' }}
                                    suffixIcon={<CalendarIcon />}
                                />
                            </MUIFormItem>
                        </Col>

                        <Col xxl={12} xs={24}>
                            <MUIFormItem
                                label={t('Departure time')}
                                name={[field.name, 'departure_time']}
                                rules={validations.departure_time}
                                formListName={formListName}
                            >
                                <DatePicker
                                    showTime={{ format: 'HH:mm a' }}
                                    suffixIcon={<CalendarIcon />}
                                />
                            </MUIFormItem>
                        </Col>

                        <Col xxl={8} xs={24}>
                            <MUIFormItem
                                label={t('Arrival Port')}
                                name={[field.name, 'arrival_place']}
                                rules={validations.arrival_port}
                                formListName={formListName}
                            >
                                <Input suffix={<LocationIcon />} />
                            </MUIFormItem>
                        </Col>

                        <Col xxl={8} sm={12} xs={24}>
                            {!checked && (
                                <MUIFormItem
                                    label={t('Departure Port')}
                                    name={[field.name, 'departure_place']}
                                    rules={validations.departure_port}
                                    formListName={formListName}
                                >
                                    <Input suffix={<LocationIcon />} />
                                </MUIFormItem>
                            )}
                        </Col>

                        <Col xxl={8} sm={12} xs={24}>
                            <Form.Item name={[field.name, 'same_port']} valuePropName="checked">
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                >
                                    {t('Same Port for Departure')}
                                </Checkbox>
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <MUIFormItem
                                label={t('Map Latitude')}
                                name={[field.name, 'latitude']}
                                rules={validations.latitude}
                                formListName={formListName}
                            >
                                <Input />
                            </MUIFormItem>
                        </Col>

                        <Col lg={12} xs={24}>
                            <MUIFormItem
                                label={t('Map Longitude')}
                                name={[field.name, 'longitude']}
                                rules={validations.longitude}
                                formListName={formListName}
                            >
                                <Input />
                            </MUIFormItem>
                        </Col>
                    </Row>

                    <Form.Item>
                        <GoogleMap
                            fieldKey={field.name}
                            latitude={iteneraryList[field.name]?.latitude}
                            longitude={iteneraryList[field.name]?.longitude}
                            form={form}
                        />
                    </Form.Item>

                    <span className="map-location-text">{`${t('You can add a location by clicking over the map')}!`}</span>
                </div>

                <Form.Item>
                    <Row justify="end" align="middle">
                        {itemIndex !== field.key && (
                            <Col>
                                <Button
                                    className="button-underline"
                                    type="link"
                                    onClick={() => {
                                        fieldIndex === field.name
                                            ? setFieldIndex(null)
                                            : setFieldIndex(field.name);
                                    }}
                                >
                                    {field.name === fieldIndex ? 'Hide' : 'View full details'}
                                </Button>
                            </Col>
                        )}

                        <Col>
                            <Button
                                className="button delete-button delete-button-outlined"
                                onClick={() => remove(field.name)}
                            >
                                <DeleteIcon />
                                {t('delete item').toUpperCase()}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default IteneraryItem;
