import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import ShowIncusionsExclusions from '@/components/ShowInclusionsExclusions';

const ViewIncusionsExclusions = ({ data }) => {
    const { t } = useTranslation();
    return (
        <>
            <Divider />

            <span className="extras-title">{t('Inclusions / Exclusions')}</span>

            <ShowIncusionsExclusions
                inclusions={data.inclusions}
                exclusions={data.exclusions}
            />
        </>
    );
};

export default ViewIncusionsExclusions;
