import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // go to step
    goToCreateActivityStep: ['step'],

    // fetch for edit
    requestFetchActivityData: ['id'],
    fetchActivityDataSucceeded: [
        'generalData',
        'timeAndDurationData',
        'partnerData',
    ],
    fetchActivityDataFailed: ['errors'],

    // submit first step
    requestSubmitActivityGeneralDetails: ['data', 'currStep', 'complStep'],
    submitActivityGeneralDetailsSucceeded: ['data', 'currStep', 'complStep'],
    submitActivityGeneralDetailsFailed: ['errors'],

    // submit second step
    requestSubmitActivityTimeDuration: ['data', 'currStep', 'complStep'],
    submitActivityTimeDurationSucceeded: ['data', 'currStep', 'complStep'],
    submitActivityTimeDurationFailed: ['errors'],

    // partner list
    requestGetPartnerList: ['filters'],
    getPartnerListSucceeded: ['data'],
    getPartnerListFailed: ['errors'],

    // get partner details
    requestGetExistingPartner: ['id'],
    getExistingPartnerSucceeded: ['data'],
    getExistingPartnerFailed: ['errors'],

    // submit third step
    requestSubmitActivityPartner: ['data', 'currStep', 'complStep'],
    submitActivityPartnerSucceeded: ['data', 'currStep', 'complStep'],
    submitActivityPartnerFailed: ['errors'],

    // clear state
    clearActivityState: null,

    // clear activity time errors
    clearActivityTimeErrors: null,
});

const goToExactStep = (step) => {
    if (step === 3) {
        return 0;
    }
    return step - 1;
};

export default {
    // go to step
    [types.GO_TO_CREATE_ACTIVITY_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_ACTIVITY_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_ACTIVITY_DATA_SUCCEEDED]: (
        state,
        {
            generalData,
            timeAndDurationData,
            partnerData,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: goToExactStep(generalData.activity_register_step),
            completedStep: goToExactStep(generalData.activity_register_step),
            loading: false,
            generalInfo: generalData,
            timeAndDuration: timeAndDurationData,
            partner: partnerData,
        },
    }),
    [types.FETCH_ACTIVITY_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_ACTIVITY_GENERAL_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ACTIVITY_GENERAL_DETAILS_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            generalInfo: data,
        },
    }),
    [types.SUBMIT_ACTIVITY_GENERAL_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_ACTIVITY_TIME_DURATION]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ACTIVITY_TIME_DURATION_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            timeAndDuration: data,
        },
    }),
    [types.SUBMIT_ACTIVITY_TIME_DURATION_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // partner list
    [types.REQUEST_GET_PARTNER_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            partnerList: [],
            errors: null,
        },
    }),
    [types.GET_PARTNER_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            partnerList: data,
            loading: false,
        },
    }),
    [types.GET_PARTNER_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            loading: false,
        },
    }),

    // get existing partner
    [types.REQUEST_GET_EXISTING_PARTNER]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.GET_EXISTING_PARTNER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            partner: data,
        },
    }),
    [types.GET_EXISTING_PARTNER_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_ACTIVITY_PARTNER]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_ACTIVITY_PARTNER_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            partner: data,
        },
    }),
    [types.SUBMIT_ACTIVITY_PARTNER_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_ACTIVITY_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            generalInfo: null,
            timeAndDuration: null,
            partner: null,
        },
    }),

    // clear activity time errors
    [types.CLEAR_ACTIVITY_TIME_ERRORS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            errors: null,
        },
    }),
};

export { types, actions };
