import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import PartnerService from '@/services/B2BPartnerServices/MyPartnerService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* viewPartner({ id }) {
    try {
        const response = yield call(PartnerService.viewPartner, id);
        yield put(actions.viewPartnerSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewPartnerFailed(extractError(error)));
    }
}

function* watchViewPartner() {
    yield takeEvery(types.REQUEST_VIEW_PARTNER, viewPartner);
}

export default function* viewSaga() {
    yield all([
        fork(watchViewPartner),
    ]);
}
