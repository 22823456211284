import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Divider,
    notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import HeaderWithHelp from '@/components/HeaderWithHelp';
import DashedContainer from '@/components/DashedContainer';
import TourExtraServices from '@/components/TourExtraServices';
import Loading from '@/components/Loading';
import CustomTextArea from '@/components/TextArea';
import WidgetCategory from '@/components/WidgetCategory';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { extractUrlFromFile } from '@/helpers/upload';
import { returnDeletedImages } from '@/helpers/deleteImages';
import { formatExtraServicesForSubmit, eServicesFormat } from '@/helpers/mapping';
import { actions } from '@/redux/tour/trip/create/reducer';
import { selectors } from '@/redux/tour/trip/create/model';

import ImagesUpload from './ImagesUpload';
import TourDetails from './TourDetails';
import TourPrices from './TourPrices';
import baseValidations from './validations';

const formFields = [
    'images',
    'name',
    'duration',
    'duration_type',
    'description',
    'tour_types',
    'max_person_no',
    'min_person_no',
    'adult_price',
    'fixed',
    'cost',
    'cost_type',
    'children',
    'child_price',
    'infants',
    'infant_price',
];

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

// format trip type as initial data
const tripTypeFormat = (types) => types.map((type) => (
    type.id
));

const formatDataToSubmit = (id, categoryId, values, initialImages) => ({
    id,
    category: categoryId,
    name: values.name,
    tour_types: values.tour_types,
    duration: values.duration,
    duration_type: values.duration_type,
    description: values.description,

    max_person_no: values.max_person_no,
    min_person_no: values.min_person_no,
    adult_price: values.adult_price,
    prices: values.fixed === 1
        ? []
        : values.prices?.map((price) => ({
            rule_type: price.rule_type,
            starting_no: price.starting_no,
            ending_no: price.ending_no,
            price: price.price,
        })),
    popular: values.popular ? 1 : 0,
    fixed: values.fixed,
    cost: values.cost,
    cost_type: values.cost_type,

    children: values.children || 0,
    child_price: values.child_price || null,
    child_price_type: values.adult_price_type,

    infants: values.infants || 0,
    infant_price: values.infant_price || null,
    infant_price_type: values.adult_price_type,
    widgets_category: values.widgets_category,
    images: values.images
        ? values.images.fileList?.map(extractUrlFromFile)
        : [],
    extra_services: values.extra_services
        ? formatExtraServicesForSubmit(values.extra_services)
        : [],
    deleted_images: returnDeletedImages(initialImages, values.images?.fileList),
});

const GeneralDetails = ({
    form,
    id,
    isEdit,
    regStep,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const generalInfo = useSelector(selectors.generalInfo);
    const itinerary = useSelector(selectors.itenerary);

    const validations = useTranslatedValidationConfig(baseValidations);

    const initialImages = generalInfo?.images;

    // add mode
    const onAdd = useCallback((values) => {
        const data = formatDataToSubmit(id, 1, values, initialImages);
        const step = 1;
        const completedStep = 0;
        dispatch(actions.requestSubmitTripGeneralDetails(
            data,
            step,
            completedStep,
        ));
    }, [dispatch, id, initialImages]);

    // edit mode
    const onEdit = useCallback((values) => {
        const data = formatDataToSubmit(id, 1, values, initialImages);
        const step = 1;
        const completedStep = 1;
        dispatch(actions.requestSubmitTripGeneralDetails(
            data,
            step,
            completedStep,
        ));
    }, [dispatch, id, initialImages]);

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    const typeError = useSelector(selectors.tripTypeError);
    useEffect(() => {
        if (typeError !== null) {
            notification.error({
                description: t(typeError),
                duration: 0,
            });
        }
        dispatch(actions.clearTripTypeError());
    }, [typeError]);

    const initialData = useMemo(() => {
        if (generalInfo) {
            return {
                name: generalInfo.name,
                tour_types: generalInfo.types
                    ? tripTypeFormat(generalInfo.types)
                    : [],
                duration: generalInfo.duration,
                duration_type: generalInfo.duration_type === 'Days' ? 2 : 1,
                description: generalInfo.description,
                max_person_no: generalInfo.max_person_no,
                min_person_no: generalInfo.min_person_no,
                adult_price: generalInfo.adult_price,
                prices: generalInfo.price_rules ? generalInfo.price_rules.map((price) => ({
                    rule_type: price.rule_type,
                    starting_no: price.starting_no,
                    ending_no: price.ending_no,
                    price: price.price,
                })) : [],
                fixed: generalInfo.price_rules.length === 0 ? 1 : 0,
                cost: generalInfo.cost,
                cost_type: generalInfo.cost_type === '1' ? 1 : 2,
                popular: generalInfo.popular,
                children: generalInfo.children,
                child_price: generalInfo.child_price,
                infants: generalInfo.infants,
                infant_price: generalInfo.infant_price,
                extra_services: eServicesFormat(generalInfo.extra_services),
                widgets_category: generalInfo
                    .widget_categories.map((category) => category.id),
                images: generalInfo.images.map((image) => (
                    {
                        id: image.id,
                        url: image.path,
                        thumbUrl: image.path,
                    }
                )),
            };
        } else {
            return {
                duration_type: 2,
                fixed: 1,
                cost_type: 1,
                prices: [{ }],
            };
        }
    }, [generalInfo]);

    if (id && !initialData.name) {
        return <Loading />;
    }

    return (
        <Form
            id="trip-general-details-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={
                (isEdit && regStep > 1)
                || (!isEdit && id)
                || (isEdit && itinerary.length !== 0)
                    ? onEdit
                    : onAdd
            }
            initialValues={initialData || {}}
        >
            <HeaderWithHelp
                title="General Details"
                question={question}
                answer={answer}
            />

            <Divider />

            <Form.Item>
                <TourDetails validations={validations} />
            </Form.Item>

            <DashedContainer title="Group size and Prices">
                <Form.Item>
                    <TourPrices
                        validations={validations}
                        form={form}
                        prices={initialData?.prices}
                        childNumber={initialData?.children}
                        infantNumber={initialData?.infants}
                    />
                </Form.Item>
            </DashedContainer>

            <DashedContainer title="Widget Category">
                <Form.Item>
                    <WidgetCategory />
                </Form.Item>
            </DashedContainer>

            <DashedContainer title="Extra Services">
                {/* <span>{t('Extra Services')}</span> */}
                <TourExtraServices
                    name="extra_services"
                    validations={validations}
                    checkboxText="The price is inclusive to the tour"
                />
            </DashedContainer>

            <CustomTextArea
                name="description"
                placeholder={t('Tour Description')}
                rules={validations.description}
            />

            <Form.Item>
                <ImagesUpload />
            </Form.Item>
        </Form>
    );
};

export default GeneralDetails;
