import { all, fork, takeEvery } from 'redux-saga/effects';
import pagination from '@/helpers/pagination';
import EmployeesService from '@/services/EmployeesService';
import { types } from './reducer';

function* watchFetchEmployees() {
    yield takeEvery(
        types.REQUEST_FETCH_EMPLOYEES,
        pagination('employees').saga(EmployeesService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchEmployees),
    ]);
}

export default paginationSaga;
