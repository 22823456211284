import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    name: {
        name: 'First Name',
        required: true,
    },
    surname: {
        name: 'Last Name',
        required: true,
    },
    id_number: {
        name: 'Id',
        // required: true,
    },
    nationality: {
        name: 'Nationality',
        // required: true,
    },
    gender: {
        name: 'Gender',
        // required: true,
    },
    birthdate: {
        name: 'Birthdate',
        // required: true,
    },
    date_employed: {
        name: 'Date employed',
        required: true,
    },
    phone_number: {
        name: 'Phone number',
        required: true,
    },
    address: {
        name: 'Address',
        required: true,
    },
    email: {
        name: 'Email',
        required: true,
    },
    password: {
        name: 'Password',
        required: true,
    },
    image: {
        name: 'Image',
        // required: true,
    },
};

export default buildValidationsFromConfig(config);
