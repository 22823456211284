import { all, fork } from 'redux-saga/effects';

import addServicesListSaga from './addServicesList/saga';
import agencyDetailsSaga from './agencyDetails/saga';
import baseUserSaga from './baseUser/saga';
import verifyEmailSaga from './verifyEmail/saga';
import verifyPhoneSaga from './verifyPhone/saga';

function* registrationSaga() {
    yield all([
        fork(addServicesListSaga),
        fork(agencyDetailsSaga),
        fork(baseUserSaga),
        fork(verifyEmailSaga),
        fork(verifyPhoneSaga),
    ]);
}

export default registrationSaga;
