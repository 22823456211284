import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('sharedServices');

const actions = {
    requestFetchSharedServices: baseActions.request,
    fetchSharedServicesSucceeded: baseActions.succeeded,
    fetchSharedServicesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
