import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';

import TourTypeListCard from '@/components/TourTypeListCard';
import { actions } from '@/redux/tour/packages/delete/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import { CalendarIcon, LocationIcon, PriceIcon } from '@/assets/images/icons/screens';
import useNotification from '@/hooks/useNotification';

const PackageList = ({ viewMode, data }) => {
    const { t } = useTranslation();

    const displayType = viewMode;
    const isList = (displayType === 'list');

    const [itemName, setItemName] = useState('');

    const messageSuccess = useSelector((state) => state.tour.packages.delete.messageSuccess);
    const error = useSelector((state) => state.tour.packages.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: itemName,
        clearAction: actions.clearDeletePackageState(),
        mode: 'list',
    });

    return (
        <Row gutter={isList ? [0, 20] : [30, 30]}>
            {data.map((item) => (
                <TourTypeListCard
                    key={item.id}
                    image={item.image}
                    title={item.name}
                    price={isList ? (
                        <>
                            <PriceIcon />
                            {' '}
                            {`${item.currency_icon}${item.price} / ${item.price_type}`}
                        </>
                    ) : (
                        <>
                            <div>{t('Default Price')}</div>
                            {`${item.currency_icon}${item.price} / ${item.price_type}`}
                        </>
                    )}
                    location={(
                        <>
                            <LocationIcon />
                            {' '}
                            {item.hotel_location}
                        </>
                    )}
                    duration={(
                        <>
                            <CalendarIcon />
                            {' '}
                            {item.duration}
                            {' '}
                            {item.duration_type === 'Days' ? 'Days' : 'Hours'}
                        </>
                    )}
                    agency={(
                        <>
                            {!isList && item.b2b_agency !== null && ('Collected from:')}
                            {item.b2b_agency}
                        </>
                    )}
                    url={`/app/tours/packages/${item.id}`}
                    description={item.description}
                    totalStep={3}
                    registerStep={item.tour_register_step}
                    displayType={displayType}
                    editUrl={`/app/tours/packages/${item.id}/edit`}
                    deleteAction={actions.requestDeletePackage(item.id)}
                    type="package"
                    removeSharedServiceAction={shareActions.requestRemoveSharedService(
                        item.id,
                        { type: 1 },
                    )}
                    removeSharedCollectedServiceAction={
                        shareActions.requestRemoveSharedCollectedService(
                            item.id,
                            { type: 1 },
                        )
                    }
                    isShared={item.share}
                    id={item.id}
                    myService={item.b2b_agency === null}
                    setItemName={setItemName}
                />
            ))}
        </Row>
    );
};

export default PackageList;
