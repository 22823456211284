import {
    Col,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ClientIcon } from '@/assets/images/icons/screens';

import BlockDescription from '@/components/Information/WithoutImage/Block';
import SectionLayout from '../SectionLayout';

const Participants = ({ invoiceParticipants, currency, invoiceCharge }) => {
    const { t } = useTranslation();

    return (
        <SectionLayout
            className="participants"
            icon={<ClientIcon />}
            leftCol={3}
            rightCol={21}
            align="middle"
        >
            <Row justify="space-between" align="middle">
                <Col className="list">
                    <Typography.Title level={5}>
                        {t('Participants')}
                        :
                    </Typography.Title>

                    {invoiceParticipants?.map((participant) => (
                        <div key={participant.id}>
                            <BlockDescription
                                name={participant.fullname}
                            >
                                <Typography.Text>
                                    {currency?.concat('0.00')}
                                </Typography.Text>
                            </BlockDescription>
                        </div>
                    ))}
                </Col>

                <Col>
                    <Typography.Text className="total-price">
                        {`${currency}${invoiceCharge?.participants_total || '0.00'}`}
                    </Typography.Text>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default Participants;
