import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Divider,
    Row,
    Col,
    Form,
    Input,
    Button,
    Slider,
    DatePicker,
    Space,
    Select,
} from 'antd';
import {
    AppsIcon,
    CalendarIcon,
    FilterIcon,
    ListIcon,
    LocationIcon,
    PlusIcon,
} from '@/assets/images/icons/screens';
import { useCurrency } from '@/hooks/useCurrency';
import { actions } from '@/redux/maxprice/reducer';

const Filters = ({
    viewMode,
    setViewMode,
    filters,
    setFilters,
    type,
    searchPlaceholder,
    locationPlaceholder,
    addNewItemText,
    addUrl,
    clearTourState,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const [isOpen, setIsOpen] = useState(false);

    const groupSizeOptions = new Array(20).fill(null).map((_, i) => i + 1);

    const maxPrice = useSelector((state) => state.maxprice.tourMaxPrice);

    useEffect(() => {
        dispatch(actions.requestGetMaxPrice({ type: 1 }));
    }, []);

    if (filters.prices[1] === 0) {
        return null;
    }

    return (
        <Form
            initialValues={filters}
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                if (name[0] !== 'prices') {
                    setFilters(name[0], value);
                }
            }}
        >
            <Row className="filter-content" justify="space-between">
                <Col>
                    <Space size={30}>
                        <Form.Item name="name" noStyle>
                            <Input.Search
                                className="search"
                                placeholder={t(searchPlaceholder)}
                            />
                        </Form.Item>

                        <Button
                            className="button filter-button"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <FilterIcon />
                            {t('advanced filter').toUpperCase()}
                        </Button>

                        <Button
                            className="change-mode-button"
                            onClick={() => {
                                setViewMode((viewMode === 'list') ? 'grid' : 'list');
                            }}
                        >
                            {(viewMode === 'list')
                                ? (
                                    <AppsIcon />
                                ) : (
                                    <ListIcon />
                                )}
                        </Button>
                    </Space>
                </Col>

                <Col className="add-button-container">
                    <Link to={addUrl}>
                        <Button
                            className="button add-button"
                            onClick={() => dispatch(clearTourState)}
                        >
                            <PlusIcon />
                            {t(addNewItemText).toUpperCase()}
                        </Button>
                    </Link>
                </Col>
            </Row>

            {isOpen && (
                <>
                    <Divider dashed className="filter-divider" />

                    <Row
                        className="advanced-filter-content"
                        gutter={[30, 30]}
                    >
                        <Col xxl={6} xl={8} sm={12} xs={24}>
                            <Form.Item name="location" noStyle>
                                <Input
                                    placeholder={t(locationPlaceholder)}
                                    suffix={<LocationIcon />}
                                />
                            </Form.Item>
                        </Col>

                        {type === 'trip' && (
                            <Col lg={6} sm={12} xs={24}>
                                <Form.Item name="group_size" noStyle>
                                    <Select
                                        className="select-option"
                                        placeholder={t('Group size')}
                                    >
                                        {groupSizeOptions.map((item) => (
                                            <Select.Option
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}

                        {type !== 'trip' && (
                            <Col xxl={6} xl={8} sm={12} xs={24}>
                                <Form.Item name="date" noStyle>
                                    <DatePicker.RangePicker
                                        placeholder={type === 'package' ? [t('Duration'), ''] : [t('Date Range'), '']}
                                        suffixIcon={<CalendarIcon />}
                                        popupClassName="filter-calendar"
                                        separator={filters.date ? '-' : ''}
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        <Col xxl={6} xl={8} sm={12} xs={24}>
                            <div>
                                <div>
                                    <span className="min-price">
                                        {`${currencyIcon}${filters.prices[0]}`}
                                    </span>
                                </div>
                                <div className="seperator" />
                                <div>
                                    <span className="max-price">
                                        {`${currencyIcon}${filters.prices[1]}`}
                                    </span>
                                </div>
                            </div>
                            <Form.Item name="prices" noStyle>
                                <Slider
                                    className="trip-slider"
                                    range
                                    min={0}
                                    max={maxPrice}
                                    step={5}
                                    tooltip={{
                                        open: false,
                                    }}
                                    onAfterChange={(value) => {
                                        setFilters('prices', value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
};

export default Filters;
