import { Switch, Route, Redirect } from 'react-router-dom';
import InHouseStats from './InHouseStats';
import B2BStats from './B2BStats';

export const routes = [
    {
        name: 'In-house Stats',
        icon: 'in-house stats icon',
        description: 'in-house stats desc',
        url: '/in-house-stats',
        component: InHouseStats,
    },
    {
        name: 'B2B Stats',
        icon: 'b2b stats icon',
        description: 'b2b stats desc',
        url: '/b2b-stats',
        component: B2BStats,
    },
];

const Reports = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/stats-reports${route.url}`}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/app/stats-reports/in-house-stats" />
        </Switch>
    );
};

export default Reports;
