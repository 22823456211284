import {
    takeEvery,
    select,
    call,
    all,
    put,
    fork,
} from 'redux-saga/effects';

import TripService from '@/services/TourServices/TripService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';
import { selectors } from './model';

function* isEdit(step) {
    const completedStep = yield select(selectors.completedStep);
    if (completedStep === null) {
        return false;
    }
    return completedStep >= step;
}

function* submitTripGeneralDetails({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        if (edit) {
            const { id } = data;
            response = yield call(TripService.editGeneralInfo, id, data);
        } else {
            response = yield call(TripService.addGeneralInfo, data);
        }
        yield put(actions.submitTripGeneralDetailsSucceeded(response.data, currStep, complStep));
    } catch (error) {
        yield put(actions.submitTripGeneralDetailsFailed(extractError(error)));
    }
}

const filterArray = (initialData, submittedData) => {
    const initial = initialData.map((item) => {
        return item.id;
    });
    const submitted = submittedData.map((item) => {
        return item.id;
    });
    const filtered = initial.filter((item) => {
        return submitted.indexOf(item) === -1;
    });
    return filtered;
};

function* submitTripItenerary({ data, currStep, complStep }) {
    try {
        const submittedData = data.itinerary;
        const initialData = yield select(selectors.itenerary);
        const deletedItems = filterArray(initialData, submittedData);
        const finalData = {
            itinerary: submittedData,
            deleted: deletedItems,
        };

        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;

        const { id: tourId } = yield select(selectors.generalInfo);
        if (edit) {
            response = yield call(TripService.editItenerary, tourId, finalData);
        } else {
            response = yield call(TripService.addItenerary, tourId, data);
        }
        yield put(actions.submitTripItenerarySucceeded(response.data, currStep, complStep));
    } catch (error) {
        yield put(actions.submitTripIteneraryFailed(extractError(error)));
    }
}

function* submitTripInclusionsExclusions({ data, currStep, complStep }) {
    try {
        const step = yield select(selectors.step);
        const edit = yield isEdit(step);
        let response;
        const { id: tourId } = yield select(selectors.generalInfo);
        if (edit) {
            response = yield call(TripService.editInclusionsExclusions, tourId, data);
        } else {
            response = yield call(TripService.addInclusionsExclusions, tourId, data);
        }
        const {
            inclusions: { selected: inclusions },
            exclusions: { selected: exclusions },
        } = response.data;
        yield put(actions.submitTripInclusionsExclusionsSucceeded(
            inclusions,
            exclusions,
            currStep,
            complStep,
        ));
    } catch (error) {
        yield put(
            actions.submitTripInclusionsExclusionsFailed(extractError(error)),
        );
    }
}

function* fetchTripData({ id }) {
    try {
        const [
            generalData,
            itenerary,
            inclExcl,
        ] = yield all([
            call(TripService.viewGeneralInfo, id),
            call(TripService.viewItenerary, id),
            call(TripService.viewInclusionsExclusions, id),
        ]);
        const {
            inclusions: { selected: inclusions },
            exclusions: { selected: exclusions },
        } = inclExcl.data;
        yield put(actions.fetchTripDataSucceeded(
            generalData.data,
            itenerary.data,
            inclusions,
            exclusions,
        ));
    } catch (error) {
        yield put(actions.fetchTripDataFailed(extractError(error)));
    }
}

function* getTripTypes({ data }) {
    try {
        const res = yield call(TripService.getTripTypes, data);
        yield put(actions.getTripTypesSucceeded(res.data));
    } catch (error) {
        yield put(actions.getTripTypesFailed(extractError(error)));
    }
}

function* addNewTripType({ data }) {
    try {
        const res = yield call(TripService.addNewTripType, data);
        yield put(actions.addNewTripTypeSucceeded(res.data));
    } catch (error) {
        yield put(actions.addNewTripTypeFailed('Tour style cannot be empty'));
    }
}

function* watchSubmitTripGeneralDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_TRIP_GENERAL_DETAILS,
        submitTripGeneralDetails,
    );
}

function* watchSubmitTripItenerary() {
    yield takeEvery(
        types.REQUEST_SUBMIT_TRIP_ITENERARY,
        submitTripItenerary,
    );
}

function* watchSubmitTripInclusionsExclusions() {
    yield takeEvery(
        types.REQUEST_SUBMIT_TRIP_INCLUSIONS_EXCLUSIONS,
        submitTripInclusionsExclusions,
    );
}

function* watchFetchTripData() {
    yield takeEvery(
        types.REQUEST_FETCH_TRIP_DATA,
        fetchTripData,
    );
}

function* watchGetTripTypes() {
    yield takeEvery(
        types.REQUEST_GET_TRIP_TYPES,
        getTripTypes,
    );
}

function* watchAddNewTripType() {
    yield takeEvery(
        types.REQUEST_ADD_NEW_TRIP_TYPE,
        addNewTripType,
    );
}

function* createTripSaga() {
    yield all([
        fork(watchSubmitTripGeneralDetails),
        fork(watchSubmitTripItenerary),
        fork(watchSubmitTripInclusionsExclusions),
        fork(watchFetchTripData),
        fork(watchGetTripTypes),
        fork(watchAddNewTripType),
    ]);
}

export default createTripSaga;
