import {
    useState,
    useMemo,
    useEffect,
    useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Radio } from 'antd';

import HeaderWithMap from '@/components/ViewOneHeaderWithMap';
import { getSubPageByHash } from '@/helpers/routing';
import { dateFormat } from '@/helpers/dateFormat';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { selectors } from '@/redux/accommodation/hotel/view/model';
import ProductCollection from '@/components/ProductCollection';

import AccommodationDetails from './AccommodationDetails';
import Reservation from './Reservation';

const subPages = [
    { name: 'Accommodation Details', hash: 'accommodation-details' },
    { name: 'Reservation', hash: 'reservation' },
];

const ViewOne = ({ viewFromB2b = false }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { type } = useParams();

    const {
        location: { hash },
        push,
    } = useHistory();
    const dispatch = useDispatch();

    const loading = useSelector(selectors.loading);
    const details = useSelector(selectors.accommodationDetails);
    const filters = useSelector(selectors.filterData);
    const accommodationMaxPrice = useSelector((state) => state.maxprice.accommodationMaxPrice);
    const { status: serviceStatus } = details !== null && details;
    const { general_data: generalDetails } = details !== null && details;
    const { b2b_agency: b2bAgency } = generalDetails !== undefined && generalDetails;

    const myService = b2bAgency === null; // if b2bAgency is null, it's my service

    const [visibleDetails, setVisibleDetails] = useState(
        () => getSubPageByHash(subPages, hash).hash,
    );

    const options = useMemo(
        () => subPages.map((page) => ({
            label: t(page.name),
            value: page.hash,
        })),
        [t],
    );

    const ViewDetailsComponent = useMemo(() => {
        switch (visibleDetails) {
            default:
            case subPages[0].hash:
                return AccommodationDetails;
            case subPages[1].hash:
                return Reservation;
        }
    }, [visibleDetails]);

    useEffect(() => {
        if (!viewFromB2b) {
            push(`/app/accommodations/${type}/${id}#${visibleDetails}`);
        } else {
            push(`/app/b2b-partners/worldwide-supplier/${type}/${id}#${visibleDetails}`);
        }
    }, [push, visibleDetails, viewFromB2b, id, type]);

    const [page, setPage] = useState(1);

    const handleFetchAccommodationRooms = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            let data = null;
            if (filters) {
                data = {
                    ...filters,
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                };
            } else {
                data = {
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                    adults: 2,
                    date_start: moment().format(dateFormat),
                    date_end: moment().add(1, 'day').format(dateFormat),
                    min_price: 10,
                    max_price: accommodationMaxPrice,
                };
            }
            accommodationMaxPrice !== null
            && dispatch(actions.requestFetchAccommodationRooms(id, data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            id,
            filters,
            accommodationMaxPrice,
        ],
    );

    useEffect(() => {
        dispatch(
            actions.requestViewAccommodation(id),
        );
    }, [dispatch, id]);

    useEffect(() => {
        handleFetchAccommodationRooms({});
    }, [handleFetchAccommodationRooms]);

    // fetch more rooms
    const handleFetchMore = useCallback(() => {
        handleFetchAccommodationRooms({ currentPage: page, fetchMore: true });
    }, [handleFetchAccommodationRooms, page]);

    return details === null || loading ? (
        'loading'
    ) : (
        <div id="view-accommodation-page">
            {!myService
            && (serviceStatus === null || serviceStatus === 2)
            && (
                <ProductCollection
                    data={generalDetails}
                    serviceStatus={serviceStatus}
                    serviceId={id}
                />
            )}
            <HeaderWithMap
                goBackUrl={myService ? `/app/accommodations/${type}` : '/app/b2b-partners/worldwide-supplier/'}
                title={details.general_data.name}
                rate={details.general_data.stars}
                address={details.general_data.address}
                lat={details?.general_data?.latitude}
                lng={details?.general_data?.longitude}
            >
                {myService && (
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        options={options}
                        onChange={(e) => setVisibleDetails(e.target.value)}
                        value={visibleDetails}
                    />
                )}
            </HeaderWithMap>

            <div className="view-content">
                <ViewDetailsComponent
                    myService={myService}
                    fetchMore={handleFetchMore}
                />
            </div>
        </div>
    );
};

export default ViewOne;
