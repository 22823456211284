import { Divider } from 'antd';
import DashedContainer from '@/components/DashedContainer';
import TourExtraServices from '@/components/TourExtraServices';
import HeaderWithHelp from '@/components/HeaderWithHelp';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const Services = ({ validations }) => {
    return (
        <div className="services">
            <HeaderWithHelp
                title="Services"
                question={question}
                answer={answer}
            />

            <Divider />

            <DashedContainer title="Extra Services">
                <TourExtraServices
                    name="extra_services"
                    validations={validations}
                    checkboxText="The price is inclusive to the tour"
                />
            </DashedContainer>
        </div>
    );
};

export default Services;
