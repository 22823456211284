import {
    Avatar,
    Col,
    Row,
    Space,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

import {
    BriefcaseIcon,
    HomeLocationIcon,
    MailIcon,
    PhoneCallIcon,
    UserIcon,
} from '@/assets/images/icons/screens';
import SectionLayout from '../SectionLayout';

const General = ({ agencyData, invoiceCustomer }) => {
    const { t } = useTranslation();

    return (
        <SectionLayout
            className="general-details"
            icon={(
                <Avatar
                    size={60}
                    icon={<UserIcon />}
                />
            )}
            leftCol={3}
            rightCol={21}
        >
            <Row>
                <Col xs={12}>
                    <Typography.Title level={5}>
                        {agencyData?.agency_name || agencyData?.name}
                    </Typography.Title>

                    <Typography.Paragraph>
                        <Space size={8}>
                            <BriefcaseIcon />
                            {agencyData?.vat_number}
                        </Space>
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        <Space size={8}>
                            <HomeLocationIcon />
                            {`
                                ${agencyData?.address},
                                ${agencyData?.city},
                                ${agencyData?.zip_code},
                                ${agencyData?.country}
                            `}
                        </Space>
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        <Space size={8}>
                            <PhoneCallIcon />
                            {agencyData?.phone}
                        </Space>
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        <Space size={8}>
                            <MailIcon />
                            {agencyData?.email}
                        </Space>
                    </Typography.Paragraph>
                </Col>

                <Col xs={12}>
                    <Typography.Title level={5} className="invoice-to">
                        {t('Invoice to')}
                        :
                    </Typography.Title>
                    { invoiceCustomer && (
                        invoiceCustomer.company_name !== null ? (
                            <>
                                <Typography.Paragraph>
                                    {invoiceCustomer.company_name}
                                    {`(id: ${invoiceCustomer.vat_number})`}
                                </Typography.Paragraph>

                                <Typography.Paragraph>
                                    {t('Address')}
                                    {': '}
                                    {invoiceCustomer.address}
                                </Typography.Paragraph>

                                <Typography.Paragraph>
                                    {t('Phone')}
                                    {': '}
                                    {invoiceCustomer.company_phone}
                                </Typography.Paragraph>

                                <Typography.Paragraph>
                                    {t('Email')}
                                    {': '}
                                    {invoiceCustomer.company_email}
                                </Typography.Paragraph>
                            </>
                        ) : (
                            <>
                                <Typography.Paragraph>
                                    {invoiceCustomer.name}
                                    {' '}
                                    { invoiceCustomer.surname}
                                    {`(id: ${invoiceCustomer.id_number})`}
                                </Typography.Paragraph>

                                <Typography.Paragraph>
                                    {t('Nationality')}
                                    {': '}
                                    {invoiceCustomer.nationality}
                                </Typography.Paragraph>

                                <Typography.Paragraph>
                                    {t('Phone')}
                                    {': '}
                                    {invoiceCustomer.phone}
                                </Typography.Paragraph>

                                <Typography.Paragraph>
                                    {t('Email')}
                                    {': '}
                                    {invoiceCustomer.email}
                                </Typography.Paragraph>
                            </>
                        )
                    )}
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default General;
