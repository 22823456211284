import { createActions } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({
    // resend
    requestResendForgotPasswordEmailCode: null,
    resendForgotPasswordEmailCodeSucceeded: ['messageSuccess'],
    resendForgotPasswordEmailCodeFailed: ['error'],

    // step 1
    requestForgotPasswordEmail: ['email'],
    forgotPasswordEmailSucceeded: ['email'],
    forgotPasswordEmailFailed: ['errors'],

    // step 2
    requestForgotPasswordVerifyEmail: ['code'],
    forgotPasswordVerifyEmailSucceeded: ['code', 'token'],
    forgotPasswordVerifyEmailFailed: ['errors'],

    // step 3
    requestForgotPasswordSetPassword: ['data'],
    forgotPasswordSetPasswordSucceeded: ['messageSuccess'],
    forgotPasswordSetPasswordFailed: ['errors'],
    forgotPasswordSetPasswordFailedInvalidToken: null,

    // clear forgot password state
    clearForgotPasswordState: null,
});

export default {
    // step 1
    [types.REQUEST_FORGOT_PASSWORD_EMAIL]: (state) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: true,
            errors: null,
        },
    }),
    [types.FORGOT_PASSWORD_EMAIL_SUCCEEDED]: (state, { email }) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: false,
            step: 1,
            email,
        },
    }),
    [types.FORGOT_PASSWORD_EMAIL_FAILED]: (state, { errors }) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: false,
            errors,
        },
    }),

    // resend
    [types.REQUEST_RESEND_FORGOT_PASSWORD_EMAIL_CODE]: (state) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            resendEmailError: null,
        },
    }),
    [types.RESEND_FORGOT_PASSWORD_EMAIL_CODE_SUCCEEDED]: (state, { messageSuccess }) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            messageSuccess,
        },
    }),
    [types.RESEND_FORGOT_PASSWORD_EMAIL_CODE_FAILED]: (state, { error }) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            resendEmailError: error,
        },
    }),

    // step 2
    [types.REQUEST_FORGOT_PASSWORD_VERIFY_EMAIL]: (state) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: true,
            errors: null,
            resendEmailError: null,
        },
    }),
    [types.FORGOT_PASSWORD_VERIFY_EMAIL_SUCCEEDED]: (state, action) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: false,
            step: 2,
            code: action.code,
            token: action.token,
        },
    }),
    [types.FORGOT_PASSWORD_VERIFY_EMAIL_FAILED]: (state, { errors }) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: false,
            errors,
        },
    }),

    // step 3
    [types.REQUEST_FORGOT_PASSWORD_SET_PASSWORD]: (state) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: true,
            errors: null,
        },
    }),
    [types.FORGOT_PASSWORD_SET_PASSWORD_SUCCEEDED]: (state, { messageSuccess }) => ({
        ...state,
        forgotPassword: initialState,
        messageSuccess,
    }),
    [types.FORGOT_PASSWORD_SET_PASSWORD_FAILED]: (state, { errors }) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: false,
            errors,
        },
    }),

    [types.FORGOT_PASSWORD_SET_PASSWORD_FAILED_INVALID_TOKEN]: (state) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            step: 1,
            submitting: false,
            errors: null,
        },
    }),

    // clear forgot password state
    [types.CLEAR_FORGOT_PASSWORD_STATE]: (state) => ({
        ...state,
        forgotPassword: {
            ...state.forgotPassword,
            submitting: false,
            messageSuccess: '',
            errors: null,
        },
    }),
};

export { types, actions };
