import { useTranslation } from 'react-i18next';
import {
    Pagination,
    Table,
    Row, Col,
    // Space,
    // Button,
} from 'antd';
import InfoBlock from '@/components/Information/WithoutImage/Block';
// import { DownLoadIcon, SendMsgIcon } from '@/assets/images/icons/screens';

const InvoiceTable = ({
    data,
    totalItems,
    page,
    setPage,
    perPage,
    setPerPage,
    currency,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('Product /Service').toUpperCase(),
            dataIndex: 'product',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Amount').toUpperCase(),
            dataIndex: 'amount',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Customer origin').toUpperCase(),
            dataIndex: 'customer',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Created').toUpperCase(),
            dataIndex: 'created',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Status').toUpperCase(),
            dataIndex: 'status',
            sorter: true,
            width: '20%',
            render: (text, record) => {
                if (record.status === '1') {
                    return <p className="paid">{t('Paid')}</p>;
                } else if (record.status === '2') {
                    return <p className="unpaid">{t('Unpaid')}</p>;
                } else if (record.status === '3') {
                    return <p className="canceled">{t('Canceled')}</p>;
                } else if (record.status === '4') {
                    return <p className="draft">{t('Draft')}</p>;
                } else if (record.status === '5') {
                    return <p className="partial">{t('Partial')}</p>;
                } else return null;
            },
        },
    ];

    // const rowSelection = {};

    const datasource = data?.map((row, i) => ({
        key: i,
        id: row.id,
        product:
    <InfoBlock
        name={row.custom === '1'
            ? <a href={'/app/invoices/custom/'.concat(row.id)}>{row.service}</a>
            : <a href={'/app/invoices/'.concat(row.id)}>{row.service}</a>}
        className="textSize14"
    >
        <span className="textSize12">{row.description}</span>
    </InfoBlock>,
        amount:
    <InfoBlock name={currency.concat(row.amount)} className="textSize14">
        <span className="textSize12">
            {
                row.discount !== '0.0000'
                    ? (
                        <span>
                            {t('Discount')}
                            :
                            {row.discount}
                        </span>
                    )
                    : t('No discount')
            }
        </span>
    </InfoBlock>,
        customer:
    <InfoBlock name={row.customer_nationality} className="textSize14">
        <span className="textSize12">
            {t('Name')}
            :
            {row.customer_name}
            {' '}
            {row.customer_surname}
        </span>
    </InfoBlock>,
        created:
    <InfoBlock name={row.date_insert} className="textSize14">
        <span className="textSize12">
            {t('by')}
            :
            {row.user}
        </span>
    </InfoBlock>,
        status: row.status,
        custom: row.custom,
    }));

    return (
        <Table
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={datasource}
            pagination={false}
            rowKey={(record) => record.id}
            size="small"
            footer={() => (
                <Row justify="end">
                    {/* <Col>
                        <Space size={25}>
                            <Button>
                                <DownLoadIcon />
                                {t('Export').toUpperCase()}
                            </Button>
                            <Button>
                                <SendMsgIcon />
                                {t('Send').toUpperCase()}
                            </Button>
                        </Space>
                    </Col> */}
                    <Col>
                        <Pagination
                            current={page}
                            total={totalItems}
                            pageSize={perPage}
                            onChange={(current, pageSize) => {
                                setPage(current);
                                setPerPage(pageSize);
                            }}
                        />
                    </Col>
                </Row>
            )}
        />
    );
};

export default InvoiceTable;
