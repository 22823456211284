import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Divider,
    Row,
    Col,
    Button,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import HeaderWithHelp from '@/components/HeaderWithHelp';
import { PlusIcon } from '@/assets/images/icons/screens';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { actions } from '@/redux/tour/trip/reducer';
import { selectors } from '@/redux/tour/trip/create/model';

import IteneraryItem from './IteneraryItem';
import baseValidations from './validations';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const formFields = [
    'title',
    'location',
    'latitude',
    'longitude',
    'itinerary_image',
    'description',
];

const formatItenerariesForSubmit = (iteneraries) => iteneraries
    .map((itenerary) => ({
        id: itenerary.id ? itenerary.id : null,
        title: itenerary.title,
        location: itenerary.location,
        latitude: itenerary.latitude,
        longitude: itenerary.longitude,
        itinerary_image: itenerary.itinerary_image?.[0]?.url,
        description: itenerary.description,
        deleted_images: itenerary.id && !itenerary.itinerary_image?.[0]?.url
            ? itenerary.id
            : undefined,
    }));

// format itenerary list as initial data
const formatItenerariesData = (iteneraryList) => iteneraryList
    .map((itenerary) => ({
        id: itenerary.id,
        title: itenerary.title,
        location: itenerary.location,
        latitude: itenerary.latitude,
        longitude: itenerary.longitude,
        itinerary_image: [{ url: itenerary.image, thumbUrl: itenerary.image }],
        description: itenerary.description,
    }));

const Itenerary = ({ form, isEdit, regStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [itemIndex, setItemIndex] = useState(0);

    const validations = useTranslatedValidationConfig(baseValidations);

    const onFinish = useCallback((values) => {
        const data = {
            itinerary: values.iteneraries
                ? formatItenerariesForSubmit(values.iteneraries)
                : [],
        };
        if (isEdit && regStep > 2) {
            const step = 2;
            const completedStep = 2;
            dispatch(actions.requestSubmitTripItenerary(
                data,
                step,
                completedStep,
            ));
        } else {
            const step = 2;
            const completedStep = 1;
            dispatch(actions.requestSubmitTripItenerary(
                data,
                step,
                completedStep,
            ));
        }
    }, [dispatch]);

    const iteneraryList = useSelector(selectors.itenerary);

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    useEffect(() => {
        let initialData = {
            iteneraries: [{ }],
        };
        if (iteneraryList.length !== 0) {
            initialData = {
                iteneraries: formatItenerariesData(iteneraryList),
            };
        }
        form.setFieldsValue(initialData);
    }, [iteneraryList]);

    useEffect(() => {
        if (isEdit && iteneraryList.length !== 0) {
            setItemIndex(iteneraryList.length - 1);
        }
    }, [isEdit, iteneraryList]);

    return (
        <Form
            id="trip-itenerary-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
        >
            <HeaderWithHelp
                title="Itenerary"
                question={question}
                answer={answer}
            />
            <Divider />

            <Form.List name="iteneraries">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <IteneraryItem
                                key={field.key}
                                field={field}
                                remove={remove}
                                validations={validations}
                                itemIndex={itemIndex}
                                formListName="iteneraries"
                                iteneraryList={iteneraryList}
                                form={form}
                            />
                        ))}

                        <Divider />

                        <Row justify="end" align="middle">
                            <Col>
                                <Form.Item className="add-button-form">
                                    <Button
                                        className="button add-button-outlined"
                                        onClick={() => {
                                            add();
                                            setItemIndex(itemIndex + 1);
                                        }}
                                    >
                                        <PlusIcon />
                                        {t('add new item').toUpperCase()}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default Itenerary;
