import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    name: {
        name: 'Name',
        required: true,
        min: 5,
        max: 150,
    },
    duration: {
        name: 'Duration',
        required: true,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    style: {
        name: 'Package style',
        required: true,
    },
    adultsNumber: {
        name: 'Adults number',
        required: true,
    },
    adultsPrice: {
        name: 'Adults price',
        required: true,
    },
    extraServiceName: {
        name: 'Extra service name',
        required: true,
    },
    // extraServicePrice: {
    //     name: 'Extra service price',
    //     required: true,
    // },
};

export default buildValidationsFromConfig(config);
