import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    taxDetails: null,
    messageSuccess: '',
    errors: null,
    submitting: false,
    loading: false,
};

export const selectors = createSelectorsFromModel(
    (state) => state.settings.taxes.create,
    model,
);

export default model;
