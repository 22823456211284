import { createActions } from 'reduxsauce';
import { removeSelectedItem, upseartItem } from '@/helpers/CRUD';

const { Types: types, Creators: actions } = createActions({
    // go to step
    goToCreateCruiseStep: ['step'],

    // fetch for edit
    requestFetchCruiseData: ['id'],
    fetchCruiseDataSucceeded: [
        'generalData',
        'shipData',
        'iteneraryData',
        'inclusionsData',
        'exclusionsData',
    ],
    fetchCruiseDataFailed: ['errors'],

    // submit first step
    requestSubmitCruiseGeneralDetails: ['data', 'currStep', 'complStep'],
    submitCruiseGeneralDetailsSucceeded: ['data', 'currStep', 'complStep'],
    submitCruiseGeneralDetailsFailed: ['errors'],

    // fetch ships
    requestGetCruiseShipList: ['filters'],
    getCruiseShipListSucceeded: ['data'],
    getCruiseShipListFailed: ['errors'],

    // create/view ships mode actions
    goToCruiseViewShips: null,
    goToCruiseCreateShip: null,

    // create ship
    requestCreateCruiseShip: ['data'],
    createCruiseShipSucceeded: ['data'],
    createCruiseShipFailed: ['errors'],

    // view ship
    requestViewShip: ['id'],
    viewShipSucceeded: ['data'],
    viewShipFailed: ['errors'],

    // delete ship
    requestDeleteShip: ['id'],
    deleteShipSucceeded: ['id'],
    deleteShipFailed: ['errors'],

    // submit second step
    requestSubmitCruiseShipDetails: ['data', 'currStep', 'complStep'],
    submitCruiseShipDetailsSucceeded: ['data', 'currStep', 'complStep'],
    submitCruiseShipDetailsFailed: ['errors'],

    // submit third step
    requestSubmitCruiseItenerary: ['data', 'currStep', 'complStep'],
    submitCruiseItenerarySucceeded: ['data', 'currStep', 'complStep'],
    submitCruiseIteneraryFailed: ['errors'],

    // submit fourth step
    requestSubmitCruiseInclusionsExclusions: ['data', 'currStep', 'complStep'],
    submitCruiseInclusionsExclusionsSucceeded: [
        'inclusionsData',
        'exclusionsData',
        'currStep',
        'complStep',
    ],
    submitCruiseInclusionsExclusionsFailed: ['errors'],

    // get cruise types
    requestGetCruiseTypes: ['data'],
    getCruiseTypesSucceeded: ['data'],
    getCruiseTypesFailed: ['errors'],

    // add type
    requestAddNewCruiseType: ['data'],
    addNewCruiseTypeSucceeded: ['data'],
    addNewCruiseTypeFailed: ['cruiseTypeError'],

    // clear state
    clearCruiseState: null,

    // clear ship details
    clearShipDetails: null,

    // clear type error
    clearCruiseTypeError: null,
});

const goToExactStep = (step) => {
    if (step === 4) {
        return 0;
    }
    return step - 1;
};

export default {
    // go to step
    [types.GO_TO_CREATE_CRUISE_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_CRUISE_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_CRUISE_DATA_SUCCEEDED]: (
        state,
        {
            generalData,
            shipData,
            iteneraryData,
            inclusionsData,
            exclusionsData,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: goToExactStep(generalData.tour_register_step),
            completedStep: goToExactStep(generalData.tour_register_step),
            loading: false,
            generalInfo: generalData,
            shipDetails: shipData,
            itenerary: iteneraryData,
            inclusions: inclusionsData,
            exclusions: exclusionsData,
        },
    }),
    [types.FETCH_CRUISE_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_CRUISE_GENERAL_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_CRUISE_GENERAL_DETAILS_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            generalInfo: data,
        },
    }),
    [types.SUBMIT_CRUISE_GENERAL_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // fetch ships
    [types.REQUEST_GET_CRUISE_SHIP_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            shipList: [],
            loading: true,
            errors: null,
        },
    }),
    [types.GET_CRUISE_SHIP_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            shipList: data,
            loading: false,
        },
    }),
    [types.GET_CRUISE_SHIP_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            loading: false,
        },
    }),

    // create/view ships mode actions
    [types.GO_TO_CRUISE_VIEW_SHIPS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            isCreatingShip: false,
        },
    }),
    [types.GO_TO_CRUISE_CREATE_SHIP]: (state) => ({
        ...state,
        create: {
            ...state.create,
            isCreatingShip: true,
        },
    }),

    // create ship
    [types.REQUEST_CREATE_CRUISE_SHIP]: (state) => ({
        ...state,
        create: {
            ...state.create,
            errors: null,
            submittingShip: true,
        },
    }),
    [types.CREATE_CRUISE_SHIP_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            shipList: upseartItem(state.create.shipList, data),
            isCreatingShip: false, // redirect to view ship
            submittingShip: false,
        },
    }),
    [types.CREATE_CRUISE_SHIP_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submittingShip: false,
            errors,
        },
    }),

    // view ship
    [types.REQUEST_VIEW_SHIP]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.VIEW_SHIP_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            ship: data,
        },
    }),
    [types.VIEW_SHIP_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // delete ship
    [types.REQUEST_DELETE_SHIP]: (state, { id }) => ({
        ...state,
        create: {
            ...state.create,
            id,
            loading: true,
            errors: null,
        },
    }),
    [types.DELETE_SHIP_SUCCEEDED]: (state, { id }) => ({
        ...state,
        create: {
            ...state.create,
            shipList: removeSelectedItem(state.create.shipList, id),
            id: null,
            loading: false,
        },
    }),
    [types.DELETE_SHIP_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            id: null,
            loading: false,
            errors,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_CRUISE_SHIP_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_CRUISE_SHIP_DETAILS_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            shipDetails: { ...data },
        },
    }),
    [types.SUBMIT_CRUISE_SHIP_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_CRUISE_ITENERARY]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_CRUISE_ITENERARY_SUCCEEDED]: (state, { data, currStep, complStep }) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            itenerary: data,
        },
    }),
    [types.SUBMIT_CRUISE_ITENERARY_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit forth step
    [types.REQUEST_SUBMIT_CRUISE_INCLUSIONS_EXCLUSIONS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_CRUISE_INCLUSIONS_EXCLUSIONS_SUCCEEDED]: (
        state,
        {
            inclusionsData,
            exclusionsData,
            currStep,
            complStep,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: currStep,
            completedStep: complStep,
            submitting: false,
            inclusions: inclusionsData,
            exclusions: exclusionsData,
        },
    }),
    [types.SUBMIT_CRUISE_INCLUSIONS_EXCLUSIONS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // get cruise types
    [types.REQUEST_GET_CRUISE_TYPES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),

    [types.GET_CRUISE_TYPES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            cruiseTypes: data,
            loading: false,
            errors: null,
        },
    }),
    [types.GET_CRUISE_TYPES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // add cruise type
    [types.REQUEST_ADD_NEW_CRUISE_TYPE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            cruiseTypeError: null,
        },
    }),

    [types.ADD_NEW_CRUISE_TYPE_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            cruiseTypes: [...state.create.cruiseTypes, data],
            cruiseTypeError: null,
        },
    }),
    [types.ADD_NEW_CRUISE_TYPE_FAILED]: (state, { cruiseTypeError }) => ({
        ...state,
        create: {
            ...state.create,
            cruiseTypeError,
        },
    }),

    // clear type error
    [types.CLEAR_CRUISE_TYPE_ERROR]: (state) => ({
        ...state,
        create: {
            ...state.create,
            cruiseTypeError: null,
        },
    }),

    // clear state
    [types.CLEAR_CRUISE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            generalInfo: null,
            shipDetails: [],
            itenerary: [],
            inclusions: [],
            exclusions: [],
            shipList: [],
            ship: null,
            cruiseTypeError: null,
        },
    }),

    // clear ship details
    [types.CLEAR_SHIP_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            ship: null,
        },
    }),
};

export { types, actions };
