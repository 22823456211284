import BaseService from '@/services/BaseService';

class B2BShareService extends BaseService {
    shareService = (id, data) => this.post(`/agency/addsharedservice/${id}`, data);

    viewSharedService = (id, data) => this.post(`/agency/viewsharedservice/${id}`, data);

    removeSharedService = (id, data) => this.put(`/agency/removeshared/${id}`, data);

    removeSharedCollectedService = (id, data) => this.put(`/agency/removesharedcollected/${id}`, data);
}
export default new B2BShareService();
