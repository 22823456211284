import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import DataContainer from '@/components/DataContainer';
import GoogleMap from '@/components/GoogleMap';

const TripItenerary = ({ data }) => {
    const { t } = useTranslation();

    return (
        data.length !== 0 && (
            <DataContainer title={t('Tour Itenerary')}>
                <Card>
                    <GoogleMap
                        latitude={data[0]?.latitude}
                        longitude={data[0]?.longitude}
                        itenerary={data}
                    />
                </Card>
            </DataContainer>
        )
    );
};

export default TripItenerary;
