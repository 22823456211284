import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    requestRegisterAgencyDetails: ['data'],
    registerAgencyDetailsSucceeded: ['data'],
    registerAgencyDetailsFailed: ['errors'],
});

export default {
    [types.REQUEST_REGISTER_AGENCY_DETAILS]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: true,
            errors: null,
        },
    }),
    [types.REGISTER_AGENCY_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        user: { ...state.user, ...data },
        registration: {
            ...state.registration,
            submitting: false,
            registerStep: 4,
        },
    }),
    [types.REGISTER_AGENCY_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            errors: (typeof errors === 'string')
                ? errors
                : Object.fromEntries(
                    Object
                        .entries(errors)
                        .map(([key, value]) => [key, value.message]),
                ),
        },
    }),
};

export { types, actions };
