import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { actions as tripActions } from '@/redux/tour/trip/view/reducer';
import { actions as packageActions } from '@/redux/tour/packages/view/reducer';
import { actions as cruiseActions } from '@/redux/tour/cruise/view/reducer';
import { actions as activityActions } from '@/redux/tour/activities/view/reducer';
import { actions as accommodationActions } from '@/redux/accommodation/hotel/view/reducer';

const noop = () => {};

const LinkButton = ({
    to,
    registerStep,
    totalStep,
    onClick = noop,
    children,
    type,
    ...props
}) => {
    const { push } = useHistory();
    const dispatch = useDispatch();

    const innerOnClick = useCallback((e) => {
        push(to);
        onClick(e);
        if (type === 'trip') {
            dispatch(tripActions.clearTripViewState());
        } else if (type === 'package') {
            dispatch(packageActions.clearPackageViewState());
        } else if (type === 'cruise') {
            dispatch(cruiseActions.clearCruiseViewState());
        } else if (type === 'activity') {
            dispatch(activityActions.clearActivityViewState());
        } else {
            type !== undefined && dispatch(accommodationActions.clearAccommodationViewState());
        }
    }, [push, to, onClick, dispatch, type]);

    return (
        <Button
            disabled={registerStep < totalStep === true}
            onClick={innerOnClick}
            {...props}
        >
            {children}
        </Button>
    );
};

export default LinkButton;
