import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import DataContainer from '@/components/DataContainer';
import { TickIcon } from '@/assets/images/icons/screens';

const FacilitiesAndServices = ({ facilities, services }) => {
    const { t } = useTranslation();
    return (
        (facilities.length || services.length !== 0) && (
            <DataContainer title={t('Facilities & Services')}>
                <Row justify="space-between">
                    <Col span={12} className="services-details">
                        <Typography.Text>
                            {t('Facilities')}
                        </Typography.Text>
                        {facilities.map((facility) => (
                            <Col span={24} key={facility.id}>
                                <TickIcon />
                                <span className="facility-name">{facility.name}</span>
                            </Col>
                        ))}
                    </Col>

                    <Col span={12} className="services-details">
                        <Typography.Text>
                            {t('Services')}
                        </Typography.Text>
                        {services.map((service) => (
                            <Col span={24} key={service.id}>
                                <TickIcon />
                                <span className="facility-name">{service.name}</span>
                            </Col>
                        ))}
                    </Col>
                </Row>
            </DataContainer>
        )
    );
};

export default FacilitiesAndServices;
