import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { HelpIconWaiting, LogOutIconWaiting, WaitingApprove } from '@/assets/images/icons/screens';
import { actions } from '@/redux/authentication/reducer';

const AgencyApprove = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { push } = useHistory();

    const registerStep = useSelector((state) => state.authentication.registration.registerStep);

    const logout = () => {
        dispatch(actions.requestLogout());
    };

    useEffect(() => {
        if (registerStep === 6) {
            push('/app/dashboard');
        }
    }, [registerStep, push]);

    return (
        <div className="empty-list-approve-page empty-list-content">
            <WaitingApprove />

            <div className="text">
                <h1 className="text-title">
                    {
                        t('Welcome to Hola Agency ')
                    }
                </h1>
                <p className="text-paragraph">
                    {
                        t("We're making a revision to your account detail in order to give you a flexible management system for your business. Your account will be active in 24 hours. In meantime, you can take a tour of all the features or ask for any information you need.")
                    }
                </p>

            </div>

            <div className="buttons-waiting-wrapper">
                <Button className="button-waiting button-need-help" onClick={() => {}}>
                    <div>
                        {`${t('Need help')}?`}
                    </div>
                    <div className="button-waiting-icon"><HelpIconWaiting /></div>
                </Button>
                <Button className="button-waiting button-log-out" onClick={logout}>
                    <div>
                        {t('Log out')}
                    </div>
                    <div className="button-waiting-icon"><LogOutIconWaiting /></div>
                </Button>
            </div>
            {/* <LinkButton to={url} className="create-button">
                {t(category)}
            </LinkButton> */}
        </div>
    );
};

export default AgencyApprove;
