import {
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { Upload, Image, Space } from 'antd';
import clsx from 'clsx';
import { DeleteIcon } from '@/assets/images/icons/screens';
import { allowedImageMIMETypes } from '@/constants/upload';
import ActionIcon from '@/components/ActionIcon';

import {
    generateOnProgressHandler,
    generateOnSuccessHandler,
    generateOnRemoveHandler,
    generateOnErrorHandler,
    generateCustomRequestHandler,
} from '../hooks';

const noop = () => {};

const AvatarUpload = ({
    image,
    content,
    className,
    allowedMIMETypes = allowedImageMIMETypes,
    onChange = noop,
    value,
    useType = 'for-tour',
    ...props
}) => {
    const [fileList, setFileList] = useState([]);
    const [deleteImage, setDeleteImage] = useState(false);

    useEffect(() => {
        setFileList(value);
    }, []);

    const isFileAllowedForUpload = useCallback(
        (file) => allowedMIMETypes.includes(file.type),
        [allowedMIMETypes],
    );

    const onStartHandler = useCallback((file) => {
        if (isFileAllowedForUpload(file)) {
            file.status = 'uploading'; // eslint-disable-line no-param-reassign
            file.percent = 0; // eslint-disable-line no-param-reassign
            file.thumbUrl = URL.createObjectURL(file); // eslint-disable-line no-param-reassign
            setFileList([file]);
        }
    }, [isFileAllowedForUpload, setFileList]);

    const onProgressHandler = generateOnProgressHandler({ setFileList });
    const onSuccessHandler = generateOnSuccessHandler({ setFileList });
    const onRemoveHandler = generateOnRemoveHandler({ setFileList });
    const onErrorHandler = generateOnErrorHandler({ setFileList });
    const customRequestHandler = generateCustomRequestHandler({
        canUploadFile: true,
        isFileAllowedForUpload,
    });

    const stringAllowedMIMETypes = useMemo(
        () => allowedMIMETypes.join(', '),
        [allowedMIMETypes],
    );

    useEffect(() => {
        if (value !== fileList) {
            onChange(fileList);
        }
    }, [value, fileList, onChange]);

    useEffect(() => {
        if (deleteImage) {
            setFileList([]);
            setDeleteImage(false);
        }
    }, [deleteImage]);

    const handleDeleteImage = useCallback(() => {
        setDeleteImage(true);
    }, []);

    return (
        <Space
            direction="vertical"
            size={25}
            className="avatar-upload-wrapper"
        >
            <Upload
                {...props}
                className={clsx('avatar-upload', className)}
                value={value}
                fileList={fileList}
                maxCount={1}
                accept={stringAllowedMIMETypes}
                customRequest={customRequestHandler}
                onStart={onStartHandler}
                onProgress={onProgressHandler}
                onSuccess={onSuccessHandler}
                onRemove={onRemoveHandler}
                onError={onErrorHandler}
                itemRender={() => null}
            >
                {(fileList && fileList.length === 1 && fileList[0].thumbUrl !== null) ? (
                    <div>
                        <Image
                            className="image"
                            src={fileList[0].thumbUrl}
                            preview={false}
                            alt="avatar"
                        />
                    </div>
                ) : (
                    image
                )}
            </Upload>
            {(useType === 'for-settings' && fileList.length === 1 && fileList[0].thumbUrl === null && content)
            || (useType === 'for-settings' && fileList.length === 0 && content)}
            {
                fileList && fileList.length === 1 && fileList[0].thumbUrl !== null
                && (
                    <div className="delete-img-icon">
                        <ActionIcon onClick={handleDeleteImage}>
                            <DeleteIcon />
                        </ActionIcon>
                    </div>
                )
            }
        </Space>
    );
};

export default AvatarUpload;
