import BaseService from '@/services/BaseService';

class FlightService extends BaseService {
    getAll = (filters) => this.post('/agency/ticketlist', filters || { });

    getOne = (id) => this.get(`/agency/viewticket/${id}`);

    deleteOne = (id) => this.delete(`/agency/deleteticket/${id}`);

    // create ticket
    createTicket = (data) => this.post('/agency/createnewticket', data);

    // other services
    getNewTicketData = () => this.get('/agency/newticketdetails');

    getAirports = (data) => this.post('agency/airportlist', data || { });

    addNewAirport = (data) => this.post('/agency/addairport', data);

    getAirlines = (data) => this.post('/agency/airlinelist', data || { });

    addNewAirline = (data) => this.post('/agency/addairline', data);

    getExtraServices = () => this.get('/agency/getflightextraservices');
}

export default new FlightService();
