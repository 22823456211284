import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import B2BShareService from '@/services/B2BPartnerServices/B2BShareService';
import { types, actions } from './reducer';

function* shareB2bService({ id, data }) {
    try {
        yield call(B2BShareService.shareService, id, data);
        yield put(actions.shareB2bServiceSucceeded('Shared successfully'));
    } catch (error) {
        yield put(actions.shareB2bServiceFailed(extractError(error)));
    }
}

function* viewSharedService({ id, data }) {
    try {
        const response = yield call(B2BShareService.viewSharedService, id, data);
        yield put(actions.viewSharedServiceSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewSharedServiceFailed(extractError(error)));
    }
}

function* removeSharedService({ id, data }) {
    try {
        yield call(B2BShareService.removeSharedService, id, data);
        yield put(actions.removeSharedServiceSucceeded(id, 'Removed successfully'));
    } catch (error) {
        yield put(actions.removeSharedServiceFailed(extractError(error)));
    }
}

function* removeSharedCollectedService({ id, data }) {
    try {
        yield call(B2BShareService.removeSharedCollectedService, id, data);
        yield put(actions.removeSharedCollectedServiceSucceeded(id, 'Removed successfully'));
    } catch (error) {
        yield put(actions.removeSharedCollectedServiceFailed(extractError(error)));
    }
}

function* watchShareB2bService() {
    yield takeEvery(
        types.REQUEST_SHARE_B2B_SERVICE,
        shareB2bService,
    );
}

function* watchViewSharedService() {
    yield takeEvery(
        types.REQUEST_VIEW_SHARED_SERVICE,
        viewSharedService,
    );
}

function* watchRemoveSharedService() {
    yield takeEvery(
        types.REQUEST_REMOVE_SHARED_SERVICE,
        removeSharedService,
    );
}

function* watchRemoveSharedCollectedService() {
    yield takeEvery(
        types.REQUEST_REMOVE_SHARED_COLLECTED_SERVICE,
        removeSharedCollectedService,
    );
}

function* shareB2bServiceSaga() {
    yield all([
        fork(watchShareB2bService),
        fork(watchRemoveSharedService),
        fork(watchRemoveSharedCollectedService),
        fork(watchViewSharedService),
    ]);
}

export default shareB2bServiceSaga;
