import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import CustomersService from '@/services/CustomersService';
import { types, actions } from './reducer';

function* deleteCustomer({ id }) {
    try {
        yield call(CustomersService.deleteOne, id);
        yield put(actions.deleteCustomerSucceeded(id, 'customer deleted successfully'));
    } catch (error) {
        yield put(actions.deleteCustomerFailed(extractError(error)));
    }
}

function* watchDeleteCustomer() {
    yield takeEvery(
        types.REQUEST_DELETE_CUSTOMER,
        deleteCustomer,
    );
}

function* customerDeleteSaga() {
    yield all([
        fork(watchDeleteCustomer),
    ]);
}

export default customerDeleteSaga;
