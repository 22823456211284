import { useTranslation } from 'react-i18next';
import LinkButton from '@/components/LinkButton';
import { RightIcon } from '@/assets/images/icons/screens';

const ViewButton = ({
    url,
    registerStep,
    totalStep,
    type,
}) => {
    const { t } = useTranslation();

    return (
        <LinkButton
            to={url}
            registerStep={registerStep}
            totalStep={totalStep}
            type={type}
            className="button view-button"
        >
            {t('View details').toUpperCase()}
            <RightIcon />
        </LinkButton>
    );
};

export default ViewButton;
