import { createActions } from 'reduxsauce';
import { replaceUpdatedItem, removeSelectedItem } from '@/helpers/CRUD';

const { Types: types, Creators: actions } = createActions({
    // go to step
    goToCreateEmployeeStep: ['step'],

    // fetch for edit
    requestFetchEmployeeData: ['id'],
    fetchEmployeeDataSucceeded: ['data'], // TODO, add one more ruquest
    fetchEmployeeDataFailed: ['errors'],

    // save data to submit
    saveDataToSubmit: ['data'],

    // submit employee data
    requestSubmitEmployeeDetails: ['data'],
    submitEmployeeDetailsSucceeded: ['data'],
    submitEmployeeDetailsFailed: ['errors'],

    // get groups
    requestGetGroups: null,
    getGroupsSucceeded: ['data'],
    getGroupsFailed: ['errors'],

    // add new group
    requestAddNewGroup: ['data'],
    addNewGroupSucceeded: ['data', 'messageSuccess'],
    addNewGroupFailed: ['messageError'],

    // edit group
    requestEditGroup: ['id', 'data'],
    editGroupSucceeded: ['data', 'messageSuccess'],
    editGroupFailed: ['messageError'],

    // get all roles
    requestGetRoles: [null],
    getRolesSucceeded: ['data'],
    getRolesFailed: ['errors'],

    // delete group
    requestDeleteGroup: ['id'],
    deleteGroupSucceeded: ['id', 'messageSuccess'],
    deleteGroupFailed: ['messageError'],

    // get group roles
    requestGetGroupRoles: ['id'],
    getGroupRolesSucceeded: ['data'],
    getGroupRolesFailed: ['errors'],

    // clear state
    clearEmployeeState: null,

    // clear group action state
    clearGroupActionState: null,
});

export default {
    // go to step
    [types.GO_TO_CREATE_EMPLOYEE_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_EMPLOYEE_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_EMPLOYEE_DATA_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            totalDetails: data,
        },
    }),
    [types.FETCH_EMPLOYEE_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // save data to submit
    [types.SAVE_DATA_TO_SUBMIT]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            personalDetails: data,
            step: 1,
            completedStep: 0,
        },
    }),

    // submit employee data
    [types.REQUEST_SUBMIT_EMPLOYEE_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_EMPLOYEE_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            step: 2,
            completedStep: 1,
            submitting: false,
            totalDetails: data,
        },
    }),
    [types.SUBMIT_EMPLOYEE_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // get groups
    [types.REQUEST_GET_GROUPS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_GROUPS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            groups: data,
        },
    }),
    [types.GET_GROUPS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // add new group
    [types.REQUEST_ADD_NEW_GROUP]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.ADD_NEW_GROUP_SUCCEEDED]: (state, { data, messageSuccess }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            groups: [...state.create.groups, data],
            messageSuccess,
            newGroupData: data,
        },
    }),
    [types.ADD_NEW_GROUP_FAILED]: (state, { messageError }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            messageError,
        },
    }),

    // edit group
    [types.REQUEST_EDIT_GROUP]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.EDIT_GROUP_SUCCEEDED]: (state, { data, messageSuccess }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            groups: replaceUpdatedItem(state.create.groups, data),
            roles: replaceUpdatedItem(state.create.roles, data),
            messageSuccess,
        },
    }),
    [types.EDIT_GROUP_FAILED]: (state, { messageError }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            messageError,
        },
    }),

    // get roles
    [types.REQUEST_GET_ROLES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_ROLES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            roles: data,
        },
    }),
    [types.GET_ROLES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // delete group
    [types.REQUEST_DELETE_GROUP]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.DELETE_GROUP_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            groups: removeSelectedItem(state.create.groups, id),
            messageSuccess,
        },
    }),
    [types.DELETE_GROUP_FAILED]: (state, { messageError }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            messageError,
        },
    }),

    // get group roles
    [types.REQUEST_GET_GROUP_ROLES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_GROUP_ROLES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            groupRoles: data,
        },
    }),
    [types.GET_GROUP_ROLES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // clear state
    [types.CLEAR_EMPLOYEE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            personalDetails: null,
            totalDetails: null,
        },
    }),

    // clear group action state
    [types.CLEAR_GROUP_ACTION_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            messageSuccess: '',
            messageError: null,
        },
    }),
};

export { types, actions };
