// Remove unnecessary values from the room/cabin object
// These values are unnecessary for the backend
export const removeUnnecessaryValues = (roomListSelected, keysToRemove) => roomListSelected.map(
    (room) => {
        const newRoom = { ...room }; // Create a copy of the room/cabin
        // Delete the specified key-value pairs
        keysToRemove.forEach((key) => delete newRoom[key]);
        return newRoom; // Return the modified room/cabin
    },
);
