import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Steps, Row, Col } from 'antd';
import { firstToUpper } from '@/helpers/string';
import EmptyData from '@/components/EmptyData';
import { selectors } from '@/redux/accommodation/hotel/create/model';
import Details from './Details';
import FacilitiesAndServices from './FacilitiesAndServices';
import Seasons from './Seasons';
import Rooms from './Rooms';

const AddHotelSteps = ({ step }) => {
    const { t } = useTranslation();
    /**
     * { string } type
     */
    const { type } = useParams();
    const formattedType = firstToUpper(type);

    const accommodationDetails = useSelector(selectors.accommodationDetails);
    const facilities = useSelector(selectors.facilities);
    const services = useSelector(selectors.services);
    const roomList = useSelector(selectors.roomList);
    const seasonList = useSelector(selectors.seasonList);
    return (
        <>
            <Steps
                className="steps-container"
                current={step}
                labelPlacement="vertical"
                items={[

                    { title: t(`${formattedType} Details`) },
                    { title: t(`${formattedType} Facilities & Services`) },
                    { title: t('Rooms') },
                    { title: t('Seasonal Prices') },

                ]}
            />

            <div className="data-display">
                {(accommodationDetails !== null)
                    ? (
                        <Row gutter={[0, 0]}>
                            <Col xs={24}>
                                <Details data={accommodationDetails} />
                            </Col>
                            <Col xs={24}>
                                <FacilitiesAndServices
                                    facilities={facilities}
                                    services={services}
                                />
                            </Col>
                            <Col xs={24}>
                                <Rooms data={roomList} />
                            </Col>
                            <Col xs={24}>
                                <Seasons data={seasonList} />
                            </Col>
                        </Row>
                    )
                    : (<EmptyData />)}
            </div>
        </>
    );
};

export default AddHotelSteps;
