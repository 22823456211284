import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('invoices');

const actions = {
    requestFetchInvoices: baseActions.request,
    fetchInvoicesSucceeded: baseActions.succeeded,
    fetchInvoicesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
