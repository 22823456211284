import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view employee details
    requestViewEmployeeDetails: ['id'],
    viewEmployeeDetailsSucceeded: ['data'],
    viewEmployeeDetailsFailed: ['error'],

    // get employee invoices
    requestGetEmployeeInvoices: ['id', 'filters', 'isFetchingMore'],
    getEmployeeInvoicesSucceeded: ['data', 'totalInvoices'],
    getEmployeeInvoicesFailed: ['error'],

    // get more employee invoices
    getMoreEmployeeInvoices: ['filters'],
    getMoreEmployeeInvoicesSucceeded: ['data'],
    getMoreEmployeeInvoicesFailed: ['error'],

    // get skill list
    requestViewSkillList: null,
    viewSkillListSucceeded: ['data'],
    viewSkillListFailed: ['error'],
});

export default {
    // view employee details
    [types.REQUEST_VIEW_EMPLOYEE_DETAILS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_EMPLOYEE_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            employeeDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_EMPLOYEE_DETAILS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // view skill list
    [types.REQUEST_VIEW_SKILL_LIST]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_SKILL_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            skillList: data,
            loading: false,
        },
    }),
    [types.VIEW_SKILL_LIST_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // get employee invoices
    [types.REQUEST_GET_EMPLOYEE_INVOICES]: (state, { isFetchingMore }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: !isFetchingMore,
            fetchingError: null,
        },
    }),
    [types.GET_MORE_EMPLOYEE_INVOICES]: (state) => ({
        ...state,
        view: {
            ...state.view,
            isLoadingMore: true,
            isLoadingMoreError: null,
        },
    }),
    [types.GET_EMPLOYEE_INVOICES_SUCCEEDED]: (state, { data, totalInvoices }) => ({
        ...state,
        view: {
            ...state.view,
            invoiceList: data,
            total: totalInvoices,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
        },
    }),
    [types.GET_MORE_EMPLOYEE_INVOICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            invoiceList: [...state.view.invoiceList, ...data],
            fetchingLoading: false,
            fetchingError: null,
            isLoadingMore: false,
            hasMore: data.length !== 0,
        },
    }),
    [types.GET_EMPLOYEE_INVOICES_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: false,
            fetchingError: error,
        },
    }),
    [types.GET_MORE_EMPLOYEE_INVOICES_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            isFetchingMore: false,
            isLoadingMoreError: error,
        },
    }),
};
