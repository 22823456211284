/* eslint-disable max-len */
import {
    Row,
    Col,
    Card,
    Divider,
    Space,
    Image,
} from 'antd';
import { LocationIconBlue } from '@/assets/images/icons/screens';
import decodeHtml from '@/helpers/decodeHtml';

const TripItenerary = ({ data }) => {
    return (
        <Card className="tour-itenerary-list">
            {data.map((itenerary) => (
                <div className="tour-itenerary-item" key={itenerary.id}>
                    <Row>
                        <Col sm={8} xs={24}>
                            <Image
                                src={itenerary.image}
                                alt="image"
                                preview={false}
                            />
                        </Col>

                        <Col sm={16} xs={24}>
                            <div className="tour-itenerary-content">
                                <Space size={10}>
                                    <div className="itenerary-day">
                                        <span>
                                            {itenerary.day}
                                        </span>
                                    </div>
                                    <span>
                                        {itenerary.title}
                                    </span>
                                </Space>

                                <div className="main-details">
                                    <div>
                                        <div className="location-icon-block">
                                            <LocationIconBlue />
                                        </div>
                                    </div>
                                    <span className="itenerary-address">{itenerary.location}</span>
                                </div>

                                <Divider />

                                <div>
                                    <span className="itenerary-description">
                                        <div dangerouslySetInnerHTML={{ __html: decodeHtml(itenerary.description) }} />
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ))}
        </Card>
    );
};

export default TripItenerary;
