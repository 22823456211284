import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    type: {
        name: 'Offer type',
        required: true,
    },
    name: {
        name: 'Offer name',
        required: true,
    },
    discount: {
        name: 'Discount',
        required: true,
    },
    discount_type: {
        name: 'Discount type',
        required: true,
    },
    date_start: {
        name: 'Start date',
        required: true,
    },
    date_end: {
        name: 'End date',
        required: true,
    },
    room_types: {
        name: 'Room types',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
