import BaseService from '@/services/BaseService';

class TaxesService extends BaseService {
    getAll = (filters) => this.post('/agency/taxeslist', filters || { });

    deleteOne = (id) => this.delete(`/agency/deletetax/${id}`);

    addTaxDetails = (data) => this.post('/agency/newtax', data);

    editTaxDetails = (id, data) => this.post(`/agency/edittax/${id}`, data);

    viewTaxDetails = (id) => this.get(`/agency/viewtax/${id}`);
}

export default new TaxesService();
