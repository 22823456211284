import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    step: 0, // 0, 1, 2
    submitting: false,
    errors: null,
    agencyAccount: null,
    agencyInfo: null,
    distributionMessage: '',

    allAgencyServices: [],
    agencyName: '',
    checkedServices: [],
    agencyList: [],
    loading: false,
};

export const selectors = createSelectorsFromModel(
    (state) => state.b2b.partner.create,
    model,
);

export default model;
