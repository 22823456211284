import { Form, Divider } from 'antd';

import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import CreateSection from '@/components/CreateInclExclSection';
import useFacilities from '@/hooks/useFacilities';
import AccommodationService from '@/services/AccommodationService';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const Facilities = ({ initialFacilitiesList }) => {
    const { data, isValidating, mutate } = useFacilities();
    return (
        <div className="facilities">
            <HeaderWithHelp
                title="Facilities"
                question={question}
                answer={answer}
            />

            <Divider />

            {isValidating ? 'loading' : (
                <>

                    <Form.Item
                        name="facilities"
                    >
                        <CheckboxMultiSelect
                            options={data.data}
                            labelParamName="name"
                            valueParamName="id"
                            initialCheckedList={initialFacilitiesList}
                        />
                    </Form.Item>

                    <CreateSection
                        data={data.data}
                        mutate={mutate}
                        buttonText="Add New Facility"
                        addNewItem={AccommodationService.addNewFacility}
                    />
                </>
            )}
        </div>
    );
};

export default Facilities;
