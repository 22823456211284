import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import useCruiseTourAmenities from '@/hooks/useCruiseTourAmenities';

const Amenities = ({ field, initialAmenities }) => {
    const { t } = useTranslation();
    const { data } = useCruiseTourAmenities();

    return (
        <>
            <span className="amenities-title">{t('Amenities')}</span>
            <Form.Item name={[field.name, 'amenities']}>
                <CheckboxMultiSelect
                    col={6}
                    options={data?.data}
                    labelParamName="name"
                    valueParamName="id"
                    hasSelectAll={false}
                    initialCheckedList={initialAmenities}
                />
            </Form.Item>
        </>
    );
};

export default Amenities;
