import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Tooltip,
    Button,
    Card,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';

const TooltipInfo = ({ data }) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);

    return (
        <Tooltip
            color="#ffffff"
            open={visible}
            placement="bottom"
            title={(
                <Card
                    className="info-card"
                    bordered={false}
                >
                    <Row gutter={10}>
                        <Col xs={12}>
                            <BlockDescription name={t('Company Name')}>
                                {data.partner.company_name}
                            </BlockDescription>
                        </Col>
                        <Col xs={12}>
                            <BlockDescription name={t('Registration Number')}>
                                {data.partner.vat_number}
                            </BlockDescription>
                        </Col>
                        <Col xs={12}>
                            <BlockDescription name={t('Phone')}>
                                {data.partner.phone_number}
                            </BlockDescription>
                        </Col>
                        <Col xs={12}>
                            <BlockDescription name={t('Email')}>
                                {data.partner.email}
                            </BlockDescription>
                        </Col>
                        <Col xs={12}>
                            <BlockDescription name={t('Address')}>
                                {data.partner.address}
                            </BlockDescription>
                        </Col>
                        <Col xs={12}>
                            <BlockDescription name={t('Website')}>
                                {data.partner.website}
                            </BlockDescription>
                        </Col>
                    </Row>
                </Card>
            )}
        >
            <Button
                className="tooltip-button"
                type="text"
                onClick={() => setVisible(!visible)}
            >
                {data.partner.company_name}
            </Button>
        </Tooltip>
    );
};

export default TooltipInfo;
