import { useCallback } from 'react';
import { Dropdown, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import { MoreOutlinedIcon } from '@/assets/images/icons/screens';
import { actions as activitiesActions } from '@/redux/tour/activities/create/reducer';

const Menu = ({
    editUrl,
    deleteAction,
    type,
    isShared,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    name,
    setItemName,
}) => {
    const { push } = useHistory();
    const dispatch = useDispatch();

    const onEdit = useCallback(() => {
        push(editUrl);
        if (type === 'activity') {
            dispatch(activitiesActions.clearActivityState());
        }
    }, [push, editUrl]);

    return (
        <Dropdown
            trigger={['click']}
            placement="bottom"
            overlayClassName="edit-delete-dropdown"
            menu={{
                items: SimpleDropdownMenu({
                    onEditClick: onEdit,
                    deleteAction,
                    type,
                    isShared,
                    removeSharedServiceAction,
                    removeSharedCollectedServiceAction,
                    name,
                    setItemName,
                }),
            }}
        >
            <Button type="text" className={!myService && 'display-none'}>
                <MoreOutlinedIcon />
            </Button>
        </Dropdown>
    );
};

export default Menu;
