import { Waypoint } from 'react-waypoint';
import { Row, Col } from 'antd';
import EmptyList from '@/components/EmptyList';
import Filters from '@/components/Invoice/InvoiceFilters';
import InvoiceTable from './InvoiceTable';

const WorkDetails = ({
    invoices,
    totalInvoices,
    filters,
    setFilters,
    handleFetchMore,
    isLoadingMore,
    hasMore,
    loading,
    customerData,
}) => {
    if (invoices.length === 0) {
        return (
            <EmptyList
                src="/assets/images/icons/invoices.svg"
                message={'There are no data stored yet'.toUpperCase()}
            />
        );
    } else {
        return (
            loading
                ? '...Loading'
                : (
                    <>
                        <Row gutter={[0, 30]}>
                            <Col xs={24}>
                                <Filters
                                    invoices={invoices}
                                    total={totalInvoices}
                                    filters={filters}
                                    setFilters={setFilters}
                                    usedForCustomerInvoices={true}
                                    customerData={customerData}
                                />
                            </Col>
                            <Col xs={24}>
                                <InvoiceTable data={invoices} />
                            </Col>
                        </Row>
                        {!isLoadingMore && hasMore && (
                            <Waypoint onEnter={() => handleFetchMore()} />
                        )}
                    </>
                )
        );
    }
};

export default WorkDetails;
