import { Switch, Route, Redirect } from 'react-router-dom';

import General from './General';
import Services from './Services';
import Taxes from './Taxes';
import Billing from './Billing';

export const routes = [
    {
        name: 'General',
        icon: 'general icon',
        description: 'general desc',
        url: '/general',
        component: General,
    },
    {
        name: 'Services',
        icon: 'service icon',
        description: 'service desc',
        url: '/services',
        component: Services,
    },
    {
        name: 'Taxes',
        icon: 'tax icon',
        description: 'tax desc',
        url: '/taxes',
        component: Taxes,
    },
    {
        name: 'Billing',
        icon: 'bill icon',
        description: 'bill desc',
        url: '/billing',
        component: Billing,
    },
];

const Settings = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/settings${route.url}`}
                    exact={route.exact}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Settings;
