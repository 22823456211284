import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    name: {
        name: 'Name',
        required: true,
        min: 5,
        max: 150,
    },
    defaultPrice: {
        name: 'Default Price',
        required: true,
    },
    availability: {
        name: 'Availability',
        required: true,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    address: {
        name: 'Address',
        required: true,
        min: 5,
        max: 150,
    },
    mapLatitude: {
        name: 'Latitude',
        required: true,
    },
    mapLongitude: {
        name: 'Longitude',
        required: true,
    },
    extraServiceName: {
        name: 'Extra service name',
        required: true,
    },
    // extraServicePrice: {
    //     name: 'Extra service price',
    //     required: true,
    // },
};

export default buildValidationsFromConfig(config);
