import { Row, Col, Steps } from 'antd';
import { useTranslation } from 'react-i18next';

const StepDisplay = ({ step: currentStep }) => {
    const { t } = useTranslation();

    return (
        <div className="step-display">
            <Row justify="center">
                <Col>
                    <Steps
                        current={currentStep}
                        labelPlacement="vertical"
                        items={[

                            { title: t('Personal Details') },
                            { title: t('Salary and Job Position') },
                        ]}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default StepDisplay;
