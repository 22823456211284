import { Typography } from 'antd';
import clsx from 'clsx';

const BlockDescription = ({
    name,
    children,
    className,
    childClassName,
}) => {
    return (
        <div className="info-section">
            <div className={clsx(className || 'name')}>
                <Typography.Text>
                    {name}
                </Typography.Text>
            </div>

            <div className={clsx(childClassName || 'content')}>
                {children}
            </div>
        </div>
    );
};

export default BlockDescription;
