import { ToggleIcon1, ToggleIcon2 } from '@/assets/images/icons/topbar';

const SubMenu = ({
    isCollapsed,
    setIsCollapsed,
}) => (isCollapsed ? (
    <ToggleIcon1 className="stbi" onClick={() => setIsCollapsed(false)} />
) : (<ToggleIcon2 className="stbi" onClick={() => setIsCollapsed(true)} />

));

export default SubMenu;
