import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Accommodation from './Accommodation';
import { basePath } from './constants';

const Accommodations = () => {
    return (
        <Switch>
            <Route path={basePath} exact component={Dashboard} />

            <Route path={`${basePath}/:type`} component={Accommodation} />
        </Switch>
    );
};

export default Accommodations;
