import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import { actions } from '@/redux/tour/cruise/delete/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import TourTypeListCard from '@/components/TourTypeListCard';
import { CalendarIcon, LocationIcon, PriceIcon } from '@/assets/images/icons/screens';
import useNotification from '@/hooks/useNotification';

const CruiseList = ({ cruises, viewMode }) => {
    const { t } = useTranslation();

    const displayType = viewMode;
    const isList = (displayType === 'list');

    const [itemName, setItemName] = useState('');

    const messageSuccess = useSelector((state) => state.tour.cruise.delete.messageSuccess);
    const error = useSelector((state) => state.tour.cruise.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: itemName,
        clearAction: actions.clearDeleteCruiseState(),
        mode: 'list',
    });

    return (
        <Row gutter={isList ? [0, 15] : [30, 30]}>
            {cruises.map((cruise) => (
                <TourTypeListCard
                    key={cruise.id}
                    image={cruise.image}
                    title={cruise.name}
                    price={isList ? (
                        <>
                            <PriceIcon />
                            {' '}
                            {`${cruise.currency_icon}${cruise.adult_price} / ${cruise.price_type}`}
                        </>
                    ) : (
                        <>
                            <div>{t('Avg. Price')}</div>
                            {`${cruise.currency_icon}${cruise.adult_price} / ${cruise.price_type}`}
                        </>
                    )}
                    location={(
                        <>
                            <LocationIcon />
                            {' '}
                            {cruise.arrival_place}
                        </>
                    )}
                    duration={(
                        <>
                            <CalendarIcon />
                            {' '}
                            {cruise.start_date}
                            {' - '}
                            {cruise.end_date}
                        </>
                    )}
                    agency={(
                        <>
                            {!isList && cruise.b2b_agency !== null && ('Collected from:')}
                            {cruise.b2b_agency}
                        </>
                    )}
                    url={`/app/tours/cruise/${cruise.id}`}
                    description={cruise.description}
                    registerStep={cruise.tour_register_step}
                    totalStep={4}
                    displayType={displayType}
                    editUrl={`/app/tours/cruise/${cruise.id}/edit`}
                    deleteAction={actions.requestDeleteTourCruise(cruise.id)}
                    type="cruise"
                    removeSharedServiceAction={shareActions.requestRemoveSharedService(
                        cruise.id,
                        { type: 1 },
                    )}
                    removeSharedCollectedServiceAction={
                        shareActions.requestRemoveSharedCollectedService(
                            cruise.id,
                            { type: 1 },
                        )
                    }
                    isShared={cruise.share}
                    id={cruise.id}
                    myService={cruise.b2b_agency === null}
                    setItemName={setItemName}
                />
            ))}
        </Row>
    );
};

export default CruiseList;
