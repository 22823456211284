import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import { actions } from '@/redux/tour/activities/delete/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import TourTypeListCard from '@/components/TourTypeListCard';
import { CalendarIcon, LocationIcon, PriceIcon } from '@/assets/images/icons/screens';
import useNotification from '@/hooks/useNotification';

const TripList = ({ activities, viewMode }) => {
    const { t } = useTranslation();
    const displayType = viewMode;
    const isList = (displayType === 'list');

    const [itemName, setItemName] = useState('');

    const messageSuccess = useSelector((state) => state.tour.activities.delete.messageSuccess);
    const error = useSelector((state) => state.tour.activities.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: itemName,
        clearAction: actions.clearDeleteActivityState(),
        mode: 'list',
    });

    return (
        <Row gutter={isList ? [0, 15] : [30, 30]}>
            {activities.map((activity) => (
                <TourTypeListCard
                    key={activity.id}
                    image={activity.image}
                    title={activity.name}
                    price={isList ? (
                        <>
                            <PriceIcon />
                            {' '}
                            {`${activity.currency_icon}${activity.price} / ${activity.price_type}`}
                        </>
                    ) : (
                        <>
                            <div>{t('Price')}</div>
                            {`${activity.currency_icon}${activity.price} / ${activity.price_type}`}
                        </>
                    )}
                    location={(
                        <>
                            <LocationIcon />
                            {' '}
                            {activity.address}
                        </>
                    )}
                    duration={(
                        <>
                            <CalendarIcon />
                            {' '}
                            {activity.start_date}
                            {' - '}
                            {activity.end_date}
                        </>
                    )}
                    // agency={(
                    //     <>
                    //         {!isList && activity.b2b_agency !== null && ('Collected from:')}
                    //         {activity.b2b_agency}
                    //     </>
                    // )}
                    url={`/app/tours/activities/${activity.id}`}
                    description={activity.description}
                    registerStep={activity.activity_register_step}
                    totalStep={3}
                    displayType={displayType}
                    editUrl={`/app/tours/activities/${activity.id}/edit`}
                    deleteAction={actions.requestDeleteActivity(activity.id)}
                    type="activity"
                    removeSharedServiceAction={shareActions.requestRemoveSharedService(
                        activity.id,
                        { type: 1 },
                    )}
                    removeSharedCollectedServiceAction={
                        shareActions.requestRemoveSharedCollectedService(
                            activity.id,
                            { type: 1 },
                        )
                    }
                    isShared={activity.share}
                    id={activity.id}
                    myService={true}
                    setItemName={setItemName}
                />
            ))}
        </Row>
    );
};

export default TripList;
