import {
    all,
    fork,
    takeEvery,
    call,
    put,
    select,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import InvoiceService from '@/services/InvoiceService';
import { types, actions } from './reducer';
import { selectors } from './model';

function* submitInvoiceServices({ data }) {
    try {
        const res = yield call(InvoiceService.submitInvoiceServices, data);
        yield put(actions.submitInvoiceServicesSucceeded(res.data));
    } catch (error) {
        yield put(actions.submitInvoiceServicesFailed(extractError(error)));
    }
}

function* submitCustomInvoiceDetails({ data }) {
    try {
        const res = yield call(InvoiceService.submitInvoiceDetails, data);
        yield put(actions.submitCustomInvoiceDetailsSucceeded(res.data));
    } catch (error) {
        yield put(actions.submitCustomInvoiceDetailsFailed(extractError(error)));
    }
}

function* submitInvoiceExtra({ data }) {
    const { invoice_id: id } = yield select(selectors.invoiceTo);
    try {
        const res = yield call(InvoiceService.submitInvoiceExtra, id, data);
        yield put(actions.submitInvoiceExtraSucceeded(res.data));
    } catch (error) {
        yield put(actions.submitInvoiceExtraFailed(extractError(error)));
    }
}

function* fetchCustomInvoiceData({ id }) {
    try {
        const res = yield call(InvoiceService.getOne, id);
        const {
            general_data: generalData,
            first_step: invoiceToData,
        } = res.data;
        const { invoice_data: invoiceExtraData } = generalData;
        yield put(actions.fetchCustomInvoiceDataSucceeded(
            generalData,
            invoiceToData,
            invoiceExtraData,
        ));
    } catch (error) {
        yield put(actions.fetchCustomInvoiceDataFailed(extractError(error)));
    }
}

function* getExistingServices({ filters }) {
    try {
        const res = yield call(InvoiceService.getExistingServices, filters);
        yield put(actions.getExistingServicesSucceeded(res.data));
    } catch (error) {
        yield put(actions.getExistingServicesFailed(extractError(error)));
    }
}

function* getServiceDetails({ id }) {
    try {
        const res = yield call(InvoiceService.getServiceDetails, id);
        yield put(actions.getServiceDetailsSucceeded(res.data));
    } catch (error) {
        yield put(actions.getServiceDetailsFailed(extractError(error)));
    }
}

function* watchSubmitInvoiceServices() {
    yield takeEvery(
        types.REQUEST_SUBMIT_INVOICE_SERVICES,
        submitInvoiceServices,
    );
}

function* watchSubmitCustomInvoiceDetails() {
    yield takeEvery(
        types.REQUEST_SUBMIT_CUSTOM_INVOICE_DETAILS,
        submitCustomInvoiceDetails,
    );
}

function* watchSubmitInvoiceExtra() {
    yield takeEvery(
        types.REQUEST_SUBMIT_INVOICE_EXTRA,
        submitInvoiceExtra,
    );
}

function* watchFetchCustomInvoiceData() {
    yield takeEvery(
        types.REQUEST_FETCH_CUSTOM_INVOICE_DATA,
        fetchCustomInvoiceData,
    );
}

function* watchGetExistingServices() {
    yield takeEvery(
        types.REQUEST_GET_EXISTING_SERVICES,
        getExistingServices,
    );
}

function* watchGetServiceDetails() {
    yield takeEvery(
        types.REQUEST_GET_SERVICE_DETAILS,
        getServiceDetails,
    );
}

function* createCustomInvoiceSaga() {
    yield all([
        fork(watchSubmitInvoiceServices),
        fork(watchSubmitCustomInvoiceDetails),
        fork(watchSubmitInvoiceExtra),
        fork(watchFetchCustomInvoiceData),
        fork(watchGetExistingServices),
        fork(watchGetServiceDetails),
    ]);
}

export default createCustomInvoiceSaga;
