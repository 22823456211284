import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Row, Col, Space } from 'antd';
import privateRoutes from '@/routes/privateRoutes';

const Menu = ({ currentRoute, isCollapsed, setIsCollapsed }) => {
    const menuItems = privateRoutes.filter((route) => route.inSidebar);
    return (
        <div className={clsx('menu-in-topbar', !isCollapsed && 'show-menu')}>
            <Row gutter={[20, 23]}>
                {menuItems.map((item) => (
                    <>
                        <Col key={item.key} xs={12}>
                            <NavLink
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                to={item.path}
                                activeClassName="item-selected"
                            >
                                <div
                                    className={clsx(
                                        'menu-item-wrapper',
                                        currentRoute.path === item.path
                                            && 'selected-div',
                                    )}
                                >
                                    <Space direction="vertical" size={6}>
                                        {item.icon}
                                        {item.name}
                                    </Space>
                                </div>
                            </NavLink>
                        </Col>
                    </>
                ))}
            </Row>
        </div>
    );
};

export default Menu;
