import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Checkbox,
    Divider,
} from 'antd';

import {
    defaultLabelParamName,
    defaultValueParamName,
    defaultGutter,
} from './config';

const CustomCheckBoxGroup = ({
    options,
    labelParamName = defaultLabelParamName,
    valueParamName = defaultValueParamName,
    gutter = defaultGutter,
    selectAllLabel = null,
    checkedRoles,
    checkedList,
    setCheckedList,
    usedFor,
    roleId,
    ...rest
}) => {
    const { t } = useTranslation();

    const [checkAll, setCheckAll] = useState(false);
    const onChange = (list) => {
        setCheckedList({ ...checkedRoles, [`roles${roleId}`]: list });
        setCheckAll(checkedList?.length === options.length);

        if ('onChange' in rest) {
            rest.onChange(list);
        }
    };

    useEffect(() => {
        if (checkedList !== undefined) {
            setCheckAll(checkedList?.length === options.length);
        }
        setCheckAll(checkedList?.length === options.length);
    }, [options, checkedList]);

    const onCheckAllChange = (e) => {
        const checkedValues = e.target.checked
            ? options.map((option) => option[valueParamName])
            : [];
        setCheckedList({ ...checkedRoles, [`roles${roleId}`]: checkedValues });
        setCheckAll(e.target.checked);
        if ('onChange' in rest) {
            rest.onChange(checkedValues);
        }
    };

    return (
        <Row gutter={[30, 30]} className="check-list">
            <Col lg={24}>
                <Checkbox
                    checked={checkAll}
                    onChange={onCheckAllChange}
                >
                    <div className="select-all">
                        { t(selectAllLabel)}
                    </div>
                </Checkbox>
            </Col>
            <>
                <Divider />
                <Col lg={24}>
                    <Checkbox.Group
                        className="checkbox-multi-select"
                        value={checkedList}
                        onChange={onChange}
                    >
                        <Row gutter={gutter}>
                            {options?.map((option) => (
                                <Col key={option[valueParamName]} lg={8}>
                                    <Checkbox
                                        value={option[valueParamName]}
                                    >
                                        {t(option[labelParamName])}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Col>
            </>
        </Row>
    );
};

export default CustomCheckBoxGroup;
