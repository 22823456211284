import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '@react-hook/window-size';
import {
    Row,
    Col,
    Button,
} from 'antd';
import SalesGraph from '@/components/SalesGraph';
import { ArrowRightIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/dashboard/reducer';
import { useCurrency } from '@/hooks/useCurrency';

import OrderCards from './OrderCards';
import MobileOrderCards from './MobileOrderCards';
import ProgressContent from './ProgressContent';
import SharedServiceList from './SharedServiceList';
import Map from './Map';

const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const { icon: currencyIcon } = useCurrency();

    const windowWidth = useWindowWidth({ wait: 50 });

    const data = useSelector((state) => state.dashboard.orders);
    const sales = useSelector((state) => state.dashboard.salesOverview);
    const b2bServices = useSelector((state) => state.dashboard.b2bServices);
    const requestSuccess = useSelector((state) => state.b2b.supplier.collect.message);

    useEffect(() => {
        dispatch(actions.requestGetOrders());
        dispatch(actions.requestGetSalesOverview());
        dispatch(actions.requestGetB2bServices());
    }, [dispatch, requestSuccess]);

    return (
        <Row
            className="dashboard"
            gutter={[25, 30]}
            justify="space-between"
        >
            <Col
                className="left-dashboard"
                xl={{ span: 18, order: 1 }}
                lg={{ span: 24, order: 2 }}
                md={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                xs={{ span: 24, order: 2 }}
            >
                <Row gutter={[0, 30]}>
                    <Col xs={24}>
                        {windowWidth < 768 ? (
                            <MobileOrderCards data={data} />
                        ) : (
                            <OrderCards data={data} />
                        )}
                    </Col>

                    <Col
                        className="sales-overview"
                        xs={24}
                    >
                        <Row gutter={[25, 25]}>
                            <Col
                                lg={17}
                                md={17}
                                sm={24}
                                xs={24}
                            >
                                <SalesGraph data={sales} currency={currencyIcon} />
                            </Col>

                            <Col
                                lg={7}
                                md={7}
                                sm={24}
                                xs={24}
                            >
                                <Row gutter={[0, 25]}>
                                    <Col
                                        xs={24}
                                        className="progress"
                                    >
                                        <ProgressContent currency={currencyIcon} />
                                    </Col>
                                    <Col xs={24}>
                                        <Button
                                            block
                                            className="report-button"
                                            onClick={() => push('/app/stats-reports/in-house-stats')}
                                        >
                                            {t('View full reports').toUpperCase()}
                                            <ArrowRightIcon />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} className="sales-locations">
                        <Map currency={currencyIcon} />
                    </Col>
                </Row>
            </Col>

            <Col
                className="right-dashboard"
                xl={{ span: 6, order: 2 }}
                lg={{ span: 24, order: 1 }}
                md={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                xs={{ span: 24, order: 1 }}
            >
                <SharedServiceList data={b2bServices} />
            </Col>
        </Row>
    );
};

export default Dashboard;
