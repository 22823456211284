import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import CountryListService from '@/services/CountryListService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* getCountryList({ filters }) {
    try {
        const response = yield call(CountryListService.getCountries, filters);
        yield put(actions.getCountryListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getCountryListFailed(extractError(error)));
    }
}

function* watchGetCountryList() {
    yield takeEvery(
        types.REQUEST_GET_COUNTRY_LIST,
        getCountryList,
    );
}

function* countrylistSaga() {
    yield all([
        fork(watchGetCountryList),
    ]);
}

export default countrylistSaga;
