import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Radio,
    Checkbox,
    Input,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import { useCurrency } from '@/hooks/useCurrency';

const PackagePrices = ({ validations, childNumber, infantNumber }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    const [hasChilds, setHasChilds] = useState(false);

    useEffect(() => {
        if ((childNumber !== '0' && childNumber !== undefined) || infantNumber) {
            setHasChilds(true);
        }
    }, [childNumber, infantNumber]);

    const options = useMemo(() => ([
        { label: t('Per adult'), value: 1 },
        { label: t('Per group'), value: 3 },
    ]), [t]);

    return (
        <Row gutter={30}>
            <Col xxl={12} xs={24}>
                <MUIFormItem
                    name="adults"
                    label={`${t('No. of Adults')}: ${t('min')} - ${t('max')}`}
                    rules={validations.adultsNumber}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col xxl={12} xs={24}>
                <MUIFormItem
                    name="adult_price"
                    rules={validations.adultsPrice}
                >
                    <Input
                        suffix={(
                            <Form.Item
                                name="adult_price_type"
                                noStyle
                            >
                                <Radio.Group
                                    options={options}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>
                        )}
                        placeholder={`${currencyIcon}0.00`}
                    />
                </MUIFormItem>
            </Col>

            <Col lg={24} xs={24}>
                <Form.Item>
                    <Checkbox
                        checked={hasChilds}
                        onChange={(e) => setHasChilds(e.target.checked)}
                    >
                        {t('Add Children or Infants')}
                    </Checkbox>
                </Form.Item>
            </Col>

            {hasChilds && (
                <>
                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="children"
                            label={`${t('Max. Children')}:`}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="child_price"
                        >
                            <Input placeholder={`0.00 / ${t('child')}`} />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="infants"
                            label={`${t('Max. Infants')}:`}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xl={12} xs={24}>
                        <MUIFormItem
                            name="infant_price"
                        >
                            <Input placeholder={`0.00 / ${t('infant')}`} />
                        </MUIFormItem>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default PackagePrices;
