import BaseService from '@/services/BaseService';

class MyPartnerService extends BaseService {
    getAll = (filters) => this.post('/agency/b2bpartnerlist', filters || { });

    // view partner
    viewPartner = (id) => this.get(`/agency/viewAgencyB2B/${id}`);

    // agency account
    addAgencyAccount = (data) => this.post('/agency/addvendor', data);

    // agency info
    addAgencyInfo = (agencyId, data) => this.post(`/agency/addvendor2/${agencyId}`, data);

    // agency distribution
    addAgencyDistribution = (agencyId, data) => this.post(`/agency/confirmvendor/${agencyId}`, data);

    // get all agency services
    getAllAgencyServices = () => this.get('/agency/allservicelist');

    // checked services
    getCheckedServices = (id) => this.get(`/agency/serviceagency/${id}`);

    // get agencies
    getAgencyList = (filters) => this.post('/agency/agencylist', filters || { });
}
export default new MyPartnerService();
