import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { actions } from '@/redux/tour/reducer';
import EmptyList from '@/components/EmptyList';
import { EmptyListIcon } from '@/assets/images/icons/screens';
import Filters from '@/components/TourFilters';
import { dateFormat } from '@/helpers/dateFormat';

import ActivitiesList from './ActivitiesList';
import Loading from './Loading';

const ViewAll = () => {
    const dispatch = useDispatch();

    const maxPrice = useSelector((state) => state.maxprice.tourMaxPrice);

    const [filters, baseSetFilters] = useState({ prices: [0, 0] });
    useEffect(() => {
        if (maxPrice !== null) {
            baseSetFilters({ prices: [0, maxPrice] });
        }
    }, [maxPrice]);

    const [viewMode, setViewMode] = useState('list');
    const [page, setPage] = useState(1);

    const handleFetchTourActivities = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                name: filters.name,
                address: filters.location,
                start_date: filters.date?.[0].format(dateFormat),
                end_date: filters.date?.[1].format(dateFormat),
                min_price: filters.prices[0],
                max_price: filters.prices[1],
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            filters.prices[1] !== 0
            && dispatch(actions.requestFetchTourActivities(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters.location,
            filters.name,
            filters.prices,
            filters.date,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const activities = useSelector((state) => state.tour.activities.data);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.tour.activities);

    useEffect(() => {
        handleFetchTourActivities({});
    }, [handleFetchTourActivities]);

    const handleFetchMore = useCallback(() => {
        handleFetchTourActivities({ currentPage: page, fetchMore: true });
    }, [handleFetchTourActivities, page]);

    return (
        <div id="tour-view-all" className="activities-view-all">
            <Filters
                filters={filters}
                setFilters={setFilters}
                viewMode={viewMode}
                setViewMode={setViewMode}
                type="activity"
                searchPlaceholder="Search for Activity name"
                locationPlaceholder="Location"
                addNewItemText="Add new activity"
                addUrl="/app/tours/activities/add"
                clearTourState={actions.clearActivityState()}
            />
            { loading
                ? <Loading />
                : activities.length === 0

                    ? (
                        <EmptyList
                            image={<EmptyListIcon />}
                            category={'Start creating activity'.toUpperCase()}
                            message="Oops! Looks you haven't created yet any Activities"
                            url="/app/tours/activities/add"
                        />
                    ) : (
                        <>
                            <ActivitiesList
                                activities={activities}
                                viewMode={viewMode}
                            />
                            {!isLoadingMore && hasMore && (
                                <Waypoint onEnter={() => handleFetchMore()} />
                            )}
                        </>
                    )}

        </div>
    );
};

export default ViewAll;
