/* eslint-disable max-len */
import {
    Row,
    Col,
    Space,
    Avatar,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';

import BlockDescription from '@/components/Information/WithoutImage/Block';
import DataContainer from '@/components/DataContainer';
import { useCurrency } from '@/hooks/useCurrency';
import decodeHtml from '@/helpers/decodeHtml';

const GeneralDetails = ({ data }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    return (
        <DataContainer title={t('General Details')}>
            <Row justify="space-between" gutter={[0, 15]}>
                <Col>
                    <Space direction="vertical">
                        <BlockDescription name={t('Tour Name')}>
                            <Typography.Text>
                                {data.name}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Duration')}>
                            <Typography.Text>
                                {data.duration}
                                {' '}
                                {data.duration_type}
                            </Typography.Text>
                        </BlockDescription>

                        <BlockDescription name={t('Tour Style')}>
                            {/* <Space size={4} split=",">
                                {data.style.map((style) => (
                                    <Typography key={style.id}>
                                        {style.name}
                                    </Typography>
                                ))}
                            </Space> */}
                        </BlockDescription>

                        <BlockDescription name={t('Group size and Prices')}>
                            <Space direction="vertical" size={0}>
                                <Typography.Text>
                                    {data.adults}
                                    {' '}
                                    {t('Adults')}
                                    {' '}
                                    {`(${currencyIcon}${data.adult_price} / ${data.adult_price_type})`}
                                </Typography.Text>

                                {data.children !== '0' && (
                                    <Typography.Text>
                                        {data.children}
                                        {' '}
                                        {t('Child')}
                                        {' '}
                                        {`(${data.child_price} / ${data.child_price_type})`}
                                    </Typography.Text>
                                )}
                                {data.infants !== 0 && (
                                    <Typography.Text>
                                        {data.infants}
                                        {' '}
                                        {t('Infant')}
                                        {' '}
                                        {`(${data.infant_price} / ${data.infant_price_type})`}
                                    </Typography.Text>
                                )}
                            </Space>
                        </BlockDescription>

                        <BlockDescription name={t('Extra Services')}>
                            {data.extra_services.map((extra) => (
                                <Typography.Text key={extra.id}>
                                    {extra.name}
                                    {' '}
                                    {extra.price === '0.00' ? `${(t('inclusive'))}` : `(${extra.price} / ${extra.price_type})`}
                                </Typography.Text>
                            ))}
                        </BlockDescription>

                        <BlockDescription name={t('Description')}>
                            <Typography.Text>
                                <div dangerouslySetInnerHTML={{ __html: decodeHtml(data.description) }} />
                            </Typography.Text>
                        </BlockDescription>
                    </Space>
                </Col>
                <Col>
                    <Avatar src={data.images?.[0]?.path} />
                </Col>
            </Row>
        </DataContainer>
    );
};

export default GeneralDetails;
