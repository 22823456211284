import pagination from '@/helpers/pagination';

const { actions: baseActions, types, reducer } = pagination('tourTrips');

const actions = {
    requestFetchTourTrips: baseActions.request,
    fetchTourTripsSucceeded: baseActions.succeeded,
    fetchTourTripsFailed: baseActions.failed,

    fetchMoreTourTripsSucceeded: baseActions.fetchMoreSucceeded,
    fetchMoreTourTripsFailed: baseActions.fetchMoreFailed,
};

export { types, actions };

export default reducer;
