import BaseService from '@/services/BaseService';

// to use this service u have to initialize it first with trip id
class TripGroupService extends BaseService {
    id = null; // tour id or group id

    constructor(id) {
        super();
        this.id = id;
    }

    getAll = (filters) => this.post(`/agency/searchtourgroups/${this.id}`, filters);

    getOne = () => this.get(`/agency/viewtourgroup/${this.id}`);

    create = (data) => this.post(
        `/agency/newtourgroup/${this.id}`,
        data,
    );

    updateOne = (data) => this.post(
        `/agency/edittourgroup/${this.id}`,
        data,
    );

    deleteOne = () => this.delete(`/agency/deletetourgroup/${this.id}`);
}

export default TripGroupService;
