import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import SalesGraph from '@/components/SalesGraph';
import { actions } from '@/redux/reports/inhouse/reducer';
import { dateFormat } from '@/helpers/dateFormat';
import { useCurrency } from '@/hooks/useCurrency';

import OrderCards from './OrderCards';
import Filters from '../Filters';
import InvoiceTable from './InvoiceTable';

const initialFilters = {};

const InHouseStats = () => {
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const orders = useSelector((state) => state.reports.inhouse.orders);
    const sales = useSelector((state) => state.reports.inhouse.salesOverview);
    const invoices = useSelector((state) => state.reports.inhouse.invoices);
    const totalInvoices = useSelector((state) => state.reports.inhouse.totalInvoices);

    const [filters, baseSetFilters] = useState(initialFilters);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const setFilters = useCallback((name, value) => {
        baseSetFilters((prev) => ({ ...prev, [name]: value }));
    }, [baseSetFilters]);

    useEffect(() => {
        const data = {
            country_id: filters.country_id?.value,
            service_id: filters.service_id,
            start_date: filters.date?.[0].format(dateFormat),
            end_date: filters.date?.[1].format(dateFormat),
            page: currentPage,
            perPage: itemsPerPage,
        };
        dispatch(actions.requestGetInHouseReports(data));
    }, [dispatch, filters, currentPage, itemsPerPage]);

    return (
        <Row gutter={[30, 30]} className="in-house-stats">
            <Col xs={24}>
                <Filters
                    type="in-house"
                    setFilters={setFilters}
                    currency={currencyIcon}
                />
            </Col>
            <Col xs={24}>
                <OrderCards orders={orders} currency={currencyIcon} />
            </Col>
            <Col xs={24} md={10}>
                <SalesGraph data={sales} currency={currencyIcon} />
            </Col>
            <Col xs={24} md={14}>
                <InvoiceTable
                    data={invoices}
                    totalItems={totalInvoices}
                    page={currentPage}
                    setPage={setCurrentPage}
                    perPage={itemsPerPage}
                    setPerPage={setItemsPerPage}
                    currency={currencyIcon}
                />
            </Col>
        </Row>
    );
};

export default InHouseStats;
