import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Typography,
    Space,
    Row,
    Col,
    Input,
    Switch,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import { SalaryIcon } from '@/assets/images/icons/screens';

const Salary = ({
    roleSectionOpen,
    details,
    currency,
    validations,
}) => {
    const { t } = useTranslation();

    const [checked, setChecked] = useState(true);

    useEffect(() => {
        if (details && details.period === 'Monthly') {
            setChecked(false);
        } else {
            setChecked(true);
        }
    }, [details]);

    return (
        <Row gutter={[30, 0]} justify="space-between">
            <Col xs={24} className="details-title">
                <Space size={11}>
                    <SalaryIcon />
                    <Typography.Text>
                        {t('Salary Information')}
                    </Typography.Text>
                </Space>
            </Col>

            <Col lg={16} xs={24}>
                <MUIFormItem
                    label={t('Salary amount')}
                    name="salary_amount"
                    rules={validations.salary}
                >
                    <Input
                        disabled={roleSectionOpen}
                        suffix={currency}
                    />
                </MUIFormItem>
            </Col>

            <Col lg={8} xs={24}>
                <Row gutter={20} justify="end">
                    <Col>
                        <Typography.Text>
                            {t('Monthly')}
                        </Typography.Text>
                    </Col>

                    <Col>
                        <Form.Item name="salary_period">
                            <Switch
                                disabled={roleSectionOpen}
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Typography.Text>
                            {t('Yearly')}
                        </Typography.Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Salary;
