import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    customer: null,
    loading: false,
    error: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.customers.view,
    model,
);

export default model;
