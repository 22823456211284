import {
    Card,
    Row,
    Col,
    Input,
    DatePicker,
    Select,
} from 'antd';
import { useTranslation } from 'react-i18next';

import MUIFormItem from '@/components/MUIFormItem';
import CountrySelect from '@/components/CountrySelect';
import GenderSelect from '@/components/GenderSelect';
import { CalendarIcon } from '@/assets/images/icons/screens';

const BirthAndGender = ({
    index,
    name,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Col lg={6}>
                <MUIFormItem
                    name={[name, index, 'birthdate']}
                    label={t('Birthdate')}
                >
                    <DatePicker
                        placeholder=""
                        suffixIcon={<CalendarIcon />}
                    />
                </MUIFormItem>
            </Col>

            <Col lg={6}>
                <MUIFormItem
                    name={[name, index, 'gender']}
                    label={t('Gender')}
                >
                    <GenderSelect placeholder={false} />
                </MUIFormItem>
            </Col>
        </>
    );
};

const PersonCard = ({
    index,
    title,
    name,
    usedFor,
}) => {
    const { t } = useTranslation();

    return (
        <Card
            className="person-card"
            title={`${title} ${index + 1}`}
        >
            <Row gutter={20}>
                <Col lg={12}>
                    <MUIFormItem
                        name={[name, index, 'firstName']}
                        label={t('First Name')}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col lg={12}>
                    <MUIFormItem
                        name={[name, index, 'lastName']}
                        label={t('Last Name')}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col lg={12}>
                    <MUIFormItem
                        name={[name, index, 'nationality']}
                        label={t('Nationality')}
                    >
                        <CountrySelect />
                    </MUIFormItem>
                </Col>

                {usedFor === 'flights' ? (
                    <Col lg={12}>
                        <MUIFormItem
                            name={[name, index, 'document']}
                            label={t('Document Type')}
                        >
                            <Select>
                                <Select.Option value={1}>1</Select.Option>
                            </Select>
                        </MUIFormItem>
                    </Col>
                ) : (
                    <BirthAndGender index={index} name={name} />
                )}
                {usedFor === 'flights' && (
                    <>
                        <Col lg={12}>
                            <MUIFormItem
                                name={[name, index, 'id_number']}
                                label={t('Document ID')}
                            >
                                <Input />
                            </MUIFormItem>
                        </Col>

                        <BirthAndGender index={index} name={name} />
                    </>
                )}
                {usedFor === 'flights' && index === 0 && name === 'adults' && (
                    <>
                        <Col lg={12}>
                            <MUIFormItem
                                name={[name, index, 'phone']}
                                label={t('Phone No. (optional)')}
                            >
                                <Input />
                            </MUIFormItem>
                        </Col>
                        <Col lg={12}>
                            <MUIFormItem
                                name={[name, index, 'email']}
                                label={t('Email. (optional)')}
                            >
                                <Input />
                            </MUIFormItem>
                        </Col>
                    </>
                )}
            </Row>
        </Card>
    );
};

export default PersonCard;
