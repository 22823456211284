import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
    Typography,
    Divider,
    Checkbox,
    Button,
} from 'antd';
import BlockDescription from '@/components/Information/WithImage/Block';
import { actions } from '@/redux/invoice/view/reducer';
import {
    BankIcon,
    CashIcon,
    CreditCardIcon,
    FullPaymentIcon,
    PartialPaymentIcon,
} from '@/assets/images/icons/screens';

import Label from './Label';

const PayInvoice = ({ invoiceId, invoiceCharge }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const paymentOptions = useMemo(() => ([
        {
            label:
    <Label
        icon={<CreditCardIcon />}
        text={[t('Credit Card')]}
    />,
            value: 1,
        },
        {
            label:
    <Label
        icon={<BankIcon />}
        text={[t('Bank')]}
    />,
            value: 2,
        },
        {
            label:
    <Label
        icon={<CashIcon />}
        text={[t('Cash')]}
    />,
            value: 3,
        },
    ]), [t]);

    const [partialQuantityChecked, setPartialQuantityChecked] = useState(false);
    const [fullQuantityChecked, setFullQuantityChecked] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [amount, setAmount] = useState('');

    useEffect(() => {
        if (paymentMethod && (fullQuantityChecked || amount.length > 0)) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [amount, paymentMethod, fullQuantityChecked]);

    useEffect(() => {
        if (!partialQuantityChecked) {
            form.setFieldsValue({
                amount: null,
            });
            setAmount('');
        }
    }, [partialQuantityChecked, form]);

    const onFinish = useCallback((values) => {
        const data = {
            amount: values.amount ? values.amount : invoiceCharge.total,
            payment_method: values.paymentMethod,
        };
        dispatch(actions.requestSubmitPayInvoice(invoiceId, data));
    }, [dispatch, invoiceCharge.total, invoiceId]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Row
                gutter={[0, 0]}
                className="payment-block"
            >
                <Col lg={24}>
                    <Typography.Text className="large-text">
                        {t('Payment Transaction')}
                    </Typography.Text>
                    <Typography.Text>
                        {t('Choose the amount you want to pay')}
                    </Typography.Text>
                </Col>
                <Col>
                    <Row gutter={[0, 24]}>
                        <Col xs={24}>
                            <Form.Item name="fullQuantity">
                                <Radio.Button
                                    checked={fullQuantityChecked}
                                    className="quantity"
                                    onClick={() => {
                                        setFullQuantityChecked(!fullQuantityChecked);
                                        setPartialQuantityChecked(false);
                                    }}
                                >
                                    <BlockDescription
                                        icon={<FullPaymentIcon />}
                                        label={`${t('Remaining amount')} - ${invoiceCharge.total}`}
                                        content={t('Pay full amount')}
                                    />
                                </Radio.Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name="partialQuantity">
                                <Radio.Button
                                    className="quantity"
                                    checked={partialQuantityChecked}
                                    onClick={() => {
                                        setPartialQuantityChecked(!partialQuantityChecked);
                                        setFullQuantityChecked(false);
                                    }}
                                >
                                    <BlockDescription
                                        icon={<PartialPaymentIcon />}
                                        label={`${t('Partial amount - from')} ${1} - ${invoiceCharge.total}`}
                                        content={t('Pay a partial amount')}
                                    />
                                </Radio.Button>
                            </Form.Item>
                        </Col>
                        {partialQuantityChecked && (
                            <Col sm={24}>
                                <Form.Item name="amount">
                                    <Input
                                        placeholder={t('Enter an amount')}
                                        suffix="0.00"
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Divider />
                <Col>
                    <Typography.Text>
                        {t('Select the method of payment')}
                    </Typography.Text>
                    <Form.Item name="paymentMethod">
                        <Radio.Group
                            options={paymentOptions}
                            size="middle"
                            optionType="button"
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                    </Form.Item>
                </Col>

                <Col>
                    <Form.Item name="termsAndCondition" valuePropName="checked">
                        <Checkbox>
                            {t('Accept Terms and Condition')}
                        </Checkbox>
                    </Form.Item>
                </Col>

                <Col sm={24}>
                    <Button
                        type="primary"
                        className={clsx('pay', disableButton && 'disabled')}
                        block
                        onClick={() => form.submit()}
                        disabled={disableButton}
                    >
                        {t('Add payment').toUpperCase()}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default PayInvoice;
