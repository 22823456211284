import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('employees');

const actions = {
    requestFetchEmployees: baseActions.request,
    fetchEmployeesSucceeded: baseActions.succeeded,
    fetchEmployeesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
