const validateFormCompletionOrder = ({ form, fields, changedField }) => {
    // ant form calls onFieldsChange in submit also, which is rly not needed
    if (changedField.length > 1) {
        return;
    }

    fields.forEach((field) => {
        const previousFields = fields.slice(
            0,
            fields.indexOf(field),
        );
        if (
            !form.isFieldsTouched(previousFields, true)
            && form.isFieldTouched(field)
        ) {
            form.validateFields(previousFields);
            /*
            previousFields.forEach((previoursField) => {
                if (
                    !form.isFieldTouched(previoursField)
                    && !form.getFieldError(previoursField)?.length > 0
                ) {
                    form.setFields([
                        {
                            name: previoursField,
                            errors: [`${previoursField} can't be empty`]
                        },
                    ]);
                }
            });
            */
        }
    });
};

export default validateFormCompletionOrder;
