import { all, fork, takeEvery } from 'redux-saga/effects';

import pagination from '@/helpers/pagination';
import TripService from '@/services/TourServices/TripService';
import { types } from './reducer';

function* watchFetchTrips() {
    yield takeEvery(
        types.REQUEST_FETCH_TOUR_TRIPS,
        pagination('tourTrips').saga(TripService),
    );
}

function* tripSaga() {
    yield all([fork(watchFetchTrips)]);
}

export default tripSaga;
