import { useTranslation } from 'react-i18next';
import {
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import {
    Row,
    Col,
    Typography,
    Upload,
    Image,
    Button,
} from 'antd';
import { DeleteIcon } from '@/assets/images/icons/screens';
import { allowedImageMIMETypes } from '@/constants/upload';
import ActionIcon from '@/components/ActionIcon';
import {
    generateOnProgressHandler,
    generateOnSuccessHandler,
    generateOnRemoveHandler,
    generateOnErrorHandler,
    generateCustomRequestHandler,
} from '../hooks';

const noop = () => {};

const CoverUpload = ({
    image,
    allowedMIMETypes = allowedImageMIMETypes,
    onChange = noop,
    value,
    ...props
}) => {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [deleteImage, setDeleteImage] = useState(false);

    useEffect(() => {
        setFileList(value);
    }, []);

    const isFileAllowedForUpload = useCallback(
        (file) => allowedMIMETypes.includes(file.type),
        [allowedMIMETypes],
    );

    const onStartHandler = useCallback((file) => {
        if (isFileAllowedForUpload(file)) {
            file.status = 'uploading'; // eslint-disable-line no-param-reassign
            file.percent = 0; // eslint-disable-line no-param-reassign
            file.thumbUrl = URL.createObjectURL(file); // eslint-disable-line no-param-reassign
            setFileList([file]);
        }
    }, [isFileAllowedForUpload, setFileList]);

    const onProgressHandler = generateOnProgressHandler({ setFileList });
    const onSuccessHandler = generateOnSuccessHandler({ setFileList });
    const onRemoveHandler = generateOnRemoveHandler({ setFileList });
    const onErrorHandler = generateOnErrorHandler({ setFileList });
    const customRequestHandler = generateCustomRequestHandler({
        canUploadFile: true,
        isFileAllowedForUpload,
    });

    const stringAllowedMIMETypes = useMemo(
        () => allowedMIMETypes.join(', '),
        [allowedMIMETypes],
    );

    useEffect(() => {
        if (value !== fileList) {
            onChange(fileList);
        }
    }, [value, fileList, onChange]);

    useEffect(() => {
        if (deleteImage) {
            setFileList([]);
            setDeleteImage(false);
        }
    }, [deleteImage]);

    const handleDeleteImage = useCallback(() => {
        setDeleteImage(true);
    }, []);

    return (
        <div className="cover-upload-wrapper">
            <Upload
                className="cover-upload"
                {...props}
                value={value}
                fileList={fileList}
                maxCount={1}
                accept={stringAllowedMIMETypes}
                customRequest={customRequestHandler}
                onStart={onStartHandler}
                onProgress={onProgressHandler}
                onSuccess={onSuccessHandler}
                onRemove={onRemoveHandler}
                onError={onErrorHandler}
                itemRender={() => null}
            >
                {(fileList && fileList.length === 1 && fileList[0].thumbUrl !== undefined) ? (
                    <Image
                        src={fileList[0].thumbUrl}
                        preview={false}
                        alt="cover"
                    />
                ) : (
                    <Row className="cover-upload-content">
                        <Col span={24}>
                            {image}
                        </Col>
                        <Col span={24}>
                            <Typography.Text>
                                {t('Drag & Drop Photo Here')}
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Typography.Text>
                                {t('or')}
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Button className="button-underline" type="link">
                                {t('Browse').toUpperCase()}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Upload>
            {
                fileList && fileList.length === 1 && fileList[0].thumbUrl !== null
                && (
                    <div className="delete-img-icon">
                        <ActionIcon onClick={handleDeleteImage}>
                            <DeleteIcon />
                        </ActionIcon>
                    </div>
                )
            }
        </div>
    );
};

export default CoverUpload;
