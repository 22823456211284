import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get sales overview
    requestGetSalesOverview: [null],
    getSalesOverviewSucceeded: [
        'salesOverViewList',
        'salesTodayList',
        'totalValues',
    ],
    getSalesOverviewFailed: ['error'],

    // get sales by location
    requestGetSalesByLocation: [null],
    getSalesByLocationSucceeded: ['data'],
    getSalesByLocationFailed: ['error'],

    // get orders
    requestGetOrders: [null],
    getOrdersSucceeded: ['data'],
    getOrdersFailed: ['error'],

    // get b2b services
    requestGetB2bServices: [null],
    getB2bServicesSucceeded: ['data'],
    getB2bServicesFailed: ['error'],

});

export default createReducer(initialState, {

    // sales overview
    [types.REQUEST_GET_SALES_OVERVIEW]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_SALES_OVERVIEW_SUCCEEDED]: (
        state,
        {
            salesOverViewList,
            salesTodayList,
            totalValues,
        },
    ) => ({
        ...state,
        salesOverview: salesOverViewList,
        salesToday: salesTodayList,
        total: totalValues,
        loading: false,
    }),
    [types.GET_SALES_OVERVIEW_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // sales by location
    [types.REQUEST_GET_SALES_BY_LOCATION]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_SALES_BY_LOCATION_SUCCEEDED]: (state, { data }) => ({
        ...state,
        salesByLocation: data,
        loading: false,
    }),
    [types.GET_SALES_BY_LOCATION_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // orders
    [types.REQUEST_GET_ORDERS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_ORDERS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        orders: data,
        loading: false,
    }),
    [types.GET_ORDERS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // b2b services
    [types.REQUEST_GET_B2B_SERVICES]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_B2B_SERVICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        b2bServices: data,
        loading: false,
    }),
    [types.GET_B2B_SERVICES_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };
