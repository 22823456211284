import { all, fork } from 'redux-saga/effects';
import paginationSaga from './pagination/saga';

function* billingSaga() {
    yield all([
        fork(paginationSaga),
    ]);
}

export default billingSaga;
