import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Rate,
    Space,
    Typography,
    Avatar,
} from 'antd';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import DataContainer from '@/components/DataContainer';

const Accommodation = ({ data }) => {
    const { t } = useTranslation();

    return (
        data && (
            <DataContainer>
                <Row className="right-col_cards">
                    <Col span={24}>
                        <Space align="start">
                            <div className="rooms-created-card_image">
                                <Avatar size={55} src={data.image} />
                            </div>
                            <div>
                                <Typography.Text>{data.name}</Typography.Text>
                                {' '}
                                <Rate disabled value={data.stars} />
                                <InlineDescription
                                    label={t('Room Types')}
                                    content={t(data.room_types)}
                                />

                                <InlineDescription
                                    label={t('Number of Rooms Available')}
                                    content={data.available_rooms}
                                />
                            </div>
                        </Space>
                    </Col>
                </Row>
            </DataContainer>
        )
    );
};

export default Accommodation;
