import { Skeleton } from 'antd';

const Graphs = () => {
    return (
        <Skeleton.Button
            className="loading-graphs"
            active
            block
        />
    );
};

export default Graphs;
