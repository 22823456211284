import { useTranslation } from 'react-i18next';
import {
    Typography,
    Space,
    Row,
    Col,
    Input,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import { InfoCircleIcon } from '@/assets/images/icons/screens';

const AccountDetails = ({ isEdit, validations }) => {
    const { t } = useTranslation();

    return (
        <Row gutter={25}>
            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Email')}
                    name="email"
                    rules={!isEdit && validations.email}
                >
                    <Input disabled={isEdit} />
                </MUIFormItem>
            </Col>

            <Col xs={24} sm={12}>
                <MUIFormItem
                    label={t('Password')}
                    name="password"
                    className="account-password"
                    rules={!isEdit && validations.password}
                >
                    <Input.Password disabled={isEdit} />
                </MUIFormItem>
            </Col>

            <Col xs={24} className="email-description">
                <Space size={6}>
                    <InfoCircleIcon />
                    <Typography.Text>
                        {`${t('This email will be used for logging in')}.`}
                    </Typography.Text>
                </Space>
            </Col>
        </Row>
    );
};

export default AccountDetails;
