import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    Dropdown,
    // Menu,
    Button,
    Space,
} from 'antd';
import {
    HistoryLogIcon,
    LogOutIcon,
    QuestionCircleIcon,
    SecurityIcon,
    UserIcon,
    UserProfileIcon,
} from '@/assets/images/icons/screens';
import { actions } from '@/redux/authentication/reducer';
import ActionIcon from '../ActionIcon';

const UserMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { push } = useHistory();

    const logout = () => {
        dispatch(actions.requestLogout());
    };

    const items = [
        {
            label: (
                <Space direction="vertical">
                    <p>Username</p>
                    <p>Administrator</p>
                </Space>
            ),
            icon: <div><UserIcon /></div>,
            key: 'USER',
        },
        {
            label: t('Profile Management'),
            icon: <div><UserProfileIcon /></div>,
            key: 'PROFILE',
        },
        {
            label: t('History Log'),
            icon: <div><HistoryLogIcon /></div>,
            key: 'HISTORY',
        },
        {
            label: (
                <ActionIcon onClick={() => push('/app/change-password')}>
                    {t('Security')}
                </ActionIcon>
            ),
            icon: (
                <div>
                    <ActionIcon onClick={() => push('/app/change-password')}>
                        <SecurityIcon />
                    </ActionIcon>
                </div>
            ),
            key: 'SECURITY',
        },
        {
            label: t('Help Desk'),
            icon: <div><QuestionCircleIcon /></div>,
            key: 'HELP',
        },
        {
            label: (
                <Button onClick={logout}>
                    <div><LogOutIcon /></div>
                    {' '}
                    {t('Log out')}
                </Button>
            ),
            key: 'LOGOUT',
        },
    ];

    return (
        items
    );
};

const TopbarUserMenu = () => (
    <Dropdown
        trigger={['click']}
        overlayClassName="user-dropdown-m1"
        menu={{
            items: UserMenu(),
        }}
    >
        <UserIcon />
    </Dropdown>
);

export default TopbarUserMenu;
