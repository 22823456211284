const model = {
    searchWidget: '',
    resultWidget: '',
    customWidget: null,
    services: [],
    domainWhitelist: [],
    loading: false,
    error: null,
    messageSuccess: '',
    messageError: null,
};

export default model;
