import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Col,
    Row,
    Typography,
    Divider,
    Space,
} from 'antd';
import { DeleteIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/invoice/createcustom/reducer';
import ActionIcon from '@/components/ActionIcon';
import SectionLayout from '../../InvoicePreview/SectionLayout';

const Services = ({ createMode, services, currency }) => {
    const dispatch = useDispatch();

    const step = useSelector((state) => state.invoice.createcustom.step);

    const formattedServices = services?.map((service, index) => ({
        ...service,
        index: index + 1,
    }));

    // delete service
    const deleteService = useCallback((key) => {
        dispatch(actions.requestDeleteService(key));
    }, [dispatch]);

    return (
        services !== undefined && formattedServices?.map((service) => (
            <div key={service.index}>
                <div className="invoice-divider"><Divider /></div>
                <SectionLayout
                    className="service-list"
                    icon={<div className="service-key">{service.index}</div>}
                    leftCol={3}
                    rightCol={21}
                    align="start"
                >
                    <Row justify="space-between" align="start">
                        <Col>
                            <Space size={30}>
                                <div>
                                    <div>
                                        <Typography.Title level={5}>
                                            {service.service_name}
                                        </Typography.Title>
                                    </div>
                                    <div className="description">
                                        {service.description}
                                    </div>
                                </div>
                                {createMode && step === 0 && (
                                    <ActionIcon onClick={() => deleteService(service.key)}>
                                        <DeleteIcon />
                                    </ActionIcon>
                                )}
                            </Space>
                        </Col>

                        <Col>
                            <Typography.Text className="total-price">
                                {currency.concat(service.price)}
                            </Typography.Text>
                        </Col>
                    </Row>
                </SectionLayout>
            </div>
        ))
    );
};

export default Services;
