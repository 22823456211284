import { useTranslation } from 'react-i18next';

export const TimeInMinHours = ({ value }) => {
    const { t } = useTranslation();

    if (value < 60) {
        return `${Math.floor(value)} ${t('minutes')}`;
    } else {
        const hours = Math.floor(value / 60);
        const minutes = value % 60;
        if (minutes === 0 && hours === 1) {
            return `${hours} ${t('hour')}`;
        } else if (minutes === 0) {
            return `${hours} ${t('hours')}`;
        } else {
            return `${hours} : ${minutes} ${t('minutes')}`;
        }
    }
};

export const TimeInDays = ({ value }) => {
    const { t } = useTranslation();
    return `${Math.floor(value)} ${(value === 1) ? t('day') : t('days')}`;
};
