import { Select, Form } from 'antd';
import { useMemo } from 'react';

const InputSelect = ({
    posibleValue,
    isDisabled,
    rule,
    name,
}) => {
    const { Option } = Select;
    const options = useMemo(() => [...Array(posibleValue)], [posibleValue]);
    return (
        <div className="phone-number-input">
            <Form.Item
                className="prefix-input"
                name={name}
                rules={rule}
                validateFirst
            >
                <Select disabled={isDisabled}>
                    {options.map((item, index) => (
                        <Option key={index.toString()} value={index + 1}>
                            {index + 1}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};
export default InputSelect;
