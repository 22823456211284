import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view partner details
    requestViewPartner: ['id'],
    viewPartnerSucceeded: ['data'],
    viewPartnerFailed: ['error'],
});

export default {
    [types.REQUEST_VIEW_PARTNER]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_PARTNER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            partnerDetails: data,
            loading: false,
        },
    }),
    [types.VIEW_PARTNER_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),
};
