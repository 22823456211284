import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    stars: {
        name: 'Stars',
        required: true,
    },
    name: {
        name: 'Name',
        required: true,
        min: 5,
        max: 150,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    address: {
        name: 'Address',
        required: true,
        min: 5,
        max: 150,
    },
    city: {
        name: 'City',
        required: true,
    },
    state: {
        name: 'State/Province',
        required: true,
    },
    zip_code: {
        name: 'Zip code',
        required: true,
    },
    country: {
        name: 'Country',
        required: true,
    },
    check_in_time: {
        name: 'Check in time',
        required: true,
    },
    check_out_time: {
        name: 'Check out time',
        required: true,
    },
    payment_methods: {
        name: 'Payment methods',
        required: true,
    },
    first_name: {
        name: 'First name',
        required: true,
    },
    last_name: {
        name: 'Last name',
        required: true,
    },
    position: {
        name: 'Position',
        required: true,
    },
    phone: {
        name: 'Phone',
        required: true,
    },
    email: {
        name: 'Email',
        required: true,
    },
    website: {
        name: 'Website',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
