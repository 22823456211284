import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('partnerList');

const actions = {
    requestFetchPartnerList: baseActions.request,
    fetchPartnerListSucceeded: baseActions.succeeded,
    fetchPartnerListFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
