import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Layout, Row, Col, Space,
} from 'antd';
import clsx from 'clsx';

import LanguageSelector from '@/components/LanguageSelector';
import Notifications from '@/components/TopbarNotifications';
import UserMenu from '@/components/TopbarUserMenu';
import { SettingsIcon } from '@/assets/images/icons/topbar';

import Toggle from './Toggle';
import SubMenu from './SubMenu';
import ActionIcon from '../ActionIcon';

const Topbar = ({
    currentRoute,
    isCollapsed,
    setIsCollapsed,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const hasSubMenu = currentRoute.subMenus.length !== 0;
    const ifDashboard = currentRoute.path === pathname;

    return (
        <div className={clsx('topbar-wrapper', hasSubMenu && !ifDashboard && 'with-border')}>
            <Layout.Header className="topbar">
                <Row justify="space-between">
                    <Col>
                        <Space size={30}>
                            <Toggle
                                isCollapsed={isCollapsed}
                                setIsCollapsed={setIsCollapsed}
                            />
                            {hasSubMenu && !ifDashboard && (
                                <SubMenu currentRoute={currentRoute} />
                            )}
                            {!hasSubMenu && (
                                <span className="m-page-title">{t(currentRoute.name)}</span>
                            )}
                            {hasSubMenu && ifDashboard && (
                                <span className="m-page-title">{t(currentRoute.name)}</span>
                            )}
                        </Space>
                    </Col>
                    <Col>
                        <Space className="general-menu" size={30}>
                            <LanguageSelector />
                            <Notifications />
                            <ActionIcon onClick={() => push('/app/settings/general')}>
                                <SettingsIcon />
                            </ActionIcon>
                            <UserMenu />
                        </Space>
                    </Col>
                </Row>
            </Layout.Header>
        </div>
    );
};

export default Topbar;
