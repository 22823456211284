import {
    useState,
    useCallback,
    useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Card,
    Input,
    Typography,
    Avatar,
    Checkbox,
    Button,
    Dropdown,
    Form,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import InlineDescription from '@/components/Information/WithoutImage/Inline';
import { actions } from '@/redux/tour/cruise/create/reducer';
import { selectors } from '@/redux/tour/cruise/create/model';
import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import { InfoCircleIcon, MoreOutlinedIcon } from '@/assets/images/icons/screens';

const ShipList = ({
    data,
    form,
    onSearch,
    isEdit,
    regStep,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const shipDetails = useSelector(selectors.shipDetails);
    const itenerary = useSelector(selectors.itenerary);

    const [selectedShip, setSelectedShip] = useState();

    const goToCreate = useCallback(() => {
        dispatch(actions.goToCruiseCreateShip());
        dispatch(actions.clearShipDetails());
    }, [dispatch]);

    const goToEdit = useCallback((id) => {
        dispatch(actions.requestViewShip(id));
        dispatch(actions.goToCruiseCreateShip());
    }, [dispatch]);

    const onFinish = useCallback(() => {
        if (
            (isEdit && regStep > 2)
            || (isEdit && itenerary.length !== 0)
            || (!isEdit && itenerary.length !== 0)
        ) {
            const step = 2;
            const completedStep = 2;
            dispatch(actions.requestSubmitCruiseShipDetails(
                { ship: selectedShip },
                step,
                completedStep,
            ));
        } else {
            const step = 2;
            const completedStep = 1;
            dispatch(actions.requestSubmitCruiseShipDetails(
                { ship: selectedShip },
                step,
                completedStep,
            ));
        }
    }, [selectedShip]);

    useEffect(() => {
        if (shipDetails?.length !== 0) {
            setSelectedShip(shipDetails?.id);
        }
    }, [shipDetails]);

    return (
        <>
            <Input.Search
                placeholder={t('Search for Ship')}
                onSearch={onSearch}
            />
            <div className="accommodation-text">
                <Typography.Text>
                    <InfoCircleIcon />
                    {' '}
                    {t('Select one of the Ships below for this Cruise Tour or')}
                </Typography.Text>

                <Button
                    className="text-add-button"
                    type="text"
                    onClick={goToCreate}
                >
                    {t('Add New Ship')}
                </Button>
            </div>
            <Form form={form} onFinish={onFinish}>
                {data.map((shipItem) => (
                    <div className="ship-card" key={shipItem.id}>
                        <Card>
                            <Card.Meta
                                avatar={(<Avatar src={shipItem.image} />)}
                                title={shipItem.name}
                                description={(
                                    <Row justify="space-between">
                                        <Col>
                                            <InlineDescription
                                                label={t('Cabin Types')}
                                                content={shipItem.cabin_types}
                                            />

                                            <InlineDescription
                                                label={t('Number of Cabins')}
                                                content={shipItem.cabins}
                                            />
                                        </Col>

                                        <Col className="selected-hotel">
                                            <Checkbox
                                                checked={selectedShip === shipItem.id}
                                                onChange={() => setSelectedShip(shipItem.id)}
                                            />
                                            <Dropdown
                                                trigger={['click']}
                                                placement="bottom"
                                                overlayClassName="edit-delete-dropdown"
                                                menu={{
                                                    items: SimpleDropdownMenu({
                                                        editText: t('Edit this ship'),
                                                        onEditClick: () => goToEdit(shipItem.id),
                                                        deleteText: t('Delete this ship'),
                                                        deleteAction: actions.requestDeleteShip(
                                                            shipItem.id,
                                                        ),
                                                        type: 'ship',
                                                    }),
                                                }}
                                            >
                                                <Button type="text">
                                                    <MoreOutlinedIcon />
                                                </Button>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                )}
                            />
                        </Card>
                    </div>
                ))}
            </Form>
        </>
    );
};

export default ShipList;
