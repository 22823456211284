import { createReducer } from 'reduxsauce';
import initialState from './model';

import bookingEngineReduxHandlers, {
    types as bookingEngineTypes,
    actions as bookingEngineActions,
} from './bookingEngine/reducer';

const types = {
    ...bookingEngineTypes,
};

const actions = {
    ...bookingEngineActions,
};

export { types, actions };

export default createReducer(initialState, {
    ...bookingEngineReduxHandlers,
});
