import { all } from 'redux-saga/effects';
import inHouseSaga from './inhouse/saga';
import b2bSaga from './b2b/saga';

function* reportSaga() {
    yield all([
        inHouseSaga(),
        b2bSaga(),
    ]);
}

export default reportSaga;
