import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import ReportService from '@/services/ReportService';
import { types, actions } from './reducer';

let total = 0;

function* getInHouseReports({ filters }) {
    try {
        const response = yield call(ReportService.getInHouseReports, filters);
        const {
            card: orderData,
            sale_overview: salesOverviewData,
            invoice: invoiceData,
        } = response.data;
        const {
            list: invoiceList,
            meta: metaObject,
        } = invoiceData;
        const totalInvoices = metaObject.total !== undefined ? metaObject.total : total;
        total = totalInvoices;
        yield put(actions.getInHouseReportsSucceeded(
            orderData,
            salesOverviewData,
            invoiceList,
            totalInvoices,
        ));
    } catch (error) {
        yield put(actions.getInHouseReportsFailed(extractError(error)));
    }
}

function* compareInHouseReports({ filters }) {
    try {
        const response = yield call(ReportService.compareInHouseReports, filters);
        const {
            total: totalData,
            cost: costData,
            earn: earnData,
            status: statusData,
            customer: customerData,
        } = response.data;
        yield put(actions.compareInHouseReportsSucceeded(
            totalData,
            costData,
            earnData,
            statusData,
            customerData,
        ));
    } catch (error) {
        yield put(actions.compareInHouseReportsFailed(extractError(error)));
    }
}

function* watchGetInHouseReports() {
    yield takeEvery(
        types.REQUEST_GET_IN_HOUSE_REPORTS,
        getInHouseReports,
    );
}

function* watchCompareInHouseReports() {
    yield takeEvery(
        types.REQUEST_COMPARE_IN_HOUSE_REPORTS,
        compareInHouseReports,
    );
}

function* inHouseSaga() {
    yield all([
        fork(watchGetInHouseReports),
        fork(watchCompareInHouseReports),
    ]);
}

export default inHouseSaga;
