import GoogleMap from '@/components/GoogleMap';

const CruiseMapItenerary = ({ data }) => {
    return (
        <GoogleMap
            itenerary={data}
            latitude={data[0]?.latitude}
            longitude={data[0]?.longitude}
        />
    );
};

export default CruiseMapItenerary;
