import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get agency details
    requestGetAgencyDetails: [null],
    getAgencyDetailsSucceeded: ['data'],
    getAgencyDetailsFailed: ['errors'],

    // submit agency details
    requestSubmitAgencyDetails: ['data'],
    submitAgencyDetailsSucceeded: ['message'],
    submitAgencyDetailsFailed: ['errors'],

    // clear message success
    clearMessageSuccess: null,

});

export default createReducer(initialState, {
    // get agency details
    [types.REQUEST_GET_AGENCY_DETAILS]: (state) => ({
        ...state,
        loading: true,
        errors: null,
    }),
    [types.GET_AGENCY_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        agencyDetails: data,
        loading: false,
    }),
    [types.GET_AGENCY_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        loading: false,
        errors,
    }),

    // submit agency details
    [types.REQUEST_SUBMIT_AGENCY_DETAILS]: (state) => ({
        ...state,
        submitting: true,
        errors: null,
    }),
    [types.SUBMIT_AGENCY_DETAILS_SUCCEEDED]: (state, { message }) => ({
        ...state,
        submitting: false,
        success: message,
    }),
    [types.SUBMIT_AGENCY_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        submitting: false,
        errors,
    }),

    // clear message success
    [types.CLEAR_MESSAGE_SUCCESS]: (state) => ({
        ...state,
        success: '',
    }),
});

export { types, actions };
