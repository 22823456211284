import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get in house reports
    requestGetInHouseReports: ['filters'],
    getInHouseReportsSucceeded: [
        'orderData',
        'salesOverviewData',
        'invoiceList',
        'totalInvoices',
    ],
    getInHouseReportsFailed: ['error'],

    // compare reports
    requestCompareInHouseReports: ['filters'],
    compareInHouseReportsSucceeded: [
        'total',
        'cost',
        'earn',
        'status',
        'customer',
    ],
    compareInHouseReportsFailed: ['error'],

});

export default createReducer(initialState, {
    // get in house reports
    [types.REQUEST_GET_IN_HOUSE_REPORTS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_IN_HOUSE_REPORTS_SUCCEEDED]: (
        state,

        {
            orderData,
            salesOverviewData,
            invoiceList,
            totalInvoices,
        },
    ) => ({
        ...state,
        orders: orderData,
        salesOverview: salesOverviewData,
        invoices: invoiceList,
        totalInvoices,
        loading: false,
    }),
    [types.GET_IN_HOUSE_REPORTS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // compare reports
    [types.REQUEST_COMPARE_IN_HOUSE_REPORTS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.COMPARE_IN_HOUSE_REPORTS_SUCCEEDED]: (
        state,
        {
            total,
            cost,
            earn,
            status,
            customer,
        },
    ) => ({
        ...state,
        total,
        cost,
        earn,
        status,
        customer,
        loading: false,
    }),
    [types.COMPARE_IN_HOUSE_REPORTS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };
