import { all, fork, takeEvery } from 'redux-saga/effects';

import pagination from '@/helpers/pagination';
import AccommodationService from '@/services/AccommodationService';
import { types } from './reducer';

function* watchFetchAccommodationList() {
    yield takeEvery(
        types.REQUEST_FETCH_ACCOMMODATION_LIST,
        pagination('accommodationList').saga(AccommodationService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchAccommodationList),
    ]);
}

export default paginationSaga;
