import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Select,
    Input,
    Space,
    Button,
    Form,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import { DeleteIcon } from '@/assets/images/icons/screens';

const PriceItem = ({
    field,
    remove,
    form,
    formListName,
    hasEndingNo,
}) => {
    const { t } = useTranslation();

    const [ruleType, setRuleType] = useState(null);

    const [displayRange, setDisplayRange] = useState(true);

    useEffect(() => {
        if (hasEndingNo) {
            setDisplayRange(true);
        } else {
            setDisplayRange(false);
        }
    }, [hasEndingNo]);

    useEffect(() => {
        if (ruleType === 2) {
            setDisplayRange(true);
        }
        if (ruleType === 1 || ruleType === 3) {
            setDisplayRange(false);
        }
    }, [ruleType]);

    const resetFields = (index) => {
        const fields = form.getFieldsValue();
        Object.assign(
            fields.prices[index],
            {
                starting_no: undefined,
                ending_no: undefined,
                price: undefined,
            },
        );
        form.setFieldsValue(
            {
                prices: fields.prices,
            },
        );
    };

    return (
        <Row gutter={30}>
            <Col xxl={8} sm={12} xs={24}>
                <MUIFormItem
                    label={t('Rule Type')}
                    name={[field.name, 'rule_type']}
                    formListName={formListName}
                >
                    <Select onSelect={(value) => {
                        setRuleType(value);
                        resetFields(field.name);
                    }}
                    >
                        <Select.Option value={3}>{t('Less than')}</Select.Option>
                        <Select.Option value={2}>{t('Range')}</Select.Option>
                        <Select.Option value={1}>{t('Greater than')}</Select.Option>
                    </Select>
                </MUIFormItem>
            </Col>
            <Col xxl={7} sm={12} xs={24}>
                {displayRange ? (
                    <Space size={30}>
                        <MUIFormItem
                            label={t('From')}
                            name={[field.name, 'starting_no']}
                            formListName={formListName}
                        >
                            <Input />
                        </MUIFormItem>
                        <MUIFormItem
                            label={t('To')}
                            name={[field.name, 'ending_no']}
                            formListName={formListName}
                        >
                            <Input />
                        </MUIFormItem>
                    </Space>
                ) : (
                    <MUIFormItem
                        label={t('From')}
                        name={[field.name, 'starting_no']}
                        formListName={formListName}
                    >
                        <Input />
                    </MUIFormItem>
                )}
            </Col>
            <Col xxl={6} sm={18} xs={16}>
                <MUIFormItem
                    label={t('Price')}
                    name={[field.name, 'price']}
                    formListName={formListName}
                >
                    <Input />
                </MUIFormItem>
            </Col>
            <Col xxl={3} sm={6} xs={8}>
                <Form.Item>
                    <Button
                        className="button btn-grey"
                        onClick={() => remove(field.name)}
                    >
                        <DeleteIcon />
                    </Button>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default PriceItem;
