import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '@/helpers/api';
import TripService from '@/services/TourServices/TripService';
import { types, actions } from './reducer';

function* deleteTourTrip({ id }) {
    try {
        yield call(TripService.deleteOne, id);
        yield put(actions.deleteTourTripSucceeded(id, 'trip deleted successfully'));
    } catch (error) {
        yield put(actions.deleteTourTripFailed(extractError(error)));
    }
}

function* watchDeleteTourTrip() {
    yield takeEvery(
        types.REQUEST_DELETE_TOUR_TRIP,
        deleteTourTrip,
    );
}

function* tripDeleteSaga() {
    yield all([
        fork(watchDeleteTourTrip),
    ]);
}

export default tripDeleteSaga;
