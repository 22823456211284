import { createSelector } from 'reselect';
import { firstToUpper, snakeToCamel } from './string';

const reduceParams = (params, values) => params.reduce(
    (payload, param, index) => ({
        ...payload,
        [param]: values[index],
    }),
    {},
);

export const createAction = (name, params) => (...values) => ({
    type: name,
    payload: Array.isArray(params) ? reduceParams(params, values) : null,
});

export const createEntityActionTypes = (entity, action) => ({
    request: `request${firstToUpper(action)}${entity}`,
    succeeded: `${action + entity}Succeeded`,
    failed: `${action + entity}Failed`,

    fetchMore: `fetchMore${entity}`,
    fetchMoreSucceeded: `fetchMore${entity}Succeeded`,
    fetchMoreFailed: `fetchMore${entity}Failed`,
});

export const createEntityCrudActionTypes = (entity) => {
    const formattedEntityName = firstToUpper(entity);
    return ['fetch', 'create', 'update', 'delete'].reduce((total, method) => ({
        ...total,
        [method]: createEntityActionTypes(formattedEntityName, method),
    }), {});
};

export const destructureMappedActions = (
    typeGroup,
    actionGroup,
) => Object
    .keys(typeGroup)
    .reduce((total, type) => ({
        ...total,
        [snakeToCamel(typeGroup[type])]: actionGroup[type],
    }), {});

export const destructureCRUDMappedActions = (
    mappedTypes,
    mappedActions,
) => Object
    .keys(mappedTypes)
    .map((typeGroup) => destructureMappedActions(
        mappedTypes[typeGroup],
        mappedActions[typeGroup],
    ))
    .reduce((total, group) => ({ ...total, ...group }), {});

export const createSelectorsFromModel = (baseSelector, model) => Object
    .keys(model)
    .reduce((selectors, key) => ({
        ...selectors,
        [key]: createSelector(baseSelector, (base) => base[key]),
    }), {});
