import {
    Col,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '@/assets/images/icons/screens';
import SectionLayout from '../../InvoicePreview/SectionLayout';

const DateOfIssue = ({ services }) => {
    const { t } = useTranslation();

    return (
        <SectionLayout
            className="date-of-issue"
            icon={<CalendarIcon />}
            leftCol={3}
            rightCol={21}
            align="start"
        >
            <Row justify="space-between" align="start">
                <Col>
                    <Typography.Title level={5}>
                        {t('Date of Issue')}
                    </Typography.Title>
                </Col>

                <Col>
                    <Typography.Text className="date">
                        {services?.[0]?.date}
                    </Typography.Text>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default DateOfIssue;
