import BaseService from '@/services/BaseService';

class TripService extends BaseService {
    getAll = (filters) => this.post('/agency/tourslist', filters || { });

    getOne = (id) => this.get(`/agency/viewtour/${id}`);

    deleteOne = (id) => this.delete(`/agency/deletetour/${id}`);

    // general details
    addGeneralInfo = (data) => this.post(
        '/agency/addtourgeneralinfo',
        data,
    );

    editGeneralInfo = (id, data) => this.post(
        `/agency/edittourgeneralinfo/${id}`,
        data,
    );

    viewGeneralInfo = (id) => this.get(
        `/agency/viewtourgeneralinfo/${id}`,
    );

    // itenerary
    addItenerary = (id, data) => this.post(
        `/agency/addtouritinerary/${id}`,
        data,
    );

    editItenerary = (id, data) => this.post(
        `/agency/edittouritinerary/${id}`,
        data,
    );

    viewItenerary = (id) => this.get(
        `/agency/viewtouritinerary/${id}`,
    );

    // inclusions, exclusions
    addInclusionsExclusions = (id, data) => this.post(
        `/agency/addtourinclusions/${id}`,
        data,
    );

    editInclusionsExclusions = (id, data) => this.post(
        `/agency/edittourinclusions/${id}`,
        data,
    );

    viewInclusionsExclusions = (id) => this.get(
        `/agency/viewtourinclusions/${id}`,
    );

    // other services
    getTripTypes = (data) => this.post('/agency/gettourtypeslist/1', data || { });

    addNewTripType = (data) => this.post('/agency/addtourtype', data);

    getInclusionsExclusions = () => this.get('/agency/getinclusions/1');

    addNewInclusion = (data) => this.post('/agency/addnewinclusion', data);

    addNewExclusion = (data) => this.post('/agency/addnewexclusion', data);
}

export default new TripService();
