import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    reservationData: null, // data from trip, cruise, packages or accommodation
    invoiceDetails: null,
    invoiceCustomer: null, // step 0
    invoiceParticipants: [], // step 1
    invoiceExtras: null, // step 2

    customerList: [],
    extraServices: [],

    loading: false,
    errors: null,
    submitting: false,
    step: 0, // 0, 1, 2
    completedStep: null, // null, 0, 1, 2
};

export const selectors = createSelectorsFromModel(
    (state) => state.invoice.create,
    model,
);

export default model;
