import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
    Row,
    Col,
    Input,
    DatePicker,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import CustomTextArea from '@/components/TextArea';
import { CalendarIcon } from '@/assets/images/icons/screens';
import { useCurrency } from '@/hooks/useCurrency';

const PackageDetails = ({ validations }) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    const [date, setDate] = useState(null);

    return (
        <>
            <MUIFormItem
                label={t('Activity Name')}
                name="name"
                rules={validations.name}
            >
                <Input />
            </MUIFormItem>

            <Row
                className="activity-details"
                gutter={30}
            >
                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Default Price')}
                        name="price"
                        rules={validations.defaultPrice}
                    >
                        <Input
                            suffix={`${currencyIcon}0.00 / ${t('person')}`}
                        />
                    </MUIFormItem>
                </Col>

                <Col xl={12} xs={24}>
                    <MUIFormItem
                        label={t('Availability')}
                        name="availability"
                        rules={validations.availability}
                    >
                        <DatePicker.RangePicker
                            placeholder={[]}
                            suffixIcon={<CalendarIcon />}
                            popupClassName="filter-calendar"
                            separator={date !== null && '-'}
                            onChange={(value) => setDate(value)}
                        />
                    </MUIFormItem>
                </Col>
            </Row>

            <CustomTextArea
                name="description"
                placeholder={t('Activity Description')}
                rules={validations.description}
            />
        </>
    );
};

export default PackageDetails;
