import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    Row,
    Col,
    Space,
    Button,
    DatePicker,
    Modal,
    Typography,
    Divider,
} from 'antd';
import {
    CompareIcon,
    TotalIncomingIcon,
    ArrowBottomIcon,
    ArrowTopIcon,
    ClockIcon,
    CloseIcon,
    PeopleIcon,
    TickIcon,
    ChartIcon,
    CalendarIcon,
    TotalSuppliersIcon,
    RevenueFromSuppliersIcon,
    TotalVendorsIcon,
    SalesByVendorsIcon,
} from '@/assets/images/icons/screens';
import { MarketingIcon, B2BIcon } from '@/assets/images/icons/sidebar';
import TableRow from './TableRow';

const CompareReports = ({
    type,
    modalVisible,
    setModalVisible,
    startMonth,
    setStartMonth,
    endMonth,
    setEndMonth,
    firstMonth,
    secondMonth,
    total,
    cost,
    earn,
    status,
    customer,
    currency,
    inHouseLoading,
    b2bLoading,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    // return revenue
    const returnRevenue = (value) => {
        const formattedValue = parseFloat(value?.replace('%', ''));
        if (formattedValue !== 0) {
            if (formattedValue > 0) {
                return `${value}% ${t('in win')}.`;
            } else {
                return `${value}% ${t('in los')}.`;
            }
        }
        return value;
    };

    return (
        <Modal
            open={modalVisible}
            maskClosable={false}
            onCancel={() => setModalVisible(false)}
            footer={null}
            className="compare-report"
            width={700}
        >
            <div>
                <Row
                    gutter={15}
                    align="middle"
                    className="modal-filters"
                >
                    <Col span={10}>
                        <Typography.Text>
                            {t('Select 2 different periods to compare the historic data between each other')}
                        </Typography.Text>
                    </Col>
                    <Col span={6}>
                        <DatePicker
                            value={moment(startMonth)}
                            picker="month"
                            onChange={(date) => setStartMonth(date.format('YYYY-MM'))}
                            suffixIcon={<CalendarIcon />}
                        />
                    </Col>
                    <Col span={2} className="icon">
                        <CompareIcon />
                    </Col>
                    <Col span={6}>
                        <DatePicker
                            value={moment(endMonth)}
                            picker="month"
                            onChange={(date) => setEndMonth(date.format('YYYY-MM'))}
                            suffixIcon={<CalendarIcon />}
                        />
                    </Col>
                </Row>
                {inHouseLoading || b2bLoading
                    ? 'Loading...'
                    : (
                        <>
                            <Row className="data">
                                <Col span={24}>
                                    {type === 'in-house' ? (
                                        <>
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<TotalIncomingIcon />}
                                                iconClassName="svg-1"
                                                label={t('Incoming')}
                                                type="in-house"
                                                firstColValue={`${currency}${total?.startMonthTotal}`}
                                                secondColValue={`${currency}${total?.endMonthTotal}`}
                                                percentage={total?.percentage}
                                            />
                                            <TableRow
                                                rowClassName="eaven-row"
                                                icon={<ArrowBottomIcon />}
                                                iconClassName="svg-2"
                                                label={t('Costs')}
                                                type="in-house"
                                                firstColValue={`${currency}${cost?.startMonthCost}`}
                                                secondColValue={`${currency}${cost?.endMonthCost}`}
                                                percentage={cost?.percentage}
                                            />
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<ArrowTopIcon />}
                                                iconClassName="svg-3"
                                                label={t('Earned')}
                                                type="in-house"
                                                firstColValue={`${currency}${earn?.startMonthEarn}`}
                                                secondColValue={`${currency}${earn?.endMonthEarn}`}
                                                percentage={earn?.percentage}
                                            />
                                            <TableRow
                                                rowClassName="eaven-row"
                                                icon={<TickIcon />}
                                                iconClassName="svg-4"
                                                label={t('Confirmed Orders')}
                                                type="in-house"
                                                firstColValue={status[0]?.countStartMonth}
                                                secondColValue={status[0]?.countEndMonth}
                                                percentage={status[0]?.percentage}
                                            />
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<ClockIcon />}
                                                iconClassName="svg-5"
                                                label={t('Pending Orders')}
                                                type="in-house"
                                                firstColValue={status[1]?.countStartMonth}
                                                secondColValue={status[1]?.countEndMonth}
                                                percentage={status[1]?.percentage}
                                            />
                                            <TableRow
                                                rowClassName="eaven-row"
                                                icon={<CloseIcon />}
                                                iconClassName="svg-6"
                                                label={t('Canceled Orders')}
                                                type="in-house"
                                                firstColValue={status[2]?.countStartMonth}
                                                secondColValue={status[2]?.countEndMonth}
                                                percentage={status[2]?.percentage}
                                            />
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<PeopleIcon />}
                                                iconClassName="svg-7"
                                                label={t('Registered Customers')}
                                                type="in-house"
                                                firstColValue={customer?.startMonthCustomer}
                                                secondColValue={customer?.endMonthCustomer}
                                                percentage={customer?.percentage}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<TotalIncomingIcon />}
                                                iconClassName="svg-1"
                                                label={t('B2B Incoming')}
                                                type="b2b"
                                                firstColValue={`${currency}${firstMonth?.b2b_incoming}`}
                                                secondColValue={`${currency}${secondMonth?.b2b_incoming}`}
                                            />
                                            <TableRow
                                                rowClassName="eaven-row"
                                                icon={<ArrowBottomIcon />}
                                                iconClassName="svg-2"
                                                label={t('Partnership Costs')}
                                                type="b2b"
                                                firstColValue={`${currency}${firstMonth?.partnership_cost}`}
                                                secondColValue={`${currency}${secondMonth?.partnership_cost}`}
                                            />
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<ArrowTopIcon />}
                                                iconClassName="svg-3"
                                                label={t('Profits')}
                                                type="b2b"
                                                firstColValue={`${currency}${firstMonth?.profit}`}
                                                secondColValue={`${currency}${secondMonth?.profit}`}
                                            />
                                            <TableRow
                                                rowClassName="eaven-row"
                                                icon={<TotalSuppliersIcon />}
                                                iconClassName="total-supplier-svg"
                                                label={t('Total Suppliers')}
                                                type="b2b"
                                                firstColValue={firstMonth?.total_supplier}
                                                secondColValue={secondMonth?.total_supplier}
                                            />
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<RevenueFromSuppliersIcon />}
                                                iconClassName="revenue-svg"
                                                label={t('Revenue from Suppliers')}
                                                type="b2b"
                                                firstColValue={`${currency}${firstMonth?.supplier_b2b_total}`}
                                                secondColValue={`${currency}${secondMonth?.supplier_b2b_total}`}
                                            />
                                            <TableRow
                                                rowClassName="eaven-row"
                                                icon={<TotalVendorsIcon />}
                                                iconClassName="total-vendor-svg"
                                                label={t('Total Vendors')}
                                                type="b2b"
                                                firstColValue={firstMonth?.total_vendor}
                                                secondColValue={secondMonth?.total_vendor}
                                            />
                                            <TableRow
                                                rowClassName="odd-row"
                                                icon={<SalesByVendorsIcon />}
                                                iconClassName="sales-svg"
                                                label={t('Sales by Vendors')}
                                                type="b2b"
                                                firstColValue={`${currency}${firstMonth?.vendor_b2b_total}`}
                                                secondColValue={`${currency}${secondMonth?.vendor_b2b_total}`}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                <div className="results">
                    <Space>
                        <ChartIcon />
                        <span className="section-title">{t('Results')}</span>
                    </Space>
                    <div className="info-text-1">
                        {type === 'in-house' ? (
                            <Typography.Text>
                                {`${t('Your revenue is')} ${returnRevenue(total?.percentage)}`}
                            </Typography.Text>
                        ) : (
                            <Typography.Text>
                                {`${t('Your revenue is')} ${returnRevenue('10')}`}
                            </Typography.Text>
                        )}
                    </div>
                    <div className="info-text-2">
                        <Typography.Text>
                            {t('To improve your revenue we suggest following steps below')}
                            :
                        </Typography.Text>
                    </div>
                    <Divider />
                    <Row justify="space-between">
                        <Col>
                            <Space size={20} className="button-container">
                                <Button
                                    className="marketing-button"
                                    onClick={() => push('/app/marketing-tools')}
                                >
                                    <MarketingIcon />
                                    {t('Use the Marketing Tools')}
                                </Button>
                                <Button
                                    className="b2b-button"
                                    onClick={() => push('/app/b2b-partners/worldwide-supplier')}
                                >
                                    <B2BIcon />
                                    {t('Increase the B2B Partners')}
                                </Button>
                            </Space>
                        </Col>
                        <Col>
                            <div className="style-component">
                                <div className="col-1" />
                                <div className="col-2" />
                                <div className="col-3" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};

export default CompareReports;
