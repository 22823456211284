import { combineReducers } from 'redux';

import security, {
    types as securityTypes,
    actions as securityActions,
} from './security/reducer';

const actions = {
    ...securityActions,
};

const types = {
    ...securityTypes,
};

export { types, actions };

const userprofileReducer = combineReducers({
    security,
});

export default userprofileReducer;
