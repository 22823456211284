import { all } from 'redux-saga/effects';
import securitySaga from './security/saga';

function* userprofileSaga() {
    yield all([
        securitySaga(),
    ]);
}

export default userprofileSaga;
