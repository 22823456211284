import { all, fork, takeEvery } from 'redux-saga/effects';
import pagination from '@/helpers/pagination';
import MyPartnerService from '@/services/B2BPartnerServices/MyPartnerService';
import { types } from './reducer';

function* watchFetchPartnerList() {
    yield takeEvery(
        types.REQUEST_FETCH_PARTNER_LIST,
        pagination('partnerList').saga(MyPartnerService),
    );
}

function* paginationSaga() {
    yield all([
        fork(watchFetchPartnerList),
    ]);
}

export default paginationSaga;
