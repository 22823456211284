import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Form,
    Divider,
    Row,
    Col,
    Button,
} from 'antd';
import HeaderWithHelp from '@/components/HeaderWithHelp';
import { PlusIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/tour/cruise/reducer';
import { selectors } from '@/redux/tour/cruise/create/model';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import IteneraryItem from './IteneraryItem';
import baseValidations from './validations';

const question = 'What is this ?';
const answer = 'Lorem ipsum dolor sit amet...';

const formFields = [
    'title',
    'arrival_time',
    'departure_time',
    'arrival_place',
    'departure_place',
    'latitude',
    'longitude',
    'itinerary_image',
    'description',
];

const formatItenerariesForSubmit = (iteneraries) => iteneraries
    .map((itenerary) => ({
        id: itenerary.id ? itenerary.id : null,
        title: itenerary.title,
        arrival_time: itenerary.arrival_time,
        departure_time: itenerary.departure_time,
        arrival_place: itenerary.arrival_place,
        departure_place: itenerary.same_port === true ? null : itenerary.departure_place,
        latitude: itenerary.latitude,
        longitude: itenerary.longitude,
        itinerary_image: itenerary.itinerary_image?.[0]?.url,
        description: itenerary.description,
        deleted_images: itenerary.id && !itenerary.itinerary_image?.[0]?.url
            ? itenerary.id
            : undefined,
    }));

// format itenerary as initial data
const formatItenerariesData = (iteneraryList) => iteneraryList
    .map((itenerary) => ({
        id: itenerary.id,
        title: itenerary.title,
        arrival_time: moment(itenerary.arrival_time),
        departure_time: moment(itenerary.departure_time),
        arrival_place: itenerary.arrival_place,
        departure_place: itenerary.departure_place,
        latitude: itenerary.latitude,
        longitude: itenerary.longitude,
        itinerary_image: [{ url: itenerary.image, thumbUrl: itenerary.image }],
        description: itenerary.description,
    }));

const CruiseItenerary = ({ form, isEdit, regStep }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [itemIndex, setItemIndex] = useState(0);

    const validations = useTranslatedValidationConfig(baseValidations);

    const iteneraryList = useSelector(selectors.itenerary);

    const onFinish = useCallback((values) => {
        const data = {
            itinerary: values.iteneraries
                ? formatItenerariesForSubmit(values.iteneraries)
                : [],
        };
        if (isEdit && regStep > 3) {
            const step = 3;
            const completedStep = 3;
            dispatch(actions.requestSubmitCruiseItenerary(
                data,
                step,
                completedStep,
            ));
        } else {
            const step = 3;
            const completedStep = 2;
            dispatch(actions.requestSubmitCruiseItenerary(
                data,
                step,
                completedStep,
            ));
        }
    }, [dispatch]);

    useEffect(() => {
        let initialData = { iteneraries: [{ }] };
        if (iteneraryList.length !== 0) {
            initialData = {
                iteneraries: formatItenerariesData(iteneraryList),
            };
        }
        form.setFieldsValue(initialData);
    }, [iteneraryList]);

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    useEffect(() => {
        if (isEdit && iteneraryList.length !== 0) {
            setItemIndex(iteneraryList.length - 1);
        }
    }, [isEdit, iteneraryList]);

    return (
        <Form
            id="cruise-itenerary-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
        >
            <HeaderWithHelp
                title="Cruise Itenerary"
                question={question}
                answer={answer}
            />

            <Divider />

            <Form.List name="iteneraries">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <IteneraryItem
                                key={field.key}
                                field={field}
                                remove={remove}
                                validations={validations}
                                itemIndex={itemIndex}
                                formListName="iteneraries"
                                form={form}
                            />
                        ))}

                        <Divider />

                        <Row justify="end" align="middle">
                            <Col>
                                <Form.Item className="add-form">
                                    <Button
                                        className="button add-button add-button-outlined"
                                        onClick={() => {
                                            add();
                                            setItemIndex(itemIndex + 1);
                                        }}
                                    >
                                        <PlusIcon />
                                        {t('add new item').toUpperCase()}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default CruiseItenerary;
