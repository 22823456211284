import { useTranslation } from 'react-i18next';

const DataContainer = ({ title, children }) => {
    const { t } = useTranslation();

    return (
        <div className="data-container">
            <span className="border-text">{t(title)}</span>
            {children}
        </div>
    );
};

export default DataContainer;
