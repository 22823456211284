import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@/helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteTourTrip: ['id'],
    deleteTourTripSucceeded: ['id', 'messageSuccess'],
    deleteTourTripFailed: ['error'],

    // clear delete trip state
    clearDeleteTripState: null,
});

export default {
    [types.REQUEST_DELETE_TOUR_TRIP]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_TOUR_TRIP_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            messageSuccess,
        },
    }),
    [types.DELETE_TOUR_TRIP_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),

    // clear delete trip state
    [types.CLEAR_DELETE_TRIP_STATE]: (state) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error: null,
            messageSuccess: '',
        },
    }),
};
