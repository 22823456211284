import {
    TicketIcon,
    BedIcon,
    CalendarIcon,
    ClockIcon,
} from '@/assets/images/icons/screens';

const formatOffers = (offers) => offers.map((offer) => {
    if (offer.type === 'Custom Offer') {
        return {
            ...offer,
            icon: <TicketIcon />,
        };
    } else if (offer.type === 'Early Booker') {
        return {
            ...offer,
            icon: <CalendarIcon />,
        };
    } else if (offer.type === 'Free Night') {
        return {
            ...offer,
            icon: <BedIcon />,
        };
    } else {
        return {
            ...offer,
            icon: <ClockIcon />,
        };
    }
});

export default formatOffers;
