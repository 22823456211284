import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Rate,
    Typography,
    Form,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import CountrySelect from '@/components/CountrySelect';
import { firstToUpper } from '@/helpers/string';
import CustomTextArea from '@/components/TextArea';
import { LocationIcon } from '@/assets/images/icons/screens';

const GeneralDetails = ({ validations, setAddress }) => {
    const { t } = useTranslation();
    const { type } = useParams();

    const formattedType = firstToUpper(type);

    return (
        <>
            <div>
                <Typography.Text>
                    {t(`Put your ${formattedType} rate (Optional)`)}
                </Typography.Text>
            </div>

            <div>
                <Form.Item name="stars" rules={validations.stars}>
                    <Rate />
                </Form.Item>
            </div>

            <Row gutter={30}>
                <Col lg={24} xs={24}>
                    <MUIFormItem
                        label={t(`${formattedType} Name`)}
                        name="name"
                        rules={validations.name}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col lg={24} xs={24}>
                    <CustomTextArea
                        name="description"
                        placeholder={t(`${formattedType} Description`)}
                        rules={validations.description}
                    />
                </Col>

                <Col lg={24} xs={24}>
                    <MUIFormItem
                        label={t('Address')}
                        name="address"
                        rules={validations.address}
                    >
                        <Input
                            suffix={<LocationIcon />}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </MUIFormItem>
                </Col>

                <Col xl={12} lg={24} xs={24}>
                    <MUIFormItem
                        label={t('City')}
                        name="city"
                        rules={validations.city}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} lg={24} xs={24}>
                    <MUIFormItem
                        label={t('State/ Province')}
                        name="state"
                        rules={validations.state}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} lg={24} xs={24}>
                    <MUIFormItem
                        label={t('Zip Code')}
                        name="zip_code"
                        rules={validations.zip_code}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col xl={12} lg={24} xs={24}>
                    <MUIFormItem
                        label={t('Country')}
                        name="country"
                        rules={validations.country}
                    >
                        <CountrySelect />
                    </MUIFormItem>
                </Col>
            </Row>
        </>
    );
};

export default GeneralDetails;
