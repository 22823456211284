/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
    useLayoutEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { useWindowWidth, useWindowHeight } from '@react-hook/window-size';
import {
    Row,
    Col,
    Space,
    Divider,
    Button,
    Card,
    Avatar,
    Typography,
} from 'antd';
import {
    PartnershipIcon,
    LocationIcon,
    RightIcon,
    CalendarIcon,
    ShipIcon,
} from '@/assets/images/icons/screens';
import { TourIcon } from '@/assets/images/icons/sidebar';
import { HotelIcon, ActivityIcon } from '@/assets/images/icons/topbar';
import TermsAndConditions from '@/components/TermsAndConditions';

const SharedServiceList = ({ data }) => {
    const containerWrapper = useRef(null);
    const itemRef = useRef(null);
    const { t } = useTranslation();
    const { push } = useHistory();

    const [serviceId, setServiceId] = useState(null);

    const [modalVisible, setModalVisible] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(null);

    const windowWidth = useWindowWidth({ wait: 50 });
    const windowHeight = useWindowHeight({ wait: 50 });

    const tabletBreakPoints = [
        { width: 1, itemsToShow: 1 },
        {
            width: 480, itemsToShow: 2,
        },
        // {
        //     width: 576, itemsToShow: 2,
        // },
    ];
    useLayoutEffect(() => {
        if (containerWrapper?.current && itemRef?.current) {
            setItemsToShow(
                Math.floor((containerWrapper?.current?.clientHeight - 70)
            / (itemRef?.current?.clientHeight + 40)) || 1,
            );
        }
    }, [windowHeight, windowWidth, containerWrapper?.current?.clientHeight,
        itemRef?.current?.clientHeight]);

    // console.log(itemsToShow, itemRef, itemRef?.current?.clientHeight);

    const pushToExactPath = (serviceCategory, id) => {
        const types = [
            {
                key: 'Tour Trip',
                type: 'trip',
            },
            {
                key: 'Tour Package',
                type: 'package',
            },
            {
                key: 'Tour Cruise',
                type: 'cruise',
            },
            {
                key: 'Activity',
                type: 'activity',
            },
            // {
            //     key: 'Hotel',
            //     type: 'hotel',
            // }
        ];
        const exactType = types.find((type) => type.key === serviceCategory);
        push(`/app/b2b-partners/worldwide-supplier/${exactType.type}/${id}`);
    };

    return (
        <>
            <Row className="dashboard-deals-container">
                <Col
                    xl={24}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={24}
                    className="dashboard-deals-container-info"
                    flex="none"
                >
                    <Space size={20} align="start">
                        <PartnershipIcon className="partnership-icon" />
                        <div className="text-block">
                            <p>{t('The best Deals for you')}</p>
                            {' '}
                            <p>{t('Choose from B2B list the best deal for your partnership')}</p>
                            <Button onClick={() => push('/app/b2b-partners/worldwide-supplier')}>
                                {t('View full list')}
                            </Button>
                        </div>
                    </Space>
                </Col>

                <Col
                    flex="auto"
                    xl={24}
                    lg={16}
                    md={16}
                    sm={12}
                    xs={24}
                    className="dashboard-deals-container-list"
                    ref={containerWrapper}
                >
                    {(windowWidth < 576 || windowWidth > 1199)
                     && (
                         <Divider />
                     )}
                    <div>
                        <Carousel
                            className="right_dashboard_carousel"
                            // breakPoints={tabletBreakPoints}
                            {...(windowWidth > 1199 ? { itemsToShow: itemsToShow || 1 }
                                : { breakPoints: tabletBreakPoints })}
                            // itemsToShow={windowWidth > 1199 ? itemsToShow : tabletBreakPoints}
                            showArrows={false}
                            enableMouseSwipe
                            verticalMode={windowWidth > 1199}
                            enableAutoPlay
                            autoPlaySpeed={3000}
                            style={
                                {
                                    ...(windowWidth > 1199
                                        ? {
                                            height:
                                            (itemsToShow * itemRef?.current?.clientHeight) + 50,
                                        } : {}),
                                }
                            }
                        >
                            {data.map((item, index) => (

                                <Col key={item.id} flex="auto">
                                    <Card
                                        ref={index === 0 ? itemRef : null}
                                        bordered={false}

                                    >
                                        <Card.Meta
                                            avatar={(
                                                <div>
                                                    <Avatar
                                                        size={90}
                                                        src={item.image_path}
                                                    />
                                                    <div className="logo">
                                                        <Avatar size={35} src={item.agency_icon} />
                                                    </div>
                                                </div>
                                            )}
                                            title={(
                                                <Button
                                                    className="view-tour"
                                                    onClick={() => pushToExactPath(item.category, item.id)}
                                                >
                                                    {(item.category === 'Tour Trip' || item.category === 'Tour Package') && <TourIcon />}
                                                    {item.category === 'Tour Cruise' && <ShipIcon />}
                                                    {item.category === 'ACTIVITY' && <ActivityIcon />}
                                                    {item.category === 'Tour Cruise' ? t('Cruise').toUpperCase() : t(item.category).toUpperCase()}
                                                </Button>
                                            )}
                                            description={(
                                                <>
                                                    <Typography.Text>
                                                        {item.name}
                                                    </Typography.Text>
                                                    <div className="details">
                                                        {item.category === 'Tour Package' ? (
                                                            <div>
                                                                <HotelIcon />
                                                                <span>{item.location}</span>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <LocationIcon />
                                                                <span>{item.location}</span>
                                                            </div>
                                                        )}
                                                        {item.category !== 'Tour Package' && item.category !== 'Tour Trip' && (
                                                            <div>
                                                                <CalendarIcon />
                                                                {`${item.start_date} - ${item.end_date}`}
                                                            </div>
                                                        )}
                                                        {item.category === 'Tour Package' && (
                                                            <div>
                                                                <CalendarIcon />
                                                                {`${item.duration} - ${item.duration_type}`}
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        />
                                        <Space>
                                            <div className="price-content">
                                                <div>
                                                    {`${item.currency_icon} ${item.price} ${item.price_type}`}
                                                </div>
                                                <div
                                                    className={clsx(
                                                        item.status === '2' ? 'second-color' : 'base-color',
                                                    )}
                                                >
                                                    <span>
                                                        {item.commission_type === 'Percentage'
                                                            ? `${item.commission}%`
                                                            : `${item.currency_icon}${item.commission}`}
                                                    </span>
                                                    <span>{t('your earning')}</span>
                                                </div>
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    setModalVisible(true);
                                                    setServiceId(item.id);
                                                }}
                                                className="base-button"
                                            >
                                                <span>{t('collect').toUpperCase()}</span>
                                                <RightIcon />
                                            </Button>
                                        </Space>
                                    </Card>
                                </Col>
                            ))}
                        </Carousel>
                    </div>

                </Col>
            </Row>
            <TermsAndConditions
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                serviceId={serviceId}
            />
        </>
    );
};

export default SharedServiceList;
