import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Checkbox } from 'antd';

import BaseMultiSelect from './BaseMultiSelect';

import { defaultLabelParamName, defaultValueParamName } from './config';

const CheckboxMultiSelect = ({
    options,
    labelParamName = defaultLabelParamName,
    valueParamName = defaultValueParamName,
    hasSelectAll = true,
    selectAllLabel = null,
    initialCheckedList,
    ...rest
}) => {
    const { t } = useTranslation();

    const [checkAll, setCheckAll] = useState(
        initialCheckedList?.length === options?.length,
    );
    const [checkedList, setCheckedList] = useState(initialCheckedList);

    useEffect(() => {
        setCheckAll(checkedList?.length === options?.length);
    }, [options]);

    const onChange = (list) => {
        setCheckedList(list);
        setCheckAll(list.length === options.length);

        if ('onChange' in rest) {
            rest.onChange(list);
        }
    };

    const onCheckAllChange = (e) => {
        const checkedValues = e.target.checked
            ? options.map((option) => option[valueParamName])
            : [];
        setCheckedList(checkedValues);
        setCheckAll(e.target.checked);
        if ('onChange' in rest) {
            rest.onChange(checkedValues);
        }
    };

    return (
        <Row gutter={[30, 30]}>
            {hasSelectAll && (
                <Col lg={24}>
                    <Checkbox checked={checkAll} onChange={onCheckAllChange}>
                        <span className="select-all">
                            {t(selectAllLabel || 'Select All')}
                        </span>
                    </Checkbox>
                </Col>
            )}
            <Col lg={24}>
                <BaseMultiSelect
                    {...rest}
                    options={options}
                    labelParamName={labelParamName}
                    valueParamName={valueParamName}
                    value={checkedList}
                    onChange={onChange}
                />
            </Col>
        </Row>
    );
};

export default CheckboxMultiSelect;
