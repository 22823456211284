import {
    buildValidationsFromConfig,
} from '@/helpers/validation';

const config = {
    title: {
        name: 'Title',
        required: true,
        min: 5,
        max: 150,
    },
    description: {
        name: 'Description',
        required: true,
        min: 5,
    },
    arrival_time: {
        name: 'Arrival time',
        required: true,
    },
    departure_time: {
        name: 'Departure time',
        required: true,
    },
    arrival_place: {
        name: 'Arrival port',
        required: true,
    },
    departure_place: {
        name: 'Departure port',
        required: true,
    },
    latitude: {
        name: 'Latitude',
        required: true,
    },
    longitude: {
        name: 'Longitude',
        required: true,
    },
};

export default buildValidationsFromConfig(config);
