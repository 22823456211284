import { useState } from 'react';
import { Layout } from 'antd';
import clsx from 'clsx';
import { useWindowWidth } from '@react-hook/window-size';
import privateRoutes from '@/routes/privateRoutes';
import Topbar from '@/components/Topbar';
import MobileTopbar from '@/components/MobileTopbar';
import Sidebar from '@/components/Sidebar';
import useCurrentRoute from '@/hooks/useCurrentRoute';

const AuthLayout = ({ children }) => {
    const windowWidth = useWindowWidth({ wait: 50 });

    const [isCollapsed, setIsCollapsed] = useState(true);
    const currentRoute = useCurrentRoute(privateRoutes);
    if (windowWidth > 991) {
        return (
            <Layout className="auth-layout">
                <Sidebar
                    isCollapsed={isCollapsed}
                    currentRoute={currentRoute}
                />
                <Layout
                    className={clsx(
                        'site-layout',
                        isCollapsed && 'sidebar-collapsed',
                    )}
                >
                    <Topbar
                        currentRoute={currentRoute}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                    />
                    <Layout.Content>{children}</Layout.Content>
                </Layout>
            </Layout>
        );
    } else {
        return (
            <Layout className="auth-mobile-layout">
                <MobileTopbar
                    currentRoute={currentRoute}
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                />
                <Layout.Content>{children}</Layout.Content>
            </Layout>
        );
    }
};

export default AuthLayout;
