import {
    useMemo,
    useCallback,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
    Row,
    Col,
    Select,
    Typography,
    Input,
    Form,
    Checkbox,
    Button,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import validateFormCompletionOrder from '@/helpers/validateFormCompletionOrder';
import { ArrowRightIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/b2b/partner/reducer';
import { selectors } from '@/redux/b2b/partner/create/model';

const apiValidatedFieldNames = [
    'email',
    'prefix',
    'phone',
    'password',
    'password_confirm',
];
const allFields = ['firstName', 'lastName'].concat(apiValidatedFieldNames);

const AccountDetails = ({
    formStep1,
    validations,
    submitting,
    agencyId,
    setAgencyId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [prefix, setPrefix] = useState(1);

    const agencies = useSelector(selectors.agencyList);

    const onSearch = useCallback((search) => {
        if (search && search.length === 3) {
            const data = {
                name: search,
            };
            dispatch(actions.requestGetAgencyList(data));
        }
    }, [dispatch]);

    const onFinish = useCallback((values) => {
        const data = {
            fullname: `${values.firstName} ${values.lastName}`,
            email: values.email,
            prefix: values.prefix,
            phone: values.phone,
            password: values.password,
            password_confirm: values.password_confirm,
            termsofservice: values.termsofservice,
        };
        dispatch(actions.requestSubmitAgencyAccount(data));
    }, [dispatch]);

    const onCountryChange = useCallback((values) => {
        const ifPhoneTouched = (
            formStep1.isFieldTouched('phone')
            || (formStep1.getFieldError('phone').length > 0)
        );
        formStep1.validateFields(
            allFields.slice(
                0,
                allFields.indexOf('prefix') + (ifPhoneTouched ? 2 : 1),
            ),
        );
        setPrefix(values.prefix);
    }, [formStep1, setPrefix]);

    const phoneNumberValidator = useMemo(() => ([
        {
            type: 'validator',
            validator: () => new Promise((resolve, reject) => {
                if (!prefix) {
                    const message = t('Prefix can\'t be empty');
                    reject(t(message));
                } else {
                    resolve();
                }
            }),
        },
        ...validations.phone,
    ]), [validations.phone, prefix, t]);

    const confirmPasswordValidator = useMemo(() => ([
        ...validations.confirmPassword,
        {
            type: 'validator',
            validator: (_, value) => new Promise((resolve, reject) => {
                if (value && value !== password) {
                    const message = t(
                        'Confirm password needs to be the same as password',
                    );
                    reject(t(message));
                } else {
                    resolve();
                }
            }),
        },
    ]), [password, t, validations.confirmPassword]);

    return (
        <Form
            form={formStep1}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            onFieldsChange={(changedField) => {
                validateFormCompletionOrder({
                    form: formStep1, fields: allFields, changedField,
                });
            }}
        >
            <div className="agency-account">
                <Typography.Text className="step-title">{t('Create Agency Account')}</Typography.Text>
                <div className="step-subtitle">{`${t('All follows fields are required')}!`}</div>

                <MUIFormItem>
                    <Select
                        placeholder={t('Search for existing account')}
                        showSearch={true}
                        allowClear
                        onSearch={onSearch}
                        onSelect={(value) => setAgencyId(value)}
                        onClear={() => setAgencyId(null)}
                    >
                        {agencies?.map((agency) => (
                            <Select.Option key={agency.id} value={agency.id}>
                                {agency.name}
                            </Select.Option>
                        ))}
                    </Select>
                </MUIFormItem>

                <div className={clsx('hidden-inputs', agencyId !== null && 'change-visibility')}>
                    <Row gutter={30}>
                        <Col xs={12}>
                            <MUIFormItem
                                label={t('First Name')}
                                name="firstName"
                                rules={validations.firstName}
                                validateFirst
                                hasFeedback
                            >
                                <Input disabled={agencyId !== null} />
                            </MUIFormItem>
                        </Col>
                        <Col xs={12}>
                            <MUIFormItem
                                label={t('Last Name')}
                                name="lastName"
                                rules={validations.lastName}
                                validateFirst
                                hasFeedback
                            >
                                <Input disabled={agencyId !== null} />
                            </MUIFormItem>
                        </Col>
                    </Row>

                    <MUIFormItem
                        label={t('Email')}
                        name="email"
                        rules={validations.email}
                        validateFirst
                        hasFeedback
                    >
                        <Input disabled={agencyId !== null} />
                    </MUIFormItem>

                    <PhoneNumberInput
                        validations={phoneNumberValidator}
                        onSelect={onCountryChange}
                        disabled={agencyId !== null}
                    />

                    <MUIFormItem
                        label={t('Password')}
                        name="password"
                        rules={validations.password}
                        validateFirst
                        hasFeedback
                    >
                        <Input.Password
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={agencyId !== null}
                        />
                    </MUIFormItem>

                    <MUIFormItem
                        label={t('Confirm Password')}
                        name="password_confirm"
                        rules={confirmPasswordValidator}
                        validateFirst
                        hasFeedback
                        dependencies={['password']}
                    >
                        <Input.Password disabled={agencyId !== null} />
                    </MUIFormItem>
                </div>
            </div>
            <Row gutter={20} align="middle">
                <Col span={15}>
                    <Form.Item
                        name="termsofservice"
                        noStyle
                        valuePropName="checked"
                        rules={validations.termsOfService}
                        validateFirst
                    >
                        <Checkbox>
                            {t('I agree to the')}
                            {' '}
                            <Link to="/terms-of-service">
                                {t('Terms of Service')}
                            </Link>
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Button
                        block
                        className="save-vendor-btn"
                        loading={submitting}
                        onClick={() => {
                            agencyId === null
                                ? formStep1.submit()
                                : dispatch(actions.goToThirdStep());
                        }}
                    >
                        {t('next step').toUpperCase()}
                        <ArrowRightIcon />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AccountDetails;
