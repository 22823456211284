import { Row, Col, Space } from 'antd';
import {
    ArrowBottomIcon,
    ArrowTopIcon,
} from '@/assets/images/icons/screens';

const returnArrow = (startValue, endValue, percentage) => {
    if (startValue > endValue) {
        return (
            <div className="difference">
                <ArrowBottomIcon />
                <span>{percentage}</span>
            </div>
        );
    }
    if (startValue < endValue) {
        return (
            <div className="difference">
                <ArrowTopIcon />
                <span>{percentage}</span>
            </div>
        );
    } else {
        return null;
    }
};

const TableRow = ({
    rowClassName,
    icon,
    iconClassName,
    label,
    type,
    firstColValue,
    secondColValue,
    percentage,
}) => {
    return (
        <Row className={rowClassName}>
            <Col span={10}>
                <Space size={10}>
                    <div className={iconClassName}>
                        {icon}
                    </div>
                    {label}
                </Space>
            </Col>
            <Col span={7}>
                <span className="value">{firstColValue}</span>
            </Col>
            <Col span={7}>
                <div>
                    <span className="value">{secondColValue}</span>
                </div>
                {type === 'in-house' && (
                    returnArrow(
                        firstColValue,
                        secondColValue,
                        percentage,
                    )
                )}
            </Col>
        </Row>
    );
};

export default TableRow;
