import { useState } from 'react';
import { Form, Input } from 'antd';

import CountrySelect from '@/components/CountrySelect';
import CountryFlagComponent from '@/components/CountryFlag';

const PhoneNumberInput = ({
    // eslint-disable-next-line no-unused-vars
    validations,
    onSelect = () => {},
    // onChange = () => {},
    disabled,
}) => {
    const [prefix, setPrefix] = useState();
    const [phone, setPhone] = useState();

    return (
        <Form.Item
            className="phone-input"
            name="phone"
            rules={validations}
            validateFirst
            hasFeedback
            onChange={(e) => {
                const { target: { value } } = e;
                setPhone(value);
                onSelect({ prefix, phone: value });
            }}
        >
            <Input
                disabled={disabled}
                placeholder="201-555-0123"
                addonBefore={(
                    <Form.Item name="prefix" value={prefix} noStyle>
                        <CountrySelect
                            disabled={disabled}
                            name="prefix"
                            valueParam="prefix"
                            onSelect={(newPrefix) => {
                                setPrefix(newPrefix);
                                onSelect({ prefix: newPrefix, phone });
                            }}
                            renderOption={(country) => (
                                <>
                                    <CountryFlagComponent code={country.short} />
                                    {`+ ${country.prefix}`}
                                </>
                            )}
                        />
                    </Form.Item>
                )}
            />
        </Form.Item>
    );
};

export default PhoneNumberInput;
