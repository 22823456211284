import { useTranslation } from 'react-i18next';
import { List } from 'antd';

const ListHead = () => {
    const { t } = useTranslation();

    return (
        <div>
            <span>
                {t('Full Name')}
            </span>
            <span>
                {t('Nationality')}
            </span>
            <span>
                {t('Age')}
            </span>
            <span>
                {t('Gender')}
            </span>
        </div>
    );
};

const ParticipantsList = ({ participants }) => {
    const { t } = useTranslation();

    return (
        <List
            size="small"
            header={<ListHead />}
            dataSource={participants}
            renderItem={(item) => (
                item.fullname
                    ? (
                        <List.Item>
                            <span>{t(item.fullname)}</span>
                            <span>{t(item.nationality)}</span>
                            <span>{t(item.birthdate)}</span>
                            <span>{t(item.gender)}</span>
                        </List.Item>
                    )
                    : (
                        <List.Item>
                            <span>--</span>
                            <span>--</span>
                            <span>--</span>
                            <span>--</span>
                        </List.Item>
                    )
            )}
        />
    );
};

export default ParticipantsList;
