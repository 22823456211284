import { Skeleton } from 'antd';
import clsx from 'clsx';

const ListItem = ({ className }) => {
    return (
        <Skeleton.Button
            className={clsx(className || 'loading-list-item')}
            active
            block
        />
    );
};

export default ListItem;
