import { useCallback } from 'react';
import { Col } from 'antd';

import IncrementNumberInput from '@/components/IncrementNumberInput';

const renderType = (type, count) => `${count} ${(count === 1)
    ? type.singular
    : type.plural
}`;

const PersonNumberInput = ({ type, onChange }) => {
    const onCountChange = useCallback((count) => {
        onChange(type.id, count);
    }, [type.id, onChange]);

    const render = useCallback(
        (count) => renderType(type, count),
        [type],
    );

    return (
        <Col>
            <IncrementNumberInput
                render={render}
                onChange={onCountChange}
                defaultCount={type.value ? type.value : 0}
            />
        </Col>
    );
};

export default PersonNumberInput;
