import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@/helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteEmployee: ['id'],
    deleteEmployeeSucceeded: ['id', 'messageSuccess'],
    deleteEmployeeFailed: ['error'],

    // clear delete employee state
    clearDeleteEmployeeState: null,
});

export default {
    [types.REQUEST_DELETE_EMPLOYEE]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_EMPLOYEE_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            messageSuccess,
        },
    }),
    [types.DELETE_EMPLOYEE_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),

    // clear delete employee state
    [types.CLEAR_DELETE_EMPLOYEE_STATE]: (state) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error: null,
            messageSuccess: '',
        },
    }),
};
