import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Card,
    Avatar,
    Space,
    Button,
    Divider,
} from 'antd';
import { CalendarIcon, EmptyRequestIcon, LocationIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/b2b/request/reducer';
import Filters from './Filters';
import RequestDetails from './RequestDetails';

const initialFilters = {};

const Requests = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [activeRequestId, setActiveRequestId] = useState(null);
    const [filters, baseSetFilters] = useState(initialFilters);
    const [page, setPage] = useState(1);

    const handleFetchRequestList = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            const data = {
                ...filters,
                // eslint-disable-next-line object-shorthand
                perPage: perPage,
                page: currentPage,
            };
            dispatch(actions.requestFetchRequestList(data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            filters,
        ],
    );

    const setFilters = useCallback((name, value) => {
        const variables = { ...filters, [name]: value };
        baseSetFilters((prev) => ({ ...prev, ...variables }));
    }, [filters]);

    const requestList = useSelector((state) => state.b2b.request.data);
    const requestSuccess = useSelector((state) => state.b2b.request.view.message);
    const {
        fetchingLoading: loading,
        isLoadingMore,
        hasMore,
    } = useSelector((state) => state.b2b.request);

    useEffect(() => {
        handleFetchRequestList({});
    }, [handleFetchRequestList]);

    const handleFetchMore = useCallback(() => {
        handleFetchRequestList({ currentPage: page, fetchMore: true });
    }, [handleFetchRequestList, page]);

    // view request details
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const viewRequestDetails = useCallback((id) => {
        const data = {
            page: currentPage,
            perPage: itemsPerPage,
        };
        dispatch(actions.viewRequestDetails(id, data));
    }, [dispatch, currentPage, itemsPerPage]);

    // if agency accept or reject request
    useEffect(() => {
        const data = {
            page: currentPage,
            perPage: itemsPerPage,
        };
        if (requestSuccess === 'Success') {
            dispatch(actions.viewRequestDetails(activeRequestId, data));
            dispatch(actions.clearRequestSuccessMessage());
        }
    }, [
        dispatch,
        currentPage,
        itemsPerPage,
        requestSuccess,
        activeRequestId,
    ]);

    return (
        <div className="b2b-requests-page">
            <Filters
                filters={filters}
                setFilters={setFilters}
                data={requestList}
            />
            {loading
                ? 'Loading...'
                : (
                    <Row gutter={1} className="request-container">
                        <Col xs={12}>
                            <div className="request-list">
                                <Row gutter={20}>
                                    {requestList.map((item) => (
                                        <Col span={24} key={item.id} className={clsx(activeRequestId === item.id && 'no-padding')}>
                                            <Card bordered={false} className={clsx(activeRequestId === item.id && 'border-left')}>
                                                <Card.Meta
                                                    avatar={(
                                                        <Avatar
                                                            className="tour-image"
                                                            src={item.image_path}
                                                            size={48}
                                                        />
                                                    )}
                                                    description={(
                                                        <Row justify="space-between" align="middle">
                                                            <Col>
                                                                <div className="title">
                                                                    {item.service_name}
                                                                </div>

                                                                <Space size={20}>
                                                                    <div className="location">
                                                                        <LocationIcon />
                                                                        {item.location}
                                                                    </div>

                                                                    <div className="location">
                                                                        <CalendarIcon />
                                                                        {`${item.duration} ${item.duration_type}`}
                                                                    </div>
                                                                </Space>
                                                            </Col>

                                                            <Col>
                                                                <Button
                                                                    onClick={() => {
                                                                        setActiveRequestId(
                                                                            item.id,
                                                                        );
                                                                        viewRequestDetails(
                                                                            item.id,
                                                                        );
                                                                    }}
                                                                    className={clsx(activeRequestId === item.id && 'active')}
                                                                >
                                                                    <span>
                                                                        {item.total_requests}
                                                                    </span>
                                                                    <span>{t('requests')}</span>
                                                                    {item.new_requests !== 0
                                                                    && (
                                                                        <div
                                                                            className={clsx(
                                                                                'pending-requests',
                                                                                activeRequestId === item.id && 'hide',
                                                                            )}
                                                                        >
                                                                            {item.new_requests}
                                                                        </div>
                                                                    )}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                />
                                            </Card>
                                            <Divider />
                                        </Col>
                                    ))}
                                </Row>
                                {!isLoadingMore && hasMore && (
                                    <Waypoint onEnter={() => handleFetchMore()} />
                                )}
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="request-details">
                                {activeRequestId === null ? (
                                    <div className="empty-request-container">
                                        <EmptyRequestIcon />
                                        <div className="info-text">
                                            {t('Click on the left item tabs to view full details of each')}
                                            <br />
                                            {t("requested B2B products you've shared")}
                                        </div>
                                    </div>
                                ) : (
                                    <RequestDetails
                                        page={currentPage}
                                        setPage={setCurrentPage}
                                        perPage={itemsPerPage}
                                        setPerPage={setItemsPerPage}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                )}
        </div>
    );
};

export default Requests;
