import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    // submit customer details
    requestSubmitCustomerDetails: ['data'],
    submitCustomerDetailsSucceeded: ['data'],
    submitCustomerDetailsFailed: ['errors'],

    // fetch for edit
    requestFetchCustomerData: ['id'],
    fetchCustomerDataSucceeded: ['data'],
    fetchCustomerDataFailed: ['errors'],

    // clear state
    clearCustomerState: ['null'],
});

export default {
    [types.REQUEST_SUBMIT_CUSTOMER_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_CUSTOMER_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            customerInfo: data,
            success: true,
        },
    }),
    [types.SUBMIT_CUSTOMER_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_CUSTOMER_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_CUSTOMER_DATA_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            customerInfo: data,
        },
    }),
    [types.FETCH_CUSTOMER_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // clear state
    [types.CLEAR_CUSTOMER_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            loading: false,
            errors: null,
            success: false,
            customerInfo: null,
        },
    }),

};

export { types, actions };
