import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Form } from 'antd';

import MultistepFormActions from '@/components/MultistepFormActions';
import { actions } from '@/redux/accommodation/hotel/create/reducer';

import Details from './Details';
import Steps from './Steps';
import FacilitiesAndServices from './FacilitiesAndServices';
import Rooms from './Rooms';
import SeasonalPrice from './SeasonalPrices';

const formSteps = [
    Details,
    FacilitiesAndServices,
    Rooms,
    SeasonalPrice,
];

const New = () => {
    const { type } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { id: urlId } = useParams();
    const reduxId = useSelector(
        (state) => state.accommodation.hotel.create.accommodationDetails?.id,
    );
    const regStep = useSelector(
        (state) => state.accommodation.hotel.create.accommodationDetails?.register_hotel_step,
    );

    const step = useSelector((state) => state.accommodation.hotel.create.step);

    const id = urlId || reduxId;
    const submitting = useSelector(
        (state) => state.accommodation.hotel.create.submitting,
    );

    const goToEditStep = useCallback((s) => {
        dispatch(actions.goToEditAccommodationStep(s));
    }, [dispatch]);

    // is used only to jump from step 3 to step 4 &&
    // from step 4 to view mode
    const goToNextStep = useCallback((s) => {
        dispatch(actions.goToCreateAccommodationStep(s));
    }, [dispatch]);

    const FormStepComponent = useMemo(() => {
        if (step >= 0 && step < formSteps.length) {
            return formSteps[step];
        } else if (formSteps.length === step) {
            return formSteps[formSteps.length - 1];
        } else {
            return formSteps[0];
        }
    }, [step]);

    useEffect(() => {
        if (urlId) {
            dispatch(actions.requestFetchAccommodationData(id));
        }
    }, [dispatch, id, urlId]);

    return (
        <Row id="add-accommodation-page" gutter={[30, 30]}>
            <Col
                lg={{ span: 14, order: 1 }}
                xs={{ span: 24, order: 2 }}
            >
                <div className="left-col">
                    <FormStepComponent
                        form={form}
                        id={id}
                        regStep={regStep}
                    />
                </div>
            </Col>

            <Col
                lg={{ span: 10, order: 2 }}
                xs={{ span: 24, order: 1 }}
            >
                <div className="right-col-container">
                    <div className="right-col">
                        <Steps step={step} />
                    </div>
                    <MultistepFormActions
                        stepCount={4}
                        step={step}
                        submitting={submitting}
                        onSubmit={() => {
                            if (step === 2 || step === 3) {
                                goToNextStep(step);
                            } else {
                                form.submit();
                            }
                        }}
                        onBack={() => goToEditStep(step - 1)}
                        viewOneUrl={
                            (id !== undefined)
                                ? `/app/accommodations/${type}/${id}`
                                : undefined
                        }
                        viewOneButtonText={`View this ${type}`}
                        viewAllUrl={`/app/accommodations/${type}/`}
                        createUrl={`/app/accommodations/${type}/add/`}
                        onPublish={console.log}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default New;
