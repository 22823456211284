import pagination from '@/helpers/pagination';
import create from './create/model';
import deleteModel from './delete/model';
import view from './view/model';

export default {
    ...pagination('partnerList').model,
    create,
    delete: deleteModel,
    view,
};
