import { GridWidgetIcon } from '@/assets/images/icons/screens';

const GridWidget = () => {
    return (
        <div className="grid-svg">
            <GridWidgetIcon />
        </div>
    );
};

export default GridWidget;
