import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    step: 0,
    completedStep: null,
    submitting: false,
    loading: false,
    errors: null,

    generalInfo: null,
    timeAndDuration: null,
    partner: null,
    partnerList: [],
};

export const selectors = createSelectorsFromModel(
    (state) => state.tour.activities.create,
    model,
);

export default model;
