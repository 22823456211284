import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Typography,
} from 'antd';
import DataContainer from '@/components/DataContainer';
import InlineDescription from '@/components/Information/WithoutImage/Inline';

const Seasons = ({ data }) => {
    const { t } = useTranslation();
    return (
        data.length !== 0 && (
            <DataContainer title={t('Seasons')}>
                <Row gutter={[0, 0]} className="right-col_cards">
                    {data.map((item) => (
                        <Col span={24} key={item.id}>
                            <Space>
                                <div className="season-price">
                                    <div className="season-price_item">{item.price}</div>
                                    <div>{t('per Night').toUpperCase()}</div>
                                </div>
                                <div>
                                    <Typography.Text>{item.name}</Typography.Text>
                                    <InlineDescription
                                        label={t('Start Season')}
                                        content={item.start_date}
                                    />
                                    <InlineDescription
                                        label={t('End Season')}
                                        content={item.end_date}
                                    />
                                </div>
                            </Space>
                        </Col>
                    ))}
                </Row>
            </DataContainer>
        )
    );
};

export default Seasons;
