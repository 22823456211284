import {
    HomeIcon,
    AccommodationIcon,
    TourIcon,
    FlightIcon,
    TransferIcon,
    InvoiceIcon,
    EmployeeIcon,
    CustomerIcon,
    B2BIcon,
    StatisticIcon,
    MarketingIcon,
    IntegrationIcon,
} from '@/assets/images/icons/sidebar';
import {
    HotelIcon,
    ResortIcon,
    MotelIcon,
    HostelIcon,
    TripIcon,
    PackageIcon,
    CruiseIcon,
    ActivityIcon,
    EmployeesIcon,
    RolesIcon,
    BookingIcon,
    ChannelManagerIcon,
    IMarketingIcon,
    InHouseStatsIcon,
    B2BStatsIcon,
    RequestIcon,
    WorldwideIcon,
    GeneralIcon,
    ServiceIcon,
    TaxIcon,
    BillIcon,
} from '@/assets/images/icons/topbar';
import { UserIcon } from '@/assets/images/icons/screens';
import Dashboard from '@/screens/Dashboard';
import Tours from '@/screens/Tours';
import Accommodations from '@/screens/Accommodations';
import Profile from '@/screens/Profile';
import Invoices from '@/screens/Invoices';
import Employees from '@/screens/Employees';
import Customers from '@/screens/Customers';
import Flights from '@/screens/Flights';
import Integrations from '@/screens/Integrations';
import Reports from '@/screens/Reports';
import B2BPartners from '@/screens/B2BPartners';
import Settings from '@/screens/Settings';
import Transfers from '@/screens/Transfers';
import Marketing from '@/screens/Marketing';
import AgencyAprove from '@/screens/AgencyApprove';
import ChangePassword from '@/screens/ChangePassword';

export default [
    {
        path: '/app/dashboard',
        key: 'APP_DASHBOARD',
        // exact: true,
        component: Dashboard,
        name: 'Dashboard',
        icon: <HomeIcon />,
        inSidebar: true,
        accessor: 'dashboard',
        subMenus: [],
    },
    {
        path: '/app/accommodations',
        key: 'APP_ACCOMMODATIONS',
        exact: false,
        component: Accommodations,
        name: 'Accommodations',
        icon: <AccommodationIcon />,
        inSidebar: true,
        accessor: 'accommodations',
        subMenus: [
            {
                name: 'Hotel',
                icon: <HotelIcon />,
                path: '/hotel',
            },
            {
                name: 'Resort',
                icon: <ResortIcon />,
                path: '/resort',
            },
            {
                name: 'Motel',
                icon: <MotelIcon />,
                path: '/motel',
            },
            {
                name: 'Hostel',
                icon: <HostelIcon />,
                path: '/hostel',
            },
        ],
    },
    {
        path: '/app/tours',
        key: 'APP_TOURS',
        exact: false,
        component: Tours,
        name: 'Tours',
        icon: <TourIcon />,
        inSidebar: true,
        accessor: 'tours',
        subMenus: [
            {
                name: 'Tour Trip',
                icon: <TripIcon />,
                path: '/trip',
            },
            {
                name: 'Tour Packages',
                icon: <PackageIcon />,
                path: '/packages',
            },
            {
                name: 'Cruise Tour',
                icon: <CruiseIcon />,
                path: '/cruise',
            },
            {
                name: 'Activities',
                icon: <ActivityIcon />,
                path: '/activities',
            },
        ],
    },
    {
        path: '/app/flights',
        key: 'APP_FLIGHTS',
        exact: false,
        component: Flights,
        name: 'Flights',
        icon: <FlightIcon />,
        inSidebar: true,
        accessor: 'flights',
        subMenus: [],
    },
    {
        path: '/app/transfers',
        key: 'APP_TRANSFERS',
        exact: true,
        component: Transfers,
        name: 'Transfers',
        icon: <TransferIcon />,
        inSidebar: true,
        accessor: 'transfers',
        subMenus: [],
    },
    {
        path: '/app/invoices',
        key: 'APP_INVOICES',
        exact: false,
        component: Invoices,
        name: 'Invoices',
        icon: <InvoiceIcon />,
        inSidebar: true,
        accessor: 'invoices',
        subMenus: [],
    },
    {
        path: '/app/employees',
        key: 'APP_EMPLOYEES',
        exact: false,
        component: Employees,
        name: 'Employees',
        icon: <EmployeeIcon />,
        inSidebar: true,
        accessor: 'employees',
        subMenus: [
            {
                name: 'Employees',
                icon: <EmployeesIcon />,
                path: '/employees',
            },
            {
                name: 'Roles / Job Positions',
                icon: <RolesIcon />,
                path: '/roles',
            },
        ],
    },
    {
        path: '/app/customers',
        key: 'APP_CUSTOMERS',
        exact: false,
        component: Customers,
        name: 'Customers',
        icon: <CustomerIcon />,
        inSidebar: true,
        accessor: 'customers',
        subMenus: [],
    },
    {
        path: '/app/b2b-partners',
        key: 'APP_B2B_PARTNERS',
        exact: false,
        component: B2BPartners,
        name: 'B2B Partners',
        icon: <B2BIcon />,
        inSidebar: true,
        accessor: 'b2b_partners',
        subMenus: [
            {
                name: 'Worldwide Supplier',
                icon: <WorldwideIcon />,
                path: '/worldwide-supplier',
            },
            {
                name: 'My Partners',
                icon: <B2BStatsIcon />,
                path: '/my-partners',
            },
            {
                name: 'Requests',
                icon: <RequestIcon />,
                path: '/requests',
            },
        ],
    },
    {
        path: '/app/stats-reports',
        key: 'APP_STATS_REPORTS',
        exact: false,
        component: Reports,
        name: 'Stats. & Reports',
        icon: <StatisticIcon />,
        inSidebar: true,
        accessor: 'stats_reports',
        subMenus: [
            {
                name: 'In-house Stats',
                icon: <InHouseStatsIcon />,
                path: '/in-house-stats',
            },
            {
                name: 'B2B Stats',
                icon: <B2BStatsIcon />,
                path: '/b2b-stats',
            },
        ],
    },
    {
        path: '/app/marketing-tools',
        key: 'APP_MARKETING_TOOLS',
        exact: true,
        component: Marketing,
        name: 'Marketing Tools',
        icon: <MarketingIcon />,
        inSidebar: true,
        accessor: 'marketing_tools',
        subMenus: [],
    },
    {
        path: '/app/integrations',
        key: 'APP_INTEGRATIONS',
        exact: false,
        component: Integrations,
        name: 'Integrations',
        icon: <IntegrationIcon />,
        inSidebar: true,
        accessor: 'integrations',
        subMenus: [
            {
                name: 'Booking Engine',
                icon: <BookingIcon />,
                path: '/booking-engine',
            },
            {
                name: 'Channel Manager',
                icon: <ChannelManagerIcon />,
                path: '/channel-manager',
            },
            {
                name: 'Marketing',
                icon: <IMarketingIcon />,
                path: '/marketing',
            },
        ],
    },
    {
        path: '/app/profile',
        key: 'APP_PROFILE',
        // exact: true,
        component: Profile,
        name: 'Profile',
        icon: <UserIcon />,
        inSidebar: false,
        accessor: 'Profile',
        subMenus: [],
    },
    {
        path: '/app/settings',
        key: 'APP_SETTINGS',
        exact: false,
        component: Settings,
        name: 'Settings',
        icon: <IntegrationIcon />,
        inSidebar: false,
        accessor: 'settings',
        subMenus: [
            {
                name: 'General',
                icon: <GeneralIcon />,
                path: '/general',
            },
            {
                name: 'Services',
                icon: <ServiceIcon />,
                path: '/services',
            },
            {
                name: 'Taxes',
                icon: <TaxIcon />,
                path: '/taxes',
            },
            {
                name: 'Billing',
                icon: <BillIcon />,
                path: '/billing',
            },
        ],
    },

    {
        path: '/app/waiting-approve',
        key: 'APP_Approve',
        // exact: true,
        component: AgencyAprove,
        name: 'Waiting Approve',
        icon: '',
        inSidebar: false,
        accessor: 'Waiting Approve',
        subMenus: [],
    },
    {
        path: '/app/change-password',
        key: 'APP_CHANGE_PASSWORD',
        // exact: true,
        component: ChangePassword,
        name: 'Change Password',
        icon: '',
        inSidebar: false,
        accessor: 'Change Password',
        subMenus: [],
    },
];
