import {
    Avatar,
    Col,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import SectionLayout from '../../SectionLayout';

const TourTrip = ({ reservationData }) => {
    const { t } = useTranslation();

    return reservationData !== undefined && (
        <SectionLayout
            className="tour-details"
            icon={(
                <Avatar
                    size={60}
                    src={reservationData.trip?.image || reservationData.image}
                />
            )}
            leftCol={3}
            rightCol={21}
        >
            <Row>
                <Col xs={12} className="tour-details-content">
                    <Typography.Title level={5}>
                        {reservationData.trip?.name || reservationData.name}
                    </Typography.Title>

                    <div className="itenerary">
                        <Typography.Text className="name">
                            {t('Itenerary')}
                            :
                        </Typography.Text>
                    </div>
                    <div className="itinerary-locations">
                        {reservationData.trip ? reservationData.trip.itinerary.map((itinerary) => (
                            <div key={itinerary.id}>
                                <Typography.Text>
                                    {itinerary.location}
                                </Typography.Text>
                            </div>
                        )) : (
                            reservationData.itinerary?.map((itinerary) => (
                                <div key={itinerary.id}>
                                    <Typography.Text>
                                        {itinerary.location}
                                    </Typography.Text>
                                </div>
                            ))
                        )}
                    </div>

                    <div className="date">
                        <Typography.Text>
                            {t('Starting Date')}
                            {': '}
                        </Typography.Text>
                    </div>
                    <div>
                        <Typography.Text>
                            {reservationData.date_start}
                        </Typography.Text>
                    </div>
                </Col>

                <Col xs={12}>
                    <div className="added-to">
                        <div className="added-to-text">
                            <Typography.Text>
                                {t('Added to')}
                                :
                            </Typography.Text>
                        </div>
                        <div>
                            <Typography.Text>
                                {reservationData.group_name}
                            </Typography.Text>
                        </div>
                    </div>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default TourTrip;
