import { useTranslation } from 'react-i18next';

const DashedContainer = ({ title, children }) => {
    const { t } = useTranslation();

    return (
        <div className="input-group-container">
            <span className="border-text">{t(title)}</span>
            {children}
        </div>
    );
};

export default DashedContainer;
