import { useParams } from 'react-router-dom';
import ViewTrip from '@/screens/Tours/Trip/ViewOne';
import ViewPackage from '@/screens/Tours/Packages/ViewOne';
import ViewCruise from '@/screens/Tours/Cruise/ViewOne';
import Activity from '@/screens/Tours/Activities/ViewOne';
import ViewAccommodation from '@/screens/Accommodations/Accommodation/ViewOne';

const ViewOneService = () => {
    const { type } = useParams();

    if (type === 'trip') {
        return (
            <ViewTrip viewFromB2b={true} />
        );
    }
    if (type === 'activity') {
        return (
            <Activity viewFromB2b={true} />
        );
    }
    if (type === 'cruise') {
        return (
            <ViewCruise viewFromB2b={true} />
        );
    } if (type === 'package') {
        return (
            <ViewPackage viewFromB2b={true} />
        );
    } else {
        return (
            <ViewAccommodation viewFromB2b={true} />
        );
    }
};
export default ViewOneService;
