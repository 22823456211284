export const extractError = (error) => {
    if (error?.response?.data) {
        if (error?.response?.data?.data && error?.response?.data?.data?.length !== 0) {
            return error?.response?.data?.data;
        } else {
            return error?.response?.data?.message;
        }
    } else {
        return error.message;
    }
};
