const model = {
    salesOverview: [],
    salesToday: [],
    total: null,
    salesByLocation: [],
    orders: [],
    b2bServices: [],
    loading: false,
    error: null,
};

export default model;
