import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import CruiseService from '@/services/TourServices/CruiseService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* viewCruise({ id }) {
    try {
        const [
            generalData,
            iteneraryData,
            cabinTypeList,
        ] = yield all([
            call(CruiseService.getOne, id),
            call(CruiseService.viewItenerary, id),
            call(CruiseService.getCabinTypeList, id),
        ]);

        const { room_types: cabinType } = cabinTypeList.data;

        yield put(actions.viewCruiseSucceeded(
            generalData.data,
            iteneraryData.data,
            cabinType,
        ));
    } catch (error) {
        yield put(actions.viewCruiseFailed(extractError(error)));
    }
}

function* fetchCruiseCabins({ id, filters, isFetchingMore }) {
    if (isFetchingMore) {
        yield put(actions.fetchMoreCruiseCabins());
    }
    try {
        const response = yield call(CruiseService.getReservationList, id, filters);
        if (isFetchingMore) {
            yield put(
                actions.fetchMoreCruiseCabinsSucceeded(response.data),
            );
            return;
        }
        yield put(actions.fetchCruiseCabinsSucceeded(response.data));
    } catch (error) {
        if (isFetchingMore) {
            yield put(actions.fetchMoreCruiseCabinsFailed(extractError(error)));
            return;
        }
        yield put(actions.fetchCruiseCabinsFailed(extractError(error)));
    }
}

function* watchViewCruise() {
    yield takeEvery(
        types.REQUEST_VIEW_CRUISE,
        viewCruise,
    );
}

function* watchFetchCruiseCabins() {
    yield takeEvery(
        types.REQUEST_FETCH_CRUISE_CABINS,
        fetchCruiseCabins,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewCruise),
        fork(watchFetchCruiseCabins),
    ]);
}

export default viewSaga;
