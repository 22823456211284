import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Space,
    Typography,
    Divider,
    Form,
    Input,
    Select,
    Checkbox,
    Row,
    Col,
    Button,
    notification,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import { TaxIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/settings/taxes/reducer';
import { selectors } from '@/redux/settings/taxes/create/model';

const AddNewTax = ({ form, isEdit, setIsOpen }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const taxDetails = useSelector(selectors.taxDetails);
    const messageSuccess = useSelector(selectors.messageSuccess);
    const messageError = useSelector(selectors.errors);

    const onFinish = useCallback((values) => {
        const data = {
            name: values.name,
            rate: values.rate,
            commission_type: values.commission_type,
            refundable: values.refundable ? 1 : 0,
        };
        dispatch(actions.requestSubmitTaxDetails(taxDetails?.id, data));
    }, [dispatch, taxDetails]);

    useEffect(() => {
        let initialData = {};
        if (taxDetails) {
            initialData = {
                name: taxDetails.name,
                rate: taxDetails.rate,
                commission_type: taxDetails.commission_type === 'Percentage' ? 1 : 2,
                refundable: taxDetails.refundable,
            };
        }
        form.setFieldsValue(initialData);
    }, [form, taxDetails]);

    useEffect(() => {
        if (messageSuccess !== '') {
            setIsOpen(false);
            notification.success({
                description: `${t(messageSuccess)} !`,
            });
        }
        if (messageError !== null) {
            notification.error({
                description: t(messageError),
            });
        }
        dispatch(actions.clearCreateTaxState());
    }, [messageSuccess, messageError, setIsOpen, dispatch, t]);

    return (
        <div className="add-new-tax">
            <Space size={20}>
                <div className="tax-icon">
                    <TaxIcon />
                </div>
                {isEdit ? (
                    <Typography.Text>{t('Edit tax')}</Typography.Text>
                ) : (
                    <Typography.Text>{t('Add new tax')}</Typography.Text>
                )}
            </Space>
            <Divider />
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
            >
                <MUIFormItem
                    label={t('Tax Name')}
                    name="name"
                >
                    <Input />
                </MUIFormItem>
                <Row gutter={30}>
                    <Col xs={8}>
                        <MUIFormItem
                            label={t('Tax Rate')}
                            name="rate"
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>
                    <Col xs={8}>
                        <MUIFormItem
                            label={t('Tax Type')}
                            name="commission_type"
                        >
                            <Select>
                                <Select.Option value={1}>{`% (${t('Percentage')})`}</Select.Option>
                                <Select.Option value={2}>{` (${t('Fixed Amount')})`}</Select.Option>
                            </Select>
                        </MUIFormItem>
                    </Col>
                    <Col xs={8}>
                        <Form.Item name="refundable" valuePropName="checked">
                            <Checkbox>
                                {t('Refundable Tax')}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    block
                    onClick={() => form.submit()}
                >
                    {t('Save').toUpperCase()}
                </Button>
            </Form>
        </div>
    );
};

export default AddNewTax;
