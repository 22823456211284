import { Row, Col, Space } from 'antd';

import Menu from '../Menu';
import Title from '../Title';
import ViewButton from '../ViewButton';

const ListDescription = ({
    title,
    location,
    size,
    price,
    url,
    duration, // from activities
    editUrl,
    deleteAction,
    type,
    registerStep,
    totalStep,
    isShared,
    agency,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    setItemName,
}) => {
    return (
        <Row justify="space-between" align="middle">
            <Col>
                <div>
                    <Title url={url} title={title} />
                </div>

                <Space size={20}>
                    <div className="price">
                        {price}
                    </div>

                    <div className="size">
                        {size}
                    </div>

                    <div className="location">
                        {location}
                    </div>

                    <div className="duration">
                        {duration}
                    </div>

                    <div className="agency">
                        {agency}
                    </div>
                </Space>
            </Col>

            <Col>
                <Space>
                    <ViewButton
                        url={url}
                        registerStep={registerStep}
                        totalStep={totalStep}
                        type={type}
                    />

                    <Menu
                        editUrl={editUrl}
                        deleteAction={deleteAction}
                        type={type}
                        isShared={isShared}
                        removeSharedServiceAction={removeSharedServiceAction}
                        removeSharedCollectedServiceAction={removeSharedCollectedServiceAction}
                        myService={myService}
                        name={title}
                        setItemName={setItemName}
                    />
                </Space>
            </Col>
        </Row>
    );
};

export default ListDescription;
