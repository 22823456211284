import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { EmptyShipListIcon } from '@/assets/images/icons/screens';

const EmptyHotelList = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    // const dispatch = useDispatch();

    // const goToCreate = useCallback(() => {
    //     dispatch(actions.goToCreateHotel());
    // }, [dispatch]);

    return (
        <div className="empty-hotel-list">
            <div>
                <EmptyShipListIcon />
            </div>
            <div>
                <span>
                    {t("Oops! Looks you haven't created yet any Hotel")}
                </span>
            </div>
            <div>
                <Button
                    className="create-button-filled"
                    onClick={() => push('/app/accommodations/hotel/add')}
                >
                    {t('Add new hotel').toUpperCase()}
                </Button>
            </div>
        </div>
    );
};

export default EmptyHotelList;
