import { all, fork, takeEvery } from 'redux-saga/effects';

import pagination from '@/helpers/pagination';
import ActivityService from '@/services/TourServices/ActivityService';
import { types } from './reducer';

function* watchFetchActivities() {
    yield takeEvery(
        types.REQUEST_FETCH_TOUR_ACTIVITIES,
        pagination('tourActivities').saga(ActivityService),
    );
}

function* activitySaga() {
    yield all([
        fork(watchFetchActivities),
    ]);
}

export default activitySaga;
