import { useTranslation } from 'react-i18next';
import { Form, Space, Button } from 'antd';
import AvatarUpload from '@/components/Upload/Avatar';
import { LogoUploadIcon } from '@/assets/images/icons/screens';

const IteneraryUpload = (props) => {
    const { t } = useTranslation();

    return (
        <Form.Item {...props}>
            <AvatarUpload
                image={<LogoUploadIcon />}
                content={(
                    <Space
                        direction="vertical"
                        className="text-content"
                    >
                        <div>
                            {t('Drag & Drop your')}
                            {' '}
                            <span className="logo">{t('Logo')}</span>
                            {' '}
                            {t('here')}
                        </div>
                        <div>{t('or')}</div>
                        <Button type="link">
                            {t('Browse').toUpperCase()}
                        </Button>
                    </Space>
                )}
                useType="for-settings"
            />
        </Form.Item>
    );
};

export default IteneraryUpload;
