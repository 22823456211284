import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get b2b reports
    requestGetB2bReports: ['filters'],
    getB2bReportsSucceeded: [
        'supplierAndVendorsData',
        'b2bSalesOverviewData',
        'invoiceList',
        'totalInvoices',
    ],
    getB2bReportsFailed: ['error'],

    // compare b2b reports
    requestCompareB2bReports: ['filters'],
    compareB2bReportsSucceeded: ['data'],
    compareB2bReportsFailed: ['error'],
});

export default createReducer(initialState, {
    // get b2b reports
    [types.REQUEST_GET_B2B_REPORTS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_B2B_REPORTS_SUCCEEDED]: (
        state,

        {
            supplierAndVendorsData,
            b2bSalesOverviewData,
            invoiceList,
            totalInvoices,
        },
    ) => ({
        ...state,
        supplierAndVendors: supplierAndVendorsData,
        b2bSalesOverview: b2bSalesOverviewData,
        invoices: invoiceList,
        totalInvoices,
        loading: false,
    }),
    [types.GET_B2B_REPORTS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // compare b2b reports
    [types.REQUEST_COMPARE_B2B_REPORTS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.COMPARE_B2B_REPORTS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        b2bReports: data,
        loading: false,
    }),
    [types.COMPARE_B2B_REPORTS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };
