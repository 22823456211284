import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Input,
    Checkbox,
    Row,
    Col,
    Card,
    Typography,
    Form,
    Radio,
    Popover,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { useCurrency } from '@/hooks/useCurrency';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';

import ServicesMenu from './ServicesMenu';
import baseValidations from './validations';

const InvoiceExtras = ({
    form,
    onFinish,
    group,
    invoiceParticipants,
    invoiceType,
    extraServices,
    clients,
    setClients,
}) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();
    const validations = useTranslatedValidationConfig(baseValidations);

    const options = useMemo(() => ([
        { label: t('%'), value: 4 },
        { label: t(currencyIcon), value: 5 },
    ]), [t]);

    return (
        <Form
            form={form}
            className="extras"
            onFinish={onFinish}
            initialValues={{ discount_type: 4 }}
        >
            {(invoiceType && invoiceParticipants.length !== 0) && (
                <>
                    <Typography.Text>
                        {`${t('Select extra services for')} ${t(group)}`}
                    </Typography.Text>

                    <Form.Item name="services">
                        <Checkbox.Group>
                            <Row gutter={[30, 25]}>
                                {extraServices?.map((service) => (
                                    <Col xxl={8} sm={12} xs={24} key={service.id}>
                                        <Popover
                                            trigger={['click']}
                                            className="extra-services-dropdown"
                                            content={(
                                                <ServicesMenu
                                                    clients={clients}
                                                    setClients={setClients}
                                                    service={service}
                                                    invoiceParticipants={invoiceParticipants}
                                                    currency={currencyIcon}
                                                />
                                            )}
                                        >
                                            <Checkbox
                                                value={service.id}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <BlockDescription name={service.name}>
                                                    <Typography.Text>
                                                        {currencyIcon.concat(service.price)}
                                                        /
                                                        {t(service.price_type)}
                                                    </Typography.Text>
                                                </BlockDescription>
                                            </Checkbox>
                                        </Popover>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </>
            )}

            <Card title={t('Offer / Discount')} className="discount-card">
                <Form.Item
                    name="discount"
                    rules={validations.discount}
                >
                    <Input
                        suffix={(
                            <Form.Item
                                name="discount_type"
                                noStyle
                            >
                                <Radio.Group
                                    options={options}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>
                        )}
                        placeholder="0.0"
                    />
                </Form.Item>
            </Card>
        </Form>
    );
};

export default InvoiceExtras;
