import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from 'antd';

import HeaderWithHelp from '@/components/HeaderWithHelp';
import { actions } from '@/redux/tour/cruise/create/reducer';
import { selectors } from '@/redux/tour/cruise/create/model';

import AddNewShip from './AddNewShip';
import EmptyShipList from './EmptyShipList';
import ShipList from './ShipList';

const question = 'What is this ?';
const answer = 'Lorem ipsum dolor sit amet...';

const ShipDetails = ({ form, isEdit, regStep }) => {
    const dispatch = useDispatch();

    const shipList = useSelector(selectors.shipList);
    const loading = useSelector(selectors.loading);
    const isCreatingShip = useSelector(selectors.isCreatingShip);

    useEffect(() => {
        dispatch(actions.requestGetCruiseShipList());
    }, [dispatch]);

    const searchForShip = (value) => {
        dispatch(actions.requestGetCruiseShipList({ name: value }));
    };

    if (loading) {
        return (
            <p>loading</p>
        );
    } else if (isCreatingShip) {
        return (
            <>
                <HeaderWithHelp
                    title="Ship Details"
                    question={question}
                    answer={answer}
                />

                <Divider />

                <AddNewShip />
            </>
        );
    } else {
        return (
            <>
                <HeaderWithHelp
                    title="Ship Details"
                    question={question}
                    answer={answer}
                />

                <Divider />

                {(shipList.length === 0) ? (
                    <EmptyShipList />
                ) : (
                    <ShipList
                        data={shipList}
                        form={form}
                        onSearch={searchForShip}
                        isEdit={isEdit}
                        regStep={regStep}
                    />
                )}
            </>
        );
    }
};

export default ShipDetails;
