import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import PackageService from '@/services/TourServices/PackageService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* viewPackage({ id }) {
    try {
        const [packageData, roomTypeList] = yield all([
            call(PackageService.getOne, id),
            call(PackageService.getRoomTypeList, id),
        ]);
        const { room_types: roomType } = roomTypeList.data;
        yield put(actions.viewPackageSucceeded(
            packageData.data,
            roomType,
        ));
    } catch (error) {
        yield put(actions.viewPackageFailed(extractError(error)));
    }
}

function* fetchPackageRooms({ id, filters, isFetchingMore }) {
    if (isFetchingMore) {
        yield put(actions.fetchMorePackageRooms());
    }
    try {
        const response = yield call(PackageService.getReservationList, id, filters);
        if (isFetchingMore) {
            yield put(
                actions.fetchMorePackageRoomsSucceeded(response.data),
            );
            return;
        }
        yield put(actions.fetchPackageRoomsSucceeded(response.data));
    } catch (error) {
        if (isFetchingMore) {
            yield put(actions.fetchMorePackageRoomsFailed(extractError(error)));
            return;
        }
        yield put(actions.fetchPackageRoomsFailed(extractError(error)));
    }
}

function* watchViewPackage() {
    yield takeEvery(
        types.REQUEST_VIEW_PACKAGE,
        viewPackage,
    );
}

function* watchFetchForPackageRooms() {
    yield takeEvery(
        types.REQUEST_FETCH_PACKAGE_ROOMS,
        fetchPackageRooms,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewPackage),
        fork(watchFetchForPackageRooms),
    ]);
}

export default viewSaga;
