import { all } from 'redux-saga/effects';
import authenticationSaga from './authentication/saga';
import dashboardSaga from './dashboard/saga';
import tourSaga from './tour/saga';
import accommodationSaga from './accommodation/saga';
import invoiceSaga from './invoice/saga';
import customersSaga from './customers/saga';
import employeesSaga from './employees/saga';
import flightsSaga from './flights/saga';
import integrationsSaga from './integrations/saga';
import b2bSaga from './b2b/saga';
import reportSaga from './reports/saga';
import settingsSaga from './settings/saga';
import maxpriceSaga from './maxprice/saga';
import userprofileSaga from './userprofile/saga';
import countrylistSaga from './countrylist/saga';

export default function* rootSaga() {
    yield all([
        authenticationSaga(),
        dashboardSaga(),
        tourSaga(),
        accommodationSaga(),
        invoiceSaga(),
        customersSaga(),
        employeesSaga(),
        flightsSaga(),
        integrationsSaga(),
        b2bSaga(),
        reportSaga(),
        settingsSaga(),
        maxpriceSaga(),
        userprofileSaga(),
        countrylistSaga(),
    ]);
}
