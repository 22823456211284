import { Row, Col, Divider } from 'antd';
import ButtonContent from '../ButtonContent';

const GridDescription = ({
    facilities,
    viewUrl,
    reservationUrl,
    isList,
    totalStep,
    registerStep,
    type,
}) => {
    return (
        <>
            <Row gutter={[40, 11]}>
                {facilities.map((facility) => (
                    <Col key={facility.id} xs={24} sm={12}>
                        {facility.name}
                    </Col>
                ))}
            </Row>
            <Divider />
            <ButtonContent
                viewUrl={viewUrl}
                reservationUrl={reservationUrl}
                isList={isList}
                totalStep={totalStep}
                registerStep={registerStep}
                type={type}
            />
        </>
    );
};

export default GridDescription;
