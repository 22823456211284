import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('flights');

const actions = {
    requestFetchFlights: baseActions.request,
    fetchFlightsSucceeded: baseActions.succeeded,
    fetchFlightsFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
