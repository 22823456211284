import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
// import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Card,
    Space,
    // Dropdown,
    // Button,
    Typography,
    Divider,
} from 'antd';
// import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { DeleteIcon /* MoreOutlinedIcon */ } from '@/assets/images/icons/screens';
import ActionIcon from '@/components/ActionIcon';
import CountryFlagComponent from '@/components/CountryFlag';
import { actions } from '@/redux/customers/delete/reducer';

import CompanyInfo from './CompanyInfo';

const PersonalDetails = ({ data }) => {
    const { t } = useTranslation();
    // const { push } = useHistory();
    const dispatch = useDispatch();

    // const editUrl = `/app/customers/${data.id}/edit`;

    // const onEdit = useCallback(() => {
    //     push(editUrl);
    // }, [push, editUrl]);

    const deleteCustomer = useCallback((id) => {
        dispatch(actions.requestDeleteCustomer(id));
    }, [dispatch]);

    return (
        <Card
            bordered={false}
            title={(
                <Space size={80}>
                    <div>
                        <Space size={10}>
                            <div className="img-wrapper">
                                <CountryFlagComponent code={data.short} />
                            </div>
                            <div>
                                <Typography.Text>
                                    {data.name}
                                    {' '}
                                    {data.surname}
                                </Typography.Text>
                                <InlineDescription
                                    label={t('Nationality')}
                                    content={data.nationality}
                                />
                            </div>
                        </Space>
                    </div>
                    {/* <Dropdown
                        trigger={['click']}
                        placement="bottom"
                        overlayClassName="edit-delete-dropdown"
                        menu={{
                            items: SimpleDropdownMenu({
                                editText: t('Edit this customer'),
                                deleteText: t('Delete this customer'),
                                onEditClick: onEdit,
                                deleteAction: actions.requestDeleteCustomer(data.id),
                            }),
                        }}
                    >
                        <Button type="text">
                            <MoreOutlinedIcon />
                        </Button>
                    </Dropdown> */}
                    <ActionIcon onClick={() => deleteCustomer(data.id)}>
                        <DeleteIcon />
                    </ActionIcon>
                </Space>
            )}
        >
            <Row gutter={[0]} className="details">
                <Col xs={24}>
                    <BlockDescription name={t('ID Number')}>
                        {data.id_number}
                    </BlockDescription>

                    <Divider />
                </Col>
                <Col xs={24}>
                    <BlockDescription name={t('Birthdate')}>
                        {data.birth}
                    </BlockDescription>

                    <Divider />
                </Col>
                <Col xs={24}>
                    <BlockDescription name={t('Gender')}>
                        {data.gender === 'M' ? 'Male' : 'Female'}
                    </BlockDescription>

                    <Divider />
                </Col>
                <Col xs={24}>
                    <BlockDescription name={t('Phone Number')}>
                        {data.phone}
                    </BlockDescription>

                    <Divider />
                </Col>
                <Col xs={24}>
                    <BlockDescription name={t('Email')}>
                        {data.email}
                    </BlockDescription>

                    <Divider />
                </Col>
                {data.company_name && (
                    <Col xs={24}>
                        <BlockDescription name={t('Client Type')}>
                            <Space size={20}>
                                {t('Company')}
                                <CompanyInfo data={data} />
                            </Space>
                        </BlockDescription>
                    </Col>
                )}
            </Row>
        </Card>
    );
};

export default PersonalDetails;
