export const isRouteSelected = (currentRoute, route) => currentRoute
    .includes(route.path);

export const concatParentPathToSubroute = (parentPath, subRoute) => ({
    ...subRoute,
    path: parentPath + subRoute.path,
});

export const cleanHash = (hash) => hash.replace('#', '');

export const getSubPageByHash = (subPages, hash) => (subPages.find(
    (subPage) => subPage.hash === cleanHash(hash),
) || subPages[0]);
