import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
    Typography,
    Button,
    Row,
    Col,
    Space,
    Dropdown,
    Divider,
} from 'antd';

import BlockDescription from '@/components/Information/WithImage/Block';
import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import { actions } from '@/redux/tour/trip/delete/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import { selectors } from '@/redux/b2b/share/model';

import {
    AdultIconBlue,
    CalendarIconBlue,
    MoreOutlinedIcon,
} from '@/assets/images/icons/screens';
import { TransferIcon } from '@/assets/images/icons/sidebar';
import TourPriceAndB2bSection from '@/components/TourPriceAndB2bSection';
import useNotification from '@/hooks/useNotification';
import decodeHtml from '@/helpers/decodeHtml';

const GeneralData = ({
    data,
    myService,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();

    const editUrl = `/app/tours/trip/${data.id}/edit`;

    const onEdit = useCallback(() => {
        push(editUrl);
    }, [push, editUrl]);

    // Message from delete action
    const messageSuccess = useSelector((state) => state.tour.trip.delete.messageSuccess);
    const error = useSelector((state) => state.tour.trip.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: data.name,
        clearAction: actions.clearDeleteTripState(),
        mode: 'view',
        pushUrl: '/app/tours/trip',
    });

    // Message from share/remove actions (share to worldwide B2B)
    const message = useSelector(selectors.message);
    const [greenButton, setGreenButton] = useState(data.share);

    useEffect(() => {
        if (message === 'Shared successfully') {
            setGreenButton(true);
        }
        if (message === 'Removed successfully') {
            setGreenButton(false);
            dispatch(shareActions.clearShareRemoveMessage());
        }
    }, [message, dispatch]);

    return (
        <div className="general-data">
            <Row gutter={[0, 10]}>
                <Col lg={14}>
                    <Typography.Text className="tour-description">
                        <div dangerouslySetInnerHTML={{ __html: decodeHtml(data.description) }} />
                    </Typography.Text>
                </Col>

                <Col lg={1}>
                    <Divider type="vertical" dashed />
                </Col>

                <Col lg={9}>
                    <Space className="price-section">
                        <TourPriceAndB2bSection
                            myService={myService}
                            data={data}
                            greenButton={greenButton}
                        />

                        {myService && (
                            <Dropdown
                                trigger={['click']}
                                placement="bottom"
                                overlayClassName={clsx(
                                    'edit-delete-dropdown',
                                    'edit-delete-dropdown-with-text',
                                )}
                                menu={{
                                    items: SimpleDropdownMenu({
                                        editText: t('Edit this trip'),
                                        deleteText: t('Delete this trip'),
                                        type: 'trip',
                                        onEditClick: onEdit,
                                        deleteAction: actions.requestDeleteTourTrip(data.id),
                                        removeSharedText: t('Remove this shared trip'),
                                        removeSharedServiceAction:
                                            shareActions.requestRemoveSharedService(
                                                data.id,
                                                { type: 1 },
                                            ),
                                        removeSharedCollectedText: t('Remove this shared collected trip'),
                                        removeSharedCollectedServiceAction:
                                            shareActions.requestRemoveSharedCollectedService(
                                                data.id,
                                                { type: 1 },
                                            ),
                                        isShared: greenButton,
                                    }),

                                }}
                            >
                                <Button type="text">
                                    <MoreOutlinedIcon />
                                </Button>
                            </Dropdown>
                        )}
                    </Space>
                </Col>
            </Row>

            <Divider />

            <Row justify="space-between" className="accomodation-block-details">
                <Col sm={6} xs={12}>
                    <BlockDescription
                        spaceSize={15}
                        icon={<CalendarIconBlue />}
                        label={t('Duration')}
                        content={`${data.duration} ${data.duration === 1 ? `${t('Day')}` : `${t('Days')}`}`}
                    />
                </Col>

                <Col sm={6} xs={12}>
                    <BlockDescription
                        spaceSize={15}
                        icon={<AdultIconBlue />}
                        label={t('Group size')}
                        content={`${data.adults} ${t('People')}`}
                    />
                </Col>

                <Col sm={6} xs={12}>
                    <BlockDescription
                        spaceSize={15}
                        icon={<TransferIcon />}
                        label={t('Style')}
                        content={data.types.map((type) => t(type.name)).join(', ')}
                    />
                </Col>

                {myService && (
                    <Col sm={6} xs={12}>
                        <BlockDescription
                            spaceSize={15}
                            icon="%"
                            label={t('Cost')}
                            content={`${data.cost} ${data.cost_type === '1' ? '%' : data.currency_icon}`}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default GeneralData;
