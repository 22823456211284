import { Switch, Route, Redirect } from 'react-router-dom';

import ViewAll from './ViewAll';
import Create from './Create';
import CreateCustomInvoice from './CreateCustomInvoice';
import ViewOne from './ViewOne';
import ViewCustomInvoice from './ViewCustomInvoice';

export const routes = [
    {
        name: 'ViewAll',
        url: '/',
        component: ViewAll,
        exact: true,
    },
    {
        name: 'Create',
        url: '/new',
        component: Create,
        exact: true,
    },
    {
        name: 'Create custom invoice',
        url: '/custom/new',
        component: CreateCustomInvoice,
        exact: true,
    },
    {
        name: 'Edit',
        url: '/:id/edit',
        component: (props) => <Create {...props} isEdit />,
        exact: true,
    },
    {
        name: 'Edit custom invoice',
        url: 'custom/:id/edit',
        component: (props) => <CreateCustomInvoice {...props} isEdit />,
        exact: true,
    },
    {
        name: 'ViewOne',
        url: '/:id',
        component: ViewOne,
        exact: true,
    },
    {
        name: 'View custom invoice',
        url: '/custom/:id',
        component: ViewCustomInvoice,
        exact: true,
    },
];

const Invoices = () => {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.name}
                    path={`/app/invoices${route.url}`}
                    exact={route.exact}
                >
                    <route.component />
                </Route>
            ))}
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Invoices;
