import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
// import Carousel from 'react-elastic-carousel';

import TourCard from '@/components/TourCard';
import { actions } from '@/redux/accommodation/dashboard/reducer';
import accommodationCards from './dashboardData';

// const breakPoints = [
//     { width: 767, itemsToShow: 1 },
//     { width: 768, itemsToShow: 2 },
//     { width: 1279, itemsToShow: 2 },
//     { width: 1280, itemsToShow: 3 },
//     { width: 1709, itemsToShow: 3 },
//     { width: 1710, itemsToShow: 4 },
// ];

const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const accCount = useSelector(
        (state) => state.accommodation.dashboard.accommodationCount,
    );

    useEffect(() => {
        dispatch(actions.requestGetAccommodationCount());
    }, [dispatch]);

    const formattedAccCards = accommodationCards.map((accCard) => {
        if (Object.keys(accCount).includes(accCard.type)) {
            return {
                ...accCard,
                count: accCount[accCard.type],
            };
        }
        return accCard;
    });

    return (
        <div className="tour-card-container">
            <Typography.Text className="first-line">
                {t('Looks like your accommodations list is empty').toUpperCase()}
            </Typography.Text>

            <Typography.Text className="sec-line">
                {`${t('You can create unlimited properties based on the following property types')}:`}
            </Typography.Text>

            <div className="tour-card-wrapper">
                <div className="tour-card-wrapper_2">
                    {formattedAccCards.map((accCard) => (
                        <div className="single-tour-card" key={accCard.id}>
                            <TourCard data={accCard} type="accommodation" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
