import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Divider,
    Table,
    Typography,
    Card,
    Avatar,
    Button,
    Pagination,
    Space,
} from 'antd';
import {
    ClockIcon,
    CloseCircleIcon,
    EyeIcon,
    ShipIcon,
    TagIcon,
    TickCircleIcon,
    TickIcon,
    CloseIcon,
    LocationIcon,
} from '@/assets/images/icons/screens';
import { TourIcon } from '@/assets/images/icons/sidebar';
import InfoBlock from '@/components/Information/WithoutImage/Block';
import BlockDescription from '@/components/Information/WithImage/Block';
import { actions } from '@/redux/b2b/request/view/reducer';
import { useCurrency } from '@/hooks/useCurrency';

const RequestDetails = ({
    page,
    setPage,
    perPage,
    setPerPage,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const serviceDetails = useSelector((state) => state.b2b.request.view.requestDetails);
    const agencyList = useSelector((state) => state.b2b.request.view.agencies);
    const totalItems = useSelector((state) => state.b2b.request.view.totalAgencies);
    const loading = useSelector((state) => state.b2b.request.view.loading);

    const columns = [
        {
            title: t('Requested by'),
            dataIndex: 'requested_by',
            sorter: true,
            width: '25%',
        },
        {
            title: t('Requested time'),
            dataIndex: 'requested_time',
            sorter: true,
            width: '25%',
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            sorter: true,
            width: '25%',
            render: (text, record) => {
                if (record.status === '1') {
                    return <p className="approved">{t('Approved')}</p>;
                } else if (record.status === '3') {
                    return <p className="canceled">{t('Canceled')}</p>;
                } else {
                    return <p className="pending">{t('Pending')}</p>;
                }
            },
        },
        {
            title: '',
            dataIndex: '',
            width: '25%',
            render: (text, record) => {
                if (record.status !== '2') {
                    return (
                        null
                    );
                } else {
                    return (
                        <Space>
                            <Button
                                className="approve-btn"
                                onClick={() => dispatch(actions.approveOrCancelRequest(
                                    {
                                        status: 1,
                                        subscribed_service_id: record.id,
                                    },
                                ))}
                            >
                                {t('Approve').toUpperCase()}
                                <span className="svg-wrapper">
                                    <TickIcon />
                                </span>
                            </Button>
                            <Button
                                className="cancel-btn"
                                onClick={() => dispatch(actions.approveOrCancelRequest(
                                    {
                                        status: 3,
                                        subscribed_service_id: record.id,
                                    },
                                ))}
                            >
                                {t('Cancel').toUpperCase()}
                                <span className="svg-wrapper">
                                    <CloseIcon />
                                </span>
                            </Button>
                        </Space>
                    );
                }
            },
        },
    ];

    const datasource = agencyList?.map((row, i) => ({
        key: i,
        id: row.id,
        requested_by:
    <BlockDescription
        icon={<Avatar size={40} src={row.agency_icon} />}
        label={row.agency_name}
        content={row.agency_country}
    />,
        requested_time:
    <InfoBlock name={row.requested_date?.slice(0, 11)} className="textSize14">
        <span className="textSize12">{row.requested_date?.slice(12)}</span>
    </InfoBlock>,
        status: row.status_id,
    }));

    return serviceDetails === null || loading ? (
        'loading'
    ) : (
        <div>
            <Card bordered={false}>
                <Card.Meta
                    avatar={(
                        <div>
                            <div className="tour-type">
                                {(serviceDetails.category === '1' || serviceDetails.category === '2') && <TourIcon />}
                                {serviceDetails.category === '3' && <ShipIcon />}
                            </div>
                            <Avatar shape="square" src={serviceDetails.image_path} />
                        </div>
                    )}
                    description={(
                        <Row>
                            <Col xs={16}>
                                <div className="title">
                                    {serviceDetails.service_name}
                                </div>
                                <Typography.Paragraph>
                                    <LocationIcon />
                                    {serviceDetails.location}
                                </Typography.Paragraph>
                                <Typography.Paragraph>
                                    <TagIcon />
                                    {`${currencyIcon} ${serviceDetails.price}
                                    / ${serviceDetails.duration} ${serviceDetails.duration_type}`}
                                </Typography.Paragraph>
                                <div className="commission">
                                    <CloseCircleIcon />
                                    {serviceDetails.commission_type === 'Percentage'
                                        ? `${serviceDetails.commission}% ${t('Commission').toLowerCase()}`
                                        : `${serviceDetails.commission} ${serviceDetails.currencyIcon} ${t('commission')}`}
                                </div>
                            </Col>
                            <Col xs={1}><Divider dashed type="vertical" /></Col>
                            <Col xs={7}>
                                <div className="request-nr">
                                    {`${serviceDetails.total_requests} ${t('requests')}`}
                                </div>
                                <Typography.Paragraph>
                                    <TickCircleIcon />
                                    {`${serviceDetails.approved_requests} ${t('Approved').toLowerCase()}`}
                                </Typography.Paragraph>
                                <Typography.Paragraph>
                                    <ClockIcon />
                                    {`${serviceDetails.pending_requests} ${t('Pending').toLowerCase()}`}
                                </Typography.Paragraph>
                                <Typography.Paragraph>
                                    <CloseCircleIcon />
                                    {`${serviceDetails.canceled_requests} ${t('Canceled').toLowerCase()}`}
                                </Typography.Paragraph>
                                <Typography.Paragraph className="views">
                                    <EyeIcon />
                                    {`${150} ${t('views')}`}
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                    )}
                />
            </Card>
            <Divider />
            <Table
                columns={columns}
                dataSource={datasource}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
            />
            <div className="request-pagination">
                <Pagination
                    size="small"
                    current={page}
                    pageSize={perPage}
                    total={totalItems}
                    onChange={(current, pageSize) => {
                        setPage(current);
                        setPerPage(pageSize);
                    }}
                />
            </div>
        </div>
    );
};

export default RequestDetails;
