import { createReducer } from 'reduxsauce';
import initialState from './model';

import paginationReduxHandlers, {
    types as paginationTypes,
    actions as paginationActions,
} from './pagination/reducer';

import createReduxHandlers, {
    types as createTypes,
    actions as createActions,
} from './create/reducer';

import deleteReduxHandlers, {
    types as deleteTypes,
    actions as deleteActions,
} from './delete/reducer';

import viewReduxHandlers, {
    types as viewTypes,
    actions as viewActions,
} from './view/reducer';

import createcustomReduxHandlers, {
    types as createcustomTypes,
    actions as createcustomActions,
} from './createcustom/reducer';

const types = {
    ...paginationTypes,
    ...createTypes,
    ...deleteTypes,
    ...viewTypes,
    ...createcustomTypes,
};

const actions = {
    ...paginationActions,
    ...createActions,
    ...deleteActions,
    ...viewActions,
    ...createcustomActions,
};

export { types, actions };

export default createReducer(initialState, {
    ...paginationReduxHandlers,
    ...createReduxHandlers,
    ...deleteReduxHandlers,
    ...viewReduxHandlers,
    ...createcustomReduxHandlers,
});
