import { useTranslation } from 'react-i18next';
import { Typography, Space } from 'antd';

const ContactDetails = ({ data }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="tour-info-section-title">
                {t('Contact Details')}
            </div>

            <div className="person">
                <div className="contact-name">
                    {data.first_name}
                    {' '}
                    {data.last_name}
                </div>
                <div className="contact-position">{data.position}</div>
            </div>

            <Space size={1} direction="vertical" className="contact-info">
                <Typography.Text>
                    {t('Email')}
                    :
                    {' '}
                    {data.email}
                </Typography.Text>

                <Typography.Text>
                    {t('Phone')}
                    :
                    {' '}
                    {data.phone}
                </Typography.Text>

                <Typography.Text>
                    {t('Website')}
                    :
                    {' '}
                    {data.website}
                </Typography.Text>
            </Space>
        </>
    );
};

export default ContactDetails;
