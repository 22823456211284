import { useTranslation } from 'react-i18next';
import {
    Layout, Row, Col, Space, Divider,
} from 'antd';
import clsx from 'clsx';
import LanguageSelector from '@/components/LanguageSelector';
import Notifications from '@/components/TopbarNotifications';
import UserMenu from '@/components/TopbarUserMenu';
import { HelpIcon, SettingsIcon } from '@/assets/images/icons/topbar';

import Toggle from './Toggle';
import Menu from './Menu';

const MobileTopbar = ({ currentRoute, isCollapsed, setIsCollapsed }) => {
    const { t } = useTranslation();

    return (
        <div className={`mobile-topbar-wrapper ${!isCollapsed ? 'mobile-topbar-wrapper-showed' : ''}`}>
            <Layout.Header className="mobile-topbar">
                <Row justify="space-between">
                    <Col>
                        <Toggle
                            isCollapsed={isCollapsed}
                            setIsCollapsed={setIsCollapsed}
                        />
                    </Col>
                    <Col
                        className={clsx(
                            'help-section',
                            !isCollapsed && 'show-help-section',
                        )}
                    >
                        <Space size={15}>
                            <HelpIcon />
                            <span>{t('Help')}</span>
                        </Space>
                    </Col>
                    <Col>
                        <Space className="general-menu" size={15}>
                            <LanguageSelector />
                            <Divider type="vertical" />
                            <Notifications />
                            <SettingsIcon />
                            <UserMenu />
                        </Space>
                    </Col>
                </Row>
            </Layout.Header>
            <Menu
                currentRoute={currentRoute}
                setIsCollapsed={setIsCollapsed}
                isCollapsed={isCollapsed}
            />
        </div>
    );
};

export default MobileTopbar;
