import {
    useCallback,
    useEffect,
    useState,
    useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import { PlusIcon } from '@/assets/images/icons/screens';
import { extractUrlFromFile } from '@/helpers/upload';
import { returnDeletedImages } from '@/helpers/deleteImages';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import { actions } from '@/redux/tour/cruise/reducer';
import { selectors } from '@/redux/tour/cruise/create/model';
import ShipItem from './ShipItem';
import baseValidations from './validations';

const returnInitialImages = (id, initialCabins) => {
    const cabin = initialCabins?.find((item) => item.id === id);
    return cabin?.images;
};

const formatCabinsForSubmit = (cabins, initialCabins) => cabins
    .map((cabin) => ({
        id: cabin.id ? cabin.id : null,
        type: cabin.cabin_type,
        number_of_cabins: cabin.number_of_cabins,
        amenities: cabin.amenities,

        adults_no: cabin.number_of_adults,
        price: cabin.price_of_adults,
        price_type: cabin.pricingType,

        max_children: cabin.number_of_children,
        child_price: cabin.price_of_children,

        max_infants: cabin.number_of_infants,
        infant_price: cabin.price_of_infants,

        images: cabin.images
            ? cabin.images.fileList?.map(extractUrlFromFile)
            : [],
        deleted_images: returnDeletedImages(
            returnInitialImages(cabin.id, initialCabins), cabin.images?.fileList,
        ),
    }));

// format cabins data before edit
const formatCabinsData = (cabinTypes) => cabinTypes
    .map((cabin) => ({
        id: cabin.id,
        cabin_type: cabin.type,
        number_of_cabins: cabin.number_of_cabins,
        amenities: cabin.amenities ? cabin.amenities.selected.map((item) => (
            item.id
        )) : [],

        number_of_adults: cabin.adults_no,
        price_of_adults: cabin.price,
        pricingType: (cabin.price_type === 'Per Person') ? 1 : 6,

        number_of_children: cabin.max_children,
        price_of_children: cabin.child_price,

        number_of_infants: cabin.max_infants,
        price_of_infants: cabin.infant_price,
        images: cabin.images.map((image) => (
            {
                id: image.id,
                url: image.path,
                thumbUrl: image.path,
            }
        )),
    }));

const AddNewShip = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const validations = useTranslatedValidationConfig(baseValidations);

    const [itemIndex, setItemIndex] = useState(0);

    const shipData = useSelector(selectors.ship);
    const cabins = shipData?.cabin_types;

    const goToShipList = useCallback(() => {
        dispatch(actions.goToCruiseViewShips());
    }, [dispatch]);

    const onFinish = useCallback((values) => {
        const data = {
            name: values.name,
            cabin_types: values.cabins
                ? formatCabinsForSubmit(values.cabins, cabins)
                : [],
        };
        dispatch(actions.requestCreateCruiseShip(data));
    }, [dispatch]);

    const initialData = useMemo(() => {
        if (shipData) {
            return {
                name: shipData.name,
                cabins: shipData.cabin_types
                    ? formatCabinsData(shipData.cabin_types)
                    : [],
            };
        } else {
            return {
                cabins: [{
                    pricingType: 1,
                }],
            };
        }
    }, [shipData]);

    useEffect(() => {
        if (cabins !== undefined) {
            setItemIndex(cabins?.length - 1);
        }
    }, [cabins]);

    const formFields = form.getFieldsValue();

    return (
        <Form
            id="cruise-itenerary-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={initialData || {}}
        >
            <Form.Item className="add-ship-name">
                <MUIFormItem
                    label={t('Ship Name')}
                    name="name"
                >
                    <Input />
                </MUIFormItem>
            </Form.Item>

            <Form.Item>
                <Form.List name="cabins">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <ShipItem
                                    key={field.key}
                                    field={field}
                                    remove={remove}
                                    formListName="cabins"
                                    initialAmenities={cabins?.[field.name]?.amenities.selected}
                                    itemIndex={itemIndex}
                                    formFields={formFields}
                                    childN={initialData?.cabins[field.name]?.number_of_children}
                                    infantN={initialData?.cabins[field.name]?.number_of_infants}
                                    validations={validations}
                                />
                            ))}

                            <Row justify="end" align="middle">
                                <Col>
                                    <Form.Item>
                                        <Button
                                            className="button add-button-outlined"
                                            onClick={() => {
                                                add();
                                                setItemIndex(itemIndex + 1);
                                            }}
                                        >
                                            <PlusIcon />
                                            {t('Add new cabin type').toUpperCase()}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            </Form.Item>

            <Form.Item>
                <Row justify="end" className="submit-form">
                    <Col>
                        <Button
                            danger
                            type="primary"
                            onClick={goToShipList}
                        >
                            {t('Cancel')}
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            {t('Submit')}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default AddNewShip;
