import pagination from '@/helpers/pagination';

const {
    actions: baseActions,
    types,
    reducer,
} = pagination('tourActivities');

const actions = {
    requestFetchTourActivities: baseActions.request,
    fetchTourActivitiesSucceeded: baseActions.succeeded,
    fetchTourActivitiesFailed: baseActions.failed,
};

export { types, actions };

export default reducer;
