import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get tour count
    requestGetTourCount: [],
    getTourCountSucceeded: ['data'],
    getTourCountFailed: ['errors'],
});

export default createReducer(initialState, {
    [types.REQUEST_GET_TOUR_COUNT]: (state) => ({
        ...state,
        loading: true,
        errors: null,
    }),
    [types.GET_TOUR_COUNT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        tourCount: data,
        loading: false,
    }),
    [types.GET_TOUR_COUNT_FAILED]: (state, { errors }) => ({
        ...state,
        loading: false,
        errors,
    }),
});

export { types, actions };
