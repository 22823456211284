import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { selectors } from '@/redux/tour/cruise/view/model';
import Filters from '@/components/RerservationFilters';
import ReservationList from '@/components/ReservationList';

const Reservation = ({ fetchMore }) => {
    const cabinList = useSelector(selectors.cabinList);
    const { id } = useSelector(selectors.generalInfo);
    const cabinTypeList = useSelector(selectors.cabinTypeList);
    const iteneraryList = useSelector(selectors.itenerary);
    const isLoadingMore = useSelector(selectors.isLoadingMore);
    const hasMore = useSelector(selectors.hasMore);

    const startDates = [];
    const endDates = [];

    // const currentDate = new Date();

    iteneraryList.forEach((item) => {
        const departureTime = new Date(item.departure_time);
        const arrivalTime = new Date(item.arrival_time);

        const start = `${item.departure_place}-${item.arrival_place} - ${departureTime.toISOString().split('T')[0]}`;
        startDates.push(start);

        const end = `${item.departure_place}-${item.arrival_place} - ${arrivalTime.toISOString().split('T')[0]}`;
        endDates.push(end);
    });

    const [cabinListSelected, setCabinListSelected] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    return (
        <>
            <Filters
                type="Tour Cruise"
                cabinTypeList={cabinTypeList}
                iteneraryStartDates={startDates}
                iteneraryEndDates={endDates}
                setCruiseStartDate={setStartDate}
                setCruiseEndDate={setEndDate}
            />
            <ReservationList
                roomListSelected={cabinListSelected}
                setRoomListSelected={setCabinListSelected}
                cruiseStartDate={startDate?.slice(-10)}
                cruiseEndDate={endDate?.slice(-10)}
                reservationList={cabinList}
                type="Tour Cruise"
                tourId={id}
            />
            {!isLoadingMore && hasMore && (
                <Waypoint onEnter={fetchMore} />
            )}
        </>
    );
};

export default Reservation;
