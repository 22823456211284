import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Divider,
    Select,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import Loading from '@/components/Loading';
import { actions } from '@/redux/settings/general/reducer';
import CountrySelect from '@/components/CountrySelect';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import useAgencyCurrency from '@/hooks/useAgencyCurrency';
import useNotification from '@/hooks/useNotification';

import Upload from './Upload';
import baseValidations from './validations';

const formFields = [
    'name',
    'address',
    'vat_number',
    'city',
    'company_size',
    'state',
    'phone',
    'country',
    'company_email',
    'timezone',
    'website',
    'zip_code',
    'icon',
    'currency',
];

const getImageName = (url) => {
    if (url.startsWith('https://')) {
        const splittedUrl = url.split('/');
        const imageName = splittedUrl[splittedUrl.length - 1];
        console.log(imageName);
        return [imageName];
    } else {
        return [url];
    }
};

const General = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const validations = useTranslatedValidationConfig(baseValidations);

    const { data, isValidating } = useAgencyCurrency();

    const agencyDetails = useSelector((state) => state.settings.general.agencyDetails);
    const errors = useSelector((state) => state.settings.general.errors);
    const messageSuccess = useSelector((state) => state.settings.general.success);

    useNotification({
        messageSuccess,
        error: null,
        clearAction: actions.clearMessageSuccess(),
    });

    const onFinish = useCallback((values) => {
        const agencyData = {
            name: values.name,
            address: values.address,
            vat_number: values.vat_number,
            city: values.city,
            company_size: values.company_size,
            state: values.state,
            phone: values.phone,
            country: values.country?.value,
            company_email: values.company_email,
            timezone: values.timezone,
            website: values.website,
            zip_code: values.zip_code,
            currency: values.currency,
            icon: values.icon[0]?.url ? getImageName(values.icon[0].url) : [null],
            deleted_images: !values.icon?.[0]?.url
                ? 1
                : undefined,
        };
        dispatch(actions.requestSubmitAgencyDetails(agencyData));
    }, [dispatch]);

    useEffect(() => {
        dispatch(actions.requestGetAgencyDetails());
    }, [dispatch]);

    const initialData = useMemo(() => {
        if (agencyDetails) {
            return {
                name: agencyDetails.name,
                address: agencyDetails.address,
                vat_number: agencyDetails.vat_number,
                city: agencyDetails.city,
                company_size: agencyDetails.company_size,
                state: agencyDetails.state,
                phone: agencyDetails.phone,
                country: { value: agencyDetails.country_id },
                company_email: agencyDetails.company_email,
                timezone: agencyDetails.timezone,
                website: agencyDetails.website,
                zip_code: agencyDetails.zip_code,
                currency: parseInt(agencyDetails.currency, 10),
                icon: [{ url: agencyDetails.icon, thumbUrl: agencyDetails.icon }],
            };
        } else {
            return null;
        }
    }, [agencyDetails]);

    useApiValidationsForForm({ form, errors, formFields });

    if (!initialData) {
        return <Loading />;
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            className="edit-agency-details-form"
            layout="vertical"
            requiredMark={false}
            initialValues={initialData}
        >
            <Row gutter={[30, 30]}>
                <Col md={6} xs={24}>
                    <div className="upload-img">
                        <Upload name="icon" />
                    </div>
                </Col>
                <Col md={18} xs={24}>
                    <div className="agency-details">
                        <Row gutter={30}>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="name"
                                    label={t('Agency / Company Name')}
                                    rules={validations.name}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="address"
                                    label={t('Street Address')}
                                    rules={validations.address}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="vat_number"
                                    label={t('Registration Number (VAT)')}
                                    rules={validations.vat_number}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="city"
                                    label={t('City')}
                                    rules={validations.city}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="company_size"
                                    label={t('Company Size')}
                                    rules={validations.company_size}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="state"
                                    label={t('State / Province')}
                                    rules={validations.state}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="phone"
                                    label={t('Company Phone')}
                                    rules={validations.phone}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="country"
                                    label={t('Country')}
                                    rules={validations.country}
                                >
                                    <CountrySelect />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="company_email"
                                    label={t('Company Email')}
                                    rules={validations.company_email}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="timezone"
                                    label={t('Timezone')}
                                    rules={validations.timezone}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="website"
                                    label={t('Website')}
                                    rules={validations.website}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="zip_code"
                                    label={t('Zip Code')}
                                    rules={validations.zip_code}
                                >
                                    <Input />
                                </MUIFormItem>
                            </Col>
                            <Col md={12} xs={24}>
                                <MUIFormItem
                                    name="currency"
                                    label={t('Currency')}
                                    rules={validations.currency}
                                >
                                    <Select loading={isValidating}>
                                        {data?.data?.map((item) => (
                                            <Select.Option
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </MUIFormItem>
                            </Col>
                        </Row>
                        <Divider />
                        <Row justify="end">
                            <Col md={6} xs={24}>
                                <Button
                                    block
                                    className="save-btn"
                                    onClick={() => form.submit()}
                                >
                                    {t('Save').toUpperCase()}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default General;
