import { useTranslation } from 'react-i18next';
import LinkButton from '@/components/LinkButton';
import { ComingSoonIcon } from '@/assets/images/icons/screens';
import { HelpIcon } from '@/assets/images/icons/topbar';

const ComingSoon = () => {
    const { t } = useTranslation();
    return (
        <div className="coming-soon-content">
            <ComingSoonIcon className="coming-soon-svg" />

            <div className="text">
                {`${t('Something amazing is in the works! Check back soon for exciting new features, or get in touch with our team to learn more')}.`}
            </div>

            <LinkButton to="" className="help-button">
                {t('contact us for more info').toUpperCase()}
                <HelpIcon />
            </LinkButton>
        </div>
    );
};

export default ComingSoon;
