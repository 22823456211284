import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
    Row,
    Col,
    Space,
    Button,
    Form,
    Typography,
} from 'antd';

import { actions } from '@/redux/invoice/createcustom/reducer';
import { selectors } from '@/redux/invoice/createcustom/model';
import CustomInvoicePreview from '@/components/Invoice/CustomInvoicePreview';
import LinkButton from '@/components/LinkButton';
import { ArrowLeftIcon, ArrowRightIcon } from '@/assets/images/icons/screens';

import BilledFor from './BilledFor';
import InvoiceTo from './InvoiceTo';
import Extras from './Extras';
import StepDisplay from './StepDisplay';

const steps = [BilledFor, InvoiceTo, Extras];

const getStep = (step) => steps[step] || steps[0];

const CreateCustomInvoice = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { push } = useHistory();

    const { id: urlId } = useParams();
    const reduxId = useSelector((state) => state.invoice.createcustom.invoiceTo?.invoice_id);

    const step = useSelector((state) => state.invoice.createcustom.step);

    const id = useMemo(() => {
        return isEdit ? urlId : reduxId;
    }, [isEdit, urlId, reduxId]);

    const submitting = useSelector(
        (state) => state.invoice.createcustom.submitting,
    );

    const goToStep = useCallback((s) => {
        dispatch(actions.goToCreateCustomInvoiceStep(s));
    }, [dispatch]);

    const FormStep = useMemo(() => getStep(step), [step]);

    const invoiceDetails = useSelector(selectors.billedFor);
    const invoiceCustomer = useSelector(selectors.invoiceTo);

    useEffect(() => {
        if (isEdit && id) {
            dispatch(actions.requestFetchCustomInvoiceData(id));
        }
    }, [dispatch, isEdit, id]);

    useEffect(() => {
        if (step === steps.length) {
            push('/app/invoices');
        }
    }, [step, push, id]);

    return (
        <div className="invoice">
            <Row>
                <Col>
                    <Space size="middle">
                        <LinkButton
                            className="go-back"
                            to={
                                id !== undefined
                                    ? `/app/invoices/custom/${id}`
                                    : '/app/invoices'
                            }
                        >
                            <ArrowLeftIcon />
                        </LinkButton>

                        <Typography.Text>
                            {t('Back')}
                        </Typography.Text>
                    </Space>
                </Col>
            </Row>

            <StepDisplay step={step} />

            <Row
                className="form"
                gutter={3}
            >
                <Col lg={14} xs={24}>
                    <div className="form-step">
                        <FormStep
                            form={form}
                            invoiceId={id}
                        />
                    </div>
                </Col>

                <Col lg={10} xs={24}>
                    <div className="form-preview">
                        <CustomInvoicePreview
                            createMode={isEdit === false}
                            invoiceDetails={invoiceDetails}
                            invoiceCustomer={invoiceCustomer}
                        />
                    </div>
                </Col>
            </Row>

            <Row
                className="form-button"
                justify="end"
            >
                <Col>
                    <Space size={20}>
                        {((step === 1 && id === undefined) || (step === 2)) && (
                            <Button onClick={() => goToStep(step - 1)}>
                                <Space size={25}>
                                    <ArrowLeftIcon />
                                    {t('Back').toUpperCase()}
                                </Space>
                            </Button>
                        )}

                        <Button
                            type="primary"
                            loading={submitting}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            <Space size={25}>
                                {step === 2 ? (
                                    t('Save invoice').toUpperCase()
                                ) : (
                                    t('Save and go to next step').toUpperCase()
                                )}
                                <ArrowRightIcon />
                            </Space>
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export default CreateCustomInvoice;
