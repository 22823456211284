import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Typography,
    Select,
    Input,
    Form,
    Button,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import CountrySelect from '@/components/CountrySelect';
import validateFormCompletionOrder from '@/helpers/validateFormCompletionOrder';
import { actions } from '@/redux/b2b/partner/reducer';
import { ArrowRightIcon } from '@/assets/images/icons/screens';

const companySizeOptions = new Array(4).fill(null).map((_, i) => ({
    label: `${(i * 5) + 1} - ${(i * 5) + 5}`,
    value: i + 1,
}));

const allFields = [
    'name',
    'vat_number',
    'company_size',
    'website',
    'address',
    'city',
    'zip_code',
    'state',
    'country',
];

const AgencyInfo = ({
    formStep2,
    validations,
    submitting,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onFinish = useCallback((values) => {
        const data = {
            name: values.name,
            vat_number: values.vat_number,
            company_size: values.company_size,
            website: values.website,
            address: values.address,
            city: values.city,
            zip_code: values.zip_code,
            state: values.state,
            country: values.country?.value,
            // commission: 0,
        };
        dispatch(actions.requestSubmitAgencyInfo(data));
    }, [dispatch]);

    return (
        <Form
            form={formStep2}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            onFieldsChange={(changedField) => {
                validateFormCompletionOrder({
                    form: formStep2, fields: allFields, changedField,
                });
            }}
        >
            <div className="agency-info">
                <Typography.Text className="step-title">{t('Create Agency Info')}</Typography.Text>
                <div className="step-subtitle">{`${t('All follows fields are required')}!`}</div>

                <MUIFormItem
                    label={t('Agency Name')}
                    name="name"
                    rules={validations.name}
                    validateFirst
                    hasFeedback
                >
                    <Input />
                </MUIFormItem>

                <Row gutter={30}>
                    <Col lg={12} xs={24}>
                        <MUIFormItem
                            label={t('Register Number (VAT)')}
                            name="vat_number"
                            validateFirst
                            rules={validations.vat_number}
                            hasFeedback
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>
                    <Col lg={12} xs={24}>
                        <MUIFormItem
                            label={t('Company Size')}
                            name="company_size"
                            validateFirst
                            rules={validations.companySize}
                            hasFeedback
                        >
                            <Select>
                                {companySizeOptions.map((size) => (
                                    <Select.Option key={size.value} value={size.value}>
                                        {size.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </MUIFormItem>
                    </Col>
                </Row>

                <MUIFormItem
                    label={t('Website')}
                    name="website"
                    validateFirst
                    rules={validations.website}
                    hasFeedback
                >
                    <Input />
                </MUIFormItem>

                <MUIFormItem
                    label={t('Street Name')}
                    name="address"
                    validateFirst
                    rules={validations.address}
                    hasFeedback
                >
                    <Input />
                </MUIFormItem>

                <Row gutter={30}>
                    <Col lg={12} xs={24}>
                        <MUIFormItem
                            label={t('City')}
                            name="city"
                            validateFirst
                            rules={validations.city}
                            hasFeedback
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <MUIFormItem
                            label={t('Zip / Postal Code')}
                            name="zip_code"
                            validateFirst
                            rules={validations.zip_code}
                            hasFeedback
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <MUIFormItem
                            label={t('State / Province')}
                            name="state"
                            validateFirst
                            rules={validations.state}
                            hasFeedback
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col lg={12} xs={24}>
                        <MUIFormItem
                            label={t('Country')}
                            name="country"
                            rules={validations.country}
                            validateFirst
                            hasFeedback
                        >
                            <CountrySelect />
                        </MUIFormItem>
                    </Col>
                </Row>
                <Button
                    block
                    className="save-vendor-btn"
                    loading={submitting}
                    onClick={() => formStep2.submit()}
                >
                    <div>
                        {t('next step').toUpperCase()}
                        <ArrowRightIcon />
                    </div>
                </Button>
            </div>
        </Form>
    );
};

export default AgencyInfo;
