import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import RequestService from '@/services/B2BPartnerServices/RequestService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

let total = 0;

function* viewRequestDetails({ id, filters }) {
    try {
        const response = yield call(RequestService.getOne, id, filters);
        const {
            service_details: requestDetails,
            agency_list: agencyData,
        } = response.data;
        const {
            list: agencyList,
            total: totalItems,
        } = agencyData;
        const totalAgencies = agencyData.total !== undefined ? totalItems : total;
        total = totalAgencies;
        yield put(actions.viewRequestDetailsSucceeded(
            requestDetails,
            agencyList,
            totalAgencies,
        ));
    } catch (error) {
        yield put(actions.viewRequestDetailsFailed(extractError(error)));
    }
}

function* approveOrCancelRequest({ data }) {
    try {
        yield call(RequestService.approveOrCancelRequest, data);
        yield put(actions.approveOrCancelRequestSucceeded('Success'));
    } catch (error) {
        yield put(actions.approveOrCancelRequestFailed(extractError(error)));
    }
}

function* watchViewRequestDetails() {
    yield takeEvery(
        types.VIEW_REQUEST_DETAILS,
        viewRequestDetails,
    );
}

function* watchApproveOrCancelRequest() {
    yield takeEvery(
        types.APPROVE_OR_CANCEL_REQUEST,
        approveOrCancelRequest,
    );
}

function* viewSaga() {
    yield all([
        fork(watchViewRequestDetails),
        fork(watchApproveOrCancelRequest),
    ]);
}

export default viewSaga;
