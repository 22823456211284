import { createActions } from 'reduxsauce';
import { removeSelectedItem } from '@/helpers/CRUD';

export const { Types: types, Creators: actions } = createActions({
    requestDeleteAccommodation: ['id', 'accommodationType'],
    deleteAccommodationSucceeded: ['id', 'messageSuccess'],
    deleteAccommodationFailed: ['error'],

    // clear delete accommodation state
    clearDeleteAccommodationState: null,
});

export default {
    [types.REQUEST_DELETE_ACCOMMODATION]: (state, { id }) => ({
        ...state,
        delete: {
            ...state.delete,
            id,
            loading: true,
            error: null,
        },
    }),
    [types.DELETE_ACCOMMODATION_SUCCEEDED]: (state, { id, messageSuccess }) => ({
        ...state,
        data: removeSelectedItem(state.data, id),
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            messageSuccess,
        },
    }),
    [types.DELETE_ACCOMMODATION_FAILED]: (state, { error }) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error,
        },
    }),

    // clear delete accommodation state
    [types.CLEAR_DELETE_ACCOMMODATION_STATE]: (state) => ({
        ...state,
        delete: {
            ...state.delete,
            id: null,
            loading: false,
            error: null,
            messageSuccess: '',
        },
    }),
};
