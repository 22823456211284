import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
    requestRegisterBaseUser: ['data'],
    registerBaseUserSucceeded: ['data'],
    registerBaseUserFailed: ['errors'],
});

export default {
    [types.REQUEST_REGISTER_BASE_USER]: (state) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: true,
            errors: null,
        },
    }),
    [types.REGISTER_BASE_USER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        authenticated: true,
        user: data,
        registration: {
            ...state.registration,
            submitting: false,
            registerStep: 1,
        },
    }),
    [types.REGISTER_BASE_USER_FAILED]: (state, { errors }) => ({
        ...state,
        registration: {
            ...state.registration,
            submitting: false,
            errors,
        },
    }),
};

export { types, actions };
