import { createReducer } from 'reduxsauce';
import initialState from './model';

import paginationReduxHandlers, {
    types as paginationTypes,
    actions as paginationActions,
} from './pagination/reducer';

import viewReduxHandlers, {
    types as viewTypes,
    actions as viewActions,
} from './view/reducer';

const types = {
    ...paginationTypes,
    ...viewTypes,
};

const actions = {
    ...paginationActions,
    ...viewActions,
};

export { types, actions };

export default createReducer(initialState, {
    ...paginationReduxHandlers,
    ...viewReduxHandlers,
});
