import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    Select,
    Divider,
    Space,
    Button,
    Typography,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import { selectors } from '@/redux/accommodation/hotel/create/model';
import { actions } from '@/redux/accommodation/hotel/create/reducer';

const Amenities = ({ hotelId, initialAmenities }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loading = useSelector(selectors.loadingAmenity);

    // on edit
    const roomDetails = useSelector(selectors.roomDetails);
    const selectedAmenities = roomDetails?.amenities.selected;

    // on create
    const amenities = useSelector(selectors.amenitiesList);
    const amenitiesToSelect = amenities?.slice(0, 6);

    // final data
    const [mergedAmenities, setMergedAmenities] = useState(roomDetails !== null
        ? selectedAmenities
        : amenitiesToSelect);

    const onSelect = useCallback((value) => {
        const option = {
            id: value.value,
            name: value.label,
        };
        if (mergedAmenities.some((el) => el.id === option.id)) {
            setMergedAmenities(mergedAmenities);
        } else {
            setMergedAmenities((prev) => [...prev, option]);
        }
    }, [mergedAmenities]);

    // add new amenity
    const [amenityName, setAmenityName] = useState('');

    const addItem = useCallback(() => {
        dispatch(actions.requestAddNewAmenity(
            hotelId,
            {
                name: amenityName,
            },
        ));
        setAmenityName('');
    }, [amenityName, dispatch, hotelId]);

    // search amenity
    const onSearch = useCallback((search) => {
        if (search && search.length === 3) {
            dispatch(actions.requestGetAmenitiesList({
                name: search,
            }));
        }
        if (search.length === 0) {
            dispatch(actions.requestGetAmenitiesList());
        }
    }, [dispatch]);

    return (
        <Row gutter={[28, 0]}>
            <Col span={24}>
                <MUIFormItem name="searchAmenity">
                    <Select
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        labelInValue
                        onSelect={onSelect}
                        onSearch={onSearch}
                        loading={loading}
                        popupClassName="tour-style-select"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider />
                                <Space
                                    align="center"
                                >
                                    <Input
                                        placeholder="Name"
                                        value={amenityName}
                                        onChange={(e) => setAmenityName(e.target.value)}
                                    />
                                    <Button type="primary" onClick={addItem}>
                                        <Typography.Text>
                                            {t('Add')}
                                        </Typography.Text>
                                    </Button>
                                </Space>
                            </>
                        )}
                    >
                        {amenities?.map((amenity) => (
                            <Select.Option key={amenity.id} value={amenity.id}>
                                {amenity.name}
                            </Select.Option>
                        ))}
                    </Select>
                </MUIFormItem>
                <Form.Item name="amenities">
                    <CheckboxMultiSelect
                        options={mergedAmenities}
                        labelParamName="name"
                        valueParamName="id"
                        hasSelectAll={false}
                        initialCheckedList={initialAmenities || []}
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default Amenities;
