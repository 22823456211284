import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
// import Carousel from 'react-elastic-carousel';

import TourCard from '@/components/TourCard';
import { actions } from '@/redux/tour/dashboard/reducer';
import tourCards from './dashboardData';

// const breakPoints = [
//     { width: 767, itemsToShow: 1 },
//     { width: 768, itemsToShow: 2 },
//     { width: 1279, itemsToShow: 2 },
//     { width: 1280, itemsToShow: 3 },
//     { width: 1709, itemsToShow: 3 },
//     { width: 1710, itemsToShow: 4 },
// ];

const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const tourCount = useSelector((state) => state.tour.dashboard.tourCount);

    useEffect(() => {
        dispatch(actions.requestGetTourCount());
    }, [dispatch]);

    const formattedTourCards = tourCards.map((tourCard) => {
        if (Object.keys(tourCount).includes(tourCard.type)) {
            return {
                ...tourCard,
                count: tourCount[tourCard.type],
            };
        }
        return tourCard;
    });

    return (
        <div className="tour-card-container">

            <Typography.Text className="first-line">
                {`${t('Looks you have empty tour categories')}!`}
            </Typography.Text>

            <Typography.Text className="sec-line">
                {`${t('You can create unlimited tours based on follows categories')}.`}
            </Typography.Text>

            <div className="tour-card-wrapper">
                <div className="tour-card-wrapper_2">
                    {formattedTourCards.map((tourCard) => (
                        <div className="single-tour-card" key={tourCard.id}>
                            <TourCard data={tourCard} type="tour" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
