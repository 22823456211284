import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import CountryFlagComponent from '@/components/CountryFlag';
import BlockDescription from '@/components/Information/WithImage/Block';
import { MapIcon } from '@/assets/images/icons/topbar';
import Toggle from './Toggle';

const languages = [
    { label: 'English', shortcode: 'en', flag: 'us' },
    // { label: 'Italian', shortcode: 'it', flag: 'it' },
    // { label: 'French', shortcode: 'fr', flag: 'fr' },
    // { label: 'Spanish', shortcode: 'es', flag: 'es' },
    // { label: 'German', shortcode: 'de', flag: 'de' },
    // { label: 'Portuguese', shortcode: 'pt', flag: 'pt' },
];

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language.split('-')[0] || i18n.language;
    const languageFlag = useMemo(() => languages.find(
        (language) => language.shortcode === currentLanguage,
    )?.flag, [languages, currentLanguage]);

    const languageLabel = useMemo(() => languages.find(
        (language) => language.shortcode === currentLanguage,
    )?.label, [languages, currentLanguage]);

    return (
        <Select
            value={currentLanguage}
            popupClassName="language-select"
            dropdownMatchSelectWidth={false}
            onChange={(newLanguage) => i18n.changeLanguage(newLanguage)}
            getRawInputElement={() => (
                <span>
                    <Toggle
                        languageFlag={languageFlag}
                    />
                </span>
            )}
            dropdownRender={(menu) => (
                <>
                    <div>
                        <BlockDescription
                            icon={<CountryFlagComponent code={languageFlag} />}
                            label={t(languageLabel)}
                            content={t('Current Language')}
                        />
                    </div>
                    {menu}
                    <MapIcon />
                </>
            )}
        >
            {languages
                .filter((language) => currentLanguage !== language.shortcode)
                .map((language) => (
                    <Select.Option
                        key={language.shortcode}
                        value={language.shortcode}
                    >
                        <span>
                            <CountryFlagComponent code={language.flag} />
                        </span>
                    </Select.Option>
                ))}
        </Select>
    );
};

export default LanguageSelector;
