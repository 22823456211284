import { useTranslation } from 'react-i18next';
import { Row, Col, Card } from 'antd';
import {
    ClockIcon,
    CloseIcon,
    PeopleIcon,
    TickIcon,
} from '@/assets/images/icons/screens';
import { returnArrow } from '@/helpers/exactIcon';

const OrderCards = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Row gutter={20}>
            <Col xs={6}>
                <Card
                    className="order-cards confirmed"
                    title={(
                        <>
                            {t('Confirmed Orders')}
                            <div>{data[0]?.value}</div>
                        </>
                    )}
                    extra={<TickIcon />}
                >
                    {returnArrow(data[0]?.percentage, t('since last month'))}
                </Card>
            </Col>

            <Col xs={6}>
                <Card
                    className="order-cards pending"
                    title={(
                        <>
                            {t('Pending Orders')}
                            <div>{data[1]?.value}</div>
                        </>
                    )}
                    extra={<ClockIcon />}
                >
                    {returnArrow(data[1]?.percentage, t('since last month'))}
                </Card>
            </Col>

            <Col xs={6}>
                <Card
                    className="order-cards canceled"
                    title={(
                        <>
                            {t('Canceled Orders')}
                            <div>{data[2]?.value}</div>
                        </>
                    )}
                    extra={<CloseIcon />}
                >
                    {returnArrow(data[2]?.percentage, t('since last month'))}
                </Card>
            </Col>

            <Col xs={6}>
                <Card
                    className="order-cards customers"
                    title={(
                        <>
                            {t('Total Customers')}
                            <div>{data[5]?.total_customers}</div>
                        </>
                    )}
                    extra={<PeopleIcon />}
                >
                    {returnArrow(data[5]?.percentage, t('since last month'))}
                </Card>
            </Col>
        </Row>
    );
};

export default OrderCards;
