import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Space,
    Form,
    DatePicker,
    Select,
    Button,
    Divider,
} from 'antd';
import { PlusIcon, CalendarIcon, SortIcon } from '@/assets/images/icons/screens';
import BlockDescription from '@/components/Information/WithoutImage/Block';

import AddEvaluation from './AddEvaluation';
import EvaluationList from './EvaluationList';

const Performance = () => {
    const { t } = useTranslation();
    const [visibleForm, setVisibleForm] = useState(false);
    return (
        <div className="employee-performance">
            <Form>
                <Row
                    justify="space-between"
                    className="filter-content"
                >
                    <Col span={5}>
                        <BlockDescription
                            name={t('Last Evaluation')}
                        >
                            Jan 13, 2021 - 5:10 PM
                        </BlockDescription>
                    </Col>
                    <Col span={19}>
                        <Space size={30}>
                            <Form.Item name="date" noStyle>
                                <DatePicker
                                    placeholder={t('Filter by date')}
                                    suffixIcon={<CalendarIcon />}
                                />
                            </Form.Item>
                            <Form.Item name="sort" noStyle>
                                <Select
                                    placeholder={t('Sort by')}
                                    suffixIcon={<SortIcon />}
                                >
                                    <Select.Option>1</Select.Option>
                                </Select>
                            </Form.Item>
                            <Button
                                className="button add-button"
                                onClick={() => setVisibleForm(true)}
                            >
                                <PlusIcon />
                                {t('Add new Evaluation')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Divider />
            {visibleForm && <AddEvaluation setVisibleForm={setVisibleForm} />}

            <EvaluationList />
        </div>
    );
};

export default Performance;
