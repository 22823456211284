import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    Steps,
    Divider,
    Form,
} from 'antd';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import { actions } from '@/redux/b2b/partner/create/reducer';
import { selectors } from '@/redux/b2b/partner/create/model';
import AccountDetails from './AccountDetails';
import baseValidations from './validations';
import AgencyInfo from './AgencyInfo';
import Distribution from './Distribution';

const AddVendorForm = ({
    modalVisible,
    setModalVisible,
    partnerId,
    setPartnerId,
    isEdit,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formStep1] = Form.useForm();
    const [formStep2] = Form.useForm();
    const [formStep3] = Form.useForm();

    const validations = useTranslatedValidationConfig(baseValidations);
    const step = useSelector((state) => state.b2b.partner.create.step);
    const submitting = useSelector((state) => state.b2b.partner.create.submitting);
    const distributionMessage = useSelector(
        (state) => state.b2b.partner.create.distributionMessage,
    );

    /* distribution step */
    const [checkedList, setCheckedList] = useState([]);
    // partner services
    const agencyServices = useSelector(selectors.checkedServices);

    useEffect(() => {
        if (agencyServices.length !== 0) {
            setCheckedList(agencyServices.flatMap((agencyService) => {
                return agencyService.service.map((product) => {
                    return {
                        id: `${agencyService.id}-${product.id}`,
                        commission: product.commission,
                        commission_type: product.commission_type,
                    };
                });
            }));
        }
    }, [agencyServices]);

    useEffect(() => {
        if (isEdit && partnerId) {
            dispatch(actions.requestGetCheckedServices(partnerId));
        }
    }, [isEdit, dispatch, partnerId]);

    // agency from existing agency list
    const [agencyId, setAgencyId] = useState(null);

    const steps = [
        {
            title: t('Account Details'),
            content: <AccountDetails
                formStep1={formStep1}
                validations={validations}
                submitting={submitting}
                agencyId={agencyId}
                setAgencyId={setAgencyId}
            />,
        },
        {
            title: t('Agency Info'),
            content: <AgencyInfo
                formStep2={formStep2}
                validations={validations}
                submitting={submitting}
            />,
        },
        {
            title: t('Distribution'),
            content: <Distribution
                submitting={submitting}
                partnerId={partnerId}
                isEdit={isEdit}
                formStep3={formStep3}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
                agencyId={agencyId}
            />,
        },
    ];

    useEffect(() => {
        if (distributionMessage === 'Success.') {
            setModalVisible(false);
        }
    }, [distributionMessage, setModalVisible]);

    return (
        <Modal
            open={modalVisible}
            footer={null}
            onCancel={() => {
                setModalVisible(false);
                formStep1.resetFields();
                formStep2.resetFields();
                formStep3.resetFields();
                setCheckedList([]);
                setPartnerId(null);
            }}
            maskClosable={true}
            closable={false}
            centered
            width={600}
            className="add-vendor"
        >
            {isEdit ? (
                steps[2].content
            ) : (
                <>
                    <Steps
                        className="steps-container"
                        current={step}
                        labelPlacement="vertical"
                        items={[

                            { title: t(steps[0].title) },
                            { title: t(steps[1].title) },
                            { title: t(steps[2].title) },
                        ]}
                    />

                    <Divider dashed />

                    {steps[step].content}
                </>
            )}
        </Modal>
    );
};

export default AddVendorForm;
