import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    step: 0,
    completedStep: null,
    submitting: false,
    loading: false,
    errors: null,
    personalDetails: null,
    totalDetails: null,
    groups: [],
    roles: [],
    totalGroups: [],
    messageSuccess: '',
    messageError: null,
    groupRoles: [],
    newGroupData: null,
};

export const selectors = createSelectorsFromModel(
    (state) => state.employees.create,
    model,
);

export default model;
