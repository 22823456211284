import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import DataContainer from '@/components/DataContainer';
import { CloseIcon, TickIcon } from '@/assets/images/icons/screens';

const InclusionsExclusions = ({ inclusions, exclusions }) => {
    const { t } = useTranslation();

    return (
        (inclusions.length || exclusions.length !== 0) && (
            <DataContainer title={t('Inclusions / Exclusions')}>
                <Row className="incl-excl-data">
                    <Col span={12}>
                        <Typography.Text>
                            <div className="incl-excl-title">{t('Inclusions')}</div>
                        </Typography.Text>
                        {inclusions.map((item) => (
                            <Col key={item.id} span={24} className="incl-excl-content">
                                <TickIcon />
                                <span>{item.name}</span>
                            </Col>
                        ))}
                    </Col>
                    <Col span={12}>
                        <Typography.Text>
                            <div className="incl-excl-title">{t('Exclusions')}</div>
                        </Typography.Text>
                        {exclusions.map((item) => (
                            <Col key={item.id} span={24} className="incl-excl-content">
                                <CloseIcon />
                                <span>{item.name}</span>
                            </Col>
                        ))}
                    </Col>
                </Row>
            </DataContainer>
        )
    );
};

export default InclusionsExclusions;
