import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Divider,
    Input,
    Button,
} from 'antd';
import CustomCheckBoxGroup from '@/components/CustomCheckBoxGroup';
import { CloseCircleIcon } from '@/assets/images/icons/screens';

import { actions } from '@/redux/employees/create/reducer';
import { selectors } from '@/redux/employees/create/model';

const AddNewRole = ({
    roleSectionOpen,
    setRoleSectionOpen,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const roles = useSelector(selectors.roles);

    useEffect(() => {
        dispatch(actions.requestGetRoles());
    }, [dispatch]);

    const [groupName, setGroupName] = useState('');
    const [checkedRoles, setCheckedRoles] = useState({});

    const close = useCallback(() => {
        setRoleSectionOpen(false);
    }, [setRoleSectionOpen]);

    const addNewGroup = useCallback(() => {
        const data = {
            name: groupName,
            roles: roles.map((role) => checkedRoles[`roles${role.id}`]).flat().filter((item) => item !== undefined),
        };
        dispatch(actions.requestAddNewGroup(data));
        setGroupName('');
        setCheckedRoles({});
        close();
    }, [groupName, checkedRoles]);

    return (
        <div className="add-new-role">
            {roleSectionOpen && (
                <>
                    <Divider>
                        {t('Creating New Role')}
                    </Divider>

                    <Input
                        placeholder={t('Title of Job Position / Role')}
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />

                    <div className="role-list">
                        {roles.map((role) => (
                            <div key={role.id} className="roles">
                                <CustomCheckBoxGroup
                                    selectAllLabel={role.category}
                                    options={role.roles}
                                    labelParamName="role"
                                    valueParamName="id"
                                    checkedRoles={checkedRoles}
                                    checkedList={checkedRoles[`roles${role.id}`]}
                                    setCheckedList={setCheckedRoles}
                                    roleId={role.id}
                                />
                            </div>
                        ))}
                    </div>
                    <Row justify="space-between">
                        <Col xs={12}>
                            <Button danger type="text" onClick={close}>
                                <CloseCircleIcon />
                                {t('Cancel')}
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Button
                                block
                                type="primary"
                                onClick={addNewGroup}
                            >
                                {t('Save')}
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default AddNewRole;
