import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';

const RoomDetails = ({ currency }) => {
    const { t } = useTranslation();

    const options = useMemo(() => ([
        { label: t('%'), value: 4 },
        { label: t(currency), value: 5 },
    ]), [t]);

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={12}>
                    <MUIFormItem
                        label={t('Room Type Name')}
                        name="type_name"
                        // validateFirst
                        // hasFeedback
                        // rules={validations.typeName}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>
                <Col span={6}>
                    <MUIFormItem
                        label={t('Room Size')}
                        name="room_size"
                    >
                        <Input suffix="m2" />
                    </MUIFormItem>
                </Col>
                <Col span={6}>
                    <MUIFormItem
                        label={t('Number of Rooms')}
                        name="no_rooms"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>
                <Col span={12}>
                    <MUIFormItem
                        label={t('Price per Night')}
                        name="price"
                        // rules={validations.measurement}
                        // validateFirst
                        // hasFeedback
                    >
                        <Input suffix={`${currency}0.00`} />
                    </MUIFormItem>
                </Col>
                <Col span={12}>
                    <MUIFormItem
                        label={t('My Commission')}
                        name="commission"
                        // validateFirst
                        // hasFeedback
                        // rules={validations.base_price}
                    >
                        <Input
                            suffix={(
                                <>
                                    0.00
                                    <Form.Item
                                        name="commission_type"
                                        noStyle
                                    >
                                        <Radio.Group
                                            options={options}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </>
                            )}
                        />
                    </MUIFormItem>
                </Col>
            </Row>
        </>
    );
};

export default RoomDetails;
