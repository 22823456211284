import { Form, Slider } from 'antd';
import { useCurrency } from '@/hooks/useCurrency';

const PriceSlider = ({ type, accommodationMaxPrice, tourMaxPrice }) => {
    const { icon: currencyIcon } = useCurrency();

    return (
        <Form.Item name="price" noStyle>
            <Slider
                className="cruise-slider"
                range
                min={0}
                max={type === 'Hotel' ? accommodationMaxPrice : tourMaxPrice}
                step={10}
                tooltip={{
                    getPopupContainer: (trigger) => {
                        return trigger?.parentElement;
                    },
                    open: true,
                    formatter: (value) => {
                        return `${currencyIcon} ${value}`;
                    },
                }}
                getPopupContainer={(trigger) => {
                    return trigger?.parentElement;
                }}
            />
        </Form.Item>
    );
};

export default PriceSlider;
