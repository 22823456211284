import BaseService from '@/services/BaseService';

class IntegrationService extends BaseService {
    getWidgetDiv = (data) => this.post('/agency/widgetsearch', data);

    getServiceList = () => this.get('/agency/widgetserviceslist');

    getDomainWhitelist = () => this.get('/agency/widget/whitelist');

    addNewDomain = (data) => this.post('/agency/widget/createdomain', data);

    deleteDomain = (id) => this.delete(`/agency/widget/deletedomain/${id}`);

    getWidgetsCategories = () => this.get('/agency/widgetscategorieslist');

    editCategoryName = (data) => this.post('/agency/widgetscategoriesupdate', data);
}

export default new IntegrationService();
