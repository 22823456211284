import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get max price
    requestGetMaxPrice: ['data'],
    getMaxPriceSucceeded: ['data', 'serviceType'],
    getMaxPriceFailed: ['error'],
});

export default createReducer(initialState, {
    // max price
    [types.REQUEST_GET_MAX_PRICE]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.GET_MAX_PRICE_SUCCEEDED]: (
        state,
        {
            data,
            serviceType,
        },
    ) => ({
        ...state,
        tourMaxPrice: serviceType === 1 ? data : null,
        accommodationMaxPrice: serviceType === 2 ? data : null,
        loading: false,
    }),
    [types.GET_MAX_PRICE_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),
});

export { types, actions };
