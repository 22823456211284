import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    Space,
    Typography,
    Button,
    Form,
} from 'antd';
import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import { BriefcaseIcon, InfoCircleIcon, PlusIcon } from '@/assets/images/icons/screens';
import { actions } from '@/redux/employees/create/reducer';
import { selectors } from '@/redux/employees/create/model';

import AddNewRole from './AddNewRole';

const Role = ({
    roleSectionOpen,
    setRoleSectionOpen,
    opacity,
    initialRoles,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const groups = useSelector(selectors.groups);

    useEffect(() => {
        dispatch(actions.requestGetGroups());
    }, [dispatch]);

    return (
        <>
            <div className={opacity} id="job-position">
                <div className="details-title">
                    <Space size={10}>
                        <BriefcaseIcon />
                        <Typography.Text>
                            {t('Job Position / Role')}
                        </Typography.Text>
                    </Space>
                </div>
                <div>
                    <Space size={6}>
                        <InfoCircleIcon />
                        <Typography.Text>
                            {t('You can choose more than one job position for this employee')}
                        </Typography.Text>
                    </Space>
                </div>
                <div>
                    <Form.Item
                        name="group"
                    >
                        <CheckboxMultiSelect
                            options={groups}
                            labelParamName="name"
                            valueParamName="id"
                            hasSelectAll={false}
                            initialCheckedList={initialRoles}
                        />
                    </Form.Item>
                    <Button
                        type="text"
                        className="text-circle-button"
                        onClick={() => setRoleSectionOpen(true)}
                    >
                        <div><PlusIcon /></div>
                        {t('Add new Role')}
                    </Button>
                </div>
            </div>
            <AddNewRole
                roleSectionOpen={roleSectionOpen}
                setRoleSectionOpen={setRoleSectionOpen}
            />
        </>
    );
};

export default Role;
