import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { HotelIcon } from '@/assets/images/icons/screens';

const EmptySeasonList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const goToCreateSeason = useCallback(() => {
        dispatch(actions.goToCreateSeason());
    }, [dispatch]);

    return (
        <div className="empty-season-list">
            <div>
                <HotelIcon />
            </div>
            <div>
                <span>
                    {t("Oops! Looks you haven't created yet any Season")}
                </span>
            </div>
            <div>
                <Button
                    className="create-button-filled"
                    onClick={goToCreateSeason}
                >
                    {t('Add new season').toUpperCase()}
                </Button>
            </div>
        </div>
    );
};

export default EmptySeasonList;
