import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({
    // view cruise data
    requestViewCruise: ['id', 'filters'],
    viewCruiseSucceeded: [
        'generalData',
        'iteneraryData',
        'cabinType',
    ],
    viewCruiseFailed: ['error'],

    // search for cabins
    requestFetchCruiseCabins: ['id', 'filters', 'isFetchingMore'],
    fetchCruiseCabinsSucceeded: ['cabins'],
    fetchCruiseCabinsFailed: ['error'],

    // fetch more rooms
    fetchMoreCruiseCabins: ['filters'],
    fetchMoreCruiseCabinsSucceeded: ['cabins'],
    fetchMoreCruiseCabinsFailed: ['error'],

    // save filters
    saveCruiseFilterData: ['filters'],

    // clear cruise view state
    clearCruiseViewState: ['null'],
});

export default {
    // view cruise data
    [types.REQUEST_VIEW_CRUISE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_CRUISE_SUCCEEDED]: (
        state,
        {
            generalData,
            iteneraryData,
            cabinType,
        },
    ) => ({
        ...state,
        view: {
            ...state.view,
            generalInfo: generalData,
            itenerary: iteneraryData,
            cabinTypeList: cabinType,
            loading: false,
        },
    }),
    [types.VIEW_CRUISE_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // search for cabins
    [types.REQUEST_FETCH_CRUISE_CABINS]: (state, { isFetchingMore }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: !isFetchingMore,
            fetchingError: null,
        },
    }),
    [types.FETCH_MORE_CRUISE_CABINS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            isLoadingMore: true,
            isLoadingMoreError: null,
        },
    }),
    [types.FETCH_CRUISE_CABINS_SUCCEEDED]: (state, { cabins }) => ({
        ...state,
        view: {
            ...state.view,
            cabinList: cabins,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
        },
    }),
    [types.FETCH_MORE_CRUISE_CABINS_SUCCEEDED]: (state, { cabins }) => ({
        ...state,
        view: {
            ...state.view,
            roomList: [...state.view.cabinList, ...cabins],
            fetchingLoading: false,
            fetchingError: null,
            isLoadingMore: false,
            hasMore: cabins.length !== 0,
        },
    }),
    [types.FETCH_CRUISE_CABINS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            fetchingLoading: false,
            fetchingError: error,
        },
    }),
    [types.FETCH_MORE_CRUISE_CABINS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            isFetchingMore: false,
            isLoadingMoreError: error,
        },
    }),

    // save filters
    [types.SAVE_CRUISE_FILTER_DATA]: (state, { filters }) => ({
        ...state,
        view: {
            ...state.view,
            filterData: filters,
        },
    }),

    // clear cruise view state
    [types.CLEAR_CRUISE_VIEW_STATE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            generalInfo: null,
            itenerary: null,
            cabinTypeList: [],
            cabinList: [],
            filterData: null,
            loading: false,
            error: null,
            fetchingLoading: false,
            fetchingError: null,
            hasMore: true,
        },
    }),
};
